import { Card } from "antd";
import { getChartReport, getTableReport } from "api";
import { ColumnChart } from "components/chart/ChartDashboard";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import { EmptyWrapper } from "components/empty/EmptyComponent";
import TableReport from "components/table/TableReport";
import TableTitle from "components/tableSendiri/TableTitle";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReportEmail } from "redux/slices/reportEmail";
import { setReportEmailChart } from "redux/slices/reportEmailChart";
import useDocumentTitle from "utils/useDocumentTitle";
import "./report.less";

const ReportEmail = () => {
  const headerCSVUser = [
    { label: "Tanggal", key: "date" },
    { label: "Domain", key: "domainName" },
    { label: "Email Terkirim", key: "countDelivered" },
    { label: "Email Gagal", key: "countFailed" },
    { label: "Total Email", key: "countSent" },
    { label: "Email Pengirim Terbanyak", key: "mostSender" },
    { label: "Email Penerima Terbanyak", key: "mostEmailReceiver" },
    { label: "Penerima Terbanyak", key: "mostDomainReceiver" },
  ];
  useDocumentTitle("Report Email");
  let dataCSV = [];
  const [filter, setFilter] = useState({
    page: 1,
    limit: "10",
  });
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const reportEmail = useSelector(({ reportEmail: reportTable }) => reportTable.value);
  const reportEmailChart = useSelector(({ reportEmailChart: reportChart }) => reportChart.value);

  const { pageSize, count: countTable } = reportEmail;
  const getDataChart = useCallback(async () => {
    setLoading(true);
    const { data: chart } = await getChartReport();
    dispatch(setReportEmailChart(chart));
    setLoading(false);
  }, [dispatch]);

  const getDataTable = useCallback(async () => {
    setLoading(true);
    const { data: table } = await getTableReport(filter);
    dispatch(setReportEmail(table));
    setLoading(false);
  }, [dispatch, filter]);

  useEffect(() => {
    if (filter) getDataTable();
  }, [getDataTable, filter]);

  useEffect(() => {
    if (!reportEmailChart) getDataChart();
  }, [getDataChart, reportEmailChart]);

  const data = { table: reportEmail, loading };

  return (
    <div className="report-email-user ">
      <DashboardHeader title="Report Email" />
      <div className="site-card-border-less-wrapper">
        <div className="px-6 py-4">
          {countTable > 0 ? (
            <div className="p-6 bg-white">
              <ColumnChart data={reportEmailChart} />
              <TableReport
                title={{
                  title: "Email Delivery Report",
                  pageLimit: {
                    isVisible: data?.count > 10,
                    currentLimit: filter?.limit,
                    onSelectLimit: (limit) => {
                      setFilter({ ...filter, limit, page: 1 });
                    },
                    disable: data?.table.count < 10,
                  },
                  buttonExport: {
                    dataCSV: data?.table.rows,
                    headerCSV: headerCSVUser,
                    responsive: "lg",
                    table: "invoice",
                  },
                }}
                filter={filter}
                setFilter={setFilter}
                responsive="xs"
                data={data}
              />
            </div>
          ) : (
            <Card
              bordered={false}
              title={
                <TableTitle
                  title="Email Delivery Report"
                  loading={loading}
                  buttonExport={{ disable: true, dataCSV }}
                  pageLimit={{
                    disable: true,
                    onSelectLimit: () => {},
                    currentLimit: pageSize,
                  }}
                />
              }
              loading={loading}>
              <EmptyWrapper
                message={`Email delivery report akan tersedia 24 jam setelah anda aktif menggunakan layanan ${process.env.REACT_APP_NAME}`}
                className="bg-white"
              />
            </Card>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReportEmail;
