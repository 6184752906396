import { Form, Modal } from "antd";
import FormSuppress from "components/form/FormSuppress";
import ModalConfirmSuppress from "./ModalConfirmSuppress";
import ModalFailedSuppress from "./ModalFailedSuppress";
import { useState, useCallback } from "react";
import { regexEmail, gatherFormData } from "utils/helpers";
import { AddSuppress, getSuppressionList, getHistoryList } from "api";
import ModalError from "./ModalError";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setSuppressionList } from "redux/slices/SuppressionList";
import { setHistoryList } from "redux/slices/HistoryList";

const ModalAddSuppress = ({
  isOpen,
  setOpen,
  dataSuppress,
  filterSuppress,
  dataHistory,
  filterHistory,
  setShowSkeleton,
}) => {
  const dispatch = useDispatch();
  const [emailValidationError, setEmailValidationError] = useState("");
  const [emailFieldValue, setEmailFieldValue] = useState(null);
  const [reasonFieldValue, setReasonFieldValue] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isAddingSuppress, setIsAddingSuppress] = useState(false);
  const [RadioState, setRadioState] = useState(null);
  const [form] = Form.useForm();

  const getCallbackSuppress = useCallback(async () => {
    const { data, code } = await getSuppressionList(filterSuppress);
    dispatch(setSuppressionList({ data, code }));
  }, [dispatch, filterSuppress]);

  useEffect(() => {
    if (!dataSuppress);
  }, [getCallbackSuppress, dataSuppress]);

  const getCallbackHistory = useCallback(async () => {
    const { data, code } = await getHistoryList(filterHistory);
    dispatch(setHistoryList({ data, code }));
  }, [dispatch, filterHistory]);

  useEffect(() => {
    if (!dataHistory);
  }, [getCallbackHistory, dataHistory]);

  const handleCancelSuprress = () => {
    form.resetFields();
    setRadioState(null);
    setOpen(false);
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmailFieldValue(email);
    const isEmailNotEmpty = email.trim() !== "";
    const isEmailValid = regexEmail.test(email);
    if (!isEmailNotEmpty) {
      setEmailValidationError("Alamat email wajib diisi");
    } else if (!isEmailValid) {
      setEmailValidationError("Harap masukkan alamat email yang vaild");
    } else {
      setEmailValidationError("");
    }
    setIsButtonDisabled(!(isEmailNotEmpty && isEmailValid && RadioState));
  };

  const onChoose = (e) => {
    setRadioState(e.target.value);
    setIsButtonDisabled(!(emailFieldValue && (RadioState === "" || RadioState !== "")));
  };

  const handleAddSuppress = async () => {
    const email = emailFieldValue;
    const message = reasonFieldValue;
    const category = RadioState;
    setIsAddingSuppress(true);

    if (!message) {
      const response = await AddSuppress({
        email: email,
        type: category,
      });
      if (response.code === 500 || (response.code !== 200 && response.code !== 400)) {
        setIsAddingSuppress(false);
        setOpen(false);
        ModalError({
          title: "Peringatan!",
          message: response.msg,
        });
      } else if (response.code === 400) {
        setTimeout(() => {
          ModalFailedSuppress({
            title: "Email Gagal Ditambahkan",
            customMessage: (
              <div>
                <p>
                  Email <strong>{email}</strong> gagal ditambahkan pada suppression list, karena
                  email sudah terdaftar sebelumnya.
                </p>
              </div>
            ),
            onOk: () => {
              setIsAddingSuppress(false);
              setOpen(false);
              setShowSkeleton(true);
              setTimeout(() => {
                setShowSkeleton(false);
                setRadioState("");
              }, 500);
            },
          });
        }, 1000);
      } else {
        setTimeout(() => {
          ModalConfirmSuppress({
            title: "Suppression Berhasil Ditambahkan",
            customMessage: (
              <div>
                <p>
                  Email <strong>{email}</strong> sudah berhasil ditambahkan ke suppression list
                </p>
              </div>
            ),
            onOk: () => {
              setOpen(false);
              setShowSkeleton(true);
              setTimeout(async () => {
                await getCallbackSuppress();
                await getCallbackHistory();
                setIsAddingSuppress(false);
                setShowSkeleton(false);
                setRadioState(null);
              }, 500);
            },
          });
        }, 1000);
      }
    } else {
      const response = await AddSuppress({
        email: email,
        message: message,
        type: category,
      });
      if (response.code === 500 || (response.code !== 200 && response.code !== 400)) {
        setIsAddingSuppress(false);
        setOpen(false);
        setRadioState("");
        ModalError({
          title: "Peringatan!",
          message: response.msg,
        });
      } else if (response.code === 400) {
        setTimeout(() => {
          ModalFailedSuppress({
            title: "Email Gagal Ditambahkan",
            customMessage: (
              <div>
                <p>
                  Email <strong>{email}</strong> gagal ditambahkan pada suppression list, karena
                  email sudah terdaftar sebelumnya.
                </p>
              </div>
            ),
            onOk: () => {
              setIsAddingSuppress(false);
              setOpen(false);
              setShowSkeleton(true);
              setTimeout(() => {
                setShowSkeleton(false);
                setRadioState(null);
              }, 500);
            },
          });
        }, 1000);
      } else {
        setTimeout(() => {
          ModalConfirmSuppress({
            title: "Suppression Berhasil Ditambahkan",
            customMessage: (
              <div>
                <p>
                  Email <strong>{email}</strong> sudah berhasil ditambahkan ke suppression list
                </p>
              </div>
            ),
            onOk: () => {
              setOpen(false);
              setShowSkeleton(true);
              setTimeout(async () => {
                await getCallbackSuppress();
                await getCallbackHistory();
                setIsAddingSuppress(false);
                setShowSkeleton(false);
              });
            },
          });
        }, 1000);
      }
    }

    gatherFormData(form);
  };
  useEffect(() => {
    form.resetFields();
  }, [form]);

  return (
    <Modal
      title="Tambah Email Suppression"
      open={isOpen}
      centered
      width="572px"
      onOk={handleAddSuppress}
      onCancel={handleCancelSuprress}
      okText="Tambah Suppression"
      cancelText="Batal"
      okButtonProps={{ disabled: isButtonDisabled, loading: isAddingSuppress }}>
      <Form
        layout="vertical"
        name="tambahSuppress"
        initialValues={{ notifikasi: true }}
        form={form}>
        <FormSuppress
          className="mb-0"
          onEmailChange={handleEmailChange}
          EmailValidation={emailValidationError}
          setReason={setReasonFieldValue}
          emailFieldValue={emailFieldValue}
          reasonFieldValue={reasonFieldValue}
          RadioState={RadioState}
          setRadioState={setRadioState}
          setIsButtonDisabled={setIsButtonDisabled}
          onChoose={onChoose}
        />
      </Form>
    </Modal>
  );
};

export default ModalAddSuppress;
