import { checkoutChecker } from "api";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCheckoutChecker } from "redux/slices/checkoutChecker";

const useCheckoutChecker = () => {
  const isChecked = useSelector(({ checkoutChecker }) => checkoutChecker.value);

  const dispatch = useDispatch();
  const action = setCheckoutChecker;

  const doSet = useCallback((data) => dispatch(action(data)), [dispatch, action]);

  useEffect(() => {
    const checkCheckout = async () => {
      const { data } = await checkoutChecker();
      if (data === null) doSet("first");
      else doSet(data);
    };
    if (!isChecked) checkCheckout();
  }, [isChecked, doSet]);
};

export default useCheckoutChecker;
