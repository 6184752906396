import { Card, Divider, Tag } from "antd";
import { getNotificationDetailAdmin } from "api";
import DashboardPageHeader from "components/dashboard-header/DashboardPageHeader";
import MarkdownInput from "components/input/MarkdownInput";
import { ICNotificationOutlined, ICPercentageOutlined } from "components/list/ListIcon";
import ModalError from "components/popups/modal/ModalError";
import { parse } from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import "./manageNotification.less";

const ContentNotification = ({ data }) => {
  if (!data) return <Card loading />;

  return (
    <div className="viewNotification__content">
      <MarkdownInput data={data.content} />
      <Divider className="mt-4 mb-6" />
      <div className="flex flex-col gap-y-4">
        <div className="categoryNotification">
          <span>Publisher:</span>
          <span>{data.publisher.name}</span>
        </div>
        <div className="categoryNotification">
          <span>Kategori Notifikasi:</span>
          <Tag
            icon={data.category === "info" ? <ICNotificationOutlined /> : <ICPercentageOutlined />}>
            {capitalizeFirstLetter(data.category)}
          </Tag>
        </div>
        <div className="categoryNotification">
          <span>Tanggal Publikasi:</span>
          <span>{data.date.slice(0, 10)}</span>
        </div>
      </div>
    </div>
  );
};

const ViewNotification = () => {
  const [data, setData] = useState(false);
  useDocumentTitle(`${data.title || "Lihat Notifikasi"}`);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { uuid } = parse(search);

  const getDetailNotification = useCallback(async () => {
    const { data, code } = await getNotificationDetailAdmin({ uuid });
    if (code !== 200) {
      ModalError({
        title: "Notifikasi tidak ditemukan",
        message: "Harap masukan uuid notifikasi dengan benar",
        onOk: () => navigate("/admin/manage-notification"),
      });
    }
    setData(data);
  }, [uuid, navigate]);

  useEffect(() => {
    if (uuid) getDetailNotification();
  }, [uuid, getDetailNotification]);

  return (
    <div className="viewNotification">
      <DashboardPageHeader title={data.title || "Loading"} />
      <div className="py-4 px-6">
        <ContentNotification data={data} />
      </div>
    </div>
  );
};

export default ViewNotification;
