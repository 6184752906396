import DashboardHeader from "components/dashboard-header/DashboardHeader";
import React from "react";
import useDocumentTitle from "utils/useDocumentTitle";
import "./kelolapaket.less";
import PackageSummary from "./PackageSummary";
import PackageTabs from "./PackageTabs";

const KelolaPaket = () => {
  useDocumentTitle("Kelola Paket");
  return (
    <div className="kelola-paket">
      <DashboardHeader title="Kelola Paket" />
      <div className="flex flex-col gap-y-6 px-6 py-4">
        <div className="package-summary-section">
          <PackageSummary />
        </div>
        <div className="px-6 py-4 bg-white">
          <PackageTabs />
        </div>
      </div>
    </div>
  );
};

export default KelolaPaket;
