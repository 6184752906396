import { createSlice } from "@reduxjs/toolkit";

export const invoiceCustomer = createSlice({
  name: "invoiceCustomer",
  initialState: {
    value: false,
  },
  reducers: {
    setInvoiceCustomer: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setInvoiceCustomer } = invoiceCustomer.actions;
export default invoiceCustomer.reducer;
