import { Form, Input, Tooltip } from "antd";
import { regexChangePasswordSMTP } from "utils/helpers";
import { ICEyeInvisibleOutlined, ICEyeOutlined } from "components/list/ListIcon";
import React, { useState } from "react";
import "../popups/modal/modal.less";

const FormChangePassSMTP = ({
  title = "Ubah password SMTP Relay",
  className = "",
  wrapperCol,
  label = "",
  isSetup = false,
  setNewPassword,
  setRepeatPassword,
  record,
  icon,
}) => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleRepeatPasswordChange = (e) => {
    setRepeatPassword(e.target.value);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleRepeatPasswordVisibility = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  const inputNewPassword = (
    <Input.Password
      placeholder="input password"
      onChange={handleNewPasswordChange}
      iconRender={(visible) => (visible ? <ICEyeOutlined /> : <ICEyeInvisibleOutlined />)}
      suffix={
        <Tooltip title={showNewPassword ? "Hide" : "Show"}>
          <span onClick={toggleNewPasswordVisibility} style={{ cursor: "pointer" }}>
            {showNewPassword ? <ICEyeOutlined /> : <ICEyeInvisibleOutlined />}
          </span>
        </Tooltip>
      }
    />
  );
  const repeatNewPassword = (
    <Input.Password
      placeholder="repeat password"
      onChange={handleRepeatPasswordChange}
      iconRender={(visible) => (visible ? <ICEyeOutlined /> : <ICEyeInvisibleOutlined />)}
      suffix={
        <Tooltip title={showRepeatPassword ? "Hide" : "Show"}>
          <span onClick={toggleRepeatPasswordVisibility} style={{ cursor: "pointer" }}>
            {showRepeatPassword ? <ICEyeOutlined /> : <ICEyeInvisibleOutlined />}
          </span>
        </Tooltip>
      }
    />
  );

  const validateRepeatPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("new_password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("Password tidak sesuai"));
    },
  });

  return (
    <div style={{ display: "flex", alignItems: "flex-start" }}>
      {icon && <div style={{ marginRight: "16px", marginTop: "1px" }}>{icon}</div>}
      <Form.Item
        label={label}
        className={className}
        wrapperCol={wrapperCol}
        hasFeedback
        newPassword={inputNewPassword}
        repeatPassword={repeatNewPassword}
        record={record}>
        {!isSetup ? (
          <div>
            {title && <h2 className="title-modal">{title}</h2>}
            <p className="mb-2">
              Apakah anda yakin ingin mengubah password SMTP Relay dari akun{" "}
              <strong>{record.relay_email}</strong>?
              <br /> <br /> Setelah mengubah password, customer harus melakukan perubahan
              konfigurasi pada semua aplikasi yang sudah disetup sebelumnya.
              <br /> <br />
            </p>
            <Form.Item
              label="Masukkan Password Baru"
              name="new_password"
              rules={[
                {
                  required: true,
                  message: "Masukkan Password Baru",
                },
                {
                  pattern: regexChangePasswordSMTP,
                  message:
                    "Password harus mengandung 10 karakter dengan kombinasi huruf kapital, huruf kecil, angka dan simbol",
                },
              ]}>
              {inputNewPassword}
            </Form.Item>
            <Form.Item
              className="mt-4"
              label="Masukkan Ulang Password"
              name="repeat_password"
              dependencies={["new_password"]}
              rules={[
                {
                  required: true,
                  message: "Password tidak sesuai",
                },
                validateRepeatPassword,
              ]}>
              {repeatNewPassword}
            </Form.Item>
          </div>
        ) : null}
      </Form.Item>
    </div>
  );
};

export default FormChangePassSMTP;
