import ChangePassLayout from "components/layout/ChangePassLayout";
import Notification from "components/notification-layout/Notification";
import NotificationDetails from "components/notification-layout/NotificationDetails";
import RequireAuth from "components/RequireAuth";
import ClickVerifikasi from "pages/auth/user/register/verifikasi-step/ClickVerifikasi";
import VerifikasiUser from "pages/auth/user/register/verifikasi-step/VerifikasiUser";
import CallToSetupCompany from "pages/CallToSetupCompany";
import {
  BillingData,
  CreateNotification,
  DaftarPelanggan,
  DetailReportBilling,
  EditPelanggan,
  KelolaAdmin,
  KelolaDokumentasi,
  KelolaPaket,
  LogEmail as LogEmailAdmin,
  MainDashboard as MainAdmin,
  ManageNotification,
  PackageActions,
  PelangganCreate,
  Profile as ProfileAdmin,
  ReportBilling,
  ReportEmail as ReportEmailAdmin,
  ViewNotification,
  EditNotification,
  SuppressionListAdmin,
} from "pages/dashboard/admin";
import EditDocumentation from "pages/dashboard/admin/kelola-dokumentasi/EditDocumentation";
import PreviewDocumentation from "pages/dashboard/admin/kelola-dokumentasi/PreviewDocumentation";
import Dashboard from "pages/dashboard/Dashboard";
import Billing from "pages/dashboard/user/billing/Billing";
import BillingConfirmation from "pages/dashboard/user/billing/confirmation/billing-confirmation";
import OverusageConfirmation from "pages/dashboard/user/billing/confirmation/overusage-confirmation";
import Dokumentasi from "pages/dashboard/user/dokumentasi/Dokumentasi";
import LogEmail from "pages/dashboard/user/log/LogEmail";
import EmailAPI from "pages/dashboard/user/api/EmailAPI";
import MainDashboard from "pages/dashboard/user/main/MainDashboard";
import Profile from "pages/dashboard/user/profile/Profile";
import ReportEmail from "pages/dashboard/user/report/ReportEmail";
import SuppressionList from "pages/dashboard/user/suppress/SuppressionList";
import DomainHasUsedTrial from "pages/dashboard/user/setup/DomainHasUsedTrial";
import DomainSuccess from "pages/dashboard/user/setup/DomainSuccess";
import ProcessingDomain from "pages/dashboard/user/setup/ProcessingDomain";
import ProcessingLayout from "pages/dashboard/user/setup/ProcessingLayout";
import SetupLayanan from "pages/dashboard/user/setup/SetupLayanan";
import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import CheckAdminConfig from "utils/CheckAdminConfig";
import CheckLogin from "utils/CheckLogin";
import CheckLoginCheckout from "utils/CheckLoginCheckout";
import CheckRegisterConfig from "utils/CheckRegisterConfig";
import ProtectedAdminRoutes from "utils/ProtectedAdminRoutes";
import ProtectedUserRoutes from "utils/ProtectedUserRoutes";

const ForgetPassLayout = lazy(() => import("components/layout/ForgetPassLayout"));
const ConfirmEmail = lazy(() => import("pages/auth/user/register/verifikasi-step/ConfirmEmail"));
const CheckoutNew = lazy(() => import("pages/checkout/CheckoutNew"));
const CheckoutProccess = lazy(() => import("pages/checkout/CheckoutProccess"));
const OverQoutaProcess = lazy(() => import("pages/checkout/OverQoutaProcess"));
const OverQoutaFailed = lazy(() => import("pages/checkout/OverQoutaFailed"));
const OverQoutaSuccess = lazy(() => import("pages/checkout/OverQoutaSuccess"));
const UserLogin = lazy(() => import("pages/auth/user/UserLogin"));
const UserRegister = lazy(() => import("pages/auth/user/register/UserRegister"));
const TFALogin = lazy(() => import("pages/auth/user/TFALogin"));
const ChangePassUser = lazy(() => import("pages/auth/user/ChangePass"));
const ChangePassSuccess = lazy(() => import("pages/auth/user/ChangePassSuccess"));
const ForgetPass = lazy(() => import("pages/auth/user/ForgetPass"));
const ForgetPassSuccess = lazy(() => import("pages/auth/user/ForgetPassSuccess"));
const ExpiredToken = lazy(() => import("pages/auth/user/ExpiredToken"));
const CompanyData = lazy(() => import("pages/auth/user/register/company-step/CompanyData"));
const AdminLogin = lazy(() => import("pages/auth/admin/AdminLogin"));
const AdminChangePass = lazy(() => import("pages/auth/admin/AdminChangePass"));
const AdminChangePassSuccess = lazy(() => import("pages/auth/admin/AdminChangePassSuccess"));
const AdminForgetPass = lazy(() => import("pages/auth/admin/AdminForgetPass"));
const AdminForgetPassSuccess = lazy(() => import("pages/auth/admin/AdminForgetPassSuccess"));
const WaitPageClientLogin = lazy(() =>
  import("pages/dashboard/admin/pelanggan/WaitPageClientLogin")
);
const NotFoundPage = lazy(() => import("pages/NotFoundPage"));
const DisplayImage = lazy(() => import("components/DisplayImage"));

const userRoles = {
  primary: "primary",
  techbill: "techbill",
  billing: "billing",
  technical: "technical",
};

const adminRoles = {
  superadmin: "superadmin",
  admin: "admin",
  billing: "accounting",
};

const Routing = ({ user, token }) => {
  return (
    <Routes>
      <Route index element={<CheckLogin />} />
      <Route path="login" element={<UserLogin />} />
      <Route path="config" element={<CallToSetupCompany />} />
      <Route path="register">
        <Route index element={<UserRegister />} />
        <Route element={<CheckRegisterConfig user={user} />}>
          <Route path="verifikasi" element={<VerifikasiUser user={user} />} />
          <Route path="company-data" element={<CompanyData />} />
        </Route>
        <Route path="verifikasi/status" element={<ClickVerifikasi />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      <Route path="changepassword" element={<ChangePassLayout />}>
        <Route index element={<ChangePassUser />} />
        <Route path="success" element={<ChangePassSuccess />} />
      </Route>
      <Route path="forgetpass" element={<ForgetPassLayout />}>
        <Route index element={<ForgetPass />} />
        <Route path="success" element={<ForgetPassSuccess />} />
      </Route>
      <Route
        element={
          <ProtectedUserRoutes user={user} token={token}>
            <Dashboard user={user} />
          </ProtectedUserRoutes>
        }>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                userRoles.primary,
                userRoles.techbill,
                userRoles.technical,
                userRoles.billing,
              ]}
            />
          }>
          <Route path="main" element={<MainDashboard />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[userRoles.primary, userRoles.techbill, userRoles.technical]}
            />
          }>
          <Route path="setup" element={<SetupLayanan />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[userRoles.primary, userRoles.techbill, userRoles.technical]}
            />
          }>
          <Route path="email-api" element={<EmailAPI />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[userRoles.primary, userRoles.techbill, userRoles.technical]}
            />
          }>
          <Route path="log" element={<LogEmail />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[userRoles.primary, userRoles.techbill, userRoles.technical]}
            />
          }>
          <Route path="report" element={<ReportEmail />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[userRoles.primary, userRoles.techbill, userRoles.technical]}
            />
          }>
          <Route path="suppression" element={<SuppressionList />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[userRoles.primary, userRoles.techbill, userRoles.billing]}
            />
          }>
          <Route path="billing" element={<Billing />} />
          <Route path="billing/confirmation" element={<BillingConfirmation />} />
          <Route path="overusage/confirmation" element={<OverusageConfirmation />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[userRoles.primary, userRoles.techbill, userRoles.technical]}
            />
          }>
          <Route path="documentation" element={<Dokumentasi />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                userRoles.primary,
                userRoles.techbill,
                userRoles.billing,
                userRoles.technical,
              ]}
            />
          }>
          <Route path="profile" element={<Profile />} />
        </Route>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                userRoles.primary,
                userRoles.techbill,
                userRoles.billing,
                userRoles.technical,
              ]}
            />
          }>
          <Route path="notification" element={<Notification />} />
          <Route path="notification/detail/:name" element={<NotificationDetails />} />
        </Route>
      </Route>
      <Route path="setup/processing" element={<ProcessingLayout />}>
        <Route index element={<ProcessingDomain />} />
        <Route path="success" element={<DomainSuccess />} />
        <Route path="failed" element={<DomainHasUsedTrial />} />
      </Route>

      <Route path="admin">
        <Route index element={<CheckAdminConfig />} />
        <Route path="login" element={<AdminLogin />} />
        <Route path="changepassword" element={<AdminChangePass />} />
        <Route path="changepassword/success" element={<AdminChangePassSuccess />} />
        <Route path="forgetpass" element={<AdminForgetPass />} />
        <Route path="forgetpass/success" element={<AdminForgetPassSuccess />} />
        <Route
          element={
            <ProtectedAdminRoutes user={user} token={token}>
              <Dashboard user={user} />
            </ProtectedAdminRoutes>
          }>
          <Route
            element={
              <RequireAuth
                allowedRoles={[adminRoles.superadmin, adminRoles.admin, adminRoles.billing]}
              />
            }>
            <Route path="main" element={<MainAdmin />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[adminRoles.superadmin, adminRoles.admin]} />}>
            <Route path="log" element={<LogEmailAdmin />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[adminRoles.superadmin, adminRoles.admin]} />}>
            <Route path="suppression" element={<SuppressionListAdmin />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[adminRoles.superadmin, adminRoles.admin]} />}>
            <Route path="report" element={<ReportEmailAdmin />} />
          </Route>
          <Route
            element={<RequireAuth allowedRoles={[adminRoles.superadmin, adminRoles.billing]} />}>
            <Route path="billing" element={<BillingData />} />
          </Route>
          <Route
            element={
              <RequireAuth
                allowedRoles={[adminRoles.superadmin, adminRoles.admin, adminRoles.billing]}
              />
            }>
            <Route path="package" element={<KelolaPaket />} />
            <Route path="package/edit" element={<PackageActions />} />
            <Route path="package/view" element={<PackageActions />} />
            <Route path="package/add" element={<PackageActions />} />
          </Route>
          <Route
            element={<RequireAuth allowedRoles={[adminRoles.superadmin, adminRoles.billing]} />}>
            <Route path="report-billing" element={<ReportBilling />} />
            <Route path="report-billing/detail/:id" element={<DetailReportBilling />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[adminRoles.superadmin, adminRoles.admin]} />}>
            <Route path="pelanggan" element={<DaftarPelanggan />} />
            <Route path="pelanggan/add" element={<PelangganCreate />} />
            <Route path="pelanggan/edit" element={<EditPelanggan />} />
          </Route>
          <Route
            element={
              <RequireAuth
                allowedRoles={[adminRoles.superadmin, adminRoles.admin, adminRoles.billing]}
              />
            }>
            <Route path="admin" element={<KelolaAdmin />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[adminRoles.superadmin, adminRoles.admin]} />}>
            <Route path="dokumentasi" element={<KelolaDokumentasi />} />
            <Route path="dokumentasi/edit" element={<EditDocumentation />} />
            <Route path="dokumentasi/preview" element={<PreviewDocumentation />} />
          </Route>
          <Route
            element={
              <RequireAuth
                allowedRoles={[adminRoles.superadmin, adminRoles.admin, adminRoles.billing]}
              />
            }>
            <Route path="profile" element={<ProfileAdmin />} />
          </Route>
          <Route element={<RequireAuth allowedRoles={[adminRoles.superadmin, adminRoles.admin]} />}>
            <Route path="manage-notification" element={<ManageNotification />} />
            <Route path="manage-notification/add" element={<CreateNotification />} />
            <Route path="manage-notification/view" element={<ViewNotification />} />
            <Route path="manage-notification/edit" element={<EditNotification />} />
          </Route>
        </Route>
      </Route>

      <Route path="checkout" element={<CheckLoginCheckout />}>
        <Route index element={<CheckoutNew />} />
        <Route path="process" element={<CheckoutProccess />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>

      <Route path="overusage/process" element={<OverQoutaProcess />} />
      <Route path="overusage/failed" element={<OverQoutaFailed />} />
      <Route path="overusage/success" element={<OverQoutaSuccess />} />

      {lastRoute.map(({ path, element }, i) => {
        return <Route path={path} element={element} key={path} />;
      })}
    </Routes>
  );
};

const lastRoute = [
  { path: "tfa", element: <TFALogin /> },
  { path: "expired", element: <ExpiredToken /> },
  { path: "logintoclient", element: <WaitPageClientLogin /> },
  { path: "*", element: <NotFoundPage /> },
  { path: "confirm-email", element: <ConfirmEmail /> },
  { path: "npwp-image", element: <DisplayImage /> },
  { path: "media", element: <DisplayImage /> },
];

export const UserRoutes = [
  { path: "main", element: <MainDashboard /> },
  { path: "setup", element: <SetupLayanan /> },
  { path: "email-api", element: <EmailAPI /> },
  { path: "log", element: <LogEmail /> },
  { path: "report", element: <ReportEmail /> },
  { path: "suppression", element: <SuppressionList /> },
  { path: "billing", element: <Billing /> },
  { path: "billing/confirmation", element: <BillingConfirmation /> },
  { path: "documentation", element: <Dokumentasi /> },
  { path: "profile", element: <Profile /> },
  { path: "notification", element: <Notification /> },
  { path: "notification/detail/:name", element: <NotificationDetails /> },
];

export const AdminRoutes = [
  { path: "main", element: <MainAdmin /> },
  { path: "log", element: <LogEmailAdmin /> },
  { path: "suppression", element: <SuppressionListAdmin /> },
  { path: "report", element: <ReportEmailAdmin /> },
  { path: "billing", element: <BillingData /> },
  { path: "package", element: <KelolaPaket /> },
  // { path: "notification", element: <Notification /> },
  { path: "package/edit", element: <PackageActions /> },
  { path: "package/view", element: <PackageActions /> },
  { path: "package/add", element: <PackageActions /> },
  { path: "report-billing", element: <ReportBilling /> },
  { path: "report-billing/detail/:id", element: <DetailReportBilling /> },
  { path: "pelanggan", element: <DaftarPelanggan /> },
  { path: "pelanggan/add", element: <PelangganCreate /> },
  { path: "pelanggan/edit", element: <EditPelanggan /> },
  { path: "admin", element: <KelolaAdmin /> },
  { path: "dokumentasi", element: <KelolaDokumentasi /> },
  { path: "dokumentasi/edit", element: <EditDocumentation /> },
  { path: "dokumentasi/preview", element: <PreviewDocumentation /> },
  { path: "profile", element: <ProfileAdmin /> },
  { path: "manage-notification", element: <ManageNotification /> },
  { path: "manage-notification/add", element: <CreateNotification /> },
  { path: "manage-notification/view", element: <ViewNotification /> },
  { path: "manage-notification/edit", element: <EditNotification /> },
];

export default Routing;
