import { Modal } from "antd";
import { ICExclamationCircleOutlined } from "components/list/ListIcon";

const { confirm } = Modal;

const ModalDeleteNotification = (props) => {
  const {
    okText = "Ya, Hapus",
    okButtonProps,
    customMessage,
    message,
    cancelText = "Batalkan",
  } = props;
  const messageModal = customMessage || <p className="mb-0">{message}</p>;
  return confirm({
    ...props,
    icon: <ICExclamationCircleOutlined style={{ color: "#FAAD14" }} />,
    content: messageModal,
    centered: true,
    okText: okText,
    okButtonProps: {
      ...okButtonProps,
      className: "ant-btn-danger",
    },
    autoFocusButton: null,
    cancelText: cancelText,
    width: "600px",
  });
};

export default ModalDeleteNotification;
