import { ConfigProvider, Table, Input, Select, Badge, Skeleton } from "antd";
import { EmptySuppress, SearchNotFound, LoadFailed } from "components/empty/EmptyComponent";
import { useEffect, useState } from "react";
import "./suppress.less";
import { TitleTableHistoryAdmin } from "components/tableSendiri/TitleTable";
import { splitDate } from "utils/helpers";
import { ICUserOutlined, ICFlagOutlined } from "components/list/ListIcon";

const { Option } = Select;
const { Search } = Input;

const TableHistory = ({
  dataHistoryAdmin,
  filterHistoryAdmin,
  showSkeletonHistory,
  setfilterHistoryAdmin,
  ListCompany,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(filterHistoryAdmin.page);
  const [currentLimit, setCurrentLimit] = useState("10");
  const [selectedCompany, setSelectedCompany] = useState("");
  const breakpoint = 1200;
  const breakpoint_third = 768;
  const [searchValue, setSearchValue] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState(ListCompany);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const isDataAvailable = dataHistoryAdmin.data?.count > 0;
  const isDataNotFound = (selectedCompany !== "" || searchQuery !== "") && !isDataAvailable;
  const isDataLoadFailed = dataHistoryAdmin.code === 500;

  const columnHistory = [
    {
      title: "Date",
      dataIndex: "timestamp",
      key: "Date",
      width: 120,
      render: (data) => {
        const { date, time } = splitDate(data);
        return (
          <div className="flex flex-col">
            <p className="mb-0">{date}</p>
            <p className="mb-0 text-secondary text-xs" type="secondary">
              {time}
            </p>
          </div>
        );
      },
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "Email Address",
      ellipsis: {
        showTitle: true,
        minWidth: 240,
      },
    },
    {
      title: "Category",
      dataIndex: "actios",
      key: "Category",
      className: "category-column",
      width: 150,
      render: (category) => (
        <Badge
          color={
            category === "Deletion" ? "#FF4D4F" : category === "Whitelist" ? "#0D9F3F" : "#FADB14"
          }
          text={category}
        />
      ),
    },
    {
      title: "Owner",
      dataIndex: "company",
      key: "Owner",
      ellipsis: {
        showTitle: true,
        minWidth: 220,
      },
      render: (owner, rowData) =>
        owner !== "Global Suppression" ? (
          <span>
            <ICUserOutlined style={{ marginRight: 4 }} />
            {owner}
          </span>
        ) : (
          <span>
            <ICFlagOutlined style={{ marginRight: 4 }} />
            {owner}
          </span>
        ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "Description",
      ellipsis: true,
    },
  ];

  const changePage = (page) => {
    setCurrentPage(page);
    const updatedFilter = setfilterHistoryAdmin({
      ...filterHistoryAdmin,
      page,
    });
    currentPage(updatedFilter);
  };

  const changePageLimit = (value) => {
    setCurrentLimit(value);
    setCurrentPage(1);
    const updatedFilter = setfilterHistoryAdmin({
      ...filterHistoryAdmin,
      page: 1,
      limit: value,
    });
    currentLimit(updatedFilter);
  };

  const changeSearchQuery = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
    const updatedFilter = setfilterHistoryAdmin({
      ...filterHistoryAdmin,
      page: 1,
      email: query,
    });
    searchQuery(updatedFilter);
  };

  const changeSearchCompany = (value) => {
    setSelectedCompany(value);
    setCurrentPage(1);
    let updatedFilterCompany = { ...filterHistoryAdmin };
    if (value) {
      updatedFilterCompany = {
        ...filterHistoryAdmin,
        page: 1,
        companyUuid: value,
      };
    } else {
      updatedFilterCompany = {
        ...filterHistoryAdmin,
        page: 1,
        companyUuid: [],
      };
    }
    setfilterHistoryAdmin(updatedFilterCompany);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    const filteredList = ListCompany.filter((company) =>
      company.company_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCompanies(filteredList);
  };

  const SearchEmail = ({ onSearch, initialValue = "" }) => {
    const [query, setQuery] = useState(initialValue);

    const handleSearch = () => {
      onSearch(query);
    };
    return (
      <Search
        placeholder="Ketik penerima"
        value={query}
        enterButton
        onChange={(e) => setQuery(e.target.value)}
        onSearch={handleSearch}
        {...(isDataLoadFailed
          ? { disabled: isDataLoadFailed }
          : isDataNotFound
          ? { disabled: false }
          : isDataAvailable
          ? { disabled: false }
          : { disabled: true })}
      />
    );
  };

  const PageLimitHistory = () => {
    return (
      <Select
        className="pageLimit__selector"
        value={currentLimit}
        onChange={changePageLimit}
        {...(isDataLoadFailed
          ? { disabled: isDataLoadFailed }
          : isDataNotFound
          ? { disabled: true }
          : isDataAvailable
          ? { disabled: false }
          : { disabled: true })}>
        <Option value="10">10 per page</Option>
        <Option value="25">25 per page</Option>
        <Option value="50">50 per page</Option>
        <Option value="100">100 per page</Option>
      </Select>
    );
  };

  return (
    <ConfigProvider
      renderEmpty={() => {
        if (isDataLoadFailed) {
          return <LoadFailed />;
        } else if (isDataNotFound) {
          return <SearchNotFound />;
        } else {
          return <EmptySuppress />;
        }
      }}>
      <div className="suppress-admin">
        <div className="flex flex-col">
          <TitleTableHistoryAdmin
            widthHistory={width}
            SearchEmail={SearchEmail}
            PageLimitHistory={PageLimitHistory}
            breakpoint_history={breakpoint}
            breakpoint_third_history={breakpoint_third}
            History_data={dataHistoryAdmin}
            searchQuery={searchQuery}
            changeSearchQuery={changeSearchQuery}
            changeSearchCompany={changeSearchCompany}
            selectedCompany={selectedCompany}
            isDataLoadFailed={isDataLoadFailed}
            isDataNotFound={isDataNotFound}
            isDataAvailable={isDataAvailable}
            searchValue={searchValue}
            filteredCompanies={filteredCompanies}
            handleSearch={handleSearch}
            ListCompany={ListCompany}
          />
          {showSkeletonHistory ? (
            <Skeleton active />
          ) : (
            <Table
              className="table_history"
              {...(width < breakpoint ? { scroll: { x: 1024 } } : {})}
              columns={columnHistory}
              dataSource={dataHistoryAdmin.data?.rows}
              pageLimit={{
                currentLimit: setfilterHistoryAdmin.limit,
                onSelectLimit: (limit) => {
                  setfilterHistoryAdmin({ ...filterHistoryAdmin, page: 1, limit });
                },
                disable: dataHistoryAdmin.data?.count < 10,
              }}
              pagination={{
                pageSize: currentLimit,
                current: currentPage,
                total: dataHistoryAdmin.data?.count,
                showSizeChanger: false,
                size: "small",
                onChange: changePage,
              }}
            />
          )}
        </div>
      </div>
    </ConfigProvider>
  );
};

export default TableHistory;
