import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Descriptions, Drawer, Tag, Tooltip } from "antd";
import {
  ICCloseCircleFilled,
  ICCloseOutlined,
  ICDownloadOutlined,
  ICExclamationCircleFilled,
  ICTagOutlined,
} from "components/list/ListIcon";

const convertNumber = (number = 0) => {
  if (typeof number !== "number") return "data-number-invalid";
  return number.toLocaleString("en-US");
};

const renderCount = (count = 0, email) => {
  const dataUnavailable = <i className="text-secondary">Data Belum Tersedia</i>;
  const dataAvailable = (
    <p className="m-0">
      <span className="text-info">{email}</span> ({convertNumber(count)} email)
    </p>
  );
  return count > 0 ? dataAvailable : dataUnavailable;
};

const RenderAdmin = ({ data }) => {
  return (
    <Descriptions
      layout="horizontal"
      column={1}
      bordered
      className="drawer-desc-wrapper"
      labelStyle={{ padding: "4px 12px" }}
      contentStyle={{ padding: "13px 12px" }}>
      <Descriptions.Item label="Perusahaan">{data.company_name}</Descriptions.Item>
      <Descriptions.Item label="Langganan">
        <Tag color={data.subscription_status === "active" ? "blue" : "red"}>
          {data.subscription_status === "active" ? "Active" : "Inactive"}
        </Tag>
      </Descriptions.Item>
      <Descriptions.Item label="Paket Layanan">
        {data.subscription_package === "-" ? (
          "-"
        ) : (
          <Tag icon={<ICTagOutlined />} className="m-0">
            {data.subscription_package}
          </Tag>
        )}
      </Descriptions.Item>
      <Descriptions.Item label="Tanggal Bergabung">
        {data.company_started} ({data.differentDate})
      </Descriptions.Item>
      <Descriptions.Item label="Domain Terdaftar">
        {data.domains ? data.domains.join(", ") : false}
      </Descriptions.Item>
      <Descriptions.Item label="Mail Usage">
        {convertNumber(data.current_total_sent)} / {convertNumber(data.current_quota)} email
      </Descriptions.Item>
      <Descriptions.Item label="Email Terkirim">
        <span className="text-success">{convertNumber(data.current_total_delivered)} email</span>
      </Descriptions.Item>
      <Descriptions.Item label="Email Gagal">
        <span className="text-danger">{convertNumber(data.current_total_failed)} email</span>
      </Descriptions.Item>
      <Descriptions.Item label="Total Email Terkirim">
        <span className="text-success">
          {convertNumber(data.total_delivered)} email ({data.total_delivered_percentage}%)
        </span>
      </Descriptions.Item>
      <Descriptions.Item label="Total Email Gagal">
        <span className="text-danger">
          {convertNumber(data.total_failed)} email ({data.total_failed_percentage}%)
        </span>
      </Descriptions.Item>
      <Descriptions.Item label="Total Seluruh Email">
        {convertNumber(data.total_sent)} email
      </Descriptions.Item>
    </Descriptions>
  );
};

const DownloadCSV = ({ data, totalDays }) => {
  const handleDownload = () => {
    window.open(data.downloadUrl, "_blank");
  };

  return (
    <Button
      disabled={!data.downloadUrl || totalDays === 1 || totalDays > 8}
      type="primary"
      icon={<ICDownloadOutlined />}
      onClick={handleDownload}>
      Download CSV
    </Button>
  );
};

const AlertReport = ({ url, totalDays }) => {
  let alert = "";

  if (totalDays === 1) {
    alert = {
      type: "info",
      message: "Data baru tersedia setelah 24 jam di tanggal tersebut",
    };
  } else if (totalDays > 8) {
    alert = {
      type: "info",
      message: "Report sudah tidak tersedia karena sudah melewati 7 hari",
    };
  } else if (!url && totalDays <= 8 && totalDays > 1) {
    alert = {
      type: "error",
      message: "Terdapat masalah pada file report, silakan hubungi Customer Care kami",
    };
  }

  if (alert) {
    return (
      <div
        data-show="true"
        className={`ant-alert ant-alert-${alert.type} ant-alert-banner report-email mb-4`}
        role="alert">
        {alert.type === "info" ? (
          <ICExclamationCircleFilled className="ant-alert-icon report-email" />
        ) : (
          <ICCloseCircleFilled className="ant-alert-icon report-email" />
        )}
        <div className="ant-alert-content">
          <div className="ant-alert-message">{alert.message}</div>
        </div>
      </div>
    );
  }
  return "";
};

const RenderUser = ({ data }) => {
  const today = new Date();
  const dateCsv = new Date(data?.date);
  let difference = today.getTime() - dateCsv.getTime();
  const totalDays = Math.ceil(difference / (1000 * 3600 * 24));

  const labelReport = (
    <div>
      <span className="pr-1">Full Report</span>
      <Tooltip
        overlayClassName="tool-full-report w-full"
        trigger={["click"]}
        placement="right"
        title={<span className="w-full">Full report hanya tersedia 7 hari kebelakang</span>}>
        <InfoCircleOutlined className="text-secondary pl-1" />
      </Tooltip>
    </div>
  );

  return (
    <>
      <Descriptions
        layout="horizontal"
        column={1}
        bordered
        className="drawer-desc-wrapper"
        labelStyle={{ padding: "0 12px" }}
        contentStyle={{ padding: "13px 12px" }}>
        <Descriptions.Item label="Tanggal">{data.date}</Descriptions.Item>
        <Descriptions.Item label="Domain">{data.domainName}</Descriptions.Item>
        <Descriptions.Item label="Email Terkirim" className="text-success">
          {convertNumber(data.countDelivered)} email
        </Descriptions.Item>
        <Descriptions.Item label="Email Gagal" className="text-danger">
          {convertNumber(data.countFailed)} email
        </Descriptions.Item>
        <Descriptions.Item label="Total Email">
          {convertNumber(data.countSent)} email
        </Descriptions.Item>
        <Descriptions.Item label="Email Pengirim Terbanyak">
          {renderCount(data.countMostSender, data.mostSender)}
        </Descriptions.Item>
        <Descriptions.Item label="Email Penerima Terbanyak">
          {renderCount(data.countMostEmailReceiver, data.mostEmailReceiver)}
        </Descriptions.Item>
        <Descriptions.Item label="Penerima Terbanyak">
          {renderCount(data.countMostDomainReceiver, data.mostDomainReceiver)}
        </Descriptions.Item>
        <Descriptions.Item label={labelReport}>
          <DownloadCSV data={data} totalDays={totalDays} />
        </Descriptions.Item>
      </Descriptions>
      <AlertReport url={data.downloadUrl} totalDays={totalDays} />
    </>
  );
};

const DrawerReport = ({ onClose, visible, data = false, available, isAdmin }) => {
  const RenderContent = isAdmin ? <RenderAdmin data={data} /> : <RenderUser data={data} />;

  return (
    <Drawer
      closable={false}
      className="drawer-log-detail title-report-email"
      title="Delivery Report Details"
      placement="right"
      onClose={onClose}
      size="large"
      open={visible}
      width={736}
      extra={
        <Button icon={<ICCloseOutlined />} type="text" onClick={onClose} className="text-success">
          Close
        </Button>
      }>
      <div className="main-drawer">{RenderContent}</div>
    </Drawer>
  );
};

export default DrawerReport;
