import { createSlice } from "@reduxjs/toolkit";

export const fakturCustomer = createSlice({
  name: "fakturCustomer",
  initialState: {
    value: false,
  },
  reducers: {
    setFakturCustomer: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setFakturCustomer } = fakturCustomer.actions;
export default fakturCustomer.reducer;
