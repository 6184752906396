import { Button, Form, Input, Modal, Tag, Tooltip } from "antd";
import { changeNotification, deleteDomain } from "api";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setListDomain } from "redux/slices/listDomain";
import { gatherFormData, requiredRules, runFunction } from "utils/helpers";
import {
  ICBellOutlined,
  ICCheckCircleOutlined,
  ICDeleteOutlined,
  ICExclamationCircleOutlined,
} from "./ListIcon";

const ListDomains = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const {
    status,
    spf,
    dkim,
    dmarc,
    aRecord,
    notification,
    domainName,
    domainUuid,
    statusRelay,
    getOthers,
    user,
  } = props;
  const { hActivationDomain, page } = props;
  const listDomain = useSelector(({ listDomain }) => listDomain.value);
  if (!props.domainName) return "";

  const handleModal = () => setVisible(true);

  const BtnActivation = () => {
    if (page === "profile") return "";
    const isRender = status === "inactive" && statusRelay !== "closed";
    return (
      isRender && (
        <Button
          icon={<ICCheckCircleOutlined />}
          disabled={!spf.status || !dkim.status || !dmarc.status || !aRecord.status}
          className={spf.status && dkim.status && dmarc.status && aRecord.status && "blob"}
          type="primary"
          onClick={() => hActivationDomain(props)}>
          Aktivasi
        </Button>
      )
    );
  };

  const handleType = async ({ domainUuid, notification }) => {
    const updateNotification = listDomain.map((x) => {
      if (x.domainUuid === domainUuid) {
        return { ...x, notification: !x.notification };
      }
      return x;
    });
    dispatch(setListDomain(updateNotification));
    await changeNotification({ domainUuid, notifikasi: !notification });
  };

  const TagRender = () => {
    if (page === "profile") return "";

    return (
      <Tag color={status === "inactive" ? "red" : "blue"} className="m-0">
        {status}
      </Tag>
    );
  };

  return (
    <>
      <div className="flex items-center justify-between list-domain-wrapper">
        <div className="flex items-center gap-x-2 left-domain">
          <p className={`mb-0 ${page === "profile" ? "font-medium" : "font-normal"}`}>
            {domainName}
          </p>
          <TagRender />
        </div>
        <div className="flex gap-x-2 right-domain items-center">
          <BtnActivation />
          <Tooltip title={notification ? "Notifikasi aktif" : "Notifikasi tidak aktif"}>
            <Button
              type={notification ? "primary" : "default"}
              className="w-12"
              icon={<ICBellOutlined />}
              disabled={user.role === "billing"}
              onClick={() => handleType({ domainUuid, notification })}
            />
          </Tooltip>
          <Tooltip title="Hapus Domain">
            <Button
              danger
              icon={<ICDeleteOutlined />}
              disabled={user.role === "billing"}
              className="w-12"
              onClick={handleModal}
            />
          </Tooltip>
        </div>
      </div>
      <Modal
        open={visible}
        children={
          <div className="ant-modal-confirm-body-wrapper">
            <div className="ant-modal-confirm-body">
              <ICExclamationCircleOutlined className="text-warning" />
              <span className="ant-modal-confirm-title">Hapus Domain Terdaftar</span>
              <div className="ant-modal-confirm-content">
                <p className="mb-4">
                  Apakah anda yakin ingin menghapus domain
                  <span className="font-medium"> {domainName}</span> dari domain terdaftar? Setelah
                  menghapus domain, seluruh kiriman email dari domain tersebut
                  <span className="font-medium"> tidak dapat</span> melewati jalur{" "}
                  {process.env.REACT_APP_NAME}.
                </p>
                <Form layout="vertical" form={form} name="hapusDomain">
                  <Form.Item
                    label="Ketik nama domain anda untuk konfirmasi hapus"
                    name="domain"
                    validateFirst
                    rules={[
                      requiredRules,
                      {
                        validator(_, value) {
                          if (value === domainName) return Promise.resolve();
                          return Promise.reject(new Error("Nama domain tidak sama!"));
                        },
                      },
                    ]}>
                    <Input placeholder="company.com" />
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        }
        onCancel={() => {
          form.resetFields();
          setVisible(false);
        }}
        onOk={() => {
          const callback = async () => {
            setLoading(true);
            const { msg, status } = await deleteDomain({ domainUuid });
            setLoading(false);
            if (msg.includes("deleted") && status === "success") {
              form.resetFields();
              ModalSuccess({
                title: `Domain ${domainName} berhasil dihapus`,
                message: `Domain ${domainName} sudah berhasil dihapus pada sistem`,
              });
              const updateDomain = listDomain.filter((x) => x.domainUuid !== domainUuid);
              dispatch(setListDomain(updateDomain));
              runFunction(() => getOthers(false, "domainSection"));
            }
            setLoading(false);
            setVisible(false);
          };
          gatherFormData(form, callback);
        }}
        okButtonProps={{
          htmlType: "submit",
          danger: true,
          loading,
        }}
      />
    </>
  );
};

export default ListDomains;
