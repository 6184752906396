import { Button } from "antd";
import { ICCheckCircleFilled, ICCloseCircleFilled } from "components/list/ListIcon";
import Support from "./Support";

const VerifikasiSelesai = ({ fillDomain }) => {
  const aksesDashboard = () => (window.location.href = "/main");
  const fillCompanyData = () => (window.location.href = "/register/company-data");
  const checkout = () => (window.location.href = "/checkout");

  const isFillDomain = {
    text: (
      <>
        <h3 className="font-medium text-2xl m-0">Aktivasi Trial Sukses</h3>
        <p className="text-secondary text-center m-0">
          Selamat, akun trial Anda berhasil terdaftar dan diaktivasi.
        </p>
        <p className="text-secondary text-center m-0">
          Untuk dapat mulai menggunakan layanan, silakan dapat mengisi data perusahaan pada tahap
          berikutnya.
        </p>
      </>
    ),
    btn: (
      <Button className="btn-setup" type="primary" onClick={fillCompanyData}>
        Mulai Lengkapi Data
      </Button>
    ),
  };

  const isSetupSoon = {
    text: (
      <>
        <h3 className="font-medium text-2xl m-0">Pendaftaran Akun Selesai</h3>
        <p className="text-secondary text-center m-0">
          Selamat, akun Anda berhasil terdaftar. Untuk dapat mulai menggunakan trial layanan, anda
          harus melakukan setup domain dan melengkapi data perusahaan setelah anda siap.
        </p>
      </>
    ),
    btn: (
      <>
        <Button className="btn-setup" type="primary" onClick={aksesDashboard}>
          Akses Dashboard
        </Button>
        <Button type="link" className="btn-setup text-success" onClick={fillCompanyData}>
          Lengkapi Data Perusahaan
        </Button>
      </>
    ),
  };

  const isUsedTrial = {
    text: (
      <>
        <h3 className="font-medium text-2xl m-0">Aktivasi Trial Gagal</h3>
        <p className="text-secondary text-center m-0">
          Domain yang anda gunakan sudah pernah menggunakan trial sebelumnya. Untuk dapat
          menggunakan layanan, anda dapat langsung berlangganan dengan menggunakan domain tersebut.
        </p>
      </>
    ),
    btn: (
      <>
        <Button className="btn-setup" type="primary" onClick={checkout}>
          Pilih Paket Layanan
        </Button>
        <Button type="link" className="btn-setup text-success" onClick={aksesDashboard}>
          Akses Dashboard
        </Button>
      </>
    ),
  };

  const render =
    fillDomain === "success" ? isFillDomain : fillDomain === "soon" ? isSetupSoon : isUsedTrial;

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col justify-center items-center setup-form mx-auto mt-12">
          <div className="container-icon-cp mb-6">
            {fillDomain === "failed" ? (
              <ICCloseCircleFilled className="check-icon text-danger" />
            ) : (
              <ICCheckCircleFilled className="check-icon green-6" />
            )}
          </div>
          {render.text}
        </div>
      </div>
      <div className="flex flex-col mx-auto text-center items-center gap-y-2 my-8">
        {render.btn}
      </div>
      <Support />
    </>
  );
};

export default VerifikasiSelesai;
