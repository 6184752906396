import { Button, Checkbox, DatePicker, Divider, Form, Input, Select, Tabs } from "antd";
import TextArea from "antd/lib/input/TextArea";
import DashboardPageHeader from "components/dashboard-header/DashboardPageHeader";
import MarkdownInput from "components/input/MarkdownInput";
import {
  ICCloseOutlined,
  ICEditOutlined,
  ICEyeOutlined,
  ICPlusOutlined,
  ICSaveOutlined,
} from "components/list/ListIcon";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { requiredRules } from "utils/helpers";
import "./kelolaDokumentasi.less";

const EditDocumentation = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  if (!state) navigate("/admin/dokumentasi");
  const { subject, body, status, services, category } = state;
  const [documentationBody, setDocumentationBody] = useState(body);
  const valueFormOnChange = (_, { body }) => {
    setDocumentationBody(body);
  };
  const onSelectStatus = (value) => console.log(value);
  return (
    <div className="documentationAdmin__edit">
      <DashboardPageHeader title="Edit Dokumentasi" />
      <div className="flex gap-x-6 px-6 py-4">
        <div className="documentationAdmin__left">
          <Form
            className="w-full"
            initialValues={{ subject: subject, body }}
            onValuesChange={valueFormOnChange}>
            <Form.Item name="subject" rules={[requiredRules]}>
              <Input placeholder="Subject" />
            </Form.Item>
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  label: (
                    <span>
                      <ICEditOutlined />
                      Edit Dokumentasi
                    </span>
                  ),
                  key: "edit",
                  children: (
                    <Form.Item name="body" className="mb-4">
                      <TextArea autoSize={{ minRows: 22, maxRows: 22 }} />
                    </Form.Item>
                  ),
                },
                {
                  label: (
                    <span>
                      <ICEyeOutlined />
                      Pratinjau
                    </span>
                  ),
                  key: "pratinjau",
                  children: <MarkdownInput data={documentationBody} />,
                },
              ]}
            />
          </Form>
        </div>
        <div className="documentationAdmin__right">
          <div className="documentationAdmin__action bg-white p-6">
            <div className="flex gap-x-2 items-center my-1">
              <Button type="default" className="w-full" icon={<ICCloseOutlined />}>
                Batalkan
              </Button>
              <Button type="primary" className="w-full" icon={<ICSaveOutlined />}>
                Simpan
              </Button>
            </div>
            <Divider className="my-4" />
            <div className="flex items-center justify-between mb-3">
              <span className="font-medium">Status</span>
              <Select
                placeholder="Pilih"
                defaultValue={status}
                className="status-list"
                onSelect={onSelectStatus}>
                <Select.Option value="publish">Publish</Select.Option>
                <Select.Option value="draft">Draft</Select.Option>
              </Select>
            </div>
            <div className="flex items-center justify-between">
              <span className="font-medium">Publish Date</span>
              <DatePicker />
            </div>
          </div>
          <div className="documentationAdmin__services bg-white p-6">
            <h5 className="mb-0 text-base font-medium">Services</h5>
            <Divider className="my-4 mt-1" />
            <div className="checkgroup__wrapper">
              <Checkbox.Group
                className="flex flex-col gap-y-2 p-2 checkgroup"
                defaultValue={services}>
                <Checkbox value="SMTP Relay">SMTP Relay</Checkbox>
                <Checkbox value="SSL Certificate">SSL Certificate</Checkbox>
                <Checkbox value="Mail Campaign">Mail Campaign</Checkbox>
                <Checkbox value="Self-service Password Management">
                  Self-service Password Management
                </Checkbox>
                <Checkbox value="a">a</Checkbox>
                <Checkbox value="a">a</Checkbox>
                <Checkbox value="a">a</Checkbox>
                <Checkbox value="a">a</Checkbox>
                <Checkbox value="a">a</Checkbox>
                <Checkbox value="a">a</Checkbox>
              </Checkbox.Group>
              <Button icon={<ICPlusOutlined />} type="link">
                Tambah Service
              </Button>
            </div>
          </div>
          <div className="documentationAdmin__category bg-white p-6">
            <h5 className="mb-0 text-base font-medium">Kategori Dokumentasi</h5>
            <Divider className="my-4 mt-1" />
            <div className="checkgroup__wrapper">
              <Checkbox.Group
                className="flex flex-col gap-y-2 p-2 checkgroup"
                defaultValue={category}>
                <Checkbox value="panduan">Panduan</Checkbox>
                <Checkbox value="tips">Tips</Checkbox>
              </Checkbox.Group>
              <Button icon={<ICPlusOutlined />} type="link">
                Tambah Service
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditDocumentation;
