import { Button, Card, Checkbox, Col, Divider, Form, Modal, Row, Space, Upload } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ICImportOutlined, ICPlusOutlined, ICUploadOutlined } from "components/list/ListIcon";

const ExtraSection = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const props = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onchange({ file, fileList }) {
      console.log(file, fileList);
    },
  };

  return (
    <div className="flex justify-between items-center">
      <div className="btn-group-log">
        <Space>
          <Button icon={<ICImportOutlined />} onClick={() => setShowModal(true)}>
            <span className="hidden-xs">Import Client</span>
          </Button>
          <Modal
            title="Bulk Import Client"
            open={showModal}
            onCancel={() => setShowModal(false)}
            okText="Simpan"
            okButtonProps={{ disabled: true }}
            centered
            cancelText="Batal"
            width={572}>
            <Form layout="vertical">
              <Form.Item
                name="Import Client"
                label="Upload file list client (.csv)"
                className="mb-2">
                <Upload {...props}>
                  <Button icon={<ICUploadOutlined />}>Click to Upload</Button>
                </Upload>
              </Form.Item>
              <Form.Item className="mb-0">
                <p className="text-info">Download template import csv</p>
              </Form.Item>
              <Divider className="m-0 mb-2" />
              <Form.Item className="mb-0" name="notificationEmailClient" valuePropName="checked">
                <Checkbox>Kirim email notifikasi ke client</Checkbox>
              </Form.Item>
              <Form.Item name="trialAccount" valuePropName="checked">
                <Checkbox>Set trial selama 7 hari</Checkbox>
              </Form.Item>
            </Form>
          </Modal>
          <Button type="primary" icon={<ICPlusOutlined />} onClick={() => navigate("add")}>
            <span className="hidden-xs">Tambah Client</span>
          </Button>
        </Space>
      </div>
    </div>
  );
};

const CustomerSummary = ({ loading }) => {
  const clientSummarys = useSelector(({ listAllClients }) => listAllClients.value);
  const clientSummary = { table: clientSummarys, loading };

  return (
    <Card
      title="Customer Summary"
      loading={clientSummary.loading}
      className="customer-summary-wrapper"
      extra={<ExtraSection />}>
      <Row gutter={[24, 0]}>
        <Col sm={12} xl={6}>
          <div className="px-6 py-4 bg-white">
            <h5 className="mb-0 text-3xl">{clientSummary.table?.total_customer}</h5>
            <p className="mb-0">Total Customers</p>
            <Divider className="my-2 mb-0" />
          </div>
        </Col>
        <Col sm={12} xl={6}>
          <div className="px-6 py-4 bg-white">
            <h5 className="mb-0 text-3xl">{clientSummary.table?.total_customer_active}</h5>
            <p className="mb-0 text-success">Active Customers</p>
            <Divider className="my-2 mb-0" />
          </div>
        </Col>
        <Col sm={12} xl={6}>
          <div className="px-6 py-4 bg-white">
            <h5 className="mb-0 text-3xl">{clientSummary.table?.total_customer_inactive}</h5>
            <p className="mb-0 text-danger">Inactive Customers</p>
            <Divider className="my-2 mb-0" />
          </div>
        </Col>
        <Col sm={12} xl={6}>
          <div className="px-6 py-4 bg-white">
            <h5 className="mb-0 text-3xl">{clientSummary.table?.total_active_contacts}</h5>
            <p className="mb-0 text-info">Active Contacts</p>
            <Divider className="my-2 mb-0" />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default CustomerSummary;
