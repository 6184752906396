import { Button, Checkbox, Form, Input, Modal } from "antd";
import { addContact, addContactClient, editContact } from "api";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { gatherFormData, requiredRules, runFunction } from "utils/helpers";
import ModalError from "./ModalError";
import ModalSuccess from "./ModalSuccess";
import ModalWarning from "./ModalWarning";

const ModalContactPelanggan = (props) => {
  const { visible, onCancel, action, data = false, sectionLoad, uuid } = props;
  const [form] = Form.useForm();
  const user = useSelector(({ userData }) => userData.value);
  const [loading, setLoading] = useState(false);
  const validateMessages = {
    required: "Harap diisi terlebih dahulu",
    types: {
      email: "Harap masukkan alamat email yang vaild",
    },
  };

  useEffect(() => {
    form.resetFields();
  }, [data, form]);

  const roleContact =
    data.contactRole === "primary"
      ? "primary"
      : data.contactRole === "techbill"
      ? ["technical", "billing"]
      : [data.contactRole];

  const title = action === "add" ? "Tambah Kontak Baru" : "Edit Kontak";

  const handleAdd = () => {
    const callback = async () => {
      setLoading(true);
      const values = form.getFieldsValue();
      const contactRole = values.contactRole.length > 1 ? "techbill" : values.contactRole[0];
      const { code, msg } = user.isAdmin
        ? await addContactClient({
            ...values,
            contactRole,
            companyUuid: uuid,
          })
        : await addContact({ ...values, contactRole });
      setLoading(false);
      if (msg.includes("already") && code === 400)
        ModalError({
          title: "Kontak gagal ditambahkan",
          message: "Email tersebut sudah digunakan, silahkan gunakan email lain.",
        });
      else if (msg.includes("Company contact reach the limit!") && code === 400)
        ModalWarning({
          title: "Gagal menambahkan kontak",
          message: (
            <p>
              Kontak gagal ditambahkan karena sudah mencapai limitasi maksimum sebanyak 5 kontak.
              <br />
              <br />
              Silakan dapat menghapus atau mengedit kontak yang sudah terdaftar.
            </p>
          ),
          onOk: () => {
            form.resetFields();
            onCancel();
          },
        });
      else
        ModalSuccess({
          title: "Kontak berhasil ditambahkan",
          message: "Silakan cek email kontak tersebut dan atur password untuk log in ke dashboard.",
          onOk: () => {
            form.resetFields();
            runFunction(sectionLoad);
            onCancel();
          },
        });
    };
    gatherFormData(form, callback);
  };

  const handleEdit = () => {
    const callback = async () => {
      setLoading(true);
      const values = form.getFieldsValue();
      const contactRole =
        data.contactRole === "primary"
          ? "primary"
          : values.contactRole.length > 1
          ? "techbill"
          : values.contactRole[0];
      const { code, msg } = await editContact({
        ...values,
        contactUuid: data.contactUuid,
        contactRole,
      });
      setLoading(false);
      if (msg.includes("already registered") && code === 400) {
        ModalError({
          title: "Alamat Email Sudah Terdaftar",
          message:
            "Alamat email yang dimasukkan sudah terdaftar pada sistem, silakan gunakan alamat email lain.",
        });
      } else if (msg.includes("Success") && code === 200) {
        onCancel();
        ModalSuccess({
          title: "Kontak berhasil diupdate",
          message:
            "Jika terdapat perubahan alamat email, silakan cek email tersebut untuk mengatur ulang password.",
          onOk: sectionLoad,
        });
      }
    };
    gatherFormData(form, callback);
  };

  const handleAction = action === "add" ? handleAdd : handleEdit;

  return (
    <Modal
      title={title}
      open={visible}
      centered
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel}>
          Batal
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleAction}>
          Simpan
        </Button>,
      ]}>
      <Form
        layout="vertical"
        name="newContact"
        form={form}
        initialValues={{
          name: data.contactName,
          email: data.contactEmail,
          phone: data.contactPhone,
          contactRole: action === "add" ? false : roleContact,
        }}
        validateMessages={validateMessages}>
        <Form.Item name="name" label="Nama Lengkap" className="mb-4" rules={[requiredRules]}>
          <Input placeholder="Nama Lengkap" />
        </Form.Item>
        <Form.Item
          name="email"
          label="Alamat Email"
          className="mb-4"
          rules={[requiredRules, { type: "email" }]}>
          <Input type="email" placeholder="nama@perusahaan.tld" />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Nomor Handphone"
          className="mb-3"
          rules={[requiredRules, { min: 8, message: "Harap masukkan nomor yang valid" }]}>
          <Input
            placeholder="0812XXXXXXXX"
            type="number"
            className="rmArrow"
            onWheel={(e) => e.target.blur()}
          />
        </Form.Item>
        <Form.Item
          name="contactRole"
          className="mb-1"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error("Harap pilih role kontak tersebut")),
            },
            { required: true, message: "Harap pilih role kontak tersebut" },
          ]}>
          <Checkbox.Group
            disabled={
              data.contactRole === "primary" ||
              (action === "edit" && user.role !== "primary" && !user.isAdmin)
                ? true
                : false
            }>
            <div className="flex flex-col gap-y-1">
              <Checkbox value="technical">Set sebagai kontak Technical</Checkbox>
              <Checkbox value="billing">Set sebagai kontak Billing</Checkbox>
            </div>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalContactPelanggan;
