import { Navigate, Outlet } from "react-router-dom";

const ProtectedUserRoutes = ({ user = "user", redirectPath = "/login", children, token }) => {
  let element = children ? children : <Outlet />;
  if (!token) {
    element = <Navigate to={redirectPath} replace />;
  } else if (user === "not-verified") {
    element = <Navigate to="/register/verifikasi" replace />;
  } else if (user) {
    if (user.isSetupDomain && user.isSetUpCompany && user.isSetup) element = children;
    else if (!user.isSetupDomain && !user.isSetUpCompany && !user.isSetup)
      element = <Navigate to="/register/verifikasi" replace />;
    else if (user.isSetupDomain && user.haveUsedTrial && user.isSetup && !user.isSetUpCompany)
      element = <Navigate to="/config" replace />;
    else if (user.isSetupDomain && !user.isSetUpCompany && user.isSetup)
      element = <Navigate to="/register/company-data" replace />;
  }

  return element;
};

export default ProtectedUserRoutes;
