import { Modal, Typography, Divider, Button, Tooltip } from "antd";
import { ICCopyOutlined } from "components/list/ListIcon";
import { handleCopyRekening } from "utils/helpers";

const { Text } = Typography;

export const ModalInfoPayment = ({ open, close }) => {
  const listsManual = [
    {
      src: "mandiri.svg",
      name: "Bank Mandiri a.n. Aktiva Kreasi Investama PT",
      rek: "156 002 1194 131",
      value: "1560021194131",
    },
    {
      src: "bca.svg",
      name: "Bank BCA a.n. Aktiva Kreasi Investama PT",
      rek: "066 3309479",
      value: "066309479",
    },
  ];

  const PaymentMethod = () => {
    return (
      <div className="pt-4">
        {listsManual.map(({ src, name, rek, value }) => (
          <>
            <div className="flex gap-x-5 mb-4">
              <img
                key={name}
                alt={name}
                src={`assets/images/banks/${src}`}
                title={name}
                className="logo-info"
              />
              <div className="flex justify-between items-center w-full">
                <div className="flex flex-col">
                  <Text className="font-medium text-base">{rek}</Text>
                  <Text className="font-medium text-xs text-secondary">{name}</Text>
                </div>
                <Tooltip placement="top" title={"Rekening telah disalin"} trigger={["click"]}>
                  <Button
                    size="small"
                    icon={<ICCopyOutlined />}
                    onClick={() => handleCopyRekening(value)}>
                    Salin
                  </Button>
                </Tooltip>
              </div>
            </div>
          </>
        ))}
        <Divider className="mt-0 mb-4" />
      </div>
    );
  };

  return (
    <Modal
      centered
      title={"Informasi Rekening"}
      open={open}
      onCancel={close}
      footer={[
        <Button key="cancel" onClick={close} type="default">
          Close
        </Button>,
      ]}>
      <Text>Harap melakukan pembayaran ke salah satu rekening berikut:</Text>
      <PaymentMethod />
      <div className="flex flex-col">
        <Text className="font-medium">Catatan</Text>
        <ol className="order-list">
          <li>
            <Text>
              Harap mencantumkan nomor invoice pada keterangan pembayaran, untuk memudahkan kami
              dalam melakukan verifikasi pembayaran
            </Text>
          </li>
          <li>
            <Text>
              Silakan melakukan konfirmasi pembayaran setelah melakukan pembayaran ke salah satu
              rekening diatas
            </Text>
          </li>
          <li>
            <Text>
              Verifikasi pembayaran akan dilakukan maksimal 2x24jam setelah proses konfirmasi
              pembayaran
            </Text>
          </li>
        </ol>
      </div>
    </Modal>
  );
};
