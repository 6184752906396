import { getDetailsCompanyData } from "api";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyData } from "redux/slices/companyData";

const useGetCompanyData = () => {
  const companyData = useSelector(({ companyData }) => companyData.value);

  const dispatch = useDispatch();
  const action = setCompanyData;

  const doSet = useCallback((data) => dispatch(action(data)), [dispatch, action]);

  useEffect(() => {
    const getCompanyData = async () => {
      const { data } = await getDetailsCompanyData();
      if (data && Object.keys(data).length) doSet(data);
    };
    if (!companyData) getCompanyData();
  }, [companyData, doSet]);
};

export default useGetCompanyData;
