import fetch from "@apicase/adapter-fetch";
import { ApiService, ApiTree } from "@apicase/services";
import { getCookie, getSessionToken, setCookieToken, setLogout } from "utils/helpers";
import apiList from "./list";

// VARIABLE LIST
// make sure match for your APP
const appBaseUrl = process.env.REACT_APP_BE_BASE_URL;
const appEnv = process.env.REACT_APP_ENVIRONMENT;

const urlGetToken = "api/token/get";
const urlRefreshToken = "api/v1/refresh";
// END OF VARIABLE LIST

const generateRandomString = (length) => {
  let text = "";
  const character = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i += 1)
    text += character.charAt(Math.floor(Math.random() * character.length));

  return text;
};

const getDeviceId = new Promise((resolve) => {
  const deviceId = generateRandomString(36);
  if (window.requestIdleCallback) requestIdleCallback(() => resolve(deviceId));
  else resolve(deviceId);
});

const serviceLogger = (event, result) => {
  if (appEnv === "watch-development") console.log("serviceLogger: ", { event }, { result });
  return null;
};
// END OF FUNCTION GROUP

const RootService = new ApiService({
  adapter: fetch,
  url: appBaseUrl,
  mode: "cors",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
  options: { timeout: 1000 },
  hooks: {
    before({ payload, next }) {
      payload.headers = {
        ...payload.headers,
      };
      next(payload);
    },
  },
});

// SERVICE LOGGER FOR API ACTIVITY & RESPONSE
RootService.on("done", (result) => serviceLogger("done", result));

RootService.on("fail", (result) => serviceLogger("fail", result));

RootService.on("finish", (result) => serviceLogger("finish", result));

RootService.on("start", (result) => serviceLogger("start", result));

RootService.on("cancel", (result) => serviceLogger("cancel", result));

RootService.on("error", (result) => serviceLogger("error", result));
// END of SERVICE LOGGER FOR API ACTIVITY & RESPONSE

const getCookieToken = () => {
  const inAdmin = window.location.href.includes("/admin");
  return getCookie(inAdmin ? "token" : "user_token");
  // return getCookie("token");
};

const getCookieRefreshToken = () => {
  const inAdmin = window.location.href.includes("/admin");
  return getCookie(inAdmin ? "refresh_token" : "user_refresh_token");
  // return getCookie("refresh_token");
};

// GET  TOKEN API & SERVICE
const TokenService = (url = urlGetToken) =>
  RootService.extend({
    url,
    method: "POST",
    body: {
      token: getCookieToken(),
      refresh_token: getCookieRefreshToken(),
    },

    hooks: {
      before({ payload, next }) {
        const token = getCookieRefreshToken();
        const newPayload = { ...payload };
        newPayload.headers = {
          ...payload.headers,
          Authorization: `Bearer ${token}`,
        };
        next(newPayload);
      },
    },
  }).on("done", (result) => {
    const { accessToken } = result.body.data;
    setCookieToken(accessToken);
  });
// END OF GET TOKEN API & SERVICE

// const GetToken = TokenService();
const RefreshToken = TokenService(urlRefreshToken);

//  HIT TOKEN ACTIVITY
const hitToken = async (payload, retry, next, urlToken = urlGetToken) => {
  let fn = RefreshToken;
  const { success, result } = await fn.doSingleRequest();
  if (success) {
    const { accessToken: tokenCode } = result.body.data;
    const newPayload = { ...payload };
    newPayload.headers = { ...payload.headers, Authorization: `Bearer ${tokenCode}` };
    retry(newPayload);
    // next(result);
  } else {
    failActivity(result.status, payload, retry, result, next);
  }
  // else if (result.body.msg.includes("malformed")) setLogout();
};
// END OF HIT TOKEN ACTIVITY

// 401 states
// additional activity
const do401 = async (payload, retry) => {
  let fn = RefreshToken;
  const { success, result } = await fn.doSingleRequest();
  if (success) {
    const { accessToken: tokenCode } = result.body.data;
    const newPayload = { ...payload };
    newPayload.headers = { ...payload.headers, Authorization: `Bearer ${tokenCode}` };
    retry(newPayload);
  }
};
// END OF 401 states

const do400 = (errorCode, payload) => {
  alert(JSON.stringify(payload));
};

// FAIL API ACTIVITY
const failActivity = async (errorCode, payload, retry, result, next) => {
  const inAdmin = window.location.href.includes("/admin");
  if (errorCode === 401) {
    await hitToken(payload, retry, next, urlRefreshToken);
  } else if (errorCode === 406) setLogout(inAdmin ? "admin" : "user");
};
// END OF FAIL API ACTIVITY

const MainService = new ApiTree(RootService, [
  {
    url: "api/v1",
    children: apiList,
    hooks: {
      before({ payload, next }) {
        const token = getSessionToken() || getCookieToken();
        const newPayload = { ...payload };
        newPayload.headers = {
          ...payload.headers,
          Authorization: `Bearer ${token}`,
        };
        next(newPayload);
      },
      async fail({ payload, retry, result, next }) {
        const errorCode = result.status;
        await failActivity(errorCode, payload, retry, result, next);
        next(result);
      },
      async done({ result, fail, next }) {
        next(result);
        return true;
      },
    },
  },
]);

export default MainService;
