import { CloseOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";

const DrawerDocumentation = ({ title, isOpen, onClose, children }) => {
  const BtnClose = () => {
    return (
      <Button icon={<CloseOutlined />} type="link" onClick={onClose} className="text-success">
        Close
      </Button>
    );
  };
  return (
    <Drawer
      className="drawer-doc"
      closable={false}
      width={900}
      open={isOpen}
      title={`Panduan ${title}`}
      extra={<BtnClose />}
      onClose={onClose}
      style={{ marginTop: 64 }}>
      <div className="main-drawer">{children}</div>
    </Drawer>
  );
};

export default DrawerDocumentation;
