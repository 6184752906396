import { getAdmin, getUser } from "api";
import { useCallback, useEffect } from "react";
import { useJwt } from "react-jwt";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "redux/slices/user";
import { getCookieToken, getSessionToken } from "utils/helpers";

const token = getSessionToken() || getCookieToken();
const useGetUser = () => {
  const { decodedToken = "" } = useJwt(token);

  const dispatch = useDispatch();
  const action = setUserData;
  const user = useSelector(({ userData }) => userData.value);

  const doSet = useCallback((data) => dispatch(action(data)), [dispatch, action]);

  useEffect(() => {
    const getuser = async () => {
      const { userUuid: uuid, adminUuid } = decodedToken;
      if (adminUuid) {
        const { data } = await getAdmin({ uuid: adminUuid });
        if (data && Object.keys(data).length) doSet(data);
        return;
      }
      const { data, code } = await getUser({ uuid });
      if (data && Object.keys(data).length) doSet(data);
      else if (code === 500) doSet("not-verified");
      return;
    };
    if (decodedToken && !user) getuser();
  }, [decodedToken, doSet, user]);

  return decodedToken;
};

export default useGetUser;
