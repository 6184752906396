import { Modal } from "antd";
import { ICExclamationCircleOutlined } from "components/list/ListIcon";

const { error } = Modal;

const ModalFailedSuppress = (props) => {
  const { okText = "Oke", okButtonProps, customMessage, message } = props;
  const messageModal = customMessage || <p className="mb-0">{message}</p>;
  return error({
    ...props,
    icon: <ICExclamationCircleOutlined style={{ color: "#FAAD14" }} />,
    content: messageModal,
    centered: true,
    okText: okText,
    okButtonProps: { ...okButtonProps },
    autoFocusButton: null,
    width: "416px",
  });
};

export default ModalFailedSuppress;
