import { Form, Input, Modal } from "antd";
import { changePassword } from "api";
import { useState } from "react";
import { gatherFormData, regexPassword } from "utils/helpers";
import ModalError from "./ModalError";
import ModalSuccess from "./ModalSuccess";
import ModalWarning from "./ModalWarning";

const ModalChangePassword = ({ isOpen, setOpen }) => {
  const [loading, setLoading] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [registered, setRegistered] = useState(false);

  const closeForm = () => setOpen(false);

  const [form] = Form.useForm();

  const handleChangePassword = () => {
    const callback = async () => {
      setLoading(true);
      const values = form.getFieldsValue();
      const { code, msg } = await changePassword(values);
      setLoading(false);
      if (code === 400 && msg.includes("Wrong email or password")) {
        ModalError({
          title: "Password Lama Tidak Sesuai",
          message:
            "Password lama yang anda masukkan salah, silakan dapat memasukkan password yang benar",
        });
      } else if (code === 400 && msg.includes("required pattern")) {
        ModalWarning({
          title: "Password gagal diperbarui",
          message:
            "Password harus mengandung minimal 8 Karakter, komninasi huruf, angka dan simbol",
        });
      } else if (code === 400 && msg.includes("must be [ref:newPassword]")) {
        ModalError({
          title: "Password gagal diperbarui",
          message: "Konfirmasi password baru tidak sama, silahkan cek kembali",
        });
      } else if (code === 200 && msg.includes("successfully")) {
        form.resetFields();
        setOpen(false);
        ModalSuccess({
          title: "Password berhasil diperbarui",
          message: "Password anda berhasil diperbarui pada sistem",
          onOk: () => {},
        });
      }
    };
    gatherFormData(form, callback);
  };

  const handleConfirmPassword = ({ target: { value } }) => {
    setConfirmPassword(value);
  };
  return (
    <Modal
      title="Ubah Password"
      open={isOpen === "password"}
      onCancel={closeForm}
      okText="Simpan"
      cancelText="Batal"
      onOk={handleChangePassword}
      okButtonProps={{ loading: loading, htmlType: "submit", disabled: registered.disabled }}
      centered>
      <Form layout="vertical" form={form} name="changePassword">
        <Form.Item
          label="Password saat ini"
          name="password"
          className="mb-1"
          rules={[
            { required: true, message: "Password saat ini harus diisi" },
            { min: 8, message: "Minimal password 8 karakter" },
          ]}>
          <Input.Password />
        </Form.Item>
        <Form.Item
          label="Password Baru"
          name="newPassword"
          className="mb-1"
          rules={[
            {
              pattern: new RegExp(regexPassword),
              message:
                "Minimal password 8 karakter dengan kombinasi huruf kapital, huruf kecil, angka dan simbol",
            },
            { required: true, message: "Password baru harus diisi" },
          ]}>
          <Input.Password onChange={(e) => handleConfirmPassword(e)} />
        </Form.Item>
        <Form.Item
          label="Ulangi Password Baru"
          name="confirmNewPassword"
          className="mb-1"
          rules={[
            {
              validator(_, value) {
                if (!value) {
                  setRegistered({ ...registered, disabled: false });
                  return Promise.reject(new Error("Harap diisi terlebih dahulu"));
                } else if (!value || value === confirmPassword) {
                  setRegistered({ ...registered, disabled: false });
                  return Promise.resolve();
                } else {
                  setRegistered({ ...registered, disabled: true });
                  return Promise.reject(new Error("Konfirmasi password tidak sama"));
                }
              },
            },
          ]}>
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalChangePassword;
