import { createSlice } from "@reduxjs/toolkit";

export const listActivityAdmin = createSlice({
  name: "listActivityAdmin",
  initialState: {
    value: false,
  },
  reducers: {
    setListAcvitiyAdmin: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setListAcvitiyAdmin } = listActivityAdmin.actions;
export default listActivityAdmin.reducer;
