import { Card, ConfigProvider, Select, Table } from "antd";
import { EmptyWrapper } from "components/empty/EmptyComponent";
import TableTitle from "components/tableSendiri/TableTitle";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const headerCSV = [
  { label: "Date", key: "date" },
  { label: "Aktivitas", key: "logDescription" },
  { label: "Admin", key: "adminName" },
];

const AdminSelector = ({ listAdmin, onSelect }) => {
  return (
    <Select
      className="selectAdmin"
      showArrow
      showSearch={false}
      popupClassName="selectAdmin__dropdown"
      placeholder="Semua Admin"
      onSelect={onSelect}>
      <Select.Option value="">Select All</Select.Option>
      {listAdmin.map(({ adminUuid, adminName }) => (
        <Select.Option key={adminUuid} value={adminUuid}>
          {adminName}
        </Select.Option>
      ))}
    </Select>
  );
};

const TableLogActivity = ({ loading, filter, setFilter, getData }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1024;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const listAdminShort = useSelector(({ listAdminShort }) => listAdminShort.value);
  const listActivityAdmin = useSelector(({ listActivityAdmin }) => listActivityAdmin.value);

  const column = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: width > breakpoint ? 200 : 200,
    },
    {
      title: "Aktivitas",
      dataIndex: "logDescription",
      key: "logDescription",
      width: 776,
    },
    {
      title: "Admin",
      dataIndex: "adminName",
      key: "adminName",
      width: 200,
    },
  ];

  const onSelectAdminFilter = (value) => {
    setFilter({ ...filter, page: 1, adminUuid: value });
  };

  if (!listAdminShort && !listActivityAdmin) return <Card bordered={false} loading />;

  return (
    <ConfigProvider
      renderEmpty={() => (
        <EmptyWrapper
          title="Data belum tersedia"
          message="Data log aktivitas admin tersebut akan muncul setelah admin melakukan aktivitas pada dashboard"
        />
      )}>
      <Table
        title={() => (
          <TableTitle
            title="Log Aktivitas Admin"
            desc={`Daftar log aktivitas admin ${process.env.REACT_APP_NAME} Dashboard`}
            buttonRefresh={{ onClick: getData, responsive: "lg", loading }}
            addsBefore={<AdminSelector listAdmin={listAdminShort} onSelect={onSelectAdminFilter} />}
            pageLimit={{
              currentLimit: 0,
              onSelectLimit: (limit) => setFilter({ ...filter, page: 1, limit }),
            }}
            buttonExport={{
              dataCSV: listActivityAdmin.rows,
              headerCSV,
              fileName: "List-Activity-Admin",
              responsive: "lg",
            }}
          />
        )}
        loading={loading}
        dataSource={listActivityAdmin.rows}
        {...(width <= breakpoint ? { scroll: { x: 1024 } } : {})}
        columns={column}
        pagination={{
          current: filter.page,
          size: "small",
          pageSize: filter.limit,
          showSizeChanger: false,
          hideOnSinglePage: true,
          total: listActivityAdmin.count,
          onChange: (page) => setFilter({ ...filter, page }),
        }}
        showSorterTooltip={false}
        rowKey={(record) => record.uuid}
      />
    </ConfigProvider>
  );
};

export default TableLogActivity;
