import { Button, Card, Form, Modal, Table, Tag, Typography } from "antd";
import { getPackage, relayActivation } from "api";
import FormDomain from "components/form/FormDomain";
import {
  ICCheckCircleOutlined,
  ICCheckOutlined,
  ICCopyOutlined,
  ICEyeInvisibleOutlined,
  ICEyeOutlined,
} from "components/list/ListIcon";
import ModalConfirm from "components/popups/modal/ModalConfirm";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setListDomain } from "redux/slices/listDomain";
import { setUserProfile } from "redux/slices/profile";
import { setSetupLayanan } from "redux/slices/setupLayanan";
import { setUserData } from "redux/slices/user";
import { setUserPackage } from "redux/slices/userPackage";
import { btnCopyPassword, btnShowHidePassword } from "utils/copyHidePass";
import { capitalizeFirstLetter } from "utils/helpers";

const { Paragraph } = Typography;

const InformationAccountSetup = () => {
  const navigate = useNavigate();
  const [registered, setRegistered] = useState(false);

  const dispatch = useDispatch();
  const {
    accessDomain,
    portAccess,
    portType,
    userRelay = false,
    passwordRelay = false,
    statusRelay,
  } = useSelector(({ setupLayanan }) => setupLayanan.value);

  const listDomain = useSelector(({ listDomain }) => listDomain.value);

  const handleAddDomain = (values) => navigate("/setup/processing", { state: values });

  const [hidePassword, setHidePassword] = useState(true);
  const [copyPassword, setCopyPassword] = useState(true);
  const pwdHide = <span>************</span>;

  const columns = [
    {
      title: "params",
      dataIndex: "params",
      key: "params",
      width: 100,
      className: "params-value-col",
    },
    {
      title: "values",
      dataIndex: "values",
      key: "values",
      className: "params-value-col",
      render: (values, index) => (
        <>
          {index.params === "Akses" || index.params === "Username" ? (
            <Paragraph copyable>{values}</Paragraph>
          ) : index.params === "Port" ? (
            <Paragraph>{values}</Paragraph>
          ) : (
            <>
              <div className="flex flex items-center gap-2 w-0">
                {!hidePassword ? <p className="mb-0">{values}</p> : pwdHide}
                <Button
                  className="m-0 act-pass"
                  type="link"
                  icon={
                    hidePassword ? (
                      <ICEyeOutlined className="text-sm" />
                    ) : (
                      <ICEyeInvisibleOutlined className="text-sm" />
                    )
                  }
                  onClick={() => btnShowHidePassword(setHidePassword)}
                />
                <Button
                  className="m-0 act-pass"
                  type="link"
                  icon={
                    copyPassword ? (
                      <ICCopyOutlined className="text-sm" />
                    ) : (
                      <ICCheckOutlined className="text-sm" />
                    )
                  }
                  onClick={() => btnCopyPassword(values, setCopyPassword)}
                />
              </div>
            </>
          )}
        </>
      ),
    },
  ];

  const eUDataSource = [
    {
      key: "1",
      params: "Akses",
      values: accessDomain,
    },
    {
      key: "2",
      params: "Port",
      values: `${portAccess} ${portType}`,
    },
  ];

  const dataSource = [
    {
      key: "1",
      params: "Akses",
      values: accessDomain,
    },
    {
      key: "2",
      params: "Port",
      values: `${portAccess} ${portType}`,
    },
    {
      key: "3",
      params: "Username",
      values: userRelay,
    },
    {
      key: "4",
      params: "Password",
      values: passwordRelay,
    },
  ];

  const renderDataSource = !userRelay && !passwordRelay ? eUDataSource : dataSource;

  const BtnActivation = () => {
    const status =
      statusRelay === "closed" &&
      listDomain.length &&
      listDomain.every(
        ({ spf, dkim, dmarc, aRecord }) =>
          spf.status && dkim.status && dmarc.status && aRecord.status
      );

    const handleDomainActivation = async () => {
      const { data } = await getPackage();
      dispatch(setUserPackage(data));
      if (data === "package") {
        ModalConfirm({
          title: `Aktivasi account ${userRelay} gagal`,
          width: 600,
          customMessage: (
            <div>
              <p className="mb-4">
                Account <span className="font-medium">{userRelay}</span> gagal diaktivasi karena
                saat ini anda tidak memiliki paket langganan yang aktif.
              </p>
              <p className="mb-0">
                Untuk dapat melakukan aktivasi, silakan dapat berlangganan dengan memilih paket
                sesuai kebutuhan anda.
              </p>
            </div>
          ),
          cancelText: "Berlangganan Sekarang",
          okText: "Oke",
          onCancel: () => navigate("/checkout"),
        });
      } else {
        await relayActivation();
        ModalSuccess({
          title: "Domain anda sedang diaktivasi",
          customMessage: (
            <p className="mb-0">
              Domain milik anda telah berhasil ditambahkan pada sistem kami. Saat ini kami sedang
              melakukan aktivasi domain anda. Tunggu proses aktivasi dan{" "}
              <span className="font-medium">jangan tutup browser anda</span>.
            </p>
          ),
          width: 500,
          onOk: () => {},
        });
        setTimeout(() => Modal.destroyAll(), 3000);
        setTimeout(() => {
          dispatch(setSetupLayanan(false));
          dispatch(setListDomain(false));
          dispatch(setUserData(false));
          dispatch(setUserProfile(false));
        }, 4000);
      }
    };

    return (
      <Button
        type="primary"
        className={!status ? "" : "blob"}
        disabled={!status}
        icon={<ICCheckCircleOutlined />}
        onClick={handleDomainActivation}>
        Aktivasi Domain
      </Button>
    );
  };

  const TitleActivation = () => {
    return (
      <div className="flex gap-x-2 items-center">
        <span>Account SMTP</span>
        <div>
          {statusRelay ? (
            <Tag color={statusRelay === "active" ? "blue" : "red"} className="h-auto">
              {capitalizeFirstLetter(statusRelay === "closed" ? "inactive" : statusRelay)}
            </Tag>
          ) : (
            <Tag color="red">Inactive</Tag>
          )}
        </div>
      </div>
    );
  };

  return (
    <Card
      title={<TitleActivation />}
      className="information-account__wrapper"
      extra={<BtnActivation />}>
      <p className="text-secondary mb-2">
        Untuk mengaktifkan akun {process.env.REACT_APP_NAME}, pastikan anda sudah melakukan
        penambahan reputasi email server pada semua domain.
      </p>
      <Table
        dataSource={renderDataSource}
        columns={columns}
        showHeader={false}
        pagination={false}
        rowKey={(record) => record.values}
        className="mb-4"
      />
      {!listDomain.length && !userRelay && (
        <Form layout="vertical" onFinish={handleAddDomain}>
          <FormDomain
            className="mb-0"
            inputClass="blob"
            label="Trial anda belum aktif, lakukan setup domain sekarang"
            isSetup
            setRegistered={setRegistered}
            registered={registered}
          />
        </Form>
      )}
    </Card>
  );
};

export default InformationAccountSetup;
