import { createSlice } from "@reduxjs/toolkit";

export const packageListFilter = createSlice({
  name: "packageListFilter",
  initialState: {
    value: false,
  },
  reducers: {
    setPackageListFilter: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setPackageListFilter } = packageListFilter.actions;
export default packageListFilter.reducer;
