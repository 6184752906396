import { PageHeader } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./dashboardheader.less";

const DashboardPageHeader = ({ title = "", extra, backPath = -1 }) => {
  const navigate = useNavigate();
  const back = () => navigate(backPath);

  return <PageHeader className="page-header bg-white" extra={extra} onBack={back} title={title} />;
};

export default DashboardPageHeader;
