import { Button, Card, Upload } from "antd";
import { loginAsClient } from "api";
import defaultImage from "assets/images/profile.svg";
import {
  ICEditOutlined,
  ICFileImageOutlined,
  ICGlobalOutlined,
  ICLoginOutlined,
  ICMailOutlined,
  ICSafetyOutlined,
  ICUnlockOutlined,
  ICUserOutlined,
} from "components/list/ListIcon";
import ModalChangePassword from "components/popups/modal/ModalChangePassword";
import ModalEditProfile from "components/popups/modal/ModalEditProfile";
import React, { useState } from "react";

const ProfileImage = ({ src, uploadImage }) => {
  const date = new Date().getTime();
  const uploadImgProps = {
    multiple: false,
    showUploadList: false,
  };

  return (
    <div className="mb-4 image-profile-wrapper relative text-center">
      <img alt="Profile" className="img-profile" src={src ? `${src}?date=${date}` : defaultImage} />
      <Upload
        {...uploadImgProps}
        customRequest={uploadImage}
        accept=".jpg,.png"
        className="upload-btn center">
        <Button type="dashed" icon={<ICFileImageOutlined />}>
          Ubah Foto
        </Button>
      </Upload>
    </div>
  );
};

const ProfileName = ({ name }) => {
  if (!name) return "";
  return (
    <div className="flex items-center mb-2 w-full">
      <ICUserOutlined className="mr-2" />
      {name}
    </div>
  );
};

const ProfileWebsite = ({ websiteUrl }) => {
  if (!websiteUrl) return "";
  return (
    <div className="flex items-center mb-2 w-full">
      <ICGlobalOutlined className="mr-2" />
      <p className="mb-0 text-info">{websiteUrl}</p>
    </div>
  );
};
const ProfileAccount = ({ link }) => {
  if (!link) return "";
  return (
    <div className="flex items-center mb-2 w-full">
      <ICMailOutlined className="mr-2" />
      <p className="mb-0">{link}</p>
    </div>
  );
};

const ProfileCreated = ({ created }) => {
  return (
    <div className="profile-picture__content">
      <div className="flex items-center mb-2">
        <ICSafetyOutlined className="mr-2" />
        {created}
      </div>
    </div>
  );
};

const ProfileAction = ({ action, data: record, getProfile }) => {
  const [openModal, setOpenModal] = useState(false);
  const ActionUser = () => {
    return (
      <div className="flex flex-col gap-y-2">
        <Button
          icon={<ICEditOutlined />}
          type="primary"
          className="w-full"
          onClick={() => setOpenModal("profile")}>
          Edit Profil
        </Button>
        <Button
          icon={<ICUnlockOutlined />}
          className="w-full"
          onClick={() => setOpenModal("password")}>
          Ubah Password
        </Button>
        <ModalEditProfile
          getOthers={getProfile}
          isOpen={openModal}
          setOpen={setOpenModal}
          data={record}
        />
        <ModalChangePassword isOpen={openModal} setOpen={setOpenModal} />
      </div>
    );
  };

  const ActionLogin = () => {
    const handleLoginAsClient = async () => {
      const { data } = await loginAsClient({
        user: record.user_primary_uuid,
        company: record.company_uuid,
        contact: record.contact_primary_uuid,
      });
      window.open(
        `${process.env.REACT_APP_FE_BASE_URL}/logintoclient?token=${data.accessToken}`,
        "_blank"
      );
    };
    return (
      <Button
        icon={<ICLoginOutlined />}
        className="w-full"
        type="primary"
        onClick={handleLoginAsClient}>
        Login Sebagai Client
      </Button>
    );
  };

  if (action === "login") return <ActionLogin />;
  else if (action === "user") return <ActionUser />;
  else return "";
};

const CardProfileNew = ({ changeImage, infoProfile = false, ...props }) => {
  const { getProfile, action, loading, photo, name, account, website, createdAt } = props;
  if (loading === "cardProfile" || loading === "all") return <Card loading />;
  return (
    <div className="p-6 bg-white profile-picture__card">
      <ProfileImage src={photo} uploadImage={changeImage} />
      <div className="profile-picture__content">
        {name && <ProfileName name={name} />}
        {website && <ProfileWebsite websiteUrl={website} />}
        {account && <ProfileAccount link={account} />}
        {createdAt && <ProfileCreated created={createdAt} />}
        {infoProfile && (
          <ProfileAction action={action} data={infoProfile} getProfile={getProfile} />
        )}
      </div>
    </div>
  );
};

export default CardProfileNew;
