import { Button, ConfigProvider, Table, Tag, Dropdown, Menu, Card, Form } from "antd";
import { EmptyWrapper } from "components/empty/EmptyComponent";
import { ICCheckOutlined, ICMoreOutlined } from "components/list/ListIcon";
import TableTitle from "components/tableSendiri/TableTitle";
import TableTitleWithCollapse from "components/tableSendiri/TableTitleWithCollapse";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter, formatNumberWithDot, getNumericValue } from "utils/helpers";
import { DrawerConfirm } from "components/popups/drawer/DrawerConfirm";
import { StopOutlined } from "@ant-design/icons";
import { handleReject } from "utils/handleFunction";
import { ModalConfirmManual } from "components/popups/modal/ModalConfirmManualPay";

const TableConfirm = ({
  role,
  ListConfirmPayment,
  title,
  emptyState,
  responsiveWidth,
  breakpoint_third,
  adminConfirmation,
  filter,
  setFilter,
  callBackConfirm,
  callbackInvoice,
  callbackSummary,
}) => {
  const [openDrawerConfirmation, setOpenDrawerConfirmation] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [dataRow, setDataRow] = useState(false);
  const [billPayment, setBillPayment] = useState(false);
  const [pphPayment, setPphPayment] = useState(false);
  const [billCount, setBillCount] = useState(null);
  const [pphCount, setPphCount] = useState(null);
  const [tanggalFieldValue, setTanggalFieldValue] = useState(null);
  const [rekeningTargetFieldValue, setRekeningTargetFieldValue] = useState(null);
  const [form] = Form.useForm();

  const handleCloseConfirmation = () => {
    setModalConfirmation(false);
    form.resetFields();
    setBillCount(null);
    setPphCount(null);
    setBillPayment(false);
    setPphPayment(false);
  };

  const handleBillPayment = (e) => {
    const isChecked = e.target.checked;
    setBillPayment(isChecked);
    if (isChecked && dataRow.paymentAmount) {
      const billAmount = parseFloat(
        formatNumberWithDot(getNumericValue(dataRow.paymentAmount))
          .replace(/\./g, "")
          .replace(",", ".")
      );
      setBillCount(billAmount);
    } else {
      setBillCount(null);
    }
  };

  const handlePphPayment = (e) => {
    const isChecked = e.target.checked;
    setPphPayment(isChecked);
    if (isChecked && dataRow.paymentAmount) {
      const pphAmount =
        (parseFloat(
          formatNumberWithDot(getNumericValue(dataRow.paymentAmount))
            .replace(/\./g, "")
            .replace(",", ".")
        ) /
          1.11) *
        0.02;
      setPphCount(pphAmount.toFixed(0));
      const billAmount =
        parseFloat(
          formatNumberWithDot(getNumericValue(dataRow.paymentAmount))
            .replace(/\./g, "")
            .replace(",", ".")
        ) - pphAmount;
      setBillCount(billAmount);
    } else {
      setBillCount(null);
      setPphCount(null);
    }
  };

  const validateNominalPajak = (_, value) => {
    return new Promise((resolve, reject) => {
      const totalAmount = (parseFloat(billCount) || 0) + (parseFloat(pphCount) || 0);
      const expectedAmount = parseFloat(
        formatNumberWithDot(getNumericValue(dataRow.paymentAmount))
          .replace(/\./g, "")
          .replace(",", ".")
      );

      if (totalAmount !== expectedAmount) {
        reject("Nominal pajak tidak sesuai dengan total tagihan, harap cek kembali");
      } else {
        resolve();
      }
    });
  };

  const changePage = (pagesa) => {
    setFilter({ ...filter, page: pagesa });
  };

  const ActionTable = (record) => {
    return (
      <div className="flex justify-center items-center">
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu>
              <Menu.Item
                key="confirm"
                disabled={record.paymentStatus !== "pending-confirmation"}
                onClick={() => {
                  setModalConfirmation(true);
                  setDataRow(record);
                }}>
                <ICCheckOutlined className="icon-action" />
                <span className="ml-2">Konfirmasi Pembayaran</span>
              </Menu.Item>
              <Menu.Item
                key="reject"
                disabled={record.paymentStatus !== "pending-confirmation"}
                danger={record.paymentStatus === "pending-confirmation"}
                onClick={() =>
                  handleReject({ record, callBackConfirm, setOpenDrawerConfirmation })
                }>
                <StopOutlined className="icon-action" />
                <span className="ml-2">Tolak Pembayaran</span>
              </Menu.Item>
            </Menu>
          }>
          <ICMoreOutlined />
        </Dropdown>
      </div>
    );
  };

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1200;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  // admin
  const columnConfirmAdmin = [
    {
      title: "Invoice Number",
      dataIndex: "invNumber",
      key: "invNumber",
      width: 158,
      render: (_, record) => {
        const navInvoice = () => window.open(record.invoiceUrl, "_blank");
        return (
          <p className="mb-0 pointer text-info" onClick={navInvoice}>
            {record.invNumber}
          </p>
        );
      },
    },
    {
      title: "Nama Perusahaan",
      dataIndex: "companyName",
      key: "companyName",
    },
    {
      title: "Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
      width: 105,
      render: (item) => (
        <Tag
          color={
            item === "confirmed" ? "green" : item === "pending-confirmation" ? "warning" : "red"
          }
          className={`invoice-table-tag${item === "confirmed" ? " paid-tag" : ""}`}>
          {capitalizeFirstLetter(
            item === "confirmed"
              ? "Confirmed"
              : item === "pending-confirmation"
              ? "Pending"
              : "Declined"
          )}
        </Tag>
      ),
    },
    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      width: 140,
    },
    {
      title: "Destination Bank",
      dataIndex: "destinationBank",
      key: "destinationBank",
      width: 140,
    },
    {
      render: (record) => (
        <Button
          type="link"
          onClick={() => {
            setOpenDrawerConfirmation(true);
            setDataRow(record);
          }}>
          Detail
        </Button>
      ),
      align: "center",
      width: 88,
    },
    {
      key: "action",
      render: (_, record) => ActionTable(record),
      width: 30,
    },
  ];

  const renderColumn = role === "admin" && columnConfirmAdmin;

  if (!ListConfirmPayment.table)
    return <Card bordered={false} loading={ListConfirmPayment.loading} className="p-0 m-0"></Card>;

  return (
    <ConfigProvider renderEmpty={() => <EmptyWrapper {...emptyState} />}>
      <Table
        dataSource={ListConfirmPayment.table.rows}
        columns={renderColumn}
        {...(width < breakpoint ? { scroll: { x: 1024 } } : {})}
        loading={ListConfirmPayment.loading}
        pagination={{
          pageSize: filter.limit, //jumlah data
          current: filter.page, //halaman
          hideOnSinglePage: true,
          onChange: (params) => {
            changePage(params);
          },
          size: "small",
          total: ListConfirmPayment.table.count,
          showSizeChanger: false,
        }}
        title={() =>
          role === "admin" ? (
            <TableTitleWithCollapse
              {...title}
              breakpoint_third={breakpoint_third}
              responsiveWidth={responsiveWidth}
            />
          ) : (
            <TableTitle {...title} />
          )
        }
      />
      <ModalConfirmManual
        form={form}
        dataRow={dataRow || {}}
        open={modalConfirmation}
        close={handleCloseConfirmation}
        adminConfirmation={adminConfirmation}
        handleBillPayment={handleBillPayment}
        handlePphPayment={handlePphPayment}
        setBillCount={setBillCount}
        setPphCount={setPphCount}
        billCount={billCount}
        billPayment={billPayment}
        pphPayment={pphPayment}
        pphCount={pphCount}
        rekeningTargetFieldValue={rekeningTargetFieldValue}
        setRekeningTargetFieldValue={setRekeningTargetFieldValue}
        tanggalFieldValue={tanggalFieldValue}
        setTanggalFieldValue={setTanggalFieldValue}
        validateNominalPajak={validateNominalPajak}
        callBackConfirm={callBackConfirm}
        callbackInvoice={callbackInvoice}
        callbackSummary={callbackSummary}
        setOpenDrawerConfirmation={setOpenDrawerConfirmation}
        zIndex={1050}
      />
      <DrawerConfirm
        open={openDrawerConfirmation}
        close={() => setOpenDrawerConfirmation(false)}
        setOpenDrawerConfirmation={setOpenDrawerConfirmation}
        dataRow={dataRow || {}}
        setModalConfirmation={setModalConfirmation}
        callBackConfirm={callBackConfirm}
        zIndex={1000}
      />
    </ConfigProvider>
  );
};

export default TableConfirm;
