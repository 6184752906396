const lists = [
  {
    children: [
      { name: "authLogout", url: "revoke", method: "POST" },
      { name: "refresh", url: "refresh", method: "POST" },
    ],
  },
  {
    url: "users",
    children: [
      { name: "authLogin", url: "login", method: "POST" },
      { name: "authRegister", url: "signup", method: "POST" },
      { name: "forgotPassword", url: "forgot-password", method: "POST" },
      { name: "resetPassword", url: "reset-password", method: "POST" },
      { name: "checkTokenResetPass", url: "reset-password", method: "GET" },
      { name: "checkExistedEmail", url: "check-email", method: "GET" },
      { name: "getUser", url: "search", method: "GET" },
      { name: "addContact", url: "add-contact", method: "POST" },
      { name: "deleteContact", url: "delete-contact", method: "POST" },
      { name: "editContact", url: "edit-contact", method: "POST" },
      { name: "confirmEmail", url: "confirm-email", method: "POST" },
      { name: "resendConfirmEmail", url: "resend-confirm-email", method: "POST" },
      { name: "checkStatusVerification", url: "check-status", method: "GET" },
      { name: "infoProfile", url: "info-profile", method: "GET" },
      { name: "changePassword", url: "change-password", method: "POST" },
      {
        url: "notification",
        children: [
          { name: "getUserNotification", url: "list", method: "GET" },
          { name: "markReadNotification", url: "read-all", method: "POST" },
          { name: "getDetailUserNotification", url: "detail", method: "GET" },
        ],
      },
    ],
  },
  {
    url: "domains",
    children: [
      { name: "setUpNewDomain", url: "setup", method: "POST" },
      { name: "checkExistedDomain", url: "check-existed", method: "GET" },
      { name: "addDomain", url: "add-domain", method: "POST" },
      { name: "changeNotification", url: "change-notification", method: "POST" },
      { name: "deleteDomain", url: "delete", method: "POST" },
      { name: "getDomain", url: "list-domain", method: "GET" },
      { name: "addDomain", url: "add-domain", method: "POST" },
      { name: "deleteDomain", url: "delete", method: "POST" },
      { name: "activateDomain", url: "activation", method: "POST" },
      { name: "checkDNS", url: "check", method: "POST" },
      { name: "getDomainActive", url: "list-active-domain", method: "GET" },
    ],
  },
  {
    url: "companies",
    children: [
      { name: "getCompany", url: "info-company", method: "GET" },
      { name: "getSetupLayanan", url: "info-service-setup", method: "GET" },
      { name: "getContact", url: "info-contact", method: "GET" },
      { name: "getAccountSMTP", url: "info-account-smtp", method: "GET" },
      { name: "setupCompanyData", url: "company-detail", method: "POST" },
      { name: "setupCompanyEmail", url: "company-email-detail", method: "POST" },
      { name: "getDetailsCompanyData", url: "get-details", method: "GET" },
      { name: "relayActivation", url: "active-relay", method: "POST" },
      { name: "unlockRelay", url: "unlock-relay", method: "POST" },
      { name: "editCompany", url: "company-detail/patch", method: "POST" },
      { name: "getConfigNotification", url: "notification-config", method: "GET" },
      { name: "changeConfigNotification", url: "notification-config", method: "POST" },
      { name: "getEmailAPI", url: "email-api-token", method: "GET" },
      { name: "GenerateAPI", url: "email-api-token", method: "POST" },
      { name: "ReGenerateAPI", url: "email-api-token", method: "PATCH" },
      {
        name: "changePhotoProfileCompany",
        url: "photo/patch",
        method: "POST",
      },
      { name: "updateNpwp", url: "npwp/patch", method: "POST" },
      { name: "updateConfig", url: "set-up-config", method: "POST" },
      { name: "getDetailSubscription", url: "get-detail-subscription", method: "GET" },
      { name: "getCompanyPackage", url: "summary-package", method: "GET" },
    ],
  },
  {
    url: "billings",
    children: [
      { name: "getPackage", url: "summary-package", method: "GET" },
      { name: "getFakturUser", url: "tax-billings/all", method: "GET" },
      { name: "getPackageAll", url: "packages/all", method: "GET" },
      { name: "checkoutLogin", url: "checkout", method: "POST" },
      { name: "checkoutRegister", url: "checkout-register", method: "POST" },
      { name: "getDetailServicePackage", url: "info-package", method: "GET" },
      { name: "getListInvoices", url: "invoices", method: "GET" },
      { name: "getCurrentPayment", url: "current-payment", method: "GET" },
      { name: "checkoutChecker", url: "checkout-check", method: "POST" },
      { name: "getInfoOverQuota", url: "info-over-quota", method: "GET" },
      { name: "enableOverQuota", url: "enable-over-quota", method: "POST" },
      { name: "changePaymentMethod", url: "change-payment-method", method: "POST" },
      { name: "confirmManualPayment", url: "payment-confirmation", method: "POST" },
    ],
  },
  {
    url: "reports",
    children: [
      { name: "getEmailSendingSummary", url: "email-summary", method: "GET" },
      { name: "getLineEmailReports", url: "email-reports-perday", method: "GET" },
      { name: "getMostEmailSummary", url: "reports-most", method: "GET" },
      { name: "getChartReport", url: "count-email-delivery", method: "GET" },
      { name: "getTableReport", url: "daily-email-delivery/all", method: "GET" },
      { name: "getTableLog", url: "log-email-delivery/all", method: "GET" },
    ],
  },
  {
    url: "suppression",
    children: [
      { name: "getSuppressionList", method: "GET" },
      { name: "getHistoryList", url: "history", method: "GET" },
      { name: "AddSuppress", method: "POST" },
      { name: "DeleteSuppress", method: "DELETE" },
    ],
  },
  {
    url: "admins",
    children: [
      { name: "getAllCompanies", url: "companyDetails", method: "GET" },
      { name: "authLoginAdmin", url: "login", method: "POST" },
      { name: "createAdmin", url: "create", method: "POST" },
      { name: "resetPasswordAdmin", url: "reset-password", method: "POST" },
      { name: "checkTokenResetPassAdmin", url: "reset-password", method: "GET" },
      { name: "forgotPasswordAdmin", url: "forgot-password", method: "POST" },
      { name: "changePasswordAdmin", url: "change-password", method: "POST" },
      { name: "changeProfileAdmin", url: "edit-admin", method: "POST" },
      { name: "changePasswordAdmin", url: "change-password", method: "POST" },
      { name: "getAdmin", url: "search", method: "GET" },
      { name: "addContactClient", url: "add-contact", method: "POST" },
      { name: "editContactClient", url: "edit-contact", method: "POST" },
      { name: "deleteContactClient", url: "delete-contact", method: "POST" },
      { name: "changePhotoAdmin", url: "photo/patch", method: "POST" },
      { name: "addNewClient", url: "add-new-client", method: "POST" },
      { name: "loginAsClient", url: "login-as-client", method: "POST" },
      { name: "getAdminLogs", url: "admin-logs", method: "GET" },
      {
        url: "users",
        children: [
          { name: "deleteAdmin", url: "delete", method: "POST" },
          { name: "editAdmin", url: "edit", method: "POST" },
          { name: "listAdmin", url: "list", method: "GET" },
        ],
      },
      {
        url: "billings",
        children: [
          { name: "getPackageClient", url: "info-package", method: "GET" },
          { name: "getInvoicesClient", url: "invoices", method: "GET" },
          { name: "exportInvoicesClient", url: "invoices/export-csv", method: "GET" },
          { name: "getFakturClient", url: "tax-invoices", method: "GET" },
          { name: "exportFakturClient", url: "tax-invoices/export-csv", method: "GET" },
          { name: "getAllInvoices", url: "all-invoices", method: "GET" },
          { name: "getAllTaxInvoices", url: "all-tax-invoices", method: "GET" },
          { name: "getAllConfirmationPayment", url: "list-payments-confirmation", method: "GET" },
          { name: "addFakturPajak", url: "tax-invoice", method: "POST" },
          { name: "getUnTaxedInvoice", url: "untaxed-invoices", method: "GET" },
          { name: "deleteTaxInvoice", url: "tax-invoice/delete", method: "POST" },
          { name: "exportAllTaxInvoice", url: "all-tax-invoices/export-csv", method: "GET" },
          { name: "exportAllInvoice", url: "all-invoices/export-csv", method: "GET" },
          { name: "getInvoiceSummary", url: "summary-invoice", method: "GET" },
          { name: "editFakturPajak", url: "tax-invoice/edit", method: "POST" },
          { name: "exportCustomerTaxInvoice", url: "tax-invoices/export-csv", method: "GET" },
          { name: "exportCustomerInvoice", url: "invoices/export-csv", method: "GET" },
          { name: "ResendBillings", url: "resend-billing-email", method: "POST" },
          { name: "ListBanks", url: "zoho/banks", method: "GET" },
          { name: "RecordManualPayment", url: "record-manual-payment", method: "POST" },
          { name: "RejectPayment", url: "reject-payment-confirmation", method: "POST" },
          { name: "GenerateInvoice", url: "generate-invoice", method: "POST" },
        ],
      },
      {
        url: "companies",
        children: [
          { name: "getAllClients", url: "all", method: "GET" },
          { name: "getDetailCompany", url: "company-data/search", method: "GET" },
          { name: "getProfileCompany", url: "info-company-profile", method: "GET" },
          { name: "getContactClients", url: "info-contact", method: "GET" },
          { name: "editCompanyClient", url: "company-detail/edit", method: "POST" },
          { name: "modifyStatusRelay", url: "modify-status-relay", method: "POST" },
          { name: "summaryClients", url: "summary", method: "GET" },
          { name: "listContactClients", url: "list-contacts", method: "GET" },
          { name: "exportListContact", url: "list-contacts/export-csv", method: "GET" },
          { name: "exportListCompanies", url: "all/export-csv", method: "GET" },
          { name: "getQuotaUsage", url: "reports-detail", method: "GET" },
          { name: "getListCompany", url: "list", method: "GET" },
          { name: "exportAllReportEmailUsage", url: "reports-detail/export-csv", method: "GET" },
          { name: "deleteCompany", url: "delete", method: "POST" },
          { name: "updateAdminCompanyNpwp", url: "company-npwp/patch", method: "POST" },
          { name: "updateAdminCompanyProfilePicture", url: "company-picture/edit", method: "POST" },
          { name: "setPasswordRelay", url: "set-password-relay", method: "POST" },
        ],
      },
      {
        url: "domains",
        children: [
          { name: "getListDomainAdmin", url: "list-domain", method: "GET" },
          { name: "getDomainClients", url: "list-domain/search", method: "GET" },
          { name: "addDomainClients", url: "add-domain", method: "POST" },
          { name: "changeNotifikasiClients", url: "change-notification", method: "POST" },
          { name: "deleteDomainClients", url: "delete-domain", method: "POST" },
        ],
      },
      {
        url: "reports",
        children: [
          { name: "getTableLogAdmin", url: "log-email-delivery/all", method: "GET" },
          { name: "getEmailSummaryAdmin", url: "email-summary", method: "GET" },
          { name: "getTopSenderAdmin", url: "reports-most", method: "GET" },
          { name: "getGraphAdmin", url: "count-email", method: "GET" },
          { name: "getClientSummary", url: "clients-summary", method: "GET" },
          { name: "getBillingReport", url: "clients-subscription", method: "GET" },
          { name: "exportBillingReport", url: "clients-subscription/export-csv", method: "GET" },
        ],
      },
      {
        url: "suppression",
        children: [
          { name: "GetAllSuppressList", url: "all", method: "GET" },
          { name: "GetHistorySuppressList", url: "history", method: "GET" },
          { name: "GetSummarySuppress", url: "summary", method: "GET" },
          { name: "CreateSuppress", method: "POST" },
          { name: "DeleteSuppressAdmin", method: "DELETE" },
        ],
      },
      {
        url: "packages",
        children: [
          { name: "getPackageSummary", url: "summary", method: "GET" },
          { name: "getPackageDetail", url: "detail", method: "GET" },
          { name: "deletePackage", url: "delete", method: "DELETE" },
          { name: "getAllPackage", url: "list", method: "GET" },
          { name: "changePackageStatus", url: "status/edit", method: "POST" },
          { name: "changePackagePublication", url: "status-publication/edit", method: "POST" },
          { name: "createPackage", url: "create", method: "POST" },
          { name: "editPackage", url: "edit", method: "POST" },
          { name: "getUsedPackage", url: "list-used", method: "GET" },
        ],
      },
      {
        url: "notification",
        children: [
          { name: "createNotification", url: "create", method: "POST" },
          { name: "listNotificationAdmin", url: "list", method: "GET" },
          { name: "getNotificationDetailAdmin", url: "detail", method: "GET" },
          { name: "DeleteNotificationAdmin", url: "delete", method: "DELETE" },
          { name: "EditNotification", url: "patch", method: "PATCH" },
        ],
      },
    ],
  },
];

export default lists;
