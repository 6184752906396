import { Badge, Button, Form, Input, Select, Table, Tag } from "antd";
import { ICFilterOutlined, ICPlusOutlined } from "components/list/ListIcon";
import TableTitleWithCollapse from "components/tableSendiri/TableTitleWithCollapse";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { splitDate } from "utils/helpers";

const SubjectDocumentationButton = ({ record }) => {
  const navigate = useNavigate();
  const onShow = () => navigate("preview", { state: record });
  const onEdit = () => navigate("edit", { state: record });
  const onPublish = () => {
    console.log(record);
  };
  const onDelete = async () => {
    console.log(record);
  };
  return (
    <div className="flex gap-x-1 items-center btn-extra flex-wrap">
      <Button type="link" size="small" className="text-xs p-0" onClick={onShow}>
        Lihat
      </Button>
      <span className="text-xs text-secondary">•</span>
      <Button type="link" size="small" className="text-xs p-0" onClick={onEdit}>
        Edit
      </Button>
      <span className="text-xs text-secondary">•</span>
      <Button type="link" size="small" className="text-xs p-0" onClick={onPublish}>
        Publish Dokumen
      </Button>
      <span className="text-xs text-secondary">•</span>
      <Button type="link" size="small" className="text-xs p-0 text-danger" onClick={onDelete}>
        Hapus
      </Button>
    </div>
  );
};

const TableDocumentation = () => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 993;
  const breakpoint_third = 768;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const data = [
    {
      date: "2022-10-24 15:41:43",
      subject: "Konfigurasi pada Email Zimbra Server Versi 8.5 - 9.x",
      body: "~~~\necho relay.excellent.co.id relay.excellent@excellent.co.id:StdPwdRelayC011619991919! > /opt/zimbra/conf/relay_password \npostmap /opt/zimbra/conf/relay_password \npostmap -q relay.excellent.co.id /opt/zimbra/conf/relay_password \nzmprov ms `zmhostname` zimbraMtaSmtpSaslPasswordMaps lmdb:/opt/zimbra/conf/relay_password \nzmprov ms `zmhostname` zimbraMtaSmtpSaslAuthEnable yes \nzmprov ms `zmhostname` zimbraMtaSmtpCnameOverridesServername no \nzmprov ms `zmhostname` zimbraMtaSmtpTlsSecurityLevel may\nzmprov ms `zmhostname` zimbraMtaSmtpSaslSecurityOptions noanonymous \nzmprov ms `zmhostname` zimbraMtaRelayHost relay.excellent.co.id:587\n~~~",
      status: "draft",
      services: ["SMTP Relay"],
      category: ["panduan", "tips"],
    },
    {
      date: "2022-10-24 15:41:43",
      subject: "Konfigurasi pada Email Zimbra Server Versi 8.0.x",
      body: "1. Remote server Zimbra menggunakan SSH dan akses ke console\n\n2. Login sebagai user root dari sistem operasi Linux\n\n3. Jalankan perintah berikut untuk login sebagai user Zimbra\n\n~~~\nsu - zimbra\n~~~\n\n4. Ketikkan atau copy perintah berikut dan paste pada console email server Zimbra anda\n\n~~~\necho relay.excellent.co.id relay.excellent@excellent.co.id:StdPwdRelayC011619991919! > /opt/zimbra/conf/relay_password \npostmap /opt/zimbra/conf/relay_password \npostmap -q relay.excellent.co.id /opt/zimbra/conf/relay_password \nzmprov ms `zmhostname` zimbraMtaSmtpSaslPasswordMaps lmdb:/opt/zimbra/conf/relay_password \nzmprov ms `zmhostname` zimbraMtaSmtpSaslAuthEnable yes \nzmprov ms `zmhostname` zimbraMtaSmtpCnameOverridesServername no \nzmprov ms `zmhostname` zimbraMtaSmtpTlsSecurityLevel may\nzmprov ms `zmhostname` zimbraMtaSmtpSaslSecurityOptions noanonymous \nzmprov ms `zmhostname` zimbraMtaRelayHost relay.excellent.co.id:587\n~~~",
      status: "draft",
      services: ["SMTP Relay"],
      category: ["panduan", "tips"],
    },
    {
      date: "2022-10-24 15:41:43",
      subject: "Konfigurasi pada Email Zimbra Server versi 7.x",
      status: "published",
      services: ["SMTP Relay"],
      category: ["panduan", "tips"],
    },
    {
      date: "2022-10-24 15:41:43",
      subject: "Konfigurasi pada Email Server Postfix",
      status: "published",
      services: ["SMTP Relay"],
      category: ["panduan", "tips"],
    },
    {
      date: "2022-10-24 15:41:43",
      subject: "Konfigurasi pada Email Server Server MDaemon",
      status: "published",
      services: ["SMTP Relay"],
      category: ["panduan", "tips"],
    },
    {
      date: "2022-10-24 15:41:43",
      subject: "Konfigurasi pada Email Server Server Sendmail",
      status: "published",
      services: ["SMTP Relay"],
      category: ["panduan", "tips"],
    },
    {
      date: "2022-10-24 15:41:43",
      subject: "Konfigurasi pada Aplikasi PHP",
      status: "published",
      services: ["SMTP Relay"],
      category: ["panduan", "tips"],
    },
    {
      date: "2022-10-24 15:41:43",
      subject: "Tips Mengecek Akun yang Terindikasi Sebagai Spam",
      status: "published",
      services: ["SMTP Relay"],
      category: ["panduan", "tips"],
    },
    {
      date: "2022-10-24 15:41:43",
      subject: "Cara Menambahkan Record DNS pada cPanel",
      status: "published",
      services: ["SMTP Relay"],
      category: ["panduan", "tips"],
    },
    {
      date: "2022-10-24 15:41:43",
      subject: "Cara Menambahkan Record DNS pada Direct Admin",
      status: "draft",
      services: ["SMTP Relay"],
      category: ["panduan", "tips"],
    },
    {
      date: "2022-10-24 15:41:43",
      subject: "Konfigurasi pada Email Zimbra Server Versi 8.5 - 9.x",
      status: "draft",
      services: ["SMTP Relay"],
      category: ["panduan", "tips"],
    },
    {
      date: "2022-10-24 15:41:43",
      subject: "Konfigurasi pada Email Zimbra Server Versi 8.5 - 9.x",
      status: "draft",
      services: ["SMTP Relay"],
      category: ["panduan", "tips"],
    },
  ];
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: width > breakpoint ? 120 : 120,
      render: (data) => {
        const { date, time } = splitDate(data);
        return (
          <div className="flex flex-col">
            <p className="mb-0">{date}</p>
            <p className="mb-0 text-secondary text-xs" type="secondary">
              {time}
            </p>
          </div>
        );
      },
    },
    {
      title: "Judul Dokumentasi",
      dataIndex: "subject",
      key: "subject",
      ellipsis: true,
      render: (_, record) => (
        <div className="subject-documentation">
          <p className="mb-1">{record.subject}</p>
          <SubjectDocumentationButton record={record} />
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 120,
      ellipsis: true,
      render: (status) =>
        status === "draft" ? (
          <Badge text="Draft" status="error" />
        ) : (
          <Badge text="Published" status="success" />
        ),
    },
    {
      title: "Services",
      key: "services",
      dataIndex: "services",
      width: 140,
      ellipsis: true,
      render: (services) =>
        services.map((item, index) => (
          <Tag className="m-0" key={index}>
            {item}
          </Tag>
        )),
    },
    {
      title: "Category",
      key: "category",
      width: 160,
      dataIndex: "category",
      render: (category) =>
        category.map((item, index) => (
          <Tag className="mr-1" key={index}>
            {item}
          </Tag>
        )),
    },
  ];

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };

  const AccordionFilter = () => {
    return (
      <Form layout="inline" className="formFilter">
        {/* <div> */}
        <Form.Item label="Kategori" name="kategori">
          <Select
            defaultValue="all"
            className="filter-billing-report"
            placeholder="Semua Kategori"
            onChange={handleChange}>
            <Select.Option value="all">Semua Kategori</Select.Option>
            <Select.Option value="bronze">Bronze</Select.Option>
            <Select.Option value="silver">Silver</Select.Option>
            <Select.Option value="gold">Gold</Select.Option>
            <Select.Option value="premium">Premium</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Services" name="services">
          <Select
            defaultValue="all"
            className="filter-billing-report"
            placeholder="Semua Service"
            onChange={handleChange}>
            <Select.Option value="all">Semua Service</Select.Option>
            <Select.Option value="active">Bulanan</Select.Option>
            <Select.Option value="inactive">Triwulan</Select.Option>
            <Select.Option value="trial">Tahunan</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Status" name="status">
          <Select
            defaultValue="all"
            className="filter-billing-report"
            placeholder="Semua Status"
            onChange={handleChange}>
            <Select.Option value="all">Select Status</Select.Option>
            <Select.Option value="tersedia">Tersedia</Select.Option>
            <Select.Option value="unavailable">Tidak Tersedia</Select.Option>
          </Select>
        </Form.Item>
        {/* </div> */}
        <div className="button-advance-filter">
          <Button type="primary" icon={<ICFilterOutlined />}>
            Apply Filter
          </Button>
        </div>
      </Form>
    );
  };

  const SearchDocumentation = ({ disabled, onSearch }) => {
    return (
      <Input.Search
        placeholder="Cari dokumentasi..."
        className="input-company"
        enterButton
        disabled={disabled}
        onSearch={onSearch}
      />
    );
  };

  const CreateDocumentation = () => {
    return (
      <Button type="primary" icon={<ICPlusOutlined />}>
        <span className="hidden-lg">Tambah Baru</span>
      </Button>
    );
  };

  return (
    <Table
      className="documentation__table"
      dataSource={data}
      {...(width < breakpoint ? { scroll: { x: 1199 } } : {})}
      columns={columns}
      pagination={{
        hideOnSinglePage: true,
        // onChange: (params) => {
        //   changePage(params);
        // },
        showSizeChanger: false,
        size: "small",
      }}
      rowClassName="documentation__row"
      rowKey={(record) => record.subject}
      title={() => (
        <TableTitleWithCollapse
          title="Daftar Dokumentasi Layanan"
          desc="List dokumentasi layanan untuk panduan pengguna layanan"
          addsBefore={<SearchDocumentation />}
          addsAccordion={<AccordionFilter />}
          addsAfter={<CreateDocumentation />}
          breakpoint_third={breakpoint_third}
          responsiveWidth={width}
        />
      )}
    />
  );
};

export default TableDocumentation;
