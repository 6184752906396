import { Button, Divider, Form, Input, Select, Tabs, Spin } from "antd";
import { EditNotification, getNotificationDetailAdmin } from "api";
import DashboardPageHeader from "components/dashboard-header/DashboardPageHeader";
import MarkdownInput from "components/input/MarkdownInput";
import {
  ICCloseOutlined,
  ICEditOutlined,
  ICEyeOutlined,
  ICSaveOutlined,
} from "components/list/ListIcon";
import ModalConfirm from "components/popups/modal/ModalConfirm";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import React, { useState, useCallback, useEffect } from "react";
import { parse } from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import { requiredRules } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import ModalError from "components/popups/modal/ModalError";
import "./manageNotification.less";

const EditNotifAdmin = () => {
  useDocumentTitle("Edit Notifikasi");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    title: "",
    content: "",
    preview: "",
    category: "",
  });
  const { search } = useLocation();
  const { uuid } = parse(search);

  const getDetailNotification = useCallback(async () => {
    setLoading(true);
    const { data, code } = await getNotificationDetailAdmin({ uuid });
    if (code !== 200) {
      ModalError({
        title: "Notifikasi tidak ditemukan",
        message: "Harap masukan uuid notifikasi dengan benar",
        onOk: () => navigate("/admin/manage-notification"),
      });
    }
    setData(data);
    setLoading(false);
  }, [uuid, navigate, setLoading]);

  useEffect(() => {
    if (uuid) getDetailNotification();
  }, [uuid, getDetailNotification]);

  const onSelectStatus = (value) => setData({ ...data, category: value });

  const cancelNotification = () => {
    ModalConfirm({
      title: "Batal Edit Notifikasi?",
      customMessage: (
        <>
          <p>Apakah anda yakin ingin meninggalkan halaman notifikasi yang sedang anda edit?</p>
          <p className="mb-0">
            Seluruh perubahan notifikasi tidak akan tersimpan jika anda keluar.
          </p>
        </>
      ),
      okText: "Ya, tinggalkan halaman",
      okButtonProps: { danger: true },
      onOk: () => navigate("/admin/manage-notification"),
    });
  };

  const publishNewNotification = async () => {
    const notificationUuid = data.uuid;
    const { status } = await EditNotification({ notificationUuid, ...data });
    if (status === "success") {
      ModalSuccess({
        title: "Notifikasi Berhasil Disimpan",
        message: (
          <div>
            Notifikasi berjudul <span style={{ fontWeight: 500 }}>{data.title}</span> berhasil
            diperbaharui
          </div>
        ),
        onOk: () => {
          navigate("/admin/manage-notification");
        },
      });
    }
  };

  return (
    <div className="EditNotifAdmin">
      <DashboardPageHeader title="Edit Notifikasi" backPath="/admin/manage-notification" />
      {loading ? (
        <div className="flex justify-center items-center" style={{ minHeight: "100vh" }}>
          <Spin size="large" />
        </div>
      ) : (
        <div className="flex gap-x-6 px-6 py-4">
          <div className="EditNotifAdmin__left">
            <Form
              name="notification"
              className="w-full"
              layout="vertical"
              onFinish={publishNewNotification}>
              <Form.Item className="mb-4" rules={[requiredRules]}>
                <Input
                  value={data.title}
                  onChange={(e) => setData({ ...data, title: e.target.value })}
                />
              </Form.Item>
              <Form.Item
                label="Summary Notifikasi"
                rules={[requiredRules]}
                style={{ fontWeight: 700 }}>
                <Input.TextArea
                  autoSize={{ minRows: 2, maxRows: 2 }}
                  maxLength="255"
                  value={data.preview}
                  onChange={(e) => setData({ ...data, preview: e.target.value })}
                />
              </Form.Item>
              <Tabs
                defaultActiveKey="1"
                items={[
                  {
                    label: (
                      <span>
                        <ICEditOutlined />
                        Edit Notifikasi
                      </span>
                    ),
                    key: "edit",
                    children: (
                      <Form.Item className="mb-4" rules={[requiredRules]}>
                        <Input.TextArea
                          autoSize={{ minRows: 22, maxRows: 22 }}
                          value={data.content}
                          onChange={(e) => setData({ ...data, content: e.target.value })}
                        />
                      </Form.Item>
                    ),
                  },
                  {
                    label: (
                      <span>
                        <ICEyeOutlined />
                        Pratinjau
                      </span>
                    ),
                    key: "pratinjau",
                    children: <MarkdownInput data={data.content} />,
                  },
                ]}
              />
            </Form>
          </div>
          <div className="EditNotifAdmin__right">
            <div className="EditNotifAdmin__action bg-white p-6">
              <div className="flex gap-x-2 items-center my-1">
                <Button
                  type="default"
                  className="w-full"
                  icon={<ICCloseOutlined />}
                  onClick={cancelNotification}>
                  Batalkan
                </Button>
                <Button
                  type="primary"
                  className="w-full"
                  htmlType="submit"
                  icon={<ICSaveOutlined />}
                  form="notification">
                  Publish
                </Button>
              </div>
              <Divider className="my-4" />
              <div className="flex items-center justify-between">
                <span className="font-medium">Kategori</span>
                <Select
                  defaultValue={data.category}
                  className="category-list"
                  onSelect={onSelectStatus}>
                  <Select.Option value="info">Info</Select.Option>
                  <Select.Option value="promo">Promo</Select.Option>
                </Select>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditNotifAdmin;
