import React from "react";
import { useSelector } from "react-redux";
import DNSAlert from "./alert/DNSAlert";
import DomainAlert from "./alert/DomainAlert";
import LockedRelayAlert from "./alert/LockedRelayAlert";
import "./alert/mainalert.less";
import PaymentAlert from "./alert/PaymentAlert";
import SuspendAlert from "./alert/SuspendAlert";
import TrialAlert from "./alert/TrialAlert";
import OverQouta from "./alert/OverQouta";
import "./cta/cta.less";
import DocumentationCTA from "./cta/DocumentationCTA";
import ProgressCTA from "./cta/ProgressCTA";
import { WarningOverQuota } from "./alert/OverQouta";

const hasKeys = (params) => params && Object.keys(params).length > 0;

const CallToAction = () => {
  const user = useSelector(({ userData }) => userData.value);

  const {
    billing,
    trialSession,
    inactiveDomain,
    isSetupDomain,
    relay_status,
    dnsStatus,
    role,
    overQuota,
    overQuotaUsage,
  } = user;

  const listItem = [
    {
      title: "Lakukan verifikasi alamat email",
      description:
        "Cek email anda dan lakukan verifikasi dengan klik link yang tersedia pada email dari aktiva.co.id",
      isDone: user.isActive,
    },
    {
      title: "Lengkapi data perusahaan",
      description: "Lengkapi data perusahaan anda untuk kebutuhan data transaksi",
      isDone: user.isSetUpCompany,
      to: "/register/company-data",
    },
    {
      title: "Setup & aktivasi trial layanan",
      description: "Masukkan nama domain dan lakukan setup domain untuk mengaktifkan trial layanan",
      isDone: user.isSetupDomain,
      to: "/setup",
    },
    {
      title: "Aktivasi domain & akun relay anda",
      description: "Sesuaikan record DNS domain anda dan lakukan aktivasi",
      isDone: user.isRelayActive,
      to: "/setup",
    },
  ];

  const isTrue = listItem.filter((item) => item.isDone === true).length;
  const hasBilling = hasKeys(billing);
  const hasTrial = hasKeys(trialSession);
  const hasInactiveDomain = inactiveDomain && isSetupDomain;
  const hasDNSDomain = dnsStatus && isSetupDomain;
  const isOverQuota = hasKeys(overQuotaUsage);
  const hasOverQuota = overQuota !== false ? true : false;
  const redering =
    hasInactiveDomain ||
    hasBilling ||
    relay_status !== "active" ||
    (hasTrial && trialSession.different_day <= 3) ||
    isOverQuota === false ||
    isOverQuota === 0 ||
    hasOverQuota;
  const isBilling = role === "billing";
  const isTechnical = role === "technical";

  return (
    <div className={`${redering ? "flex flex-col gap-y-3" : "hidden"}`}>
      {isTrue < 4 && <ProgressCTA isTrue={isTrue} listItem={listItem} />}
      {isTrue < 4 && !isBilling && <DocumentationCTA />}
      {hasInactiveDomain && !isBilling && <DomainAlert domain={user.inactiveDomain} user={user} />}
      {hasDNSDomain && !isBilling && <DNSAlert domain={user.dnsStatus} user={user} />}
      {hasBilling && !isTechnical && <PaymentAlert />}
      {hasBilling && billing.status === "overdue" && relay_status === "suspended" && (
        <SuspendAlert />
      )}
      {hasTrial && !hasBilling && <TrialAlert {...trialSession} />}
      {relay_status === "locked" && !isBilling && isTrue === 4 && <LockedRelayAlert user={user} />}
      {hasOverQuota && <OverQouta />}
      {(isOverQuota === false || isOverQuota === 0) && hasOverQuota === false && (
        <WarningOverQuota user={user} />
      )}
    </div>
  );
};

export default CallToAction;
