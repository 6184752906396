import { Button, Card, Col, Row } from "antd";
import { getUserNotification, markReadNotification } from "api";
import { ICCheckOutlined } from "components/list/ListIcon";
import ListNotification from "components/list/ListNotification";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationList } from "redux/slices/notificationList";
import useGetCompanyPackage from "utils/hooks/useGetCompanyPackage";
import useDocumentTitle from "utils/useDocumentTitle";
import "./notification.less";
import NotificationInformationCard from "./NotificationInformationCard";

const ReadNotification = ({ getNotification }) => {
  const doRead = async () => {
    const { code } = await markReadNotification();
    if (code === 200) getNotification();
  };

  return (
    <Button icon={<ICCheckOutlined />} onClick={doRead}>
      Tandai Semua sudah dibaca
    </Button>
  );
};

const Notification = () => {
  useDocumentTitle("Notification");
  useGetCompanyPackage();
  const { isAdmin } = useSelector(({ userData }) => userData.value);
  const dispatch = useDispatch();

  const getNotification = async () => {
    const { data } = isAdmin ? "" : await getUserNotification();
    dispatch(setNotificationList(data));
  };

  return (
    <div className="notification-page w-full px-6 py-4">
      <Row gutter={[24, 24]}>
        <Col xl={isAdmin ? 24 : 18} lg={24} md={24} sm={24} xs={24}>
          <Card
            title="Semua Notifikasi"
            extra={<ReadNotification getNotification={getNotification} />}
            className="notification-list-card">
            <ListNotification position="page" getNotification={getNotification} />
          </Card>
        </Col>
        {!isAdmin && (
          <Col xl={6} lg={24} md={24} sm={24} xs={24}>
            <NotificationInformationCard />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default Notification;
