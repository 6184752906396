import { Button, Card, Collapse, Space, Table, Tag, Typography } from "antd";
import { checkDNS } from "api";
import laptop from "assets/images/laptop.svg";
import { EmptyWrapper } from "components/empty/EmptyComponent";
import {
  ICCheckCircleFilled,
  ICCheckCircleOutlined,
  ICCloseCircleFilled,
  ICCloseCircleOutlined,
  ICReloadOutlined,
  ICUpOutlined,
} from "components/list/ListIcon";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { setListDomain } from "redux/slices/listDomain";
import "./setupcard.less";

const { Panel } = Collapse;
const { Paragraph } = Typography;

const CustomHeader = ({ rotate, uuid, loading, setLoading, item, listDomain }) => {
  const dispatch = useDispatch();

  const [click, setClick] = useState(rotate);
  const refreshDNS = async (e) => {
    setLoading(item.domainName);
    e.stopPropagation();
    const res = await checkDNS({ domainUuid: uuid });

    const validasiDNS = listDomain.map((i) => {
      if (i.domainName === item.domainName) {
        return { ...i, ...res.data };
      }
      return i;
    });

    dispatch(setListDomain(validasiDNS));
    setLoading(false);
  };
  return (
    <Space size={8}>
      <Button icon={<ICReloadOutlined spin={loading === item.domainName} />} onClick={refreshDNS}>
        Cek Status DNS
      </Button>
      <Button type="link" className="text-success p-0" onClick={() => setClick((prev) => !prev)}>
        Konfigurasi DNS <ICUpOutlined className="text-xs" rotate={click ? 0 : 180} />
      </Button>
    </Space>
  );
};

const TitleAccordion = ({ items: { domainName, spf, dmarc, dkim, aRecord } }) => {
  return (
    <div className="flex items-center gap-x-4">
      <span className="font-medium">{domainName}</span>
      <div className="flex flex-wrap gap-x-1 gap-y-1">
        <Tag
          color={spf.status ? "blue" : "red"}
          icon={spf.status ? <ICCheckCircleFilled /> : <ICCloseCircleFilled />}
          className={`mr-0${spf.status ? " text-info" : " ant-tag-rose"}`}>
          SPF
        </Tag>
        <Tag
          color={dmarc.status ? "blue" : "red"}
          icon={dmarc.status ? <ICCheckCircleFilled /> : <ICCloseCircleFilled />}
          className={`mr-0${dmarc.status ? " text-info" : " ant-tag-rose"}`}>
          DMARC
        </Tag>
        <Tag
          color={dkim.status ? "blue" : "red"}
          icon={dkim.status ? <ICCheckCircleFilled /> : <ICCloseCircleFilled />}
          className={`mr-0${dkim.status ? " text-info" : " ant-tag-rose"}`}>
          DKIM
        </Tag>
        <Tag
          color={aRecord.status ? "blue" : "red"}
          icon={aRecord.status ? <ICCheckCircleFilled /> : <ICCloseCircleFilled />}
          className={`mr-0${aRecord.status ? " text-info" : " ant-tag-rose"}`}>
          A Record
        </Tag>
      </div>
    </div>
  );
};

const StatusEmailServer = () => {
  const [loading, setLoading] = useState(false);
  const listDomain = useSelector(({ listDomain }) => listDomain.value);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1199;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      className: "dns-table",
      width: width > breakpoint ? 105 : 105,
      fixed: "left",
    },
    {
      title: "Host",
      dataIndex: "host",
      key: "host",
      className: "dns-table",
      width: 320,
      render: (host) => <Paragraph copyable>{host}</Paragraph>,
    },
    Table.EXPAND_COLUMN,
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      className: "dns-table",
      render: (value) => <Paragraph copyable>{value}</Paragraph>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      className: "dns-table",
      width: 105,
      render: (status) => (
        <Tag className="m-0" color={status ? "blue" : "red"}>
          {status ? "OK" : "Not Ok"}
        </Tag>
      ),
    },
  ];

  const SetupTable = ({ data, item }) => {
    return (
      <Table
        className="status-email__table"
        dataSource={data}
        columns={columns}
        {...(width < breakpoint ? { scroll: { x: 1024 } } : {})}
        pagination={false}
        expandable={{
          rowExpandable: (record) => record.type === "A Record",
          expandedRowClassName: (_, index) => `${index} uhuy`,
          expandedRowRender: (record, index, indent, expanded) => {
            const columnss = [
              {
                key: "type",
                width: 105,
              },
              {
                key: "host",
                width: 320,
              },
              {
                key: "value",
                dataIndex: "value",
                render: (_, record) => (
                  <div className="flex items-center gap-x-2">
                    {record.status ? (
                      <>
                        <ICCheckCircleOutlined className="text-success" />
                        <p className="m-0 text-success">Record sudah tersedia dan valid</p>
                      </>
                    ) : (
                      <>
                        <ICCloseCircleOutlined className="text-danger" />
                        <p className="m-0 text-danger">
                          Record tidak tersedia, silakan tambahkan record IP Address website atau
                          email server anda atau bisa ditambahkan dengan IP localhost diatas pada
                          DNS
                        </p>
                      </>
                    )}
                  </div>
                ),
              },
              {
                key: "status",
                width: 105,
              },
            ];
            return (
              <Table
                className="status-email__nested"
                columns={columnss}
                dataSource={[record]}
                bordered={false}
                showHeader={false}
                pagination={false}
                rowKey={(record) => record.host}
              />
            );
          },
        }}
        rowKey={(record) => record.host}
        rowClassName={(record) => (record.status ? "icon-success" : "icon-danger")}
      />
    );
  };

  if (!listDomain) return <Skeleton className="w-full" height={372} />;

  return (
    <Card className="status-email-server-card" id="dns-reputation">
      <div className="status-email-server-body-text">
        <h5 className="mb-1 font-medium text-base">Informasi Penambahan Reputasi Email Server</h5>
        {listDomain && listDomain.length > 0 && (
          <p className="mb-0 text-secondary">
            Penambahan reputasi untuk meningkatkan akurasi penerimaan email di inbox penerima dengan
            menambahkan record SPF, DMARC dan DKIM di DNS Public.
          </p>
        )}
      </div>
      {listDomain && listDomain.length > 0 ? (
        <Collapse className="setup-collapse" ghost>
          {listDomain.map((domain) => (
            <Panel
              key={domain.domainUuid}
              header={
                <CustomHeader
                  rotate={false}
                  uuid={domain.domainUuid}
                  loading={loading}
                  setLoading={setLoading}
                  item={domain}
                  listDomain={listDomain}
                />
              }
              extra={<TitleAccordion items={domain} />}
              showArrow={false}
              collapsible="header"
              className={
                domain.spf.status &&
                domain.dmarc.status &&
                domain.dkim.status &&
                domain.aRecord.status
                  ? "collapse-success"
                  : "collapse-fail blob"
              }>
              <SetupTable
                data={[domain.spf, domain.dmarc, domain.dkim, domain.aRecord]}
                item={domain}
              />
            </Panel>
          ))}
        </Collapse>
      ) : (
        <EmptyWrapper
          image={laptop}
          title="Anda Belum Memiliki Domain Aktif"
          className="py-4 empty-collapse mx-auto"
          message={`Silakan melakukan aktivasi domain agar domain anda terdaftar dan dapat melakukan pengiriman menggunakan ${process.env.REACT_APP_NAME}.`}
          msgClassName="max-w-400"
        />
      )}
    </Card>
  );
};

export default StatusEmailServer;
