import { createSlice } from "@reduxjs/toolkit";

export const EmailAPI = createSlice({
  name: "EmailAPI",
  initialState: {
    value: false, // default state
  },
  reducers: {
    // reducers action
    setEmailAPI: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { setEmailAPI } = EmailAPI.actions;
export default EmailAPI.reducer;
