import { Card, Tabs } from "antd";
import { ICInboxOutlined, ICHistoryOutlined } from "components/list/ListIcon";
import { getSuppressionList, getHistoryList } from "api";
import { useState, useCallback, useEffect } from "react";
import TableSuppress from "components/table/TableSuppress";
import TableHistory from "components/table/TableHistory";
import { useDispatch, useSelector } from "react-redux";
import { setSuppressionList } from "redux/slices/SuppressionList";
import { setHistoryList } from "redux/slices/HistoryList";

const SuppressSection = () => {
  const dispatch = useDispatch();
  const [activeTabs, setActiveTabs] = useState("1");
  const handleChange = (key) => setActiveTabs(key);
  const [showSkeletonSuppress, setShowSkeletonSuppress] = useState(false);
  const [showSkeletonHistory, setShowSkeletonHistory] = useState(false);

  const [filterSuppress, setfilterSuppress] = useState({
    limit: "10",
    page: 1,
  });

  const [filterHistory, setfilterHistory] = useState({
    limit: "10",
    page: 1,
  });
  const isSuppress = useSelector(({ SuppressionList }) => SuppressionList.value);
  const isHistory = useSelector(({ HistoryList }) => HistoryList.value);

  const getDataSuppress = useCallback(async () => {
    setShowSkeletonSuppress(true);
    const { data, code } = await getSuppressionList(filterSuppress);
    if (data && Object.keys(data).length) dispatch(setSuppressionList({ data, code }));
    setShowSkeletonSuppress(false);
  }, [dispatch, filterSuppress]);

  const getDataHistory = useCallback(async () => {
    setShowSkeletonHistory(true);
    const { data, code } = await getHistoryList(filterHistory);
    if (data && Object.keys(data).length) dispatch(setHistoryList({ data, code }));
    setShowSkeletonHistory(false);
  }, [dispatch, filterHistory]);

  useEffect(() => {
    getDataSuppress();
    getDataHistory();
  }, [getDataSuppress, getDataHistory]);

  return (
    <Card>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTabs}
        className="billing-tab"
        onChange={handleChange}
        items={[
          {
            label: (
              <span>
                <ICInboxOutlined />
                Suppression List
              </span>
            ),
            key: "1",
            children: (
              <div>
                <TableSuppress
                  dataSuppress={isSuppress}
                  filterSuppress={filterSuppress}
                  setFilter={setfilterSuppress}
                  dataHistory={isHistory}
                  filterHistory={filterHistory}
                  setShowSkeleton={setShowSkeletonSuppress}
                  showSkeleton={showSkeletonSuppress}
                  getDataSuppress={getDataSuppress}
                />
              </div>
            ),
          },
          {
            label: (
              <span>
                <ICHistoryOutlined />
                History Aktivitas
              </span>
            ),
            key: "2",
            children: (
              <div>
                <TableHistory
                  dataHistory={isHistory}
                  filterHistory={filterHistory}
                  setFilter={setfilterHistory}
                  setShowSkeleton={setShowSkeletonHistory}
                  showSkeleton={showSkeletonHistory}
                  getDataHistory={getDataHistory}
                />
              </div>
            ),
          },
        ]}
      />
    </Card>
  );
};

export default SuppressSection;
