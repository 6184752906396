import { Button, Card, Collapse, Form, Input, Select } from "antd";
import { getListDomainAdmin, getTableLogAdmin } from "api";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import DatePicker from "components/datepicker/DatePicker";
import { ICCloseOutlined, ICSearchOutlined, ICUpOutlined } from "components/list/ListIcon";
import TableLog from "components/table/TableLog";
import { endOfDay, format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setListDomainAdmin } from "redux/slices/admin/listDomainAdmin";
import { setLogEmailAdmin } from "redux/slices/admin/logEmailAdmin";
import useDocumentTitle from "utils/useDocumentTitle";
import "./log.less";

const ExtraHeader = ({ filter, setFilter, onSearchDomain, name, listDomains }) => {
  // Get list domain
  const dispatch = useDispatch();
  const listDomainAdmin = useSelector(({ listDomainAdmin: domainList }) => domainList.value);
  const getDataDomain = useCallback(async () => {
    const { data } = await getListDomainAdmin();
    dispatch(setListDomainAdmin(data));
  }, [dispatch]);

  useEffect(() => {
    if (!listDomainAdmin) getDataDomain();
  }, [getDataDomain, listDomainAdmin]);
  // end list domain

  const handleChangeDomain = async (domain) => {
    setFilter({ ...filter, domain, page: 1 });
  };

  const handleChangeStatus = async (status) => {
    setFilter({ ...filter, status, page: 1 });
  };

  const showDomain = listDomains.length > 0 ? listDomains : listDomainAdmin;

  return (
    <div className="flex justify-between items-center">
      <span className="title-log-email">Filter</span>
      <div className="flex gap-x-6 items-center">
        <div className="flex gap-x-1 items-center">
          <span className="hidden-xs">Domain:</span>
          <Select
            name="domain"
            onClick={(e) => e.stopPropagation()}
            onChange={handleChangeDomain}
            placeholder={<span className="text-primary">Semua Domain</span>}
            className="domain-list"
            dropdownRender={(menu) => (
              <>
                <Input
                  placeholder="Ketik Domain.."
                  value={name}
                  onChange={(e) => onSearchDomain(e)}
                />
                {menu}
              </>
            )}>
            <Select.Option value="">Semua Domain</Select.Option>
            {showDomain?.length &&
              showDomain.map(({ name }, index) => (
                <Select.Option key={name + index} value={name}>
                  {name}
                </Select.Option>
              ))}
          </Select>
        </div>
        <div className="flex gap-x-1 items-center">
          <span className="hidden-xs">Status:</span>
          <Select
            name="status"
            placeholder={<span className="text-primary">Semua Status</span>}
            className="status-list"
            popupClassName="filter-dropdown"
            onChange={handleChangeStatus}
            onClick={(e) => e.stopPropagation()}>
            <Select.Option value="">Semua Status</Select.Option>
            <Select.Option value="sent">Delivered</Select.Option>
            <Select.Option value="bounced">Failed</Select.Option>
          </Select>
        </div>
      </div>
    </div>
  );
};

const HeaderPanel = () => {
  const [click, setClick] = useState(false);

  return (
    <div className="flex items-center w-full">
      <span className="text-success" onClick={() => setClick((prev) => !prev)}>
        Advanced Filter
      </span>
      <ICUpOutlined className="ml-1 text-success" rotate={click ? 0 : 180} />
    </div>
  );
};

const RenderTable = ({ filter, setFilter, loading, getData, data }) => {
  if (data)
    return (
      <TableLog
        filter={filter}
        setFilter={setFilter}
        loading={loading}
        hitApi={getData}
        emptyState={{
          title: data.status === "success" ? "Data tidak ditemukan" : "Data belum tersedia",
          message:
            data.status === "success"
              ? "Email log yang anda cari tidak ditemukan, pastikan tidak ada salah ketik pada penulisan anda"
              : `Email log akan muncul setelah client melakukan pengiriman email menggunakan akun ${process.env.REACT_APP_NAME}.`,
        }}
        role="admin"
      />
    );

  return <Card loading />;
};

const CollapseFilter = ({ filter, setFilter, getData }) => {
  const [form] = Form.useForm();
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [listDomains, setListDomains] = useState([]);
  const [name, setName] = useState("");
  const listDomainAdmin = useSelector(({ listDomainAdmin: domainList }) => domainList.value);

  const resetfilter = () => {
    form.resetFields();
    delete filter.datetime;
    delete filter.to_datetime;
    let filtered = {
      ...filter,
      page: 1,
      sender: "",
      recipient: "",
    };
    setFilter(filtered);
    getData();
  };

  const onChangeDatetime = (values) => {
    if (values && values.length > 0) {
      const startDate = format(new Date(values[0]), "yyyy-MM-dd");
      const endDate = format(new Date(values[1]), "yyyy-MM-dd");
      setStartDate(`${startDate} 00:00:00`);
      setEndDate(`${endDate} 23:59:59`);
    } else {
      setStartDate(false);
      setEndDate(false);
    }
  };

  const handleAdvanceFilter = async (values) => {
    const { sender, recipient } = values;
    delete filter.datetime;
    delete filter.to_datetime;
    let filtered = { ...filter, sender, recipient, page: 1 };
    if (startDate && endDate)
      filtered = {
        ...filter,
        page: 1,
        sender,
        recipient,
        datetime: startDate,
        to_datetime: endDate,
      };
    setFilter(filtered);
  };

  const onSearchDomain = ({ target: { value } }) => {
    setName(value);
    if (value) {
      const filter = listDomainAdmin.filter((domain) =>
        domain.name.toLowerCase().includes(value.toLowerCase())
      );
      setListDomains(filter);
    } else setListDomains([]);
  };

  const disabledDate = (current) => {
    return current && current > endOfDay(new Date());
  };

  return (
    <Collapse defaultActiveKey={["0"]} ghost className="log-collapse">
      <Collapse.Panel
        header={<HeaderPanel />}
        key="1"
        extra={
          <ExtraHeader
            filter={filter}
            setFilter={setFilter}
            onSearchDomain={onSearchDomain}
            listDomains={listDomains}
            name={name}
          />
        }
        showArrow={false}
        collapsible="header"
        className="header-log-email">
        <Form
          layout="inline"
          className="formFilter"
          form={form}
          onFinish={handleAdvanceFilter}
          initialValues={{ sender: "", recipient: "", datetime: "" }}>
          <div>
            <Form.Item label="Sender" name="sender" className="form-sender">
              <Input placeholder="sender@domain.tld" className="flex" />
            </Form.Item>
            <Form.Item label="Recipient" name="recipient" className="form-recipient">
              <Input placeholder="recipient@domain.tld" className="flex" />
            </Form.Item>
            <Form.Item name="datetime" className="form-daterange">
              <DatePicker.RangePicker
                className="w-full"
                disabledDate={disabledDate}
                onChange={onChangeDatetime}
                format="yyyy-MM-dd"
              />
            </Form.Item>
          </div>
          <div className="button-advance-filter">
            <Button onClick={resetfilter} icon={<ICCloseOutlined />}>
              Reset
            </Button>
            <Button type="primary" icon={<ICSearchOutlined />} htmlType="submit">
              Cari
            </Button>
          </div>
        </Form>
      </Collapse.Panel>
    </Collapse>
  );
};

const LogEmail = () => {
  useDocumentTitle("Log Email");
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    domain: "",
    status: "",
    sender: "",
    recipient: "",
    page: 1,
    limit: "10",
  });
  const dispatch = useDispatch();
  const logEmailAdmin = useSelector(({ logEmailAdmin }) => logEmailAdmin.value);

  const hitApiAdmin = useCallback(async () => {
    setLoading(true);
    const { data, status } = await getTableLogAdmin(filter);
    dispatch(setLogEmailAdmin({ tableLogAdmin: data, status }));
    setLoading(false);
  }, [filter, dispatch]);

  useEffect(() => {
    if (filter) hitApiAdmin();
  }, [hitApiAdmin, filter]);

  return (
    <div className="log-admin">
      <DashboardHeader title="Log Email Terkirim" />
      <div className="px-6 py-4">
        <div className="flex flex-col gap-y-6">
          <CollapseFilter filter={filter} setFilter={setFilter} getData={hitApiAdmin} />
          <RenderTable
            data={logEmailAdmin}
            filter={filter}
            setFilter={setFilter}
            getData={hitApiAdmin}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};
export default LogEmail;
