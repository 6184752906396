import { StopOutlined } from "@ant-design/icons";
import { Button, Descriptions, Drawer, Tag, Typography } from "antd";
import { ICCheckOutlined, ICCloseOutlined, ICEyeOutlined } from "components/list/ListIcon";
import { handleReject } from "utils/handleFunction";
import { capitalizeFirstLetter } from "utils/helpers";

export const DrawerConfirm = ({
  open,
  close,
  dataRow,
  setModalConfirmation,
  callBackConfirm,
  setOpenDrawerConfirmation,
  zIndex,
}) => {
  const navInvoice = () => window.open(dataRow.invoiceUrl, "_blank");
  return (
    <Drawer
      className="drawer-log-detail title-report-email footer-action"
      title="Payment Information Details"
      width={600}
      open={open}
      onClose={close}
      extra={
        <Button icon={<ICCloseOutlined />} type="text" onClick={close} className="text-success">
          Close
        </Button>
      }
      closable={false}
      zIndex={zIndex}
      footer={
        <div className="flex justify-between items-center ">
          <Typography.Text className="font-bold">Take Action</Typography.Text>
          <div className="flex gap-x-2">
            <Button
              type="primary"
              icon={<ICCheckOutlined />}
              disabled={dataRow.paymentStatus !== "pending-confirmation"}
              onClick={() => setModalConfirmation(true)}>
              Konfirmasi
            </Button>
            <Button
              danger
              icon={<StopOutlined />}
              disabled={dataRow.paymentStatus !== "pending-confirmation"}
              onClick={() =>
                handleReject({ record: dataRow, callBackConfirm, setOpenDrawerConfirmation })
              }>
              Tolak Pembayaran
            </Button>
          </div>
        </div>
      }>
      <Descriptions
        layout="horizontal"
        column={1}
        bordered
        className="drawer-desc-wrapper"
        labelStyle={{ padding: "4px 12px" }}
        contentStyle={{ padding: "13px 12px" }}>
        <Descriptions.Item label="Tanggal">{dataRow.paymentDate}</Descriptions.Item>
        <Descriptions.Item label="Nama Perusahaan">{dataRow.companyName}</Descriptions.Item>
        <Descriptions.Item label="Payment Status">
          <Tag
            color={
              dataRow.paymentStatus === "confirmed"
                ? "green"
                : dataRow.paymentStatus === "pending-confirmation"
                ? "warning"
                : "red"
            }
            className={`invoice-table-tag${
              dataRow.paymentStatus === "confirmed" ? " paid-tag" : ""
            }`}>
            {capitalizeFirstLetter(
              dataRow.paymentStatus === "confirmed"
                ? "Confirmed"
                : dataRow.paymentStatus === "pending-confirmation"
                ? "Pending"
                : "Declined"
            )}
          </Tag>
        </Descriptions.Item>
        <Descriptions.Item label="Invoice Number">
          <span className="mb-0 pointer text-info" onClick={navInvoice}>
            {dataRow.invNumber}
          </span>
        </Descriptions.Item>
        <Descriptions.Item label="Payment Amount">{dataRow.paymentAmount}</Descriptions.Item>
        <Descriptions.Item label="Source Bank">{dataRow.sourceBank}</Descriptions.Item>
        <Descriptions.Item label="Destination Bank">{dataRow.destinationBank}</Descriptions.Item>
        <Descriptions.Item label="Proof of Payment">
          <Button icon={<ICEyeOutlined />} href={dataRow.proofUrl} target="_blank">
            View Payment
          </Button>
        </Descriptions.Item>
      </Descriptions>
    </Drawer>
  );
};
