import { Button, Card, Checkbox } from "antd";
import DashboardPageHeader from "components/dashboard-header/DashboardPageHeader";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useDocumentTitle from "utils/useDocumentTitle";
import "./overusage-confirmation.less";
import ModalConfirm from "components/popups/modal/ModalConfirm";
import { WarningOverQuota } from "../../main/alert/OverQouta";

const OverusageConfirmation = () => {
  useDocumentTitle("Confirmation");
  const navigate = useNavigate();
  const location = useLocation();

  const [checked, setChecked] = useState(false);

  const toggleCheck = () => setChecked((prev) => !prev);

  const handleConfirm = () => {
    ModalConfirm({
      title: "Apakah Anda yakin ingin menggunakan over quota?",
      customMessage: (
        <div>
          <p className="mb-6">
            Anda akan dikenakan biaya tambahan sesuai dengan penggunaan email berlebih yang
            dikirimkan.
            <br /> <br /> Akun anda akan diaktifkan kembali dan dapat mengirimkan email setelah
            melakukan konfirmasi tersebut.
          </p>
        </div>
      ),
      okText: "Ya, Lanjutkan",
      cancelText: "Batalkan",
      onOk: () => {
        navigate("/overusage/process");
      },
    });
  };

  return (
    <div className="OverusageConfirmation">
      <DashboardPageHeader title="Over Quota Usage" backPath={location.state?.referrer} />
      <div className="flex flex-col px-6 pt-3 gap-y-6">
        <WarningOverQuota />
        <Card className="OverusageConfirmation__card" title="Konfirmasi Over Quota Usage">
          <div className="OverusageConfirmation__body">
            <p>
              Dengan melakukan konfirmasi penggunaan kelebihan kuota layanan tersebut, berarti
              pelanggan setuju dengan ketentuan berikut:
            </p>
            <ul>
              <li>
                Layanan akan diaktifkan kembali (unsuspend) dan pelanggan dapat menggunakan layanan
                serta mengirimkan email kembali
              </li>
              <li>
                Pelanggan akan dikenakan biaya kelebihan penggunaan (over usage) sesuai dengan
                jumlah kuota berlebih yang digunakan
              </li>
              <li>
                Biaya kelebihan penggunaan email tersebut yakni sebesar Rp100.000 per 10.000 kiriman
                email
              </li>
              <li>Tagihan akan dikirimkan diakhir periode kuota dari layanan tersebut</li>
              <li>Pelanggan wajib melakukan pembayaran tagihan kelebihan kuota tersebut</li>
              <li>
                Apabila pelanggan tidak kunjung membayar tagihan setelah tanggal jatuh tempo, maka
                layanan akan terkena suspend
              </li>
            </ul>
          </div>
          <div className="flex flex-col items-start gap-y-1">
            <Checkbox
              className="text-secondary"
              onChange={toggleCheck}
              checked={checked}
              defaultChecked={checked}>
              Saya telah membaca dan menyetujui ketentuan diatas
            </Checkbox>
            <Button type="primary" disabled={!checked} className="ml-auto" onClick={handleConfirm}>
              Konfirmasi & Lanjutkan
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default OverusageConfirmation;
