import { Col, Row } from "antd";
import { useEffect, useCallback, useState } from "react";
import { setCheckoutChecker } from "redux/slices/checkoutChecker";
import useDocumentTitle from "utils/useDocumentTitle";
import { getDetailServicePackage } from "api";
import "./billing.less";
import InformationSubscribe from "./InformationSubscribe";
import InvoiceSection from "./InvoiceSection";
import NextBill from "./NextBill";
import { DetailOverQuota } from "./DetailOverQuota";
import { setServicePackage } from "redux/slices/servicePackage";
import { useDispatch, useSelector } from "react-redux";
import { getInfoOverQuota } from "api";
import { setInfoOverQuota } from "redux/slices/infoOverQuota";

const Billing = () => {
  useDocumentTitle("Tagihan");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showSkeleton, setshowSkeleton] = useState(false);
  const isChecked = useSelector(({ checkoutChecker }) => checkoutChecker.value);
  const user = useSelector(({ userData }) => userData.value);
  useEffect(() => {
    if (isChecked.checked) dispatch(setCheckoutChecker({ ...isChecked, checked: false }));
  }, [dispatch, isChecked]);

  const servicePackage = useSelector(({ servicePackage }) => servicePackage.value);

  const getPackage = useCallback(async () => {
    setLoading(true);
    const { data } = await getDetailServicePackage({ companyId: 1 });
    dispatch(setServicePackage(data));
    setTimeout(setLoading, 1000, false);
  }, [dispatch]);

  useEffect(() => {
    if (!servicePackage) getPackage();
  }, [getPackage, servicePackage]);

  const infoQuota = useSelector(({ infoOverQuota }) => infoOverQuota.value);

  const getInfoQuota = useCallback(async () => {
    setshowSkeleton(true);
    const { data } = await getInfoOverQuota();
    dispatch(setInfoOverQuota(data));
    setTimeout(setshowSkeleton, 1000, false);
  }, [dispatch]);

  useEffect(() => {
    if (!infoQuota) getInfoQuota();
  }, [infoQuota, getInfoQuota]);

  return (
    <div className="billing-user flex flex-col gap-y-6 px-6 py-4">
      <Row gutter={[24, 8]}>
        <Col lg={9} xl={6} className="w-full card-nextbill">
          <NextBill servicePackage={servicePackage} />
        </Col>
        <Col lg={15} xl={18} className="w-full card-current-payment">
          {user.overQuotaUsage !== 0 && user.overQuotaUsage ? (
            <DetailOverQuota showSkeleton={showSkeleton} infoQuota={infoQuota} />
          ) : undefined}
          <InformationSubscribe loading={loading} servicePackage={servicePackage} />
        </Col>
      </Row>
      <InvoiceSection />
    </div>
  );
};

export default Billing;
