import { getPackageListFilter } from "api";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPackageListFilter } from "redux/slices/admin/packageListFilter";

const useGetPackageListFilter = () => {
  const packageList = useSelector(({ packageListFilter }) => packageListFilter.value);

  const dispatch = useDispatch();
  const action = setPackageListFilter;

  const doSet = useCallback((data) => dispatch(action(data)), [dispatch, action]);

  useEffect(() => {
    const getPackageList = async () => {
      const { data } = await getPackageListFilter({ short: true });
      if (data && Object.keys(data).length) doSet(data);
    };
    if (!packageList) getPackageList();
  }, [packageList, doSet]);
};

export default useGetPackageListFilter;
