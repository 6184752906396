import { getListCompany } from "api";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setListAllCompany } from "redux/slices/admin/listAllCompany";

export const useGetListCompany = () => {
  const dispatch = useDispatch();
  const listCompany = useSelector(({ listAllCompany }) => listAllCompany.value);

  const getData = useCallback(async () => {
    const { data } = await getListCompany();
    dispatch(setListAllCompany(data));
  }, [dispatch]);

  useEffect(() => {
    if (!listCompany) getData();
  }, [getData, listCompany]);
};
