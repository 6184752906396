export const btnShowHidePassword = (setHidePassword) => {
  setHidePassword((prev) => !prev);
};

export const btnCopyPassword = (char = "", setCopyPassword) => {
  if (!char) return "";
  const el = document.body.appendChild(document.createElement("input"));
  el.type = "hidden";
  el.value = char;
  el.focus();
  el.select();
  document.execCommand("copy");
  window.navigator.clipboard.writeText(char);
  setCopyPassword((prev) => !prev);
  setTimeout(setCopyPassword, 4000, true);
};
