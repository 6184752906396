import { createSlice } from "@reduxjs/toolkit";

export const listAdmin = createSlice({
  name: "listAdmin",
  initialState: {
    value: false,
  },
  reducers: {
    setListAdmin: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setListAdmin } = listAdmin.actions;
export default listAdmin.reducer;
