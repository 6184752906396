import { Button } from "antd";
import { unlockRelay } from "api";
import dataMaintenance from "assets/images/data_maintenance.svg";
import ModalConfirm from "components/popups/modal/ModalConfirm";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import ModalWarning from "components/popups/modal/ModalWarning";
import ModalWarningLocked from "components/popups/modal/ModalWarningLocked";
import { useDispatch } from "react-redux";
import { setUserData } from "redux/slices/user";

const LockedRelayAlert = ({ user }) => {
  const dispatch = useDispatch();
  const showModal = () => {
    ModalConfirm({
      title: `Unlock akun ${process.env.REACT_APP_NAME}?`,
      customMessage: (
        <>
          <p>
            Sebelum melakukan unlock akun, pastikan sudah melakukan pembersihan email spam pada
            email server anda agar akun tidak terkena lock kembali.
          </p>
          <p>
            Jika akun anda terkena lock sebanyak 3 kali berturut-turut, maka fitur unlock akun
            secara mandiri akan dinonaktifkan.
          </p>
        </>
      ),
      okButtonProps: { danger: true },
      okText: "Unlock akun",
      onOk: async () => {
        const { data, status, msg, code } = await unlockRelay();
        if (
          status === "failed" &&
          msg === "Company does not have any active subscriptions!" &&
          code === 400
        ) {
          ModalWarningLocked({
            title: `Unlock akun SMTP Gagal`,
            customMessage: (
              <div>
                <p>
                  Akun SMTP anda gagal diaktifkan karena anda tidak memiliki subscription yang
                  aktif. Silakan dapat berlangganan layanan kami agar dapat melakukan unlock account
                  dan terus menggunakan layanan kami.
                </p>
              </div>
            ),
            width: 600,
            showSubscriptionButton: true,
          });
        } else if (status === "failed" && msg === "Unlock exceeded the limit!" && code === 400) {
          const date = new Date(data.unlockTime);
          date.setHours(date.getHours() + 1); // Add 1 hour
          date.setMinutes(date.getMinutes() + 30); // Add 30 minutes

          if (date.getMinutes() >= 60) {
            date.setHours(date.getHours() + 1);
            date.setMinutes(date.getMinutes() - 60);
          }
          const hour = date.getHours();
          const minute = String(date.getMinutes()).padStart(2, "0");
          const second = date.getSeconds();
          const timeZone = -(new Date().getTimezoneOffset() / 60);
          const timeRegion = [
            { time: 7, region: "WIB" },
            { time: 8, region: "WITA" },
            { time: 9, region: "WIT" },
          ];
          const localTime = timeRegion.find((item) => item.time === timeZone);
          ModalWarning({
            title: `Unlock akun ${process.env.REACT_APP_NAME} Gagal`,
            customMessage: (
              <div>
                <p>
                  Anda sudah melakukan unlock akun Relay selama 3 kali berturut-turut dan terdeteksi
                  sebagai aktivitas yang tidak wajar. Saat ini anda tidak diizinkan untuk melakukan
                  unlock akun {process.env.REACT_APP_NAME} anda.
                </p>
                <p>
                  Silakan lakukan unlock akun kembali pada pukul
                  {` ${hour}:${minute}:${second} ${localTime.region || ""}`}, atau hubungi
                  <a href="https://support.aktiva.co.id/"> Customer Care</a>.
                </p>
              </div>
            ),
            width: 600,
          });
        } else {
          ModalSuccess({
            title: `Akun ${process.env.REACT_APP_NAME} berhasil di unlock`,
            message: `Akun ${process.env.REACT_APP_NAME} anda berhasil di unlock, saat ini anda dapat mengirimkan email kembali`,
            onOk: () => {
              dispatch(setUserData({ ...user, relay_status: "active" }));
            },
          });
        }
      },
    });
  };
  return (
    <div className="smtp-alert-card">
      <div className="flex smtp-alert-body justify-between">
        <div className="flex flex-col smtp-alert-left">
          <h5 className="font-medium text-base">
            Akun {process.env.REACT_APP_NAME} dalam Kondisi Locked
          </h5>
          <p className="text-secondary mb-4">
            Terdapat indikasi kiriman email spam yang mengakibatkan akun{" "}
            {process.env.REACT_APP_NAME} terkena locked, informasi detail sudah dikirimkan ke email
            terdaftar. Silakan lakukan pembersihan email spam tersebut sebelum anda mengaktifkan
            kembali akun {process.env.REACT_APP_NAME} anda.
          </p>
          <div className="flex gap-x-2">
            <Button
              href="https://support.aktiva.co.id/knowledge-base/tips-mengecek-akun-yang-terindikasi-sebagai-spam/"
              target="_blank">
              Panduan Eskalasi Spam
            </Button>
            <Button type="danger" onClick={showModal}>
              Unlock Akun SMTP
            </Button>
          </div>
        </div>
        <div className="smtp-alert-right">
          <img src={dataMaintenance} alt={`Locked ${process.env.REACT_APP_NAME}`} />
        </div>
      </div>
    </div>
  );
};

export default LockedRelayAlert;
