import { Button, Card, Descriptions, Modal, Image, Form } from "antd";
import emptyCompanyInformation from "assets/images/emptyCompanyInformation.svg";
import { ICEditOutlined, ICUploadOutlined } from "components/list/ListIcon";
import ModalEditCompanyInformation from "components/popups/modal/ModalEditCompanyInformation";
import ModalLengkapiNpwp from "components/popups/modal/ModalLengkapiNpwp";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { maskNPWP, openInNewTab } from "utils/helpers";
import NPWPInfo from "assets/images/new_npwp.png";

const BtnCompany = ({ setVisible, disable }) => {
  return (
    <Button
      type="primary"
      disabled={disable}
      icon={<ICEditOutlined />}
      onClick={() => setVisible("editCompany")}>
      Edit Informasi
    </Button>
  );
};

const Rowset = ({ list = [] }) => {
  return (
    <Descriptions layout="horizontal" column={1} className="description__wrapper">
      {list.map((item) => (
        <Descriptions.Item key={item.label} label={item.label}>
          {item.value}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};

const CardCompanyInformation = ({ data, ...props }) => {
  const [disable, setDisable] = useState(false);
  const { getOthers, loading, isAdmin, companyUuid } = props;
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const navFillData = () => navigate("/register/company-data");
  const showModal = () => setVisible("fillNpwp");
  const Type = data.npwpType;
  const [action, setAction] = useState(
    ((Type === "15" || !Type) && "NPWP 15") || (Type === "16" && "NPWP 16")
  );
  const [form] = Form.useForm();

  const showModalGuide = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const GuideModal = (
    <Modal
      title="Perbedaan NPWP 15 dan NPWP 16"
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      width={860}
      destroyOnClose>
      <div style={{ position: "relative" }}>
        <Image src={NPWPInfo} style={{ width: "100%", height: "100%" }} />
        <p style={{ marginTop: 10 }}>
          <strong>Baca selengkapnya: </strong>
          <a
            href="https://setkab.go.id/resmi-diluncurkan-ini-format-baru-npwp/"
            target="_blank"
            rel="noreferrer">
            https://setkab.go.id/resmi-diluncurkan-ini-format-baru-npwp/
          </a>
        </p>
      </div>
    </Modal>
  );

  if (Object.values(data).every((value) => value === null))
    return (
      <Card title="Informasi Perusahaan" extra={<BtnCompany setVisible={setVisible} disable />}>
        <div className="flex flex-col items-center gap-y-3">
          <div className="img-wrapper">
            <img src={emptyCompanyInformation} alt="Personal Data" />
          </div>
          <div className="content-wrapper text-center">
            <h5 className="font-medium text-base mb-1">Data Perusahaan Belum Dilengkapi</h5>
            <p className="text-secondary mb-0">
              Silakan dapat melengkapi data informasi perusahaan anda.
            </p>
          </div>
          <div className="action-wrapper">
            <Button type="primary" icon={<ICEditOutlined />} onClick={navFillData}>
              Lengkapi Data
            </Button>
          </div>
        </div>
      </Card>
    );

  return (
    <Card
      title="Informasi Perusahaan"
      loading={loading === "companyInformation"}
      extra={<BtnCompany setVisible={setVisible} />}>
      <Rowset
        list={[
          { label: "Nama", value: data.companyName },
          { label: "Alamat", value: data.address },
          { label: "Telepon", value: data.phone },
          { label: "Website", value: data.website },
          {
            label: "NPWP",
            value: (
              <div className="flex flex-col gap-y-2">
                {data.npwp !== "null" && (data.npwpType === "15" || !data.npwpType) ? (
                  <span>{maskNPWP(data.npwp)}</span>
                ) : data.npwp !== "null" && data.npwpType === "16" ? (
                  <span>{data.npwp}</span>
                ) : (
                  "-"
                )}
                {data.urlNpwp ? (
                  <Button
                    type="link"
                    className="text-info buttonNpwp"
                    onClick={() => openInNewTab(`/npwp-image?url=${data.urlNpwp}`)}>
                    Npwp-{data.companyName}.{data.urlNpwp.split(".").slice(-1)[0]}
                  </Button>
                ) : (
                  <Button icon={<ICUploadOutlined />} type="primary" onClick={showModal}>
                    Lengkapi NPWP
                  </Button>
                )}
              </div>
            ),
          },
        ]}
      />
      <ModalLengkapiNpwp
        uuid={companyUuid}
        isOpen={visible}
        setOpen={setVisible}
        npwp={data.npwp}
        getUpdate={getOthers}
        action={action}
        setAction={setAction}
        showModalGuide={showModalGuide}
        GuideModal={GuideModal}
        disable={disable}
        setDisable={setDisable}
        form={form}
      />
      <ModalEditCompanyInformation
        isAdmin={isAdmin}
        isOpen={visible}
        setOpen={setVisible}
        name={data.companyName}
        address={data.address}
        phone={data.phone}
        website={data.website}
        npwp={data.npwp}
        urlNpwp={data.urlNpwp}
        getData={getOthers}
        companyUuid={companyUuid}
        action={action}
        setAction={setAction}
        Type={Type}
        showModalGuide={showModalGuide}
        GuideModal={GuideModal}
        disable={disable}
        setDisable={setDisable}
        form={form}
      />
    </Card>
  );
};

export default CardCompanyInformation;
