import { Button, ConfigProvider, Dropdown, Menu, Table, Tag, Tooltip } from "antd";
import { exportBillingReport } from "api";
import { EmptyWrapper } from "components/empty/EmptyComponent";
import {
  ICCheckCircleOutlined,
  ICCloseCircleOutlined,
  ICExportOutlined,
  ICTagOutlined,
} from "components/list/ListIcon";
import TableTitleWithCollapse from "components/tableSendiri/TableTitleWithCollapse";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TableReportBilling = ({
  filter,
  setFilter,
  loading,
  renderFilter: AccordionFilter,
  responsiveWidth,
  breakpoint_third,
}) => {
  const navigate = useNavigate();
  const inSuspend = (record, index) => {
    return record.statusLangganan === "inactive" && "row-suspend";
  };

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1330;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const reportBilling = useSelector(({ reportBilling }) => reportBilling.value);

  const headerCSV = [
    { label: "Nama Perusahaan", key: "company_name" },
    { label: "Primary Kontak", key: "company_contact" },
    { label: "NPWP", key: "company_npwp" },
    { label: "Status Langganan", key: "subcription_status" },
    { label: "Paket Langganan", key: "subcription_name" },
    { label: "Periode Langganan", key: "subcription_period" },
    { label: "Nominal Tagihan", key: "current_bill" },
    { label: "Nominal Dibayarkan", key: "total_paid_bill" },
  ];

  const colDaftarPelanggan = [
    {
      title: "Nama Perusahaan",
      dataIndex: "company_name",
      key: "company_name",
      width: width > breakpoint ? 380 : 200,
      fixed: "left",
      render: (_, record) => {
        const navs = () => navigate(`detail/${record.company_uuid}`, { state: record });
        return (
          <div className="flex flex-col">
            <p className="mb-0 text-info rows-perusahaan" onClick={navs}>
              {record.company_name}
            </p>
            <p className="mb-0 text-secondary text-xs" type="secondary">
              {record.company_contact || ""}
            </p>
          </div>
        );
      },
    },
    {
      title: "NPWP",
      dataIndex: "company_npwp",
      key: "company_npwp",
      width: 73,
      align: "center",
      render: (_, record) =>
        record.company_npwp ? (
          <ICCheckCircleOutlined className="text-success text-lg" />
        ) : (
          <ICCloseCircleOutlined className="text-secondary text-lg" />
        ),
    },
    {
      title: "Status Langganan",
      dataIndex: "subcription_status",
      key: "subcription_status",
      width: 102,
      render: (text) => {
        if (text === "active")
          return (
            <Tag color="blue" className="blue-6">
              Active
            </Tag>
          );
        else if (text === "trial") return <Tag color="warning">Trial</Tag>;
        else if (text === "inactive") return <Tag color="red">Inactive</Tag>;
      },
    },
    {
      title: "Paket Layanan",
      dataIndex: "subcription_name",
      key: "subcription_name",
      width: 140,
      render: (values) => {
        if (values !== "-") return <Tag icon={<ICTagOutlined />}>{values}</Tag>;
        return "-";
      },
    },
    {
      title: "Periode Langganan",
      dataIndex: "subcription_period",
      key: "subcription_period",
      width: 120,
    },
    {
      title: "Nominal Tagihan",
      dataIndex: "current_bill",
      key: "current_bill",
      width: 180,
      align: "right",
    },
    {
      title: "Nominal Dibayarkan",
      dataIndex: "total_paid_bill",
      key: "total_paid_bill",
      width: 180,
      align: "right",
    },
  ];

  const changePage = (parPage) => setFilter({ ...filter, page: parPage });

  const searchCompany = (value) => {
    if (value) setFilter({ ...filter, page: 1, limit: filter.limit, company_name: value });
    else setFilter({ ...filter, company_name: "", page: 1, limit: filter.limit });
  };

  const ButtonExportCSV = ({ dataCSV, headerCSV, responsive, disable }) => {
    if (!dataCSV) return "";

    const ExportTableData = () => {
      const btnExport = useRef(null);
      const exportData = async () => {
        setTimeout(() => {
          btnExport.current.link.click();
        }, 300);
      };

      return (
        <>
          <span onClick={exportData}>Export table data</span>
          <div className="hidden">
            <CSVLink
              ref={btnExport}
              data={dataCSV}
              headers={headerCSV}
              filename="List-Report-Tagihan-Customer"
            />
          </div>
        </>
      );
    };

    const ExportTableAllData = () => {
      const btnExport = useRef(null);
      const [data, setData] = useState([]);
      const exportData = async () => {
        const { data } = await exportBillingReport();
        if (data) setData(data);
        else setData(data);
        setTimeout(() => {
          btnExport.current.link.click();
        }, 300);
      };

      return (
        <>
          <span onClick={exportData}>Export all data</span>
          <div className="hidden">
            <CSVLink
              ref={btnExport}
              data={data}
              headers={headerCSV}
              filename="List-All-Report-Tagihan-Customer"
            />
          </div>
        </>
      );
    };

    const items = [
      { label: <ExportTableData />, key: 1 },
      { label: <ExportTableAllData />, key: 2 },
    ];
    const menu = <Menu items={items} />;
    return (
      <Dropdown trigger={["click"]} overlay={menu} disabled={disable}>
        <Tooltip overlayClassName={responsive && `tooltipCSV-${responsive}`} title="Export To CSV">
          <Button icon={<ICExportOutlined />} type="primary">
            <span className={responsive && `hidden-${responsive}`}>Export to CSV</span>
          </Button>
        </Tooltip>
      </Dropdown>
    );
  };

  return (
    <ConfigProvider
      renderEmpty={() => (
        <EmptyWrapper
          title="Data tidak ditemukan"
          message="Data perusahaan yang anda cari tidak ditemukan, pastikan tidak ada salah ketik pada penulisan"
        />
      )}>
      <Table
        dataSource={reportBilling.rows}
        columns={colDaftarPelanggan}
        {...(width < breakpoint ? { scroll: { x: 1024 } } : {})}
        loading={loading}
        pagination={{
          pageSize: filter.limit,
          current: filter.page,
          hideOnSinglePage: true,
          onChange: (params) => {
            changePage(params);
          },
          total: reportBilling.count,
          showSizeChanger: false,
          size: "small",
        }}
        showSorterTooltip={false}
        rowClassName={inSuspend}
        title={() => (
          <TableTitleWithCollapse
            title="Daftar Pelanggan"
            desc="List pelanggan dan laporan tagihan berjalan"
            loading={loading}
            pageLimit={{
              currentLimit: filter.limit,
              onSelectLimit: (limit) => {
                setFilter({ ...filter, limit, page: 1 });
              },
              disable: reportBilling.count < 10,
            }}
            searchCompany={{ isAdmin: true, onSearch: searchCompany }}
            addsAccordion={<AccordionFilter />}
            addsAfter={
              <ButtonExportCSV
                dataCSV={reportBilling.rows}
                headerCSV={headerCSV}
                responsive="lg"
                disable={reportBilling.count < 1}
              />
            }
            responsiveWidth={responsiveWidth}
            breakpoint_third={breakpoint_third}
          />
        )}
        rowKey={(record) => record.company_uuid}
      />
    </ConfigProvider>
  );
};

export default TableReportBilling;
