import { Card, Col, Divider, Radio, Row } from "antd";
import { getEmailSendingSummary, getLineEmailReports, getMostEmailSummary } from "api";
import { BarChart, LineChart } from "components/chart/ChartDashboard";
import { useCallback, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { setEmailSummary } from "redux/slices/emailSummary";
import { setMailDeliveryChart } from "redux/slices/mailDeliveryChart";
import useDocumentTitle from "utils/useDocumentTitle";
import CallToAction from "./CallToAction";
import EmailSendingSummary from "./EmailSendingSummary";
import "./maindashboard.less";

const ChartRadioButton = ({ value, setRadio }) => {
  const onRadioChange = ({ target: { value } }) => setRadio(value);
  return (
    <Radio.Group buttonStyle="solid" onChange={onRadioChange} value={value}>
      <Radio.Button value="week">Week</Radio.Button>
      <Radio.Button value="month">Month</Radio.Button>
    </Radio.Group>
  );
};

const SummaryRadioButton = ({ value, setRadio }) => {
  const onRadioChange = ({ target: { value } }) => setRadio(value);
  return (
    <Radio.Group buttonStyle="solid" value={value} onChange={onRadioChange}>
      <Radio.Button value="allTime">All Time</Radio.Button>
      <Radio.Button value="today">Today</Radio.Button>
      <Radio.Button value="week">This Week</Radio.Button>
      <Radio.Button value="month">This Month</Radio.Button>
    </Radio.Group>
  );
};

const MainDashboard = () => {
  useDocumentTitle("Dashboard");
  const [statistic, setStatistic] = useState("allTime");
  const [chart, setChart] = useState("week");
  const dispatch = useDispatch();

  const emailSummary = useSelector(({ emailSummary }) => emailSummary.value);
  const deliveryChart = useSelector(({ mailDeliveryChart }) => mailDeliveryChart.value);
  const { barMostEmailSummary } = deliveryChart;

  const getEmailSummary = useCallback(async () => {
    const { data } = await getEmailSendingSummary({ getBy: statistic });
    dispatch(setEmailSummary(data));
  }, [dispatch, statistic]);

  useEffect(() => {
    if (statistic) getEmailSummary();
  }, [getEmailSummary, statistic]);

  const getChart = useCallback(async () => {
    const { data: lineEmailReports } = await getLineEmailReports({ getBy: chart });
    const { data: barMostEmailSummary } = await getMostEmailSummary({ getBy: chart });
    dispatch(setMailDeliveryChart({ lineEmailReports, barMostEmailSummary }));
  }, [chart, dispatch]);

  useEffect(() => {
    if (chart) getChart();
  }, [getChart, chart]);

  if (!emailSummary && !deliveryChart)
    return (
      <div className="main-dashboard-user flex flex-col gap-y-6 px-6 py-4">
        <Skeleton className="w-full" height={232} />
        <Skeleton className="w-full" height={625} />
      </div>
    );

  return (
    <div className="main-dashboard-user flex flex-col gap-y-6 px-6 py-4">
      <CallToAction />
      <Card
        title="Email Sending Summary"
        className="card-wrapper-dashboard"
        loading={!emailSummary}
        extra={<SummaryRadioButton value={statistic} radio={statistic} setRadio={setStatistic} />}>
        <Row gutter={[24, 0]} wrap justify="space-between">
          <EmailSendingSummary />
        </Row>
      </Card>
      <Card
        title="Grafik Pengiriman Email"
        extra={<ChartRadioButton radio={chart} setRadio={setChart} value={chart} />}
        loading={!emailSummary}
        className="card-wrapper-chart">
        <Row gutter={[24, 24]}>
          <Col xl={18} lg={24} md={24} sm={24}>
            <span className="text-emaildashboard">
              Pengiriman dalam {chart === "month" ? "1 bulan" : "1 minggu"}
            </span>
            <LineChart data={deliveryChart.lineEmailReports} />
          </Col>
          <Col xl={6} lg={24} md={24} sm={24} className="column-chart-right">
            <Row gutter={[8, 8]}>
              <Col lg={8} xl={24}>
                <div className="px-6 py-4">
                  <p className="mb-2">Volume Penerima Terbanyak</p>
                  <BarChart
                    title="Volume Penerima Terbanyak"
                    data={barMostEmailSummary?.top_receiver_domain}
                  />
                  <Divider className="mb-2 mt-4" />
                </div>
              </Col>
              <Col lg={8} xl={24}>
                <div className="px-6 py-4">
                  <p className="mb-2">Volume Domain Pengirim Terbanyak</p>
                  <BarChart
                    title="Volume Domain Pengirim Terbanyak"
                    data={barMostEmailSummary?.top_sender_domain}
                  />
                  <Divider className="mb-2 mt-4" />
                </div>
              </Col>
              <Col lg={8} xl={24}>
                <div className="px-6 py-4">
                  <p className="mb-2">Volume Email Pengirim Terbanyak</p>
                  <BarChart
                    title="Volume Email Pengirim Terbanyak"
                    data={barMostEmailSummary?.top_sender_email}
                  />
                  <Divider className="mb-2 mt-4" />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default MainDashboard;
