import { getCompanyPackage } from "api";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCompanyPackage } from "redux/slices/companyPackage";

const useGetCompanyPackage = () => {
  const companyPackage = useSelector(({ companyPackage }) => companyPackage.value);

  const dispatch = useDispatch();
  const action = setCompanyPackage;

  const doSet = useCallback((data) => dispatch(action(data)), [dispatch, action]);

  useEffect(() => {
    const getUserPackage = async () => {
      const { data } = await getCompanyPackage();
      if (data && Object.keys(data).length) doSet(data);
    };
    if (!companyPackage) getUserPackage();
  }, [companyPackage, doSet]);
};

export default useGetCompanyPackage;
