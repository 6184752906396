import { createSlice } from "@reduxjs/toolkit";

export const notificationList = createSlice({
  name: "notificationList",
  initialState: {
    value: false,
  },
  reducers: {
    setNotificationList: (state, action) => {
      state.value = action.payload;
    },
  },
});

// action type, payload, callback

export const { setNotificationList } = notificationList.actions;
export default notificationList.reducer;
