import { createSlice } from "@reduxjs/toolkit";

export const reportEmail = createSlice({
  name: "reportEmail",
  initialState: {
    value: false, // default state
  },
  reducers: {
    // reducers action
    setReportEmail: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setReportEmail } = reportEmail.actions;
export default reportEmail.reducer;
