import { createSlice } from "@reduxjs/toolkit";

export const companyData = createSlice({
  name: "companyData",
  initialState: {
    value: false, // default state
  },
  reducers: {
    // reducers action
    setCompanyData: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setCompanyData } = companyData.actions;
export default companyData.reducer;
