import { Button } from "antd";
import resultImage from "assets/images/result_domain.svg";
import { useLocation, useNavigate } from "react-router-dom";

const DomainSuccess = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const navMain = () => (window.location.href = "/main");
  const navCompany = () => navigate("/register/company-data");

  return (
    <>
      <img src={resultImage} alt="" />
      <div className="flex text-center flex-col gap-y-1">
        <h2 className="text-3xl mb-1">Aktivasi Trial Sukses</h2>
        <p className="text-secondary mb-0">
          Selamat, akun trial Anda berhasil terdaftar dan diaktivasi.
        </p>
        <p className="text-secondary mb-0">
          {state.isSetUpCompany
            ? `Saat ini anda sudah dapat menggunakan trial layanan ${process.env.REACT_APP_NAME} tersebut.`
            : "Untuk dapat mulai menggunakan layanan, silakan dapat mengisi data perusahaan pada tahap berikutnya."}
        </p>
      </div>
      <Button type="primary" className="mt-4" onClick={state.isSetUpCompany ? navMain : navCompany}>
        {state.isSetUpCompany ? "Akses Dashboard" : "Lengkapi Data Perusahaan"}
      </Button>
    </>
  );
};

export default DomainSuccess;
