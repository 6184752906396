import { Form, Input, Modal } from "antd";
import { editCompany, editCompanyClient } from "api";
import FormNPWP from "components/form/FormNPWP";
import { useEffect, useState } from "react";
import { gatherFormData, requiredRules } from "utils/helpers";
import ModalError from "./ModalError";
import ModalSuccess from "./ModalSuccess";
import { ICQuestionCircleOutlined } from "components/list/ListIcon";

const ModalEditCompanyInformation = ({
  setOpen,
  isOpen,
  getData,
  name,
  address,
  phone,
  website,
  npwp,
  Type,
  action,
  setAction,
  showModalGuide,
  GuideModal,
  disable,
  setDisable,
  form,
  ...props
}) => {
  const [loading, setLoading] = useState(false);
  const { isAdmin, companyUuid } = props;

  const closeForm = () => {
    if (Object.values(form.getFieldValue()).some((value) => !value)) setDisable(true);
    else setDisable(false);
    setOpen(false);
    form.resetFields();
    setAction(((Type === "15" || !Type) && "NPWP 15") || (Type === "16" && "NPWP 16"));
  };

  const handleEditCompany = () => {
    setLoading(true);
    const callback = async () => {
      const values = form.getFieldsValue();
      let body = { ...values };

      if (values.fileNpwp && values.fileNpwp.length)
        body = { ...values, fileNpwp: values.fileNpwp[0].originFileObj };
      else delete body.fileNpwp;
      const response =
        isAdmin === "user"
          ? await editCompany(body)
          : await editCompanyClient({ ...body, companyUuid });
      setLoading(false);
      if (response.code === 400) {
        closeForm();
        ModalError({
          title: "Peringatan!",
          message: "Harap di cek kembali No NPWP dan File yang akan di upload",
        });
      } else if (response && response.status === "success") {
        closeForm();
        ModalSuccess({
          title: "Informasi Perusahaan berhasil diupdate",
          message: `Informasi Perusahaan ${values.companyName} berhasil diupdate pada sistem`,
          onOk: async () => {
            await getData(false, "companyInformation");
            form.setFieldsValue({
              companyName: values.companyName,
              address: values.address,
              phone: values.phone,
              npwp: values.npwp,
              npwpType: values.npwpType,
              website: values.website,
            });
            setAction(values.npwpType === "15" ? "NPWP 15" : "NPWP 16");
          },
        });
      }
    };
    gatherFormData(form, callback);
  };

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const handleMask = ({ unmaskedValue }) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...values, npwp: unmaskedValue });
  };

  const noArrow = "rmArrow";

  return (
    <Modal
      title="Edit Informasi Perusahaan"
      open={isOpen === "editCompany"}
      onOk={handleEditCompany}
      centered
      okButtonProps={{ htmlType: "submit", loading: loading, disabled: disable }}
      onCancel={closeForm}
      okText="Simpan"
      cancelText="Batal">
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          companyName: name,
          address: address,
          phone: phone,
          npwp: npwp,
          npwpType: Type,
          website: website,
        }}>
        <Form.Item label="Nama" name="companyName" className="mb-4" rules={[requiredRules]}>
          <Input
            onChange={(e) => {
              const value = e.target.value;
              setDisable(!value);
            }}
          />
        </Form.Item>
        <Form.Item label="Alamat" name="address" className="mb-4" rules={[requiredRules]}>
          <Input.TextArea
            rows={2}
            onChange={(e) => {
              const value = e.target.value;
              setDisable(!value);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Telepon"
          name="phone"
          className="mb-4"
          rules={[requiredRules, { min: 6, message: "Harap masukkan nomor yang valid" }]}>
          <Input
            type="number"
            className={noArrow}
            onWheel={(e) => e.target.blur()}
            onChange={(e) => {
              const value = e.target.value;
              setDisable(value.length < 6 || !value);
            }}
          />
        </Form.Item>
        <Form.Item label="Website" name="website" className="mb-4" rules={[requiredRules]}>
          <Input
            addonBefore="https://"
            onChange={(e) => {
              const value = e.target.value;
              setDisable(!value);
            }}
          />
        </Form.Item>
        <FormNPWP
          handleMask={handleMask}
          classNPWP="mb-4"
          setDisable={setDisable}
          setAction={setAction}
          action={action}
          form={form}
          noArrow={noArrow}
        />
        {isAdmin === "user" && (
          <p className="text-secondary" style={{ marginBottom: 0 }}>
            Pilih format NPWP anda, kenali perbedaannya
            <span style={{ color: "#0D9F3F", marginLeft: 4 }}>
              <ICQuestionCircleOutlined onClick={showModalGuide} />
            </span>
          </p>
        )}
        {isAdmin === "user" && GuideModal}
      </Form>
    </Modal>
  );
};

export default ModalEditCompanyInformation;
