import DashboardHeader from "components/dashboard-header/DashboardHeader";
import useDocumentTitle from "utils/useDocumentTitle";
import List from "./List";
import "./suppress.less";

const SuppressionListAdmin = () => {
  useDocumentTitle("Suppression List");
  return (
    <div>
      <DashboardHeader title="Suppression List" />
      <div className="flex flex-col gap-y-6 px-6 py-5">
        <List />
      </div>
    </div>
  );
};

export default SuppressionListAdmin;
