import { Modal } from "antd";
import { ICExclamationCircleOutlined } from "components/list/ListIcon";

const { confirm } = Modal;

const ModalActivate = (props) => {
  const {
    okText = "Generate API Key",
    okButtonProps,
    customMessage,
    message,
    cancelText = "Batalkan",
    iconType = "generate",
  } = props;
  const messageModal = customMessage || <p className="mb-0">{message}</p>;
  const iconColor = iconType === "regenerate" || "remove" ? "#FAAD14" : "#1890FF";
  const width = iconType === "remove" ? "600px" : "540px";
  // Menentukan kelas tombol berdasarkan iconType
  let okButtonClassName = "";
  if (iconType === "regenerate" || "remove") {
    okButtonClassName = "ant-btn-danger";
  }
  return confirm({
    ...props,
    icon: <ICExclamationCircleOutlined style={{ color: iconColor }} />,
    content: messageModal,
    centered: true,
    okText: okText,
    okButtonProps: {
      ...okButtonProps,
      className: okButtonClassName, // Menggunakan kelas yang ditentukan
    },
    autoFocusButton: null,
    cancelText: cancelText,
    width: width,
  });
};

export default ModalActivate;
