import { Card, Col, Radio, Row } from "antd";
import { getTopSenderAdmin } from "api";
import { BarChart } from "components/chart/ChartDashboard";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEmailSummary } from "redux/slices/emailSummary";

const TopSender = () => {
  const dispatch = useDispatch();
  const [chartRadioValue, setChartRadioValue] = useState("today");
  const [width, setWidth] = useState(window.innerWidth);

  const sentSummary = useSelector(({ emailSummary }) => emailSummary.value);
  const { topSender } = sentSummary;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const RadioButton = () => {
    const handler = async ({ target: { value } }) => {
      setChartRadioValue(value);
      let { data } = await getTopSenderAdmin({ getBy: value });
      dispatch(setEmailSummary({ ...sentSummary, topSender: data }));
    };

    return (
      <Radio.Group
        buttonStyle="solid"
        defaultValue="today"
        {...(width < 992 ? { size: "small" } : { size: "middle" })}
        value={chartRadioValue}
        onChange={handler}>
        <Radio.Button value="today">Today</Radio.Button>
        <Radio.Button value="week">Week</Radio.Button>
        <Radio.Button value="month">Month</Radio.Button>
      </Radio.Group>
    );
  };

  return (
    <Card
      className="top-sender__card"
      title="Top Sender"
      loading={!sentSummary}
      extra={<RadioButton />}>
      <div className="flex card-top-sender flex-wrap w-full">
        <Row className="w-full">
          <Col sm={24} md={24} lg={12} xl={12}>
            <div className="px-3 pt-2 pb-4">
              <p className="mb-0">Email Pengirim Terbanyak</p>
              <BarChart
                title="User Email Pengirim Terbanyak"
                data={topSender?.top_sender_email}
                column
              />
            </div>
          </Col>
          <Col sm={24} md={24} lg={12} xl={12}>
            <div className="px-3 pt-2 pb-4">
              <p className="mb-0">Domain Pengirim Terbanyak</p>
              <BarChart
                title="Domain Pengirim Terbanyak"
                data={topSender?.top_sender_domain}
                column
              />
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default TopSender;
