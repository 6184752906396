import { Button, Card, ConfigProvider, Dropdown, Table, Tag } from "antd";
import { EmptyWrapper } from "components/empty/EmptyComponent";
import { ICCloseCircleFilled, ICDownOutlined, ICTagOutlined } from "components/list/ListIcon";
import TableTitleWithCollapse from "components/tableSendiri/TableTitleWithCollapse";
import React from "react";

const TablePelanggan = ({
  ActionTableClients,
  filter,
  setFilter,
  data,
  title,
  emptyState,
  responsiveWidth,
  breakpoint_third,
}) => {
  const inSuspend = (record) => {
    return record.relay_status === "suspended" && "row-suspend";
  };

  const colDaftarPelanggan = [
    {
      title: "Nama Perusahaan",
      dataIndex: "name",
      key: "namaPerusahaan",
      width: responsiveWidth > 1200 ? 380 : 200,
      fixed: "left",
      render: (_, record) => {
        if (record?.company_name?.includes("rm::")) {
          return (
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <p className="text-secondary mb-0">{record?.company_name.slice(4)}</p>
                <Tag color="default" className="text-secondary mr-0">
                  Deleted
                </Tag>
              </div>
              <div className="flex items-center">
                <p className="mb-0 text-secondary text-xs" type="secondary">
                  {record?.relay_email ? record?.relay_email : <span className="text-xl">-</span>}
                </p>
                <ICCloseCircleFilled className="ml-1 text-secondary text-2xs leading-none" />
              </div>
            </div>
          );
        }
        return (
          <div className="flex flex-col">
            <p className="mb-0">{record?.company_name}</p>
            <p className="mb-0 text-secondary text-xs" type="secondary">
              {record?.relay_email ? record?.relay_email : <span className="text-xl">-</span>}
            </p>
          </div>
        );
      },
    },
    {
      title: "Primary Contact",
      dataIndex: "primaryContact",
      key: "primaryContact",
      width: 240,
      render: (_, record) => {
        if (record?.contact_primary_email?.includes("rm::")) {
          return (
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <p className="mb-0 text-secondary">{record?.contact_primary_name.slice(4)}</p>
                <Tag color="default" className="text-secondary mr-0">
                  Deleted
                </Tag>
              </div>
              <div className="flex items-center">
                <p className="mb-0 text-secondary text-xs" type="secondary">
                  {record?.contact_primary_email.slice(4)}
                </p>
                <ICCloseCircleFilled className="ml-1 text-secondary text-2xs leading-none" />
              </div>
            </div>
          );
        }
        return (
          <div className="flex flex-col">
            <p className="mb-0">{record?.contact_primary_name}</p>
            <p className="mb-0 text-secondary text-xs" type="secondary">
              {record?.contact_primary_email}
            </p>
          </div>
        );
      },
    },
    {
      title: "Domain",
      dataIndex: "domain",
      key: "domain",
      width: 123,
      render: (_, record) => (
        <div className="flex flex-col">
          <p className="mb-0">{record.current_domain}</p>
          <p className="mb-0 text-secondary text-xs" type="secondary">
            /{record.max_domain} domains
          </p>
        </div>
      ),
    },
    {
      title: "Package",
      dataIndex: "package_name",
      key: "package",
      width: 123,
      render: (values) =>
        values ? <Tag icon={<ICTagOutlined />}> {values}</Tag> : <span> - </span>,
    },
    {
      title: "Status Langganan",
      dataIndex: "package_status",
      key: "package_status",
      width: 102,
      render: (values) => {
        const status = [
          { name: "Active", color: "blue" },
          { name: "Inactive", color: "red" },
          { name: "Trial", color: "warning" },
        ];
        const packageStatus = status.find((item) => item.name.toLowerCase() === values);
        return (
          <Tag
            color={packageStatus.color}
            {...(values === "active" ? { className: "blue-6 mr-0" } : { className: "mr-0" })}>
            {packageStatus.name}
          </Tag>
        );
      },
    },
    {
      title: "Status Relay",
      dataIndex: "statusRelay",
      key: "statusRelay",
      width: 88,
      render: (_, record) => (
        <>
          {record?.relay_status === "active" && (
            <Tag color="blue" className="blue-6">
              Active
            </Tag>
          )}
          {record?.relay_status === "suspended" && <Tag color="warning">Suspend</Tag>}
          {record?.relay_status === "locked" && <Tag color="red">Locked</Tag>}
          {record?.relay_status === "closed" && <Tag color="red">Inactive</Tag>}
          {!record?.relay_status && <span className="text-xl"> - </span>}
        </>
      ),
    },
    {
      dataIndex: "action",
      key: "action",
      width: 88,
      render: (_, record) => (
        <Dropdown trigger={["click"]} overlay={() => ActionTableClients(record)}>
          <Button>
            Action
            <ICDownOutlined />
          </Button>
        </Dropdown>
      ),
    },
  ];

  const changePage = (pages) => {
    setFilter({ ...filter, page: pages });
  };

  if (!data.table)
    return (
      <Card
        bordered={false}
        loading
        title="Daftar Pelanggan"
        desc={`List pelanggan pengguna layanan ${process.env.REACT_APP_NAME}`}
      />
    );

  return (
    <ConfigProvider renderEmpty={() => <EmptyWrapper {...emptyState} />}>
      <Table
        loading={data?.loading}
        dataSource={data?.table.rows}
        columns={colDaftarPelanggan}
        rowKey={(record) => record.company_uuid}
        {...(responsiveWidth < 1200 ? { scroll: { x: 1024 } } : {})}
        title={() => (
          <TableTitleWithCollapse
            {...title}
            responsiveWidth={responsiveWidth}
            breakpoint_third={breakpoint_third}
          />
        )}
        pagination={{
          pageSize: filter.limit,
          current: filter.page,
          hideOnSinglePage: true,
          onChange: (params) => {
            changePage(params);
          },
          size: "small",
          showSizeChanger: false,
          total: data?.table.count,
        }}
        showSorterTooltip={false}
        rowClassName={inSuspend}
      />
    </ConfigProvider>
  );
};

export default TablePelanggan;
