import Cookies from "js-cookie";

export const getSiteMode = () => {
  const { pathname: path } = window.location;
  if (path.includes("/admin")) return "admin";
  return "user";
};

export const getLoginStatus = () => {
  const inCookie = Cookies.get("is-login");
  const inStorage = localStorage.getItem("is-login");
  return inCookie || inStorage === 1;
};

export const isFunction = (fn) => typeof fn === "function";

export const runFunction = (fn) => {
  if (typeof fn === "function") fn();
};

export const formatNumber = (value) => {
  return value.toLocaleString("en-US");
};

export const formatNumberWithDot = (number) => {
  return number?.toLocaleString().replace(/,/g, ".");
};

export const areNumbersApproximatelyEqual = (num1, num2, tolerance = 0.01) => {
  return Math.abs(num1 - num2) < tolerance;
};

export const getStartPagin = (page, length) => {
  if ((!page && !length) || (typeof page === "number" && typeof length === "number")) return 0;
  if (page === 0) return page;
  return page - 1 + length;
};

export const getNumericValue = (value) => {
  return parseFloat(value.replace(/[^\d]/g, "")) || undefined;
};

export const convertIDR = (number = 0) => {
  if (typeof number !== "number") return "Rp data-number-invalid";
  return `Rp${number.toLocaleString("id-ID")}`;
};
export const setStorage = (name, value) => localStorage.setItem(name, value);
export const getStorage = (name) => localStorage.getItem(name);
export const removeStorage = (name = "" || []) => {
  if (typeof name === "object" && name.length > 0) name.map((n) => localStorage.removeItem(n));
  else localStorage.removeItem(name);
};
export const setCookie = (name, value, options = { path: "/" }) =>
  Cookies.set(name, value, { ...options });
export const getCookie = (name) => Cookies.get(name) || false;
export const removeCookie = (name = "" || []) => {
  if (typeof name === "object" && name.length > 0) name.map((n) => Cookies.remove(n));
  else Cookies.remove(name);
};

export const BanCharacters = /^[a-zA-Z !@#$%^&*()_+{}[\]:;<>,.?~\\/-]*$/;

export const requiredRules = { required: true, message: "Harap diisi terlebih dahulu" };

export const requiredEmail = { required: true, message: "Alamat email wajib diisi" };

export const numberRules = { pattern: new RegExp(/^[0-9]+$/), message: "Mohon masukkan angka!" };

export const regexDomain =
  /^(https?:\/\/)?[0-9a-z-_]*(\.[0-9a-z-_]+)*(\.[a-z]{2,6})+(\/[0-9a-z-_]*)*?\/?$/gim;

export const regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const regexPhone = /^[0|62]{1,3}\d{7,16}/gm;

export const regexPassword =
  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}/[\]|\\:;"'<>,.?/_₹]).{8,}$/;

export const regexChangePasswordSMTP =
  /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}/[\]|\\:;"'<>,.?/_₹]).{10,}$/;

export const getSessionToken = () => {
  return window.sessionStorage.getItem("user_token");
};

export const setCookieToken = (value) => {
  const inAdmin = window.location.href.includes("/admin");
  return setCookie(inAdmin ? "token" : "user_token", value);
};

export const getCookieToken = () => {
  const inAdmin = window.location.href.includes("/admin");
  return getCookie(inAdmin ? "token" : "user_token");
};

export const getCookieRefreshToken = () => {
  const inAdmin = window.location.href.includes("/admin");
  return getCookie(inAdmin ? "admin_refresh_token" : "refresh_token");
};

export const getTokenForced = () => {
  return getCookie("token") || getCookie("admin_token");
};

export const getRefreshTokenForced = () => {
  return getCookie("refresh_token") || getCookie("admin_refresh_token");
};

export const onEnter = (e, callback) => {
  if (!e || !e.keyCode || !e.key) return "";
  if ((e.keyCode === 13 || e.key === "Enter") && isFunction(callback)) callback();
};

export const setLogin = (data, path, user) => {
  if (!data || !path) return false;
  if (user === "user") {
    setCookie("user_token", data.accessToken);
    setCookie("user_refresh_token", data.refreshToken);
  } else {
    setCookie("token", data.accessToken);
    setCookie("refresh_token", data.refreshToken);
  }
};

export const setLogout = (user) => {
  if (user === "admin") removeCookie(["token", "refresh_token"]);
  else removeCookie(["user_refresh_token", "user_token"]);
  setTimeout(() => {
    if (user === "user") {
      window.location.href = "/login";
    } else window.location.href = "/admin/login";
  }, 150);
};

export function secondsToTime(e) {
  const m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, "0");

  return `${m} menit ${s} detik`;
}

export const gatherFormData = (form, callback) => {
  if (form && form.getFieldsValue && form.getFieldsError) {
    form.submit();
    let errAmount = [];
    setTimeout(() => {
      form.getFieldsError().map((e) => e.errors.length && errAmount.push(e));
      const values = form.getFieldsValue();
      if (errAmount.length < 1 && isFunction(callback)) return callback(values);
    }, 100);
    return false;
  }
  return false;
};

export const maskNPWP = (data = "") => {
  if (data === "" || data === null) return "-";
  return `${data.substring(0, 2)}.${data.substring(2, 5)}.${data.substring(5, 8)}.${data.substring(
    8,
    9
  )}-${data.substring(9, 12)}.${data.substring(12, 15)}`;
};

export const inAdmin = () => {
  return window.location.href.includes("/admin");
};

export const capitalizeFirstLetter = (word = "") => {
  return word && word.charAt(0).toUpperCase() + word.slice(1);
};

export const emailType = [
  { value: "newsletter", text: "Email Newsletter" },
  { value: "notifikasi", text: "Email Notifikasi" },
  { value: "promosi", text: "Email Promosi/Marketing" },
  { value: "transaksional", text: "Email Transaksional" },
];

export const dataSources = [
  { value: "Internal", text: "Data dari Website Perusahaan" },
  { value: "Membeli", text: "Membeli Database Email" },
  { value: "Terdaftar", text: "Pelanggan Terdaftar" },
  { value: "Eksternal", text: "Website Eksternal" },
  { value: "Rekanan", text: "Rekanan Perusahaan" },
];

export const cEmailSent = [
  { value: "<30", text: "<30.000 email per bulan" },
  { value: "30", text: "30.000 - 50.000 email per bulan" },
  { value: "50", text: "50.000 - 100.000 email per bulan" },
  { value: "100", text: "100.000 - 250.000 email per bulan" },
  { value: "250", text: "250.000 - 500.000 email per bulan" },
  { value: "500", text: "<500.000 email per bulan" },
];

export const totalUser = [
  { value: "1 user", text: "1 - 25 user" },
  { value: "25 user", text: "25 - 250 user" },
  { value: "250 user", text: "250 - 500 user" },
  { value: "500 user", text: "500 - 1000 user" },
  { value: "1000 user", text: "> 1000 user" },
];

export const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener, noreferrer");
  if (newWindow) newWindow.opener = null;
};

export const splitDate = (date) => {
  const dateSplit = date.split(" ");
  const dayDate = dateSplit[0];
  const time = dateSplit[1];
  return { date: dayDate, time };
};

export const handleCopyRekening = (Rek) => {
  if (window.isSecureContext && navigator.clipboard) {
    window.navigator.clipboard.writeText(Rek);
  } else {
    const copyRek = document.body.appendChild(document.createElement("input"));
    copyRek.value = Rek;
    copyRek.focus();
    copyRek.select();
    document.execCommand("copy");
    document.body.removeChild(copyRek);
  }
};
