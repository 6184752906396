import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Progress, Tag, Tooltip, Typography } from "antd";
import { ICArrowRightOutlined, ICCheckOutlined, ICTagOutlined } from "components/list/ListIcon";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;

const UsedQuota = ({ data = false, user }) => {
  const navigate = useNavigate();
  const navCheckout = () => navigate("/checkout");
  const activateTrial = () => navigate("/setup");

  const ChoosePacket = () => {
    return (
      <Button type="primary" icon={<ICArrowRightOutlined />} onClick={navCheckout} className="my-3">
        Pilih paket
      </Button>
    );
  };

  const ActivateTrial = () => {
    return (
      <Button type="primary" icon={<ICCheckOutlined />} onClick={activateTrial} className="my-3">
        Aktivasi Trial
      </Button>
    );
  };

  let progressColor =
    data.countUsagePercentage < 80
      ? "#0D9F3F"
      : data.countUsagePercentage < 90
      ? "#FAAD14"
      : "#FF4D4F";

  if (data)
    return (
      <>
        <div className="flex justify-between items-center py-1">
          <div className="text-secondary">Penggunaan Kuota</div>
          <Tag icon={<ICTagOutlined />} className="m-0">
            {data.servicePackageName}
          </Tag>
        </div>
        <div className="flex justify-between items-center">
          <Title level={2} className="header-2">
            {data.countUsagePercentage}%
          </Title>
          <Text type="secondary">
            {`${data.countUsage?.toLocaleString("en-US")}/${data.maxUsage?.toLocaleString(
              "en-US"
            )}`}
          </Text>
        </div>
        <Progress
          percent={data.countUsagePercentage}
          strokeColor={progressColor}
          showInfo={false}
        />
      </>
    );

  return (
    <div className="flex flex-col">
      <p className="text-secondary pb-1">Tidak ada paket aktif</p>
      {user.haveUsedTrial ? <ChoosePacket /> : <ActivateTrial />}
    </div>
  );
};

const EmailSendingSummary = () => {
  const emailSummary = useSelector(({ emailSummary }) => emailSummary.value);
  const user = useSelector(({ userData }) => userData.value);
  return (
    <>
      <Col lg={12} md={12} sm={24} xl={6}>
        <div className="px-6 py-4">
          <div className="flex justify-between items-center py-1">
            <div className="text-secondary">Total Terkirim</div>
            <Tooltip
              overlayInnerStyle={{ width: "max-content" }}
              trigger={["click"]}
              title="Email yang sudah kami kirim dan sampai ke tujuan">
              <InfoCircleOutlined className="text-secondary" />
            </Tooltip>
          </div>
          <Title level={2} className="header-2">
            {emailSummary?.totalDelivered?.toLocaleString("en-US")}
          </Title>
          <Text type="success" className="font-medium">
            Email Terkirim &#40;
            {Math.round((emailSummary?.totalDeliveredPercentage + Number.EPSILON) * 100) / 100}
            %&#41;
          </Text>
          <Divider className="my-2" />
        </div>
      </Col>
      <Col lg={12} md={12} sm={24} xl={6}>
        <div className="px-6 py-4">
          <div className="flex justify-between items-center py-1">
            <div className="text-secondary">Total Gagal</div>
            <Tooltip
              overlayInnerStyle={{ width: "max-content" }}
              trigger={["click"]}
              title="Email yang gagal kami kirimkan ke tujuan">
              <InfoCircleOutlined className="text-secondary" />
            </Tooltip>
          </div>
          <Title level={2} className="header-2">
            {emailSummary?.totalFailed?.toLocaleString("en-US")}
          </Title>
          <Text type="danger" className="font-medium">
            Email Gagal (
            {Math.round((emailSummary?.totalFailedPercentage + Number.EPSILON) * 100) / 100}%)
          </Text>
          <Divider className="my-2" />
        </div>
      </Col>
      <Col lg={12} md={12} sm={24} xl={6}>
        <div className="px-6 py-4">
          <div className="flex justify-between items-center py-1">
            <div className="text-secondary">Total Kiriman</div>
            <Tooltip
              overlayInnerStyle={{ width: "max-content" }}
              trigger={["click"]}
              title="Total seluruh email yang anda request untuk dikirim">
              <InfoCircleOutlined className="text-secondary" />
            </Tooltip>
          </div>
          <Title level={2} className="header-2">
            {emailSummary?.totalSent?.toLocaleString("en-US")}
          </Title>
          <Text type="primary" className="font-medium">
            Email Kiriman
          </Text>
          <Divider className="my-2" />
        </div>
      </Col>
      <Col lg={12} md={12} sm={24} xl={6}>
        <div className="px-6 py-4">
          <UsedQuota data={emailSummary.servicePackage} user={user} />
          <Divider className="my-2" />
        </div>
      </Col>
    </>
  );
};

export default EmailSendingSummary;
