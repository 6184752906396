import { RejectPayment } from "api";
import ModalConfirm from "components/popups/modal/ModalConfirm";
import ModalError from "components/popups/modal/ModalError";
import ModalSuccess from "components/popups/modal/ModalSuccess";

export const handleReject = ({ record, callBackConfirm, setOpenDrawerConfirmation }) => {
  ModalConfirm({
    title: `Tolak pembayaran dari ${record.companyName}`,
    customMessage: (
      <span>
        Apakah anda yakin ingin menolak pembayaran untuk invoice{" "}
        <span className="font-medium">{record.invNumber}</span> dari {record.companyName}?
        <br />
        <br /> Pembayaran sebesar {record.paymentAmount} akan ditolak dari sistem dan customer akan
        mendapat notifikasi penolakan.
      </span>
    ),
    okText: "Tolak Pembayaran",
    okButtonProps: { type: "danger" },
    cancelText: "Batalkan",
    width: 600,
    onOk: async () => {
      const { code } = await RejectPayment({ uuid: record.uuid });
      if (code === 200) {
        ModalSuccess({
          title: "Pembayaran berhasil ditolak",
          customMessage: (
            <span>
              Pembayaran untuk nomor invoice{" "}
              <span className="font-medium">{record.inv_number}</span> milik {record.company_name}{" "}
              berhasil ditolak
            </span>
          ),
          width: 471,
          onOk: () => {
            setOpenDrawerConfirmation(false);
            callBackConfirm();
          },
        });
      } else {
        ModalError({
          title: "Terdapat kesalahan pada sistem",
          customMessage:
            "Terdapat kendala pada sistem saat memperbarui pembayaran customer. Silakan hubungi Tim Product Development",
          width: 471,
          onOk: () => {
            setOpenDrawerConfirmation(false);
            callBackConfirm();
          },
        });
      }
    },
  });
};
