import {
  Form,
  Input,
  Checkbox,
  DatePicker,
  Select,
  Divider,
  Typography,
  Upload,
  Button,
} from "antd";
import { BanCharacters, getNumericValue, areNumbersApproximatelyEqual } from "utils/helpers";
import { ListBanks } from "api";
import { useEffect, useState } from "react";
import { ICArrowRightOutlined, ICUploadOutlined } from "components/list/ListIcon";
import { BankIndonesia, listsBankTarget } from "components/ListBank";

const { Text } = Typography;

export const FormRecordPayment = ({
  form,
  dataTable,
  handleBillPayment,
  billPayment,
  setTanggalFieldValue,
  setRekeningFieldValue,
  handlePphPayment,
  pphPayment,
  billCount,
  pphCount,
  validateNominalPajak,
  setBillCount,
  setPphCount,
  setMatchCount,
}) => {
  const [bankOptions, setBankOptions] = useState([]);
  // const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBanks = async () => {
      const response = await ListBanks();
      if (response.status === "success") {
        const banks = response.data.rows.map((bank) => ({
          value: bank.bankId,
          label: bank.bankName,
        }));
        setBankOptions(banks);
      }
      // setLoading(false);
    };

    fetchBanks();
  }, []);

  const InputBilling = areNumbersApproximatelyEqual(
    billCount,
    getNumericValue(dataTable.inv_price)
  );

  if (InputBilling) {
    setMatchCount(true);
  } else {
    setMatchCount(false);
  }

  return (
    <Form form={form} layout="vertical" className="flex flex-col gap-y-4">
      <Text>
        Record pembayaran dari <span className="font-medium">{dataTable.company_name}</span> untuk
        nomor invoice <span className="font-medium">{dataTable.inv_number}</span> dengan nominal{" "}
        {dataTable.inv_price}
      </Text>
      <Form.Item label="Nominal Pembayaran" name="nominal" className="mb-0 right-required">
        <Input
          addonBefore="Rp"
          placeholder="0"
          disabled={billPayment}
          value={billPayment || pphPayment ? billCount : undefined}
          onChange={(e) => setBillCount(e.target.value)}
          onKeyDown={(e) => {
            if (e.target.value.length >= 16 && e.keyCode !== 8 && e.keyCode !== 46) {
              e.preventDefault();
            }
            if (BanCharacters.test(e.key) && e.key !== "Backspace") {
              e.preventDefault();
            }
          }}
        />
        <Checkbox style={{ paddingTop: 6 }} onChange={handleBillPayment} disabled={pphPayment}>
          Nominal pembayaran sesuai tagihan
        </Checkbox>
      </Form.Item>
      <Form.Item label="Tanggal Pembayaran" name="tanggal" className="mb-0 right-required">
        <DatePicker
          placeholder="Pilih Tanggal"
          className="w-full"
          onChange={(e) => setTanggalFieldValue(e._d)}
        />
      </Form.Item>
      <Form.Item label="Rekening Bank Penerima" name="bank" className="mb-0 right-required">
        <Select
          placeholder="Pilih rekening tujuan"
          onChange={(e) => setRekeningFieldValue(e)}
          options={
            bankOptions.length > 0
              ? bankOptions
              : [
                  {
                    value: null,
                    label: (
                      <span className="flex justify-center text-secondary text-sm">
                        Data Belum Tersedia
                      </span>
                    ),
                  },
                ]
          }
          // disabled={loading}
        />
      </Form.Item>
      <Divider className="mt-0 mb-0" />
      <Form.Item
        name="nominal"
        className="mb-0 "
        {...(pphPayment && { rules: [{ validator: validateNominalPajak }] })}>
        <div className="flex flex-col">
          <Checkbox disabled={billPayment} onChange={handlePphPayment}>
            Pembayaran dikenakan potongan PPh dari customer
          </Checkbox>
          <span className="pt-2 pb-1 right-required-span">Nominal Pajak</span>
          <Input
            addonBefore="Rp"
            placeholder="0"
            disabled={!pphPayment || billPayment}
            value={pphPayment ? pphCount : undefined}
            onChange={(e) => {
              setPphCount(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.target.value.length >= 16 && e.keyCode !== 8 && e.keyCode !== 46) {
                e.preventDefault();
              }
              if (BanCharacters.test(e.key) && e.key !== "Backspace") {
                e.preventDefault();
              }
            }}
          />
        </div>
      </Form.Item>
    </Form>
  );
};

export const FormManualPayment = ({
  form,
  paymentData,
  setBillCount,
  setPphCount,
  billPayment,
  billCount,
  setSourceFieldValue,
  setRekeningTargetFieldValue,
  setTanggalFieldValue,
  handleBillPayment,
  setFileValue,
  adminConfirmation,
  pphPayment,
  pphCount,
  validateNominalPajak,
  handlePphPayment,
}) => {
  const props = {
    customRequest: ({ file, onSuccess }) => {
      onSuccess("ok");
    },
    multiple: false,
    maxCount: 1,
  };

  const [bankOptions, setBankOptions] = useState([]);

  useEffect(() => {
    if (adminConfirmation) {
      const fetchBanks = async () => {
        const response = await ListBanks();
        if (response.status === "success") {
          const banks = response.data.rows.map((bank) => ({
            value: bank.bankId,
            label: bank.bankName,
          }));
          setBankOptions(banks);
        }
      };

      fetchBanks();
    }
  }, [adminConfirmation]);

  return (
    <Form form={form} layout="vertical" className="flex flex-col gap-y-4">
      <Text>
        {adminConfirmation ? (
          <>
            Konfirmasi & record pembayaran dari{" "}
            <span className="font-medium">{paymentData.companyName}</span> untuk nomor invoice{" "}
            <span className="font-medium">{paymentData.invNumber}</span> dengan nominal{" "}
            {paymentData.paymentAmount}
          </>
        ) : (
          <>
            Konfirmasi pembayaran untuk tagihan dengan nomor invoice{" "}
            <span className="font-medium">{paymentData.invoice_number}</span> sebesar{" "}
            {paymentData.price}
          </>
        )}
      </Text>
      {adminConfirmation && (
        <div className="alert-confirmation">
          <div className="flex items-center justify-between text-left">
            <div className="flex flex-col justify-between">
              <Text className="text-xs font-medium">Source Bank</Text>
              <Text>{paymentData.sourceBank}</Text>
            </div>
            <ICArrowRightOutlined />
            <div className="flex flex-col justify-between text-right">
              <Text className="text-xs font-medium">Destination Bank</Text>
              <Text>{paymentData.destinationBank}</Text>
            </div>
          </div>
        </div>
      )}
      <Form.Item label="Nominal Pembayaran" name="nominal" className="mb-0 right-required">
        <Input
          addonBefore="Rp"
          placeholder="0"
          disabled={billPayment}
          value={billPayment || pphPayment ? billCount : undefined}
          onChange={(e) => setBillCount(e.target.value)}
          onKeyDown={(e) => {
            if (e.target.value.length >= 16 && e.keyCode !== 8 && e.keyCode !== 46) {
              e.preventDefault();
            }
            if (BanCharacters.test(e.key) && e.key !== "Backspace") {
              e.preventDefault();
            }
          }}
        />
        <Checkbox style={{ paddingTop: 6 }} onChange={handleBillPayment} disabled={pphPayment}>
          Nominal pembayaran sesuai tagihan
        </Checkbox>
      </Form.Item>
      <Form.Item label="Tanggal Pembayaran" name="tanggal" className="mb-0 right-required">
        <DatePicker
          placeholder="Pilih Tanggal"
          className="w-full"
          onChange={(e) => setTanggalFieldValue(!e ? null : e._d)}
        />
      </Form.Item>
      {!adminConfirmation && (
        <Form.Item label="Rekening Bank Pengirim" name="pengirim" className="mb-0 right-required">
          <Select
            showSearch
            placeholder="Pilih rekening pengirim"
            onChange={(e) => setSourceFieldValue(e)}
            optionFilterProp="label"
            filterOption={(input, option) =>
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            notFoundContent="Rekening pengirim tidak ditemukan"
            options={
              BankIndonesia.length > 0
                ? BankIndonesia
                : [
                    {
                      value: null,
                      label: (
                        <span className="flex justify-center text-secondary text-sm">
                          Data Belum Tersedia
                        </span>
                      ),
                    },
                  ]
            }
          />
        </Form.Item>
      )}
      <Form.Item label="Rekening Bank Penerima" name="penerima" className="mb-0 right-required">
        <Select
          placeholder="Pilih rekening tujuan"
          onChange={(e) => setRekeningTargetFieldValue(e)}
          {...(!adminConfirmation
            ? {
                options:
                  listsBankTarget.length > 0
                    ? listsBankTarget
                    : [
                        {
                          value: null,
                          label: (
                            <span className="flex justify-center text-secondary text-sm">
                              Data Belum Tersedia
                            </span>
                          ),
                        },
                      ],
              }
            : {
                options:
                  bankOptions.length > 0
                    ? bankOptions
                    : [
                        {
                          value: null,
                          label: (
                            <span className="flex justify-center text-secondary text-sm">
                              Data Belum Tersedia
                            </span>
                          ),
                        },
                      ],
              })}
        />
      </Form.Item>
      <Divider className="mt-0 mb-0" />
      {!adminConfirmation ? (
        <Form.Item
          name="filePayment"
          valuePropName="fileList"
          label="Bukti Pembayaran"
          getValueFromEvent={(e) => {
            setFileValue(e);
          }}
          className="mb-0 right-required"
          rules={[
            {
              validator: (rule, value) => {
                if (value && value.length) {
                  const fileSize = value[0].size / 1024 / 1024 < 2;
                  if (!fileSize) {
                    return Promise.reject(new Error("Ukuran file terlalu besar (maksimal: 2MB)"));
                  } else {
                    return Promise.resolve();
                  }
                }
              },
            },
          ]}>
          <Upload {...props} accept=".jpg, .jpeg, .png, .pdf">
            <Button icon={<ICUploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
      ) : (
        <Form.Item
          name="nominal"
          className="mb-0 "
          {...(pphPayment && { rules: [{ validator: validateNominalPajak }] })}>
          <div className="flex flex-col">
            <Checkbox disabled={billPayment} onChange={handlePphPayment}>
              Pembayaran dikenakan potongan PPh dari customer
            </Checkbox>
            <span className="pt-2 pb-1 right-required-span">Nominal Pajak</span>
            <Input
              addonBefore="Rp"
              placeholder="0"
              disabled={!pphPayment || billPayment}
              value={pphPayment ? pphCount : undefined}
              onChange={(e) => {
                setPphCount(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.target.value.length >= 16 && e.keyCode !== 8 && e.keyCode !== 46) {
                  e.preventDefault();
                }
                if (BanCharacters.test(e.key) && e.key !== "Backspace") {
                  e.preventDefault();
                }
              }}
            />
          </div>
        </Form.Item>
      )}
    </Form>
  );
};
