import { Card, Col, Row } from "antd";
import { useSelector } from "react-redux";

import CardEmailBounce from "components/box-card/CardEmailBounce";

const EmailBounceSummary = () => {
  const sentEmail = useSelector(({ emailSummary }) => emailSummary.value);
  return (
    <Card title="Email Bounce Summary" loading={!sentEmail} className="email-summary-wrapper">
      <Row gutter={[24, 16]} className="card-bounce-wrapper" wrap>
        {sentEmail &&
          sentEmail.emailSummary.map((item) => (
            <Col lg={12} key={item.titleFailed}>
              <CardEmailBounce
                title={item.titleFailed}
                number={item.failed}
                percentBounce={item.failedPercentage}
              />
            </Col>
          ))}
      </Row>
    </Card>
  );
};

export default EmailBounceSummary;
