import { Card, Row, Skeleton } from "antd";
import CardSummarySuppress from "components/box-card/CardSummarySuppress";
import "./suppress.less";

const SummarySuppress = ({ dataSummary, showSkeletonSummary }) => {
  const isDataLoadFailed = dataSummary.code === 500;
  return (
    <div className="mb-6">
      <Card title="Suppression Summary" className="suppress-summary-wrapper">
        {showSkeletonSummary ? (
          <Skeleton active />
        ) : isDataLoadFailed ? (
          <Row gutter={[24, 0]} className="summary-row">
            <CardSummarySuppress title="Global" number={"-"} />
            <CardSummarySuppress title="Whitelist" number={"-"} />
            <CardSummarySuppress title="Hard" number={"-"} />
            <CardSummarySuppress title="Manual" number={"-"} />
          </Row>
        ) : (
          <Row gutter={[24, 0]} className="summary-row">
            <CardSummarySuppress title="Global" number={dataSummary.data?.summary.global} />
            <CardSummarySuppress title="Whitelist" number={dataSummary.data?.summary.whitelist} />
            <CardSummarySuppress title="Hard" number={dataSummary.data?.summary.hard} />
            <CardSummarySuppress title="Manual" number={dataSummary.data?.summary.manual} />
          </Row>
        )}
      </Card>
    </div>
  );
};

export default SummarySuppress;
