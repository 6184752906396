import { Card, Progress, Tag } from "antd";
import { ICDollarCircleOutlined, ICTagOutlined } from "components/list/ListIcon";
import React from "react";

const CardPacketInfo = ({ data, loading }) => {
  if (!data || loading === "all") return <Card title="Informasi Paket" loading />;

  return (
    <div className="packageWrapper">
      <Card title="Informasi Paket" loading={loading === "informasiPaket"}>
        {data === "package" ? (
          <>
            <div className="flex justify-between mb-4">
              <p className="mb-0">Periode</p>
              <span className="text-secondary">Tidak Aktif</span>
            </div>
            <div className="flex justify-between mb-4">
              <p className="mb-0">Status Langganan</p>
              <Tag color="red">Inactive</Tag>
            </div>
            <div className="flex justify-between mb-4 packageLine">
              <p className="mb-0">Paket Layanan</p>
              <Tag color="red" icon={<ICTagOutlined />}>
                Inactive
              </Tag>
            </div>
            <div className="flex justify-between mb-4">
              <p className="mb-0">Tipe Langganan</p>
              <Tag color="red" icon={<ICDollarCircleOutlined />}>
                {" "}
                Inactive
              </Tag>
            </div>

            <div className="kuota-wrapper mb-4">
              <div className="flex justify-between items-center">
                <span>Kuota</span>
                <span className="text-secondary">0/0 (0%)</span>
              </div>
              <Progress percent={0} showInfo={false} />
            </div>
            <div className="domain-wrapper">
              <div className="flex justify-between items-center">
                <span>Domain</span>
                <span className="text-secondary">0/0 (0%)</span>
              </div>
              <Progress percent={0} showInfo={false} />
            </div>
          </>
        ) : (
          <div className="flex flex-col">
            <div className="flex justify-between mb-4">
              <p className="mb-0">Periode</p>
              <p className="mb-0 text-secondary text-right">{data.period}</p>
            </div>
            <div className="flex justify-between mb-4">
              <p className="mb-0">Status Langganan</p>
              <Tag color={data === "package" ? "red" : "blue"} className="blue-6 m-0">
                {data.status === "active" && "Active"}
              </Tag>
            </div>
            <div className="flex justify-between mb-4 packageLine">
              <p className="mb-0">Paket Layanan</p>
              <Tag className="m-0" color={data === "package" && "red"} icon={<ICTagOutlined />}>
                {data.package}
              </Tag>
            </div>
            <div className="flex justify-between mb-4">
              <p className="mb-0">Tipe Langganan</p>
              {data.typeSubscription ? (
                <Tag
                  icon={<ICDollarCircleOutlined />}
                  color={data === "package" && "red"}
                  className="m-0">
                  {data.typeSubscription}
                </Tag>
              ) : (
                <span>-</span>
              )}
            </div>
            <div className="kuota-wrapper mb-4">
              <div className="flex justify-between items-center">
                <span>Kuota</span>
                <span className="text-secondary">
                  {data.currentEmail.toLocaleString("en-US")}/
                  {data.limitEmail.toLocaleString("en-US")} (
                  {Math.round((data.currentEmail / data.limitEmail) * 100)}
                  %)
                </span>
              </div>
              <Progress
                percent={Math.round((data.currentEmail / data.limitEmail) * 100)}
                strokeColor={
                  Math.round((data.currentEmail / data.limitEmail) * 100) >= 80
                    ? "#FF4D4F"
                    : "#0D9F3F"
                }
                showInfo={false}
              />
            </div>
            <div className="domain-wrapper">
              <div className="flex justify-between items-center">
                <span>Domain</span>
                <span className="text-secondary">
                  {data.currentDomain}/{data.limitDomain} (
                  {Math.round((data.currentDomain / data.limitDomain) * 100)}
                  %)
                </span>
              </div>
              <Progress
                percent={Math.round((data.currentDomain / data.limitDomain) * 100)}
                strokeColor={
                  Math.round((data.currentDomain / data.limitDomain) * 100) >= 80
                    ? "#FF4D4F"
                    : "#0D9F3F"
                }
                showInfo={false}
              />
            </div>
          </div>
        )}
      </Card>
    </div>
  );
};

export default CardPacketInfo;
