import { MoreOutlined } from "@ant-design/icons";
import { Button, Card, Dropdown, Menu } from "antd";
import customerService from "assets/images/customer_service.svg";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import { ICQuestionCircleOutlined } from "components/list/ListIcon";
import DrawerDocumentation from "components/popups/drawer/DrawerDocumentation";
import { useState } from "react";
import useCheckRelay from "utils/hooks/useCheckRelay";
import useGetDomainActive from "utils/hooks/useGetDomainActive";
import useDocumentTitle from "utils/useDocumentTitle";
import "./dokumentasi.less";
import ListDokumentasi from "./ListDokumentasi";

const Dokumentasi = () => {
  useDocumentTitle("Dokumentasi");
  useCheckRelay();
  useGetDomainActive();
  const [drawerText, setDrawerText] = useState(false);
  const [visible, setVisible] = useState(false);

  const onClose = () => {
    setVisible(false);
  };

  const menu = (
    <Menu
      items={[
        { key: 1, label: "Kirim panduan ke email" },
        { key: 2, label: "Bantuan" },
      ]}
    />
  );

  const extraBtn = (
    <Dropdown overlay={menu} trigger={["click"]}>
      <MoreOutlined rotate={90} />
    </Dropdown>
  );

  return (
    <>
      <DashboardHeader title="Panduan Setting" />
      <div className="dokumentasi-user flex flex-col gap-y-6 px-6 py-4">
        <Card
          title={`Panduan Konfigurasi ${process.env.REACT_APP_NAME}`}
          className="w-full"
          extra={extraBtn}>
          <p className="mb-3 text-secondary">
            Lakukan konfigurasi {process.env.REACT_APP_NAME} sesuai dengan engine email server yang
            digunakan, anda dapat mengikuti salah satu panduan berikut
          </p>
          <ul className="list-doc">
            <ListDokumentasi setOpen={setVisible} setDrawer={setDrawerText} />
            <DrawerDocumentation
              isOpen={visible}
              title={drawerText.title}
              onClose={onClose}
              children={drawerText.element}
            />
          </ul>
        </Card>
        <Card className="blue-card help-card-wrapper">
          <div className="flex justify-between items-center">
            <div className="help-card-left">
              <h5 className="font-medium text-base">Butuh Bantuan Kami Lebih Lanjut?</h5>
              <p className="mb-4 text-secondary w-full">
                Apabila anda membutuhkan bantuan kami lebih lanjut untuk melakukan konfigurasi SMTP
                Relay pada Email Server Anda, kami siap membantu. Gunakan layanan support ticket
                kami untuk mendapatkan support lebih lanjut.
              </p>
              <Button
                type="primary"
                icon={<ICQuestionCircleOutlined />}
                target="_blank"
                href="https://support.aktiva.co.id ">
                Akses Support Ticket
              </Button>
            </div>
            <div className="help-card-right">
              <img src={customerService} alt="Customer Service" />
            </div>
          </div>
        </Card>
      </div>
    </>
  );
};

export default Dokumentasi;
