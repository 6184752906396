import { Button, Form, Input } from "antd";
import { checkExistedDomain } from "api";
import { regexDomain } from "utils/helpers";

const FormDomain = ({ className = "", wrapperCol, label = "", isSetup = false, ...props }) => {
  const { registered, setRegistered, inputClass = "" } = props;

  const inputOnly = <Input placeholder="yourdomain.com" />;
  const withButton = (
    <div className={`flex domain-form-dashboard ${inputClass}`}>
      <Input placeholder="company.com" className="mr-1" />
      <Button htmlType="submit" disabled={registered.disabled} type="primary">
        Aktivasi Trial
      </Button>
    </div>
  );

  const inputRender = isSetup ? withButton : inputOnly;
  return (
    <Form.Item
      name="namaDomain"
      label={label}
      className={className}
      wrapperCol={wrapperCol}
      hasFeedback
      rules={[
        {
          validator: (_, domain) => {
            if (!domain) {
              setRegistered({ ...registered, disabled: false });
              return Promise.reject(new Error("Harap diisi terlebih dahulu"));
            } else {
              const regexvalid = new RegExp(regexDomain).test(domain);
              if (regexvalid)
                return new Promise((resolve, reject) => {
                  checkExistedDomain({ namaDomain: domain }).then(({ data }) => {
                    if (data === "existed") {
                      setRegistered({ ...registered, disabled: true });
                      return reject(
                        new Error(
                          "Nama domain sudah terdaftar pada sistem, silakan gunakan domain lain atau hubungi Customer Care kami"
                        )
                      );
                    }
                    setRegistered({ ...registered, disabled: false });
                    return resolve();
                  });
                });
              else return Promise.reject(new Error("Harap masukkan domain yang benar"));
            }
          },
        },
      ]}>
      {inputRender}
    </Form.Item>
  );
};

export default FormDomain;
