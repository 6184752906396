import { Button, Card, Checkbox, Form, Input, Modal, Tag, Tooltip } from "antd";
import { addDomainClients, changeNotifikasiClients, deleteDomainClients } from "api";
import {
  ICBellOutlined,
  ICDeleteOutlined,
  ICExclamationCircleOutlined,
  ICPlusOutlined,
} from "components/list/ListIcon";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isFunction, runFunction } from "utils/helpers";

import { EmptyWrappers } from "components/empty/EmptyComponent";
import FormDomain from "components/form/FormDomain";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import { setDetailCompany } from "redux/slices/admin/detailCompany";
import { gatherFormData, requiredRules } from "utils/helpers";

const BtnAddDomain = ({ showModal, setShowModal, sectionLoad }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);
  const { profileCompany } = useSelector(({ detailCompany }) => detailCompany.value);

  const gatherFormDatas = (callback) => {
    if (form && form.getFieldsValue && form.getFieldsError) {
      form.submit();
      let errAmount = [];
      setTimeout(() => {
        form.getFieldsError().map((e) => e.errors.length && errAmount.push(e));
        const values = form.getFieldsValue();
        if (errAmount.length < 1 && isFunction(callback)) return callback(values);
      }, 100);
      return false;
    }
    return false;
  };

  const handleOk = () => {
    const callback = async () => {
      setLoading(true);
      const values = form.getFieldsValue();
      const notifikasi = values?.notifikasi ? true : false;
      await addDomainClients({
        ...values,
        companyUuid: profileCompany?.companyUuid,
        notifikasi: notifikasi,
      });
      form.resetFields();
      setShowModal(false);
      setLoading(false);
      runFunction(() => sectionLoad("registeredDomain"));
    };
    gatherFormDatas(callback);
  };

  return (
    <>
      <Button type="primary" icon={<ICPlusOutlined />} onClick={() => setShowModal(true)}>
        Tambah Domain
      </Button>
      <Modal
        title="Tambah Domain Baru"
        open={showModal}
        onOk={handleOk}
        onCancel={() => setShowModal(false)}
        okText="Simpan"
        centered
        cancelText="Batal"
        okButtonProps={{ loading: loading, disabled: registered.disabled, htmlType: "submit" }}>
        <Form layout="vertical" form={form} initialValues={{ notifikasi: true }}>
          <FormDomain
            className="mb-4"
            label="Nama Domain"
            setRegistered={setRegistered}
            registered={registered}
          />
          <Form.Item name="notifikasi" valuePropName="checked">
            <Checkbox>Aktifkan notifikasi via email untuk domain ini</Checkbox>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const ListDomain = (x) => {
  const [form] = Form.useForm();
  const { sectionLoad } = x;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showDelDomain, setShowDelDomain] = useState(false);
  const detailCompany = useSelector(({ detailCompany }) => detailCompany.value);
  const { profileCompany, domainCompany } = detailCompany;

  const handleType = async ({ domainUuid, notification }) => {
    const updateNotif = domainCompany.map((listDomain) => {
      if (listDomain.domainUuid === domainUuid) {
        return { ...listDomain, notification: !notification };
      }
      return listDomain;
    });
    dispatch(setDetailCompany({ ...detailCompany, domainCompany: updateNotif }));
    const { code, status } = await changeNotifikasiClients({
      domainUuid: domainUuid,
      notifikasi: !notification,
    });
    if (code === 200 && status === "success");
  };

  return (
    <>
      <div className="flex items-center justify-between list-domain-wrapper">
        <div className="flex items-center gap-x-2 left-domain">
          <p className="mb-0 font-medium">{x?.domainName}</p>
          <Tag color={x?.status === "inactive" ? "red" : "blue"} className="mr-0">
            {x?.status}
          </Tag>
        </div>
        <div className="flex gap-x-2 right-domain items-center">
          <Tooltip title={x?.notification ? "Notifikasi aktif" : "Notifikasi tidak aktif"}>
            <Button
              type={x?.notification ? "primary" : "default"}
              className="w-12"
              icon={<ICBellOutlined />}
              onClick={() => handleType(x)}
            />
          </Tooltip>
          <Tooltip title="Hapus Domain">
            <Button
              danger
              icon={<ICDeleteOutlined />}
              className="w-12"
              onClick={() => setShowDelDomain(true)}
            />
          </Tooltip>
        </div>
      </div>
      <Modal
        open={showDelDomain}
        children={
          <div className="ant-modal-confirm-body-wrapper">
            <div className="ant-modal-confirm-body">
              <ICExclamationCircleOutlined className="text-warning" />
              <span className="ant-modal-confirm-title">Hapus Domain Terdaftar</span>
              <div className="ant-modal-confirm-content">
                <p className="mb-4">
                  Apakah anda yakin ingin menghapus domain
                  <span className="font-medium"> {x?.domainName}</span> dari domain terdaftar?
                  Setelah menghapus domain, seluruh kiriman email dari domain tersebut
                  <span className="font-medium"> tidak dapat</span> melewati jalur{" "}
                  {process.env.REACT_APP_NAME}.
                </p>
                <Form layout="vertical" form={form} name="hapusDomain">
                  <Form.Item
                    label="Ketik nama domain anda untuk konfirmasi hapus"
                    name="domain"
                    validateFirst
                    rules={[
                      requiredRules,
                      {
                        validator(_, value) {
                          if (value === x?.domainName) return Promise.resolve();
                          return Promise.reject(new Error("Nama domain tidak sama!"));
                        },
                      },
                    ]}>
                    <Input placeholder="company.com" />
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        }
        onCancel={() => {
          form.resetFields();
          setShowDelDomain(false);
        }}
        onOk={() => {
          const callback = async () => {
            setLoading(true);
            const { msg, status } = await deleteDomainClients({
              domainUuid: x?.domainUuid,
              companyUuid: profileCompany?.companyUuid,
            });
            setLoading(false);
            if (msg.includes("deleted") && status === "success") {
              form.resetFields();
              ModalSuccess({
                title: `Domain ${x?.domainName} berhasil dihapus`,
                message: `Domain ${x?.domainName} sudah berhasil dihapus pada sistem`,
              });

              runFunction(() => sectionLoad("registeredDomain"));
            }
            setLoading(false);
            setShowDelDomain(false);
          };
          gatherFormData(form, callback);
        }}
        okButtonProps={{
          htmlType: "submit",
          danger: true,
          loading,
        }}
      />
    </>
  );
};

const RegisteredDomain = ({ loading, sectionLoad }) => {
  const { domainCompany } = useSelector(({ detailCompany }) => detailCompany.value);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Card
        className="w-full h-auto"
        loading={loading === "registeredDomain"}
        title="Domain Terdaftar"
        extra={
          <BtnAddDomain
            showModal={showModal}
            setShowModal={setShowModal}
            domain={domainCompany}
            loading={loading === "registeredDomain"}
            sectionLoad={sectionLoad}
          />
        }>
        {domainCompany?.length > 0 ? (
          domainCompany?.map((x) => <ListDomain {...x} sectionLoad={sectionLoad} />)
        ) : (
          <EmptyWrappers imgMargin="mb-3" title="Belum ada domain yang di daftarkan" />
        )}
      </Card>
    </>
  );
};

export default RegisteredDomain;
