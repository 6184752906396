import { Col, Row } from "antd";
import { getDetailsSubscription, getDomain, getUser } from "api";
import CardRegisteredDomain from "components/box-card/CardRegisteredDomain";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setDetailsSubscription } from "redux/slices/detailsSubscription";
import { setListDomain } from "redux/slices/listDomain";
import { setUserData } from "redux/slices/user";
import useDocumentTitle from "utils/useDocumentTitle";
import DNSAlert from "../main/alert/DNSAlert";
import DomainAlert from "../main/alert/DomainAlert";
import InformationAccountSetup from "./InformationAccountSetup";
import "./setupcard.less";
import StatusEmailServer from "./StatusEmailServer";
import useGetData from "./useGetData";

const HasAlert = ({ user = false, domain = [] }) => {
  const hasInactiveDomain = domain && domain.filter((item) => item.status === "inactive");

  const dnsStatus =
    domain &&
    domain.filter(
      ({ aRecord, dkim, dmarc, spf }) =>
        !aRecord.status || !dkim.status || !dmarc.status || !spf.status
    );

  if (hasInactiveDomain.length > 0 || dnsStatus.length > 0)
    return (
      <div className="flex flex-col gap-y-3">
        {hasInactiveDomain.length > 0 && user.isSetupDomain && (
          <DomainAlert domain={user.inactiveDomain} user={user} />
        )}
        {dnsStatus.length > 0 && user.isSetupDomain && (
          <DNSAlert domain={user.dnsStatus} user={user} />
        )}
      </div>
    );

  return "";
};

const SetupLayanan = () => {
  useDocumentTitle("Setup Layanan");
  useGetData();
  const dispatch = useDispatch();
  const dataSetup = useSelector(({ setupLayanan }) => setupLayanan.value);
  const dataDomain = useSelector(({ listDomain }) => listDomain.value);
  const user = useSelector(({ userData }) => userData.value);
  const { hash } = useLocation();

  const getData = async () => {
    const { data } = await getDomain();
    const { data: userData } = await getUser({ uuid: user.uuid });
    const { data: detailSubs } = await getDetailsSubscription();
    dispatch(setListDomain(data));
    dispatch(setUserData(userData));
    dispatch(setDetailsSubscription(detailSubs));
  };

  useEffect(() => {
    const id = document.getElementById(hash.replace("#", ""));
    if (dataSetup && dataDomain && hash) {
      setTimeout(() => {
        window.scrollTo({ top: id.offsetTop, behavior: "smooth" });
      }, 100);
    }
  }, [hash, dataDomain, dataSetup]);

  if (!dataSetup && !dataDomain)
    return (
      <div className="flex flex-col gap-y-6 px-6 py-4">
        <Row gutter={[24, 16]}>
          <Col xl={12} className="w-full">
            <Skeleton height={348} className="w-full" />
          </Col>
          <Col xl={12} className="w-full">
            <Skeleton height={348} className="w-full" />
          </Col>
        </Row>
        <Skeleton id="dns-reputation" height={372} className="w-full" />
      </div>
    );

  return (
    <div className="setup-layanan-page">
      <DashboardHeader title="Setup Layanan" />
      <div className="flex flex-col gap-y-6 px-6 py-4">
        <HasAlert user={user} domain={dataDomain} />
        <Row gutter={[24, 16]}>
          <Col xl={12} className="w-full">
            <InformationAccountSetup />
          </Col>
          <Col xl={12} className="w-full">
            <CardRegisteredDomain getOthers={getData} />
          </Col>
        </Row>
        <StatusEmailServer />
      </div>
    </div>
  );
};

export default SetupLayanan;
