import { Button, Card, Descriptions, Form, Space, Tag, Typography } from "antd";
import { setUpNewDomain, unlockRelay } from "api";
import FormDomain from "components/form/FormDomain";
import {
  ICCheckOutlined,
  ICCopyOutlined,
  ICEyeInvisibleOutlined,
  ICEyeOutlined,
  ICUnlockOutlined,
} from "components/list/ListIcon";
import ModalConfirm from "components/popups/modal/ModalConfirm";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import ModalWarning from "components/popups/modal/ModalWarning";
import ModalWarningLocked from "components/popups/modal/ModalWarningLocked";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { setUserProfile } from "redux/slices/profile";
import { setSetupLayanan } from "redux/slices/setupLayanan";
import { btnCopyPassword, btnShowHidePassword } from "utils/copyHidePass";
import { capitalizeFirstLetter, runFunction } from "utils/helpers";

const { Paragraph } = Typography;

const BtnActivation = ({ statusRelay, getOthers, role }) => {
  const showModal = () => {
    ModalConfirm({
      title: `Unlock akun ${process.env.REACT_APP_NAME}?`,
      customMessage: (
        <>
          <p>
            Sebelum melakukan unlock akun, pastikan sudah melakukan pembersihan email spam pada
            email server anda agar akun tidak terkena lock kembali.
          </p>
          <p>
            Jika akun anda terkena lock sebanyak 3 kali berturut-turut, maka fitur unlock akun
            secara mandiri akan dinonaktifkan.
          </p>
        </>
      ),
      okButtonProps: { danger: true },
      okText: "Unlock akun",
      onOk: async () => {
        const { data, status, msg, code } = await unlockRelay();
        if (
          status === "failed" &&
          msg === "Company does not have any active subscriptions!" &&
          code === 400
        ) {
          ModalWarningLocked({
            title: `Unlock akun SMTP Gagal`,
            customMessage: (
              <div>
                <p>
                  Akun SMTP anda gagal diaktifkan karena anda tidak memiliki subscription yang
                  aktif. Silakan dapat berlangganan layanan kami agar dapat melakukan unlock account
                  dan terus menggunakan layanan kami.
                </p>
              </div>
            ),
            width: 600,
            showSubscriptionButton: true,
          });
        } else if (status === "failed" && msg === "Unlock exceeded the limit!" && code === 400) {
          const date = new Date(data.unlockTime);
          date.setHours(date.getHours() + 1); // Add 1 hour
          date.setMinutes(date.getMinutes() + 30); // Add 30 minutes

          if (date.getMinutes() >= 60) {
            date.setHours(date.getHours() + 1);
            date.setMinutes(date.getMinutes() - 60);
          }
          const hour = date.getHours();
          const minute = String(date.getMinutes()).padStart(2, "0");
          const second = date.getSeconds();
          const timeZone = -(new Date().getTimezoneOffset() / 60);
          const timeRegion = [
            { time: 7, region: "WIB" },
            { time: 8, region: "WITA" },
            { time: 9, region: "WIT" },
          ];
          const localTime = timeRegion.find((item) => item.time === timeZone);
          ModalWarning({
            title: `Unlock akun ${process.env.REACT_APP_NAME} Gagal`,
            customMessage: (
              <div>
                <p>
                  Anda sudah melakukan unlock akun Relay selama 3 kali berturut-turut dan terdeteksi
                  sebagai aktivitas yang tidak wajar. Saat ini anda tidak diizinkan untuk melakukan
                  unlock akun {process.env.REACT_APP_NAME} anda.
                </p>
                <p>
                  Silakan lakukan unlock akun kembali pada pukul
                  {` ${hour}:${minute}:${second} ${localTime.region || ""}`}, atau hubungi
                  <a href="https://support.aktiva.co.id/"> Customer Care</a>.
                </p>
              </div>
            ),
            width: 600,
          });
        } else {
          ModalSuccess({
            title: `Akun ${process.env.REACT_APP_NAME} berhasil di unlock`,
            message: `Akun ${process.env.REACT_APP_NAME} anda berhasil di unlock, saat ini anda dapat mengirimkan email kembali`,
            onOk: () => {
              runFunction(() => getOthers("informasiPerusahaan"));
            },
          });
        }
      },
    });
  };

  return (
    <Button
      type="primary"
      danger
      disabled={role !== "billing" && statusRelay === "locked" ? false : true}
      icon={<ICUnlockOutlined />}
      onClick={showModal}>
      Unlock
    </Button>
  );
};

const TitleCard = ({ statusRelay }) => {
  return (
    <Space>
      <span>Informasi Akun</span>
      <Tag
        color={statusRelay === "active" ? "blue" : statusRelay === "suspended" ? "yellow" : "red"}>
        {capitalizeFirstLetter(statusRelay === "closed" ? "inactive" : statusRelay)}
      </Tag>
    </Space>
  );
};

const AccountInformation = ({ data = false, domainUuid, getOthers, user }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [copyPassword, setCopyPassword] = useState(true);
  const [registered, setRegistered] = useState(false);
  const pwdHide = <span>************</span>;

  const handleAddDomain = async (values) => {
    setLoading(true);
    const { msg, status } = await setUpNewDomain(values);
    if (msg.includes("Created") && status === "success") {
      setLoading(false);
      form.resetFields();
      ModalSuccess({
        title: `Domain ${values.namaDomain} sedang diaktivasi`,
        message:
          "Domain namadomain.com telah berhasil ditambahkan pada sistem kami. Saat ini kami sedang melakukan aktivasi domain anda. Tunggu proses aktivasi dan jangan tutup browser anda.",
        onOk: () => {
          dispatch(setSetupLayanan(false));
          dispatch(setUserProfile(false));
          navigate("/setup");
        },
        width: 500,
      });
    }
    setLoading(false);
  };

  return (
    <Card
      loading={loading === "AccountInformation"}
      title={<TitleCard statusRelay={data ? data?.statusRelay : "inactive"} />}
      extra={
        <BtnActivation
          statusRelay={data ? data?.statusRelay : "inactive"}
          uuidDomain={domainUuid}
          getOthers={getOthers}
          role={user.role}
        />
      }>
      {data ? (
        <Descriptions layout="horizontal" column={1} className="description__wrapper">
          <Descriptions.Item label="Username">
            <Paragraph className="mb-0" copyable>
              {data.userRelay}
            </Paragraph>
          </Descriptions.Item>
          <Descriptions.Item label="Password">
            <div className="flex flex items-center gap-2 w-0">
              {hidePassword ? pwdHide : <p className="mb-0">{data.passwordRelay}</p>}
              <Button
                className="m-0 text-secondary act-pass"
                type="link"
                icon={
                  hidePassword ? (
                    <ICEyeOutlined className="text-sm" />
                  ) : (
                    <ICEyeInvisibleOutlined className="text-sm" />
                  )
                }
                onClick={() => btnShowHidePassword(setHidePassword)}
              />
              <Button
                className="m-0 text-secondary act-pass"
                type="link"
                icon={
                  copyPassword ? (
                    <ICCopyOutlined className="text-sm " />
                  ) : (
                    <ICCheckOutlined className="text-sm" />
                  )
                }
                onClick={() => btnCopyPassword(data.passwordRelay, setCopyPassword)}
              />
            </div>
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <Form layout="vertical" onFinish={handleAddDomain}>
          <FormDomain
            className="mb-0"
            inputClass="blob"
            label="Trial anda belum aktif, lakukan setup domain sekarang"
            loading={loading}
            isSetup
            setRegistered={setRegistered}
            registered={registered}
          />
        </Form>
      )}
    </Card>
  );
};

export default AccountInformation;
