import { Alert } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const SetupNavigate = () => {
  const { pathname } = useLocation();
  const navHref = <a href="/setup#dns-reputation"> Setup Layanan</a>;
  const navLink = <Link to="/setup#dns-reputation"> Setup Layanan</Link>;

  return pathname.includes("setup") ? navHref : navLink;
};

const DomainAlert = ({ className, domain, user }) => {
  if (!user && !user.inactiveDomain && !user.isSetupDomain) return "";

  return (
    <Alert
      className={className}
      message={
        <p className="mb-0">
          Domain <span className="font-medium">{domain}</span> saat ini belum aktif. Silakan lakukan
          aktivasi dengan klik tombol Aktivasi pada
          <SetupNavigate />
        </p>
      }
      type="warning"
      showIcon
    />
  );
};

export default DomainAlert;
