import { Button, Divider, Tag } from "antd";
import DashboardPageHeader from "components/dashboard-header/DashboardPageHeader";
import MarkdownInput from "components/input/MarkdownInput";
import { ICEditOutlined } from "components/list/ListIcon";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PreviewDocumentation = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  if (!state) navigate("/admin/dokumentasi");
  const { subject, body, category } = state;

  const navEditDocumentation = () => navigate("/admin/dokumentasi/edit", { state });

  const ButtonExtraHeader = () => {
    return (
      <Button type="primary" icon={<ICEditOutlined />} onClick={navEditDocumentation}>
        Edit Dokumen
      </Button>
    );
  };

  return (
    <div className="documentationAdmin__view">
      <DashboardPageHeader title={subject} extra={<ButtonExtraHeader />} />
      <div className="px-6 py-4">
        <div className="previewDocumentation">
          <MarkdownInput data={body} />
          <Divider className="my-4" />
          <span>Kategori Dokumen: </span>
          {category.map((item, index) => (
            <Tag className="mr-1" key={index}>
              {item}
            </Tag>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PreviewDocumentation;
