import { createSlice } from "@reduxjs/toolkit";

export const listNotificationAdmin = createSlice({
  name: "listNotificationAdmin",
  initialState: {
    value: false,
  },
  reducers: {
    setListNotificationAdmin: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setListNotificationAdmin } = listNotificationAdmin.actions;
export default listNotificationAdmin.reducer;
