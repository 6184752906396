import { getAccountSMTP } from "api";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAccount } from "redux/slices/accountSMTP";

const useCheckRelay = () => {
  const accountSMTP = useSelector(({ accountSMTP }) => accountSMTP.value);

  const dispatch = useDispatch();
  const action = setAccount;

  const doSet = useCallback((data) => dispatch(action(data)), [dispatch, action]);

  useEffect(() => {
    const checkRelay = async () => {
      const { data } = await getAccountSMTP();
      if (data && Object.keys(data).length) doSet(data);
    };
    if (!accountSMTP) checkRelay();
  }, [accountSMTP, doSet]);
};

export default useCheckRelay;
