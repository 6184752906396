import { LogoAktiva } from "components/Components";
import ApiError from "components/error/ApiError";
import { useLocation } from "react-router-dom";
import NotifVerifikasi from "./NotifVerifikasi";

const ClickVerifikasi = () => {
  const { state } = useLocation();
  if (!state) window.location.href = "/login";
  return (
    <div className="flex flex-col h-screen">
      <LogoAktiva className="logo-aktiva" />
      <div className="flex flex-col h-full items-center auth-form mx-auto">
        {state.code === 500 ? (
          <ApiError
            title="Terdapat kendala pada server"
            body="Terdapat kendala saat mengakses ke server, silakan dapat memuat ulang halaman atau dapat hubungi Customer Care kami"
          />
        ) : (
          <NotifVerifikasi StatusVerif={state} />
        )}
      </div>
      <div className="footer-height">
        <div className="bg-footer w-full"></div>
      </div>
    </div>
  );
};

export default ClickVerifikasi;
