import { createSlice } from "@reduxjs/toolkit";

export const billingDataInvoiceTable = createSlice({
  name: "billingDataInvoiceTable",
  initialState: {
    value: false,
  },
  reducers: {
    setBillingDataInvoiceTable: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setBillingDataInvoiceTable } = billingDataInvoiceTable.actions;
export default billingDataInvoiceTable.reducer;
