import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";

const RequireAuth = ({ allowedRoles }) => {
  const user = useSelector(({ userData }) => userData.value);
  const authorized = allowedRoles.find((role) => role === user.role);
  const location = useLocation();
  return authorized ? <Outlet /> : <Navigate to="main" state={{ from: location }} replace />;
};

export default RequireAuth;
