import { ConfigProvider, Table } from "antd";
import { EmptyWrapper } from "components/empty/EmptyComponent";
import TableTitle from "components/tableSendiri/TableTitle";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CurrencyFormat } from "utils/ConvertData";

const UsedPackageTable = ({ filter, setFilter }) => {
  const packageUsed = useSelector(({ packageUsed }) => packageUsed.value);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1200;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const column = [
    {
      title: "Nama Paket",
      dataIndex: "name",
      key: "name",
      ...(width < breakpoint ? { width: 280 } : {}),
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      key: "description",
      ...(width < breakpoint ? { width: 556 } : {}),
    },
    {
      title: "Pengguna",
      dataIndex: "user",
      key: "user",
      render: (text) => (
        <div className="flex flex-col">
          <p className="mb-0">{text}</p>
          <p className="text-secondary mb-0 text-xs">Pengguna</p>
        </div>
      ),
    },
    {
      title: "Biaya Langganan",
      dataIndex: "price",
      key: "price",
      align: "right",
      ...(width < breakpoint ? { width: 180 } : {}),
      render: (item) => CurrencyFormat(item),
    },
  ];

  return (
    <ConfigProvider
      renderEmpty={() => (
        <EmptyWrapper
          title="Data belum tersedia"
          message="Data paket terpakai akan muncul jika terdapat customer yang berlangganan paket yang disediakan."
        />
      )}>
      <Table
        dataSource={packageUsed.rows}
        columns={column}
        className="package-admin-table"
        pagination={{
          current: filter.page,
          pageSize: filter.limit,
          hideOnSinglePage: true,
          onChange: (page) => setFilter({ ...filter, page }),
          total: packageUsed.count,
          showSizeChanger: false,
          size: "small",
        }}
        title={() => (
          <TableTitle
            title="Paket Terpakai"
            desc="List paket yang sudah digunakan oleh pelanggan"
            pageLimit={{
              currentLimit: filter.limit,
              onSelectLimit: (limit) => {
                setFilter({ ...filter, limit });
              },
              disable: packageUsed.count <= 10,
            }}
            buttonExport={{
              dataCSV: packageUsed.rows,
              // headerCSV: headerCSV,
              fileName: "List-Paket-Terpakai-All Client",
            }}
          />
        )}
        showSorterTooltip={false}
        rowKey={(record) => record.name}
        {...(width < breakpoint ? { scroll: { x: 1176 } } : {})}
      />
    </ConfigProvider>
  );
};

export default UsedPackageTable;
