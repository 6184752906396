import { Col, Divider } from "antd";

const convertNumber = (number = 0) => {
  if (typeof number !== "number") return "data-number-invalid";
  return number.toLocaleString("en-US");
};

const CardInvoiceSummary = ({ number = "0", title, status = "thisMonth", invoice = true }) => {
  let titleText = (
    <>
      {title === "Unpaid" && <p className="mb-0 text-warning">{title} Invoice</p>}
      {title === "Paid" && <p className="mb-0 text-success">{title} Invoice</p>}
      {title === "Cancelled" && <p className="mb-0 text-danger">{title} Invoice</p>}
      {title === "Total" && <p className="mb-0">{title} Invoice</p>}
    </>
  );

  return (
    <Col lg={12} md={12} sm={24} xl={6}>
      <div className="py-4 px-6 bg-white">
        <h5 className="mb-0 text-3xl">{invoice ? (status ? convertNumber(number) : 420) : 0}</h5>
        {titleText}
        <Divider className="my-2 mb-0" />
      </div>
    </Col>
  );
};

export default CardInvoiceSummary;
