import { Alert, Steps } from "antd";
import HeaderSection from "components/header/HeaderSection";
import { useState } from "react";
import "./verifikasi.less";
import VerifikasiEmail from "./VerifikasiEmail";
import VerifikasiSelesai from "./VerifikasiSelesai";
import VSSetupDomain from "./VSSetupDomain";

const VerifikasiUser = ({ user }) => {
  const setup =
    user.isActive && user.isSetupDomain ? 2 : user.isActive && !user.isSetupDomain ? 1 : 0;
  const [currentStep, setCurrentStep] = useState(setup);
  const [fillDomain, setFillDomain] = useState(false);
  const setStep = setCurrentStep;
  const next = () => {
    setStep(currentStep + 1);
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
  };

  const step = { currentStep, next, fillDomain, setFillDomain };

  const alertRender = currentStep === 0 && (
    <Alert
      className="w-full alert-data justify-center"
      message="Akun anda dalam proses verifikasi email. Lakukan verifikasi untuk melanjutkan registrasi layanan. "
      type="warning"
      showIcon
      closable
    />
  );

  const stepList = [
    {
      step: 1,
      title: "Verifikasi Email",
      content: <VerifikasiEmail {...step} />,
    },
    {
      step: 2,
      title: "Aktivasi Trial",
      content: <VSSetupDomain {...step} />,
    },
    {
      step: 3,
      title: "Aktivasi Selesai",
      content: <VerifikasiSelesai {...step} />,
    },
  ];

  return (
    <div className="verifikasi-user">
      <HeaderSection />
      <div className="mb-9">{alertRender}</div>
      <div className="box-step mx-auto">
        <Steps current={currentStep} className="py-3">
          {stepList.map((item) => (
            <Steps.Step key={item.title} title={item.title}>
              {item.content}
            </Steps.Step>
          ))}
        </Steps>
        <div className="steps-content">{stepList[currentStep].content}</div>
      </div>
    </div>
  );
};

export default VerifikasiUser;
