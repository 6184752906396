import { createSlice } from "@reduxjs/toolkit";

export const domainActive = createSlice({
  name: "domainActive",
  initialState: {
    value: false, // default state
  },
  reducers: {
    // reducers action
    setDomainActive: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setDomainActive } = domainActive.actions;
export default domainActive.reducer;
