import { Col, Row } from "antd";
import { useEffect } from "react";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import "./api.less";
import useDocumentTitle from "utils/useDocumentTitle";
import { setCheckoutChecker } from "redux/slices/checkoutChecker";
import { useDispatch, useSelector } from "react-redux";
import InformationAccountAPI from "./InformationAccountApi";
import SampleResponse from "./SampleResponse";
import useGetData from "./useGetData";

const EmailAPI = () => {
  useDocumentTitle("Email API");
  const dispatch = useDispatch();
  const isChecked = useSelector(({ checkoutChecker }) => checkoutChecker.value);
  useEffect(() => {
    if (isChecked.checked) dispatch(setCheckoutChecker({ ...isChecked, checked: false }));
  }, [dispatch, isChecked]);
  useGetData();
  return (
    <div className="email-api-page">
      <DashboardHeader title="Email API" />
      <div className="flex flex-col gap-y-6 px-6 py-4">
        <Row gutter={[24, 16]}>
          <Col xl={12} className="w-full">
            <InformationAccountAPI />
          </Col>
          <Col xl={12} className="w-full">
            <SampleResponse />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default EmailAPI;
