import { createSlice } from "@reduxjs/toolkit";

export const listAllCustomer = createSlice({
  name: "listAllCustomer",
  initialState: {
    value: false,
  },
  reducers: {
    setListAllCustomer: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setListAllCustomer } = listAllCustomer.actions;
export default listAllCustomer.reducer;
