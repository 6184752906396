import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import HeaderSection from "components/header/HeaderSection";

const ProcessingLayout = () => {
  const { state } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!state) navigate("/setup");
  }, [state, navigate]);

  return (
    state && (
      <>
        <HeaderSection />
        <div className="center">
          <div className="flex flex-col justify-center items-center process-domain__wrapper gap-y-4">
            <Outlet />
          </div>
        </div>
      </>
    )
  );
};

export default ProcessingLayout;
