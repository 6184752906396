import { createSlice } from "@reduxjs/toolkit";

export const packageList = createSlice({
  name: "packageList",
  initialState: {
    value: false,
  },
  reducers: {
    setPackageList: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setPackageList } = packageList.actions;
export default packageList.reducer;
