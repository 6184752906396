import { getDomain, getUser } from "api";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setListDomain } from "redux/slices/listDomain";
import { setUserData } from "redux/slices/user";

const useIntervalCheckDNS = (user) => {
  const dispatch = useDispatch();

  const getData = useCallback(async () => {
    const { data } = await getDomain();
    const { data: userData } = await getUser({ uuid: user.uuid });
    dispatch(setListDomain(data));
    dispatch(setUserData(userData));
  }, [dispatch, user.uuid]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!user.isAdmin) {
        getData();
      }
    }, 600000);

    return () => {
      clearInterval(interval);
    };
  }, [getData, user.isAdmin]);
};

export default useIntervalCheckDNS;
