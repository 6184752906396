import {
  Button,
  ConfigProvider,
  Table,
  Input,
  Select,
  Badge,
  Space,
  Skeleton,
  Tooltip,
} from "antd";
import { EmptySuppress, SearchNotFound, LoadFailed } from "components/empty/EmptyComponent";
import { ICDeleteOutlined, ICPlusOutlined } from "components/list/ListIcon";
import { DeleteSuppress, getHistoryList } from "api";
import { useEffect, useState, useCallback } from "react";
import ModalConfirmDeleteSuppress from "components/popups/modal/ModalConfirmDelete";
import ModalDelete from "components/popups/modal/ModalGenerate";
import "pages/dashboard/user/suppress/suppress.less";
import DetailDrawer from "../popups/drawer/DetailDrawer";
import { splitDate } from "utils/helpers";
import { setHistoryList } from "redux/slices/HistoryList";
import { useDispatch } from "react-redux";
import { TitleTableSuppressUser } from "components/tableSendiri/TitleTable";
const { Search } = Input;
const { Option } = Select;

const TableSuppress = ({
  dataSuppress,
  setFilter,
  filterSuppress,
  filterHistory,
  dataHistory,
  setShowSkeleton,
  showSkeleton,
  getDataSuppress,
}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(filterSuppress.page);
  const [currentLimit, setCurrentLimit] = useState(filterSuppress.limit);
  const breakpoint = 1200;
  const breakpoint_third = 768;

  const getCallbackHistory = useCallback(async () => {
    const { data, code } = await getHistoryList(filterHistory);
    dispatch(setHistoryList({ data, code }));
  }, [dispatch, filterHistory]);

  useEffect(() => {
    if (!dataHistory);
  }, [getCallbackHistory, dataHistory]);

  const changePage = (page) => {
    setCurrentPage(page);
    const updatedFilter = setFilter({
      ...filterSuppress,
      page,
    });
    currentPage(updatedFilter);
  };

  const changePageLimit = (value) => {
    setCurrentLimit(value);
    setCurrentPage(1);
    const updatedFilter = setFilter({
      ...filterSuppress,
      page: 1,
      limit: value,
    });
    currentLimit(updatedFilter);
  };

  const changeCategory = (category) => {
    setSelectedCategory(category);
    setCurrentPage(1);
    const updatedFilter = setFilter({
      ...filterSuppress,
      page: 1,
      type: category === "All" ? "" : category,
    });
    selectedCategory(updatedFilter);
  };

  const changeSearchQuery = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
    const updatedFilter = setFilter({
      ...filterSuppress,
      page: 1,
      email: query,
    });
    searchQuery(updatedFilter);
  };

  const PaginationTable = () => {
    const isDataAvailable = dataSuppress.data?.count > 0;
    const isDataNotFound = (searchQuery !== "" || selectedCategory !== "All") && !isDataAvailable;
    const isDataLoadFailed = dataSuppress.code === 500;

    useEffect(() => {
      const handleResizeWindow = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResizeWindow);
      return () => {
        window.removeEventListener("resize", handleResizeWindow);
      };
    }, []);

    const BtnAdd = ({ setOpen }) => {
      return {
        ...(width < breakpoint ? (
          <Tooltip title="Tambah" placement="top">
            <Button onClick={() => setOpen(true)} type="primary" className="btn-add">
              <ICPlusOutlined />
            </Button>
          </Tooltip>
        ) : (
          <Button
            icon={<ICPlusOutlined />}
            onClick={() => setOpen(true)}
            type="primary"
            className="btn-add">
            <span className="btn-text">Tambah</span>
          </Button>
        )),
      };
    };

    const BtnDelete = ({ rowData }) => {
      const handleDeleteSuppress = () => {
        ModalDelete({
          title: "Hapus Email dari Suppression List?",
          customMessage: (
            <div>
              <p className="mb-6">
                Apakah anda yakin ingin menghapus email <strong>{rowData.email}</strong> dari
                suppression list?
                <br /> <br /> Setelah dihapus, anda dapat mengirimkan email ke alamat email
                tersebut. Namun dapat berpotensi kembali masuk kedalam suppression list, apabila
                email tujuan tidak dapat dijangkau oleh sistem.
              </p>
            </div>
          ),
          iconType: "remove",
          okText: "Remove dari Suppression",
          cancelText: "Batalkan",
          onOk: async () => {
            await DeleteSuppress({
              email: rowData.email,
            });
            setTimeout(() => {
              ModalConfirmDeleteSuppress({
                title: "Suppression Berhasil Dihapus",
                customMessage: (
                  <div>
                    <p>
                      Email <strong>{rowData.email}</strong> sudah berhasil dihapus dari suppression
                      list
                    </p>
                  </div>
                ),
                onOk: () => {
                  setShowSkeleton(true);
                  setTimeout(async () => {
                    await getDataSuppress();
                    await getCallbackHistory();
                    setShowSkeleton(false);
                  }, 500);
                },
              });
            }, 1000);
          },
        });
      };
      return (
        <Tooltip title="Delete" placement="top">
          <Button
            danger
            icon={<ICDeleteOutlined />}
            className="w-12"
            onClick={handleDeleteSuppress}
          />
        </Tooltip>
      );
    };

    const columnSuppress = [
      {
        title: "Date",
        dataIndex: "timestamp",
        key: "Date",
        width: 120,
        render: (data) => {
          const { date, time } = splitDate(data);
          return (
            <div className="flex flex-col">
              <p className="mb-0">{date}</p>
              <p className="mb-0 text-secondary text-xs" type="secondary">
                {time}
              </p>
            </div>
          );
        },
      },
      {
        title: "Email Address",
        dataIndex: "email",
        key: "Email Address",
        ellipsis: true,
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "Category",
        width: 150,
        render: (category) => (
          <Badge
            color={
              category === "hard"
                ? "red"
                : category === "manual"
                ? "#FADB14"
                : category === "whitelist"
                ? "#0D9F3F"
                : "orange"
            }
            text={
              category === "hard"
                ? "Hard Bounce"
                : category === "manual"
                ? "Manual Added"
                : category === "whitelist"
                ? "Whitelist"
                : "Soft bounce"
            }
            style={{ fontWeight: 500 }}
          />
        ),
        style: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
      },
      {
        title: "Error Message",
        dataIndex: "message",
        key: "Error Message",
        ellipsis: true,
      },
      {
        dataIndex: "actions",
        key: "actions",
        fixed: "right",
        width: 160,
        render: (_, rowData) => (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <Space size="small">
              <Button
                type="link"
                onClick={() => {
                  setSelectedRowData(rowData);
                  setShowDrawer(true);
                }}>
                Detail
              </Button>
              <BtnDelete rowData={rowData} />
            </Space>
          </div>
        ),
      },
    ];

    const Category = ({ isDataNotAvailable, isDataNotFound }) => {
      return (
        <Select
          className="Category__selector"
          value={selectedCategory}
          onChange={changeCategory}
          defaultValue={"All"}
          disabled={isDataNotAvailable || isDataNotFound}>
          <Option value="All">Semua Kategori</Option>
          <Option value="hard">Hard Bounce</Option>
          <Option value="manual">Manual Added</Option>
          <Option value="soft">Soft Bounce</Option>
          <Option value="whitelist">Whitelist</Option>
        </Select>
      );
    };

    const SearchEmail = ({ onSearch, initialValue = "", isDataNotFound, isDataNotAvailable }) => {
      const [query, setQuery] = useState(initialValue);

      const handleSearch = () => {
        onSearch(query);
      };

      return (
        <Search
          placeholder="recipient@domain.tld"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onSearch={handleSearch}
          enterButton
          disabled={isDataNotAvailable || (isDataNotFound && query === "")}
        />
      );
    };

    const PageLimit = ({ isDataNotAvailable, isDataNotFound }) => {
      return (
        <Select
          className="pageLimit__selector"
          onChange={changePageLimit}
          value={currentLimit}
          disabled={isDataNotAvailable || (isDataNotFound && currentLimit === "10")}>
          <Option value="10">10 per page</Option>
          <Option value="25">25 per page</Option>
          <Option value="50">50 per page</Option>
          <Option value="100">100 per page</Option>
        </Select>
      );
    };

    return (
      <ConfigProvider
        renderEmpty={() => {
          if (
            (dataSuppress.data?.count === 0 && searchQuery !== "") ||
            selectedCategory !== "All"
          ) {
            return <SearchNotFound />;
          } else if (dataSuppress.data?.count === 0) {
            return <EmptySuppress />;
          } else if (dataSuppress.code === 500) {
            return <LoadFailed />;
          }
        }}>
        <div className="suppress-user mt-4">
          <div className="flex flex-col">
            <TitleTableSuppressUser
              isDataLoadFailed={isDataLoadFailed}
              isDataNotFound={isDataNotFound}
              isDataAvailable={isDataAvailable}
              Category={Category}
              SearchEmail={SearchEmail}
              PageLimit={PageLimit}
              BtnAdd={BtnAdd}
              changeSearchQuery={changeSearchQuery}
              searchQuery={searchQuery}
              setShowModal={setShowModal}
              showModal={showModal}
              filterSuppress={filterSuppress}
              dataHistory={dataHistory}
              filterHistory={filterHistory}
              dataSuppress={dataSuppress}
              setShowSkeleton={setShowSkeleton}
              breakpoint={breakpoint}
              breakpoint_third={breakpoint_third}
              width={width}
            />
            {showSkeleton ? (
              <Skeleton active />
            ) : (
              <Table
                {...(width < breakpoint ? { scroll: { x: 1024 } } : undefined)}
                columns={columnSuppress}
                dataSource={dataSuppress.data?.rows}
                style={{
                  overflowX: "auto",
                  maxWidth: "100%",
                }}
                pageLimit={{
                  currentLimit: filterSuppress.limit,
                  onSelectLimit: (limit) => {
                    setFilter({ ...filterSuppress, page: 1, limit });
                  },
                  disable: dataSuppress.data?.count < 10,
                }}
                pagination={{
                  pageSize: currentLimit,
                  current: currentPage,
                  total: dataSuppress.data?.count,
                  showSizeChanger: false,
                  size: "small",
                  onChange: changePage,
                }}
              />
            )}
            <DetailDrawer
              visible={showDrawer}
              onClose={() => setShowDrawer(false)}
              rowData={selectedRowData || {}}
            />
          </div>
        </div>
      </ConfigProvider>
    );
  };
  return <PaginationTable filter={filterSuppress} setFilter={setFilter} />;
};

export default TableSuppress;
