import { Button, Tooltip, Select, Input } from "antd";
import { ICUpOutlined, ICExportOutlined, ICFlagOutlined } from "components/list/ListIcon";
import ModalAddSuppress from "components/popups/modal/ModalAddSuppress";
import { CSVLink } from "react-csv";

export const TitleTableSuppressAdmin = ({
  breakpoint,
  width,
  click,
  BtnAdd,
  SearchReciever,
  PageLimit,
  CollapseFilter,
  setShowModal,
  showModal,
  setClick,
  ModalAddSuppressAdmin,
  breakpoint_third,
  isDataLoadFailed,
  isDataNotFound,
  isDataAvailable,
  changeSearchQuery,
  searchQuery,
  dataSuppressAdmin,
  ListCompany,
  setShowSkeletonSuppress,
  getDataSuppress,
  getDataHistory,
  selectedCategory,
  handleAdvanceFilter,
  changeCategory,
  changeCompany,
  company,
  handleSearch,
  disabledDate,
  searchValue,
  onChangeDatetime,
  filteredCompanies,
}) => {
  const headerCSV = [
    { label: "Date", key: "timestamp" },
    { label: "Owner", key: "company" },
    { label: "Email", key: "email" },
    { label: "Category", key: "category" },
    { label: "Sender Domain", key: "sender_domain" },
    { label: "Host Server", key: "mx_host" },
    { label: "Message ID", key: "message_id" },
    { label: "Error Message", key: "message" },
  ];
  const dataCSV = dataSuppressAdmin.data?.rows;
  const ButtonExport = () => {
    const ButtonComponent = () => {
      if (!dataCSV)
        return (
          <Button type="primary" disabled={true}>
            {width <= breakpoint ? (
              <ICExportOutlined />
            ) : (
              <span>
                <ICExportOutlined style={{ marginRight: 8 }} />
                Export To CSV
              </span>
            )}
          </Button>
        );
      return (
        <CSVLink data={dataCSV} headers={headerCSV} filename={"History Suppression List Admin"}>
          <Button
            type="primary"
            {...(isDataLoadFailed
              ? { disabled: isDataLoadFailed }
              : isDataNotFound
              ? { disabled: true }
              : isDataAvailable
              ? { disabled: false }
              : { disabled: true })}>
            {width <= breakpoint ? (
              <ICExportOutlined />
            ) : (
              <span>
                <ICExportOutlined style={{ marginRight: 8 }} />
                Export To CSV
              </span>
            )}
          </Button>
        </CSVLink>
      );
    };

    return {
      ...(width <= breakpoint ? (
        <Tooltip title="Export To CSV">
          <ButtonComponent />
        </Tooltip>
      ) : (
        <ButtonComponent />
      )),
    };
  };

  return {
    ...(width <= breakpoint && width >= breakpoint_third ? (
      <div className={`tableTitleAccordion__wrapper ${click ? "tableTitleAccordion__open" : ""}`}>
        <div className="suppress-wrapper">
          <div className="flex justify-between items-center">
            <div className="title-text">
              <h5 className="text-base font-medium mb-1">Suppression List</h5>
              <p className="text-secondary text-sm font-normal mb-0">
                Daftar alamat email yang gagal dikirimkan sebelumnya
              </p>
            </div>
            <div className="btn-group-suppress items-center gap-x-2">
              <div className="flex justify-end mb-2 gap-x-2">
                <PageLimit />
                <div className="flex gap-x-1">
                  <BtnAdd setOpen={setShowModal} />
                  <ButtonExport />
                </div>
              </div>
              <div className="flex items-center text-success filter-text pointer">
                <span onClick={() => setClick((prev) => !prev)}>Filter</span>
                <ICUpOutlined className="ml-1 mr-2" rotate={click ? 0 : 180} />
                <SearchReciever onSearch={changeSearchQuery} initialValue={searchQuery} />
              </div>
              <ModalAddSuppressAdmin
                isOpen={showModal}
                setOpen={setShowModal}
                ListCompany={ListCompany}
                setShowSkeletonSuppress={setShowSkeletonSuppress}
                getCallbackSuppress={getDataSuppress}
                getCallbackHistory={getDataHistory}
              />
            </div>
          </div>
        </div>
        <div className="tableTitleAccordion__content" style={{ marginTop: 0 }}>
          <CollapseFilter
            isDataAvailable={isDataAvailable}
            isDataLoadFailed={isDataLoadFailed}
            isDataNotFound={isDataNotFound}
            selectedCategory={selectedCategory}
            handleAdvanceFilter={handleAdvanceFilter}
            changeCategory={changeCategory}
            ListCompany={ListCompany}
            changeCompany={changeCompany}
            company={company}
            handleSearch={handleSearch}
            disabledDate={disabledDate}
            searchValue={searchValue}
            onChangeDatetime={onChangeDatetime}
            filteredCompanies={filteredCompanies}
          />
        </div>
      </div>
    ) : width <= breakpoint_third ? (
      <div className={`tableTitleAccordion__wrapper ${click ? "tableTitleAccordion__open" : ""}`}>
        <div className="suppress-wrapper">
          <div className="title-text">
            <h5 className="text-base font-medium mb-1">Suppression List</h5>
            <p className="text-secondary text-sm font-normal mb-0">
              Daftar alamat email yang gagal dikirimkan sebelumnya
            </p>
          </div>
          <div className="flex justify-end" style={{ marginTop: 10 }}>
            <div className="btn-group-suppress items-center gap-x-2">
              <div className="flex justify-end mb-2 gap-x-2">
                <PageLimit />
                <div className="flex gap-x-1">
                  <BtnAdd setOpen={setShowModal} />
                  <ButtonExport />
                </div>
              </div>
              <div
                className="flex items-center text-success filter-text pointer"
                onClick={() => setClick((prev) => !prev)}>
                <span>Filter</span>
                <ICUpOutlined className="ml-1 mr-2" rotate={click ? 0 : 180} />
                <SearchReciever onSearch={changeSearchQuery} initialValue={searchQuery} />
              </div>
              <ModalAddSuppressAdmin
                isOpen={showModal}
                setOpen={setShowModal}
                ListCompany={ListCompany}
                setShowSkeletonSuppress={setShowSkeletonSuppress}
                getCallbackSuppress={getDataSuppress}
                getCallbackHistory={getDataHistory}
              />
            </div>
          </div>
        </div>
        <div className="tableTitleAccordion__content" style={{ marginTop: 0 }}>
          <CollapseFilter
            isDataAvailable={isDataAvailable}
            isDataLoadFailed={isDataLoadFailed}
            isDataNotFound={isDataNotFound}
            selectedCategory={selectedCategory}
            handleAdvanceFilter={handleAdvanceFilter}
            changeCategory={changeCategory}
            ListCompany={ListCompany}
            changeCompany={changeCompany}
            company={company}
            handleSearch={handleSearch}
            disabledDate={disabledDate}
            searchValue={searchValue}
            filteredCompanies={filteredCompanies}
          />
        </div>
      </div>
    ) : (
      <div className={`tableTitleAccordion__wrapper ${click ? "tableTitleAccordion__open" : ""}`}>
        <div className="suppress-wrapper">
          <div className="flex justify-between items-center">
            <div className="title-text">
              <h5 className="text-base font-medium mb-1">Suppression List</h5>
              <p className="text-secondary text-sm font-normal mb-0">
                Daftar alamat email yang gagal dikirimkan sebelumnya
              </p>
            </div>
            <div className="btn-group-suppress flex items-center gap-x-2">
              <div
                className="flex items-center text-success filter-text pointer"
                onClick={() => setClick((prev) => !prev)}>
                <span>Filter</span>
                <ICUpOutlined className="ml-1" rotate={click ? 0 : 180} />
              </div>
              <SearchReciever onSearch={changeSearchQuery} initialValue={searchQuery} />
              <PageLimit />
              <div className="flex gap-x-1">
                <BtnAdd setOpen={setShowModal} />
                <ButtonExport />
              </div>
              <ModalAddSuppressAdmin
                isOpen={showModal}
                setOpen={setShowModal}
                ListCompany={ListCompany}
                setShowSkeletonSuppress={setShowSkeletonSuppress}
                getCallbackSuppress={getDataSuppress}
                getCallbackHistory={getDataHistory}
              />
            </div>
          </div>
        </div>
        <div className="tableTitleAccordion__content" style={{ marginTop: 0 }}>
          <CollapseFilter
            isDataAvailable={isDataAvailable}
            isDataLoadFailed={isDataLoadFailed}
            isDataNotFound={isDataNotFound}
            selectedCategory={selectedCategory}
            handleAdvanceFilter={handleAdvanceFilter}
            changeCategory={changeCategory}
            ListCompany={ListCompany}
            changeCompany={changeCompany}
            company={company}
            handleSearch={handleSearch}
            disabledDate={disabledDate}
            searchValue={searchValue}
            filteredCompanies={filteredCompanies}
          />
        </div>
      </div>
    )),
  };
};

export const TitleTableHistoryAdmin = ({
  widthHistory,
  SearchEmail,
  PageLimitHistory,
  breakpoint_third_history,
  breakpoint_history,
  History_data,
  changeSearchQuery,
  searchQuery,
  changeSearchCompany,
  isDataLoadFailed,
  isDataNotFound,
  isDataAvailable,
  searchValue,
  filteredCompanies,
  handleSearch,
  ListCompany,
  selectedCompany,
}) => {
  const headerCSV = [
    { label: "Date", key: "timestamp" },
    { label: "Owner", key: "company" },
    { label: "Email", key: "email" },
    { label: "Category", key: "actios" },
    { label: "Description", key: "description" },
  ];
  const dataCSV = History_data.data?.rows;
  const ButtonExport = () => {
    const ButtonComponent = () => {
      if (!dataCSV)
        return (
          <Button type="primary" disabled={true}>
            {widthHistory <= breakpoint_history ? (
              <ICExportOutlined />
            ) : (
              <span>
                <ICExportOutlined style={{ marginRight: 8 }} />
                Export To CSV
              </span>
            )}
          </Button>
        );
      return (
        <CSVLink data={dataCSV} headers={headerCSV} filename={"History Suppression List Admin"}>
          <Button
            type="primary"
            {...(isDataLoadFailed
              ? { disabled: isDataLoadFailed }
              : isDataNotFound
              ? { disabled: true }
              : isDataAvailable
              ? { disabled: false }
              : { disabled: true })}>
            {widthHistory <= breakpoint_history ? (
              <ICExportOutlined />
            ) : (
              <span>
                <ICExportOutlined style={{ marginRight: 8 }} />
                Export To CSV
              </span>
            )}
          </Button>
        </CSVLink>
      );
    };
    return {
      ...(widthHistory <= breakpoint_history ? (
        <Tooltip title="Export To CSV">
          <ButtonComponent />
        </Tooltip>
      ) : (
        <ButtonComponent />
      )),
    };
  };

  return {
    ...(widthHistory <= breakpoint_history && widthHistory >= breakpoint_third_history ? (
      <div className="suppress-wrapper">
        <div className="flex justify-between items-center">
          <div className="title-text">
            <h5 className="text-base font-medium mb-1">History Aktivitas</h5>
            <p className="text-secondary text-sm font-normal mb-0">
              History aktivitas pengguna pada list suppression yang ada
            </p>
          </div>
          <div className="btn-group-suppress items-center gap-x-2">
            <div className="flex justify-end mb-2 gap-x-2">
              <Select
                onClick={(e) => e.stopPropagation()}
                onSelect={changeSearchCompany}
                value={selectedCompany}
                defaultValue={""}
                {...(isDataLoadFailed
                  ? { disabled: isDataLoadFailed }
                  : isDataNotFound
                  ? { disabled: false }
                  : isDataAvailable
                  ? { disabled: false }
                  : { disabled: true })}
                dropdownRender={(menu) => (
                  <>
                    <Input
                      placeholder="Ketik perusahaan..."
                      onChange={(e) => handleSearch(e.target.value)}
                      value={searchValue}
                    />
                    {menu}
                  </>
                )}>
                <Select.Option value="">
                  <span>Semua Perusahaan</span>
                </Select.Option>
                <Select.Option value="global">
                  <span>
                    <ICFlagOutlined style={{ marginRight: 4 }} />
                    Global Suppression
                  </span>
                </Select.Option>
                {filteredCompanies.length > 0
                  ? filteredCompanies.map(({ company_name, company_uuid }, index) => (
                      <Select.Option key={company_uuid + index} value={company_uuid}>
                        {company_name}
                      </Select.Option>
                    ))
                  : ListCompany.length > 0
                  ? ListCompany.map(({ company_name, company_uuid }, index) => (
                      <Select.Option key={company_uuid + index} value={company_uuid}>
                        {company_name}
                      </Select.Option>
                    ))
                  : undefined}
              </Select>
              <SearchEmail onSearch={changeSearchQuery} initialValue={searchQuery} />
            </div>
            <div className="flex justify-end mb-2 gap-x-2">
              <PageLimitHistory />
              <ButtonExport />
            </div>
          </div>
        </div>
      </div>
    ) : widthHistory <= breakpoint_third_history ? (
      <div className="suppress-wrapper">
        <div className="title-text">
          <h5 className="text-base font-medium mb-1">History Aktivitas</h5>
          <p className="text-secondary text-sm font-normal mb-0">
            History aktivitas pengguna pada list suppression yang ada
          </p>
        </div>
        <div className="flex justify-end items-center" style={{ marginTop: 10 }}>
          <div className="btn-group-suppress items-center gap-x-2">
            <div className="flex justify-end mb-2 gap-x-2">
              <Select
                onClick={(e) => e.stopPropagation()}
                onSelect={changeSearchCompany}
                value={selectedCompany}
                defaultValue={""}
                {...(isDataLoadFailed
                  ? { disabled: isDataLoadFailed }
                  : isDataNotFound
                  ? { disabled: false }
                  : isDataAvailable
                  ? { disabled: false }
                  : { disabled: true })}
                dropdownRender={(menu) => (
                  <>
                    <Input
                      placeholder="Ketik perusahaan..."
                      onChange={(e) => handleSearch(e.target.value)}
                      value={searchValue}
                    />
                    {menu}
                  </>
                )}>
                <Select.Option value="">
                  <span>Semua Perusahaan</span>
                </Select.Option>
                <Select.Option value="global">
                  <span>
                    <ICFlagOutlined style={{ marginRight: 4 }} />
                    Global Suppression
                  </span>
                </Select.Option>
                {filteredCompanies.length > 0
                  ? filteredCompanies.map(({ company_name, company_uuid }, index) => (
                      <Select.Option key={company_uuid + index} value={company_uuid}>
                        {company_name}
                      </Select.Option>
                    ))
                  : ListCompany.length > 0
                  ? ListCompany.map(({ company_name, company_uuid }, index) => (
                      <Select.Option key={company_uuid + index} value={company_uuid}>
                        {company_name}
                      </Select.Option>
                    ))
                  : undefined}
              </Select>
              <SearchEmail onSearch={changeSearchQuery} initialValue={searchQuery} />
            </div>
            <div className="flex justify-end mb-2 gap-x-2">
              <PageLimitHistory />
              <ButtonExport />
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="suppress-wrapper">
        <div className="flex justify-between items-center">
          <div className="title-text">
            <h5 className="text-base font-medium mb-1">History Aktivitas</h5>
            <p className="text-secondary text-sm font-normal mb-0">
              History aktivitas pengguna pada list suppression yang ada
            </p>
          </div>
          <div className="btn-group-log">
            <div className="flex gap-x-2">
              <Select
                onClick={(e) => e.stopPropagation()}
                onSelect={changeSearchCompany}
                value={selectedCompany}
                defaultValue={""}
                {...(isDataLoadFailed
                  ? { disabled: isDataLoadFailed }
                  : isDataNotFound
                  ? { disabled: false }
                  : isDataAvailable
                  ? { disabled: false }
                  : { disabled: true })}
                dropdownRender={(menu) => (
                  <>
                    <Input
                      placeholder="Ketik perusahaan..."
                      onChange={(e) => handleSearch(e.target.value)}
                      value={searchValue}
                    />
                    {menu}
                  </>
                )}>
                <Select.Option value="">
                  <span>Semua Perusahaan</span>
                </Select.Option>
                <Select.Option value="global">
                  <span>
                    <ICFlagOutlined style={{ marginRight: 4 }} />
                    Global Suppression
                  </span>
                </Select.Option>
                {filteredCompanies.length > 0
                  ? filteredCompanies.map(({ company_name, company_uuid }, index) => (
                      <Select.Option key={company_uuid + index} value={company_uuid}>
                        {company_name}
                      </Select.Option>
                    ))
                  : ListCompany.length > 0
                  ? ListCompany.map(({ company_name, company_uuid }, index) => (
                      <Select.Option key={company_uuid + index} value={company_uuid}>
                        {company_name}
                      </Select.Option>
                    ))
                  : undefined}
              </Select>
              <SearchEmail onSearch={changeSearchQuery} initialValue={searchQuery} />
              <PageLimitHistory />
              <ButtonExport />
            </div>
          </div>
        </div>
      </div>
    )),
  };
};

export const TitleTableSuppressUser = ({
  isDataLoadFailed,
  isDataNotFound,
  isDataAvailable,
  Category,
  SearchEmail,
  PageLimit,
  BtnAdd,
  changeSearchQuery,
  searchQuery,
  setShowModal,
  showModal,
  filterSuppress,
  dataSuppress,
  dataHistory,
  filterHistory,
  setShowSkeleton,
  breakpoint,
  breakpoint_third,
  width,
}) => {
  return {
    ...(width < breakpoint && width > breakpoint_third ? (
      <div className="mb-2">
        <div className="flex justify-between items-center">
          <div className="title-text">
            <h5 className="text-base font-medium mb-1">Suppression List</h5>
            <p className="text-secondary text-sm font-normal mb-0">
              Daftar alamat email yang gagal dikirimkan sebelumnya
            </p>
          </div>
          <div className="btn-group-suppress items-center gap-x-2">
            {isDataLoadFailed ? (
              <div className="flex justify-end mb-2 gap-x-2">
                <Category isDataNotAvailable={isDataLoadFailed} />
                <SearchEmail
                  onSearch={changeSearchQuery}
                  initialValue={searchQuery}
                  isDataNotAvailable={isDataLoadFailed}
                />
                <div className="flex gap-x-1">
                  <PageLimit isDataNotAvailable={isDataLoadFailed} />
                  <Tooltip title="Tambah" placement="top">
                    <BtnAdd setOpen={setShowModal} isDataNotAvailable={isDataLoadFailed} />
                  </Tooltip>
                </div>
              </div>
            ) : isDataNotFound ? (
              <div>
                <div className="flex justify-end mb-2 gap-x-2">
                  <Category />
                  <SearchEmail onSearch={changeSearchQuery} initialValue={searchQuery} />
                </div>
                <div className="flex justify-end mb-2 gap-x-2">
                  <PageLimit
                    isDataNotAvailable={!isDataAvailable || isDataLoadFailed}
                    isDataNotFound={isDataNotFound}
                  />
                  <Tooltip title="Tambah" placement="top">
                    <BtnAdd setOpen={setShowModal} />
                  </Tooltip>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex justify-end mb-2 gap-x-2">
                  <Category isDataNotAvailable={!isDataAvailable} />
                  <SearchEmail
                    onSearch={changeSearchQuery}
                    initialValue={searchQuery}
                    isDataNotAvailable={!isDataAvailable}
                  />
                </div>
                <div className="flex justify-end mb-2 gap-x-2">
                  <PageLimit isDataNotAvailable={!isDataAvailable} />
                  <Tooltip title="Tambah" placement="top">
                    <BtnAdd setOpen={setShowModal} />
                  </Tooltip>
                </div>
              </div>
            )}
            <ModalAddSuppress
              isOpen={showModal}
              setOpen={setShowModal}
              filterSuppress={filterSuppress}
              dataHistory={dataHistory}
              filterHistory={filterHistory}
              setShowSkeleton={setShowSkeleton}
              dataSuppress={dataSuppress}
            />
          </div>
        </div>
      </div>
    ) : width <= breakpoint_third ? (
      <div className="mb-2">
        <div className="title-text mb-1">
          <h5 className="text-base font-medium mb-1">Suppression List</h5>
          <p className="text-secondary text-sm font-normal mb-0">
            Daftar alamat email yang gagal dikirimkan sebelumnya
          </p>
        </div>
        <div className="flex justify-end items-center">
          <div className="btn-group-suppress items-center gap-x-2">
            {isDataLoadFailed ? (
              <div className="flex justify-end mb-2 gap-x-2">
                <Category isDataNotAvailable={isDataLoadFailed} />
                <SearchEmail
                  onSearch={changeSearchQuery}
                  initialValue={searchQuery}
                  isDataNotAvailable={isDataLoadFailed}
                />
                <div className="flex gap-x-1">
                  <PageLimit isDataNotAvailable={isDataLoadFailed} />
                  <Tooltip title="Tambah" placement="top">
                    <BtnAdd setOpen={setShowModal} isDataNotAvailable={isDataLoadFailed} />
                  </Tooltip>
                </div>
              </div>
            ) : isDataNotFound ? (
              <div>
                <div className="flex justify-end mb-2 gap-x-2">
                  <Category />
                  <SearchEmail onSearch={changeSearchQuery} initialValue={searchQuery} />
                </div>
                <div className="flex justify-end mb-2 gap-x-2">
                  <PageLimit
                    isDataNotAvailable={!isDataAvailable || isDataLoadFailed}
                    isDataNotFound={isDataNotFound}
                  />
                  <Tooltip title="Tambah" placement="top">
                    <BtnAdd setOpen={setShowModal} />
                  </Tooltip>
                </div>
              </div>
            ) : (
              <div>
                <div className="flex justify-end mb-2 gap-x-2">
                  <Category isDataNotAvailable={!isDataAvailable} />
                  <SearchEmail
                    onSearch={changeSearchQuery}
                    initialValue={searchQuery}
                    isDataNotAvailable={!isDataAvailable}
                  />
                </div>
                <div className="flex justify-end mb-2 gap-x-2">
                  <PageLimit isDataNotAvailable={!isDataAvailable} />
                  <Tooltip title="Tambah" placement="top">
                    <BtnAdd setOpen={setShowModal} />
                  </Tooltip>
                </div>
              </div>
            )}
            <ModalAddSuppress
              isOpen={showModal}
              setOpen={setShowModal}
              filterSuppress={filterSuppress}
              dataHistory={dataHistory}
              filterHistory={filterHistory}
              setShowSkeleton={setShowSkeleton}
              dataSuppress={dataSuppress}
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="mb-2">
        <div className="flex justify-between items-center">
          <div className="title-text">
            <h5 className="text-base font-medium mb-1">Suppression List</h5>
            <p className="text-secondary text-sm font-normal mb-0">
              Daftar alamat email yang gagal dikirimkan sebelumnya
            </p>
          </div>
          <div className="btn-group-suppress items-center gap-x-2">
            {isDataLoadFailed ? (
              <div className="flex gap-x-2">
                <Category isDataNotAvailable={isDataLoadFailed} />
                <SearchEmail
                  onSearch={changeSearchQuery}
                  initialValue={searchQuery}
                  isDataNotAvailable={isDataLoadFailed}
                />
                <PageLimit isDataNotAvailable={isDataLoadFailed} />
                <BtnAdd setOpen={setShowModal} isDataNotAvailable={isDataLoadFailed} />
                <ModalAddSuppress
                  isOpen={showModal}
                  setOpen={setShowModal}
                  filterSuppress={filterSuppress}
                  dataHistory={dataHistory}
                  filterHistory={filterHistory}
                  setShowSkeleton={setShowSkeleton}
                  dataSuppress={dataSuppress}
                />
              </div>
            ) : isDataNotFound ? (
              <div className="flex gap-x-2">
                <Category />
                <SearchEmail onSearch={changeSearchQuery} initialValue={searchQuery} />
                <PageLimit
                  isDataNotAvailable={!isDataAvailable || isDataLoadFailed}
                  isDataNotFound={isDataNotFound}
                />
                <BtnAdd setOpen={setShowModal} />
                <ModalAddSuppress
                  isOpen={showModal}
                  setOpen={setShowModal}
                  filterSuppress={filterSuppress}
                  dataHistory={dataHistory}
                  filterHistory={filterHistory}
                  setShowSkeleton={setShowSkeleton}
                  dataSuppress={dataSuppress}
                />
              </div>
            ) : (
              <div className="flex gap-x-2">
                <Category isDataNotAvailable={!isDataAvailable} />
                <SearchEmail
                  onSearch={changeSearchQuery}
                  initialValue={searchQuery}
                  isDataNotAvailable={!isDataAvailable}
                />
                <PageLimit isDataNotAvailable={!isDataAvailable} />
                <BtnAdd setOpen={setShowModal} />
                <ModalAddSuppress
                  isOpen={showModal}
                  setOpen={setShowModal}
                  filterSuppress={filterSuppress}
                  dataHistory={dataHistory}
                  filterHistory={filterHistory}
                  setShowSkeleton={setShowSkeleton}
                  dataSuppress={dataSuppress}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )),
  };
};
