import { Button, Card, ConfigProvider, Table, Tag, Tooltip } from "antd";
import { EmptyWrapper } from "components/empty/EmptyComponent";
import {
  ICDeleteOutlined,
  ICEditOutlined,
  ICFilePdfOutlined,
  ICDownloadOutlined,
} from "components/list/ListIcon";
import TableTitle from "components/tableSendiri/TableTitle";
import { useEffect, useState } from "react";
import { openInNewTab } from "utils/helpers";

const TableFaktur = ({
  role,
  setVisible,
  handleDeleteFP,
  title,
  data,
  filter,
  setFilter,
  emptyState,
  emptyComponent: EmptyComponent,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1200;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const changePage = (pagesa) => {
    setFilter({ ...filter, page: pagesa });
  };

  const fakturColumnUser = [
    {
      title: "Date",
      dataIndex: "publishDate",
      key: "publishDate",
      width: width > breakpoint ? 120 : 120,
    },
    {
      title: "Nomor Seri Faktur",
      dataIndex: "serialNumberTaxInvoice",
      key: "serialNumberTaxInvoice",
      width: 220,
      render: (values) => (
        <span>{values.replace(/(\d{3})(\d{3})(\d{2})(\d{8})/, "$1.$2-$3.$4")}</span>
      ),
    },
    {
      title: "Nomor Invoice",
      dataIndex: "serialNumberInvoice",
      width: 240,
      key: "serialNumberInvoice",
    },
    {
      title: "PPN",
      dataIndex: "price",
      key: "price",
      align: "right",
      render: (price) => <span>{price?.toLocaleString("en-US")}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (item) => (item ? <Tag color="blue">Terbit</Tag> : <Tag color="orange">Pending</Tag>),
      align: "left",
    },
    {
      dataIndex: "urlTaxInvoice",
      key: "urlTaxInvoice",
      width: 60,
      render: (values) => (
        <Tooltip title="Download">
          <Button
            onClick={() => openInNewTab(`${values}`)}
            disabled={values === null}
            icon={<ICDownloadOutlined />}
            type="link"
            className="blue-6"
          />
        </Tooltip>
      ),
      align: "center",
    },
  ];

  const fakturColumnAdmin = [
    {
      title: "Date",
      dataIndex: "tax_date",
      key: "tax_date",
      width: 120,
    },
    {
      title: "Nomor Seri Faktur",
      dataIndex: "tax_number",
      key: "tax_number",
      render: (values) => (
        <span>{values.replace(/(\d{3})(\d{3})(\d{2})(\d{8})/, "$1.$2-$3.$4")}</span>
      ),
      ...(width < breakpoint ? { width: 160 } : {}),
    },
    {
      title: "Nama Perusahaan",
      dataIndex: "company_name",
      key: "company_name",
      width: width > breakpoint ? 300 : 240,
      render: (value) => {
        if (value.includes("rm::"))
          return (
            <div className="flex items-center gap-2">
              <p className="text-secondary mb-0">{value.slice(4)}</p>
              <Tag color="default" className="text-secondary mr-0">
                Deleted
              </Tag>
            </div>
          );
        return <p className="mb-0">{value}</p>;
      },
    },
    {
      title: "Nomor Invoice",
      dataIndex: "inv_number",
      key: "inv_number",
      ...(width < breakpoint ? { width: 180 } : {}),
    },
    {
      title: "Nominal PPN",
      dataIndex: "tax_price",
      key: "tax_price",
      align: "right",
      ...(width < breakpoint ? { width: 180 } : {}),
    },
    {
      title: "File PDF",
      dataIndex: "tax_url",
      key: "tax_url",
      align: "left",
      render: (values) => (
        <Button
          onClick={() => window.open(`/npwp-image?url=${values}`, "_blank")}
          icon={<ICFilePdfOutlined />}
          type="link"
          className="blue-6 px-0">
          Lihat
        </Button>
      ),
    },
    {
      dataIndex: "button",
      key: "button",
      render: (_, record) => (
        <div className="flex items-center gap-x-1">
          <Tooltip title="Update Faktur Pajak">
            <Button
              icon={<ICEditOutlined />}
              className="w-12"
              onClick={() => setVisible({ record, mode: "edit" })}
            />
          </Tooltip>
          <Tooltip title="Hapus Faktur Pajak">
            <Button
              danger
              icon={<ICDeleteOutlined />}
              className="w-12"
              onClick={() => handleDeleteFP(record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const fakturColumnAccounting = [
    {
      title: "Date ",
      dataIndex: "tax_date",
      key: "tax_date",
      width: 160,
    },
    {
      title: "Invoice Number",
      dataIndex: "inv_number",
      key: "inv_number",
      width: 200,
      render: (values) => <p className="text-info mb-0">{values}</p>,
    },
    {
      title: "Nomor Seri Faktur",
      dataIndex: "tax_number",
      key: "tax_number",
      width: 200,
      render: (_, record) => {
        const navTaxInvoice = () => window.open(`/npwp-image?url=${record.tax_url}`, "_blank");
        return (
          <p className="mb-0 text-info pointer" onClick={navTaxInvoice}>
            <span>{record.tax_number.replace(/(\d{3})(\d{3})(\d{2})(\d{8})/, "$1.$2-$3.$4")}</span>
          </p>
        );
      },
    },
    {
      title: "Nominal PPN",
      dataIndex: "tax_price",
      key: "tax_price",
      width: 176,
      align: "right",
    },
    {
      dataIndex: "button",
      key: "button",
      width: 128,
      render: (_, record) => (
        <div className="flex items-center gap-x-1">
          <Tooltip title="Update Faktur Pajak">
            <Button
              icon={<ICEditOutlined />}
              className="w-12"
              onClick={() => setVisible({ record, mode: "edit" })}
            />
          </Tooltip>
          <Tooltip title="Hapus Faktur Pajak">
            <Button
              danger
              icon={<ICDeleteOutlined />}
              className="w-12"
              onClick={() => handleDeleteFP(record)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const renderFakturColumn =
    role === "user"
      ? fakturColumnUser
      : role === "admin"
      ? fakturColumnAdmin
      : fakturColumnAccounting;

  if (!data.table) return <Card bordered={false} loading={data.loading} className="p-0 m-0"></Card>;

  return (
    <ConfigProvider
      renderEmpty={() => (role === "user" ? EmptyComponent : <EmptyWrapper {...emptyState} />)}>
      <Table
        dataSource={data.table.rows}
        columns={renderFakturColumn}
        title={() => <TableTitle {...title} />}
        loading={data.loading}
        pagination={{
          pageSize: filter.limit,
          current: filter.page,
          hideOnSinglePage: true,
          onChange: (params) => {
            changePage(params);
          },
          size: "small",
          total: data.table.count,
          showSizeChanger: false,
        }}
        rowKey={(record) => (role === "accounting" ? record.tax_uuid : record.serialNumberInvoice)}
        {...(width < breakpoint ? { scroll: { x: 1097 } } : {})}
      />
    </ConfigProvider>
  );
};

export default TableFaktur;
