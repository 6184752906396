import { ConfigProvider, Table, Input, Select, Badge, Skeleton } from "antd";
import { EmptySuppress, SearchNotFound, LoadFailed } from "components/empty/EmptyComponent";
import { useEffect, useState } from "react";

import "pages/dashboard/user/suppress/suppress.less";
import { splitDate } from "utils/helpers";

const { Option } = Select;
const { Search } = Input;

const TableHistory = ({ dataHistory, filterHistory, setFilter, showSkeleton, setShowSkeleton }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(filterHistory.page);
  const [currentLimit, setCurrentLimit] = useState(filterHistory.limit);

  const breakpoint = 1200;
  const breakpoint_third = 768;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const changePage = (page) => {
    setCurrentPage(page);
    const updatedFilter = setFilter({
      ...filterHistory,
      page,
    });
    currentPage(updatedFilter);
  };

  const changePageLimit = (value) => {
    setCurrentLimit(value);
    setCurrentPage(1);
    const updatedFilter = setFilter({
      ...filterHistory,
      page: 1,
      limit: value,
    });
    currentLimit(updatedFilter);
  };

  const changeSearchQuery = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
    const updatedFilter = setFilter({
      ...filterHistory,
      page: 1,
      email: query,
    });
    searchQuery(updatedFilter);
  };

  const columnHistory = [
    {
      title: "Date",
      dataIndex: "timestamp",
      key: "Date",
      width: width < breakpoint ? 120 : undefined,
      render: (data) => {
        const { date, time } = splitDate(data);
        return (
          <div className="flex flex-col">
            <p className="mb-0">{date}</p>
            <p className="mb-0 text-secondary text-xs" type="secondary">
              {time}
            </p>
          </div>
        );
      },
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "Email Address",
      ellipsis: true,
      width: width < breakpoint ? "20%" : undefined,
    },
    {
      title: "Category",
      dataIndex: "actios",
      key: "Category",
      className: "category-column",
      width: width < breakpoint ? 150 : undefined,
      render: (category) => (
        <Badge
          color={
            category === "Deletion" ? "#FF4D4F" : category === "Whitelist" ? "#0D9F3F" : "#FADB14"
          }
          text={
            category === "Deletion"
              ? "Deletion"
              : category === "Manual Added"
              ? "Manual Added"
              : category === "Whitelist"
              ? "Whitelist"
              : ""
          }
        />
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "Description",
      width: width < breakpoint ? "25%" : undefined,
    },
  ];

  const SearchEmail = ({ onSearch, initialValue = "", isDataNotFound, isDataNotAvailable }) => {
    const [query, setQuery] = useState(initialValue);

    const handleSearch = () => {
      setShowSkeleton(true);
      onSearch(query);
    };

    return (
      <Search
        placeholder="recipient@domain.tld"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onSearch={handleSearch}
        enterButton
        disabled={isDataNotAvailable || (isDataNotFound && query === "")}
      />
    );
  };

  const PageLimit = ({ isDataNotAvailable, isDataNotFound }) => {
    return (
      <Select
        className="pageLimit__selector"
        onChange={changePageLimit}
        value={currentLimit}
        disabled={isDataNotAvailable || (isDataNotFound && currentLimit === "10")}>
        <Option value="10">10 per page</Option>
        <Option value="25">25 per page</Option>
        <Option value="50">50 per page</Option>
        <Option value="100">100 per page</Option>
      </Select>
    );
  };

  const TitleTable = () => {
    const isDataAvailable = dataHistory.data?.count > 0;
    const isDataNotFound = searchQuery !== "" && !isDataAvailable;
    const isDataLoadFailed = dataHistory.code === 500;
    return {
      ...(width <= breakpoint_third ? (
        <div className="mb-2">
          <div className="title-text">
            <h5 className="text-base font-medium mb-1">History Aktivitas</h5>
            <p className="text-secondary text-sm font-normal mb-2">
              History aktivitas pengguna pada list suppression yang ada
            </p>
          </div>
          <div className="flex justify-end items-center">
            <div className="btn-group-suppress items-center gap-x-2">
              {isDataLoadFailed ? (
                <div className="flex justify-end mb-2 gap-x-2">
                  <SearchEmail
                    onSearch={changeSearchQuery}
                    initialValue={searchQuery}
                    setShowSkeleton={true}
                    isDataNotAvailable={isDataLoadFailed}
                  />
                  <PageLimit isDataNotAvailable={isDataLoadFailed} />
                </div>
              ) : isDataNotFound ? (
                <div className="flex justify-end mb-2 gap-x-2">
                  <SearchEmail
                    onSearch={changeSearchQuery}
                    initialValue={searchQuery}
                    setShowSkeleton={true}
                  />
                  <PageLimit isDataNotAvailable={!isDataAvailable || isDataLoadFailed} />
                </div>
              ) : (
                <div className="flex justify-end mb-2 gap-x-2">
                  <SearchEmail
                    onSearch={changeSearchQuery}
                    initialValue={searchQuery}
                    setShowSkeleton={true}
                    isDataNotAvailable={!isDataAvailable}
                  />
                  <PageLimit isDataNotAvailable={!isDataAvailable} />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="mb-2">
          <div className="flex justify-between items-center">
            <div className="title-text">
              <h5 className="text-base font-medium mb-1">History Aktivitas</h5>
              <p className="text-secondary text-sm font-normal mb-0">
                History aktivitas pengguna pada list suppression yang ada
              </p>
            </div>
            <div className="btn-group-suppress items-center gap-x-2">
              {isDataLoadFailed ? (
                <div className="flex justify-end mb-2 gap-x-2">
                  <SearchEmail
                    onSearch={changeSearchQuery}
                    initialValue={searchQuery}
                    setShowSkeleton={true}
                    isDataNotAvailable={isDataLoadFailed}
                  />
                  <PageLimit isDataNotAvailable={isDataLoadFailed} />
                </div>
              ) : isDataNotFound ? (
                <div className="flex justify-end mb-2 gap-x-2">
                  <SearchEmail
                    onSearch={changeSearchQuery}
                    initialValue={searchQuery}
                    setShowSkeleton={true}
                  />
                  <PageLimit isDataNotAvailable={!isDataAvailable || isDataLoadFailed} />
                </div>
              ) : (
                <div className="flex justify-end mb-2 gap-x-2">
                  <SearchEmail
                    onSearch={changeSearchQuery}
                    initialValue={searchQuery}
                    setShowSkeleton={true}
                    isDataNotAvailable={!isDataAvailable}
                  />
                  <PageLimit isDataNotAvailable={!isDataAvailable} />
                </div>
              )}
            </div>
          </div>
        </div>
      )),
    };
  };

  return (
    <ConfigProvider
      renderEmpty={() => {
        if (dataHistory.data?.count === 0 && searchQuery !== "") {
          return <SearchNotFound />;
        } else if (dataHistory.data?.count === 0) {
          return <EmptySuppress />;
        } else if (dataHistory.code === 500) {
          return <LoadFailed />;
        }
      }}>
      <div className="suppress-user mt-4">
        <div className="flex flex-col">
          <TitleTable />
          {showSkeleton ? (
            <Skeleton active />
          ) : (
            <Table
              className="table_history"
              {...(width < breakpoint ? { scroll: { x: 1024 } } : undefined)}
              columns={columnHistory}
              dataSource={dataHistory.data?.rows}
              pageLimit={{
                currentLimit: filterHistory.limit,
                onSelectLimit: (limit) => {
                  setFilter({ ...filterHistory, page: 1, limit });
                },
                disable: dataHistory.data?.count < 10,
              }}
              pagination={{
                pageSize: currentLimit,
                current: currentPage,
                total: dataHistory.data?.count,
                showSizeChanger: false,
                size: "small",
                onChange: changePage,
              }}
            />
          )}
        </div>
      </div>
    </ConfigProvider>
  );
};

export default TableHistory;
