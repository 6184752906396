import { createSlice } from "@reduxjs/toolkit";

export const pageStatus = createSlice({
  name: "pageStatus",
  initialState: {
    value: {
      isAdmin: false,
    }, // default state
  },
  reducers: {
    // reducers action
    setIsAdmin: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setIsAdmin } = pageStatus.actions;
export default pageStatus.reducer;
