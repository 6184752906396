import { Col, Divider } from "antd";

const CardEmailStatus = ({ title, number = "0", percentSent = "0" }) => {
  return (
    <Col lg={12} md={12} sm={12} xl={6}>
      <div className="px-6 py-4 bg-white card-email-status">
        <p className="text-secondary my-1">{title}</p>
        <p className="text-3xl mb-0 font-medium">{number.toLocaleString("en-US")}</p>
        <Divider />
        <p className="mb-0 text-success">{percentSent}% Email Terkirim</p>
      </div>
    </Col>
  );
};

export default CardEmailStatus;
