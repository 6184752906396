import { createSlice } from "@reduxjs/toolkit";

export const reportUsage = createSlice({
  name: "reportUsage",
  initialState: {
    value: false,
  },
  reducers: {
    setReportUsage: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setReportUsage } = reportUsage.actions;
export default reportUsage.reducer;
