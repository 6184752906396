import React from "react";
import "./dButton.less";

const DButton = ({
  type = "button",
  className = "",
  onClick,
  children,
  disabled,
  icon,
  responsive,
}) => {
  return (
    <button type={type} className={`DBtn ${className}`} onClick={onClick} disabled={disabled}>
      {icon}
      <span className={`DBtn-text ${responsive ? `hidden-${responsive}` : ""}`}>{children}</span>
    </button>
  );
};

export default DButton;
