import { Navigate, Outlet } from "react-router-dom";

const ProtectedAdminRoutes = ({
  user = "user",
  redirectPath = "/admin/login",
  children,
  token,
}) => {
  let element = children ? children : <Outlet />;
  if (!token) {
    element = <Navigate to={redirectPath} replace />;
  } else if (user && user.isAdmin) {
    element = children;
  } else if (user && !user.isAdmin) {
    element = <Navigate to="/main" replace />;
  }

  return element;
};

export default ProtectedAdminRoutes;
