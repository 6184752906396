import { createSlice } from "@reduxjs/toolkit";

export const reportBillingCustomerDetails = createSlice({
  name: "reportBillingCustomerDetails",
  initialState: {
    value: false,
  },
  reducers: {
    setReportBillingCustomerDetails: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setReportBillingCustomerDetails } = reportBillingCustomerDetails.actions;
export default reportBillingCustomerDetails.reducer;
