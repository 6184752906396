import { createSlice } from "@reduxjs/toolkit";

export const billingDataInvoiceSummary = createSlice({
  name: "billingDataInvoiceSummary",
  initialState: {
    value: false,
  },
  reducers: {
    setBillingDataInvoiceSummary: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setBillingDataInvoiceSummary } = billingDataInvoiceSummary.actions;
export default billingDataInvoiceSummary.reducer;
