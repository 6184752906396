import { getDomainActive } from "api";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDomainActive } from "redux/slices/domainActive";

const useGetDomainActive = () => {
  const domainActive = useSelector(({ domainActive }) => domainActive.value);
  const dispatch = useDispatch();

  const getData = useCallback(async () => {
    const { data } = await getDomainActive();
    dispatch(setDomainActive(data));
  }, [dispatch]);

  useEffect(() => {
    if (!domainActive) getData();
  }, [domainActive, getData]);
};

export default useGetDomainActive;
