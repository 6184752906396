import { createSlice } from "@reduxjs/toolkit";

export const listDomainAdmin = createSlice({
  name: "listDomainAdmin",
  initialState: {
    value: false,
  },
  reducers: {
    setListDomainAdmin: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setListDomainAdmin } = listDomainAdmin.actions;
export default listDomainAdmin.reducer;
