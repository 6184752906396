import Spinner from "components/Spinner/Spinner";
import { Suspense } from "react";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import Routing from "Routing";
import { getCookieToken, getSessionToken } from "utils/helpers";
import useGetUser from "utils/hooks/useGetUser";

function App() {
  useGetUser();
  const user = useSelector(({ userData }) => userData.value);
  const token = getSessionToken() || getCookieToken();

  return (
    <Suspense fallback={<Spinner full />}>
      <Routing user={user} token={token} />
    </Suspense>
  );
}

export default App;
