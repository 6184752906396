import { createSlice } from "@reduxjs/toolkit";

export const userPackage = createSlice({
  name: "userPackage",
  initialState: {
    value: false, // default state
  },
  reducers: {
    // reducers action
    setUserPackage: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setUserPackage } = userPackage.actions;
export default userPackage.reducer;
