import { Button } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import resultImage from "assets/images/data_maintenance.svg";

const DomainHasUsedTrial = () => {
  const navigate = useNavigate();

  const navCheckout = () => navigate("/checkout");
  const navDashboard = () => navigate("/main");
  return (
    <>
      <img src={resultImage} alt="" />
      <div className="flex text-center flex-col gap-y-1">
        <h2 className="text-3xl mb-1">Aktivasi Trial Gagal</h2>
        <p className="text-secondary mb-0">
          Domain yang anda gunakan sudah pernah menggunakan trial sebelumnya. Untuk dapat
          menggunakan layanan, anda dapat langsung berlangganan dengan menggunakan domain tersebut.
        </p>
      </div>
      <div className="flex flex-col w-full items-center gap-y-3 mt-4">
        <Button type="primary" className="btn-nav" onClick={navCheckout}>
          Pilih Paket Layanan
        </Button>
        <Button type="link" className="btn-nav" onClick={navDashboard}>
          Akses Dashboard
        </Button>
      </div>
    </>
  );
};

export default DomainHasUsedTrial;
