import { BankOutlined, ExportOutlined, FileDoneOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Select,
  Tabs,
  Tooltip,
  Typography,
} from "antd";
import { deleteTaxInvoice, exportAllInvoices, exportAllTaxInvoices } from "api";
import InputWithDropdown from "components/input/InputWithDropdown";
import {
  ICCreditCardOutlined,
  ICDollarCircleOutlined,
  ICFileDoneOutlined,
  ICFilterOutlined,
  ICPlusOutlined,
} from "components/list/ListIcon";
import ModalConfirm from "components/popups/modal/ModalConfirm";
import ModalFakturPajak from "components/popups/modal/ModalFakturPajak";
import { ModalSuccessFaktur } from "components/popups/modal/ModalSuccess";
import TableFaktur from "components/table/TableFaktur";
import TableInvoice from "components/table/TableInvoice";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import billingImg from "assets/images/billing.svg";
import TableConfirm from "components/table/TableConfirm";

const headerCSVInvoices = [
  { label: "Nama Perusahaan", key: "company_name" },
  { label: "Nomor Invoice", key: "inv_number" },
  { label: "Faktur Pajak", key: "inv_tax" },
  { label: "Status", key: "inv_status" },
  { label: "Tanggal Invoice", key: "inv_date" },
  { label: "Jatuh Tempo", key: "inv_due_date" },
  { label: "Jumlah Tagihan", key: "inv_price" },
];

const headerCSVFaktur = [
  { label: "Tanggal Faktur", key: "tax_date" },
  { label: "Nomor Faktur", key: "tax_number" },
  { label: "Nama Perusahaan", key: "company_name" },
  { label: "Nomor Invoice", key: "inv_number" },
  { label: "Nominal PPN", key: "tax_price" },
];

const ButtonExportCSV = ({ dataCSV, headerCSV, fileName, responsive, disable, table }) => {
  if (!dataCSV) return "";

  const ExportTableData = () => {
    const btnExport = useRef(null);
    const exportData = async () => {
      setTimeout(() => {
        btnExport.current.link.click();
      }, 300);
    };

    return (
      <>
        <span onClick={exportData}>Export table data</span>
        <div className="hidden">
          <CSVLink ref={btnExport} data={dataCSV} headers={headerCSV} filename={fileName} />
        </div>
      </>
    );
  };

  const ExportTableAllData = () => {
    const btnExport = useRef(null);
    const [data, setData] = useState([]);
    const exportData = async () => {
      const { data } =
        table === "invoice" ? await exportAllInvoices() : await exportAllTaxInvoices();
      if (data) setData(data);
      else setData([]);
      setTimeout(() => {
        btnExport.current.link.click();
      }, 300);
    };

    return (
      <>
        <span onClick={exportData}>Export all data</span>
        <div className="hidden">
          <CSVLink
            ref={btnExport}
            data={data}
            headers={headerCSV}
            filename={
              table === "invoice" ? "List-All-Invoice-Customer.csv" : "List-All-FP-Customer.csv"
            }
          />
        </div>
      </>
    );
  };

  const items = [
    { label: <ExportTableData />, key: 1 },
    { label: <ExportTableAllData />, key: 2 },
  ];
  const menu = <Menu selectable items={items} />;
  return (
    <Dropdown trigger={["click"]} overlay={menu} disabled={disable}>
      <Tooltip overlayClassName={responsive && `tooltipCSV-${responsive}`} title="Export To CSV">
        <Button icon={<ExportOutlined />} type="primary">
          <span className={responsive && `hidden-${responsive}`}>Export To CSV</span>
        </Button>
      </Tooltip>
    </Dropdown>
  );
};

const AccordionFilter = ({ setFilter, filter, confirmTabs }) => {
  const onFilter = (values) => {
    const filteredValues = {
      ...values,
      paymentDate: values.paymentDate ? values.paymentDate.format("YYYY/MM/DD") : undefined,
    };

    setFilter({
      ...filter,
      ...filteredValues,
      page: 1,
      limit: filter.limit,
    });
  };

  return (
    <Form
      layout="inline"
      className="formFilter"
      onFinish={onFilter}
      defaultValue={{ paid_status: "", fp_status: "", nominal: "" }}>
      <Form.Item
        label={confirmTabs ? "Status Bayar" : "Status Invoice"}
        name={confirmTabs ? "status" : "paid_status"}>
        <Select
          className="filter-select"
          allowClear
          placeholder={confirmTabs ? "Semua Status" : "Semua Invoice"}>
          <Select.Option value={confirmTabs ? [] : ""}>
            {confirmTabs ? "Semua Status" : "Semua Invoice"}
          </Select.Option>
          <Select.Option value={confirmTabs ? "confirmed" : "overdue"}>
            {confirmTabs ? "Confirmed" : "Overdue"}
          </Select.Option>
          <Select.Option value={confirmTabs ? "rejected" : "paid"}>
            {confirmTabs ? "Declined" : "Paid"}
          </Select.Option>
          <Select.Option value={confirmTabs ? "pending-confirmation" : "unpaid"}>
            {confirmTabs ? "Pending" : "Unpaid"}
          </Select.Option>
          {!confirmTabs && <Select.Option value="cancelled">Cancelled</Select.Option>}
        </Select>
      </Form.Item>
      <Form.Item
        label={confirmTabs ? "Bank Tujuan" : "Status FP"}
        name={confirmTabs ? "destination" : "fp_status"}>
        <Select
          className="filter-select"
          allowClear
          placeholder={confirmTabs ? "Semua Bank" : "Semua"}>
          <Select.Option value={confirmTabs ? [] : ""}>
            {confirmTabs ? "Semua Bank" : "Semua Status"}
          </Select.Option>
          <Select.Option value={confirmTabs ? "bca" : false}>
            {confirmTabs ? "Bank BCA" : "Belum Diterbitkan"}
          </Select.Option>
          <Select.Option value={confirmTabs ? "mandiri" : true}>
            {confirmTabs ? "Bank Mandiri" : "Sudah Diterbitkan"}
          </Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label={confirmTabs ? <Typography.Text ellipsis>Tanggal Bayar</Typography.Text> : "Nominal"}
        name={confirmTabs ? "paymentDate" : "nominal"}>
        {confirmTabs ? (
          <DatePicker placeholder="Pilih Tanggal" className="w-full" format="YYYY/MM/DD" />
        ) : (
          <Select className="filter-select" allowClear placeholder="Semua">
            <Select.Option className="log-admin-selectall" value="">
              Semua Nominal
            </Select.Option>
            <Select.Option value="moreThan">Diatas Rp5.000.000</Select.Option>
            <Select.Option value="lessThan">Dibawah Rp5.000.000</Select.Option>
          </Select>
        )}
      </Form.Item>
      <div className="button-advance-filter">
        <Button type="primary" htmlType="submit" icon={<ICFilterOutlined />}>
          Apply Filter
        </Button>
      </div>
    </Form>
  );
};

const InvoiceTable = ({
  loading,
  filterInvoice,
  filterFaktur,
  filterConfirm,
  setFilterInvoice,
  setFilterFaktur,
  setFilterConfirm,
  getInvoices,
  getTaxInvoices,
  getSummaryInvoice,
  getConfirmPayment,
}) => {
  const [width, setWidth] = useState(window.innerWidth);

  const [visible, setVisible] = useState(false);
  const [activeTabs, setActiveTabs] = useState("1");
  const invoiceTable = useSelector(({ billingDataInvoiceTable }) => billingDataInvoiceTable.value);
  const fakturTable = useSelector(({ billingDataFakturTable }) => billingDataFakturTable.value);
  const confirmTable = useSelector(
    ({ billingDataConfirmPayment }) => billingDataConfirmPayment.value
  );
  const breakpoint_third = 768;

  const handleDeleteFP = (record) => {
    ModalConfirm({
      title: `Hapus faktur pajak ${record.inv_number}`,
      width: 600,
      message: "Apakah anda yakin ingin menghapus faktur pajak untuk invoice tersebut?",
      cancelText: "Batalkan",
      okText: "Ya Hapus",
      okButtonProps: { danger: true },
      onOk: async () => {
        await deleteTaxInvoice({ uuid: record.tax_uuid });
        ModalSuccessFaktur({
          action: "hapus",
          formName: (
            <p className="mb-0">
              Faktur Pajak untuk invoice {record.inv_number} berhasil dihapus pada sistem
            </p>
          ),
          onOk: () => {
            getTaxInvoices();
            getInvoices();
          },
        });
      },
    });
  };

  const showModal = ({ action }) => {
    setVisible({ mode: action });
  };
  const handleOk = () => {
    getInvoices();
    getTaxInvoices();
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
  };
  const handleChange = (key) => {
    setActiveTabs(key);
  };

  const dataInvoices = { table: invoiceTable, loading };
  const dataFaktur = { table: fakturTable, loading };
  const dataConfirm = { table: confirmTable, loading };

  const ButtonUploadFaktur = () => {
    return (
      <Button
        type="ghost"
        icon={<ICPlusOutlined />}
        onClick={() => showModal({ action: "upload" })}>
        <span className="hidden-lg">Upload FP</span>
      </Button>
    );
  };

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  if (!invoiceTable || !fakturTable || !confirmTable) return <Card loading />;

  const itemsInvoice = [
    { label: "Nama perusahaan", icon: <BankOutlined />, key: "company_name" },
    { label: "Nomor invoice", icon: <ICCreditCardOutlined />, key: "invoice_number" },
  ];

  const itemsFaktur = [
    { label: "Nama perusahaan", icon: <BankOutlined />, key: "company_name" },
    { label: "Nomor invoice", icon: <ICCreditCardOutlined />, key: "inv_number" },
    { label: "Nomor seri FP", icon: <FileDoneOutlined />, key: "tax_number" },
  ];

  const itemsConfirm = [
    { label: "Nama perusahaan", icon: <BankOutlined />, key: "companyName" },
    { label: "Nomor invoice", icon: <ICCreditCardOutlined />, key: "invNumber" },
  ];

  const actionListInvoice = [
    { action: "company_name", placeholder: "Cari nama perusahaan", icon: BankOutlined },
    { action: "invoice_number", placeholder: "Cari nomor invoice", icon: ICCreditCardOutlined },
  ];
  const actionListFaktur = [
    { action: "company_name", placeholder: "Cari nama perusahaan", icon: BankOutlined },
    { action: "inv_number", placeholder: "Cari nomor invoice", icon: ICCreditCardOutlined },
    { action: "tax_number", placeholder: "Cari nomor faktur", icon: FileDoneOutlined },
  ];

  const actionListConfirm = [
    { action: "companyName", placeholder: "Cari nama perusahaan", icon: BankOutlined },
    { action: "invNumber", placeholder: "Cari nomor invoice", icon: ICCreditCardOutlined },
  ];

  return (
    <div className="px-6 py-4 bg-white">
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTabs}
        className="billing-tab"
        onChange={handleChange}
        items={[
          {
            label: (
              <span>
                <ICCreditCardOutlined />
                Invoice Pembayaran
              </span>
            ),
            key: "1",
            children: (
              <TableInvoice
                title={{
                  className: "table-titleInvoice",
                  title: "Daftar Invoice",
                  desc: "List invoice layanan yang digunakan client",
                  loading,
                  pageLimit: {
                    currentLimit: filterInvoice.limit,
                    onSelectLimit: (limit) => {
                      setFilterInvoice({ ...filterInvoice, limit, page: 1 });
                    },
                    disable: dataInvoices.table?.count < 10,
                  },
                  addsBefore: (
                    <InputWithDropdown
                      items={itemsInvoice}
                      actionList={actionListInvoice}
                      setFilter={setFilterInvoice}
                      filter={filterInvoice}
                    />
                  ),
                  addsAfter: (
                    <ButtonExportCSV
                      dataCSV={dataInvoices.table?.rows}
                      headerCSV={headerCSVInvoices}
                      fileName="List-Invoice-Customer.csv"
                      responsive="lg"
                      disable={dataInvoices.table?.rows.length < 1}
                      table="invoice"
                    />
                  ),
                  addsAccordion: (
                    <AccordionFilter
                      setFilter={setFilterInvoice}
                      filter={filterInvoice}
                      width={width}
                    />
                  ),
                }}
                emptyState={{
                  title: "Data tidak ditemukan",
                  message:
                    "Data perusahaan yang anda cari tidak ditemukan, pastikan tidak ada salah ketik pada penulisan",
                }}
                data={dataInvoices}
                role="admin"
                filter={filterInvoice}
                setFilter={setFilterInvoice}
                breakpoint_third={breakpoint_third}
                responsiveWidth={width}
                callbackInvoice={getInvoices}
                callbackSummary={getSummaryInvoice}
              />
            ),
          },
          {
            label: (
              <span>
                <ICFileDoneOutlined />
                Faktur Pajak
              </span>
            ),
            key: "2",
            children: (
              <>
                <TableFaktur
                  role="admin"
                  data={dataFaktur}
                  setVisible={setVisible}
                  filter={filterFaktur}
                  setFilter={setFilterFaktur}
                  title={{
                    title: "Daftar Faktur Pajak",
                    desc: "List faktur pajak tagihan client",
                    loading,
                    pageLimit: {
                      currentLimit: filterFaktur.limit,
                      onSelectLimit: (limit) => {
                        setFilterFaktur({ ...filterFaktur, limit, page: 1 });
                      },
                      disable: dataFaktur.table?.count < 10,
                    },
                    addsBefore: (
                      <InputWithDropdown
                        actionList={actionListFaktur}
                        items={itemsFaktur}
                        filter={filterFaktur}
                        setFilter={setFilterFaktur}
                      />
                    ),
                    addsBeforeExport: <ButtonUploadFaktur />,
                    addsAfter: (
                      <ButtonExportCSV
                        dataCSV={dataFaktur.table?.rows}
                        headerCSV={headerCSVFaktur}
                        fileName="List-FP-Customer.csv"
                        responsive="lg"
                        disable={dataFaktur.table?.rows.length < 1}
                        table="faktur"
                      />
                    ),
                  }}
                  emptyState={{
                    ...(dataFaktur.table?.isSearch
                      ? {
                          title: "Data tidak ditemukan",
                          message:
                            "Data perusahaan yang anda cari tidak ditemukan, pastikan tidak ada salah ketik pada penulisan",
                        }
                      : {
                          title: "Belum ada faktur pajak",
                          message:
                            "Data faktur pajak belum tersedia, tambahkan faktur pajak milik klien dengan upload faktur pajak.",
                          image: billingImg,
                        }),
                  }}
                  handleDeleteFP={handleDeleteFP}
                />
                <ModalFakturPajak
                  data={visible.record}
                  cancel={handleCancel}
                  handleSuccess={handleOk}
                  open={visible}
                  setOpen={setVisible}
                  action={visible.mode}
                />
              </>
            ),
          },
          {
            label: (
              <span>
                <ICDollarCircleOutlined />
                Konfirmasi Payment
              </span>
            ),
            key: "3",
            children: (
              <>
                <TableConfirm
                  title={{
                    className: "table-titleInvoice",
                    title: "Daftar Pembayaran Customer",
                    desc: "List bukti pembayaran dari customer",
                    loading,
                    pageLimit: {
                      currentLimit: filterConfirm.limit,
                      onSelectLimit: (limit) => {
                        setFilterConfirm({ ...filterConfirm, limit, page: 1 });
                      },
                      disable: dataConfirm.table?.count < 10,
                    },
                    addsBefore: (
                      <InputWithDropdown
                        items={itemsConfirm}
                        actionList={actionListConfirm}
                        setFilter={setFilterConfirm}
                        filter={filterConfirm}
                      />
                    ),
                    addsAccordion: (
                      <AccordionFilter
                        setFilter={setFilterConfirm}
                        filter={filterConfirm}
                        width={width}
                        confirmTabs={true}
                      />
                    ),
                  }}
                  emptyState={{
                    title: "Data tidak ditemukan",
                    message:
                      "Data perusahaan yang anda cari tidak ditemukan, pastikan tidak ada salah ketik pada penulisan",
                  }}
                  ListConfirmPayment={dataConfirm}
                  role="admin"
                  filter={filterConfirm}
                  setFilter={setFilterConfirm}
                  breakpoint_third={breakpoint_third}
                  responsiveWidth={width}
                  adminConfirmation={true}
                  callBackConfirm={getConfirmPayment}
                  callbackInvoice={getInvoices}
                  callbackSummary={getSummaryInvoice}
                />
              </>
            ),
          },
        ]}
      />
    </div>
  );
};

export default InvoiceTable;
