import { ConfigProvider, Divider, List } from "antd";
import { EmptyNotification } from "components/empty/EmptyComponent";
import { format } from "date-fns";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/helpers";
import {
  ICCreditCardOutlined,
  ICMailOutlined,
  ICNotificationOutlined,
  ICPercentageOutlined,
} from "./ListIcon";

const CategoryIcon = ({ className, category }) => {
  const iconList = [
    { name: "Spamming", icon: <ICMailOutlined className={className} /> },
    { name: "Billing", icon: <ICCreditCardOutlined className={className} /> },
    { name: "Promo", icon: <ICPercentageOutlined className={className} /> },
    { name: "Info", icon: <ICNotificationOutlined className={className} /> },
  ];

  const iconRender = iconList.find((item) => item.name === category);
  return iconRender ? iconRender.icon : "";
};

const ListNotificationRender = ({ item, position, setExpandable, getNotification }) => {
  const navigate = useNavigate();
  const navDetailNotification = () => {
    navigate(`/notification/detail/${item.title.split(" ").join("-")}`, {
      state: { uuid: item.uuid },
    });
    getNotification();
    if (position === "navbar") setExpandable(false);
  };

  return position === "navbar" ? (
    <>
      <div
        className={`menu-notification-dropdown pointer py-3 pl-5 pr-5 ${
          item.isRead ? "" : "notification-unread"
        }`}
        onClick={navDetailNotification}>
        <p className="mb-2 text-notification-header">{item.title}</p>
        <p className="text-secondary mb-2 notif-body">{item.preview}</p>
        <p className="flex text-secondary mb-0 notif-category">
          <span className="mr-1">
            <CategoryIcon category={capitalizeFirstLetter(item.category)} />
          </span>
          <span className="mr-1">{capitalizeFirstLetter(item.category)}</span>
          <span className="text-sm text-secondary mr-1">•</span>
          <span>{format(new Date(item.date), "dd MMM yyyy • hh:mm")}</span>
        </p>
      </div>
      <Divider className="m-0" />
    </>
  ) : (
    <>
      <div
        className={`menu-notification-body pointer py-4 px-5 pl-5 pr-5 ${
          item.isRead ? "" : "notification-unread"
        }`}
        onClick={navDetailNotification}>
        <div className="text-secondary date-notif">
          <CategoryIcon className="mr-1" category={capitalizeFirstLetter(item.category)} />
          <span className="mr-1">{capitalizeFirstLetter(item.category)}</span>
          <span className="text-xs text-secondary mr-1 items-center">•</span>
          <span>{format(new Date(item.date), "dd MMM yyyy • hh:mm")}</span>
        </div>
        <p className="mb-1 text-notification-header">{item.title}</p>
        <p className="text-secondary mb-1 notif-body">{item.preview}</p>
      </div>
      <Divider className="m-0" />
    </>
  );
};

const ListNotification = ({ position, setExpandable, getNotification }) => {
  const [filter, setFilter] = useState({ page: 1 });
  const data = useSelector(({ notificationList }) => notificationList.value);
  const filterList = data && data.rows.slice(0, 5);

  return (
    <ConfigProvider renderEmpty={() => <EmptyNotification />}>
      <List
        itemLayout="horizontal"
        {...(data && { dataSource: position === "navbar" ? filterList : data.rows })}
        {...(position === "page"
          ? {
              pagination: {
                pageSize: 7,
                defaultPageSize: 7,
                hideOnSinglePage: true,
                current: filter.page,
                onChange: (page) => setFilter({ page }),
                size: "small",
              },
            }
          : {})}
        rowKey={(record) => record.uuid}
        renderItem={(item) => (
          <ListNotificationRender
            position={position}
            item={item}
            setExpandable={setExpandable}
            getNotification={getNotification}
          />
        )}
      />
    </ConfigProvider>
  );
};

export default ListNotification;
