import { Col, Row } from "antd";
import {
  getContactClients,
  getDetailCompany,
  getDomainClients,
  getPackageClient,
  getProfileCompany,
  updateAdminCompanyProfilePicture,
} from "api";
import CardContactInformation from "components/box-card/CardContactInformation";
import CardPacketInfo from "components/box-card/CardPacketInfo";
import CardProfileNew from "components/box-card/CardProfileNew";
import DashboardPageHeader from "components/dashboard-header/DashboardPageHeader";
import Spinner from "components/Spinner/Spinner";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setDetailCompany } from "redux/slices/admin/detailCompany";
import useDocumentTitle from "utils/useDocumentTitle";
import "../pelanggan.less";
import InformasiPerusahaan from "./InformasiPerusahaan";
import RegisteredDomain from "./RegisteredDomain";

const EditPelanggan = () => {
  const [disable, setDisable] = useState(false);
  const { state } = useLocation();
  const { company_uuid } = state;
  const [loadingSection, setLoadingSection] = useState(false);
  const dispatch = useDispatch();
  const editPelanggan = useSelector(({ detailCompany }) => detailCompany.value);
  const { profileCompany } = editPelanggan;
  const { contactCompany } = editPelanggan;
  const { infoCompany } = editPelanggan;
  useDocumentTitle(`Edit ${profileCompany?.companyName || "Pelanggan"}`);

  const getData = useCallback(
    async (userInfo, forSectionCard) => {
      setLoadingSection(forSectionCard);
      const { data: dataCompany } = await getDetailCompany({ companyUuid: company_uuid });
      const { data: profileCompany } = await getProfileCompany({ companyUuid: company_uuid });
      const { data: contactCompany } = await getContactClients({ companyUuid: company_uuid });
      const { data: packageCompany } = await getPackageClient({ companyUuid: company_uuid });
      const { data: domainCompany } = await getDomainClients({ companyUuid: company_uuid });
      const { data: infoCompany } = await getDetailCompany({ companyUuid: company_uuid });
      dispatch(
        setDetailCompany({
          infoCompany,
          dataCompany,
          profileCompany,
          contactCompany,
          packageCompany,
          domainCompany,
        })
      );
      setLoadingSection(false);
    },
    [dispatch, company_uuid]
  );

  const getUpdateContact = async () => {
    const { data: contactCompany } = await getContactClients({ companyUuid: company_uuid });
    dispatch(setDetailCompany({ ...editPelanggan, contactCompany }));
  };

  const changeImage = async (option) => {
    setLoadingSection("cardProfile");
    const { file } = option;
    await updateAdminCompanyProfilePicture({ photo: file, uuid: company_uuid });
    const { data: profileCompany } = await getProfileCompany({ companyUuid: company_uuid });
    dispatch(setDetailCompany({ ...editPelanggan, profileCompany }));
    setLoadingSection(false);
  };

  useEffect(() => {
    if (!editPelanggan) getData();
  }, [getData, editPelanggan]);

  return !editPelanggan ? (
    <Spinner loading={loadingSection} full />
  ) : (
    <div className="pelanggan-admin">
      <DashboardPageHeader title={state?.company_name} />
      <div className="px-6 py-4">
        <div className="profile-client__wrapper">
          <Row gutter={[24, 24]}>
            <Col className="profileClient__left" xs={24} xl={8}>
              <Row gutter={[24, 24]}>
                <Col xs={24} md={12} xl={24}>
                  <CardProfileNew
                    action="login"
                    loading={loadingSection}
                    infoProfile={state}
                    name={profileCompany?.companyName}
                    photo={profileCompany?.companyPhoto}
                    website={profileCompany?.companyWebsite}
                    account={profileCompany?.companyRelay}
                    createdAt={profileCompany?.companyCreatedAt}
                    changeImage={changeImage}
                  />
                </Col>
                <Col xs={24} md={12} xl={24}>
                  <CardPacketInfo data={editPelanggan.packageCompany} loading={loadingSection} />
                </Col>
              </Row>
            </Col>
            <Col className="profileClient__right" xl={16}>
              <Row gutter={[24, 24]}>
                <Col xs={24}>
                  <InformasiPerusahaan
                    isAdmin="admin"
                    loading={loadingSection}
                    data={infoCompany}
                    sectionLoad={getData}
                    companyUuid={company_uuid}
                    disable={disable}
                    setDisable={setDisable}
                  />
                </Col>
                <Col xs={24}>
                  <Row className="contactDomain__row" gutter={[10, 10]}>
                    <Col xs={24} lg={12} xl={12}>
                      <CardContactInformation
                        loading={loadingSection}
                        sectionLoad={getUpdateContact}
                        contact={contactCompany}
                        company_uuid={company_uuid}
                      />
                    </Col>
                    <Col xs={24} lg={12} xl={12}>
                      <RegisteredDomain loading={loadingSection} sectionLoad={getData} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default EditPelanggan;
