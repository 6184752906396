import Spinner from "components/Spinner/Spinner";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookieToken } from "./helpers";
import useGetUser from "./hooks/useGetUser";

const CheckLogin = () => {
  const { pathname: path } = useLocation();
  const navigate = useNavigate();
  useGetUser();

  const user = useSelector(({ userData }) => userData.value);
  const token = getCookieToken();

  useEffect(() => {
    if (!token && !user) window.location.href = "/login";
    else if (token && user === "not-verified") navigate("/register/verifikasi");
    else if (user) {
      if (user.isAdmin) navigate("/admin/main");
      else navigate("/main");
    }
  }, [navigate, path, token, user]);

  return <Spinner full />;
};

export default CheckLogin;
