import { Form, Input, Select, Checkbox, Radio } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { regexEmail, requiredEmail } from "utils/helpers";
import { useState } from "react";

const FormSuppressAdmin = ({
  className = "",
  wrapperCol,
  label = "",
  isSetup = false,
  onEmailChange,
  setCompany,
  EmailValidation,
  setReason,
  checked,
  disabled,
  setChecked,
  ListCompany,
  setGlobalValue,
  setIsButtonDisabled,
  companySelectValue,
  RadioState,
  onChoose,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState(ListCompany);

  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    const filteredList = ListCompany.filter((company) =>
      company.company_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCompanies(filteredList);
  };

  const onChange = (e) => {
    setChecked(e.target.checked);
    if (e.target.checked === true) {
      setGlobalValue(true);
    } else {
      setGlobalValue(false);
      setIsButtonDisabled(!companySelectValue);
    }
  };

  const inputEmail = <Input placeholder="nama@perusahaan.tld" onChange={onEmailChange} />;
  const SelectCompany = (
    <Select
      onClick={(e) => e.stopPropagation()}
      placeholder={"Pilih Perusahaan"}
      onSelect={(value) => {
        setCompany(value);
        setSearchValue("");
      }}
      dropdownRender={(menu) => (
        <>
          <Input
            placeholder="Ketik perusahaan..."
            onChange={(e) => handleSearch(e.target.value)}
            value={searchValue}
          />
          {menu}
        </>
      )}
      onDropdownVisibleChange={(open) => {
        if (!open) {
          setSearchValue("");
        }
      }}
      disabled={checked}>
      {filteredCompanies.length > 0
        ? filteredCompanies.map(({ company_name, company_uuid }, index) => (
            <Select.Option key={company_uuid + index} value={company_uuid}>
              {company_name}
            </Select.Option>
          ))
        : ListCompany.length > 0
        ? ListCompany.map(({ company_name, company_uuid }, index) => (
            <Select.Option key={company_uuid + index} value={company_uuid}>
              {company_name}
            </Select.Option>
          ))
        : undefined}
    </Select>
  );
  const inputReason = (
    <TextArea
      placeholder="Opsional: alasan mengapa email tujuan di block"
      onChange={handleReasonChange}
    />
  );

  const RadioButton = (
    <Radio.Group onChange={onChoose} value={RadioState}>
      <Radio value={"blacklist"}>Blacklist Suppression</Radio>
      <Radio value={"whitelist"}>Whitelist Suppression</Radio>
    </Radio.Group>
  );

  return (
    <Form.Item label={label} className={className} wrapperCol={wrapperCol} hasFeedback>
      {!isSetup ? (
        <div>
          <p className="mb-4">
            Tambah manual alamat email tujuan kedalam suppression list email, maka anda tidak dapat
            mengirimkan email ke alamat email tersebut.
          </p>
          <Form.Item
            label="Alamat Email"
            name="email"
            rules={[requiredEmail, { pattern: regexEmail, message: EmailValidation }]}
            className="mb-4">
            {inputEmail}
          </Form.Item>
          <Form.Item className="mb-4">{RadioButton}</Form.Item>
          <Form.Item label="Suppression Owner" name="Owner" className="mb-4">
            {SelectCompany}
            <Checkbox
              checked={checked}
              disabled={disabled}
              onChange={onChange}
              style={{ marginTop: 8 }}>
              Atur sebagai Suppression List Global
            </Checkbox>
          </Form.Item>
          <Form.Item label="Alasan" name="message" className="mb-0">
            {inputReason}
          </Form.Item>
        </div>
      ) : null}
    </Form.Item>
  );
};

export default FormSuppressAdmin;
