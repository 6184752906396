import { Button, Card, Col, Row, Space, Tag } from "antd";
import { ICEditOutlined, ICSelectOutlined } from "components/list/ListIcon";
import React from "react";

import { useNavigate } from "react-router";

const TitleCard = ({ status }) => {
  return (
    <div className="flex gap-x-2 items-center">
      <span>Informasi Paket Berlangganan</span>
      {status === "active" || status === "overdue" ? (
        <Tag color="blue" className="blue-6">
          Aktif
        </Tag>
      ) : status === "inactive" ? (
        <Tag color="red">Belum aktif</Tag>
      ) : (
        ""
      )}
    </div>
  );
};

const InformationSubscribe = ({ loading, servicePackage }) => {
  const navigate = useNavigate();
  const navConfirmation = () => navigate("/billing/confirmation");
  const navCheckout = () => navigate("/checkout");

  return (
    <Card loading={loading} title={<TitleCard status={servicePackage.status} />}>
      {servicePackage.status === "active" || servicePackage.status === "overdue" ? (
        <div className="flex w-full justify-between">
          <Col md={12}>
            <div className="flex flex-col items-start">
              <Space size={8} direction="vertical" className="mb-4">
                <p className="text-secondary mb-0">Paket berlangganan saat ini</p>
                <p className="text-xl mb-0 font-medium">
                  {servicePackage.price}
                  <span className="text-xs font-normal text-secondary">/bulan</span>
                </p>
                <div>
                  <p className="mb-1">{servicePackage.package.replace(" - null", "")}</p>
                  <p className="mb-0 text-secondary">{`${
                    servicePackage.startDateSubs === "-" ? "" : servicePackage.startDateSubs
                  }  - ${servicePackage.endDateSubs === "-" ? "" : servicePackage.endDateSubs}`}</p>
                </div>
              </Space>
              <Button type="default" icon={<ICEditOutlined />} onClick={navConfirmation}>
                Ubah Paket Layanan
              </Button>
            </div>
          </Col>
          <Col md={12}>
            <Row justify="space-between">
              <Col md={12} xs={24}>
                <div className="mb-3">
                  <p className="mb-0 text-secondary">Maksimum Kuota</p>
                  <p className="mb-0">{servicePackage.limit_email}</p>
                </div>
                <div className="mb-3">
                  <p className="mb-0 text-secondary">Limitasi Kiriman Email</p>
                  <p className="mb-0">{servicePackage.limit_email_hourly}</p>
                </div>
              </Col>
              <Col md={12} xs={24}>
                <div className="mb-3">
                  <p className="mb-0 text-secondary">Maksimum Domain</p>
                  <p className="mb-0">{servicePackage.limit_domain}</p>
                </div>
                <div className="mb-3">
                  <p className="mb-0 text-secondary">Limitasi Attachment</p>
                  <p className="mb-0">{servicePackage.limit_attachment_size}</p>
                </div>
              </Col>
            </Row>
          </Col>
        </div>
      ) : (
        <div className="flex flex-col items-start">
          <p className="text-secondary mb-4">Anda belum berlangganan paket apapun</p>
          <Button icon={<ICSelectOutlined />} onClick={navCheckout}>
            Pilih Paket Layanan
          </Button>
        </div>
      )}
    </Card>
  );
};

export default InformationSubscribe;
