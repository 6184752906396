import { Modal } from "antd";

const { error } = Modal;

const ModalError = (props) => {
  const { title, width, message, customMessage } = props;
  const messageModal = customMessage || <p className="mb-0">{message}</p>;
  return error({
    ...props,
    title: title,
    width: width || 416,
    content: messageModal,
    centered: true,
    okText: "Oke",
  });
};

export default ModalError;
