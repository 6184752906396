import { createSlice } from "@reduxjs/toolkit";

export const accountSMTP = createSlice({
  name: "accountSMTP",
  initialState: {
    value: false, // default state
  },
  reducers: {
    // reducers action
    setAccount: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setAccount } = accountSMTP.actions;
export default accountSMTP.reducer;
