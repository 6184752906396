import { createSlice } from "@reduxjs/toolkit";

export const reportUsageChart = createSlice({
  name: "reportUsageChart",
  initialState: {
    value: false,
  },
  reducers: {
    setReportUsageChart: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setReportUsageChart } = reportUsageChart.actions;
export default reportUsageChart.reducer;
