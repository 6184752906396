import { Badge, Select, Card, Typography } from "antd";
import { useState, useRef, useEffect } from "react";

const { Option } = Select;
const { Paragraph } = Typography;

const SampleResponse = () => {
  const SelectStatus = [
    { value: "success", label: "200 - Success" },
    { value: "no_token", label: "401 - No Token Provided" },
    { value: "invalid-token", label: "401 - Invalid Token" },
    { value: "wrong_domain", label: "401 - Wrong Domain" },
    { value: "validation_error", label: "400 - Validation Error" },
    { value: "internal_error", label: "500 - Internal Error" },
  ];

  const [selectedStatus, setSelectedStatus] = useState(SelectStatus[0].value);
  const [dropdownWidth, setDropdownWidth] = useState("auto");
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Calculate width based on the text content
    if (dropdownRef.current) {
      const textWidth = dropdownRef.current.offsetWidth;
      setDropdownWidth(`${textWidth}px`);
    }
  }, [selectedStatus]);

  const handleStatusChange = (value) => {
    setSelectedStatus(value);
  };

  const TitleSample = () => {
    return (
      <div className="flex gap-x-2 items-center" style={{ justifyContent: "space-between" }}>
        <span>Sample Response</span>
        <Select
          style={{ width: dropdownWidth }}
          onChange={handleStatusChange}
          value={selectedStatus}
          ref={dropdownRef}>
          {SelectStatus.map((option) => (
            <Option key={option.value} value={option.value}>
              <Badge color={option.value === "success" ? "green" : "red"} />
              <span style={{ marginLeft: "4px" }}>{option.label}</span>
            </Option>
          ))}
        </Select>
      </div>
    );
  };

  const SampleContent = () => {
    let sampleApiResponse = {};

    switch (selectedStatus) {
      case "success":
        sampleApiResponse = {
          data: {
            status: "Success",
            code: 200,
            msg: "Message queued as 188f6e94c1800096dd",
            id: "188f6e94c1800096dd",
            from: "support@aktiva.co.id",
            to: ["support@excellent.co.id", "sales@excellent.co.id"],
          },
        };
        break;
      case "no_token":
        sampleApiResponse = {
          data: {
            status: "Failed",
            code: 401,
            msg: "No token provided!",
            error: {
              msg: "Require token to access this route!",
            },
          },
        };
        break;
      case "invalid-token":
        sampleApiResponse = {
          data: {
            status: "Failed",
            code: 401,
            msg: "Not authorized!",
            error: {
              msg: "Not authorized to access this route!",
            },
          },
        };
        break;
      case "wrong_domain":
        sampleApiResponse = {
          data: {
            status: "Failed",
            code: 401,
            msg: "Send access denied!",
            error: {
              msg: "You not owned the sending domain!",
            },
          },
        };
        break;
      case "validation_error":
        sampleApiResponse = {
          data: {
            status: "Failed",
            code: 400,
            msg: "Validation Error!",
            error: {
              msg: [
                '"from" must be a valid email',
                '"to[0]" must be a valid email',
                '"replyTo" must be a valid email',
                '"subject" must be a string',
                '"content_type" must be one of [html, text]',
                '"<field_name>" is not allowed',
                'Cannot upload file to "<field_name>" field',
              ],
            },
          },
        };
        break;
      case "internal_error":
        sampleApiResponse = {
          data: {
            status: "Failed",
            code: 500,
            msg: "Internal server error",
            error: {
              msg: "Please contact customer support to escalate this problem!",
            },
          },
        };
        break;
      default:
        break;
    }

    return (
      <div>
        <Paragraph className="response">
          <pre
            style={{ padding: "5px 12px", margin: "0 0", background: "white", fontSize: "12px" }}>
            {JSON.stringify(sampleApiResponse.data, null, 2)}
          </pre>
        </Paragraph>
      </div>
    );
  };

  return (
    <Card title={<TitleSample />} className="sample__wrapper">
      {" "}
      <SampleContent />
    </Card>
  );
};

export default SampleResponse;
