import { getEmailAPI } from "api";
import { useCallback, useEffect } from "react";
import { useJwt } from "react-jwt";
import { useDispatch, useSelector } from "react-redux";
import { setEmailAPI } from "redux/slices/EmailAPI";
import { getCookieToken, getSessionToken } from "utils/helpers";

const useGetData = () => {
  const token = getSessionToken() || getCookieToken();
  const dispatch = useDispatch();
  const { decodedToken = "" } = useJwt(token);

  const isSetup = useSelector(({ EmailAPI }) => EmailAPI.value);

  const action = setEmailAPI;

  const doSet = useCallback((data) => dispatch(action(data)), [dispatch, action]);

  useEffect(() => {
    const getData = async () => {
      const { data } = await getEmailAPI();
      if (data && Object.keys(data).length) doSet(data);
    };
    if (decodedToken && !isSetup) getData();
  }, [decodedToken, doSet, isSetup]);
};

export default useGetData;
