import { Button, Card, Descriptions, Typography, Form } from "antd";
import { ICEditOutlined } from "components/list/ListIcon";
import ModalEditCompanyInformation from "components/popups/modal/ModalEditCompanyInformation";
import ModalLengkapiNpwp from "components/popups/modal/ModalLengkapiNpwp";
import { useState } from "react";
import { cEmailSent, dataSources, emailType, maskNPWP, openInNewTab } from "utils/helpers";

const { Text } = Typography;

const BtnCompany = ({ setVisible, disable }) => {
  return (
    <Button
      type="primary"
      disabled={disable}
      icon={<ICEditOutlined />}
      className="mb-2"
      onClick={() => setVisible("editCompany")}>
      Edit Informasi
    </Button>
  );
};

const Rowset = ({ list = [] }) => {
  return (
    <Descriptions
      layout="horizontal"
      column={1}
      bordered
      className="drawer-desc-wrapper-clients p-0">
      {list.map((item) => (
        <Descriptions.Item key={item.label} label={item.label}>
          {item.value}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};

const InformasiPerusahaan = ({
  data: dataCompany,
  loading,
  isAdmin,
  companyUuid,
  sectionLoad,
  disable,
  setDisable,
}) => {
  const [visible, setVisible] = useState(false);
  const Type = dataCompany.npwpType;
  const [action, setAction] = useState(
    ((Type === "15" || !Type) && "NPWP 15") || (Type === "16" && "NPWP 16")
  );
  const [form] = Form.useForm();

  return (
    <Card
      title="Informasi Perusahaan"
      loading={loading === "companyInformation"}
      extra={<BtnCompany setVisible={setVisible} />}>
      <Rowset
        list={[
          { label: "Nama", value: dataCompany.companyName },
          { label: "Alamat", value: dataCompany?.address },
          { label: "Telepon", value: dataCompany?.phone },
          { label: "Website", value: dataCompany?.website },
          {
            label: "NPWP",
            value: (
              <div className="flex flex-col gap-y-2 py-2 items-start">
                {dataCompany.npwp !== "null" &&
                (dataCompany.npwpType === "15" || !dataCompany.npwpType) ? (
                  <span>{maskNPWP(dataCompany.npwp)}</span>
                ) : dataCompany.npwp !== "null" && dataCompany.npwpType === "16" ? (
                  <span>{dataCompany.npwp}</span>
                ) : (
                  "-"
                )}
                {dataCompany.urlNpwp ? (
                  <Button
                    type="link"
                    className="text-info buttonNpwp"
                    onClick={() => openInNewTab(`/npwp-image?url=${dataCompany.urlNpwp}`)}>
                    Npwp-{dataCompany.companyName}.{dataCompany.urlNpwp.split(".").slice(-1)[0]}
                  </Button>
                ) : (
                  <Text className="mb-2 p-0 text-secondary mt-2" italic>
                    Npwp & File Belum Dilengkapi
                  </Text>
                )}
              </div>
            ),
          },
          {
            label: "Estimasi Kiriman",
            value: cEmailSent.find((x) => x.value === dataCompany?.countEmailSent)?.text,
          },
          { label: "Peran Kontak", value: dataCompany?.roleUser ? dataCompany?.roleUser : "-" },
          { label: "Estimasi Domain", value: dataCompany?.estimateDomain },
          {
            label: "Tipe Kiriman",
            value: emailType.find((x) => x.value === dataCompany?.emailType)?.text,
          },
          {
            label: "Sumber Email",
            value: dataSources.find((x) => x.value === dataCompany?.dataSources)?.text,
          },
          { label: "Provider Existing", value: dataCompany?.previousVendor },
        ]}
      />
      <ModalLengkapiNpwp
        isOpen={visible}
        setOpen={setVisible}
        npwp={dataCompany.npwp}
        action={action}
        setAction={setAction}
        disable={disable}
        setDisable={setDisable}
        form={form}
      />
      <ModalEditCompanyInformation
        isAdmin={isAdmin}
        isOpen={visible}
        setOpen={setVisible}
        name={dataCompany.companyName}
        address={dataCompany.address}
        phone={dataCompany.phone}
        website={dataCompany.website}
        npwp={dataCompany.npwp}
        urlNpwp={dataCompany.urlNpwp}
        getData={sectionLoad}
        companyUuid={companyUuid}
        action={action}
        setAction={setAction}
        disable={disable}
        setDisable={setDisable}
        form={form}
      />
    </Card>
  );
};

export default InformasiPerusahaan;
