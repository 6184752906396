import { Form, Modal } from "antd";
import FormSuppressAdmin from "components/form/FormSuppressAdmin";
import ModalConfirmSuppress from "./ModalConfirmSuppress";
import ModalFailedSuppress from "./ModalFailedSuppress";
import { useState } from "react";
import { regexEmail, gatherFormData } from "utils/helpers";
import { CreateSuppress } from "api";
import ModalError from "./ModalError";

const ModalAddSuppressAdmin = ({
  isOpen,
  setOpen,
  ListCompany,
  setShowSkeletonSuppress,
  getCallbackSuppress,
  getCallbackHistory,
  filteredCompanies,
}) => {
  const [emailValidationError, setEmailValidationError] = useState("");
  const [emailFieldValue, setEmailFieldValue] = useState("");
  const [companySelectValue, setCompanySelectValue] = useState("");
  const [globalValue, setGlobalValue] = useState(false);
  const [reasonFieldValue, setReasonFieldValue] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isAddingSuppress, setIsAddingSuppress] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [checked, setChecked] = useState(false);
  const [RadioState, setRadioState] = useState("");

  const [form] = Form.useForm();

  const handleCancelSuprress = () => {
    form.resetFields();
    setEmailFieldValue("");
    setIsButtonDisabled(true);
    setCompanySelectValue("");
    setChecked(false);
    setOpen(false);
    setGlobalValue(false);
    setRadioState("");
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setEmailFieldValue(email);
    const isEmailNotEmpty = email.trim() !== "";
    const isEmailValid = regexEmail.test(email);
    if (!isEmailNotEmpty) {
      setEmailValidationError("Alamat email wajib diisi");
    } else if (!isEmailValid) {
      setEmailValidationError("Harap masukkan alamat email yang vaild");
    } else {
      setEmailValidationError("");
    }
    setIsButtonDisabled(
      !(isEmailNotEmpty && isEmailValid && RadioState && (companySelectValue || globalValue))
    );
  };

  const handleCompanySelectChange = (value) => {
    setCompanySelectValue(value);
    setIsButtonDisabled(!(emailFieldValue && RadioState && (value || globalValue || checked)));
  };

  const handleGlobalSelectChange = (value) => {
    setGlobalValue(value);
    setIsButtonDisabled(
      !(emailFieldValue && RadioState && (companySelectValue || value || checked))
    );
  };

  const onChoose = (e) => {
    setRadioState(e.target.value);
    setIsButtonDisabled(
      !(
        emailFieldValue &&
        (RadioState === "" || RadioState !== "") &&
        (companySelectValue || globalValue)
      )
    );
  };

  const handleAddSuppress = async () => {
    const email = emailFieldValue;
    const company = companySelectValue;
    const message = reasonFieldValue;
    const global = globalValue;
    const category = RadioState;
    setIsAddingSuppress(true);

    if (!message) {
      let requestBody = {
        email: email,
        global: global,
        type: category,
      };

      if (global === false) {
        requestBody.companyUuid = company;
      }

      const response = await CreateSuppress(requestBody);
      if (response.code === 500 || (response.code !== 200 && response.code !== 400)) {
        setOpen(false);
        ModalError({
          title: "Peringatan!",
          message: response.msg,
          onOk: () => {
            setIsAddingSuppress(false);
            setOpen(false);
            setShowSkeletonSuppress(true);
            setTimeout(() => {
              setShowSkeletonSuppress(false);
              setEmailFieldValue("");
              setIsButtonDisabled(true);
              setCompanySelectValue("");
              setChecked(false);
              setRadioState("");
              form.resetFields();
            }, 500);
          },
        });
      } else if (response.code === 400) {
        setTimeout(() => {
          ModalFailedSuppress({
            title: "Email Gagal Ditambahkan",
            customMessage: (
              <div>
                <p>
                  Email <strong>{email}</strong> gagal ditambahkan pada suppression list, karena
                  email sudah terdaftar sebelumnya.
                </p>
              </div>
            ),
            onOk: () => {
              setIsAddingSuppress(false);
              setOpen(false);
              setShowSkeletonSuppress(true);
              setTimeout(() => {
                setShowSkeletonSuppress(false);
                setEmailFieldValue("");
                setIsButtonDisabled(true);
                setCompanySelectValue("");
                setChecked(false);
                setRadioState("");
                form.resetFields();
              }, 500);
            },
          });
        }, 1000);
      } else {
        setTimeout(() => {
          ModalConfirmSuppress({
            title: "Suppression Berhasil Ditambahkan",
            customMessage: (
              <div>
                <p>
                  Email <strong>{email}</strong> sudah berhasil ditambahkan ke suppression list
                </p>
              </div>
            ),
            onOk: () => {
              setOpen(false);
              setShowSkeletonSuppress(true);
              setTimeout(async () => {
                await getCallbackSuppress();
                await getCallbackHistory();
                setIsAddingSuppress(false);
                setShowSkeletonSuppress(false);
                setEmailFieldValue("");
                setIsButtonDisabled(true);
                setCompanySelectValue("");
                setChecked(false);
                setRadioState("");
                form.resetFields();
              }, 500);
            },
          });
        }, 1000);
      }
    } else {
      let requestBody = {
        email: email,
        global: global,
        message: message,
        type: category,
      };

      if (global === false) {
        requestBody.companyUuid = company;
      }

      const response = await CreateSuppress(requestBody);
      if (response.code === 500 || response.code !== 200) {
        setOpen(false);
        ModalError({
          title: "Peringatan!",
          message: response.msg,
          onOk: () => {
            setOpen(false);
            setShowSkeletonSuppress(true);
            setTimeout(() => {
              setShowSkeletonSuppress(false);
              setEmailFieldValue("");
              setIsButtonDisabled(true);
              setCompanySelectValue("");
              setChecked(false);
              setRadioState("");
              form.resetFields();
            }, 500);
          },
        });
      } else if (response.code === 409) {
        setTimeout(() => {
          ModalFailedSuppress({
            title: "Email Gagal Ditambahkan",
            customMessage: (
              <div>
                <p>
                  Email <strong>{email}</strong> gagal ditambahkan pada suppression list, karena
                  email sudah terdaftar sebelumnya.
                </p>
              </div>
            ),
            onOk: () => {
              setOpen(false);
              setShowSkeletonSuppress(true);
              setTimeout(() => {
                setShowSkeletonSuppress(false);
                setEmailFieldValue("");
                setIsButtonDisabled(true);
                setCompanySelectValue("");
                setChecked(false);
                setRadioState("");
                form.resetFields();
              }, 500);
            },
          });
        }, 1000);
      } else {
        setTimeout(() => {
          ModalConfirmSuppress({
            title: "Suppression Berhasil Ditambahkan",
            customMessage: (
              <div>
                <p>
                  Email <strong>{email}</strong> sudah berhasil ditambahkan ke suppression list
                </p>
              </div>
            ),
            onOk: () => {
              setOpen(false);
              setShowSkeletonSuppress(true);
              setTimeout(async () => {
                await getCallbackSuppress();
                await getCallbackHistory();
                setIsAddingSuppress(false);
                setShowSkeletonSuppress(false);
                setEmailFieldValue("");
                setIsButtonDisabled(true);
                setCompanySelectValue("");
                setChecked(false);
                setRadioState("");
                form.resetFields();
              });
            },
          });
        }, 1000);
      }
    }
    gatherFormData(form);
  };

  return (
    <Modal
      title="Tambah Email Suppression"
      open={isOpen}
      centered
      width="572px"
      onOk={handleAddSuppress}
      onCancel={handleCancelSuprress}
      okText="Tambah Suppression"
      cancelText="Batal"
      okButtonProps={{ disabled: isButtonDisabled, loading: isAddingSuppress }}>
      <Form
        layout="vertical"
        name="tambahSuppress"
        initialValues={{ notifikasi: true }}
        form={form}>
        <FormSuppressAdmin
          className="mb-0"
          onEmailChange={handleEmailChange}
          EmailValidation={emailValidationError}
          setReason={setReasonFieldValue}
          setCompany={handleCompanySelectChange}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          checked={checked}
          disabled={disabled}
          setChecked={setChecked}
          ListCompany={ListCompany}
          globalValue={globalValue}
          setGlobalValue={handleGlobalSelectChange}
          filteredCompanies={filteredCompanies}
          companySelectValue={companySelectValue}
          setIsButtonDisabled={setIsButtonDisabled}
          RadioState={RadioState}
          setRadioState={setRadioState}
          onChoose={onChoose}
        />
      </Form>
    </Modal>
  );
};

export default ModalAddSuppressAdmin;
