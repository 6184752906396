const { default: BillingData } = require("./billing/BillingData");
const { default: KelolaDokumentasi } = require("./kelola-dokumentasi/KelolaDokumentasi");
const { default: KelolaPaket } = require("./kelola-paket/KelolaPaket");
const { default: PackageActions } = require("./kelola-paket/PackageActions");
const { default: KelolaAdmin } = require("./kelolaAdmin/KelolaAdmin");
const { default: LogEmail } = require("./log/LogEmail");
const { default: MainDashboard } = require("./main/MainDashboard");
const { default: CreateNotification } = require("./manage-notification/CreateNotification");
const { default: ManageNotification } = require("./manage-notification/ManageNotification");
const { default: ViewNotification } = require("./manage-notification/ViewNotification");
const { default: EditNotification } = require("./manage-notification/EditNotification");
const { default: DaftarPelanggan } = require("./pelanggan/DaftarPelanggan");
const { default: EditPelanggan } = require("./pelanggan/edit/EditPelanggan");
const { default: PelangganCreate } = require("./pelanggan/PelangganCreate");
const { default: Profile } = require("./profile/Profile");
const { default: DetailReportBilling } = require("./report-billing/DetailReportBilling");
const { default: ReportBilling } = require("./report-billing/ReportBilling");
const { default: ReportEmail } = require("./report/ReportUsage");
const { default: SuppressionListAdmin } = require("./suppress/SuppressionList");

export {
  BillingData,
  KelolaDokumentasi,
  KelolaPaket,
  KelolaAdmin,
  LogEmail,
  PackageActions,
  MainDashboard,
  DaftarPelanggan,
  EditPelanggan,
  PelangganCreate,
  Profile,
  DetailReportBilling,
  ReportBilling,
  ReportEmail,
  ManageNotification,
  CreateNotification,
  ViewNotification,
  EditNotification,
  SuppressionListAdmin,
};
