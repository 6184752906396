import {
  getAllConfirmationPayment,
  getAllInvoices,
  getAllTaxInvoices,
  getInvoiceSummary,
  getListCompany,
} from "api";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setBillingDataFakturTable } from "redux/slices/admin/billingDataFakturTable";
import { setBillingDataInvoiceSummary } from "redux/slices/admin/billingDataInvoiceSummary";
import { setBillingDataInvoiceTable } from "redux/slices/admin/billingDataInvoiceTable";
import { setListAllCompany } from "redux/slices/admin/listAllCompany";
import useDocumentTitle from "utils/useDocumentTitle";
import "./billingdata.less";
import InvoiceSummary from "./InvoiceSummary";
import InvoiceTable from "./InvoiceTable";
import { setBillingDataConfirmPayment } from "redux/slices/admin/billingDataConfirmPayment";

const BillingData = () => {
  useDocumentTitle("Data Tagihan");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [radioValue, setRadioValue] = useState("month");
  const listCompany = useSelector(({ listAllCompany }) => listAllCompany.value);
  const [filterInvoice, setFilterInvoice] = useState({
    page: 1,
    limit: "10",
    company_name: "",
  });
  const [filterFaktur, setFilterFaktur] = useState({
    page: 1,
    limit: "10",
    company_name: "",
  });
  const [filterConfirm, setFilterConfirm] = useState({ page: 1, limit: "10" });

  const getSummaryInvoice = useCallback(async () => {
    const { data: invoiceSummary } = await getInvoiceSummary({ getBy: radioValue });
    dispatch(setBillingDataInvoiceSummary(invoiceSummary));
  }, [dispatch, radioValue]);

  const getAllCompany = useCallback(async () => {
    const { data } = await getListCompany();
    dispatch(setListAllCompany(data));
  }, [dispatch]);

  const getInvoices = useCallback(async () => {
    setLoading(true);
    const { data: invoice } = await getAllInvoices(filterInvoice);
    dispatch(setBillingDataInvoiceTable(invoice));
    setLoading(false);
  }, [dispatch, filterInvoice]);

  const getTaxInvoices = useCallback(async () => {
    setLoading(true);
    const { data: faktur } = await getAllTaxInvoices(filterFaktur);
    dispatch(setBillingDataFakturTable(faktur));
    setLoading(false);
  }, [dispatch, filterFaktur]);

  const getConfirmPayment = useCallback(async () => {
    setLoading(true);
    const { data } = await getAllConfirmationPayment(filterConfirm);
    dispatch(setBillingDataConfirmPayment(data));
    setLoading(false);
  }, [dispatch, filterConfirm]);

  useEffect(() => {
    if (radioValue) getSummaryInvoice();
  }, [radioValue, getSummaryInvoice]);

  useEffect(() => {
    if (filterInvoice) getInvoices();
  }, [filterInvoice, getInvoices]);

  useEffect(() => {
    if (filterFaktur) getTaxInvoices();
  }, [filterFaktur, getTaxInvoices]);

  useEffect(() => {
    if (!listCompany) getAllCompany();
  }, [getAllCompany, listCompany]);

  useEffect(() => {
    if (filterConfirm) getConfirmPayment();
  }, [filterConfirm, getConfirmPayment]);

  return (
    <div className="billing-admin">
      <DashboardHeader title="Data Tagihan" />
      <div className="flex flex-col gap-y-6 px-6 py-4">
        <div className="invoice-summary-section">
          <InvoiceSummary radioValue={radioValue} setRadioValue={setRadioValue} />
        </div>
        <div className="table-invoice">
          <InvoiceTable
            loading={loading}
            setLoading={setLoading}
            filterInvoice={filterInvoice}
            filterFaktur={filterFaktur}
            filterConfirm={filterConfirm}
            setFilterFaktur={setFilterFaktur}
            setFilterInvoice={setFilterInvoice}
            setFilterConfirm={setFilterConfirm}
            getInvoices={getInvoices}
            getTaxInvoices={getTaxInvoices}
            getSummaryInvoice={getSummaryInvoice}
            getConfirmPayment={getConfirmPayment}
          />
        </div>
      </div>
    </div>
  );
};

export default BillingData;
