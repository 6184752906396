import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Modal } from "antd";
import { authLogout, deleteContact } from "api";
import ModalContactPelanggan from "components/popups/modal/ModalContactPelanggan";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import { useState } from "react";
import { useSelector } from "react-redux";
import { gatherFormData, runFunction, setLogout } from "utils/helpers";
import ContactButton from "./ContactButton";
import ContactTag from "./ContactTag";

const { confirm } = Modal;

const ContactList = ({ loading, setLoading, user, sectionLoad }) => {
  const { contactName, contactEmail, contactRole, contactPhone, contactUuid } = user;
  const { email, isAdmin } = useSelector(({ userData }) => userData.value);
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);

  const handleDeleteContact = () => {
    confirm({
      title: `Hapus kontak ${contactName}`,
      icon: <ExclamationCircleOutlined />,
      width: 600,
      content: (
        <>
          <p>Apakah anda yakin ingin menghapus kontak client tersebut?</p>
          <p className="mb-0">Anda tidak dapat mengembalikannya kembali setelah kontak dihapus</p>
        </>
      ),
      okText: "Hapus Kontak",
      okButtonProps: {
        danger: true,
        htmlType: "submit",
      },
      cancelText: "Batalkan",
      centered: true,
      onOk: () => {
        const callback = async () => {
          await deleteContact({ contactUuid });
          ModalSuccess({
            title: "Kontak berhasil dihapus",
            message: `Kontak ${contactName} berhasil dihapus pada sistem`,
            onOk: async () => {
              if (contactEmail === email && !isAdmin) {
                const { code } = await authLogout();
                if (code === 200) setLogout("user");
              }
              runFunction(() => sectionLoad());
            },
          });
        };
        gatherFormData(form, callback);
      },
    });
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <div className="flex justify-between contact-wrapper">
      <div className="contact">
        <p className="mb-1 font-medium">{contactName}</p>
        <p className="mb-1 text-xs">{contactEmail}</p>
        <ContactButton
          role={contactRole}
          handleEdit={showModal}
          handleDelete={handleDeleteContact}
        />
        <ModalContactPelanggan
          action="action"
          visible={visible}
          onCancel={handleCancel}
          data={{
            contactName,
            contactEmail,
            contactRole,
            contactPhone,
            contactUuid,
          }}
          loading={loading}
          setLoading={setLoading}
          sectionLoad={sectionLoad}
        />
      </div>
      <div className="contact align-right">
        <p className="mb-1 mr-2">{contactPhone}</p>
        <ContactTag role={contactRole} />
      </div>
    </div>
  );
};

export default ContactList;
