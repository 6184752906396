import { createSlice } from "@reduxjs/toolkit";

export const checkoutChecker = createSlice({
  name: "checkoutChecker",
  initialState: {
    value: false,
  },
  reducers: {
    setCheckoutChecker: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setCheckoutChecker } = checkoutChecker.actions;
export default checkoutChecker.reducer;
