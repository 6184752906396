import { Modal } from "antd";

const { success } = Modal;

const defaultFn = () => undefined;

export const ModalSuccessFaktur = ({ action, formName, companyName, onOk = defaultFn }) => {
  const message =
    action === "upload"
      ? `Faktur Pajak untuk client ${companyName} berhasil diupload pada sistem`
      : `Faktur Pajak untuk client ${companyName} berhasil diperbarui pada sistem`;
  const customMessage = formName || <p className="mb-0">{message}</p>;
  return success({
    title: `Faktur pajak berhasil di ${action}`,
    content: customMessage,
    centered: true,
    okText: "Oke",
    onOk: () => {
      onOk();
      Modal.destroyAll();
    },
  });
};

export const ModalSuccessQouta = ({
  title,
  message,
  customMessage,
  onOk = defaultFn,
  okText = "Oke",
}) => {
  const messageModal = customMessage || <p className="mb-0">{message}</p>;
  return success({
    title: title,
    width: 500,
    content: messageModal,
    centered: true,
    okText,
    onOk: () => {
      onOk();
      Modal.destroyAll();
    },
  });
};

const ModalSuccess = ({
  title,
  message,
  customMessage,
  width,
  onOk = defaultFn,
  okText = "Oke",
}) => {
  const messageModal = customMessage || <p className="mb-0">{message}</p>;
  return success({
    title: title,
    width: width || 416,
    content: messageModal,
    centered: true,
    okText,
    onOk: () => {
      onOk();
      Modal.destroyAll();
    },
  });
};

export default ModalSuccess;
