import { Alert } from "antd";
import React from "react";
import { Link, useLocation } from "react-router-dom";

const SetupNavigate = () => {
  const { pathname } = useLocation();
  const navHref = <a href="/setup#dns-reputation"> Setup Layanan</a>;
  const navLink = <Link to="/setup#dns-reputation"> Setup Layanan</Link>;

  return pathname.includes("setup") ? navHref : navLink;
};

const DNSAlert = ({ className, domain, user }) => {
  if (!user && !user.dnsStatus && !user.isSetupDomain) return "";

  return (
    <Alert
      className={className}
      message={
        <p className="mb-0">
          DNS domain <span className="font-medium">{domain}</span> belum sesuai. Harap sesuaikan
          dengan melakukan konfigurasi DNS sesuai panduan pada
          <SetupNavigate />
        </p>
      }
      type="warning"
      showIcon
    />
  );
};

export default DNSAlert;
