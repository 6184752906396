import { Card, Radio, Row } from "antd";
import { useSelector } from "react-redux";
import CardInvoiceSummary from "components/box-card/CardInvoiceSummary";

const RadioButton = ({ radioValue, setRadioValue }) => {
  const handler = ({ target: { value } }) => {
    setRadioValue(value);
  };

  return (
    <Radio.Group buttonStyle="solid" value={radioValue} onChange={handler}>
      <Radio.Button value="month">This Month</Radio.Button>
      <Radio.Button value="year">This Year</Radio.Button>
      <Radio.Button value="all">All Data</Radio.Button>
    </Radio.Group>
  );
};

const InvoiceSummary = ({ radioValue, setRadioValue }) => {
  const summaryInvoice = useSelector(
    ({ billingDataInvoiceSummary }) => billingDataInvoiceSummary.value
  );

  if (!summaryInvoice)
    return (
      <Card
        title="Invoice Summary"
        loading
        extra={<RadioButton />}
        className="invoice-summary-wrapper"
      />
    );

  return (
    <Card
      title="Invoice Summary"
      extra={<RadioButton radioValue={radioValue} setRadioValue={setRadioValue} />}
      className="invoice-summary-wrapper">
      <Row gutter={[24, 0]}>
        <CardInvoiceSummary title="Unpaid" number={summaryInvoice.inv_unpaid} invoice="true" />
        <CardInvoiceSummary title="Paid" number={summaryInvoice.inv_paid} invoice="true" />
        <CardInvoiceSummary
          title="Cancelled"
          number={summaryInvoice.inv_cancelled}
          invoice="true"
        />
        <CardInvoiceSummary title="Total" number={summaryInvoice.inv_total} invoice="true" />
      </Row>
    </Card>
  );
};

export default InvoiceSummary;
