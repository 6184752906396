import { createSlice } from "@reduxjs/toolkit";

export const detailsSubscription = createSlice({
  name: "detailsSubscription",
  initialState: {
    value: false,
  },
  reducers: {
    setDetailsSubscription: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setDetailsSubscription } = detailsSubscription.actions;
export default detailsSubscription.reducer;
