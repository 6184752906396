import { UserOutlined } from "@ant-design/icons";
import { Affix, Badge, Button, Divider, Dropdown, Menu, Space } from "antd";
import { authLogout, getUserNotification, markReadNotification } from "api";
import { LogoAktivaDark } from "components/Components";
import { EmptyNotification } from "components/empty/EmptyComponent";
import {
  ICBellOutlined,
  ICCreditCardOutlined,
  ICInfoCircleOutlined,
  ICPoweroffOutlined,
  ICQuestionCircleOutlined,
  ICUserOutlined,
} from "components/list/ListIcon";
import ListNotification from "components/list/ListNotification";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setNotificationList } from "redux/slices/notificationList";
import { setLogout } from "utils/helpers";
import "./usernavbar.less";

const NotificationDropdown = ({ data, setExpandable, isAdmin }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getNotification = async () => {
    const { data } = isAdmin ? "" : await getUserNotification();
    dispatch(setNotificationList(data));
  };
  const doRead = async () => {
    const { code } = await markReadNotification();
    if (code === 200) getNotification();
  };
  return (
    <Affix offsetTop={64}>
      <div className="bg-white">
        <div className="flex flex-col notif-box-nabvar">
          <div className={`flex ${data.countUnread > 0 ? "text-success" : ""}`}>
            <ICInfoCircleOutlined className="info-circle-icon-notification" />
            <p className="my-3 text-notification-header">
              Notification {data.countUnread > 0 ? `(${data.countUnread})` : ""}
            </p>
          </div>
          <Divider className="m-0" />
          {data.rows.length < 1 ? (
            <div className="notification-dropdown">
              <EmptyNotification />
            </div>
          ) : (
            <div className="box-notification">
              <ListNotification
                position="navbar"
                setExpandable={setExpandable}
                getNotification={getNotification}
              />
            </div>
          )}
          <div className="flex">
            <Button
              type="text"
              className="w-full text-btn-secondary text-sm btn-act-notif pt-3 pb-3 p-0"
              size="large"
              onClick={doRead}>
              Tandai sudah dibaca
            </Button>
            <Button
              type="text"
              className="w-full text-btn-secondary text-sm btn-act-notif pt-3 pb-3 p-0"
              size="large"
              onClick={() => navigate("notification", setExpandable(false))}>
              Lihat semua notifikasi
            </Button>
          </div>
        </div>
      </div>
    </Affix>
  );
};

const UserNavbar = () => {
  const data = useSelector(({ notificationList }) => notificationList.value);
  const { isAdmin, name } = useSelector(({ userData }) => userData.value);
  const [expandable, setExpandable] = useState(false);

  const user = useSelector(({ userData }) => userData.value);

  const handleLogout = async () => {
    await authLogout();
    setTimeout(() => {
      setLogout(isAdmin ? "admin" : "user");
    }, 500);
  };

  const userDropdown = (
    <Affix offsetTop={64}>
      <div className="bg-white navbarUser">
        <div className="flex flex-col px-3 py-1 h-auto bg-white">
          <p className="mb-0">{isAdmin ? name : user.companyName}</p>
          <p className="text-secondary mb-0">{user.email}</p>
        </div>
        <Menu
          className="dropdownMenu"
          items={[
            {
              key: "profile",
              label: <Link to={isAdmin ? "/admin/profile" : "/profile"}>Profil Saya</Link>,
              icon: <ICUserOutlined />,
            },
            {
              key: "tagihan",
              label: <Link to="/billing">Tagihan</Link>,
              icon: <ICCreditCardOutlined />,
              className: isAdmin && "hidden",
            },
          ]}
        />
        <Button
          type="link"
          icon={<ICPoweroffOutlined className="text-xs" rotate={90} />}
          className="btn-logout text-danger"
          onClick={handleLogout}>
          Keluar
        </Button>
      </div>
    </Affix>
  );
  const handleOpenChange = (flag) => {
    setExpandable(flag);
  };

  return (
    <div className="flex justify-between">
      <LogoAktivaDark />
      <div className="flex justify-between items-center ">
        <Space direction="horizontal">
          {!isAdmin && (
            <>
              <Button
                type="dashed"
                target="_blank"
                href="https://support.aktiva.co.id "
                ghost
                icon={<ICQuestionCircleOutlined />}>
                Support Ticket
              </Button>
              <div className="dropdown-notification">
                <Dropdown
                  onOpenChange={handleOpenChange}
                  open={expandable}
                  overlay={
                    data && (
                      <NotificationDropdown
                        isAdmin={isAdmin}
                        data={data}
                        setExpandable={setExpandable}
                      />
                    )
                  }
                  trigger={["click"]}
                  placement="bottomRight"
                  className="notification-icon"
                  overlayStyle={{ paddingTop: 16 }}
                  style={{ overflow: "scroll" }}>
                  <Button type="link">
                    <Badge count={data && data.countUnread} className="badge-icon">
                      <ICBellOutlined />
                    </Badge>
                  </Button>
                </Dropdown>
              </div>
            </>
          )}
          <div className="dropdown-user">
            <Dropdown
              overlay={userDropdown}
              placement="bottomRight"
              trigger={["click"]}
              className="notification-icon"
              overlayStyle={{ paddingTop: 16 }}>
              <Button
                icon={<UserOutlined className="text-base" />}
                type="link"
                className="user-button">
                {user.name}
              </Button>
            </Dropdown>
          </div>
        </Space>
      </div>
    </div>
  );
};

export default UserNavbar;
