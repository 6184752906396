import { Form, Modal } from "antd";
import { updateAdminCompanyNpwp, updateNpwp } from "api";
import FormNPWP from "components/form/FormNPWP";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { gatherFormData } from "utils/helpers";
import ModalError from "./ModalError";
import ModalSuccess from "./ModalSuccess";
import { ICQuestionCircleOutlined } from "components/list/ListIcon";
import { useLocation } from "react-router-dom";

const ModalLengkapiNpwp = ({
  uuid,
  setOpen,
  isOpen,
  npwp,
  getUpdate,
  action,
  setAction,
  showModalGuide,
  GuideModal,
  disable,
  setDisable,
  form,
  getDataFaktur,
}) => {
  const [loading, setLoading] = useState(false);

  const { isAdmin } = useSelector(({ userData }) => userData.value);

  const Location = useLocation();

  const closeForm = () => {
    setDisable(true);
    setOpen(false);
    setAction("NPWP 15");
    form.setFieldsValue({ npwp: "" });
  };

  const handleFillNPWP = () => {
    setLoading(true);
    const callback = async () => {
      const values = form.getFieldsValue();
      let body = { ...values };
      if (values.fileNpwp) body = { ...values, fileNpwp: values.fileNpwp[0].originFileObj };
      else delete body.fileNpwp;
      const { status, code, msg } = isAdmin
        ? await updateAdminCompanyNpwp({ uuid, ...body })
        : await updateNpwp(body);
      setLoading(false);
      if (status === "success") {
        closeForm();
        ModalSuccess({
          title: "Data NPWP Berhasil Disimpan",
          message:
            "Data NPWP Perusahaan anda berhasil disimpan pada sistem. Silakan dapat melihat secara berkala untuk mengunduh Faktur Pajak anda.",
          onOk: () => {
            Location.pathname !== "/billing"
              ? getUpdate(false, "companyInformation")
              : getDataFaktur();
            form.setFieldsValue({
              npwp: values.npwp,
              npwpType: values.npwpType,
            });
            setAction(values.npwpType === "15" ? "NPWP 15" : "NPWP 16");
          },
        });
      } else if (code === 400 && msg === "Please attach file") {
        closeForm();
        ModalError({
          title: "Harap upload file NPWP Anda",
          message:
            "Anda belum mengupload softcopy file NPWP pada form tersebut. Harap upload file NPWP Anda sebagai kelengkapan data administrasi.",
          onOk: () => {
            form.setFieldsValue({
              npwp: values.npwp,
              npwpType: values.npwpType,
            });
            setAction(values.npwpType === "15" ? "NPWP 15" : "NPWP 16");
          },
        });
      } else {
        closeForm();
        ModalError({
          title: "Warning!",
          message: "Cek kembali data yang anda masukan.",
          onOk: () => {
            form.setFieldsValue({ npwp: "" });
            setAction("NPWP 15");
          },
        });
      }
    };
    gatherFormData(form, callback);
  };

  const handleMask = ({ unmaskedValue }) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...values, npwp: unmaskedValue });
  };

  return (
    <Modal
      title="Lengkapi Data NPWP"
      open={isOpen === "fillNpwp"}
      onOk={handleFillNPWP}
      okButtonProps={{ htmlType: "submit", loading: loading, disabled: disable }}
      onCancel={closeForm}
      okText="Simpan NPWP"
      centered
      cancelText="Batal">
      <Form layout="vertical" form={form} initialValues={{ npwp: npwp }}>
        <FormNPWP
          handleMask={handleMask}
          classNPWP="mb-1"
          setDisable={setDisable}
          setAction={setAction}
          action={action}
          form={form}
        />
        {isAdmin === false && (
          <p className="text-secondary" style={{ marginBottom: 0 }}>
            Pilih format NPWP anda, kenali perbedaannya
            <span style={{ color: "#0D9F3F", marginLeft: 4 }}>
              <ICQuestionCircleOutlined onClick={showModalGuide} />
            </span>
          </p>
        )}
        {isAdmin === false && GuideModal}
      </Form>
    </Modal>
  );
};

export default ModalLengkapiNpwp;
