import { Button } from "antd";
import { useSelector } from "react-redux";

const ListDokumentasi = ({ setOpen, setDrawer }) => {
  const showDrawer = (item) => {
    setOpen(true);
    setDrawer(item);
  };

  const domains = useSelector(({ domainActive }) => domainActive.value);
  const account = useSelector(({ accountSMTP }) => accountSMTP.value);
  const { accessDomain, passwordRelay, userRelay } = account;

  const list = [
    {
      key: 1,
      title: "Konfigurasi pada Email Zimbra Server Versi 8.5 - 9.x",
      element: (
        <>
          <p className="mb-3">1. Remote server Zimbra menggunakan SSH dan akses ke console</p>
          <p className="mb-3">2. Login sebagai user root dari sistem operasi Linux</p>
          <p className="mb-3">3. Jalankan perintah berikut untuk login sebagai user Zimbra</p>
          <pre>
            <code>su - zimbra</code>
          </pre>
          <p className="mb-3">
            4. Ketikkan atau copy perintah berikut dan paste pada console email server Zimbra anda
          </p>
          <pre>
            <code>
              echo '{accessDomain} {userRelay}:{passwordRelay}' {">"}{" "}
              /opt/zimbra/conf/relay_password <br />
              postmap /opt/zimbra/conf/relay_password <br />
              postmap -q {accessDomain} /opt/zimbra/conf/relay_password <br />
              zmprov ms `zmhostname` zimbraMtaSmtpSaslPasswordMaps
              lmdb:/opt/zimbra/conf/relay_password <br />
              zmprov ms `zmhostname` zimbraMtaSmtpSaslAuthEnable yes <br />
              zmprov ms `zmhostname` zimbraMtaSmtpCnameOverridesServername no <br />
              zmprov ms `zmhostname` zimbraMtaSmtpTlsSecurityLevel may
              <br />
              zmprov ms `zmhostname` zimbraMtaSmtpSaslSecurityOptions noanonymous <br />
              postconf -e
              sender_dependent_default_transport_maps=lmdb:/opt/zimbra/conf/aktiva-bysender <br />
              echo '#List Domain Relay' {">"} /opt/zimbra/conf/aktiva-bysender <br />
              {domains &&
                domains.map(({ name }) => {
                  return `echo '@${name} smtp:${accessDomain}:587' >> /opt/zimbra/conf/aktiva-bysender\n`;
                })}
              postmap /opt/zimbra/conf/aktiva-bysender <br />
              postfix reload <br />
            </code>
          </pre>
        </>
      ),
    },
    {
      key: 2,
      title: "Konfigurasi pada Email Zimbra Server Versi 8.0.x",
      element: (
        <>
          <p className="mb-3">1. Remote server Zimbra menggunakan SSH dan akses ke console</p>
          <p className="mb-3">2. Login sebagai user root dari sistem operasi Linux</p>
          <p className="mb-3">3. Jalankan perintah berikut untuk login sebagai user Zimbra</p>
          <pre>
            <code>su - zimbra</code>
          </pre>
          <p className="mb-3">
            4. Ketikkan atau copy perintah berikut dan paste pada console email server Zimbra anda
          </p>
          <pre>
            <code>
              echo '{accessDomain} {userRelay}:{passwordRelay}' {">"}{" "}
              /opt/zimbra/conf/relay_password <br />
              postmap /opt/zimbra/conf/relay_password <br />
              postmap -q {accessDomain} /opt/zimbra/conf/relay_password <br />
              zmlocalconfig -e postfix_smtp_sasl_password_maps=hash:/opt/zimbra/conf/relay_password{" "}
              <br />
              zmlocalconfig -e postfix_smtp_sasl_auth_enable=yes <br />
              zmlocalconfig -e postfix_smtp_cname_overrides_servername=no <br />
              zmlocalconfig -e postfix_smtp_tls_security_level=may <br />
              zmlocalconfig -e postfix_smtp_sasl_security_options=noanonymous <br />
              zmlocalconfig -e postfix_smtp_use_tls=yes <br />
              postconf -e smtp_use_tls=yes <br />
              postconf -e smtpd_use_tls=yes <br />
              postconf -e
              sender_dependent_default_transport_maps=hash:/opt/zimbra/conf/aktiva-bysender <br />
              echo '#List Domain Relay' {">"} /opt/zimbra/conf/aktiva-bysender <br />
              {domains &&
                domains.map(({ name }) => {
                  return `echo '@${name} smtp:${accessDomain}:587' >> /opt/zimbra/conf/aktiva-bysender\n`;
                })}
              postmap /opt/zimbra/conf/aktiva-bysender <br />
              postfix reload <br />
            </code>
          </pre>
        </>
      ),
    },
    {
      key: 3,
      title: "Konfigurasi pada Email Zimbra Server versi 7.x",
      element: (
        <>
          <p className="mb-3">1. Remote server Zimbra menggunakan SSH dan akses ke console</p>
          <p className="mb-3">2. Login sebagai user root dari sistem operasi Linux</p>
          <p className="mb-3">3. Jalankan perintah berikut untuk login sebagai user Zimbra</p>
          <pre>
            <code>su - zimbra</code>
          </pre>
          <p className="mb-3">
            4. Ketikkan atau copy perintah berikut dan paste pada console email server Zimbra anda
          </p>
          <pre>
            <code>
              echo '{accessDomain} {userRelay}:{passwordRelay}' {">"}{" "}
              /opt/zimbra/conf/relay_password <br />
              postmap /opt/zimbra/conf/relay_password <br />
              postmap -q {accessDomain} /opt/zimbra/conf/relay_password <br />
              postconf -e smtp_sasl_password_maps=hash:/opt/zimbra/conf/relay_password <br />
              postconf -e smtp_sasl_auth_enable=yes <br />
              postconf -e smtp_sasl_security_options=noanonymous <br />
              postconf -e smtp_tls_security_level=may <br />
              postconf -e
              sender_dependent_default_transport_maps=hash:/opt/zimbra/conf/aktiva-bysender <br />
              echo '#List Domain Relay' {">"} /opt/zimbra/conf/aktiva-bysender <br />
              {domains &&
                domains.map(({ name }) => {
                  return `echo '@${name} smtp:${accessDomain}:587' >> /opt/zimbra/conf/aktiva-bysender\n`;
                })}
              postmap /opt/zimbra/conf/aktiva-bysender <br />
              postfix reload <br />
            </code>
          </pre>
        </>
      ),
    },
    {
      key: 4,
      title: "Konfigurasi pada Email Server Postfix",
      element: (
        <>
          <p className="mb-3">1. Buat file sasl_password</p>
          <pre>
            <code>vi /etc/postfix/sasl_passwd</code>
          </pre>
          <p className="mb-3">2. Isi dengan isian berikut</p>
          <pre>
            <code>
              [{accessDomain}]:587 {userRelay}:{passwordRelay}
            </code>
          </pre>
          <p className="mb-3">3. Simpan konfigurasi dan lakukan postmap</p>
          <pre>
            <code>postmap /etc/postfix/sasl_passwd</code>
          </pre>
          <p className="mb-3">4. Berikan hak akses dan ubah permission</p>
          <pre>
            <code>
              chown root:root /etc/postfix/sasl_passwd /etc/postfix/sasl_passwd.db <br />
              chmod 0600 /etc/postfix/sasl_passwd /etc/postfix/sasl_passwd.db <br />
            </code>
          </pre>
          <p className="mb-3">5. Salin file main.cf dan lakukan perubahan seperti berikut</p>
          <pre>
            <code>cp /etc/postfix/main.cf /etc/postfix/main.cf.backup</code>
          </pre>
          <p className="mb-3">6. Sesuaikan isian pada relayhost</p>
          <pre>
            <code>
              smtp_sasl_auth_enable = yes <br />
              smtp_sasl_security_options = noanonymous <br />
              smtp_sasl_password_maps = hash:/etc/postfix/sasl_passwd <br />
              smtp_use_tls = yes <br />
              smtp_tls_CAfile = /etc/ssl/certs/ca-certificates.crt <br />
            </code>
          </pre>
          <p className="mb-3">
            7. Setelah menyesuaikan isian relayhost, silakan jalankan perintah-perintah berikut
          </p>
          <pre>
            <code>
              postconf -e sender_dependent_default_transport_maps=hash:/etc/postfix/aktiva-bysender{" "}
              <br />
              echo "#List Domain Relay" {">"} /etc/postfix/aktiva-bysender <br />
              {domains &&
                domains.map(({ name }) => {
                  return `echo "@${name} smtp:[${accessDomain}]:587" >> /etc/postfix/aktiva-bysender\n`;
                })}
              postmap /etc/postfix/aktiva-bysender <br />
              postfix reload <br />
            </code>
          </pre>
        </>
      ),
    },
    {
      key: 5,
      title: "Konfigurasi pada Email Server Server MDaemon",
      element: (
        <>
          <p className="mb-0">1. Pilih Setup menu, dan click Server Setting</p>
          <p className="mb-0">2. Pada bagian Ports, pastikan port outgoing menggunakan port 587</p>
          <p className="mb-0">
            3. Pada bagian Delivery configuration, isi konfigurasi server, username dan password
            yang diberikan
          </p>
          <p className="mb-0">
            4. Jika konfigurasi hendak dilakukan perdomain, dapat masuk pada menu Setup | Domain
            Manager | (nama domain) | Smart Host.
          </p>
        </>
      ),
    },
    {
      key: 6,
      title: "Konfigurasi pada Email Server Server Sendmail",
      element: (
        <>
          <p className="mb-3">1. Edit file sendmail.mc</p>
          <pre>
            <code>$ vi /etc/mail/sendmail.mc</code>
          </pre>
          <p className="mb-3">
            2. Lalu tambahkan perintah dibawah ini, dibawah baris dnl # Default Mailer setup
          </p>
          <pre>
            <code>
              <p className="mb-0">define(`SMART_HOST`, `{accessDomain}`)dnl</p>
              <p className="mb-0">FEATURE(`authinfo`)dnl</p>
              <p className="mb-0">define(`RELAY_MAILER_ARGS`, `TCP $h 587`)</p>
            </code>
          </pre>
          <p className="mb-3">3. Buat file authinfo</p>
          <pre>
            <code>$ vi /etc/mail/authinfo</code>
          </pre>
          <p className="mb-3">4. Isi dengan script berikut</p>
          <pre>
            <code>
              AuthInfo:{accessDomain} "U:{userRelay}" "I:{userRelay}" "P:" "M:LOGIN PLAIN"
            </code>
          </pre>
          <p className="mb-3">5. Update Konfigurasi file sendmail</p>
          <pre>
            <code>
              <p className="mb-0">$ cd /etc/mail</p>
              <p className="mb-0">$ m4 sendmail.mc {">"} sendmail.cf</p>
              <p className="mb-0">$ makemap hash authinfo {"<"} authinfo</p>
            </code>
          </pre>
          <p className="mb-3">6. Restart service sendmail</p>
          <pre>
            <code>$ /etc/init.d/sendmail restart</code>
          </pre>
        </>
      ),
    },
    {
      key: 7,
      title: "Konfigurasi pada Aplikasi PHP",
      element: (
        <>
          <p className="mb-3">
            1. Download PHPMailer{" "}
            <a href="https://github.com/PHPMailer/PHPMailer" target="_blank" rel="noreferrer">
              disini
            </a>
          </p>
          <p className="mb-3">2. Buat file PHP dengan isian berikut</p>
          <pre>
            <code>
              <p className="mb-0">{`<?php `}</p>
              <p className="mb-0">{`    require_once "PHPMailer/PHPMailerAutoload.php";`}</p>
              <p className="mb-0">{`    $mail = new PHPMailer;`}</p>
              <br />
              <p className="mb-0">{`    //Enable SMTP debugging.`}</p>
              <p className="mb-0">{`    $mail->SMTPDebug = 3;`}</p>
              <br />
              <p className="mb-0">{`    //Set PHPMailer to use SMTP.`}</p>
              <p className="mb-0">{`    $mail->isSMTP(); `}</p>
              <p className="mb-0">{`    $mail->Host = "${accessDomain}";`}</p>
              <br />
              <p className="mb-0">{`    //Set this to true if SMTP host requires authentication to send email`}</p>
              <p className="mb-0">{`    $mail->SMTPAuth = true;`}</p>
              <br />
              <p className="mb-0">{`    //Diisikan dengan username dan password SMTP yang didapatkan ketika berlangganan ${process.env.REACT_APP_NAME}`}</p>
              <p className="mb-0">{`    $mail->Username = "${userRelay}"; `}</p>
              <p className="mb-0">{`    $mail->Password = "${passwordRelay}";`}</p>
              <br />
              <p className="mb-0">{`    //If SMTP requires TLS encryption then set it`}</p>
              <p className="mb-0">{`    $mail->SMTPSecure = "tls";`}</p>
              <p className="mb-0">{`    //Set TCP port to connect to `}</p>
              <p className="mb-0">{`    $mail->Port = 587; `}</p>
              <br />
              <p className="mb-0">{`    //Diisikan dengan Alamat email pengirim dan Nama Pengirim`}</p>
              <p className="mb-0">{`    $mail->From = "yourname@yourcompany.com";`}</p>
              <p className="mb-0">{`    $mail->FromName = "Your Name";`}</p>
              <br />
              <p className="mb-0">{`    //Diisikan dengan Alamat email penerima dan Nama Penerima`}</p>
              <p className="mb-0">{`    $mail->addAddress("recipient@domain.com", "Recipient Name");`}</p>
              <p className="mb-0">{`    $mail->isHTML(true);`}</p>
              <br />
              <p className="mb-0">{`    //Subject dan Content Email (sesuaikan)`}</p>
              <p className="mb-0">{`    $mail->Subject = "Your Email Subject";`}</p>
              <p className="mb-0">{`        $mail->Body = "<i>Your Email Content</i>";`}</p>
              <p className="mb-0">{`        $mail->AltBody = "This is your email content";`}</p>
              <p className="mb-0">{`    if(!$mail->send()) `}</p>
              <p className="mb-0">{`    {`}</p>
              <p className="mb-0">{`        echo "Mailer Error: " . $mail->ErrorInfo;`}</p>
              <p className="mb-0">{`    } else `}</p>
              <p className="mb-0">{`    {`}</p>
              <p className="mb-0">{`        echo "Message has been sent successfully";`}</p>
              <p className="mb-0">{`    }`}</p>
              <p className="mb-0">{`?>`}</p>
            </code>
          </pre>
          <p className="mb-3">3. Test pengiriman email dengan menjalankan file tersebut</p>
        </>
      ),
    },
    {
      key: 1,
      title: "Konfigurasi pada Email Zextras Carbonio 24.x",
      element: (
        <>
          <p className="mb-3">1. Remote server Zextras Carbonio menggunakan SSH dan akses ke console</p>
          <p className="mb-3">2. Login sebagai user root dari sistem operasi Linux</p>
          <p className="mb-3">3. Jalankan perintah berikut untuk login sebagai user Zextras Carbonio</p>
          <pre>
            <code>su - zextras</code>
          </pre>
          <p className="mb-3">
            4. Ketikkan atau copy perintah berikut dan paste pada console email server Zextras Carbonio anda
          </p>
          <pre>
            <code>
              echo '{accessDomain} {userRelay}:{passwordRelay}' {">"}{" "}
              /opt/zextras/conf/relay_password <br />
              postmap /opt/zextras/conf/relay_password <br />
              postmap -q {accessDomain} /opt/zextras/conf/relay_password <br />
              zmprov ms `zmhostname` zimbraMtaSmtpSaslPasswordMaps
              lmdb:/opt/zextras/conf/relay_password <br />
              zmprov ms `zmhostname` zimbraMtaSmtpSaslAuthEnable yes <br />
              zmprov ms `zmhostname` zimbraMtaSmtpCnameOverridesServername no <br />
              zmprov ms `zmhostname` zimbraMtaSmtpTlsSecurityLevel may <br />
              zmprov ms `zmhostname` zimbraMtaSmtpSaslSecurityOptions noanonymous <br />
              postconf -e sender_dependent_default_transport_maps=lmdb:/opt/zextras/conf/aktiva-bysender <br />
              echo '#List Domain Relay' {">"} /opt/zextras/conf/aktiva-bysender <br />
              {domains &&
                domains.map(({ name }) => {
                  return `echo '@${name} smtp:${accessDomain}:587' >> /opt/zextras/conf/aktiva-bysender\n`;
                })}
              postmap /opt/zextras/conf/aktiva-bysender <br />
              postfix reload <br />
            </code>
          </pre>
        </>
      ),
    },
  ];

  return list.map((item) => (
    <li key={item.key}>
      <Button type="link" onClick={() => showDrawer(item)}>
        {item.title}
      </Button>
    </li>
  ));
};

export default ListDokumentasi;
