import { Button, Card, Tabs, Form } from "antd";
import { getFakturUser, getListInvoicesUser } from "api";
import billing from "assets/images/billing.svg";
import npwpImage from "assets/images/npwp.svg";
import ApiError from "components/error/ApiError";
import {
  ICCreditCardOutlined,
  ICFileDoneOutlined,
  ICUploadOutlined,
} from "components/list/ListIcon";
import ModalLengkapiNpwp from "components/popups/modal/ModalLengkapiNpwp";
import TableFaktur from "components/table/TableFaktur";
import TableInvoice from "components/table/TableInvoice";
import TableTitle from "components/tableSendiri/TableTitle";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFakturPajak } from "redux/slices/fakturPajak";
import { setInvoice } from "redux/slices/invoice";

const FakturPajakEmpty = ({ data, visible, setVisible }) => {
  const fillNpwp = (
    <div className="flex flex-col items-center tagihan-img">
      <img src={npwpImage} alt="NPWP not available" />
      <h5 className="text-base font-medium">Anda belum melengkapi data NPWP</h5>
      <p className="text-secondary text-center mb-4">
        Lengkapi data NPWP Perusahaan anda agar kami dapat menerbitkan Faktur Pajak.
      </p>
      <Button type="primary" icon={<ICUploadOutlined />} onClick={() => setVisible("fillNpwp")}>
        Lengkapi NPWP
      </Button>
    </div>
  );

  const emptyData = (
    <div className="flex flex-col items-center tagihan-img mb-10">
      <img src={billing} alt="Invoice not available" />
      <h5 className="text-base font-medium">Belum ada data faktur pajak</h5>
      <p className="text-secondary text-center mb-0">
        Data faktur pajak belum tersedia, pastikan anda sudah berlangganan untuk melihat faktur
        pajak.
      </p>
    </div>
  );

  const tagihanError = (
    <div className="flex flex-col items-center tagihan-img">
      <ApiError />
    </div>
  );

  let views;
  if (data.msg === "Please fill your NPWP") views = fillNpwp;
  else if (data && data.table.count === 0) views = emptyData;
  else if (data.code === 500) views = tagihanError;

  return views;
};

const InvoiceSection = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [disable, setDisable] = useState(false);
  const [loadingInvoice, setLoadingInvoice] = useState(false);
  const [loadingFaktur, setLoadingFaktur] = useState(false);
  const [action, setAction] = useState("NPWP 15");
  const [filterInvoice, setFilterInvoice] = useState({
    page: 1,
    limit: "10",
  });
  const [filterFaktur, setFilterFaktur] = useState({
    page: 1,
    limit: "10",
  });
  const faktur = useSelector(({ fakturPajak }) => fakturPajak.value);
  const invoice = useSelector(({ invoice }) => invoice.value);
  const { table: tableInvoice } = invoice;
  const { count, code: codeInvoice } = invoice;
  const { table: tblFaktur } = faktur;
  const [visible, setVisible] = useState(false);
  const [activeTabs, setActiveTabs] = useState("1");

  const handleChange = (key) => setActiveTabs(key);

  const InvoiceEmpty = () => {
    const empty = (
      <>
        <TableTitle disable />
        <div className="flex flex-col items-center tagihan-img mb-10">
          <img src={billing} alt="Invoice not available" />
          <h5 className="text-base font-medium">Belum ada data pembayaran</h5>
          <p className="text-secondary text-center mb-0">
            Data pembayaran invoice belum tersedia, pastikan anda sudah berlangganan untuk melihat
            invoice pembayaran.
          </p>
        </div>
      </>
    );

    const invoiceError = (
      <>
        <TableTitle disable />
        <ApiError />
      </>
    );

    const renderEmptyInvoice = count === 0 ? empty : codeInvoice === 500 ? invoiceError : "";

    return renderEmptyInvoice;
  };

  const getDataInvoice = useCallback(async () => {
    setLoadingInvoice(true);
    const { data: table } = await getListInvoicesUser(filterInvoice);
    dispatch(setInvoice({ table }));
    setTimeout(setLoadingInvoice, 1000, false);
  }, [dispatch, filterInvoice]);

  const getDataFaktur = useCallback(async () => {
    setLoadingFaktur(true);
    const { data: table, code, msg } = await getFakturUser(filterFaktur);
    dispatch(setFakturPajak({ table, code, msg }));
    setTimeout(setLoadingFaktur, 1000, false);
  }, [dispatch, filterFaktur]);

  useEffect(() => {
    getDataFaktur();
    getDataInvoice();
  }, [getDataFaktur, getDataInvoice]);

  const dataFaktur = { table: tblFaktur, loading: loadingFaktur };
  const dataInvoice = { table: tableInvoice, loading: loadingInvoice };

  return (
    <Card>
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTabs}
        className="billing-tab"
        onChange={handleChange}
        items={[
          {
            label: (
              <span>
                <ICCreditCardOutlined />
                Invoice Pembayaran
              </span>
            ),
            key: "1",
            children: (
              <div>
                <TableInvoice
                  title={{
                    title: "Invoice Pembayaran",
                    desc: "History invoice dari pembayaran yang sudah dilakukan",
                    pageLimit: {
                      currentLimit: filterInvoice.limit,
                      onSelectLimit: (limit) => {
                        setFilterInvoice({ ...filterInvoice, limit, page: 1 });
                      },
                      disable: dataInvoice.table?.count < 10,
                    },
                  }}
                  role="user"
                  data={dataInvoice}
                  filter={filterInvoice}
                  setFilter={setFilterInvoice}
                  emptyState={{
                    title: "Belum ada data pembayaran",
                    image: billing,
                    message:
                      "Data pembayaran invoice belum tersedia, pastikan anda sudah berlangganan untuk melihat invoice pembayaran.",
                  }}
                />
                <InvoiceEmpty />
              </div>
            ),
          },
          {
            label: (
              <span>
                <ICFileDoneOutlined />
                Faktur Pajak
              </span>
            ),
            key: "2",
            children: (
              <div>
                <TableFaktur
                  title={{
                    title: "Faktur Pajak",
                    desc: "Faktur pajak atas pembayaran invoice yang dilakukan",
                    pageLimit: {
                      currentLimit: filterFaktur.limit,
                      onSelectLimit: (limit) => {
                        setFilterFaktur({ ...filterFaktur, limit, page: 1 });
                      },
                      disable: dataFaktur.table?.count < 10,
                    },
                  }}
                  emptyComponent={
                    <FakturPajakEmpty visible={visible} setVisible={setVisible} data={faktur} />
                  }
                  role="user"
                  data={dataFaktur}
                  filter={filterFaktur}
                  setFilter={setFilterFaktur}
                />
                <ModalLengkapiNpwp
                  isOpen={visible}
                  setOpen={setVisible}
                  form={form}
                  disable={disable}
                  setDisable={setDisable}
                  action={action}
                  setAction={setAction}
                  getDataFaktur={getDataFaktur}
                />
              </div>
            ),
          },
        ]}
      />
    </Card>
  );
};

export default InvoiceSection;
