import { getPackageAll } from "api";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPacketList } from "redux/slices/packet";

const useGetPacket = () => {
  const packetList = useSelector(({ packetList }) => packetList.value);
  const dispatch = useDispatch();

  const getData = useCallback(async () => {
    const { data } = await getPackageAll();
    dispatch(setPacketList(data));
  }, [dispatch]);

  useEffect(() => {
    if (!packetList) getData();
  }, [packetList, getData]);
};

export default useGetPacket;
