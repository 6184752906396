import { Button, Card } from "antd";
import { activateDomain } from "api";
import { EmptyWrapper } from "components/empty/EmptyComponent";
import ListDomains from "components/list/ListDomains";
import { ICPlusOutlined } from "components/list/ListIcon";
import ModalAddDomain from "components/popups/modal/ModalAddDomain";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import useGetData from "pages/dashboard/user/setup/useGetData";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import useGetDomain from "utils/hooks/useGetDomain";

const BtnAddDomain = ({ setOpen, disabled }) => {
  return (
    <Button
      type="primary"
      icon={<ICPlusOutlined />}
      disabled={disabled}
      onClick={() => setOpen(true)}>
      Tambah Domain
    </Button>
  );
};

const CardRegisteredDomain = ({ page = "setup", loading, getOthers }) => {
  useGetData();
  useGetDomain();
  const [showModal, setShowModal] = useState(false);

  const user = useSelector(({ userData }) => userData.value);
  const { userRelay } = useSelector(({ setupLayanan }) => setupLayanan.value);
  const listDomain = useSelector(({ listDomain }) => listDomain.value);
  const { statusRelay } = useSelector(({ setupLayanan }) => setupLayanan.value);

  const hActivationDomain = async ({ domainUuid, domainName }) => {
    ModalSuccess({
      title: `Domain ${domainName} sedang diaktivasi`,
      customMessage: (
        <p className="mb-0">
          Domain <span className="font-medium">{domainName}</span> telah berhasil ditambahkan pada
          sistem kami. Saat ini kami sedang melakukan aktivasi domain anda. Tunggu proses aktivasi
          dan <span className="font-medium">jangan tutup browser anda</span>.
        </p>
      ),
      width: 500,
    });
    const { code, status } = await activateDomain({ domainUuid: domainUuid });
    if (code === 200 && status === "success") getOthers();
  };

  const handle = { hActivationDomain, page, statusRelay, getOthers, user };

  useEffect(() => {
    const interval = setInterval(() => {
      const hasProcessingDomain =
        listDomain && listDomain.filter((item) => item.status === "processing").length;
      if (page === "setup" && hasProcessingDomain > 0) getOthers();
    }, 5000);
    return () => clearInterval(interval);
  }, [listDomain, page, getOthers]);

  if (!listDomain) return <Skeleton className="w-full" height={348} />;

  return (
    <Card
      loading={loading === "domainSection"}
      title="Domain Terdaftar"
      extra={
        <BtnAddDomain
          setOpen={setShowModal}
          disabled={(userRelay || listDomain.length) && user.role !== "billing" ? false : true}
        />
      }
      className="w-full h-auto">
      {listDomain.length > 0 ? (
        listDomain.map((x) => <ListDomains {...x} {...handle} key={x.domainUuid} />)
      ) : (
        <EmptyWrapper
          imgMargin="mb-3"
          message={`Silakan melakukan aktivasi domain agar domain anda terdaftar dan dapat melakukan
            pengiriman menggunakan ${process.env.REACT_APP_NAME}.`}
          title="Domain Anda Belum di Aktivasi"
        />
      )}
      <ModalAddDomain isOpen={showModal} setOpen={setShowModal} getData={getOthers} />
    </Card>
  );
};

export default CardRegisteredDomain;
