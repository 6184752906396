import { Card, Col, Divider, Row } from "antd";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import TableDocumentation from "components/table/TableDocumentation";
import React from "react";
import useDocumentTitle from "utils/useDocumentTitle";
import "./kelolaDokumentasi.less";

const DocumentationSummary = () => {
  return (
    <Card title="Dokumentasi Summary" className="documentation-summary__wrapper">
      <Row gutter={[24, 0]} align="middle">
        <Col lg={12} md={12} sm={24} xl={6}>
          <Card className="card-display-package" bordered={false}>
            <h5 className="mb-0 text-3xl">14</h5>
            <p className="mb-0 text-info">Published</p>
            <Divider className="my-2 mb-0" />
          </Card>
        </Col>
        <Col lg={12} md={12} sm={24} xl={6}>
          <Card className="card-display-package" bordered={false}>
            <h5 className="mb-0 text-3xl">2</h5>
            <p className="mb-0 text-danger">Draft</p>
            <Divider className="my-2 mb-0" />
          </Card>
        </Col>
        <Col lg={12} md={12} sm={24} xl={6}>
          <Card className="card-display-package" bordered={false}>
            <h5 className="mb-0 text-3xl">5</h5>
            <p className="mb-0 text-secondary">Service Category</p>
            <Divider className="my-2 mb-0" />
          </Card>
        </Col>
        <Col lg={12} md={12} sm={24} xl={6}>
          <Card className="card-display-package" bordered={false}>
            <h5 className="mb-0 text-3xl">2</h5>
            <p className="mb-0 text-secondary">Post Category</p>
            <Divider className="my-2 mb-0" />
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

const KelolaDokumentasi = () => {
  useDocumentTitle("Kelola Dokumentasi");
  return (
    <div className="documentation-admin__wrapper">
      <DashboardHeader title="Kelola Dokumentasi" />
      <div className="flex flex-col gap-y-6 px-6 py-4">
        <DocumentationSummary />
        <div className="documentation-table__wrapper px-6 bg-white pb-4 pt-2">
          <TableDocumentation />
        </div>
      </div>
    </div>
  );
};

export default KelolaDokumentasi;
