import { Tabs } from "antd";
import { getListActivityAdmin, getListAdmin } from "api";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import { ICFileDoneOutlined, ICRobotOutlined } from "components/list/ListIcon";
import ModalKelolaAdmin from "components/popups/modal/ModalKelolaAdmin";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setListAcvitiyAdmin } from "redux/slices/admin/listActivityAdmin";
import { setListAdmin } from "redux/slices/admin/listAdmin";
import { setListAdminShort } from "redux/slices/admin/listAdminShort";
import useDocumentTitle from "utils/useDocumentTitle";
import "./kelolaAdmin.less";
import TableListAdmin from "./TableListAdmin";
import TableLogActivity from "./TableLogActivity";

const KelolaAdmin = () => {
  useDocumentTitle("Kelola Admin");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [activeTabs, setActiveTabs] = useState("1");
  const [visible, setVisible] = useState(false);
  const [filterList, setFilterList] = useState({
    page: 1,
    limit: "10",
  });
  const [filterActivity, setFilterActivity] = useState({
    page: 1,
    limit: 10,
  });

  const handleChange = (key) => setActiveTabs(key);
  const handleCancel = () => setVisible(false);

  const handleOk = () => setVisible(false);

  const user = useSelector(({ userData }) => userData.value);
  const listAdminShort = useSelector(({ listAdminShort }) => listAdminShort.value);

  const getListAdminLong = useCallback(async () => {
    setLoading(true);
    const { data } = await getListAdmin(filterList);
    dispatch(setListAdmin(data));
    setLoading(false);
  }, [dispatch, filterList]);

  useEffect(() => {
    let isCancelled = false;
    if (filterList && !isCancelled) {
      getListAdminLong();
    }
    return () => {
      isCancelled = true;
    };
  }, [filterList, getListAdminLong]);

  const getListActivity = useCallback(async () => {
    setLoading(true);
    const { data } = await getListActivityAdmin(filterActivity);
    dispatch(setListAcvitiyAdmin(data));
    setLoading(false);
  }, [dispatch, filterActivity]);

  useEffect(() => {
    let isCancelled = false;
    if (filterActivity && !isCancelled) getListActivity();
    return () => {
      isCancelled = true;
    };
  }, [filterActivity, getListActivity]);

  const getListAdminShort = useCallback(async () => {
    const { data } = await getListAdmin({ short: true });
    dispatch(setListAdminShort(data));
  }, [dispatch]);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && !listAdminShort) {
      getListAdminShort();
    }
    return () => {
      isCancelled = true;
    };
  }, [getListAdminShort, listAdminShort]);

  return (
    <div className="kelola-admin">
      <DashboardHeader title="Kelola Admin" />
      <div className="px-6 py-4">
        <div className="tabs__wrapper">
          <Tabs
            activeKey={activeTabs}
            defaultActiveKey="1"
            className="activity-log-admin"
            onChange={handleChange}
            items={[
              {
                label: (
                  <span>
                    <ICRobotOutlined />
                    Daftar Admin
                  </span>
                ),
                key: "1",
                children: (
                  <TableListAdmin
                    setOpen={setVisible}
                    user={user}
                    filter={filterList}
                    setFilter={setFilterList}
                    getListAdmin={getListAdminLong}
                    loading={loading}
                  />
                ),
              },
              {
                label: (
                  <span>
                    <ICFileDoneOutlined />
                    Log Aktivitas Admin
                  </span>
                ),
                key: "2",
                children: (
                  <TableLogActivity
                    filter={filterActivity}
                    setFilter={setFilterActivity}
                    loading={loading}
                    getData={getListActivity}
                  />
                ),
              },
            ]}
          />
        </div>
        <ModalKelolaAdmin
          data={visible.record}
          visible={visible}
          onCancel={handleCancel}
          onOk={handleOk}
          action={visible.mode}
          user={user}
          getUpdate={getListAdminLong}
        />
      </div>
    </div>
  );
};

export default KelolaAdmin;
