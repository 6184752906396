import { Card, Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import CardEmailStatus from "components/box-card/CardEmailStatus";

const EmailStatusSummary = () => {
  const sentSummary = useSelector(({ emailSummary }) => emailSummary.value);

  if (!sentSummary)
    return (
      <Row gutter={[24, 24]}>
        <Col lg={12} md={12} sm={12} xl={6}>
          <Card loading={!sentSummary} />
        </Col>
        <Col lg={12} md={12} sm={12} xl={6}>
          <Card loading={!sentSummary} />
        </Col>
        <Col lg={12} md={12} sm={12} xl={6}>
          <Card loading={!sentSummary} />
        </Col>
        <Col lg={12} md={12} sm={12} xl={6}>
          <Card loading={!sentSummary} />
        </Col>
      </Row>
    );

  return (
    <Row gutter={[24, 24]}>
      {sentSummary &&
        sentSummary.emailSummary.map((item) => (
          <CardEmailStatus
            title={item.titleDelivered}
            number={item.delivered}
            percentSent={item.deliveredPercentage}
            key={item.titleDelivered}
          />
        ))}
    </Row>
  );
};

export default EmailStatusSummary;
