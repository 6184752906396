import Spinner from "components/Spinner/Spinner";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { getCookieToken } from "./helpers";

const CheckAdminConfig = () => {
  const navigate = useNavigate();

  const user = useSelector(({ userData }) => userData.value);
  const token = getCookieToken();

  useEffect(() => {
    if (!token && !user) window.location.href = "/admin/login";
    else navigate("/admin/main");
  }, [token, user, navigate]);

  return user ? <Outlet /> : <Spinner full />;
};

export default CheckAdminConfig;
