import { Card, ConfigProvider, Table, Tag } from "antd";
import { EmptyWrapper } from "components/empty/EmptyComponent";
import DrawerReport from "components/popups/drawer/DrawerReport";
import TableTitle from "components/tableSendiri/TableTitle";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const TableReport = ({ className, filter, setFilter, emptyState, title, data }) => {
  const [available, setAvailable] = useState(6);
  const [visible, setVisible] = useState(false);
  const [dataDrawer, setData] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);

  const { isAdmin } = useSelector(({ userData }) => userData.value);

  const showModal = (record) => {
    setVisible(true);
    setData(record);
  };

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  // table user
  const colUser = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 120,
    },
    {
      title: "Domain",
      dataIndex: "domainName",
      key: "domainName",
      width: width > 1200 ? 420 : 120,
      ellipsis: true,
    },
    {
      title: "Mail Delivered",
      dataIndex: "countDelivered",
      key: "countDelivered",
      align: "right",
      render: (text) => <p className="mb-0 col-maildev">{text.toLocaleString("en-us")} email</p>,
    },
    {
      title: "Mail Failed",
      key: "countFailed",
      dataIndex: "countFailed",
      align: "right",
      render: (text) => <p className="mb-0 col-mailfail">{text.toLocaleString("en-us")} email</p>,
    },
    {
      title: "Total Email",
      key: "countSent",
      dataIndex: "countSent",
      align: "right",
      render: (text) => <p className="mb-0">{text.toLocaleString("en-us")} email</p>,
    },
    {
      key: "action",
      dataIndex: "action",
      width: 88,
      align: "center",
      render: (_, record) => (
        <p className="mb-0 col-detail-action" onClick={() => showModal(record)}>
          Details
        </p>
      ),
    },
  ];

  // table admin
  const colAdmin = [
    {
      title: "Nama Perusahaan",
      dataIndex: "company_name",
      key: "company_name",
      ...(width < 992 && width > 767 ? { fixed: "left" } : {}),
      width: width > 992 ? 420 : 200,
      render: (value) => {
        if (value.includes("rm::"))
          return (
            <div className="flex items-center gap-1">
              <p className="text-secondary mb-0">{value.slice(4)}</p>
              <Tag color="default" className="text-secondary mr-0">
                Deleted
              </Tag>
            </div>
          );
        return <p className="mb-0">{value}</p>;
      },
    },
    {
      title: "Langganan",
      dataIndex: "subscription_status",
      key: "subscription_status",
      width: 102,
      align: "center",
      render: (text) => (
        <Tag color={text === "active" ? "blue" : "red"}>
          {text === "active" ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Mail Delivered",
      dataIndex: "current_total_delivered",
      key: "current_total_delivered",
      width: width > 992 ? 163 : 98.5,
      render: (text) => <p className="mb-0 col-maildev">{text.toLocaleString("en-us")} email</p>,
      align: "right",
    },
    {
      title: "Mail Failed",
      key: "current_total_failed",
      dataIndex: "current_total_failed",
      width: width > 992 ? 163 : 98.5,
      render: (text) => <p className="mb-0 col-mailfail">{text.toLocaleString("en-us")} email</p>,
      align: "right",
    },
    {
      title: "Mail Usage",
      key: "current_total_sent",
      dataIndex: "current_total_sent",
      width: width > 992 ? 163 : 98.5,
      render: (_, record) => (
        <div className="flex flex-col text-right">
          <p className="mb-0">{record.current_total_sent.toLocaleString("en-US")} email</p>
          <p className="text-secondary text-xs mb-0">
            /{record.current_quota.toLocaleString("en-US")} email
          </p>
        </div>
      ),
      align: "right",
    },
    {
      title: "Total Sending",
      key: "total_sent",
      dataIndex: "total_sent",
      width: width > 992 ? 163 : 98.5,
      render: (text) => <p className="mb-0">{text.toLocaleString("en-us")} email</p>,
      align: "right",
    },
  ];

  const onClose = () => {
    setVisible(false);
    setData(false);
  };

  const changePage = (page) => {
    setFilter({ ...filter, limit: filter.limit, page: page });
  };

  const breakpoint = 992;

  const renderColumn = isAdmin ? colAdmin : colUser;
  const renderData = isAdmin ? data.table?.rows : data?.table.rows;

  if (!data.table)
    return <Card loading title={isAdmin ? "Report Penggunaan" : "Email Delivery Report"} />;

  return (
    <div className={className}>
      <ConfigProvider renderEmpty={() => <EmptyWrapper {...emptyState} />}>
        <Table
          loading={data?.loading}
          columns={renderColumn}
          rowKey={(record) =>
            isAdmin ? record.company_uuid : `${record.domainName} ${record.date}`
          }
          title={() => <TableTitle {...title} />}
          dataSource={renderData}
          {...(width < breakpoint ? { scroll: { x: 661 } } : {})}
          pagination={{
            pageSize: filter.limit,
            size: "small",
            current: filter.page,
            hideOnSinglePage: true,
            onChange: (params) => {
              changePage(params);
            },
            showSizeChanger: false,
            total: data.table.count,
          }}
          rowClassName="pointer"
          onRow={(record, _) => {
            return {
              onClick: () => {
                if (isAdmin) {
                  setVisible(true);
                  setData(record);
                }
              },
            };
          }}
        />
      </ConfigProvider>
      <DrawerReport
        onClose={onClose}
        visible={visible}
        data={dataDrawer}
        available={available}
        isAdmin={isAdmin}
      />
    </div>
  );
};

export default TableReport;
