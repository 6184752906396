import { Button, Card, ConfigProvider, Table, Tag } from "antd";
import Tooltip from "antd/es/tooltip";
import { EmptyWrapper } from "components/empty/EmptyComponent";
import { ICDeleteOutlined, ICEditOutlined } from "components/list/ListIcon";
import TableTitle from "components/tableSendiri/TableTitle";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const ListContactClients = ({ title, filter, setFilter, onDelete, setOpen, data, emptyState }) => {
  const dispatch = useDispatch();

  const colDaftarKontak = [
    {
      title: "Nama Kontak",
      dataIndex: "contact_name",
      key: "contact_name",
      width: 200,
      fixed: "left",
    },
    {
      title: "Email",
      dataIndex: "contact_email",
      key: "contact_email",
      width: 228,
    },
    {
      title: "Nama Perusahaan",
      dataIndex: "contact_company",
      key: "contact_company",
      width: 240,
    },
    {
      title: "Telepon",
      dataIndex: "contact_phone",
      key: "contact_phone",
      width: 228,
    },
    {
      title: "Tags",
      dataIndex: "contact_role",
      key: "contact_role",
      width: 160,
      render: (text) => (
        <>
          {text === "primary" && (
            <Tag color="blue" className="blue-6">
              primary
            </Tag>
          )}
          {text === "billing" && (
            <Tag color="warning" className="text-warning">
              billing
            </Tag>
          )}
          {text === "technical" && (
            <Tag color="red" className="red-6">
              technical
            </Tag>
          )}
          {text === "techbill" && (
            <>
              <Tag color="warning" className="text-warning">
                billing
              </Tag>
              <Tag color="red" className="red-6">
                technical
              </Tag>
            </>
          )}
        </>
      ),
    },
    {
      dataIndex: "action",
      key: "action",
      width: 120,
      render: (_, record) => (
        <div className="flex items-center gap-x-1">
          <Tooltip title="Edit Kontak">
            <Button
              icon={<ICEditOutlined />}
              onClick={() =>
                setOpen({
                  contactName: record.contact_name,
                  contactEmail: record.contact_email,
                  contactPhone: record.contact_phone,
                  contactRole: record.contact_role,
                  contactUuid: record.contact_uuid,
                })
              }
            />
          </Tooltip>
          <Tooltip title="Hapus Kontak">
            <Button
              danger
              icon={<ICDeleteOutlined />}
              onClick={() => onDelete(record)}
              disabled={record?.contact_role === "primary" ? true : false}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const changePage = (pages) => {
    dispatch(setFilter({ ...filter, page: pages }));
  };

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1200;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  if (!data.table)
    return (
      <Card
        loading
        title="Daftar Kontak"
        desc={`List kontak perusahaan pengguna layanan ${process.env.REACT_APP_NAME}`}
      />
    );
  return (
    <ConfigProvider renderEmpty={() => <EmptyWrapper {...emptyState} />}>
      <Table
        loading={data.loading}
        dataSource={data.table.rows}
        columns={colDaftarKontak}
        rowKey={(record) => record.contact_uuid}
        className="activity-log-admin-table"
        showSorterTooltip={false}
        title={() => <TableTitle {...title} />}
        pagination={{
          pageSize: filter.limit,
          current: filter.page,
          hideOnSinglePage: true,
          onChange: (params) => {
            changePage(params);
          },
          size: "small",
          total: data?.table.count,
          showSizeChanger: false,
        }}
        {...(width < breakpoint ? { scroll: { x: 1024 } } : {})}
      />
    </ConfigProvider>
  );
};

export default ListContactClients;
