import { Col, Row } from "antd";
import { changePhotoAdmin, getAdmin } from "api";
import CardProfileNew from "components/box-card/CardProfileNew";
import Spinner from "components/Spinner/Spinner";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "redux/slices/user";
import InformasiAdmin from "./InformasiAdmin";
import "./profile.less";

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(({ userData }) => userData.value);

  const getUser = async () => {
    const { data } = await getAdmin({ uuid: user.uuid });
    dispatch(setUserData(data));
    setLoading(false);
  };

  const changeImage = async (option) => {
    setLoading("cardProfile");
    const { file } = option;
    const { status } = await changePhotoAdmin({ photo: file });
    if (status === "success") getUser();
  };
  if (!user) return <Spinner full />;

  return (
    <div className="profile-admin">
      <div className="px-6 py-4">
        <Row gutter={[24, 0]}>
          <Col xl={6} lg={9} md={9} sm={24} xs={24}>
            <div className="profile-picture mb-6">
              <CardProfileNew
                photo={user.profilePicture}
                createdAt={user.createdAt}
                loading={loading}
                infoProfile={user}
                changeImage={changeImage}
              />
            </div>
          </Col>
          <Col xl={18} lg={15} md={15} sm={24} xs={24}>
            <InformasiAdmin />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Profile;
