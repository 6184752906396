import { createSlice } from "@reduxjs/toolkit";

export const listAllCompany = createSlice({
  name: "listAllCompany",
  initialState: {
    value: false,
  },
  reducers: {
    setListAllCompany: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setListAllCompany } = listAllCompany.actions;
export default listAllCompany.reducer;
