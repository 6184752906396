import { Badge, Button, ConfigProvider, Descriptions, Drawer, Space, Table } from "antd";
import { EmptyWrapper } from "components/empty/EmptyComponent";
import { ICCloseOutlined, ICLoadingOutlined } from "components/list/ListIcon";
import TableTitle from "components/tableSendiri/TableTitle";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { capitalizeFirstLetter, splitDate } from "utils/helpers";

const TableLog = ({ filter, setFilter, hitApi, loading, role, emptyState }) => {
  const headerCSV = [
    { label: "Date", key: "date" },
    { label: "From", key: "from" },
    { label: "Recipient", key: "recipient" },
    { label: "Subject", key: "subject" },
    { label: "Status", key: "status" },
    { label: "Sending Server", key: "serverSender" },
    { label: "Host", key: "host" },
    { label: "Message ID", key: "messageId" },
  ];
  const [visible, setVisible] = useState(false);
  const [dataLog, setDataLog] = useState(false);
  const onClose = () => {
    setVisible(false);
  };
  const logEmail = useSelector(({ logEmail }) => logEmail.value);
  const { tableLogUser: logEmailData = false } = logEmail;
  const logEmailAdmin = useSelector(({ logEmailAdmin: logAdmin }) => logAdmin.value);
  const { tableLogAdmin: logEmailDataAdmin = false } = logEmailAdmin;
  const { isAdmin } = useSelector(({ userData }) => userData.value);
  const tableLog = !isAdmin ? logEmailData : logEmailDataAdmin;

  const changePage = (parPage) => setFilter({ ...filter, page: parPage });
  const PaginationTable = ({ filter: dataFilter, setFilter: setDataFilter }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 768;

    useEffect(() => {
      const handleResizeWindow = () => setWidth(window.innerWidth);
      // subscribe to window resize event "onComponentDidMount"
      window.addEventListener("resize", handleResizeWindow);
      return () => {
        // unsubscribe "onComponentDestroy"
        window.removeEventListener("resize", handleResizeWindow);
      };
    }, []);

    const columns = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        width: 105,
        render: (data) => {
          const { date, time } = splitDate(data);
          return (
            <div className="flex flex-col">
              <p className="mb-0">{date}</p>
              <p className="mb-0 text-secondary text-xs" type="secondary">
                {time}
              </p>
            </div>
          );
        },
      },
      {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
        ellipsis: true,
        ...(width > 991 && width < 1200 ? { width: 200 } : {}),
      },
      {
        title: "From",
        dataIndex: "from",
        key: "from",
        ...(width > 1200 ? { width: width >= 1600 ? 220 : 200 } : {}),
        ellipsis: true,
      },
      {
        title: "Recipient",
        key: "recipient",
        dataIndex: "recipient",
        ...(width > 1200 ? { width: width >= 1600 ? 220 : 200 } : {}),
        ellipsis: true,
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        width: 120,
        render: (status) =>
          status === "delivered" ? (
            <Badge status="success" text="Delivered" />
          ) : (
            <Badge status="error" text="Failed" />
          ),
      },
    ];

    return (
      <ConfigProvider renderEmpty={() => <EmptyWrapper {...emptyState} />}>
        <Table
          className="email-log-table"
          columns={columns}
          dataSource={tableLog.rows}
          title={() => (
            <TableTitle
              title="Email Log"
              desc={`Log email penggunaan ${process.env.REACT_APP_NAME}. Klik untuk melihat detail`}
              buttonRefresh={{
                onClick: () => {
                  hitApi();
                },
              }}
              loading={loading}
              pageLimit={{
                currentLimit: dataFilter.limit,
                onSelectLimit: (limit) => {
                  setDataFilter({ ...dataFilter, page: 1, limit });
                },
                disable: tableLog?.count < 10,
              }}
              buttonExport={{
                dataCSV: tableLog.rows,
                headerCSV: headerCSV,
                responsive: "md",
                fileName: isAdmin ? "Log-Email-Customer" : "",
              }}
            />
          )}
          loading={{
            spinning: loading,
            indicator: <ICLoadingOutlined className="spinner-size m-auto" spin />,
          }}
          rowClassName="pointer"
          pagination={{
            pageSize: dataFilter.limit,
            current: dataFilter.page,
            hideOnSinglePage: true,
            onChange: (params) => {
              changePage(params);
            },
            total: tableLog.count,
            showSizeChanger: false,
            size: "small",
          }}
          onRow={(record, _) => {
            return {
              onClick: () => {
                setVisible(true);
                setDataLog(record);
              },
            };
          }}
          rowKey={(record) => record.uuid}
          {...(width < breakpoint ? { scroll: { x: 1176 } } : {})}
        />
      </ConfigProvider>
    );
  };

  return (
    <div className="px-6 py-2 bg-white">
      <PaginationTable filter={filter} setFilter={setFilter} />
      <Drawer
        closable={false}
        className="drawer-log-detail title-log-email"
        title="Log Details"
        placement="right"
        onClose={onClose}
        size="large"
        open={visible}
        extra={
          <Space>
            <Button
              icon={<ICCloseOutlined />}
              type="text"
              onClick={onClose}
              style={{ color: "#0D9F3F" }}>
              Close
            </Button>
          </Space>
        }>
        <div className="main-drawer">
          <Descriptions
            layout="horizontal"
            column={1}
            bordered
            className="drawer-desc-wrapper"
            labelStyle={{ padding: "0 16px" }}
            contentStyle={{ padding: "13px 12px" }}>
            <Descriptions.Item label="Waktu">{dataLog.date}</Descriptions.Item>
            <Descriptions.Item label="Sending Server">{dataLog.serverSender}</Descriptions.Item>
            <Descriptions.Item label="Message ID">{dataLog.messageId}</Descriptions.Item>
            <Descriptions.Item label="Subject">{dataLog.subject}</Descriptions.Item>
            <Descriptions.Item label="Pengirim">{dataLog.from}</Descriptions.Item>
            <Descriptions.Item label="Penerima">{dataLog.recipient}</Descriptions.Item>
            <Descriptions.Item label="Status">
              <Badge
                status={dataLog.status === "delivered" ? "success" : "error"}
                text={capitalizeFirstLetter(dataLog.status)}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Host">{dataLog.host}</Descriptions.Item>
            {dataLog.status === "failed" && (
              <Descriptions.Item label="Message">{dataLog.error}</Descriptions.Item>
            )}
          </Descriptions>
        </div>
      </Drawer>
    </div>
  );
};

export default TableLog;
