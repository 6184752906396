import { Button, Card, Col, Dropdown, Menu, Row, Tabs, Tooltip } from "antd";
import {
  deleteTaxInvoice,
  exportFakturClient,
  exportInvoicesClient,
  getContactClients,
  getDetailCompany,
  getFakturClient,
  getInvoicesClient,
} from "api";
import billingImg from "assets/images/billing.svg";
import CardCompanyInformation from "components/box-card/CardCompanyInformation";
import CardContactInformation from "components/box-card/CardContactInformation";
import { EmptyWrapper } from "components/empty/EmptyComponent";
import {
  ICDollarCircleOutlined,
  ICExclamationCircleOutlined,
  ICExportOutlined,
  ICPlusOutlined,
  ICTeamOutlined,
} from "components/list/ListIcon";
import ModalConfirm from "components/popups/modal/ModalConfirm";
import ModalError from "components/popups/modal/ModalError";
import ModalFakturPajak from "components/popups/modal/ModalFakturPajak";
import { ModalSuccessFaktur } from "components/popups/modal/ModalSuccess";
import TableFaktur from "components/table/TableFaktur";
import TableInvoice from "components/table/TableInvoice";
import TableTitle from "components/tableSendiri/TableTitle";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setFakturCustomer } from "redux/slices/admin/fakturCustomer";
import { setInvoiceCustomer } from "redux/slices/admin/invoiceCustomer";
import { setReportBillingCustomerDetails } from "redux/slices/admin/reportBillingCustomerDetails";
import { useGetListCompany } from "utils/hooks/useGetListCompany";
import "./reportBilling.less";

const ButtonExportCSV = ({ dataCSV, headerCSV, responsive, disable, getData, fileName, uuid }) => {
  if (!dataCSV) return "";

  const ExportTableData = () => {
    const btnExport = useRef(null);
    const exportData = async () => {
      setTimeout(() => {
        btnExport.current.link.click();
      }, 300);
    };

    return (
      <>
        <span onClick={exportData}>Export table data</span>
        <div className="hidden">
          <CSVLink
            ref={btnExport}
            data={dataCSV}
            headers={headerCSV}
            filename={`List-${fileName}`}
          />
        </div>
      </>
    );
  };

  const ExportTableAllData = () => {
    const btnExport = useRef(null);
    const [data, setData] = useState([]);
    const exportData = async () => {
      const { data, code } = await getData({ uuid });
      if (data && code === 200) {
        setData(data);
        setTimeout(() => {
          btnExport.current.link.click();
        }, 300);
      } else {
        ModalError({
          title: "Server sedang mengalami perbaikan",
          message: "Server sedang mengalami perbaikan, harap hubungi team support. Terima kasih",
          onOk: () => {},
        });
      }
    };

    return (
      <>
        <span onClick={exportData}>Export all data</span>
        <div className="hidden">
          <CSVLink
            ref={btnExport}
            data={data}
            headers={headerCSV}
            filename={`List-All-${fileName}`}
          />
        </div>
      </>
    );
  };

  const items = [
    { label: <ExportTableData />, key: 1 },
    { label: <ExportTableAllData />, key: 2 },
  ];
  const menu = <Menu items={items} />;
  return (
    <Dropdown trigger={["click"]} overlay={menu} disabled={disable}>
      <Tooltip overlayClassName={responsive && `tooltipCSV-${responsive}`} title="Export To CSV">
        <Button icon={<ICExportOutlined />} type="primary">
          <span className={responsive && `hidden-${responsive}`}>Export to CSV</span>
        </Button>
      </Tooltip>
    </Dropdown>
  );
};

const ButtonUpload = ({ data, disable, responsive, setVisible }) => {
  const showModal = ({ record, action }) => {
    setVisible({ record, mode: action });
  };

  return (
    <Button
      type="primary"
      disabled={disable}
      icon={<ICPlusOutlined />}
      onClick={() => showModal({ record: data, action: "uploadCompany" })}>
      <span className={responsive && `hidden-${responsive}`}>Upload</span>
    </Button>
  );
};

const OverviewTagihan = ({ data, loading }) => {
  useGetListCompany();
  const { state } = useLocation();
  const { company_uuid } = state;
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [loadingCompany, setLoadingCompany] = useState(false);
  const [loadingInvoice, setLoadingInvoice] = useState(false);
  const [loadingFaktur, setLoadingFaktur] = useState(false);
  const [filterInvoice, setFilterInvoice] = useState({
    company_uuid,
    page: 1,
    limit: "10",
  });
  const [filterFaktur, setFilterFaktur] = useState({
    company_uuid,
    page: 1,
    limit: "10",
  });
  const [activeTabs, setActiveTabs] = useState("1");
  const invoiceCustomer = useSelector(({ invoiceCustomer }) => invoiceCustomer.value);
  const fakturCustomer = useSelector(({ fakturCustomer }) => fakturCustomer.value);

  const handleDeleteFP = (record) => {
    ModalConfirm({
      title: `Hapus faktur pajak ${record.inv_number}`,
      icon: <ICExclamationCircleOutlined />,
      width: 600,
      message: "Apakah anda yakin ingin menghapus faktur pajak untuk invoice tersebut?",
      okText: "Ya Hapus",
      okButtonProps: {
        danger: true,
        htmlType: "submit",
      },
      cancelText: "Batalkan",
      centered: true,
      onOk: async () => {
        await deleteTaxInvoice({ uuid: record.tax_uuid });
        getDataFaktur();
        getDataInvoice();
        ModalSuccessFaktur({
          action: "hapus",
          formName: (
            <p className="mb-0">
              Faktur Pajak untuk invoice {record.invoiceNumber} berhasil dihapus pada sistem
            </p>
          ),
        });
      },
    });
  };

  const handleOk = () => {
    getDataInvoice();
    getDataFaktur();
    setVisible(false);
  };
  const handleCancel = () => {
    setVisible(false);
  };

  const getDataCompany = async () => {
    setLoadingCompany("companyInformation");
    const { data: company } = await getDetailCompany({ companyUuid: company_uuid });
    dispatch(setReportBillingCustomerDetails({ ...data, company }));
    setLoadingCompany(false);
  };

  const getDataContact = async () => {
    setLoadingCompany("contactInformation");
    const { data: contact } = await getContactClients({ companyUuid: company_uuid });
    dispatch(setReportBillingCustomerDetails({ ...data, contact }));
    setLoadingCompany(false);
  };

  const getDataInvoice = useCallback(async () => {
    setLoadingInvoice(true);
    const { data } = await getInvoicesClient(filterInvoice);
    dispatch(setInvoiceCustomer(data));
    setTimeout(setLoadingInvoice, 1000, false);
  }, [dispatch, filterInvoice]);

  const getDataFaktur = useCallback(async () => {
    setLoadingFaktur(true);
    const { data } = await getFakturClient(filterFaktur);
    dispatch(setFakturCustomer(data));
    setTimeout(setLoadingFaktur, false, 1000);
  }, [dispatch, filterFaktur]);

  const dataInvoices = { table: invoiceCustomer, loading: loadingInvoice };
  const dataFaktur = { table: fakturCustomer, loading: loadingFaktur };

  const handleChange = (key) => {
    setActiveTabs(key);
  };

  const InvoiceListTable = () => {
    const headerCSV = [
      { label: "Nomor Invoice", key: "inv_number" },
      { label: "Status", key: "inv_status" },
      { label: "Faktur Pajak", key: "inv_tax" },
      { label: "Tanggal Invoice", key: "inv_date" },
      { label: "Jatuh Tempo", key: "inv_due_date" },
      { label: "Jumlah Tagihan", key: "inv_price" },
    ];

    if (dataInvoices.table.rows.length) {
      return (
        <TableInvoice
          role="accounting"
          title={{
            title: "Daftar Invoice",
            addsBefore: (
              <ButtonExportCSV
                headerCSV={headerCSV}
                dataCSV={dataInvoices.table.rows}
                responsive="xs"
                fileName={`Invoice-${data.profile.companyName.split(" ").join("-")}`}
                getData={exportInvoicesClient}
                uuid={company_uuid}
              />
            ),
          }}
          data={dataInvoices}
          filter={filterInvoice}
          setFilter={setFilterInvoice}
        />
      );
    }
    return (
      <div className="flex flex-col gap-y-1">
        <TableTitle
          title="Daftar Invoice"
          className="p-2"
          addsAfter={<ButtonExportCSV disable />}
        />
        <EmptyWrapper
          title="Belum ada data pembayaran"
          message="Data pembayaran invoice belum tersedia, customer belum melakukan transaksi pembelian paket."
          image={billingImg}
          imgMargin="mb-4"
        />
      </div>
    );
  };

  const FakturListTable = () => {
    const headerCSV = [
      { label: "Tanggal", key: "tax_date" },
      { label: "Nomor Invoice", key: "inv_number" },
      { label: "Nomor Seri Faktur", key: "tax_number" },
      { label: "Nominal PPN", key: "tax_price" },
    ];

    if (dataFaktur.table.rows.length) {
      return (
        <TableFaktur
          role="accounting"
          title={{
            title: "Faktur Pajak",
            addsAfter: (
              <ButtonExportCSV
                header={headerCSV}
                dataCSV={dataFaktur.table.rows}
                responsive="xs"
                fileName={`FP-${data.profile.companyName.split(" ").join("-")}`}
                getData={exportFakturClient}
                uuid={company_uuid}
              />
            ),
            addsBefore: <ButtonUpload responsive="xs" data={data} setVisible={setVisible} />,
          }}
          data={dataFaktur}
          filter={filterFaktur}
          setFilter={setFilterFaktur}
          handleDeleteFP={handleDeleteFP}
          setVisible={setVisible}
        />
      );
    }
    return (
      <div className="flex flex-col gap-y-1">
        <TableTitle
          title="Daftar Faktur Pajak"
          className="p-2"
          addsAfter={<ButtonExportCSV disable />}
          addsBefore={<ButtonUpload responsive="xs" data={data} setVisible={setVisible} />}
        />
        <EmptyWrapper
          title="Data Faktur Pajak belum tersedia"
          message="Belum tersedia data faktur pajak saat ini, silakan lakukan upload untuk menambahkan data faktur pajak."
          image={billingImg}
          imgMargin="mb-4"
        />
      </div>
    );
  };

  useEffect(() => {
    if (filterInvoice) getDataInvoice();
  }, [filterInvoice, getDataInvoice]);

  useEffect(() => {
    if (filterFaktur) getDataFaktur();
  }, [filterFaktur, getDataFaktur]);

  if (!data || loading === "all") return <Card loading />;

  return (
    <div className="p-6 bg-white report-billing">
      <Tabs
        defaultActiveKey="1"
        activeKey={activeTabs}
        className="overviewTagihan-tab"
        onChange={handleChange}
        items={[
          {
            label: (
              <span>
                <ICTeamOutlined />
                Overview
              </span>
            ),
            key: "1",
            children: (
              <Row gutter={[24, 24]}>
                <Col sm={24} xl={12} className="company__column">
                  <CardCompanyInformation
                    isAdmin="admin"
                    data={data.company}
                    companyUuid={company_uuid}
                    loading={loadingCompany}
                    getOthers={getDataCompany}
                  />
                </Col>
                <Col sm={24} xl={12} className="contact__column">
                  <CardContactInformation
                    contact={data.contact}
                    sectionLoad={getDataContact}
                    loading={loadingCompany}
                    company_uuid={company_uuid}
                  />
                </Col>
              </Row>
            ),
          },
          {
            label: (
              <span>
                <ICDollarCircleOutlined />
                Data Tagihan
              </span>
            ),
            key: "2",
            children: (
              <div className="flex flex-col gap-y-4">
                <InvoiceListTable />
                <FakturListTable />
                <ModalFakturPajak
                  data={{
                    ...visible.record,
                    company_name: data.profile?.companyName,
                    company_uuid: data.profile?.companyUuid,
                  }}
                  cancel={handleCancel}
                  handleSuccess={handleOk}
                  open={visible}
                  action={visible.mode}
                  setOpen={setVisible}
                />
              </div>
            ),
          },
        ]}
      />
    </div>
  );
};

export default OverviewTagihan;
