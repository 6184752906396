import { createSlice } from "@reduxjs/toolkit";

export const listDomain = createSlice({
  name: "listDomain",
  initialState: {
    value: false,
  },
  reducers: {
    setListDomain: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setListDomain } = listDomain.actions;
export default listDomain.reducer;
