import { Bar, Column, Line } from "@ant-design/plots";
import { Typography } from "antd";
import lineEmpty from "assets/images/line_empty.svg";
import { EmptyChartTopSender, EmptyWrapper } from "components/empty/EmptyComponent";

const { Title, Text } = Typography;

export const BarChart = ({ data = [], title, column }) => {
  const config = {
    xField: "value",
    yField: "type",
    seriesField: "domain",
    isPercent: true,
    isStack: true,
    padding: 0,
    margin: 0,
    top: true,
    marginRatio: 0,
    height: 42,

    color: ["#E8684A", "#5B8FF9", "#5AD8A6", "#5D7092", "#F6BD16"],
    minBarWidth: 30,
    maxBarWidth: 30,
    legend: false,
    barStyle: {
      strokeOpacity: 0,
    },
    tooltip: {
      title: title,
      position: "bottom",
      enterable: true,
      formatter: (a) => {
        return { name: a.domain, value: (a.value * 100).toFixed(0) + "%" };
      },
      domStyles: {
        "g2-tooltip": { width: "max-content" },
      },
    },
    xAxis: {
      position: "top",
      label: {
        style: {
          opacity: 0,
        },
      },
      line: {
        style: {
          lineOpacity: 0,
          lineWidth: 0,
        },
      },
      grid: {
        line: {
          style: {
            lineWidth: 0,
          },
        },
      },
    },
    yAxis: {
      position: "top",
      top: true,
      label: {
        style: {
          opacity: 0,
        },
      },
      grid: {
        line: {
          style: {
            lineWidth: 0,
          },
        },
      },
      line: {
        style: {
          lineOpacity: 0,
          lineWidth: 0,
        },
      },
    },
  };

  if (!data.length) return <EmptyChartTopSender />;
  return (
    <>
      <Bar {...config} data={data} />
      <div className={`flex gap-x-3 flex-wrap${column ? " flex-col" : ""}`}>
        {data.map(({ domain }, i) => (
          <p className="mb-0 text-secondary text-xs chart-legend" key={i}>
            {domain}
          </p>
        ))}
      </div>
    </>
  );
};

export const MostSendEmail = ({ data = [] }) => {
  const config = {
    data,
    isGroup: true,
    xField: "month",
    yField: "value",
    seriesField: "status",
    color: ({ status }) => {
      return status === "delivered" ? "#0D9F3F" : "#FF4D4F";
    },
    marginRatio: 0.2,
    xAxis: {
      label: {
        formatter: (label) => {
          return label.slice(-3);
        },
      },
    },
    yAxis: {
      line: {
        style: {
          opacity: 0.5,
        },
      },
      label: {
        offset: 12,
        formatter: (a) => `${a.length > 3 ? `${a.substring(0, a.length - 3)}k` : a}`,
      },
    },
  };

  if (!data.length)
    return (
      <EmptyWrapper
        imgMargin="mb-7"
        message={`Grafik akan tersedia setelah client melakukan pengiriman email melalui ${process.env.REACT_APP_NAME}`}
        type="line"
      />
    );

  return <Column {...config} />;
};

export const MostEmailWeekly = ({ data = [] }) => {
  const config = {
    data,
    isGroup: true,
    xField: "month",
    yField: "value",
    seriesField: "status",
    color: ({ status }) => {
      return status === "delivered" ? "#0D9F3F" : "#FF4D4F";
    },
    marginRatio: 0.2,
    yAxis: {
      line: {
        style: {
          opacity: 0.5,
        },
      },
    },
  };

  if (!data.length)
    return (
      <EmptyWrapper
        imgMargin="mb-7"
        message={`Grafik akan tersedia setelah client melakukan pengiriman email melalui ${process.env.REACT_APP_NAME}`}
        type="line"
      />
    );

  return <Column {...config} />;
};

export const LineChart = ({ data = [] }) => {
  const config = {
    data,
    height: 497,
    xField: "date",
    renderer: "svg",
    autoFit: true,
    yField: "value",
    seriesField: "category",
    appendPadding: 10,
    yAxis: {
      label: {
        formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
      },
    },
    color: ({ category }) => {
      return category === "delivered" ? "#0D9F3F" : "#FF4D4F";
    },
  };
  if (!data.length)
    return (
      <div
        className="flex flex-col items-center justify-center h-full text-center m-auto empty-line-chart-text"
        style={{ maxWidth: 400 }}>
        <img src={lineEmpty} alt="Line Chart" />
        <Title level={5}>Data belum tersedia</Title>
        <Text type="secondary" className="font-normal">
          Grafik akan tersedia setelah anda melakukan pengiriman email melalui{" "}
          {process.env.REACT_APP_NAME}
        </Text>
      </div>
    );
  return <Line {...config} className="line-chart-dashboard" />;
};

export const ReportUsageChart = ({ data = [], title }) => {
  const config = {
    xField: "value",
    yField: "type",
    seriesField: "domain",
    isPercent: true,
    isStack: true,
    padding: 0,
    margin: 0,
    top: true,
    marginRatio: 0,
    height: 50,

    color: ["#E8684A", "#5B8FF9", "#5AD8A6", "#5D7092", "#F6BD16"],
    minBarWidth: 30,
    maxBarWidth: 30,
    legend: false,
    barStyle: {
      strokeOpacity: 0,
    },
    tooltip: {
      title,
      position: "bottom",
      enterable: true,
      domStyles: {
        "g2-tooltip": { width: "max-content" },
      },
    },
    xAxis: {
      position: "top",
      label: {
        style: {
          opacity: 0,
        },
      },
      line: {
        style: {
          lineOpacity: 0,
          lineWidth: 0,
        },
      },
      grid: {
        line: {
          style: {
            lineWidth: 0,
          },
        },
      },
    },
    yAxis: {
      position: "top",
      top: true,
      label: {
        style: {
          opacity: 0,
        },
      },
      grid: {
        line: {
          style: {
            lineWidth: 0,
          },
        },
      },
      line: {
        style: {
          lineOpacity: 0,
          lineWidth: 0,
        },
      },
    },
  };

  if (data.length < 1) return <EmptyChartTopSender />;

  return (
    <>
      <Bar {...config} data={data} />
      <div className="flex flex-col">
        {data.map((dom) => (
          <p className="mb-0 text-secondary text-xs chart-legend" key={`${dom.domain}${dom.value}`}>
            {dom.domain}
          </p>
        ))}
      </div>
    </>
  );
};

export const ColumnChart = ({ data }) => {
  const config = {
    isGroup: true,
    xField: "month",
    yField: "value",
    seriesField: "status",
    yAxis: {
      label: { formatter: (a) => `${a.length > 3 ? `${a.substring(0, a.length - 3)}k` : a}` },
    },
    legend: { offsetY: -8 },
    color: ["#0D9F3F", "#FF4D4F"],
    intervalPadding: 16,
    dodgePadding: 4,
    label: { style: { opacity: 0 } },
    tooltip: {
      formatter: (a) => {
        return { name: a.status, value: a.value.toLocaleString("ID-id") };
      },
    },
  };

  return <Column className="mb-10 mt-2" {...config} data={data} />;
};
