import { Button, Card, Dropdown, Form, Input, Menu, Modal, Select, Tabs, Tooltip } from "antd";
import {
  deleteCompany,
  deleteContact,
  exportListCompanies,
  exportListContact,
  loginAsClient,
  modifyStatusRelay,
} from "api";
import { EmptyWrapper } from "components/empty/EmptyComponent";
import {
  ICContactsOutlined,
  ICDeleteOutlined,
  ICEditOutlined,
  ICExclamationCircleOutlined,
  ICExportOutlined,
  ICFilterOutlined,
  ICLockOutlined,
  ICLoginOutlined,
  ICPoweroffOutlined,
  ICTeamOutlined,
  ICBlockOutlined,
} from "components/list/ListIcon";
import ModalConfirm from "components/popups/modal/ModalConfirm";
import ModalContactPelanggan from "components/popups/modal/ModalContactPelanggan";
import ModalError from "components/popups/modal/ModalError";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import ModalChangePassSMTP from "components/popups/modal/ModalChangePasswordSMTP";
import ModalWarning from "components/popups/modal/ModalWarning";
import ListContactClients from "components/table/ListContactClients";
import TablePelanggan from "components/table/TablePelanggan";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setDetailCompany } from "redux/slices/admin/detailCompany";
import { gatherFormData } from "utils/helpers";
import useGetPackageListFilter from "utils/hooks/useGetPackageListFilter";
import useDocumentTitle from "utils/useDocumentTitle";

export const requiredRules = { required: true, message: "Harap diisi terlebih dahulu" };

const ModalConfirmDeleteCompany = ({ open, setOpen, getDataCustomer, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  return (
    <Modal
      centered
      open={open}
      children={
        <div className="ant-modal-confirm-body-wrapper">
          <div className="ant-modal-confirm-body">
            <ICExclamationCircleOutlined className="text-warning" />
            <span className="ant-modal-confirm-title">Hapus client {open.company_name}</span>
            <div className="ant-modal-confirm-content">
              <p className="mb-4">Apakah anda yakin ingin menghapus client tersebut?</p>
              <p className="mb-2">
                Setelah menghapus client, seluruh data yang berkaitan dengan client (log, kontak,
                domain) akan terhapus. Anda tidak dapat mengembalikan kembali data yang sudah
                dihapus. Ketik <span className="font-medium">confirm</span> untuk menghapus client
              </p>
              <Form layout="vertical" form={form} name="hapusClient">
                <Form.Item
                  name="confirm"
                  validateFirst
                  rules={[
                    requiredRules,
                    {
                      validator(_, value) {
                        if (value === "confirm") return Promise.resolve();
                        return Promise.reject(new Error("Ketik confirm untuk menghapus client!"));
                      },
                    },
                  ]}>
                  <Input placeholder="confirm" />
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      }
      onCancel={() => {
        form.resetFields();
        setOpen(false);
      }}
      okText="Ya Hapus"
      cancelText="Batalkan"
      onOk={() => {
        const callback = async () => {
          setLoading(true);
          const { status } = await deleteCompany({ uuid: open.company_uuid });
          setLoading(false);
          if (status === "success") {
            ModalSuccess({
              title: "Client berhasil dihapus",
              width: 452,
              message: `Data client ${open?.company_name} berhasil dihapus pada sistem`,
              onOk: () => getDataCustomer(),
            });
          } else
            ModalError({
              title: "Client gagal dihapus",
              width: 452,
              message: `Data client ${open?.company_name} gagal dihapus`,
              onOk: () => {},
            });
          form.resetFields();
          setOpen(false);
        };
        gatherFormData(form, callback);
      }}
      okButtonProps={{
        htmlType: "submit",
        danger: true,
        loading,
      }}
    />
  );
};

const ButtonExportCSV = ({ table, dataCSV, headerCSV, responsive, disable, getExport }) => {
  if (!dataCSV) return "";

  const ExportTableData = () => {
    const btnExport = useRef(null);
    const exportData = async () => {
      setTimeout(() => {
        btnExport.current.link.click();
      }, 300);
    };

    return (
      <>
        <span onClick={exportData}>Export table data</span>
        <div className="hidden">
          <CSVLink
            ref={btnExport}
            data={dataCSV}
            headers={headerCSV}
            filename={table === "customer" ? "List-Data-Customer" : "List-Contact-Customer"}
          />
        </div>
      </>
    );
  };

  const ExportTableAllData = () => {
    const btnExport = useRef(null);
    const [data, setData] = useState([]);
    const exportData = async () => {
      const { data } = await getExport();

      if (data) setData(data);
      else setData(data);
      setTimeout(() => {
        btnExport.current.link.click();
      }, 300);
    };

    return (
      <>
        <span onClick={exportData}>Export all data</span>
        <div className="hidden">
          <CSVLink
            ref={btnExport}
            data={data}
            headers={headerCSV}
            filename={table === "customer" ? "List-All-Data-Customer" : "List-All-Contact-Customer"}
          />
        </div>
      </>
    );
  };

  const items = [
    { label: <ExportTableData />, key: 1 },
    { label: <ExportTableAllData />, key: 2 },
  ];
  const menu = <Menu items={items} />;
  return (
    <Dropdown trigger={["click"]} overlay={menu} disabled={disable}>
      <Tooltip overlayClassName={responsive && `tooltipCSV-${responsive}`} title="Export To CSV">
        <Button icon={<ICExportOutlined />} type="primary">
          <span className={responsive && `hidden-${responsive}`}>Export to CSV</span>
        </Button>
      </Tooltip>
    </Dropdown>
  );
};

const AccordionFilter = ({ setFilter, filter, width, breakpoint_third }) => {
  const listPackageFilter = useSelector(({ packageListFilter }) => packageListFilter.value);
  const breakpoint = 767;
  const [statRelay, setStatRelay] = useState([]);
  const [packageName, setPackageName] = useState([]);
  const [statSubs, setStatSubs] = useState([]);

  const onFilter = (values) => {
    const {
      status_relay: statRelay = [],
      package_name: packageName = [],
      status_subcribtion: statSubs = [],
    } = values;
    const relayStats = statRelay && statRelay.filter((item) => item !== "selectAll");
    const namePack = packageName && packageName.filter((item) => item !== "selectAll");
    const subsStat = statSubs && statSubs.filter((item) => item !== "selectAll");

    setStatRelay(statRelay);
    setPackageName(packageName);
    setStatSubs(statSubs);
    setFilter({
      ...filter,
      page: 1,
      limit: filter.limit,
      status_relay: !relayStats.length === 4 ? [] : relayStats,
      package_name: !namePack.length === 3 ? [] : namePack,
      status_subcribtion: !subsStat.length === 3 ? [] : subsStat,
    });
  };

  return (
    <Form
      layout="inline"
      className="formFilter"
      onFinish={onFilter}
      initialValues={{
        package_name: packageName,
        status_subcribtion: statSubs,
        status_relay: statRelay,
      }}>
      <Form.Item label="Paket Layanan" name="package_name">
        <Select
          className="filter-select"
          mode="multiple"
          maxTagCount={width <= breakpoint ? 0 : "responsive"}
          allowClear
          showSearch={false}
          placeholder="Semua Paket">
          <Select.Option value="selectAll">Select All</Select.Option>
          {listPackageFilter &&
            listPackageFilter.rows.map((item) => (
              <Select.Option key={item.uuid} value={item.packageName}>
                {item.packageName}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item label="Subscription" name="status_subcribtion">
        <Select
          className="filter-select"
          mode="multiple"
          maxTagCount={window.innerWidth <= 767 ? 0 : "responsive"}
          allowClear
          showSearch={false}
          placeholder="Semua Status">
          <Select.Option className="log-admin-selectall" value="selectAll">
            Select All
          </Select.Option>
          <Select.Option value="active">Active</Select.Option>
          <Select.Option value="inactive">Inactive</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item label="Akun Relay" name="status_relay">
        <Select
          className="filter-select"
          mode="multiple"
          maxTagCount={window.innerWidth <= 767 ? 0 : "responsive"}
          allowClear
          showSearch={false}
          placeholder="Semua Relay">
          <Select.Option className="log-admin-selectall" value="selectAll">
            Select All
          </Select.Option>
          <Select.Option value="active">Active</Select.Option>
          <Select.Option value="closed">Inactive</Select.Option>
          <Select.Option value="locked">Locked</Select.Option>
          <Select.Option value="suspended">Suspend</Select.Option>
        </Select>
      </Form.Item>
      <div className="button-advance-filter">
        <Button type="primary" htmlType="submit" icon={<ICFilterOutlined />}>
          Apply Filter
        </Button>
      </div>
    </Form>
  );
};

const CustomerTable = ({
  getDataContact,
  getDataCustomer,
  loading,
  setLoading,
  filter,
  setFilter,
  filterContact,
  setFilterContact,
}) => {
  useDocumentTitle("Daftar Pelanggan");
  useGetPackageListFilter();
  const breakpoint_third = 768;
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [showModalDeleteCompany, setShowModalDeleteCompany] = useState(false);
  const [showModalChangePassSMTP, setShowChangePassSMTP] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();

  const listClients = useSelector(({ listAllClients }) => listAllClients.value);
  const listAllContact = useSelector(({ listAllContact }) => listAllContact.value);
  const listAllCustomer = useSelector(({ listAllCustomer }) => listAllCustomer.value);

  const dataCompany = { table: listAllCustomer, loading };
  const dataContact = { table: listAllContact, loading };

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const headerCSVCustomer = [
    { label: "Nama Perusahaan", key: "company_name" },
    { label: "Relay Account", key: "relay_email" },
    { label: "Status Relay", key: "relay_status" },
    { label: "Kontak Primary", key: "contact_primary_email" },
    { label: "Domain", key: "current_domain" },
    { label: "Space Domain", key: "max_domain" },
    { label: "Paket", key: "package_name" },
    { label: "Status Langganan", key: "package_status" },
    { label: "Status Relay", key: "relay_status" },
  ];

  const headerCSVContact = [
    { label: "Nama Kontak", key: "contact_name" },
    { label: "Email", key: "contact_email" },
    { label: "Nama Perusahaan", key: "contact_company" },
    { label: "Telepon", key: "contact_phone" },
    { label: "Tags", key: "contact_role" },
  ];

  const handleHapusKontak = (record) => {
    ModalConfirm({
      title: `Hapus kontak client  ${record.contact_name}`,
      customMessage: (
        <>
          <p className="mb-4">Apakah anda yakin ingin menghapus kontak client tersebut?</p>
          <p>Anda tidak dapat mengembalikannya kembali setelah kontak dihapus</p>
        </>
      ),
      onOk: async () => {
        const values = record;
        await deleteContact({ contactUuid: values.contact_uuid });
        ModalSuccess({
          title: "Kontak berhasil dihapus",
          message: `Kontak ${record.contact_name} berhasil dihapus pada sistem`,
          onOk: () => getDataContact(),
        });
      },
    });
  };

  const ActionTableClients = (record) => {
    return (
      <Menu
        items={[
          {
            label: "Edit Client",
            key: 1,
            icon: <ICEditOutlined />,
            onClick: () => dispatch(setDetailCompany(false, navigate("edit", { state: record }))),
          },
          {
            label: "Login as Client",
            key: 2,
            icon: <ICLoginOutlined />,
            onClick: async () => {
              const { data } = await loginAsClient({
                user: record.user_primary_uuid,
                company: record.company_uuid,
                contact: record.contact_primary_uuid,
              });
              window.open(
                `${process.env.REACT_APP_FE_BASE_URL}/logintoclient?token=${data.accessToken}`,
                "_blank"
              );
            },
          },
          {
            label: "Ubah Pass Relay",
            key: 3,
            icon: <ICBlockOutlined />,
            disabled:
              !record?.relay_status ||
              record?.relay_status === "locked" ||
              record?.relay_status === "closed" ||
              record?.relay_status === "suspended"
                ? true
                : false,
            onClick: () => {
              setSelectedRow(record);
              setShowChangePassSMTP(true);
            },
          },
          {
            label: record?.relay_status === "locked" ? "Unlock Relay" : "Lock Relay",
            key: 4,
            icon: <ICLockOutlined />,
            disabled:
              !record?.relay_status ||
              record?.relay_status === "closed" ||
              record?.relay_status === "inactive" ||
              record?.relay_status === "suspended"
                ? true
                : false,
            onClick: () => {
              ModalConfirm({
                title:
                  record?.relay_status === "locked"
                    ? `Unlock akun ${process.env.REACT_APP_NAME} ${record?.company_name}`
                    : `Lock akun ${process.env.REACT_APP_NAME} ${record?.company_name}`,
                customMessage: (
                  <>
                    <p className="mb-4">
                      Apakah anda yakin ingin{" "}
                      {record?.relay_status === "locked" ? "unlock" : "lock"} akun{" "}
                      {process.env.REACT_APP_NAME}
                      tersebut?
                    </p>
                    <p className="mb-0">
                      {record?.relay_status === "locked"
                        ? "Pastikan client sudah melakukan pembersihan terhadap email spam agar IP Public tetap terjaga kebersihannya."
                        : `Setelah melakukan lock akun ${process.env.REACT_APP_NAME}, maka client tidak dapat mengirimkan email sampai dilakukan unlock akun kembali.`}
                    </p>
                  </>
                ),
                okButtonProps: { danger: true },
                okText: record?.relay_status === "locked" ? "Unlock Akun Relay" : "Lock Akun Relay",
                onOk: async () => {
                  const statusLock = record?.relay_status === "locked" ? "active" : "locked";
                  await modifyStatusRelay({
                    relay_email: record?.relay_email,
                    new_status: statusLock,
                  });
                  ModalSuccess({
                    title:
                      record?.relay_status === "locked"
                        ? "Akun berhasil di unlock"
                        : "Akun berhasil di lock",
                    width: 452,
                    message: `Akun ${process.env.REACT_APP_NAME} ${
                      record?.company_name
                    } berhasil di ${
                      record?.relay_status === "locked" ? "unlock" : "lock"
                    } oleh sistem`,
                    onOk: () => getDataCustomer(),
                  });
                },
              });
            },
          },
          {
            label: record?.relay_status !== "suspended" ? "Suspend Relay" : "Unsuspend Relay",
            key: 5,
            icon: <ICPoweroffOutlined />,
            disabled:
              !record?.relay_status ||
              record?.relay_status === "closed" ||
              record?.relay_status === "inactive"
                ? true
                : false,
            onClick: () => {
              ModalConfirm({
                title:
                  record?.relay_status !== "suspended"
                    ? `Suspend akun ${process.env.REACT_APP_NAME} ${record?.company_name}`
                    : `Unsuspend akun ${process.env.REACT_APP_NAME} ${record?.company_name}`,
                customMessage: (
                  <>
                    <p className="mb-4">
                      Apakah anda yakin ingin{" "}
                      {record?.relay_status !== "suspended" ? "suspend" : "unsuspend"} akun SMTP
                      Relay tersebut?
                    </p>
                    <p className="mb-0">
                      {record?.relay_status !== "suspended"
                        ? `Setelah melakukan suspend akun ${process.env.REACT_APP_NAME}, maka client tidak dapat mengirimkan email dan unlock secara mandiri sampai anda melakukan unsuspend layanan.`
                        : `Setelah melakukan unsuspend akun ${process.env.REACT_APP_NAME}, maka client dapat mengirimkan email kembali seperti sebelumnya.`}
                    </p>
                  </>
                ),
                okButtonProps: { danger: true },
                okText:
                  record?.relay_status !== "suspended"
                    ? "Suspend Akun Relay"
                    : "Unsuspend Akun Relay",
                onOk: async () => {
                  const statusSuspended =
                    record?.relay_status === "suspended" ? "active" : "suspended";
                  const response = await modifyStatusRelay({
                    relay_email: record?.relay_email,
                    new_status: statusSuspended,
                  });
                  if (
                    response.code === 400 &&
                    response.msg === "Company does not have any active subscription"
                  ) {
                    ModalWarning({
                      title: "Unsuspend akun SMTP Gagal Dilakukan",
                      width: 600,
                      message: (
                        <p>
                          Akun SMTP <strong>{record?.company_name}</strong> gagal diaktifkan karena
                          tidak memiliki subscription yang aktif. Silakan dapat menginformasikan
                          kepada customer untuk dapat berlangganan terlebih dahulu.
                        </p>
                      ),
                    });
                  } else {
                    ModalSuccess({
                      title:
                        record?.relay_status !== "suspended"
                          ? "Akun berhasil di suspend"
                          : "Akun berhasil di unsuspend",
                      width: 452,
                      message: `${
                        record?.relay_status !== "suspended"
                          ? `Akun ${process.env.REACT_APP_NAME} ${record?.company_name} berhasil di suspend oleh sistem`
                          : `Akun ${process.env.REACT_APP_NAME} ${record?.company_name} berhasil di unsuspend oleh sistem dan aktif kembali`
                      }`,
                      onOk: () => getDataCustomer(),
                    });
                  }
                },
              });
            },
          },
          {
            label: "Hapus Client",
            key: 6,
            disabled: record.package_status === "active",
            icon: <ICDeleteOutlined />,
            danger: record.package_status !== "active",
            onClick: () => setShowModalDeleteCompany(record),
          },
        ]}
      />
    );
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const searchCompany = (value, event) => {
    if (value) {
      setFilter({ ...filter, page: 1, limit: filter.limit, company_name: value });
    } else {
      setFilter({ ...filter, page: 1, limit: filter.limit, company_name: "" });
    }
  };

  const searchContact = (value, event) => {
    if (value) {
      setFilterContact({
        ...filterContact,
        page: 1,
        limit: filterContact.limit,
        company_name: value,
      });
    } else {
      setFilterContact({ ...filterContact, page: 1, limit: filterContact.limit, company_name: "" });
    }
  };

  return (
    <Card>
      <Tabs
        defaultActiveKey="1"
        className="activity-log-admin"
        items={[
          {
            label: (
              <span>
                <ICTeamOutlined />
                Daftar Pelanggan
              </span>
            ),
            key: "1",
            children:
              !listClients?.count > 0 ? (
                <TablePelanggan
                  title={{
                    title: "Daftar Pelanggan",
                    desc: `List pelanggan pengguna layanan ${process.env.REACT_APP_NAME}`,
                    loading: loading,
                    pageLimit: {
                      currentLimit: filter.limit,
                      onSelectLimit: (limit) => {
                        setFilter({ ...filter, limit, page: 1 });
                      },
                      disable: dataCompany.table?.count < 10,
                    },
                    searchCompany: { isAdmin: true, onSearch: searchCompany },
                    addsAfter: (
                      <ButtonExportCSV
                        table="customer"
                        dataCSV={dataCompany.table?.rows}
                        headerCSV={headerCSVCustomer}
                        responsive="lg"
                        disable={dataCompany.table?.count < 1}
                        getExport={exportListCompanies}
                      />
                    ),
                    addsAccordion: <AccordionFilter setFilter={setFilter} filter={filter} />,
                  }}
                  ActionTableClients={ActionTableClients}
                  filter={filter}
                  setFilter={setFilter}
                  data={dataCompany}
                  emptyState={{
                    title: "Data tidak ditemukan",
                    desc: "Data perusahaan yang anda cari tidak ditemukan, pastikan tidak ada salah ketik pada penulisan",
                  }}
                  responsiveWidth={width}
                  breakpoint_third={breakpoint_third}
                />
              ) : (
                <EmptyWrapper
                  title="Belum ada data Pelanggan"
                  message="Data pelanggan belum tersedia."
                  className="bg-white mt-10"
                />
              ),
          },
          {
            label: (
              <span>
                <ICContactsOutlined />
                Daftar Kontak
              </span>
            ),
            key: "2",
            children:
              !listClients?.listContactClients?.rows > 0 ? (
                <ListContactClients
                  title={{
                    title: "Daftar Kontak",
                    desc: `List kontak perusahaan pengguna layanan ${process.env.REACT_APP_NAME}.`,
                    searchCompany: { isAdmin: true, onSearch: searchContact },
                    pageLimit: {
                      currentLimit: filterContact?.limit,
                      onSelectLimit: (limit) => {
                        setFilterContact({ ...filterContact, limit, page: 1 });
                      },
                      isVisible: dataContact?.table.count > 10,
                    },
                    addsAfter: (
                      <ButtonExportCSV
                        table="contact"
                        dataCSV={dataContact.table.rows}
                        headerCSV={headerCSVContact}
                        getExport={exportListContact}
                        responsive="lg"
                        disable={dataContact.table.count < 1}
                      />
                    ),
                  }}
                  emptyState={{
                    title: "Data tidak ditemukan",
                    message:
                      "Data perusahaan yang anda cari tidak ditemukan, pastikan tidak ada salah ketik pada penulisan",
                  }}
                  filter={filterContact}
                  setFilter={setFilterContact}
                  isAdmin="true"
                  setOpen={setVisible}
                  data={dataContact}
                  onDelete={handleHapusKontak}
                />
              ) : (
                <EmptyWrapper
                  title="Belum ada data Daftar Kontak"
                  message="Silahkan Refresh Page"
                  className="bg-white mt-10"
                />
              ),
          },
        ]}
      />
      <ModalConfirmDeleteCompany
        open={showModalDeleteCompany}
        setOpen={setShowModalDeleteCompany}
        getDataCustomer={getDataCustomer}
      />
      <ModalContactPelanggan
        action="edit"
        visible={visible}
        onCancel={handleCancel}
        sectionLoad={getDataContact}
        setLoading={setLoading}
        data={visible}
      />
      <ModalChangePassSMTP
        open={showModalChangePassSMTP}
        setOpen={setShowChangePassSMTP}
        record={selectedRow}
        dataLoading={setLoading}
        getDataCustomer={getDataCustomer}
      />
    </Card>
  );
};

export default CustomerTable;
