import Spinner from "components/Spinner/Spinner";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { getCookieToken } from "./helpers";
import useCheckoutChecker from "./hooks/useCheckoutChecker";
import useCheckRelay from "./hooks/useCheckRelay";
import useGetCompanyData from "./hooks/useGetCompanyData";
import useGetDetailsSubscription from "./hooks/useGetDetailsSubscription";
import useGetPacket from "./hooks/useGetPacket";

const CheckLoginCheckout = () => {
  useCheckRelay();
  useGetCompanyData();
  useCheckoutChecker();
  useGetDetailsSubscription();
  useGetPacket();

  const navigate = useNavigate();
  const user = useSelector(({ userData }) => userData.value);
  const token = getCookieToken();
  const isChecked = useSelector(({ checkoutChecker }) => checkoutChecker.value);

  useEffect(() => {
    if (!token && !user) window.location.href = "/login";
    else if (isChecked) {
      if (isChecked === "first") return;
      else if (!isChecked.checked) navigate("/billing/confirmation");
    }
  }, [token, user, isChecked, navigate]);

  return user ? <Outlet /> : <Spinner full />;
};

export default CheckLoginCheckout;
