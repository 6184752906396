import { getPackage } from "api";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setUserPackage } from "redux/slices/userPackage";

export default function useGetUserPackage() {
  const dispatch = useDispatch();

  const getPackageUser = useCallback(async () => {
    const { data } = await getPackage();
    dispatch(setUserPackage(data));
  }, [dispatch]);

  useEffect(() => {
    getPackageUser();
  }, [getPackageUser]);
}
