import { Divider } from "antd";

const CardEmailBounce = ({ title, number = 0, percentBounce = 0 }) => {
  return (
    <div className="card-bounce">
      <div className="card-bounce__body">
        <p className="my-1 text-secondary">{title}</p>
        <h2 className="mb-0 text-3xl font-medium">{number.toLocaleString("en-US")}</h2>
        <p className="mb-0 text-danger">{percentBounce}% Email Bounce</p>
        <Divider />
      </div>
    </div>
  );
};

export default CardEmailBounce;
