import { LogoAktiva } from "components/Components";
import React from "react";
import { Outlet } from "react-router-dom";

const ChangePassLayout = () => {
  return (
    <div className="flex flex-col h-screen changepass">
      <LogoAktiva className="logo-aktiva" />
      <Outlet />
      <div className="footer-height">
        <div className="bg-footer w-full"></div>
      </div>
    </div>
  );
};

export default ChangePassLayout;
