import { Button, Dropdown, Menu, Tooltip } from "antd";
import { exportAllReportEmailUsage, getQuotaUsage } from "api";
import { ICExportOutlined } from "components/list/ListIcon";
import TableReport from "components/table/TableReport";
import { useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { setReportUsage } from "redux/slices/admin/reportUsage";
import "./report.less";
import InputWithDropdown from "components/input/InputWithDropdown";
import { BankOutlined } from "@ant-design/icons";
import { ICGlobalOutlined } from "components/list/ListIcon";

const ButtonExportCSV = ({ dataCSV, headerCSV, responsive, disable }) => {
  if (!dataCSV) return "";

  const ExportTableData = () => {
    const btnExport = useRef(null);
    const exportData = async () => {
      setTimeout(() => {
        btnExport.current.link.click();
      }, 300);
    };

    return (
      <>
        <span onClick={exportData}>Export table data</span>
        <div className="hidden">
          <CSVLink
            ref={btnExport}
            data={dataCSV}
            headers={headerCSV}
            filename="List-Report-Penggunaan-Customer"
          />
        </div>
      </>
    );
  };

  const ExportTableAllData = () => {
    const btnExport = useRef(null);
    const [data, setData] = useState([]);
    const exportData = async () => {
      const { data } = await exportAllReportEmailUsage();

      if (data) setData(data);
      else setData(data);
      setTimeout(() => {
        btnExport.current.link.click();
      }, 300);
    };

    return (
      <>
        <span onClick={exportData}>Export all data</span>
        <div className="hidden">
          <CSVLink
            ref={btnExport}
            data={data}
            headers={headerCSV}
            filename="List-All-Report-Penggunaan-Customer"
          />
        </div>
      </>
    );
  };

  const items = [
    { label: <ExportTableData />, key: 1 },
    { label: <ExportTableAllData />, key: 2 },
  ];
  const menu = <Menu items={items} />;
  return (
    <Dropdown trigger={["click"]} overlay={menu} disabled={disable}>
      <Tooltip overlayClassName={responsive && `tooltipCSV-${responsive}`} title="Export To CSV">
        <Button icon={<ICExportOutlined />} type="primary">
          <span className={responsive && `hidden-${responsive}`}>Export to CSV</span>
        </Button>
      </Tooltip>
    </Dropdown>
  );
};

const CustomerReportTable = ({ reportUsage, filter, setFilter, loading, setLoading }) => {
  const headerCSVAdmin = [
    { label: "Nama Perusahaan", key: "company_name" },
    { label: "Status Langganan", key: "subscription_status" },
    { label: "Paket Layanan", key: "subscription_package" },
    { label: "Tanggal Berlangganan", key: "company_started" },
    { label: "Lama Berlangganan", key: "differentDate" },
    { label: "Kuota Terpakai", key: "current_total_sent" },
    { label: "Kuota Email", key: "current_quota" },
    { label: "Email Terkirim", key: "current_total_delivered" },
    { label: "Email Gagal", key: "current_total_failed" },
    { label: "Total Email Terkirim", key: "total_delivered" },
    { label: "Total Email Gagal", key: "total_failed" },
    { label: "Total Seluruh Email", key: "total_sent" },
  ];

  const actionListUsage = [
    { action: "company_name", placeholder: "Cari nama perusahaan", icon: BankOutlined },
    { action: "domain_name", placeholder: "Cari nama domain", icon: ICGlobalOutlined },
  ];

  const itemsUsage = [
    { label: "Nama perusahaan", icon: <BankOutlined />, key: "company_name" },
    { label: "Nama Domain", icon: <ICGlobalOutlined />, key: "domain_name" },
  ];

  const dispatch = useDispatch();

  const reportUsages = useSelector(({ reportUsage }) => reportUsage.value);
  const data = { table: reportUsages, loading };

  const getUpdate = async (pageSize, page) => {
    const { data: table } = await getQuotaUsage({ ...filter, limit: pageSize, page });
    dispatch(setReportUsage({ ...reportUsage, table }));
  };

  return (
    <TableReport
      title={{
        title: "Report Penggunaan",
        desc: "Laporan penggunaan kuota email client",
        pageLimit: {
          isVisible: data.table.count > 10,
          currentLimit: filter.limit,
          onSelectLimit: (limit) => {
            setFilter({ ...filter, limit, page: 1 });
          },
          disable: data.table.count < 10,
        },
        addsBefore: (
          <InputWithDropdown
            items={itemsUsage}
            actionList={actionListUsage}
            setFilter={setFilter}
            filter={filter}
          />
        ),
        addsAfter: (
          <ButtonExportCSV
            dataCSV={data.table?.rows}
            headerCSV={headerCSVAdmin}
            responsive="lg"
            disable={data.table.count < 1}
          />
        ),
      }}
      className="px-6 bg-white pb-4 pt-2"
      filter={filter}
      setFilter={setFilter}
      getData={getUpdate}
      data={data}
      emptyState={{
        title: "Data tidak ditemukan",
        message:
          "Data perusahaan yang anda cari tidak ditemukan, pastikan tidak ada salah ketik pada penulisan",
      }}
    />
  );
};

export default CustomerReportTable;
