import { Form, Modal } from "antd";
import FormChangePassSMTP from "components/form/FormChangePasswordSMTP";
import ModalConfirmChangePassSMTP from "./ModalConfirmChangePassSMTP";
import { setPasswordRelay } from "api";
import React, { useState } from "react";
import "./modal.less";
import { ICExclamationCircleOutlined } from "components/list/ListIcon";

const { useForm } = Form;

const ModalChangePassSMTP = ({ open, setOpen, record, dataLoading, getDataCustomer }) => {
  const [newPassword, setNewPassword] = useState(null);
  const [repeatPassword, setrepeatNewPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form] = useForm();

  const handleCancelChange = () => {
    form.resetFields();
    setOpen(false);
  };

  const handleConfirmChange = async () => {
    setLoading(true);
    await setPasswordRelay({
      relayAccount: record.relay_email,
      password: newPassword,
      validatePassword: repeatPassword,
    });
    setLoading(false);
    ModalConfirmChangePassSMTP({
      title: "Password Berhasil Diubah",
      customMessage: (
        <div>
          <p>
            Password akun SMTP <strong>{record.relay_email}</strong> berhasil diubah pada sistem.
          </p>
        </div>
      ),
      onOk: () => {
        form.resetFields();
        setOpen(false);
        dataLoading(true);
        setTimeout(async () => {
          await getDataCustomer();
        }, 500);
        dataLoading(false);
      },
    });
  };
  return (
    <Modal
      open={open}
      closable={false}
      centered
      width="524px"
      onOk={handleConfirmChange}
      onCancel={handleCancelChange}
      okText="Simpan"
      cancelText="Batal"
      confirmLoading={loading}>
      <Form
        layout="vertical"
        name="change_pass_smtp"
        initialValues={{ notifikasi: true }}
        form={form}>
        <FormChangePassSMTP
          icon={<ICExclamationCircleOutlined style={{ color: "#FAAD14" }} />}
          setNewPassword={setNewPassword}
          setRepeatPassword={setrepeatNewPassword}
          record={record}
        />
      </Form>
    </Modal>
  );
};

export default ModalChangePassSMTP;
