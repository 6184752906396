import { Modal } from "antd";
import { ICExclamationCircleOutlined } from "components/list/ListIcon";

const { confirm } = Modal;

const ModalWarningLocked = (props) => {
  const {
    okText = "Oke",
    width = 600,
    okButtonProps,
    customMessage,
    message,
    cancelText = "Langganan Sekarang",
  } = props;

  const handleCancel = () => {
    window.location.href = "/checkout";
  };

  const messageModal = customMessage || <p className="mb-0">{message}</p>;
  return confirm({
    ...props,
    icon: <ICExclamationCircleOutlined />,
    content: messageModal,
    centered: true,
    okText: okText,
    okButtonProps: { ...okButtonProps },
    autoFocusButton: null,
    cancelText: cancelText,
    onCancel: handleCancel,
    width: width,
  });
};

export default ModalWarningLocked;
