import { Modal, Typography, Card, Radio, Divider } from "antd";
import XenditLogo from "assets/images/xendit.svg";

const { Text } = Typography;

export const ModalChangePayment = ({
  open,
  close,
  handleChangePayment,
  process,
  setPaymentState,
  paymentState,
}) => {
  const listsManual = [
    { src: "bca.svg", name: "BCA" },
    { src: "mandiri.svg", name: "Mandiri" },
  ];

  const listsXendit = [
    { src: "bca.svg", name: "BCA" },
    { src: "bni.svg", name: "BNI" },
    { src: "mandiri.svg", name: "Mandiri" },
    { src: "bjb.svg", name: "BJB" },
    { src: "bri.svg", name: "BRI" },
    { src: "permata.svg", name: "Permata" },
    { src: "bsi.svg", name: "BSI" },
  ];

  const PaymentMethod = () => {
    return (
      <>
        <Divider className="mt-3 mb-0" />
        <div className="pt-3 px-6">
          <span className="text-secondary text-xs">
            Pembayaran melalui transfer ke virtual account salah satu bank berikut
          </span>
          <div className="flex items-center gap-1 pt-2">
            {listsXendit.map(({ src, name }) => (
              <img key={name} alt={name} src={`assets/images/banks/${src}`} title={name} />
            ))}
          </div>
        </div>
      </>
    );
  };

  return (
    <Modal
      centered
      title={"Ubah Metode Pembayaran"}
      open={open}
      onCancel={close}
      onOk={handleChangePayment}
      okText="Simpan"
      cancelText="Batal"
      confirmLoading={process}>
      <Text>Pilih metode pembayaran yang diinginkan:</Text>
      <Radio.Group
        className="flex flex-col gap-y-2 pt-4"
        defaultValue={paymentState}
        onChange={(e) => setPaymentState(e.target.value)}>
        <Card className={`card-payment ${paymentState === 1 && "selected-payment"}`}>
          <Radio value={1} className="flex items-center change-pay mr-0">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <Text className="font-medium text-base">Virtual Account</Text>
                <Text className="font-medium text-xs text-secondary">Konfirmasi otomatis</Text>
              </div>
              <img src={XenditLogo} alt="" />
            </div>
          </Radio>
          {paymentState === 1 && <PaymentMethod />}
        </Card>
        <Card className={`card-payment ${paymentState === 2 && "selected-payment"}`}>
          <Radio value={2} className="flex items-center change-pay mr-0">
            <div className="flex justify-between">
              <div className="flex flex-col">
                <Text className="font-medium text-base">Bank Transfer</Text>
                <Text className="font-medium text-xs text-secondary">Konfirmasi manual</Text>
              </div>
              <div className="flex gap-x-3 pr-4">
                {listsManual.map(({ src, name }) => (
                  <img key={name} alt={name} src={`assets/images/banks/${src}`} title={name} />
                ))}
              </div>
            </div>
          </Radio>
        </Card>
      </Radio.Group>
      <div className="flex flex-col pt-4">
        <Text className="font-medium">Catatan</Text>
        <ol className="order-list">
          <li>
            <Text>
              Pembayaran melalui Virtual Account akan terupdate secara otomatis pada sistem kami,
              tidak perlu melakukan konfirmasi manual
            </Text>
          </li>
          <li>
            <Text>
              Verifikasi pembayaran dengan Bank Transfer dilakukan maksimal 2x24jam setelah proses
              konfirmasi pembayaran
            </Text>
          </li>
        </ol>
      </div>
    </Modal>
  );
};
