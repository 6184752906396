import { createSlice } from "@reduxjs/toolkit";

export const HistoryList = createSlice({
  name: "HistoryList",
  initialState: {
    value: false, // default state
    code: false,
  },
  reducers: {
    // reducers action
    setHistoryList: (state, { payload, code }) => {
      state.value = payload;
      state.code = code;
    },
  },
});

export const { setHistoryList } = HistoryList.actions;
export default HistoryList.reducer;
