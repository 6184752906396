import { getClientChartSummary, getQuotaUsage } from "api";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReportUsage } from "redux/slices/admin/reportUsage";
import { setReportUsageChart } from "redux/slices/admin/reportUsageChart";
import useDocumentTitle from "utils/useDocumentTitle";
import CustomerReportSummary from "./CustomerReportSummary";
import CustomerReportTable from "./CustomerReportTable";
import "./report.less";

const ReportEmail = () => {
  useDocumentTitle("Report Penggunaan");
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const reportSummary = useSelector(({ reportUsageChart }) => reportUsageChart.value);
  const [filter, setFilter] = useState({
    page: 1,
    limit: "10",
    company_name: "",
  });

  const getCustomerReportSummary = useCallback(async () => {
    setLoading(true);
    const { data: chart } = await getClientChartSummary({ getBy: "month" });
    dispatch(setReportUsageChart(chart));
    setLoading(false);
  }, [dispatch]);

  const getCustomerReportTable = useCallback(async () => {
    setLoading(true);
    const { data: table } = await getQuotaUsage(filter);
    dispatch(setReportUsage(table));
    setLoading(false);
  }, [dispatch, filter]);

  useEffect(() => {
    if (!reportSummary) getCustomerReportSummary();
  }, [reportSummary, getCustomerReportSummary]);

  useEffect(() => {
    if (filter) getCustomerReportTable();
  }, [filter, getCustomerReportTable]);

  return (
    <div className="report-admin">
      <DashboardHeader title="Report Penggunaan Pelanggan" />
      <div className="py-4 px-6">
        <div className="flex flex-col gap-y-6">
          <CustomerReportSummary />
          <CustomerReportTable
            reportUsage={getCustomerReportTable}
            filter={filter}
            setFilter={setFilter}
            loading={loading}
            setLoading={setLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ReportEmail;
