import { Button, List, Progress, Tag } from "antd";
import doneCircle from "assets/images/check-circle-done.svg";
import unfinishedCircle from "assets/images/check-circle-unfinished.svg";
import { useNavigate } from "react-router-dom";

const ProgressCTA = ({ isTrue = 1, listItem = [] }) => {
  const navigate = useNavigate();

  const ListExtra = ({ isDone, to, title }) => {
    if (isDone)
      return (
        <div className="extra px-3 m-auto">
          <Tag color="blue" className="m-0">
            Selesai
          </Tag>
        </div>
      );
    const handleNavigate = () => navigate(to);
    return (
      <div className="extra p-2">
        <Button type="primary" className="py-1 px-3" onClick={handleNavigate}>
          Lengkapi
        </Button>
      </div>
    );
  };

  return (
    <div className="cta-progress bg-white">
      <div className="flex flex-col py-4 px-6 cta-progress__header justify-between">
        <div className="cta-progress__title">
          <h3 className="font-medium text-2xl mb-2">Mulai gunakan {process.env.REACT_APP_NAME}!</h3>
          <h5 className="font-normal text-base">
            Lakukan semua langkah dibawah ini secara lengkap untuk dapat menggunakan trial layanan
          </h5>
        </div>
        <div className="cta-progress__extra">
          <Progress
            percent={25 * isTrue}
            width={80}
            strokeColor="#FAAD14"
            trailColor="#0D9F3F"
            format={() => `${isTrue} dari 4 selesai`}
          />
        </div>
      </div>
      <div className="cta-progress__body py-4 px-6">
        <List
          itemLayout="horizontal"
          dataSource={listItem}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={item.title}
                className={`items-center ${item.isDone ? "done" : ""}`}
                avatar={
                  <img
                    src={item.isDone ? doneCircle : unfinishedCircle}
                    alt=""
                    title={item.title}
                  />
                }
                description={item.description}
              />
              <ListExtra {...item} />
            </List.Item>
          )}
          rowKey={(record) => record.title}
        />
      </div>
    </div>
  );
};

export default ProgressCTA;
