import { Button, Card, Tag } from "antd";
import customerService from "assets/images/customer_service.svg";
import {
  ICArrowRightOutlined,
  ICQuestionCircleOutlined,
  ICTagOutlined,
} from "components/list/ListIcon";
import ProgressPaket from "components/progress-bar/ProgressPaket";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const CardPackageInformation = ({ companyPackage }) => {
  const navigate = useNavigate();
  const handleCheckout = () => {
    navigate("/checkout");
  };

  if (companyPackage)
    return (
      <Card title="Informasi Paket">
        <div className="flex justify-between items-center mb-2">
          <span>Status Langganan</span>
          <Tag color="blue" className="mr-0">
            {companyPackage.subscriptionStatus}
          </Tag>
        </div>
        <div className="flex justify-between items-center mb-4">
          <span>Paket Layanan</span>
          <Tag color="default" icon={<ICTagOutlined />} className="mr-0">
            {companyPackage.activePackage}
          </Tag>
        </div>
        <ProgressPaket
          title="Kuota"
          used={companyPackage.quotaUsage}
          space={companyPackage.quotaMaximum}
        />
        <ProgressPaket
          title="Domain"
          used={companyPackage.domainUsage}
          space={companyPackage.domainMaximum}
        />
      </Card>
    );
  return (
    <Card title="Informasi Paket">
      <p className="mb-3 text-secondary">Belum ada langganan aktif</p>
      <Button type="primary" icon={<ICArrowRightOutlined />} onClick={handleCheckout}>
        Langganan Sekarang
      </Button>
    </Card>
  );
};

const NotificationInformationCard = () => {
  const companyPackage = useSelector(({ companyPackage }) => companyPackage.value);
  return (
    <div className="flex flex-col gap-y-6">
      <div className="notification-information-card">
        <CardPackageInformation companyPackage={companyPackage} />
      </div>
      <div className="notification-support-card">
        <div className="flex flex-col">
          <div className="notification-support-card__content">
            <h5 className="mb-2 font-medium text-base">Butuh Bantuan Kami Lebih Lanjut?</h5>
            <p className="text-secondary mb-2">
              Gunakan layanan support ticket yang kami sediakan untuk mendapatkan support lebih
              lanjut.
            </p>
            <Button
              icon={<ICQuestionCircleOutlined />}
              target="_blank"
              href="https://support.aktiva.co.id "
              type="primary">
              Akses Support Ticket
            </Button>
          </div>
          <div className="notification-cs-img m-auto">
            <img src={customerService} alt="Customer Service" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationInformationCard;
