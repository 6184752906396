import { createSlice } from "@reduxjs/toolkit";

export const companyPackage = createSlice({
  name: "companyPackage",
  initialState: {
    value: false, // default state
  },
  reducers: {
    // reducers action
    setCompanyPackage: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setCompanyPackage } = companyPackage.actions;
export default companyPackage.reducer;
