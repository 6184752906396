import { Button } from "antd";
import React from "react";

const AlertCard = ({ className, titleAlert = "", bodyAlert = "", ...props }) => {
  const { imgSrc, imgAlt = "", btnText = "", onClick, contentBody } = props;
  const bodyRender = contentBody || <p className="text-secondary mb-4">{bodyAlert}</p>;
  return (
    <div className={`alert-card__wrapper ${className}`}>
      <div className="alert-card__left">
        <h5 className="font-medium text-base">{titleAlert}</h5>
        {bodyRender}
        <Button type="primary" onClick={onClick}>
          {btnText}
        </Button>
      </div>
      <div className="alert-card__right">
        <img src={imgSrc} alt={imgAlt} />
      </div>
    </div>
  );
};

export default AlertCard;
