import { createSlice } from "@reduxjs/toolkit";

export const listAllContact = createSlice({
  name: "listAllContact",
  initialState: {
    value: false,
  },
  reducers: {
    setListAllContact: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setListAllContact } = listAllContact.actions;
export default listAllContact.reducer;
