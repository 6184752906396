import Spinner from "components/Spinner/Spinner";
import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { getCookieToken } from "./helpers";

const CheckRegisterConfig = ({ user }) => {
  const { pathname: path } = useLocation();
  const navigate = useNavigate();

  const token = getCookieToken();

  useEffect(() => {
    if (!token && !user) window.location.href = "/register";
    else {
      if (user && user.isAdmin) navigate("/admin/main");
      else if (user && user.isSetup) {
        if (user.isSetupDomain && user.isSetUpCompany) navigate("/main");
        else if (
          (user.isSetupDomain && !user.isSetUpCompany) ||
          (!user.isSetupDomain && !user.isSetUpCompany)
        )
          navigate("/register/company-data");
        else navigate("/main");
      } else if (user && !user.isSetup) {
        if (!user.isSetupDomain && path === "/register/verifikasi") return <Outlet />;
        else if (!user.isSetupDomain && path === "/register/company-data")
          navigate("/register/verifikasi");
        else <Outlet />;
      } else if (user === "not-verified" && token) {
        navigate("/register/verifikasi");
      }
    }
  }, [navigate, token, user, path]);

  return user ? <Outlet /> : <Spinner full />;
};

export default CheckRegisterConfig;
