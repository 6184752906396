import suspendImg from "assets/images/data_maintenance.svg";
import AlertCard from "components/box-card/AlertCard";
import React from "react";
import { useNavigate } from "react-router-dom";

const SuspendAlert = () => {
  const navigate = useNavigate();
  const navCheckout = () => navigate("/checkout");
  return (
    <AlertCard
      titleAlert={`Layanan ${process.env.REACT_APP_NAME} Anda Terkena Suspend`}
      bodyAlert={`Akun ${process.env.REACT_APP_NAME} ditangguhkan karena pembayaran anda sudah melewati tanggal jatuh tempo. Untuk dapat menggunakan kembali layanan ${process.env.REACT_APP_NAME}, silakan dapat berlangganan kembali sesuai dengan kebutuhan paket anda.`}
      btnText="Berlangganan Sekarang"
      imgSrc={suspendImg}
      onClick={navCheckout}
      className="red-bg"
    />
  );
};

export default SuspendAlert;
