import { Modal } from "antd";
import { ConfirmManualPayment, RecordManualPayment } from "api";
import { FormManualPayment } from "components/form/FormManualPayment";
import { useState } from "react";
import { format } from "date-fns";
import ModalSuccess from "./ModalSuccess";
import ModalError from "./ModalError";

export const ModalConfirmManual = ({
  open,
  close,
  getCurrentPayments,
  dataRow,
  form,
  adminConfirmation,
  setBillCount,
  setPphCount,
  billPayment,
  billCount,
  pphPayment,
  pphCount,
  handleBillPayment,
  handlePphPayment,
  callbackData,
  tanggalFieldValue,
  setTanggalFieldValue,
  rekeningTargetFieldValue,
  setRekeningTargetFieldValue,
  sourceFieldValue,
  setSourceFieldValue,
  fileValue,
  setFileValue,
  validateNominalPajak,
  callBackConfirm,
  callbackInvoice,
  callbackSummary,
  setOpenDrawerConfirmation,
  zIndex,
}) => {
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleConfirmPayment = async () => {
    setLoadingConfirm(true);

    const requestBody = {
      amount: billCount,
      sourceBank: sourceFieldValue,
      destinationBank: rekeningTargetFieldValue,
      date: format(new Date(tanggalFieldValue), "yyyy-MM-dd"),
      uploadFile: fileValue.file.originFileObj,
    };

    const response = await ConfirmManualPayment(requestBody);

    if (response.code === 200) {
      return ModalSuccess({
        title: "Konfirmasi pembayaran berhasil",
        customMessage:
          "Terima kasih atas konfirmasi pembayaran yang disampaikan. Tim kami akan melakukan pengecekkan terhadap pembayaran anda. Layanan akan otomatis aktif atau diperpanjang jika pembayaran divalidasi.",
        width: 471,
        onOk: () => {
          close();
          setLoadingConfirm(false);
          callbackData();
        },
      });
    } else {
      return ModalError({
        title: "Terdapat kesalahan pada sistem",
        width: 471,
        customMessage:
          "Konfirmasi pembayaran gagal dilakukan karena terkendala pada sistem. Silakan coba lagi atau dapat menghubungi kami melalui Support Ticket.",
        onOk: () => {
          close();
          setLoadingConfirm(false);
        },
      });
    }
  };

  const HandleManualPayment = async () => {
    setLoadingSubmit(true);
    const requestBody = {
      amount: billCount,
      taxAmount: pphCount ? pphCount : 0,
      withTax: pphPayment ? true : false,
      bankId: rekeningTargetFieldValue,
      invoiceUuid: dataRow.invUuid,
      date: format(new Date(tanggalFieldValue), "yyyy-MM-dd"),
    };

    const response = await RecordManualPayment(requestBody);

    if (response.code === 404) {
      return ModalError({
        title: "Bank ID Not Found",
        customMessage: response.msg,
        width: 470,
        onOk: () => {
          close();
          setLoadingSubmit(false);
        },
      });
    } else if (response.code === 400) {
      return ModalError({
        title: "Input doesnt Match",
        customMessage: response.msg,
        width: 470,
        onOk: () => {
          close();
          setLoadingSubmit(false);
        },
      });
    } else if (response.code === 200) {
      return ModalSuccess({
        title: "Record payment berhasil dilakukan",
        customMessage: (
          <span>
            Pembayaran untuk nomor invoice <span className="font-medium">{dataRow.invNumber}</span>{" "}
            milik {dataRow.companyName} telah berhasil diperbaharui pada sistem.
          </span>
        ),
        width: 470,
        onOk: () => {
          close();
          setLoadingSubmit(false);
          setOpenDrawerConfirmation(false);
          callBackConfirm();
          callbackSummary();
          callbackInvoice();
        },
      });
    } else {
      return ModalError({
        title: "Terdapat kesalahan pada sistem",
        customMessage:
          "Terdapat kendala pada sistem saat memperbarui pembayaran customer. Silakan hubungi Tim Product Development",
        width: 470,
        onOk: () => {
          close();
          setLoadingSubmit(false);
        },
      });
    }
  };

  return (
    <Modal
      centered
      title={"Konfirmasi Pembayaran"}
      open={open}
      onCancel={close}
      okText="Simpan"
      cancelText="Batal"
      onOk={adminConfirmation ? HandleManualPayment : handleConfirmPayment}
      okButtonProps={{
        disabled: billPayment
          ? !adminConfirmation
            ? !(tanggalFieldValue && rekeningTargetFieldValue && sourceFieldValue && fileValue)
            : !(tanggalFieldValue && rekeningTargetFieldValue)
          : !billPayment
          ? !adminConfirmation
            ? !(
                billCount &&
                tanggalFieldValue &&
                rekeningTargetFieldValue &&
                sourceFieldValue &&
                fileValue
              )
            : !(billCount && tanggalFieldValue && rekeningTargetFieldValue)
          : true,
      }}
      confirmLoading={adminConfirmation ? loadingSubmit : loadingConfirm}
      className="modal-confirm"
      zIndex={zIndex}>
      <FormManualPayment
        paymentData={getCurrentPayments ? getCurrentPayments?.data : dataRow}
        form={form}
        setBillCount={setBillCount}
        setPphCount={setPphCount}
        billPayment={billPayment}
        billCount={billCount}
        pphPayment={pphPayment}
        pphCount={pphCount}
        setRekeningTargetFieldValue={setRekeningTargetFieldValue}
        setSourceFieldValue={setSourceFieldValue}
        setTanggalFieldValue={setTanggalFieldValue}
        handleBillPayment={handleBillPayment}
        handlePphPayment={handlePphPayment}
        setFileValue={setFileValue}
        adminConfirmation={adminConfirmation}
        validateNominalPajak={validateNominalPajak}
      />
    </Modal>
  );
};
