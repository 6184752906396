import { setUpNewDomain } from "api";
import header from "assets/images/checkout_loading.svg";
import Spinner from "components/Spinner/Spinner";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setSetupLayanan } from "redux/slices/setupLayanan";

const ProcessingDomain = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(({ userData }) => userData.value);

  const setUpDomain = useCallback(async () => {
    const { msg, status } = await setUpNewDomain(state);
    setTimeout(() => {
      if (msg.includes("Created") && status === "success") navigate("success", { state: user });
      else if (status === "failed") navigate("failed", { state: user });
      dispatch(setSetupLayanan(false));
    }, 3000);
  }, [state, navigate, user, dispatch]);

  useEffect(() => {
    if (state) setUpDomain();
    else navigate("/setup");
  }, [setUpDomain, state, navigate, user]);

  return (
    <>
      <img src={header} alt="" className="mb-4" />
      <h2 className="mx-auto text-3xl mb-1">
        Aktivasi Trial {process.env.REACT_APP_NAME} <Spinner />
      </h2>
      <p className="text-secondary mb-0">Mohon tunggu hingga proses aktivasi trial selesai</p>
    </>
  );
};

export default ProcessingDomain;
