import { createSlice } from "@reduxjs/toolkit";

export const SummarySuppress = createSlice({
  name: "SummarySuppress",
  initialState: {
    value: false,
    code: false,
  },
  reducers: {
    setSummarySuppress: (state, { payload, code }) => {
      state.value = payload;
      state.code = code;
    },
  },
});

export const { setSummarySuppress } = SummarySuppress.actions;
export default SummarySuppress.reducer;
