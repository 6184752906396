import { createSlice } from "@reduxjs/toolkit";

export const fakturPajak = createSlice({
  name: "fakturPajak",
  initialState: {
    value: false,
  },
  reducers: {
    setFakturPajak: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setFakturPajak } = fakturPajak.actions;
export default fakturPajak.reducer;
