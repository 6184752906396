import { Button, Card, Form, Select } from "antd";
import { getBillingReport } from "api";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import { ICFilterOutlined } from "components/list/ListIcon";
import TableReportBilling from "components/table/TableReportBilling";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReportBilling } from "redux/slices/reportBilling";
import useGetPackageListFilter from "utils/hooks/useGetPackageListFilter";
import useDocumentTitle from "utils/useDocumentTitle";
import "./reportBilling.less";

const ReportBilling = () => {
  useDocumentTitle("Report Tagihan");
  useGetPackageListFilter();
  const dispatch = useDispatch();
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1200;
  const breakpoint_third = 768;
  const [subName, setSubName] = useState([]);
  const [subStatus, setSubStatus] = useState([]);
  const [subPeriod, setSubPeriod] = useState([]);
  const [companyNPWP, setCompanyNPWP] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
    limit: "10",
    company_name: "",
    subcription_status: [],
    subcription_name: [],
    subcription_period: [],
    company_npwp: "",
  });
  const reportBilling = useSelector(({ reportBilling }) => reportBilling.value);
  const listPackageFilter = useSelector(({ packageListFilter }) => packageListFilter.value);

  const getData = useCallback(async () => {
    setLoading(true);
    const { data } = await getBillingReport(filter);
    dispatch(setReportBilling(data));
    setLoading(false);
  }, [dispatch, filter]);

  useEffect(() => {
    if (filter) getData();
  }, [getData, filter]);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const AccordionFilter = () => {
    const onFilter = (values) => {
      const {
        subcription_name: subName = [],
        subcription_status: subStatus = [],
        subcription_period: subPeriod = [],
        company_npwp: companyNPWP = [],
      } = values;
      const name = subName && subName.filter((item) => item !== "selectAll");
      const Status = subStatus && subStatus.filter((item) => item !== "selectAll");
      const period = subPeriod && subPeriod.filter((item) => item !== "selectAll");
      const npwp = companyNPWP && companyNPWP.filter((item) => item !== "selectAll");

      setSubName(subName);
      setSubStatus(subStatus);
      setSubPeriod(subPeriod);
      setCompanyNPWP(companyNPWP);

      setFilter({
        ...filter,
        page: 1,
        limit: filter.limit,
        subcription_name: name.length === 5 ? [] : name,
        subcription_status: Status.length === 2 ? [] : Status,
        subcription_period: period.length === 3 ? [] : period,
        company_npwp: npwp.length === 2 ? "" : npwp.toString(),
      });
    };

    return {
      ...(width < breakpoint ? (
        <Form
          className="formFilter"
          initialValues={{
            subcription_name: subName,
            subcription_status: subStatus,
            subcription_period: subPeriod,
            company_npwp: companyNPWP,
          }}
          onFinish={onFilter}>
          <div className="flex gap-x-3">
            <Form.Item label="Paket" name="subcription_name">
              <Select
                mode="multiple"
                allowClear
                className="filter-billing-report"
                placeholder="Semua Paket"
                maxTagCount="responsive">
                <Select.Option value="selectAll">Semua Paket</Select.Option>
                {listPackageFilter &&
                  listPackageFilter.rows.map((item) => (
                    <Select.Option key={item.uuid} value={item.packageName}>
                      {item.packageName}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label="Subscription" name="subcription_status">
              <Select
                mode="multiple"
                allowClear
                className="filter-billing-report"
                placeholder="Semua Status"
                maxTagCount="responsive">
                <Select.Option value="selectAll">Semua Status</Select.Option>
                <Select.Option value="active">Active</Select.Option>
                <Select.Option value="inactive">Inactive</Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div className="flex gap-x-2">
            <Form.Item label="Periode" name="subcription_period">
              <Select
                mode="multiple"
                allowClear
                className="filter-billing-report"
                placeholder="Semua Data"
                maxTagCount="responsive">
                <Select.Option value="selectAll">Semua Data</Select.Option>
                <Select.Option value="Bulanan">Bulanan</Select.Option>
                <Select.Option value="Triwulan">Triwulan</Select.Option>
                <Select.Option value="Tahuhan">Tahunan</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item label="NPWP" name="company_npwp">
              <Select
                mode="multiple"
                allowClear
                className="filter-billing-report"
                placeholder="Semua Status"
                maxTagCount="responsive">
                <Select.Option value="selectAll">Semua Status</Select.Option>
                <Select.Option value="true">Tersedia</Select.Option>
                <Select.Option value="false">Tidak Tersedia</Select.Option>
              </Select>
            </Form.Item>
            {width > breakpoint_third && (
              <div className="button-advance-filter">
                <Button type="primary" htmlType="submit" icon={<ICFilterOutlined />}>
                  Apply Filter
                </Button>
              </div>
            )}
          </div>
          {width <= breakpoint_third && (
            <div className="flex button-advance-filter justify-end">
              <Button type="primary" htmlType="submit" icon={<ICFilterOutlined />}>
                Apply Filter
              </Button>
            </div>
          )}
        </Form>
      ) : (
        <Form
          layout="inline"
          className="formFilter"
          initialValues={{
            subcription_name: subName,
            subcription_status: subStatus,
            subcription_period: subPeriod,
            company_npwp: companyNPWP,
          }}
          onFinish={onFilter}>
          <Form.Item label="Paket" name="subcription_name">
            <Select
              mode="multiple"
              allowClear
              className="filter-billing-report"
              placeholder="Semua Paket"
              maxTagCount="responsive">
              <Select.Option value="selectAll">Semua Paket</Select.Option>
              {listPackageFilter &&
                listPackageFilter.rows.map((item) => (
                  <Select.Option key={item.uuid} value={item.packageName}>
                    {item.packageName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Subscription" name="subcription_status">
            <Select
              mode="multiple"
              allowClear
              className="filter-billing-report"
              placeholder="Semua Status"
              maxTagCount="responsive">
              <Select.Option value="selectAll">Semua Status</Select.Option>
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="inactive">Inactive</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="Periode" name="subcription_period">
            <Select
              mode="multiple"
              allowClear
              className="filter-billing-report"
              placeholder="Semua Data"
              maxTagCount="responsive">
              <Select.Option value="selectAll">Semua Data</Select.Option>
              <Select.Option value="Bulanan">Bulanan</Select.Option>
              <Select.Option value="Triwulan">Triwulan</Select.Option>
              <Select.Option value="Tahuhan">Tahunan</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label="NPWP" name="company_npwp">
            <Select
              mode="multiple"
              allowClear
              className="filter-billing-report"
              placeholder="Semua Status"
              maxTagCount="responsive">
              <Select.Option value="selectAll">Semua Status</Select.Option>
              <Select.Option value="true">Tersedia</Select.Option>
              <Select.Option value="false">Tidak Tersedia</Select.Option>
            </Select>
          </Form.Item>
          <div className="button-advance-filter">
            <Button type="primary" htmlType="submit" icon={<ICFilterOutlined />}>
              Apply Filter
            </Button>
          </div>
        </Form>
      )),
    };
  };

  return (
    <div className="report-billing">
      <DashboardHeader title="Report Tagihan" />
      <div className="flex flex-col gap-y-6 px-6 py-4">
        <Card loading={!reportBilling}>
          <TableReportBilling
            filter={filter}
            setFilter={setFilter}
            loading={loading}
            renderFilter={AccordionFilter}
            responsiveWidth={width}
            breakpoint_third={breakpoint_third}
          />
        </Card>
      </div>
    </div>
  );
};

export default ReportBilling;
