export const BankIndonesia = [
  {
    label: "Bank Aceh Syariah",
    value: "Bank Aceh Syariah",
  },
  {
    label: "Bank Aladin Syariah",
    value: "Bank Aladin Syariah",
  },
  {
    label: "Bank Allo Indonesia",
    value: "Bank Allo Indonesia",
  },
  {
    label: "Bank Amar Indonesia",
    value: "Bank Amar Indonesia",
  },
  {
    label: "Bank ANZ Indonesia",
    value: "Bank ANZ Indonesia",
  },
  {
    label: "Bank Artha Graha Internasional",
    value: "Bank Artha Graha Internasional",
  },
  {
    label: "Bank Banten",
    value: "Bank Banten",
  },
  {
    label: "Bank BCA Syariah",
    value: "Bank BCA Syariah",
  },
  {
    label: "Bank Bengkulu",
    value: "Bank Bengkulu",
  },
  {
    label: "Bank BJB",
    value: "Bank BJB",
  },
  {
    label: "Bank BJB Syariah",
    value: "Bank BJB Syariah",
  },
  {
    label: "Bank BNP Paribas Indonesia",
    value: "Bank BNP Paribas Indonesia",
  },
  {
    label: "Bank BPD Bali",
    value: "Bank BPD Bali",
  },
  {
    label: "Bank BPD DIY",
    value: "Bank BPD DIY",
  },
  {
    label: "Bank BRK Syariah",
    value: "Bank BRK Syariah",
  },
  {
    label: "Bank BSG",
    value: "Bank BSG",
  },
  {
    label: "Bank BTPN",
    value: "Bank BTPN",
  },
  {
    label: "Bank BTPN Syariah",
    value: "Bank BTPN Syariah",
  },
  {
    label: "Bank Bumi Arta",
    value: "Bank Bumi Arta",
  },
  {
    label: "Bank Capital Indonesia",
    value: "Bank Capital Indonesia",
  },
  {
    label: "Bank Central Asia",
    value: "Bank Central Asia",
  },
  {
    label: "Bank China Construction Bank Indonesia",
    value: "Bank China Construction Bank Indonesia",
  },
  {
    label: "Bank CIMB Niaga",
    value: "Bank CIMB Niaga",
  },
  {
    label: "Bank Commonwealth",
    value: "Bank Commonwealth",
  },
  {
    label: "Bank CTBC Indonesia",
    value: "Bank CTBC Indonesia",
  },
  {
    label: "Bank Danamon Indonesia",
    value: "Bank Danamon Indonesia",
  },
  {
    label: "Bank DBS Indonesia",
    value: "Bank DBS Indonesia",
  },
  {
    label: "Bank Digital BCA",
    value: "Bank Digital BCA",
  },
  {
    label: "Bank DKI",
    value: "Bank DKI",
  },
  {
    label: "Bank Ganesha",
    value: "Bank Ganesha",
  },
  {
    label: "Bank Hibank Indonesia",
    value: "Bank Hibank Indonesia",
  },
  {
    label: "Bank HSBC Indonesia",
    value: "Bank HSBC Indonesia",
  },
  {
    label: "Bank IBK Indonesia",
    value: "Bank IBK Indonesia",
  },
  {
    label: "Bank ICBC Indonesia",
    value: "Bank ICBC Indonesia",
  },
  {
    label: "Bank Ina Perdana",
    value: "Bank Ina Perdana",
  },
  {
    label: "Bank Index Selindo",
    value: "Bank Index Selindo",
  },
  {
    label: "Bank Jago",
    value: "Bank Jago",
  },
  {
    label: "Bank Jambi",
    value: "Bank Jambi",
  },
  {
    label: "Bank Jasa Jakarta",
    value: "Bank Jasa Jakarta",
  },
  {
    label: "Bank Jateng",
    value: "Bank Jateng",
  },
  {
    label: "Bank Jatim",
    value: "Bank Jatim",
  },
  {
    label: "Bank J Trust Indonesia",
    value: "Bank J Trust Indonesia",
  },
  {
    label: "Bank Kalbar",
    value: "Bank Kalbar",
  },
  {
    label: "Bank Kalsel",
    value: "Bank Kalsel",
  },
  {
    label: "Bank Kalteng",
    value: "Bank Kalteng",
  },
  {
    label: "Bank Kaltimtara",
    value: "Bank Kaltimtara",
  },
  {
    label: "Bank KB Bukopin",
    value: "Bank KB Bukopin",
  },
  {
    label: "Bank KB Bukopin Syariah",
    value: "Bank KB Bukopin Syariah",
  },
  {
    label: "Bank KEB Hana Indonesia",
    value: "Bank KEB Hana Indonesia",
  },
  {
    label: "Bank Krom Indonesia",
    value: "Bank Krom Indonesia",
  },
  {
    label: "Bank Lampung",
    value: "Bank Lampung",
  },
  {
    label: "Bank Maluku Malut",
    value: "Bank Maluku Malut",
  },
  {
    label: "Bank Mandiri",
    value: "Bank Mandiri",
  },
  {
    label: "Bank Mandiri Taspen",
    value: "Bank Mandiri Taspen",
  },
  {
    label: "Bank Maspion",
    value: "Bank Maspion",
  },
  {
    label: "Bank Mayapada Internasional",
    value: "Bank Mayapada Internasional",
  },
  {
    label: "Bank Maybank Indonesia",
    value: "Bank Maybank Indonesia",
  },
  {
    label: "Bank Mega",
    value: "Bank Mega",
  },
  {
    label: "Bank Mega Syariah",
    value: "Bank Mega Syariah",
  },
  {
    label: "Bank Mestika Dharma",
    value: "Bank Mestika Dharma",
  },
  {
    label: "Bank Mizuho Indonesia",
    value: "Bank Mizuho Indonesia",
  },
  {
    label: "Bank MNC Internasional",
    value: "Bank MNC Internasional",
  },
  {
    label: "Bank Muamalat Indonesia",
    value: "Bank Muamalat Indonesia",
  },
  {
    label: "Bank Multiarta Sentosa",
    value: "Bank Multiarta Sentosa",
  },
  {
    label: "Bank Nagari",
    value: "Bank Nagari",
  },
  {
    label: "Bank Nano Syariah",
    value: "Bank Nano Syariah",
  },
  {
    label: "Bank Nationalnobu",
    value: "Bank Nationalnobu",
  },
  {
    label: "Bank Negara Indonesia",
    value: "Bank Negara Indonesia",
  },
  {
    label: "Bank Neo Commerce",
    value: "Bank Neo Commerce",
  },
  {
    label: "Bank NTB Syariah",
    value: "Bank NTB Syariah",
  },
  {
    label: "Bank NTT",
    value: "Bank NTT",
  },
  {
    label: "Bank OCBC Indonesia",
    value: "Bank OCBC Indonesia",
  },
  {
    label: "Bank of India Indonesia",
    value: "Bank of India Indonesia",
  },
  {
    label: "Bank Oke Indonesia",
    value: "Bank Oke Indonesia",
  },
  {
    label: "Bank Panin",
    value: "Bank Panin",
  },
  {
    label: "Bank Panin Dubai Syariah",
    value: "Bank Panin Dubai Syariah",
  },
  {
    label: "Bank Papua",
    value: "Bank Papua",
  },
  {
    label: "Bank Permata",
    value: "Bank Permata",
  },
  {
    label: "Bank QNB Indonesia",
    value: "Bank QNB Indonesia",
  },
  {
    label: "Bank Rakyat Indonesia",
    value: "Bank Rakyat Indonesia",
  },
  {
    label: "Bank Raya Indonesia",
    value: "Bank Raya Indonesia",
  },
  {
    label: "Bank Resona Perdania",
    value: "Bank Resona Perdania",
  },
  {
    label: "Bank Sahabat Sampoerna",
    value: "Bank Sahabat Sampoerna",
  },
  {
    label: "Bank SBI Indonesia",
    value: "Bank SBI Indonesia",
  },
  {
    label: "Bank Seabank Indonesia",
    value: "Bank Seabank Indonesia",
  },
  {
    label: "Bank Shinhan Indonesia",
    value: "Bank Shinhan Indonesia",
  },
  {
    label: "Bank Sinarmas",
    value: "Bank Sinarmas",
  },
  {
    label: "Bank Sulselbar",
    value: "Bank Sulselbar",
  },
  {
    label: "Bank Sulteng",
    value: "Bank Sulteng",
  },
  {
    label: "Bank Sultra",
    value: "Bank Sultra",
  },
  {
    label: "Bank Sumsel Babel",
    value: "Bank Sumsel Babel",
  },
  {
    label: "Bank Sumut",
    value: "Bank Sumut",
  },
  {
    label: "Bank Superbank Indonesia",
    value: "Bank Superbank Indonesia",
  },
  {
    label: "Bank Syariah Indonesia",
    value: "Bank Syariah Indonesia",
  },
  {
    label: "Bank Tabungan Negara",
    value: "Bank Tabungan Negara",
  },
  {
    label: "Bank UOB Indonesia",
    value: "Bank UOB Indonesia",
  },
  {
    label: "Bank Victoria Internasional",
    value: "Bank Victoria Internasional",
  },
  {
    label: "Bank Victoria Syariah",
    value: "Bank Victoria Syariah",
  },
  {
    label: "Bank Woori Saudara",
    value: "Bank Woori Saudara",
  },
];

export const listsBankTarget = [
  {
    label: "Bank Mandiri",
    value: "Bank Mandiri",
  },
  {
    label: "Bank BCA",
    value: "Bank BCA",
  },
];
