import { createSlice } from "@reduxjs/toolkit";

export const invoice = createSlice({
  name: "invoice",
  initialState: {
    value: false,
  },
  reducers: {
    setInvoice: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setInvoice } = invoice.actions;
export default invoice.reducer;
