import { Button, Divider, Form, Input, Select, Tabs } from "antd";
import { createNotification } from "api";
import DashboardPageHeader from "components/dashboard-header/DashboardHeader";
import MarkdownInput from "components/input/MarkdownInput";
import {
  ICCloseOutlined,
  ICEditOutlined,
  ICEyeOutlined,
  ICSaveOutlined,
} from "components/list/ListIcon";
import ModalConfirm from "components/popups/modal/ModalConfirm";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import { format } from "date-fns";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { requiredRules } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import "./manageNotification.less";

const CreateNotification = () => {
  useDocumentTitle("Tambah Notifikasi");
  const navigate = useNavigate();
  const [documentationBody, setDocumentationBody] = useState({
    title: "",
    content: "",
    category: "info",
  });
  const valueFormOnChange = (_, { title, content, preview }) => {
    setDocumentationBody({ ...documentationBody, title, content, preview });
  };
  const onSelectStatus = (value) => setDocumentationBody({ ...documentationBody, category: value });

  const cancelNotification = () => {
    ModalConfirm({
      title: "Batal Tambah Notifikasi",
      customMessage: (
        <>
          <p>Apakah anda yakin ingin meninggalkaan halaman notifikasi yang sedang anda edit?</p>
          <p className="mb-0">
            Seluruh perubahan notifikasi tidak akan tersimpan jika anda keluar.
          </p>
        </>
      ),
      okText: "Ya, tinggalkan halaman",
      okButtonProps: { danger: true },
      onOk: () => navigate("/admin/manage-notification"),
    });
  };

  const publishNotification = async (values) => {
    const dateNow = format(new Date(), "yyyy-MM-dd hh:mm");
    const { status } = await createNotification({ ...documentationBody, date: dateNow });
    if (status === "success") {
      ModalSuccess({
        title: "Notifikasi Berhasil Dikirim",
        message: "Notifikasi berhasil dibroadcast ke seluruh customer.",
        onOk: () => {
          navigate("/admin/manage-notification");
        },
      });
    }
  };

  return (
    <div className="createNotification">
      <DashboardPageHeader title="Tambah Notifikasi" backPath="/admin/manage-notification" />
      <div className="flex gap-x-6 px-6 py-4">
        <div className="createNotification__left">
          <Form
            name="notification"
            className="w-full"
            layout="vertical"
            onValuesChange={valueFormOnChange}
            onFinish={publishNotification}>
            <Form.Item name="title" className="mb-4" rules={[requiredRules]}>
              <Input placeholder="Judul Notifikasi" />
            </Form.Item>
            <Form.Item label="Summary Notifikasi" name="preview" rules={[requiredRules]}>
              <Input.TextArea
                placeholder="Ketik summary notifikasi disini.."
                autoSize={{ minRows: 2, maxRows: 2 }}
                maxLength="255"
              />
            </Form.Item>
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  label: (
                    <span>
                      <ICEditOutlined />
                      Edit Notifikasi
                    </span>
                  ),
                  key: "edit",
                  children: (
                    <Form.Item name="content" className="mb-4" rules={[requiredRules]}>
                      <Input.TextArea
                        placeholder="Ketik konten notifikasi dengan format Markdown disini.."
                        autoSize={{ minRows: 22, maxRows: 22 }}
                      />
                    </Form.Item>
                  ),
                },
                {
                  label: (
                    <span>
                      <ICEyeOutlined />
                      Pratinjau
                    </span>
                  ),
                  key: "pratinjau",
                  children: <MarkdownInput data={documentationBody.content} />,
                },
              ]}
            />
          </Form>
        </div>
        <div className="createNotification__right">
          <div className="createNotification__action bg-white p-6">
            <div className="flex gap-x-2 items-center my-1">
              <Button
                type="default"
                className="w-full"
                icon={<ICCloseOutlined />}
                onClick={cancelNotification}>
                Batalkan
              </Button>
              <Button
                type="primary"
                className="w-full"
                htmlType="submit"
                icon={<ICSaveOutlined />}
                form="notification">
                Publish
              </Button>
            </div>
            <Divider className="my-4" />
            <div className="flex items-center justify-between">
              <span className="font-medium">Kategori</span>
              <Select
                placeholder="Pilih"
                defaultValue="info"
                className="category-list"
                onSelect={onSelectStatus}>
                <Select.Option value="info">Info</Select.Option>
                <Select.Option value="promo">Promo</Select.Option>
              </Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateNotification;
