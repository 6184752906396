import { Dropdown, Input, Menu } from "antd";
import { ICDownOutlined } from "components/list/ListIcon";
import React, { useState } from "react";
import "./InputWithDropdown.styles.less";

const SearchDropdown = ({ icon: Icon, menu, filter, setFilter }) => {
  return (
    <Dropdown
      destroyPopupOnHide
      trigger={["click"]}
      overlay={menu}
      className="inputDropdown__dropdown"
      overlayClassName="inputDropdown__overlay">
      <span className="flex items-center gap-1 text-secondary">
        <Icon />
        <ICDownOutlined />
      </span>
    </Dropdown>
  );
};

const InputWithDropdown = ({ items, actionList, filter, setFilter }) => {
  const [action, setAction] = useState(items[0].key);
  const [searchValue, setSearchValue] = useState("");

  const onSelectDropdown = ({ key }) => {
    setAction(key);
    setSearchValue("");
    setFilter({ ...filter, [action]: [] });
  };

  const menu = <Menu onClick={onSelectDropdown} items={items} />;
  const searchAction = actionList.find((item) => item.action === action);

  const handleSearch = (value) => {
    if (!value) {
      setFilter({ ...filter, [action]: [] });
    } else {
      setFilter({ ...filter, [action]: value });
    }
  };

  return (
    <Input.Search
      className="inputDropdown"
      enterButton
      addonBefore={
        <SearchDropdown
          menu={menu}
          icon={searchAction.icon}
          action={searchAction.action}
          filter={filter}
          setFilter={setFilter}
        />
      }
      value={searchValue}
      onChange={({ target: { value } }) => setSearchValue(value)}
      onSearch={handleSearch}
      placeholder={searchAction.placeholder}
    />
  );
};

export default InputWithDropdown;
