import { createSlice } from "@reduxjs/toolkit";

export const setupLayanan = createSlice({
  name: "setupLayanan",
  initialState: {
    value: false, // default state
  },
  reducers: {
    // reducers action
    setSetupLayanan: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setSetupLayanan } = setupLayanan.actions;
export default setupLayanan.reducer;
