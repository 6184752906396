import { Button, Card, Divider, Tooltip } from "antd";
import {
  changePhotoProfileCompany,
  getAccountSMTP,
  getCompany,
  getConfigNotification,
  getContact,
  getDomain,
  getInfoProfile,
} from "api";
import CardCompanyInformation from "components/box-card/CardCompanyInformation";
import CardContactInformation from "components/box-card/CardContactInformation";
import CardProfileNew from "components/box-card/CardProfileNew";
import CardRegisteredDomain from "components/box-card/CardRegisteredDomain";
import {
  ICSelectOutlined,
  ICExclamationCircleFilled,
  ICCheckCircleFilled,
} from "components/list/ListIcon";
import ProgressPaket from "components/progress-bar/ProgressPaket";
import Spinner from "components/Spinner/Spinner";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserProfile } from "redux/slices/profile";
import useGetUserPackage from "utils/hooks/useGetUserPackage";
import useDocumentTitle from "utils/useDocumentTitle";
import DNSAlert from "../main/alert/DNSAlert";
import DomainAlert from "../main/alert/DomainAlert";
import AccountInformation from "./AccountInformation";
import NotificationSettings from "./NotificationSettings";
import "./profile.less";
import OverQouta from "../main/alert/OverQouta";

const HasAlert = ({ user = false }) => {
  if ((user.inactiveDomain || user.dnsStatus) && user.role !== "billing")
    return (
      <div className="flex flex-col gap-y-3">
        {user.inactiveDomain && user.isSetupDomain && (
          <DomainAlert domain={user.inactiveDomain} user={user} />
        )}
        {user.dnsStatus && user.isSetupDomain && <DNSAlert domain={user.dnsStatus} user={user} />}
      </div>
    );

  return "";
};

const HasPackage = ({ packageData }) => {
  if (packageData === "package")
    return (
      <div className="flex flex-col items-center justify-center gap-y-4">
        <h5 className="text-base text-secondary my-1">Tidak ada paket aktif</h5>
        <Divider className="my-2" />
      </div>
    );
  if (!(packageData.startDateNextSubscriptionPeriod && packageData.endDateNextSubscriptionPeriod)) {
    return (
      <>
        <div className="flex justify-between mb-4">
          <p className="mb-0">Periode Langganan</p>
          <p className="text-secondary mb-0">
            {`${packageData?.startDateSubs === "-" ? "" : packageData?.startDateSubs} - ${
              packageData?.endDateSubs === "-" ? "" : packageData?.endDateSubs
            }`}
          </p>
        </div>
        <div className="flex justify-between mb-4">
          <p className="mb-0">Periode Kuota</p>
          <p className="text-secondary mb-0">
            {`${packageData?.startDateQuota === "-" ? "" : packageData?.startDateQuota} - ${
              packageData?.endDateQuota === "-" ? "" : packageData?.endDateQuota
            }`}
          </p>
        </div>
        <ProgressPaket
          title="Kuota"
          space={packageData?.maxMailCountAllowed}
          used={packageData?.usageMailCount}
        />
        <ProgressPaket
          title="Domain"
          space={packageData?.maxDomainAllowed}
          used={packageData?.usageDomain}
        />
      </>
    );
  } else {
    return (
      <>
        <div className="flex justify-between mb-4">
          <p className="mb-0">Periode Langganan</p>
          <p className="text-secondary mb-0">
            {`${packageData?.startDateSubs === "-" ? "" : packageData?.startDateSubs} - ${
              packageData?.endDateSubs === "-" ? "" : packageData?.endDateSubs
            }`}
          </p>
        </div>
        <div className="flex justify-between mb-4">
          <p className="mb-0">Periode Kuota</p>
          <p className="text-secondary mb-0">
            {`${packageData?.startDateQuota === "-" ? "" : packageData?.startDateQuota} - ${
              packageData?.endDateQuota === "-" ? "" : packageData?.endDateQuota
            }`}
          </p>
        </div>
        {packageData?.nextPeriodStatus === "unconfirm" ? (
          <div className="flex justify-between mb-4">
            <p className="mb-0">
              Periode Selanjutnya{" "}
              <Tooltip
                title="Masih terdapat tagihan yang belum dibayar dan layanan berpotensi terkena suspend"
                overlayStyle={{
                  width: "auto",
                  whiteSpace: "nowrap",
                  maxWidth: "none",
                }}
                placement="bottom">
                <ICExclamationCircleFilled style={{ color: "#FAAD14", marginLeft: "2px" }} />
              </Tooltip>
            </p>
            <p className="text-secondary mb-0" style={{ pointerEvents: "none", opacity: 0.5 }}>
              {`${packageData?.startDateNextSubscriptionPeriod} - ${packageData?.endDateNextSubscriptionPeriod}`}
            </p>
          </div>
        ) : (
          <div className="flex justify-between mb-4">
            <p className="mb-0">
              Periode Selanjutnya{" "}
              <Tooltip
                trigger={["click"]}
                title="Tagihan anda sudah lunas dan layanan akan diperpanjang otomatis"
                overlayStyle={{
                  width: "auto",
                  whiteSpace: "nowrap",
                  maxWidth: "none",
                }}
                placement="bottom">
                <ICCheckCircleFilled style={{ color: "#0D9F3F", marginLeft: "2px" }} />
              </Tooltip>
            </p>
            <p className="text-secondary mb-0">
              {`${packageData?.startDateNextSubscriptionPeriod} - ${packageData?.endDateNextSubscriptionPeriod}`}
            </p>
          </div>
        )}
        <ProgressPaket
          title="Kuota"
          space={packageData?.maxMailCountAllowed}
          used={packageData?.usageMailCount}
        />
        <ProgressPaket
          title="Domain"
          space={packageData?.maxDomainAllowed}
          used={packageData?.usageDomain}
        />
      </>
    );
  }
};

const Profile = () => {
  useDocumentTitle("Profile");
  useGetUserPackage();
  const userProfile = useSelector(({ userProfile }) => userProfile.value);
  const user = useSelector(({ userData }) => userData.value);
  const userPackage = useSelector(({ userPackage }) => userPackage.value);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [loadingSection, setLoadingSection] = useState(false);
  const dispatch = useDispatch();

  const hasOverQuota = user.overQuota !== false ? true : false;

  const getOthers = useCallback(
    async (userInfo, forSectionCard) => {
      setLoading(true);
      setLoadingSection(forSectionCard);
      const { data: companyInfo } = await getCompany();
      const { data: domainInfo } = await getDomain();
      const { data: contactInfo } = await getContact();
      const { data: accountSMTPInfo } = await getAccountSMTP();
      const { data: infoProfile } = await getInfoProfile();
      const { data: dailyStatic } = await getConfigNotification({ type: "daily-static" });
      const { data: emailFailed } = await getConfigNotification({ type: "email-failed" });
      const { data: emailNotification } = await getConfigNotification({
        type: "email-notification",
      });
      dispatch(
        setUserProfile({
          ...userProfile,
          userInfo: userInfo || userProfile.userInfo,
          domainInfo,
          companyInfo,
          contactInfo,
          accountSMTPInfo,
          infoProfile,
          dailyStatic,
          emailFailed,
          emailNotification,
        })
      );
      setLoading(false);
      setLoadingSection(false);
    },
    [dispatch, userProfile]
  );

  const getProfile = async () => {
    setLoading("cardProfile");
    const { data: infoProfile } = await getInfoProfile();
    const { data: contactInfo } = await getContact();
    dispatch(setUserProfile({ ...userProfile, infoProfile, contactInfo }));
    setLoading(false);
  };

  const getData = async () => {
    const { data: contactInfo } = await getContact();
    const { data: infoProfile } = await getInfoProfile();
    dispatch(setUserProfile({ ...userProfile, contactInfo, infoProfile }));
  };

  useEffect(() => {
    if (user && !userProfile) getOthers(user);
  }, [user, userProfile, getOthers]);

  const {
    companyInfo: companyData,
    domainInfo: domainData,
    contactInfo: contactData,
    accountSMTPInfo: accountSMTPinfoData,
    infoProfile: profileInfoUser,
    dailyStatic: getDailyStatic,
    emailFailed: getEmailFailed,
    emailNotification: getEmailNotification,
  } = userProfile;

  const handleCheckout = () => {
    navigate("/checkout");
  };
  const BtnPacket = () => {
    return (
      <Button
        type="primary"
        icon={<ICSelectOutlined />}
        disabled={user.role === "technical"}
        onClick={handleCheckout}>
        {userPackage === "package" ? "Pilih Paket" : "Ubah Paket"}
      </Button>
    );
  };

  const changeImage = async (option) => {
    const { file } = option;
    const { status } = await changePhotoProfileCompany({ photo: file });
    if (status === "success") getProfile();
  };

  return (
    <div className="profile-user">
      {loading && !userProfile ? (
        <Spinner loading={loading} full />
      ) : (
        <div className="flex flex-col gap-y-6 px-6 py-4">
          <HasAlert user={user} />
          <div className="flex profile-user__wrapper gap-x-6">
            <div className="flex flex-col profile-user__left">
              <div className="profile-picture mb-6">
                {profileInfoUser && (
                  <CardProfileNew
                    changeImage={changeImage}
                    loading={loading}
                    infoProfile={profileInfoUser}
                    action="user"
                    getProfile={getProfile}
                    photo={profileInfoUser.companyPhoto}
                    name={profileInfoUser.contactName}
                    account={profileInfoUser.contactEmail}
                    createdAt={profileInfoUser.contactCreatedAt}
                  />
                )}
              </div>
              <NotificationSettings
                data={[getDailyStatic, getEmailFailed, getEmailNotification]}
                role={user.role}
              />
            </div>
            <div className="mb-6 flex flex-col profile-user__right gap-y-6">
              {hasOverQuota && <OverQouta />}
              <div className="flex gap-2 profile-user__account">
                <div className="right-res-profile">
                  <AccountInformation
                    user={user}
                    getOthers={getOthers}
                    data={accountSMTPinfoData}
                    domainUuid={domainData}
                  />
                </div>
                <div className="right-res-profile">
                  <Card title="Detail Paket" extra={<BtnPacket />}>
                    <HasPackage packageData={userPackage} />
                  </Card>
                </div>
              </div>
              {companyData && (
                <CardCompanyInformation
                  isAdmin="user"
                  data={companyData}
                  getOthers={getOthers}
                  loading={loadingSection}
                />
              )}
              <div className="flex gap-2 profile-user__company">
                <div className="right-res-profile">
                  <CardContactInformation contact={contactData} sectionLoad={getData} />
                </div>
                <div className="right-res-profile">
                  <CardRegisteredDomain
                    page="profile"
                    loading={loadingSection}
                    getOthers={getOthers}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
