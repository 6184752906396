import { Button, Card, Form, Input } from "antd";
import { checkExistedDomain, setUpNewDomain, updateConfig } from "api";
import laptop from "assets/images/laptop.svg";
import magnifier from "assets/images/magnifier.svg";
import { TitleAndParagraph } from "components/Components";
import { useState } from "react";
import { regexDomain } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import Support from "./Support";

const domainInvalid = "Harap masukkan domain yang benar";
const domainRegistered = "Nama domain sudah terdaftar pada sistem";

const VSSetupDomain = ({ next, setFillDomain }) => {
  useDocumentTitle("Setup Domain");
  const [form] = Form.useForm();
  const [setupNow, setSetupNow] = useState(false);
  const [setupSoon, setSetupSoon] = useState(false);
  const [registered, setRegistered] = useState({ disabled: true });
  const [loading, setLoading] = useState(false);

  const registDomain = async ({ domain }) => {
    if (domain) {
      setLoading(true);
      const { code, msg, status } = await setUpNewDomain({ namaDomain: domain });
      if (status === "success") {
        setLoading(false);
        setFillDomain("success");
        next();
      } else if (status === "failed") {
        setFillDomain("failed");
        const { code } = await updateConfig();
        if (code === 200) {
          setTimeout(() => {
            setLoading(false);
            next();
          }, 1000);
        }
      } else if (code === 400 && msg.includes("registered")) {
        setRegistered({ ...registered, disabled: true });
        setLoading(false);
      } else if (code === 401 && status === "failed") {
        setRegistered({ ...registered, disabled: true });
        setLoading(false);
      }
    }
  };

  const handleNext = async () => {
    setLoading(true);
    const { code } = await updateConfig();
    if (code === 200) {
      setTimeout(() => {
        setLoading(false);
        next();
      }, 1000);
    }
  };

  const handleSetupNow = () => {
    setSetupNow(true);
    setSetupSoon(false);
  };

  const handleSetupSoon = () => {
    setSetupNow(false);
    setSetupSoon(true);
    setFillDomain("soon");
  };

  return (
    <div className="flex flex-col justify-center setup-form mx-auto mt-10 pb-20">
      <TitleAndParagraph
        level={2}
        classNameT="header-2"
        classNameP="mb-6"
        childrenP={`Setup domain anda untuk melakukan aktivasi trial layanan selama 7 hari. Akun ${process.env.REACT_APP_NAME} anda akan aktif setelah anda melengkapi informasi domain yang digunakan.`}
        childrenT="Aktivasi Trial Layanan"
      />
      <div className={`flex justify-center ${setupSoon ? "mb-7" : "mb-4"} gap-x-15`}>
        <div className="setup-now">
          <Card
            hoverable
            className={`card-setup ${setupNow ? "card-setup-active" : ""}`}
            onClick={handleSetupNow}>
            <TitleAndParagraph
              level={5}
              classNameT="header-5"
              classNameP="mb-6"
              childrenP="Setup domain anda sekarang dan gunakan trial layanan selama 7 hari"
              childrenT="Aktivasi Trial Sekarang"
            />
            <div className="img-setup-card mx-auto svg-card">
              <img src={laptop} alt="card-img" />
            </div>
          </Card>
        </div>
        <div className="setup-soon">
          <Card
            hoverable
            className={`card-setup ${setupSoon ? "card-setup-active" : ""}`}
            onClick={handleSetupSoon}>
            <TitleAndParagraph
              level={5}
              classNameT="header-5"
              classNameP="mb-6"
              childrenP="Pelajari terlebih dahulu dashboard dan panduan kami sebelum memulai trial layanan"
              childrenT="Aktivasi Trial Nanti"
            />
            <div className="img-setup-card mx-auto svg-card">
              <img src={magnifier} alt="card-img" />
            </div>
          </Card>
        </div>
      </div>
      {setupNow && (
        <Form
          form={form}
          layout="vertical"
          name="email-setup-now"
          onFinish={registDomain}
          // onValuesChange={(_, allValues) => onValueChange(allValues)}
        >
          <Form.Item
            label="Masukkan Nama Domain Anda"
            className={`email-input-setup ${registered.alert ? "mb-0" : "mb-7"}`}
            hasFeedback
            validateStatus={registered.validate}
            name="domain"
            rules={[
              {
                validator: (rule, value) => {
                  if (!value) {
                    setRegistered({ disabled: true, validate: "error" });
                    return Promise.reject(new Error("Harap diisi terlebih dahulu"));
                  } else {
                    const regexvalid = new RegExp(regexDomain).test(value);
                    if (regexvalid) {
                      setRegistered({ disabled: true, validate: "validating" });
                      return new Promise((resolve, reject) => {
                        checkExistedDomain({ namaDomain: value }).then(({ data }) => {
                          if (data === "existed") {
                            setRegistered({ validate: "error", disabled: true });
                            return reject(new Error(domainRegistered));
                          }
                          setRegistered({ validate: "success", disabled: false });
                          return resolve();
                        });
                      });
                    } else {
                      setRegistered({ validate: "error", disabled: true });
                    }
                    return Promise.reject(new Error(domainInvalid));
                  }
                },
              },
            ]}>
            <Input placeholder="yourdomain.com" />
          </Form.Item>
          <Form.Item className="align-center">
            <Button
              className="btn-setup"
              loading={loading}
              htmlType="submit"
              type="primary"
              disabled={registered.disabled}>
              Selanjutnya
            </Button>
          </Form.Item>
        </Form>
      )}
      {setupSoon && (
        <Button type="primary" className="btn-setup" onClick={handleNext} loading={loading}>
          Selanjutnya
        </Button>
      )}
      <Support />
    </div>
  );
};

export default VSSetupDomain;
