import { Card, Col, Row } from "antd";
import { MostEmailWeekly, MostSendEmail } from "components/chart/ChartDashboard";
import { useSelector } from "react-redux";
import useDocumentTitle from "utils/useDocumentTitle";
import EmailBounceSummary from "./EmailBounceSummary";
import EmailStatusSummary from "./EmailStatusSummary";
import "./maindashboard.less";
import TopSender from "./TopSender";
import { useGetAdminDashboardData } from "./useGetAdminDashboardData";

const MainDashboard = () => {
  useDocumentTitle("Dashboard");
  useGetAdminDashboardData();
  const sentEmail = useSelector(({ emailSummary }) => emailSummary.value);
  return (
    <div className="main-admin">
      <div className="flex flex-col px-6 py-4">
        <div className="mb-6">
          <EmailStatusSummary />
        </div>
        <div className="mb-6">
          <Row gutter={[24, 0]}>
            <Col lg={12} md={12} sm={12} xl={12}>
              <EmailBounceSummary />
            </Col>
            <Col lg={12} md={12} sm={12} xl={12}>
              <TopSender />
            </Col>
          </Row>
        </div>
        <div className="email-send-graph">
          <Card className="w-full" loading={!sentEmail} title="Grafik Pengiriman Email">
            <Row gutter={[32, 0]}>
              <Col lg={12} md={12} sm={24} xl={12}>
                <p className="mb-3 text-base font-medium">Email 1 Tahun Terakhir</p>
                <MostSendEmail data={sentEmail?.graph?.dataYear} />
              </Col>
              <Col lg={12} md={12} sm={24} xl={12}>
                <p className="mb-3 text-base font-medium">Email 1 Minggu Terakhir</p>
                <MostEmailWeekly data={sentEmail?.graph?.dataWeek} />
              </Col>
            </Row>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default MainDashboard;
