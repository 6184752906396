import { getDetailsSubscription } from "api";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDetailsSubscription } from "redux/slices/detailsSubscription";

const useGetDetailsSubscription = () => {
  const detailSubscription = useSelector(({ detailsSubscription }) => detailsSubscription.value);

  const dispatch = useDispatch();
  const action = setDetailsSubscription;

  const doSet = useCallback((data) => dispatch(action(data)), [dispatch, action]);

  useEffect(() => {
    const getDetail = async () => {
      const { data } = await getDetailsSubscription();
      if (data && Object.keys(data).length) doSet(data);
      else doSet("failed");
    };
    if (!detailSubscription) getDetail();
  }, [detailSubscription, doSet]);
};

export default useGetDetailsSubscription;
