import { createSlice } from "@reduxjs/toolkit";

export const currentPayment = createSlice({
  name: "currentPayment",
  initialState: {
    value: false, // default state
  },
  reducers: {
    // reducers action
    setCurrentPayment: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setCurrentPayment } = currentPayment.actions;
export default currentPayment.reducer;
