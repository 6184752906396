import { getAllClients, listContactClients, summaryClients } from "api";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setListAllClients } from "redux/slices/admin/listAllClients";
import { setListAllContact } from "redux/slices/admin/listAllContact";
import { setListAllCustomer } from "redux/slices/admin/listAllCustomer";
import CustomerSummary from "./CustomerSummary";
import CustomerTable from "./CustomerTable";
import "./pelanggan.less";

const DaftarPelanggan = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    page: 1,
    limit: "10",
    company_name: "",
    package_name: [],
    status_relay: [],
    status_subcribtion: [],
  });
  const [filterContact, setFilterContact] = useState({
    page: 1,
    limit: "10",
    company_name: "",
  });
  const [loading, setLoading] = useState(false);
  const listAllClients = useSelector(({ listAllClients }) => listAllClients.value);

  const getDataSummary = useCallback(async () => {
    setLoading(true);
    const { data: clientSummary } = await summaryClients();
    dispatch(setListAllClients(clientSummary));
    setLoading(false);
  }, [dispatch]);

  const getDataTable = useCallback(async () => {
    setLoading(true);
    const { data } = await getAllClients(filter);
    dispatch(setListAllCustomer(data));
    setLoading(false);
  }, [dispatch, filter]);

  const getDataContactClients = useCallback(async () => {
    setLoading(true);
    const { data: infoContactClients } = await listContactClients(filterContact);
    dispatch(setListAllContact(infoContactClients));
    setLoading(false);
  }, [dispatch, filterContact]);

  useEffect(() => {
    if (filter) getDataTable();
  }, [filter, getDataTable]);

  useEffect(() => {
    if (!listAllClients) getDataSummary();
  }, [listAllClients, getDataSummary]);

  useEffect(() => {
    if (filterContact) getDataContactClients();
  }, [filterContact, getDataContactClients]);

  return (
    <div className="pelanggan-admin">
      <DashboardHeader title="Daftar Pelanggan" />
      <div className="flex flex-col gap-y-6 px-6 py-4">
        <div className="customer-summary-section">
          <CustomerSummary loading={loading} />
        </div>
        <div className="table-customer">
          <CustomerTable
            getDataContact={getDataContactClients}
            getDataCustomer={getDataTable}
            loading={loading}
            setLoading={setLoading}
            filter={filter}
            filterContact={filterContact}
            setFilterContact={setFilterContact}
            setFilter={setFilter}
          />
        </div>
      </div>
    </div>
  );
};

export default DaftarPelanggan;
