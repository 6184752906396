import { createSlice } from "@reduxjs/toolkit";

export const logActivityAdmin = createSlice({
  name: "logActivityAdmin",
  initialState: {
    value: false,
  },
  reducers: {
    setLogActivityAdmin: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setLogActivityAdmin } = logActivityAdmin.actions;
export default logActivityAdmin.reducer;
