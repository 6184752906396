import { createSlice } from "@reduxjs/toolkit";

export const userData = createSlice({
  name: "userData",
  initialState: {
    value: false, // default state
  },
  reducers: {
    // reducers action
    setUserData: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setUserData } = userData.actions;
export default userData.reducer;
