import { Button, Modal } from "antd";
import { Link } from "react-router-dom";
import {
  ICQuestionCircleOutlined,
  ICBookOutlined,
  ICPlayCircleFilled,
} from "components/list/ListIcon";
import { useState } from "react";
import Thumbnail from "assets/images/regist_guide_medium.png";

const DocumentationCTA = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const youtubeVideoLink = "https://www.youtube.com/embed/aFv-PZududU";

  const videoModal = (
    <Modal
      title="Video Panduan Setup Trial"
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      width={920}
      destroyOnClose>
      <div style={{ position: "relative", paddingBottom: "56.25%" }}>
        <iframe
          width="100%"
          src={youtubeVideoLink}
          title="YouTube Video"
          allowFullScreen
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}></iframe>
      </div>
    </Modal>
  );

  return (
    <div className="documentation-cta__wrapper">
      <div className="documentation-cta__left">
        <h5 className="font-medium text-base">Panduan dan Support</h5>
        <p className="text-secondary mb-4">
          Kami sudah menyiapkan dokumentasi untuk memudahkan anda dalam melakukan konfigurasi SMTP.
          Apabila anda mengalami kesulitan, bisa klik video disamping atau hubungi kami melalui
          Support Ticket.
        </p>
        <Button type="primary" className="guide" style={{ marginRight: 8 }}>
          <ICQuestionCircleOutlined style={{ marginRight: 8 }} />
          <a href="https://support.aktiva.co.id/" style={{ color: "white" }}>
            Support Ticket
          </a>
        </Button>
        <Button>
          <Link to="/documentation">
            <ICBookOutlined style={{ marginRight: 8 }} />
            Lihat Dokumentasi
          </Link>
        </Button>
      </div>
      <div className="thumbnail-container-cta">
        <img
          src={Thumbnail}
          alt="Video Thumbnail"
          onClick={showModal}
          style={{ cursor: "pointer", width: 224, height: 127 }}
        />
        <div className="silverplay-icon-cta" onClick={showModal}>
          <ICPlayCircleFilled />
        </div>
      </div>
      {videoModal}
    </div>
  );
};

export default DocumentationCTA;
