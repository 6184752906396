import dataMaintenance from "assets/images/data_maintenance.svg";
import AlertCard from "components/box-card/AlertCard";
import React from "react";
import { useNavigate } from "react-router-dom";

const TrialAlert = ({ ...props }) => {
  const { different_day, end_date } = props;
  const diffSecond = new Date(end_date).getTime() - new Date().getTime();
  const endDateSplit = end_date.split(" ");
  const date = endDateSplit.slice(0, 3).join(" ");
  const time = endDateSplit[3];
  const navigate = useNavigate();
  const navCheckout = () => navigate("/checkout");

  const textAlert = {
    week: {
      title: `Trial ${process.env.REACT_APP_NAME} Anda Akan Berakhir ${different_day} Hari Lagi`,
      body: `Waktu trial ${process.env.REACT_APP_NAME} tersisa ${different_day} hari lagi, yakni hingga ${date} pukul ${time}. Agar akun anda tidak ditangguhkan dan dapat terus menggunakan layanan ${process.env.REACT_APP_NAME} tersebut, silakan dapat berlangganan sesuai dengan kebutuhan dari paket yang kami sediakan.`,
    },
    today: {
      title: `Trial ${process.env.REACT_APP_NAME} Anda Akan Berakhir Hari Ini`,
      body: `Trial ${process.env.REACT_APP_NAME} anda akan berakhir hari ini pukul ${time}. Agar akun anda tidak ditangguhkan dan dapat terus menggunakan layanan ${process.env.REACT_APP_NAME} tersebut, silakan dapat berlangganan sesuai dengan kebutuhan dari paket yang kami sediakan.`,
    },
    due: {
      title: `Trial ${process.env.REACT_APP_NAME} Anda Sudah Berakhir`,
      body: `Akun ${process.env.REACT_APP_NAME} ditangguhkan karena sudah menggunakan trial layanan ${process.env.REACT_APP_NAME} selama 7 hari. Untuk dapat menggunakan kembali layanan ${process.env.REACT_APP_NAME}, silakan dapat berlangganan sesuai dengan kebutuhan dari paket yang kami sediakan.`,
    },
  };

  if (different_day > 3) return "";

  return (
    <AlertCard
      titleAlert={
        different_day <= 3 && different_day > 0
          ? textAlert.week.title
          : different_day === 0 && diffSecond > 0
          ? textAlert.today.title
          : textAlert.due.title
      }
      bodyAlert={
        different_day <= 3 && different_day > 0
          ? textAlert.week.body
          : different_day === 0 && diffSecond > 0
          ? textAlert.today.body
          : textAlert.due.body
      }
      imgSrc={dataMaintenance}
      imgAlt={`Locked ${process.env.REACT_APP_NAME}`}
      btnText="Berlangganan Sekarang"
      onClick={navCheckout}
      className="red-bg"
    />
  );
};

export default TrialAlert;
