import { Button, Card, ConfigProvider, Form, Table, Tag, Dropdown, Menu } from "antd";
import { GenerateInvoice, ResendBillings } from "api";
import { EmptyWrapper } from "components/empty/EmptyComponent";
import {
  ICCheckCircleOutlined,
  ICCloseCircleOutlined,
  ICDollarCircleOutlined,
  ICFilePdfOutlined,
  ICMoreOutlined,
} from "components/list/ListIcon";
import TableTitle from "components/tableSendiri/TableTitle";
import TableTitleWithCollapse from "components/tableSendiri/TableTitleWithCollapse";
import { useEffect, useState } from "react";
import { capitalizeFirstLetter, getNumericValue, formatNumberWithDot } from "utils/helpers";
import ModalConfirm from "components/popups/modal/ModalConfirm";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import ModalError from "components/popups/modal/ModalError";
import { ModalManualPayment } from "components/popups/modal/ModalManualPay";
import { FileTextOutlined, MailOutlined } from "@ant-design/icons";

const TableInvoice = ({
  role,
  data,
  filter,
  setFilter,
  title,
  emptyState,
  responsiveWidth,
  breakpoint_third,
  callbackInvoice,
  callbackSummary,
}) => {
  const [showModalManual, setShowModalManual] = useState(false);
  const [dataTable, setDataTable] = useState(false);
  const [billPayment, setBillPayment] = useState(false);
  const [pphPayment, setPphPayment] = useState(false);
  const [billCount, setBillCount] = useState(null);
  const [pphCount, setPphCount] = useState(null);
  const [form] = Form.useForm();

  const handleOpenModalManual = (record) => {
    setShowModalManual(true);
    setDataTable(record);
  };

  const handleCloseModalManual = () => {
    setShowModalManual(false);
    form.resetFields();
    setBillCount(null);
    setPphCount(null);
    setBillPayment(false);
    setPphPayment(false);
  };

  const handleBillPayment = (e) => {
    const isChecked = e.target.checked;
    setBillPayment(isChecked);
    if (isChecked && dataTable.inv_price) {
      const billAmount = parseFloat(
        formatNumberWithDot(getNumericValue(dataTable.inv_price))
          .replace(/\./g, "")
          .replace(",", ".")
      );
      setBillCount(billAmount);
    } else {
      setBillCount(null);
    }
  };

  const handlePphPayment = (e) => {
    const isChecked = e.target.checked;
    setPphPayment(isChecked);
    if (isChecked && dataTable.inv_price) {
      const pphAmount =
        (parseFloat(
          formatNumberWithDot(getNumericValue(dataTable.inv_price))
            .replace(/\./g, "")
            .replace(",", ".")
        ) /
          1.11) *
        0.02;
      setPphCount(pphAmount.toFixed(0));
      const billAmount =
        parseFloat(
          formatNumberWithDot(getNumericValue(dataTable.inv_price))
            .replace(/\./g, "")
            .replace(",", ".")
        ) - pphAmount;
      setBillCount(billAmount);
    } else {
      setBillCount(null);
      setPphCount(null);
    }
  };

  const validateNominalPajak = (_, value) => {
    return new Promise((resolve, reject) => {
      const totalAmount = (parseFloat(billCount) || 0) + (parseFloat(pphCount) || 0);
      const expectedAmount = parseFloat(
        formatNumberWithDot(getNumericValue(dataTable.inv_price))
          .replace(/\./g, "")
          .replace(",", ".")
      );

      if (totalAmount !== expectedAmount) {
        reject("Nominal pajak tidak sesuai dengan total tagihan, harap cek kembali");
      } else {
        resolve();
      }
    });
  };

  const changePage = (pagesa) => {
    setFilter({ ...filter, page: pagesa });
  };

  const ActionTable = (record) => {
    const isResendEnabled = record.inv_status === "unpaid" || record.inv_status === "overdue";
    const isInvGenerated = record.inv_generated === true;

    const handleResendEmailBilling = async () => {
      ModalConfirm({
        title: "Resend Email Billing?",
        customMessage: (
          <div>
            <p className="mb-6">
              Apakah anda yakin ingin mengirim ulang email billing untuk invoice{" "}
              <strong>{record.inv_number}</strong> milik <strong>{record.company_name}</strong>?{" "}
              <br /> <br /> Customer akan menerima email notifikasi billing jika anda kirimkan
              ulang.
            </p>
          </div>
        ),
        cancelText: "Batalkan",
        okText: "Ya, Resend email",
        onOk: async () => {
          const response = await ResendBillings({
            invUuid: record.inv_uuid,
          });
          if (response.code === 500) {
            ModalError({
              title: "Terdapat Kendala Saat Mengirimkan",
              customMessage: (
                <p className="mb-0">
                  Terdapat kendala pada sistem saat mengirimkan email. Silakan hubungi Tim Product
                  Development
                </p>
              ),
            });
          } else {
            ModalSuccess({
              title: "Email Billing Berhasil Dikirimkan Ulang",
              customMessage: (
                <p className="mb-0">
                  Email billing untuk invoice <strong>{record.inv_number}</strong> milik{" "}
                  <strong>{record.company_name}</strong> sudah berhasil dikirimkan ulang ke
                  customer.
                </p>
              ),
              okText: "Oke",
            });
          }
        },
      });
    };

    const handleConvertInvoice = () => {
      ModalConfirm({
        title: "Convert estimate to invoice?",
        customMessage: (
          <>
            Apakah anda yakin ingin mengconvert tagihan tersebut dari estimate menjadi invoice untuk{" "}
            <span className="font-medium">{record.inv_number}</span> milik{" "}
            <span className="font-medium">{record.company_name}</span>?<br />
            <br /> Invoice akan dibuat pada Zoho tanpa melakukan record payment.
          </>
        ),
        okText: "Ya, Convert ke Invoice",
        cancelText: "Batalkan",
        onOk: async () => {
          const { code } = await GenerateInvoice({ uuid: record.inv_uuid });
          if (code === 200) {
            ModalSuccess({
              title: "Berhasil melakukan convert invoice",
              customMessage: (
                <>
                  Estimate <span className="font-medium">{record.inv_number}</span> milik{" "}
                  {record.company_name} telah berhasil diconvert menjadi invoice di Zoho.
                </>
              ),
              type: "primary",
              onOk: () => {
                callbackInvoice();
              },
            });
          } else {
            ModalError({
              title: "Terdapat kesalahan pada sistem",
              customMessage:
                "Terdapat kendala pada sistem saat melakukan convert invoice. Silakan hubungi Tim Product Development",
              type: "primary",
            });
          }
        },
      });
    };

    return (
      <div className="flex justify-center items-center">
        <Dropdown
          trigger={["click"]}
          overlay={
            <Menu>
              <Menu.Item
                key="record"
                onClick={() => handleOpenModalManual(record)}
                disabled={!isResendEnabled}
                icon={<ICDollarCircleOutlined />}>
                Record Pembayaran
              </Menu.Item>
              <Menu.Item
                key="resend"
                onClick={handleResendEmailBilling}
                disabled={!isResendEnabled}
                icon={<MailOutlined />}>
                Resend Email Billing
              </Menu.Item>
              <Menu.Item
                key="convert"
                disabled={isInvGenerated}
                icon={<FileTextOutlined />}
                onClick={handleConvertInvoice}>
                Convert to Invoice
              </Menu.Item>
            </Menu>
          }>
          <ICMoreOutlined />
        </Dropdown>
      </div>
    );
  };

  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1200;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  // user
  const columnInvoiceUser = [
    {
      title: "Invoice Date",
      dataIndex: "invoice_date",
      key: "invoice_date",
      width: width > breakpoint ? 120 : 120,
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_number",
      key: "invoice_number",
      className: "blue-6",
      width: 160,
    },
    {
      title: "Due Date",
      dataIndex: "invoice_due_date",
      key: "invoice_due_date",
      width: 120,
    },
    {
      title: "Jumlah Tagihan",
      dataIndex: "price",
      key: "price",
      align: "right",
      width: 120,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item) => {
        const listStatus = [
          { name: "Paid", color: "blue", className: "paid-tag" },
          { name: "Unpaid", color: "gold", className: "unpaid-tag" },
          { name: "Cancelled", color: "red", className: "cancelled-tag" },
          { name: "Overdue", color: "magenta", className: "overdue-tag" },
        ];
        const renderList = listStatus.find((list) => list.name === item);
        return (
          <Tag color={renderList.color} className={renderList.className}>
            {renderList.name}
          </Tag>
        );
      },
      width: 92,
    },
    {
      dataIndex: "link_pdf_invoice",
      key: "link_pdf_invoice",
      render: (values) => (
        <Button
          href={values}
          target="_blank"
          icon={<ICFilePdfOutlined />}
          type="link"
          className="blue-6"
        />
      ),
      width: 60,
    },
  ];
  // admin
  const columnInvoiceAdmin = [
    {
      title: "Invoice Number",
      dataIndex: "inv_number",
      key: "inv_number",
      render: (_, record) => {
        const navInvoice = () => window.open(record.inv_url, "_blank");
        const invoice = [
          { status: "paid", color: "text-info" },
          { status: "cancelled", color: "text-danger" },
          { status: "unpaid", color: "text-secondary" },
          { status: "overdue", color: "text-secondary" },
          { status: "paid", color: "text-secondary" },
        ];
        const renderInvoice = invoice.find((inv) => inv.status === record.inv_status);
        return (
          <p className={`mb-0 pointer ${renderInvoice.color}`} onClick={navInvoice}>
            {record.inv_number}
          </p>
        );
      },
    },
    {
      title: "Nama Perusahaan",
      dataIndex: "company_name",
      key: "company_name",
      width: width > breakpoint ? 420 : 240,
      render: (value) => {
        if (value.includes("rm::"))
          return (
            <div className="flex items-center gap-2">
              <p className="text-secondary mb-0">{value.slice(4)}</p>
              <Tag color="default" className="text-secondary mr-0">
                Deleted
              </Tag>
            </div>
          );
        return <p className="mb-0">{value}</p>;
      },
    },
    {
      title: "FP",
      dataIndex: "inv_tax",
      key: "inv_tax",
      width: 76,
      align: "center",
      render: (item) =>
        item ? (
          <ICCheckCircleOutlined className="text-success text-lg" />
        ) : (
          <ICCloseCircleOutlined className="text-secondary text-lg" />
        ),
    },
    {
      title: "Status",
      dataIndex: "inv_status",
      key: "inv_status",
      render: (item) => (
        <Tag
          color={item === "paid" ? "green" : item === "unpaid" ? "warning" : "red"}
          className={`invoice-table-tag${item === "paid" ? " paid-tag" : ""}`}>
          {capitalizeFirstLetter(item)}
        </Tag>
      ),
    },
    {
      title: "Invoice Date",
      dataIndex: "inv_date",
      key: "inv_date",
    },
    {
      title: "Due Date",
      dataIndex: "inv_due_date",
      key: "inv_due_date",
    },
    {
      title: "Jumlah Tagihan",
      dataIndex: "inv_price",
      key: "inv_price",
      align: "right",
    },
    {
      key: "action",
      render: (_, record) => ActionTable(record),
    },
  ];

  const columnInvoiceAccounting = [
    {
      title: "Invoice Number",
      dataIndex: "inv_number",
      key: "inv_number",
      render: (_, record) => {
        const navInvoice = () => window.open(record.inv_url, "_blank");
        return (
          <p className="mb-0 text-info pointer" onClick={navInvoice}>
            {record.inv_number}
          </p>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "inv_status",
      key: "inv_status",
      width: 120,
      render: (values) =>
        values === "paid" ? (
          <Tag color="green">{values}</Tag>
        ) : values === "unpaid" ? (
          <Tag color="warning">{values}</Tag>
        ) : (
          <Tag color="red">{values}</Tag>
        ),
    },
    {
      title: "FP",
      dataIndex: "inv_tax",
      key: "inv_tax",
      width: 76,
      align: "center",
      render: (values) =>
        values ? (
          <ICCheckCircleOutlined className="text-success text-lg" />
        ) : (
          <ICCloseCircleOutlined className="text-secondary text-lg" />
        ),
    },
    {
      title: "Invoice Date",
      dataIndex: "inv_date",
      key: "inv_date",
      width: 160,
    },
    {
      title: "Due Date",
      dataIndex: "inv_due_date",
      key: "inv_due_date",
    },

    {
      title: "Jumlah Tagihan",
      dataIndex: "inv_price",
      key: "inv_price",
      width: 148,
      align: "right",
    },
  ];

  const renderColumn =
    role === "user"
      ? columnInvoiceUser
      : role === "admin"
      ? columnInvoiceAdmin
      : columnInvoiceAccounting;

  if (!data.table) return <Card bordered={false} loading={data.loading} className="p-0 m-0"></Card>;
  return (
    <ConfigProvider renderEmpty={() => <EmptyWrapper {...emptyState} />}>
      <Table
        dataSource={data.table.rows}
        columns={renderColumn}
        {...(width < breakpoint ? { scroll: { x: 1024 } } : {})}
        loading={data.loading}
        pagination={{
          pageSize: filter.limit, //jumlah data
          current: filter.page, //halaman
          hideOnSinglePage: true,
          onChange: (params) => {
            changePage(params);
          },
          size: "small",
          total: data.table.count,
          showSizeChanger: false,
        }}
        title={() =>
          role === "admin" ? (
            <TableTitleWithCollapse
              {...title}
              breakpoint_third={breakpoint_third}
              responsiveWidth={responsiveWidth}
            />
          ) : (
            <TableTitle {...title} />
          )
        }
        rowKey={(record) => (role === "user" ? record.invoice_uuid : record.inv_uuid)}
      />
      <ModalManualPayment
        open={showModalManual}
        close={handleCloseModalManual}
        dataTable={dataTable}
        handleBillPayment={handleBillPayment}
        billPayment={billPayment}
        form={form}
        handlePphPayment={handlePphPayment}
        pphPayment={pphPayment}
        billCount={billCount}
        pphCount={pphCount}
        validateNominalPajak={validateNominalPajak}
        setBillCount={setBillCount}
        setPphCount={setPphCount}
        callbackInvoice={callbackInvoice}
        callbackSummary={callbackSummary}
      />
    </ConfigProvider>
  );
};

export default TableInvoice;
