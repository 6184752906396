import { createSlice } from "@reduxjs/toolkit";

export const InfoOverQuota = createSlice({
  name: "getInfoOverQuota",
  initialState: {
    value: false, // default state
    code: false,
  },
  reducers: {
    // reducers action
    setInfoOverQuota: (state, { payload, code }) => {
      state.value = payload;
      state.code = code;
    },
  },
});

export const { setInfoOverQuota } = InfoOverQuota.actions;
export default InfoOverQuota.reducer;
