import {
  Button,
  ConfigProvider,
  Table,
  Input,
  Select,
  Badge,
  Space,
  Tooltip,
  Skeleton,
} from "antd";
import {
  ICDeleteOutlined,
  ICPlusOutlined,
  ICUserOutlined,
  ICFlagOutlined,
} from "components/list/ListIcon";
import { useEffect, useState } from "react";
import ModalAddSuppressAdmin from "components/popups/modal/ModalAddSuppressAdmin";
import { EmptySuppress, SearchNotFound, LoadFailed } from "components/empty/EmptyComponent";
import ModalConfirmDeleteSuppress from "components/popups/modal/ModalConfirmDelete";
import ModalDelete from "components/popups/modal/ModalGenerate";
import "./suppress.less";
import DetailDrawerAdmin from "components/popups/drawer/DetaiDrawerAdmin";
import { splitDate } from "utils/helpers";
import { TitleTableSuppressAdmin } from "components/tableSendiri/TitleTable";
import { DeleteSuppressAdmin } from "api";
import ModalError from "components/popups/modal/ModalError";
import { CollapseFilter } from "components/tableSendiri/CollapseFilter";
import { endOfDay, format } from "date-fns";

const { Option } = Select;

const TableSupressAdmin = ({
  dataSuppressAdmin,
  filterSuppressAdmin,
  ListCompany,
  setfilterSuppressAdmin,
  setShowSkeletonSuppress,
  showSkeletonSuppressAdmin,
  getDataSuppress,
  getDataHistory,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [click, setClick] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(filterSuppressAdmin.page);
  const [currentLimit, setCurrentLimit] = useState("10");
  const [company, setCompany] = useState("AllCompany");
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredCompanies, setFilteredCompanies] = useState(ListCompany);

  const breakpoint = 1200;
  const breakpoint_third = 768;

  const isDataAvailable = dataSuppressAdmin.data?.count > 0;
  const isDataNotFound =
    (company !== "" ||
      searchQuery !== "" ||
      selectedCategory !== "All" ||
      (startDate && endDate !== false)) &&
    !isDataAvailable;
  const isDataLoadFailed = dataSuppressAdmin.code === 500;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const BtnAdd = ({ setOpen }) => {
    return {
      ...(width < breakpoint ? (
        <Tooltip title="Tambah" placement="top">
          <Button
            onClick={() => setOpen(true)}
            type="secondary"
            className="btn-add"
            {...(isDataLoadFailed ? { disabled: isDataLoadFailed } : { disabled: false })}>
            <ICPlusOutlined />
          </Button>
        </Tooltip>
      ) : (
        <Button
          icon={<ICPlusOutlined />}
          onClick={() => setOpen(true)}
          type="secondary"
          className="btn-add"
          {...(isDataLoadFailed ? { disabled: isDataLoadFailed } : { disabled: false })}>
          <span className="btn-text">Tambah</span>
        </Button>
      )),
    };
  };

  const BtnDelete = ({ rowData }) => {
    const handleDeleteSuppress = () => {
      console.log(rowData);
      ModalDelete({
        title: "Hapus Email dari Suppression List?",
        customMessage: (
          <div>
            <p className="mb-6">
              Apakah anda yakin ingin menghapus email <strong>{rowData.email}</strong> dari
              suppression list?
              <br /> <br /> Setelah dihapus, anda dapat mengirimkan email ke alamat email tersebut.
              Namun dapat berpotensi kembali masuk kedalam suppression list, apabila email tujuan
              tidak dapat dijangkau oleh sistem.
            </p>
          </div>
        ),
        iconType: "remove",
        okText: "Remove dari Suppression",
        cancelText: "Batalkan",
        onOk: async () => {
          const response = await DeleteSuppressAdmin({
            email: rowData.email,
            companyUuid: rowData.company_uuid,
            global: rowData.global,
          });
          console.log(response);
          if (response.code === 500 || response.code !== 200) {
            ModalError({
              title: "Peringatan!",
              message: response.msg,
              onOk: () => {
                setShowSkeletonSuppress(true);
                setTimeout(() => {
                  setShowSkeletonSuppress(false);
                }, 500);
              },
            });
          } else {
            ModalConfirmDeleteSuppress({
              title: "Suppression Berhasil Dihapus",
              customMessage: (
                <div>
                  <p>
                    Email <strong>{rowData.email}</strong> sudah berhasil dihapus dari suppression
                    list
                  </p>
                </div>
              ),
              onOk: () => {
                setShowSkeletonSuppress(true);
                setTimeout(async () => {
                  await getDataSuppress();
                  await getDataHistory();
                  setShowSkeletonSuppress(false);
                }, 500);
              },
            });
          }
        },
      });
    };
    return (
      <Tooltip title="Delete" placement="top">
        <Button
          danger
          icon={<ICDeleteOutlined />}
          className="w-12"
          onClick={handleDeleteSuppress}
        />
      </Tooltip>
    );
  };

  const columnSuppress = [
    {
      title: "Date",
      dataIndex: "timestamp",
      key: "Date",
      width: 120,
      render: (data) => {
        const { date, time } = splitDate(data);
        return (
          <div className="flex flex-col">
            <p className="mb-0">{date}</p>
            <p className="mb-0 text-secondary text-xs" type="secondary">
              {time}
            </p>
          </div>
        );
      },
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "Email Address",
      ellipsis: {
        showTitle: true,
        minWidth: 240,
      },
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "Category",
      width: 150,
      className: "category-column",
      render: (category) => (
        <Badge
          color={
            category === "hard"
              ? "red"
              : category === "manual"
              ? "#FADB14"
              : category === "whitelist"
              ? "#0D9F3F"
              : "orange"
          }
          text={
            category === "hard"
              ? "Hard Bounce"
              : category === "manual"
              ? "Manual Added"
              : category === "whitelist"
              ? "Whitelist"
              : "Soft bounce"
          }
        />
      ),
      style: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
    {
      title: "Owner",
      dataIndex: "company",
      key: "Owner",
      ellipsis: {
        showTitle: true,
        minWidth: 220,
      },
      render: (owner, rowData) =>
        owner !== "Global Suppression" ? (
          <span>
            <ICUserOutlined style={{ marginRight: 4 }} />
            {owner}
          </span>
        ) : (
          <span>
            <ICFlagOutlined style={{ marginRight: 4 }} />
            {owner}
          </span>
        ),
    },
    {
      title: "Error Message",
      dataIndex: "message",
      key: "Error Message",
      ellipsis: true,
    },
    {
      dataIndex: "actions",
      key: "actions",
      width: 160,
      fixed: "right",
      render: (_, rowData) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Space size="small">
            <Button
              type="link"
              onClick={() => {
                setSelectedRowData(rowData);
                setShowDrawer(true);
              }}>
              Detail
            </Button>
            <BtnDelete rowData={rowData} />
          </Space>
        </div>
      ),
    },
  ];

  const handleSearch = (value) => {
    setSearchValue(value);
    const filteredList = ListCompany.filter((company) =>
      company.company_name.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredCompanies(filteredList);
  };

  const disabledDate = (current) => {
    return current && current > endOfDay(new Date());
  };

  const changePage = (page) => {
    setCurrentPage(page);
    const updatedFilter = setfilterSuppressAdmin({
      ...filterSuppressAdmin,
      page,
    });
    currentPage(updatedFilter);
  };

  const changePageLimit = (value) => {
    setCurrentLimit(value);
    setCurrentPage(1);
    const updatedFilter = setfilterSuppressAdmin({
      ...filterSuppressAdmin,
      page: 1,
      limit: value,
    });
    currentLimit(updatedFilter);
  };

  const changeCategory = (values) => {
    setSelectedCategory(values);
  };

  const changeSearchQuery = (query) => {
    setSearchQuery(query);
    setCurrentPage(1);
    const updatedFilter = setfilterSuppressAdmin({
      ...filterSuppressAdmin,
      page: 1,
      email: query,
    });
    searchQuery(updatedFilter);
  };

  const changeCompany = (Company) => {
    setCompany(Company);
  };

  const onChangeDatetime = (values) => {
    if (values && values.length > 0) {
      const formattedStartDate = values[0]
        ? format(new Date(values[0]), "yyyy-MM-dd HH:mm:ss")
        : false;
      const formattedEndDate = values[1]
        ? format(new Date(values[1]), "yyyy-MM-dd HH:mm:ss")
        : false;
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
    } else {
      setStartDate(false);
      setEndDate(false);
    }
  };

  const handleAdvanceFilter = async (values) => {
    const { select_company, select_category, datetime } = values;
    let filtered = { ...filterSuppressAdmin, page: 1 };

    if (
      (!select_company && !select_category && datetime) ||
      (select_company && !select_category && !datetime) ||
      (!select_company && select_category && !datetime) ||
      (select_company && select_category && !datetime) ||
      (!select_company && select_category && datetime) ||
      (select_company && !select_category && datetime) ||
      (select_company && select_category && datetime)
    ) {
      let formattedStartDate, formattedEndDate;
      if (datetime) {
        formattedStartDate = datetime[0]
          ? format(new Date(datetime[0]), "yyyy-MM-dd HH:mm:ss")
          : false;
        formattedEndDate = datetime[1]
          ? format(new Date(datetime[1]), "yyyy-MM-dd HH:mm:ss")
          : false;
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
      } else {
        formattedStartDate = [];
        formattedEndDate = [];
        setStartDate(false);
        setEndDate(false);
      }
      filtered = {
        ...filterSuppressAdmin,
        page: 1,
        companyUuid:
          select_company === undefined || select_company === "AllCompany" ? [] : select_company,
        type: select_category === undefined || select_category === "All" ? [] : select_category,
        dateStart: formattedStartDate,
        dateEnd: formattedEndDate,
      };
    }
    setfilterSuppressAdmin(filtered);
  };

  const SearchReciever = ({ onSearch, initialValue = "" }) => {
    const [query, setQuery] = useState(initialValue);

    const handleSearch = () => {
      onSearch(query);
    };

    return (
      <Input.Search
        placeholder="Ketik penerima"
        enterButton
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onSearch={handleSearch}
        className="search-reciever"
        {...(isDataLoadFailed
          ? { disabled: isDataLoadFailed }
          : isDataNotFound
          ? { disabled: false }
          : isDataAvailable
          ? { disabled: false }
          : { disabled: true })}
      />
    );
  };

  const PageLimit = ({ isDataNotFound }) => {
    return (
      <Select
        className="pageLimit__selector"
        onChange={changePageLimit}
        value={currentLimit}
        {...(isDataLoadFailed
          ? { disabled: isDataLoadFailed }
          : isDataNotFound
          ? { disabled: true }
          : isDataAvailable
          ? { disabled: false }
          : { disabled: true })}>
        <Option value="10">10 per page</Option>
        <Option value="25">25 per page</Option>
        <Option value="50">50 per page</Option>
        <Option value="100">100 per page</Option>
      </Select>
    );
  };

  return (
    <ConfigProvider
      renderEmpty={() => {
        if (isDataLoadFailed) {
          return <LoadFailed />;
        } else if (isDataNotFound) {
          return <SearchNotFound />;
        } else {
          return <EmptySuppress />;
        }
      }}>
      <div className="suppress-admin">
        <div className="flex flex-col">
          <TitleTableSuppressAdmin
            breakpoint={breakpoint}
            width={width}
            setClick={setClick}
            click={click}
            BtnAdd={BtnAdd}
            selectedCategory={selectedCategory}
            handleAdvanceFilter={handleAdvanceFilter}
            changeCategory={changeCategory}
            changeCompany={changeCompany}
            company={company}
            SearchReciever={SearchReciever}
            changeSearchQuery={changeSearchQuery}
            searchQuery={searchQuery}
            PageLimit={PageLimit}
            CollapseFilter={CollapseFilter}
            setShowModal={setShowModal}
            showModal={showModal}
            ModalAddSuppressAdmin={ModalAddSuppressAdmin}
            breakpoint_third={breakpoint_third}
            isDataLoadFailed={isDataLoadFailed}
            isDataNotFound={isDataNotFound}
            isDataAvailable={isDataAvailable}
            dataSuppressAdmin={dataSuppressAdmin}
            ListCompany={ListCompany}
            setShowSkeletonSuppress={setShowSkeletonSuppress}
            getDataSuppress={getDataSuppress}
            getDataHistory={getDataHistory}
            handleSearch={handleSearch}
            disabledDate={disabledDate}
            searchValue={searchValue}
            startDate={startDate}
            endDate={endDate}
            onChangeDatetime={onChangeDatetime}
            filteredCompanies={filteredCompanies}
          />
          {showSkeletonSuppressAdmin ? (
            <Skeleton active />
          ) : (
            <Table
              className="table_suppress_admin"
              {...(width < breakpoint ? { scroll: { x: 1024 } } : undefined)}
              columns={columnSuppress}
              dataSource={dataSuppressAdmin.data?.rows}
              style={{
                overflowX: "auto",
                maxWidth: "100%",
              }}
              pageLimit={{
                currentLimit: setfilterSuppressAdmin.limit,
                onSelectLimit: (limit) => {
                  setfilterSuppressAdmin({ ...filterSuppressAdmin, page: 1, limit });
                },
                disable: dataSuppressAdmin.data?.count < 10,
              }}
              pagination={{
                pageSize: currentLimit,
                current: currentPage,
                total: dataSuppressAdmin.data?.count,
                showSizeChanger: false,
                size: "small",
                onChange: changePage,
              }}
            />
          )}
          <DetailDrawerAdmin
            visible={showDrawer}
            onClose={() => setShowDrawer(false)}
            rowData={selectedRowData || {}}
          />
        </div>
      </div>
    </ConfigProvider>
  );
};

export default TableSupressAdmin;
