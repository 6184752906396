import { Button, Card, Col, Divider, Form, Input, Row, Select } from "antd";
import { addNewClient } from "api";
import DashboardPageHeader from "components/dashboard-header/DashboardPageHeader";
import { ICCloseOutlined, ICSaveOutlined } from "components/list/ListIcon";
import ModalError from "components/popups/modal/ModalError";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { numberRules, requiredRules } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

const PelangganCreate = () => {
  useDocumentTitle("Tambah Pelanggan");
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    setLoading(true);
    const { code, msg } = await addNewClient(values);
    setLoading(false);
    if (msg.includes("Email already registered!") && code === 400) {
      ModalError({
        title: "Gagal menambahkan client",
        message: "Email sudah digunakan, silahkan gunakan email lainnya",
        onOk: () => {},
      });
    } else {
      ModalSuccess({
        title: "Berhasil menambahkan client",
        onOk: () => {
          form.resetFields();
        },
      });
    }
  };

  return (
    <>
      <DashboardPageHeader title="Tambah Client Baru" />
      <div className="px-6 py-4">
        <Card title={`Tambah Pelanggan ${process.env.REACT_APP_NAME}`}>
          <Form
            form={form}
            layout="vertical"
            className="form-package"
            onFinish={onFinish}
            initialValues={{ company_name: "" }}>
            <Row gutter={[60, 0]}>
              <Col xs={12} md={8}>
                <h5 className="font-medium text-base">Data Perusahaan</h5>
                <Divider className="mt-1 mb-3" />
                <Form.Item
                  name="company_name"
                  label="Nama Perusahaan"
                  className="right-required"
                  rules={[requiredRules]}>
                  <Input placeholder="Nama Perusahaan" />
                </Form.Item>
                <Form.Item
                  name="company_website"
                  label="Website Perusahaan"
                  className="right-required"
                  rules={[
                    requiredRules,
                    { type: "url", message: "Harap masukkan website perusahaan yang benar!" },
                  ]}>
                  <Input placeholder="www.company.com" />
                </Form.Item>
                <Form.Item
                  label="Nomor Telepon"
                  name="company_phone"
                  className="right-required"
                  rules={[requiredRules, { min: 6, message: "Harap masukkan nomor yang valid" }]}>
                  <Input
                    placeholder="0812XXXXXXX"
                    type="number"
                    className="rmArrow"
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
                <Form.Item
                  name="company_estimate_user"
                  label="Jumlah User"
                  className="right-required"
                  rules={[requiredRules]}>
                  <Select placeholder="Pilih">
                    <Select.Option value="1 user">1 - 25 user</Select.Option>
                    <Select.Option value="25 user">25 - 250 user</Select.Option>
                    <Select.Option value="250 user">250 - 500 user</Select.Option>
                    <Select.Option value="500 user">500 - 1000 user</Select.Option>
                    <Select.Option value="1000 user">&#62;1000 user</Select.Option>
                  </Select>
                </Form.Item>
                <h5 className="font-medium text-base">Data Email</h5>
                <Divider className="mt-1 mb-4" />
                <Form.Item
                  name="company_estimate_domain"
                  label="Total Domain"
                  className="right-required"
                  rules={[requiredRules, numberRules, { max: 2 }]}>
                  <Input placeholder="2" addonAfter="domain" />
                </Form.Item>
                <Form.Item
                  name="company_estimate_qouta"
                  label="Estimasi Jumlah Kiriman Email"
                  className="right-required"
                  rules={[requiredRules]}>
                  <Select placeholder="Pilih" placement="bottomLeft">
                    <Select.Option value="<30">&lt;30.000 email per bulan</Select.Option>
                    <Select.Option value="30">30.000 - 50.000 email per bulan</Select.Option>
                    <Select.Option value="50">50.000 - 100.000 email per bulan</Select.Option>
                    <Select.Option value="100">100.000 - 250.000 email per bulan</Select.Option>
                    <Select.Option value="250">250.000 - 500.000 email per bulan</Select.Option>
                    <Select.Option value=">500">&gt;500.000 email per bulan</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={12} md={8}>
                <h5 className="font-medium text-base">Primary Contact</h5>
                <Divider className="mt-1 mb-3" />
                <Form.Item
                  name="contact_name"
                  label="Nama Lengkap"
                  className="right-required"
                  rules={[requiredRules]}>
                  <Input placeholder="Nama Lengkap" />
                </Form.Item>
                <Form.Item
                  name="contact_email"
                  label="Alamat Email"
                  className="right-required"
                  rules={[requiredRules]}>
                  <Input placeholder="email@company.com" />
                </Form.Item>
                <Form.Item
                  name="contact_phone"
                  label="Nomor Handphone"
                  className="right-required"
                  rules={[requiredRules, { min: 8, message: "Harap masukkan nomor yang valid" }]}>
                  <Input
                    placeholder="0812XXXXXXX"
                    type="number"
                    className="rmArrow"
                    onWheel={(e) => e.target.blur()}
                  />
                </Form.Item>
              </Col>
            </Row>
            <div className="w-full flex justify-end gap-x-2">
              <Button icon={<ICCloseOutlined />} danger onClick={() => navigate(-1)}>
                Batalkan
              </Button>
              <Button loading={loading} type="primary" htmlType="submit" icon={<ICSaveOutlined />}>
                Tambah Client
              </Button>
            </div>
          </Form>
        </Card>
      </div>
    </>
  );
};

export default PelangganCreate;
