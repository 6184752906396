import { getUserNotification } from "api";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationList } from "redux/slices/notificationList";

export const useGetNotification = (user) => {
  const dispatch = useDispatch();
  const notificationList = useSelector(({ notificationList }) => notificationList.value);

  const getData = useCallback(async () => {
    const { data } = user.isAdmin ? "" : await getUserNotification();
    dispatch(setNotificationList(data));
  }, [dispatch, user.isAdmin]);

  useEffect(() => {
    if (user && !notificationList) getData();
    else if (user && notificationList) {
      const interval = setInterval(() => {
        getData();
      }, 180000);
      return () => clearInterval(interval);
    }
  }, [getData, notificationList, user]);
};
