import { createSlice } from "@reduxjs/toolkit";

export const HistoryListAdmin = createSlice({
  name: "HistoryListAdmin",
  initialState: {
    value: false, // default state
    code: false,
  },
  reducers: {
    // reducers action
    setHistoryListAdmin: (state, { payload, code }) => {
      state.value = payload;
      state.code = code;
    },
  },
});

export const { setHistoryListAdmin } = HistoryListAdmin.actions;
export default HistoryListAdmin.reducer;
