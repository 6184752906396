import { createSlice } from "@reduxjs/toolkit";

export const listAllClients = createSlice({
  name: "listAllClients",
  initialState: {
    value: false,
  },
  reducers: {
    setListAllClients: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setListAllClients } = listAllClients.actions;
export default listAllClients.reducer;
