import { Card, Col, Divider, Radio, Row } from "antd";
import { getClientChartSummary } from "api";
import { ReportUsageChart } from "components/chart/ChartDashboard";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setReportUsageChart } from "redux/slices/admin/reportUsageChart";

const CustomerReportSummary = () => {
  const dispatch = useDispatch();
  const reportUsageCharts = useSelector(({ reportUsageChart }) => reportUsageChart.value);
  const [valueRadio, setValueRadio] = useState("month");
  const [isLoading, setIsLoading] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 768;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const handleChangeRadio = async ({ target: { value } }) => {
    setValueRadio(value);
    setIsLoading(true);
    const { data } = await getClientChartSummary({ getBy: value });
    dispatch(setReportUsageChart(data));
    setIsLoading(false);
  };

  const RadioButton = () => {
    return (
      <Radio.Group
        buttonStyle="solid"
        defaultValue="month"
        size={width < breakpoint ? "small" : "middle"}
        value={valueRadio}
        onChange={handleChangeRadio}>
        <Radio.Button value="month">This Month</Radio.Button>
        <Radio.Button value="week">This Week</Radio.Button>
        <Radio.Button value="lastmonth">Last Month</Radio.Button>
      </Radio.Group>
    );
  };

  if (!reportUsageCharts)
    return (
      <Card title="Customer Report Summary" className="customer-report-summary-wrapper" loading />
    );

  return (
    <Card
      title="Customer Report Summary"
      className="customer-report-summary-wrapper"
      loading={isLoading}
      extra={<RadioButton />}>
      <Row gutter={[24, 0]}>
        <Col lg={12} md={12} sm={24} xl={6}>
          <div className="flex flex-col px-6 pt-4 pb-1">
            <p className="mb-0 font-medium">5 Customer Pengirim Terbanyak</p>
            <ReportUsageChart
              data={reportUsageCharts?.top_sender_client}
              title="Customer Pengirim Terbanyak"
            />
            <Divider className="my-2 mt-4" />
          </div>
        </Col>
        <Col lg={12} md={12} sm={24} xl={6}>
          <div className="flex flex-col px-6 pt-4 pb-1">
            <p className="mb-0 font-medium">5 Domain Pengirim Terbanyak</p>
            <ReportUsageChart
              data={reportUsageCharts?.top_sender_domain}
              title="Domain Pengirim Terbanyak"
            />
            <Divider className="my-2 mt-4" />
          </div>
        </Col>
        <Col lg={12} md={12} sm={24} xl={6}>
          <div className="flex flex-col px-6 pt-4 pb-1">
            <p className="mb-0 font-medium">5 Email Pengirim Terbanyak</p>
            <ReportUsageChart
              data={reportUsageCharts?.top_sender_email}
              title="Email Pengirim Terbanyak"
            />
            <Divider className="my-2 mt-4" />
          </div>
        </Col>
        <Col lg={12} md={12} sm={24} xl={6}>
          <div className="flex flex-col px-6 pt-4 pb-1">
            <p className="mb-0 font-medium">5 Domain Penerima Terbanyak</p>
            <ReportUsageChart
              data={reportUsageCharts?.top_receiver_domain}
              title="Domain Penerima Terbanyak"
            />
            <Divider className="my-2 mt-4" />
          </div>
        </Col>
      </Row>
    </Card>
  );
};

export default CustomerReportSummary;
