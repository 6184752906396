import { Button } from "antd";
import { resendConfirmEmail } from "api";
import { TitleParagraph } from "components/Components";
import {
  ICCheckCircleFilled,
  ICCloseCircleFilled,
  ICWarningFilled,
} from "components/list/ListIcon";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { removeCookie, removeStorage } from "utils/helpers";

import "./verifikasi.less";

const NotifVerifikasi = ({ StatusVerif: { status } }) => {
  const [counter, setCounter] = useState(5);
  if ((status.includes("actived") || status.includes("diaktifkan")) && counter <= 0) {
    removeCookie(["r_btn"]);
    removeStorage("isValid");
    window.location.href = "/register/verifikasi";
  }

  const resendEmail = async () => {
    await resendConfirmEmail();
  };

  useEffect(() => {
    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [counter]);

  // let status = "";
  let title = "";
  let message = "";
  let actLink = "";
  let icon = "";

  if (status.includes("actived")) {
    icon = <ICCheckCircleFilled className="check-icon green-6" />;
    title = "Verifikasi Email Berhasil";
    message = `Akun anda sudah selesai didaftarkan pada sistem, halaman akan diredirect ke halaman berikutnya dalam ${counter} detik..`;
    actLink = (
      <p className="text-secondary mb-0">
        Jika tidak kunjung tampil, silakan klik
        <Link to="/register/verifikasi"> disini</Link>
      </p>
    );
  } else if (status.includes("diaktifkan")) {
    icon = <ICCheckCircleFilled className="check-icon green-6" />;
    title = "Email Sudah Terverifikasi";
    message = `
    Akun anda sudah dilakukan verifikasi sebelumnya, anda tidak perlu melakukan verifikasi ulang. Halaman ini akan diredirect ke halaman dashboard dalam ${counter} detik..`;
    actLink = (
      <p className="text-secondary mb-0">
        Jika tidak kunjung tampil, silakan klik
        <Link to="/main"> disini</Link>
      </p>
    );
  } else if (status.includes("expired")) {
    icon = <ICCloseCircleFilled className="check-icon text-danger" />;
    title = "Link Tidak Valid";
    message =
      "Link verifikasi email anda sudah expired. Untuk melakukan verifikasi email silakan dapat melakukan permintaan kirm email verifikasi kembali.";
    actLink = (
      <Button onClick={resendEmail} type="link" className="btn-navigate">
        Kirim ulang email verifikasi
      </Button>
    );
  } else if (status.includes("ditangguhkan")) {
    icon = <ICWarningFilled className="check-icon text-warning" />;
    title = "Akun Anda Ditangguhkan";
    message =
      "Akun anda ditangguhkan karena tidak melakukan verifikasi email dalam waktu 7 hari. Silakan dapat melakukan registrasi ulang untuk dapat menggunakan layanan kami.";
    actLink = (
      <Button type="link" block className="btn-navigate" href="/register">
        Akses Halaman Registrasi
      </Button>
    );
  }

  return (
    <div className="flex flex-col justify-center text-center items-center verifikasi-user status mx-auto my-10">
      {/* Berhasil */}
      <div className="container-icon-cp mb-1">{icon}</div>
      <TitleParagraph title={title} desc={message} descClass="mb-4" className="mb-2" />
      {actLink}
    </div>
  );
};

export default NotifVerifikasi;
