import { Progress } from "antd";
import React from "react";

const ProgressPaket = ({ title, used = 0, space = 0 }) => {
  let progressPercent = (used / space) * 100;
  let progressColor = progressPercent >= 80 ? "#FF4D4F" : "#0D9F3F";
  let progressColorKuota =
    progressPercent < 80 ? "#0D9F3F" : progressPercent < 90 ? "#FAAD14" : "#FF4D4F";

  return (
    <div className="mb-4">
      <div className="flex justify-between items-center">
        <span>{title}</span>
        <span className="text-secondary">
          {used.toLocaleString("en-US")}/{space.toLocaleString("en-US")}
        </span>
      </div>
      <Progress
        percent={progressPercent}
        strokeColor={title.toLowerCase() === "kuota" ? progressColorKuota : progressColor}
        showInfo={false}
      />
    </div>
  );
};

export default ProgressPaket;
