import { createSlice } from "@reduxjs/toolkit";

export const detailCompany = createSlice({
  name: "detailCompany",
  initialState: {
    value: false,
  },
  reducers: {
    setDetailCompany: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setDetailCompany } = detailCompany.actions;
export default detailCompany.reducer;
