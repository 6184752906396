export const ConvertDate = (tanggalLangganan) => {
  const tanggalSekarang = Date.now();
  // const tanggalLangganan = 1612137600000;
  const langganan = tanggalSekarang - tanggalLangganan;
  const lama = Math.floor(langganan / (1000 * 3600 * 24));
  const tahun = Math.floor(lama / 360) + " Year ";
  const bulan = Math.floor((lama % 360) / 30) + " Month";

  const hasilBerlangganan = `${tahun < 1 ? bulan : tahun + bulan}`;

  return hasilBerlangganan;
};

export const ConvertFormatDate = (date) => {
  const tanggal = new Date(date);
  const convertArray = tanggal.toString().slice(4, 15);
  const string = convertArray.split(" ");
  const newArray = [string[1], string[0], string[2]];
  return newArray.join(" ");
};

export const CurrencyFormat = (nominal) => {
  const money = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
    minimumFractionDigits: 0,
  }).format(nominal);
  return money;
};
