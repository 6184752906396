import { Tag } from "antd";
import React from "react";

const ContactTag = ({ role }) => {
  const list = [
    { role: "primary", color: "blue" },
    { role: "technical", color: "green" },
    { role: "billing", color: "warning" },
  ];

  const render = list.find((item) => item.role === role);

  const tagRender =
    role === "techbill" ? (
      <div className="flex">
        <Tag color="green">technical</Tag>
        <Tag color="warning">billing</Tag>
      </div>
    ) : (
      <Tag color={render.color}>{render.role}</Tag>
    );

  return tagRender;
};

export default ContactTag;
