import { Card, Tabs } from "antd";
import { getAllPackage, getPackageSummary, getUsedPackage } from "api";
import { ICEditOutlined, ICTagOutlined } from "components/list/ListIcon";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPackageList } from "redux/slices/admin/packageList";
import { setPackageSummary } from "redux/slices/admin/packageSummary";
import { setPackageUsed } from "redux/slices/admin/packageUsed";
import RegisteredPackageTable from "./RegisteredPackageTable";
import UsedPackageTable from "./UsedPackageTable";

const PackageTabs = () => {
  const dispatch = useDispatch();
  const [tabActive, setTabActive] = useState(1);
  const [packageFilter, setPackageFilter] = useState({ page: 1, limit: "10" });
  const [packageUsedFilter, setPackageUsedFilter] = useState({ page: 1, limit: "10" });

  const packageUsed = useSelector(({ packageUsed }) => packageUsed.value);
  const packageList = useSelector(({ packageList }) => packageList.value);

  const onChangeTabs = (key) => setTabActive(key);

  const getSummaryPackage = async () => {
    const { data: summary } = await getPackageSummary();
    dispatch(setPackageSummary(summary));
  };

  const getPackageList = useCallback(async () => {
    const { data: packageList } = await getAllPackage(packageFilter);
    dispatch(setPackageList(packageList));
  }, [packageFilter, dispatch]);

  const getUsedPackageList = useCallback(async () => {
    const { data: packageUsed } = await getUsedPackage(packageUsedFilter);
    dispatch(setPackageUsed(packageUsed));
  }, [packageUsedFilter, dispatch]);

  useEffect(() => {
    if (packageFilter) getPackageList();
  }, [getPackageList, packageFilter]);

  useEffect(() => {
    if (packageUsedFilter) getUsedPackageList();
  }, [packageUsedFilter, getUsedPackageList]);

  if (packageUsed && packageList)
    return (
      <Tabs
        className="package-tab"
        onChange={onChangeTabs}
        activeKey={tabActive}
        items={[
          {
            label: (
              <span>
                <ICEditOutlined />
                Daftar Paket
              </span>
            ),
            key: 1,
            children: (
              <RegisteredPackageTable
                getPackage={getPackageList}
                getPackageSummary={getSummaryPackage}
                filter={packageFilter}
                setFilter={setPackageFilter}
              />
            ),
          },
          {
            label: (
              <span>
                <ICTagOutlined />
                Paket Terpakai
              </span>
            ),
            key: 2,
            children: (
              <UsedPackageTable filter={packageUsedFilter} setFilter={setPackageUsedFilter} />
            ),
          },
        ]}
      />
    );
  return <Card loading bordered={false} />;
};

export default PackageTabs;
