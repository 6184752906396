import Cookies from "js-cookie";
import { getCookie } from "utils/helpers";
import MainService from "./base";

const handleErrorResponse = (error = "API error handle response") =>
  console.error("API ERROR: ", error);

const handleGETRequest = async (api, body) => {
  const {
    result: {
      body: { data, error, msg, status, code },
    },
  } = await MainService(api)
    .doRequest({ query: body })
    .then((result) => result)
    .catch((errorGeneral) => {
      handleErrorResponse(`${api}: ${errorGeneral}`);
      return {
        result: {
          body: { data: null, error: null },
        },
        errorJS: errorGeneral,
      };
    });

  return {
    data,
    error,
    msg,
    status,
    code,
  };
};

const handlePOSTRequest = async (
  api,
  body,
  asFormData = false,
  keyUnite = "",
  useQuery = false
) => {
  const formData = new FormData();
  let actualBody = { ...body };

  if (asFormData) {
    for (const key in body) {
      // reunite array data to existing formData
      if (keyUnite === key && typeof body[key] === "object")
        body[keyUnite].map((i) => formData.append(keyUnite, i));
      else formData.append(key, body[key]);
    }
    actualBody = formData;
  }

  const {
    result: {
      body: { code, data, error, msg, status },
    },
  } = await MainService(api)
    .doRequest({
      query: useQuery ? { ...body } : {},
      body: actualBody,
      hooks: {
        before({ payload, next }) {
          const newPayload = { ...payload };
          if (asFormData) delete newPayload.headers["Content-Type"];
          next(newPayload);
        },
      },
    })
    .then((result) => result)
    .catch((errorResponse) => {
      handleErrorResponse(`${api}: ${errorResponse}`);
      return {
        result: {
          body: { data: null, error: null },
        },
        errorJS: errorResponse,
      };
    });

  if (error) {
    if (error.code === 102 || error.code === 406) {
      Cookies.remove("is-login");
      alert("10001", "Mohon untuk login kembali", "error");
      const currentLocation = encodeURIComponent(window.location.pathname + window.location.search);
      window.location.href = `/login?from=${currentLocation}`;
    }
  }

  return {
    code,
    data,
    msg,
    status,
    error,
  };
};

const handleDELETERequest = async (api, body) => {
  const {
    result: {
      body: { data, error, msg, status, code },
    },
  } = await MainService(api)
    .doRequest({ query: body })
    .then((result) => result)
    .catch((errorGeneral) => {
      handleErrorResponse(`${api}: ${errorGeneral}`);
      return {
        result: {
          body: { data: null, error: null },
        },
        errorJS: errorGeneral,
      };
    });

  return {
    data,
    error,
    msg,
    status,
    code,
  };
};

const handleRefreshRequest = async (api, body) => {
  const {
    result: {
      body: { code, data, error, msg, status },
    },
  } = await MainService(api)
    .doRequest({
      body: body,
      hooks: {
        before({ payload, next }) {
          const token = getCookie("refresh_token");
          const newPayload = { ...payload };
          newPayload.headers = {
            ...payload.headers,
            Authorization: `Bearer ${token}`,
          };
          next(newPayload);
        },
      },
    })
    .then((result) => result)
    .catch((errorResponse) => {
      handleErrorResponse(`${api}: ${errorResponse}`);
      return {
        result: {
          body: { data: null, error: null },
        },
        errorJS: errorResponse,
      };
    });

  if (error) {
    if (error.code === 102 || error.code === 406) {
      Cookies.remove("is-login");
      alert("10001", "Mohon untuk login kembali", "error");
      const currentLocation = encodeURIComponent(window.location.pathname + window.location.search);
      window.location.href = `/login?from=${currentLocation}`;
    }
  }

  return {
    code,
    data,
    msg,
    status,
    error,
  };
};

/** Edit after this part */

/* Users */
export const authLogout = () => handlePOSTRequest("authLogout");
export const refreshToken = (body) => handleRefreshRequest("refresh", body);
export const authLogin = ({ email, password }) =>
  handlePOSTRequest("authLogin", { email, password });
export const authRegister = ({ name, email, password }) =>
  handlePOSTRequest("authRegister", { name, email, password });
export const forgotPassword = ({ email }) => handlePOSTRequest("forgotPassword", { email });
export const resetPassword = ({ newPassword, confirmNewPassword }) =>
  handlePOSTRequest("resetPassword", { newPassword, confirmNewPassword });
export const checkTokenResetPass = (token) => handleGETRequest("checkTokenResetPass", token);
export const checkExistedEmail = ({ email }) => handleGETRequest("checkExistedEmail", { email });
export const getUser = ({ uuid }) => handleGETRequest("getUser", { uuid });
export const addContact = ({ name, email, phone, contactRole }) =>
  handlePOSTRequest("addContact", { name, email, phone, contactRole });
export const deleteContact = ({ contactUuid }) =>
  handlePOSTRequest("deleteContact", { contactUuid });
export const editContact = ({ contactUuid, name, email, phone, contactRole }) =>
  handlePOSTRequest("editContact", { contactUuid, name, email, phone, contactRole });
export const getInfoProfile = () => handleGETRequest("infoProfile");
export const changePassword = ({ id, password, newPassword, confirmNewPassword }) =>
  handlePOSTRequest("changePassword", { id, password, newPassword, confirmNewPassword });

/* Domain  */
export const addDomain = ({ namaDomain, notifikasi }) =>
  handlePOSTRequest("addDomain", { namaDomain, notifikasi });
export const deleteDomain = ({ domainUuid }) => handlePOSTRequest("deleteDomain", { domainUuid });
export const confirmEmail = ({ token }) =>
  handlePOSTRequest("confirmEmail", { token }, false, "", true);
export const resendConfirmEmail = () => handlePOSTRequest("resendConfirmEmail");
export const checkStatusVerification = () => handleGETRequest("checkStatusVerification");
export const setUpNewDomain = ({ namaDomain }) =>
  handlePOSTRequest("setUpNewDomain", { namaDomain, notifikasi: true });
export const checkExistedDomain = ({ namaDomain }) =>
  handleGETRequest("checkExistedDomain", { namaDomain });
export const changeNotification = ({ domainUuid, notifikasi }) =>
  handlePOSTRequest("changeNotification", { domainUuid, notifikasi });
export const activateDomain = ({ domainUuid }) =>
  handlePOSTRequest("activateDomain", { domainUuid });
export const checkDNS = ({ domainUuid }) => handlePOSTRequest("checkDNS", { domainUuid });
export const getDomainActive = () => handleGETRequest("getDomainActive");

//  Send Via API
export const getEmailAPI = () => handleGETRequest("getEmailAPI");
export const GenerateAPI = () => handlePOSTRequest("GenerateAPI");
export const ReGenerateAPI = () => handleRefreshRequest("ReGenerateAPI");

/* Companies */
export const getCompany = () => handleGETRequest("getCompany");
export const getDomain = () => handleGETRequest("getDomain");
export const getContact = () => handleGETRequest("getContact");
export const getAccountSMTP = () => handleGETRequest("getAccountSMTP");
export const getSetupLayanan = () => handleGETRequest("getSetupLayanan");
export const setupCompanyData = ({ name, website, address, phone, estimateUserCount, roleUser }) =>
  handlePOSTRequest("setupCompanyData", {
    name,
    website,
    address,
    phone,
    estimateUserCount,
    roleUser,
  });
export const setupCompanyEmail = ({
  estimateDomain,
  dataSources,
  emailType,
  previousVendor,
  countEmailSent,
}) =>
  handlePOSTRequest("setupCompanyEmail", {
    estimateDomain,
    dataSources,
    emailType,
    previousVendor,
    countEmailSent,
  });
export const getDetailsCompanyData = () => handleGETRequest("getDetailsCompanyData");
export const relayActivation = () => handlePOSTRequest("relayActivation");
export const unlockRelay = () => handlePOSTRequest("unlockRelay");
export const editCompany = (body) => handlePOSTRequest("editCompany", body, true);
export const getConfigNotification = ({ type }) =>
  handleGETRequest("getConfigNotification", { type });
export const changeConfigNotification = ({ type, value }) =>
  handlePOSTRequest("changeConfigNotification", { type, value });
export const changePhotoProfileCompany = ({ photo }) =>
  handlePOSTRequest("changePhotoProfileCompany", { photo }, true);
export const updateNpwp = ({ npwp, npwpType, fileNpwp }) =>
  handlePOSTRequest("updateNpwp", { npwp, npwpType, fileNpwp }, true);
export const updateConfig = () => handlePOSTRequest("updateConfig");
export const getDetailsSubscription = () => handleGETRequest("getDetailSubscription");
export const getCompanyPackage = () => handleGETRequest("getCompanyPackage");

/* Reports */
export const getChartReport = () => handleGETRequest("getChartReport");
export const getTableReport = ({ page = 1, limit = 10, ...filter }) =>
  handleGETRequest("getTableReport", { page, limit, ...filter });
export const getEmailSendingSummary = ({ getBy }) =>
  handleGETRequest("getEmailSendingSummary", { getBy });
export const getLineEmailReports = ({ getBy }) =>
  handleGETRequest("getLineEmailReports", { getBy });
export const getMostEmailSummary = ({ getBy }) =>
  handleGETRequest("getMostEmailSummary", { getBy });
export const getTableLog = ({ page = 1, limit = 10, ...filter }) =>
  handleGETRequest("getTableLog", { page, limit, ...filter });

//  Suppression
export const getSuppressionList = ({ page = 1, limit = 10, ...filterSuppress }) =>
  handleGETRequest("getSuppressionList", { limit, page, ...filterSuppress });
export const getHistoryList = ({ page = 1, limit = 10, ...filterHistory }) =>
  handleGETRequest("getHistoryList", { limit, page, ...filterHistory });
export const AddSuppress = ({ email, message, type }) =>
  handlePOSTRequest("AddSuppress", { email, message, type });
export const DeleteSuppress = ({ email }) => handleDELETERequest("DeleteSuppress", { email });

/* User Notification */
export const getUserNotification = () => handleGETRequest("getUserNotification");
export const markReadNotification = () => handlePOSTRequest("markReadNotification");
export const getDetailUserNotification = ({ uuid }) =>
  handleGETRequest("getDetailUserNotification", { uuid });

/* Admin */
export const getAllCompanies = () => handleGETRequest("getAllCompanies");
export const authLoginAdmin = ({ email, password }) =>
  handlePOSTRequest("authLoginAdmin", { email, password });
export const createAdmin = ({ name, email, role }) =>
  handlePOSTRequest("createAdmin", { name, email, role });
export const editAdmin = ({ uuid, name, email, role }) =>
  handlePOSTRequest("editAdmin", { uuid, name, email, role });
export const deleteAdmin = ({ uuid }) => handlePOSTRequest("deleteAdmin", { uuid });
export const getListAdmin = (body) => handleGETRequest("listAdmin", body);
export const getListActivityAdmin = (body) => handleGETRequest("getAdminLogs", body);
export const resetPasswordAdmin = (body) => handlePOSTRequest("resetPasswordAdmin", body);
export const checkTokenResetPassAdmin = (token) =>
  handleGETRequest("checkTokenResetPassAdmin", token);
export const forgotPasswordAdmin = (body) => handlePOSTRequest("forgotPasswordAdmin", body);
export const changePasswordAdmin = (body) => handlePOSTRequest("changePasswordAdmin", body);
export const getTableLogAdmin = ({ page = 1, limit = 10, ...filter }) =>
  handleGETRequest("getTableLogAdmin", { page, limit, ...filter });
export const getListDomainAdmin = () => handleGETRequest("getListDomainAdmin");
export const getAdmin = (body) => handleGETRequest("getAdmin", body);
export const getEmailSummaryAdmin = () => handleGETRequest("getEmailSummaryAdmin");
export const getTopSenderAdmin = (body) => handleGETRequest("getTopSenderAdmin", body);
export const getGraphAdmin = () => handleGETRequest("getGraphAdmin");
export const addContactClient = (body) => handlePOSTRequest("addContactClient", body);
export const changeProfileAdmin = ({ name, email }) =>
  handlePOSTRequest("changeProfileAdmin", { name, email });
export const getClientChartSummary = (body) => handleGETRequest("getClientSummary", body);
export const addNewClient = (body) => handlePOSTRequest("addNewClient", body);
export const loginAsClient = ({ user, company, contact }) =>
  handlePOSTRequest("loginAsClient", {
    userPrimaryUuid: user,
    companyUuid: company,
    contactPrimaryUuid: contact,
  });
export const getBillingReport = ({
  page,
  limit,
  company_name,
  subcription_name,
  subcription_status,
  subcription_period,
  company_npwp,
}) =>
  handleGETRequest("getBillingReport", {
    page,
    limit,
    company_name,
    subcription_name,
    subcription_status,
    subcription_period,
    company_npwp,
  });
export const exportBillingReport = () => handleGETRequest("exportBillingReport");

/* Admin Company */
export const getAllClients = ({
  page = 1,
  limit = 10,
  company_name,
  package_name,
  status_relay,
  status_subcribtion,
}) =>
  handleGETRequest("getAllClients", {
    page,
    limit,
    company_name,
    package_name,
    status_relay,
    status_subcribtion,
  });
export const getDetailCompany = ({ companyUuid }) =>
  handleGETRequest("getDetailCompany", { companyUuid });
export const getProfileCompany = ({ companyUuid }) =>
  handleGETRequest("getProfileCompany", { companyUuid });
export const getContactClients = ({ companyUuid }) =>
  handleGETRequest("getContactClients", { companyUuid });
export const editCompanyClient = (body) => handlePOSTRequest("editCompanyClient", body, true);
export const modifyStatusRelay = ({ relay_email, new_status }) =>
  handlePOSTRequest("modifyStatusRelay", { relay_email, new_status });
export const summaryClients = () => handleGETRequest("summaryClients");
export const listContactClients = ({ page, limit, ...filter }) =>
  handleGETRequest("listContactClients", { page, limit, ...filter });
export const exportListContact = () => handleGETRequest("exportListContact");
export const exportListCompanies = () => handleGETRequest("exportListCompanies");
export const exportAllReportEmailUsage = () => handleGETRequest("exportAllReportEmailUsage");
export const changePhotoAdmin = (body) => handlePOSTRequest("changePhotoAdmin", body, true);
export const getQuotaUsage = (body) => handleGETRequest("getQuotaUsage", body);
export const getListCompany = () => handleGETRequest("getListCompany");
export const deleteCompany = ({ uuid }) =>
  handlePOSTRequest("deleteCompany", { companyUuid: uuid });
export const updateAdminCompanyProfilePicture = ({ uuid, photo }) =>
  handlePOSTRequest("updateAdminCompanyProfilePicture", { companyUuid: uuid, photo }, true);
export const updateAdminCompanyNpwp = ({ uuid, npwp, npwpType, fileNpwp }) =>
  handlePOSTRequest(
    "updateAdminCompanyNpwp",
    { companyUuid: uuid, npwp, npwpType, fileNpwp },
    true
  );
export const setPasswordRelay = ({ relayAccount, password, validatePassword }) =>
  handlePOSTRequest("setPasswordRelay", { relayAccount, password, validatePassword });

/* Admin Billings */
export const getPackageClient = ({ companyUuid }) =>
  handleGETRequest("getPackageClient", { companyUuid });
export const getInvoicesClient = ({ company_uuid, page, limit }) =>
  handleGETRequest("getInvoicesClient", { company_uuid, page, limit });
export const exportInvoicesClient = ({ uuid }) =>
  handleGETRequest("exportInvoicesClient", { company_uuid: uuid });
export const getFakturClient = ({ company_uuid, page, limit }) =>
  handleGETRequest("getFakturClient", { company_uuid, page, limit });
export const exportFakturClient = ({ uuid }) =>
  handleGETRequest("exportFakturClient", { company_uuid: uuid });
export const getAllInvoices = ({
  page,
  limit,
  company_name = "",
  invoice_number = [],
  paid_status = [],
  nominal = [],
  fp_status = [],
}) =>
  handleGETRequest("getAllInvoices", {
    page,
    limit,
    company_name,
    invoice_number,
    paid_status,
    nominal,
    fp_status,
  });
export const getAllTaxInvoices = ({
  page,
  limit,
  company_name = "",
  inv_number = [],
  tax_number = [],
}) => handleGETRequest("getAllTaxInvoices", { page, limit, company_name, inv_number, tax_number });
export const getAllConfirmationPayment = ({
  page,
  limit,
  companyName = [],
  invNumber = [],
  status = [],
  paymentDate = [],
  destination = [],
}) =>
  handleGETRequest("getAllConfirmationPayment", {
    page,
    limit,
    companyName,
    invNumber,
    status,
    paymentDate,
    destination,
  });
export const addFakturPajak = ({ companyUuid, invUuid, invDate, taxNumber, file, notification }) =>
  handlePOSTRequest(
    "addFakturPajak",
    {
      company_uuid: companyUuid,
      inv_uuid: invUuid,
      inv_date: invDate,
      tax_inv_number: taxNumber,
      sent_email: notification,
      file,
    },
    true
  );
export const editFakturPajak = ({ invDate, taxNumber, file, notification, tax_uuid }) =>
  handlePOSTRequest(
    "editFakturPajak",
    {
      inv_date: invDate,
      tax_inv_number: taxNumber,
      sent_email: notification,
      file,
      tax_uuid,
    },
    true
  );
export const getUnTaxedInvoice = ({ uuid }) =>
  handleGETRequest("getUnTaxedInvoice", { company_uuid: uuid });
export const deleteTaxInvoice = ({ uuid }) =>
  handlePOSTRequest("deleteTaxInvoice", { tax_uuid: uuid });
export const exportAllTaxInvoices = () => handleGETRequest("exportAllTaxInvoice");
export const exportAllInvoices = () => handleGETRequest("exportAllInvoice");
export const getInvoiceSummary = ({ getBy = "" }) =>
  handleGETRequest("getInvoiceSummary", { getBy });
export const exportAllCustomerTaxInvoice = ({ uuid }) =>
  handleGETRequest("exportCustomerTaxInvoice", { company_uuid: uuid });
export const exportAllCustomerInvoice = ({ uuid }) =>
  handleGETRequest("exportCustomerInvoice", { company_uuid: uuid });
export const ResendBillings = ({ invUuid }) => handlePOSTRequest("ResendBillings", { invUuid });
export const ListBanks = () => handleGETRequest("ListBanks");
export const RecordManualPayment = ({ amount, bankId, withTax, taxAmount, invoiceUuid, date }) =>
  handlePOSTRequest("RecordManualPayment", {
    amount,
    bankId,
    withTax,
    taxAmount,
    invoiceUuid,
    date,
  });
export const GenerateInvoice = (uuid) => handlePOSTRequest("GenerateInvoice", uuid);

/*Admin Domains */
export const getDomainClients = ({ companyUuid }) =>
  handleGETRequest("getDomainClients", { companyUuid });
export const addDomainClients = (body) => handlePOSTRequest("addDomainClients", body);
export const changeNotifikasiClients = (body) => handlePOSTRequest("changeNotifikasiClients", body);
export const deleteDomainClients = (body) => handlePOSTRequest("deleteDomainClients", body);

/* Billings */
export const getPackage = () => handleGETRequest("getPackage");
export const getFakturUser = ({ page, limit }) =>
  handleGETRequest("getFakturUser", { page, limit });
export const getPackageAll = () => handleGETRequest("getPackageAll");
export const checkoutLogin = (body) => handlePOSTRequest("checkoutLogin", body);
export const checkoutRegister = (props) => handlePOSTRequest("checkoutRegister", props);
export const getDetailServicePackage = () => handleGETRequest("getDetailServicePackage");
export const getListInvoicesUser = ({ page, limit }) =>
  handleGETRequest("getListInvoices", { page, limit });
export const getCurrentPayment = () => handleGETRequest("getCurrentPayment");
export const checkoutChecker = () => handleGETRequest("checkoutChecker");
export const getInfoOverQuota = () => handleGETRequest("getInfoOverQuota");
export const enableOverQuota = () => handlePOSTRequest("enableOverQuota");
export const changePaymentMethod = ({ paymentMethod }) =>
  handlePOSTRequest("changePaymentMethod", { paymentMethod });
export const ConfirmManualPayment = ({ sourceBank, destinationBank, amount, date, uploadFile }) =>
  handlePOSTRequest(
    "confirmManualPayment",
    {
      sourceBank,
      destinationBank,
      amount,
      date,
      uploadFile,
    },
    true
  );
export const RejectPayment = (uuid) => handlePOSTRequest("RejectPayment", uuid);

// Admin Suppression
export const GetAllSuppressList = ({ page, limit, ...filterSuppress }) =>
  handleGETRequest("GetAllSuppressList", { page, limit, ...filterSuppress });
export const GetHistorySuppressList = ({ page, limit, ...filterHistory }) =>
  handleGETRequest("GetHistorySuppressList", { page, limit, ...filterHistory });
export const GetSummarySuppress = () => handleGETRequest("GetSummarySuppress");
export const CreateSuppress = ({ email, companyUuid, message, global, type }) =>
  handlePOSTRequest("CreateSuppress", { email, companyUuid, message, global, type });
export const DeleteSuppressAdmin = ({ email, companyUuid, global }) =>
  handleDELETERequest("DeleteSuppressAdmin", { email, companyUuid, global });

/* Admin Packages */
export const getPackageSummary = () => handleGETRequest("getPackageSummary");
export const getPackageDetail = ({ uuid }) =>
  handleGETRequest("getPackageDetail", { package_uuid: uuid });
export const deletePackage = ({ uuid }) =>
  handleDELETERequest("deletePackage", { package_uuid: uuid });
export const getAllPackage = ({ page = false, limit = false }) =>
  handleGETRequest("getAllPackage", { page, limit });
export const getPackageListFilter = ({ short }) => handleGETRequest("getAllPackage", { short });
export const changePackageStatus = ({ uuid, status }) =>
  handlePOSTRequest("changePackageStatus", { package_uuid: uuid, status });
export const changePackagePublication = ({ uuid, status }) =>
  handlePOSTRequest("changePackagePublication", { package_uuid: uuid, status });
export const createPackage = ({
  packageName,
  packageDescription,
  packagePrice,
  zohoItemId,
  maxEmailSent,
  maxCountEmailPerHour,
  maxDomain,
  maxAttachment,
  supportService,
  dedicatedIP,
  packageShow,
}) =>
  handlePOSTRequest("createPackage", {
    packageName,
    packageDescription,
    packagePrice,
    zohoItemId,
    maxEmailSent,
    maxCountEmailPerHour,
    maxDomain,
    maxAttachment,
    supportService,
    dedicatedIP,
    packageShow,
  });
export const editPackage = ({
  uuid,
  packageName,
  packageDescription,
  packagePrice,
  zohoItemId,
  maxEmailSent,
  maxCountEmailPerHour,
  maxDomain,
  maxAttachment,
  supportService,
  dedicatedIP,
  packageShow,
}) =>
  handlePOSTRequest("editPackage", {
    uuid,
    packageName,
    packageDescription,
    packagePrice,
    zohoItemId,
    maxEmailSent,
    maxCountEmailPerHour,
    maxDomain,
    maxAttachment,
    supportService,
    dedicatedIP,
    packageShow,
  });
export const getUsedPackage = ({ page, limit }) =>
  handleGETRequest("getUsedPackage", { page, limit });

/* Admin Notification */
export const createNotification = ({ title, category, preview, content, date }) =>
  handlePOSTRequest("createNotification", { title, category, preview, content, date });
export const getListNotificationAdmin = ({ page, limit }) =>
  handleGETRequest("listNotificationAdmin", { page, limit });
export const getNotificationDetailAdmin = ({ uuid }) =>
  handleGETRequest("getNotificationDetailAdmin", { uuid });
export const DeleteNotificationAdmin = ({ notifUuid }) =>
  handleDELETERequest("DeleteNotificationAdmin", { notifUuid });
export const EditNotification = ({ notificationUuid, title, preview, content, category }) =>
  handleRefreshRequest("EditNotification", { notificationUuid, title, preview, content, category });
