import { Button, Alert } from "antd";
import { Link, useLocation } from "react-router-dom";
import overQouta from "assets/images/over_qouta.svg";
import { formatNumberWithDot } from "utils/helpers";

const formatNumberWithCommas = formatNumberWithDot;

export const WarningOverQuota = ({ user }) => {
  const location = useLocation();
  const isOverQuota = location.pathname === "/overusage/confirmation";
  return (
    <div className="over-qouta__warning">
      <Alert
        banner
        {...(!isOverQuota
          ? {
              message: (
                <span>
                  Saat ini Anda menggunakan over usage quota dengan total{" "}
                  <strong>{formatNumberWithCommas(user.overQuotaUsage)} email</strong> terkirim.
                  Lihat estimasi biaya tagihan anda pada menu <Link to="/billing">Tagihan</Link>
                </span>
              ),
            }
          : {
              message: (
                <span>
                  Setelah melakukan konfirmasi, akun anda akan diaktifkan kembali dan dikenakan
                  biaya tambahan sesuai dengan kelebihan penggunaan anda.
                </span>
              ),
            })}
      />
    </div>
  );
};

const OverQouta = () => {
  const location = useLocation();

  const isMainUrl = location.pathname === "/main";
  return (
    <div className="over-qouta__wrapper">
      <div {...(isMainUrl ? { className: "over-qouta__left" } : { className: "over-qouta" })}>
        <h5 className="font-medium text-base">Penggunaan Kuota Email Anda Sudah Mencapai 100%</h5>
        <p className="text-secondary mb-4">
          Anda sudah menggunakan seluruh kuota pengiriman email anda sebesar 100%, saat ini layanan
          tersebut terkena suspend dan anda tidak dapat mengirimkan email ke tujuan. Untuk tetap
          dapat menggunakan email, silakan upgrade paket atau konfirmasi penggunaan over quota.
        </p>
        {isMainUrl && (
          <Button className="upgrade" style={{ marginRight: 8 }}>
            <Link to="/checkout">Upgrade Paket</Link>
          </Button>
        )}
        <Button type="primary">
          <Link to="/overusage/confirmation">Konfirmasi Over Quota</Link>
        </Button>
      </div>
      {isMainUrl && (
        <div className="alert-qouta">
          <img src={overQouta} alt="" />
        </div>
      )}
    </div>
  );
};

export default OverQouta;
