import { Button, Card, Col, Divider, Row } from "antd";
import { getPackageSummary } from "api";
import xenditLogo from "assets/images/xendit.svg";
import { ICLoginOutlined, ICPlusOutlined } from "components/list/ListIcon";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPackageSummary } from "redux/slices/admin/packageSummary";
import { openInNewTab } from "utils/helpers";

const PackageSummary = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const packageSummary = useSelector(({ packageSummary }) => packageSummary.value);

  const BtnAddPackage = () => {
    const navAddPackage = () =>
      navigate("add", {
        state: {
          action: "create",
          headerTitle: "Tambah Paket Baru",
          cardTitle: `Tambah Paket ${process.env.REACT_APP_NAME}`,
        },
      });
    return (
      <Button type="primary" icon={<ICPlusOutlined />} onClick={navAddPackage}>
        Tambah Paket
      </Button>
    );
  };

  const getSummaryPackage = useCallback(async () => {
    const { data: summary } = await getPackageSummary();
    dispatch(setPackageSummary(summary));
  }, [dispatch]);

  useEffect(() => {
    if (!packageSummary) getSummaryPackage();
  }, [getSummaryPackage, packageSummary]);

  if (!packageSummary) return <Card title="Package Summary" extra={<BtnAddPackage />} loading />;

  return (
    <Card title="Package Summary" extra={<BtnAddPackage />} className="package-summary-wrapper">
      <Row gutter={[24, 0]} align="middle">
        <Col lg={12} md={12} sm={24} xl={6}>
          <Card className="card-display-package" bordered={false}>
            <h5 className="mb-0 text-3xl">{packageSummary.totalPackage}</h5>
            <p className="mb-0 text-info">Total Package</p>
            <Divider className="my-2 mb-0" />
          </Card>
        </Col>
        <Col lg={12} md={12} sm={24} xl={6}>
          <Card className="card-display-package" bordered={false}>
            <h5 className="mb-0 text-3xl">{packageSummary.activeSubscription}</h5>
            <p className="mb-0 text-success">Active Subscription</p>
            <Divider className="my-2 mb-0" />
          </Card>
        </Col>
        <Col lg={12} md={12} sm={24} xl={6}>
          <Card className="card-display-package" bordered={false}>
            <h5 className="mb-0 text-3xl">{packageSummary.inactivePackage}</h5>
            <p className="mb-0 text-danger">Inactive Package</p>
            <Divider className="my-2 mb-0" />
          </Card>
        </Col>
        <Col lg={12} md={12} sm={24} xl={6}>
          <Card className="card-display-package" bordered={false}>
            <div className="paper-id-image-wrapper">
              <img src={xenditLogo} alt="Paper Id Logo" className="w-full" />
            </div>
            <Button
              className="invoice-paperId text-info p-0"
              type="link"
              onClick={() => openInNewTab("https://dashboard.xendit.co/recurring")}
              icon={<ICLoginOutlined />}>
              <span className="underline">Akses Payment Gateway</span>
            </Button>
            <Divider className="my-2 mb-0" />
          </Card>
        </Col>
      </Row>
    </Card>
  );
};

export default PackageSummary;
