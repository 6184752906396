import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setIsAdmin } from "redux/slices/pageStatus";

export default function DashboardChecker() {
  // LOGIC CHECKING....
  //  /admin/dashboard    for admin
  // /dashboard           for user

  const { pathname: path } = useLocation();
  const dispatch = useDispatch();

  const pageStatus = useSelector(({ pageStatus }) => pageStatus.value);

  useEffect(() => {
    const isAdmin = path.includes("/admin/");
    if (isAdmin !== pageStatus.isAdmin) dispatch(setIsAdmin({ ...pageStatus, isAdmin }));
  }, [path, dispatch, pageStatus]);

  return <></>;
}
