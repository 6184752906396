import { Alert, Button, Card, Checkbox } from "antd";
import DashboardPageHeader from "components/dashboard-header/DashboardPageHeader";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCheckoutChecker } from "redux/slices/checkoutChecker";
import useCheckoutChecker from "utils/hooks/useCheckoutChecker";
import useDocumentTitle from "utils/useDocumentTitle";
import "./billing-confirmation.less";

const BillingConfirmation = () => {
  useDocumentTitle("Confirmation");
  useCheckoutChecker();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navCheckout = () => {
    dispatch(setCheckoutChecker({ ...isChecked, checked: true }));
    navigate("/checkout");
  };
  const [checked, setChecked] = useState(false);

  const isChecked = useSelector(({ checkoutChecker }) => checkoutChecker.value);
  const toggleCheck = () => setChecked((prev) => !prev);

  return (
    <div className="billingConfirmation">
      <DashboardPageHeader title="Ubah Paket Layanan" backPath="/billing" />
      <div className="flex flex-col px-6 pt-3 gap-y-6">
        {isChecked && (
          <Alert
            message={
              isChecked.inv_number && isChecked.active_subscriptions ? (
                <p className="mb-0">
                  Anda masih memiliki paket langganan yang aktif dengan paket{" "}
                  <span className="font-medium">
                    {process.env.REACT_APP_NAME} Paket {isChecked.active_subscriptions}
                  </span>{" "}
                  dan tagihan aktif yang belum dibayar dengan nomor invoice
                  <span className="font-medium"> {isChecked.inv_number}</span>. Jika anda melakukan
                  pembelian baru, maka paket langganan sebelumnya akan otomatis diubah dengan paket
                  baru dan tagihan sebelumnya akan otomatis dibatalkan oleh sistem.
                </p>
              ) : isChecked.active_subscriptions ? (
                <p className="mb-0">
                  Anda masih memiliki paket langganan yang aktif dengan paket{" "}
                  <span className="font-medium">
                    {process.env.REACT_APP_NAME} Paket {isChecked.active_subscriptions}
                  </span>
                  . Jika anda melakukan pembelian baru, maka paket langganan sebelumnya akan
                  otomatis diubah dengan paket baru.
                </p>
              ) : (
                <p className="mb-0">
                  Anda masih memiliki tagihan aktif yang belum dibayar dengan nomor invoice
                  <span className="font-medium"> {isChecked && isChecked.inv_number}</span>. Jika
                  anda melakukan pembelian baru, maka tagihan sebelumnya akan otomatis dibatalkan
                  oleh sistem.
                </p>
              )
            }
            type="warning"
            showIcon
          />
        )}
        <Card className="billingConfirmation__card" title="Konfirmasi Ubah Paket Layanan">
          <div className="billingConfirmation__body">
            <p>
              Dengan mengubah paket langganan tersebut, berarti pelanggan setuju dengan ketentuan
              berikut:
            </p>
            <ul>
              <li>Proforma invoice yang belum dibayarkan otomatis dibatalkan oleh sistem</li>
              <li>Paket baru akan otomatis aktif setelah anda melakukan pembayaran</li>
              <li>
                Spesifikasi paket akan menyesuaikan dengan paket baru. Jika domain yang dimiliki
                melebihi spesifikasi paket, maka anda harus menghapus salah satu atau beberapa
                domain
              </li>
              <li>
                Paket langganan yang sedang aktif akan hilang setelah paket baru aktif, termasuk
                kuota email yang tersisa
              </li>
              <li>
                Periode berlangganan dan kuota penggunaan akan direset pada paket baru yang diambil,
                periode akan dimulai sejak pembayaran dilakukan
              </li>
            </ul>
          </div>
          <div className="flex flex-col items-start gap-y-1">
            <Checkbox
              className="text-secondary"
              onChange={toggleCheck}
              checked={checked}
              defaultChecked={checked}>
              Saya telah membaca dan menyetujui ketentuan diatas
            </Checkbox>
            <Button type="primary" disabled={!checked} className="ml-auto" onClick={navCheckout}>
              Selanjutnya
            </Button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default BillingConfirmation;
