import { createSlice } from "@reduxjs/toolkit";

export const logEmailAdmin = createSlice({
  name: "logEmailAdmin",
  initialState: {
    value: false,
  },
  reducers: {
    setLogEmailAdmin: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setLogEmailAdmin } = logEmailAdmin.actions;
export default logEmailAdmin.reducer;
