import financeApp from "assets/images/finance_app.svg";
import AlertCard from "components/box-card/AlertCard";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PaymentAlert = () => {
  const { billing } = useSelector(({ userData }) => userData.value);
  const dayNow = new Date();
  const dayDue = new Date(billing.due_date);
  const options = { year: "numeric", month: "long", day: "numeric" };
  const dayDueToLocale = dayDue.toLocaleDateString("id-ID", options);
  let difference = dayDue.getTime() - dayNow.getTime();
  const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
  const navigate = useNavigate();
  const navBilling = () => navigate("/billing");
  const dueDate = totalDays <= 7 && totalDays >= 0;
  if (totalDays > 7)
    return (
      <AlertCard
        titleAlert="Tagihan Anda sudah Terbit"
        contentBody={
          <p className="mb-4 text-secondary">
            Tagihan layanan {process.env.REACT_APP_NAME} anda sudah terbit. Silakan lakukan
            pembayaran sebelum tanggal jatuh tempo:{" "}
            <span className="font-medium">{dayDueToLocale}</span>, agar layanan dapat terus
            digunakan dan tidak terkena suspend.
          </p>
        }
        className="blue-bg"
        imgSrc={financeApp}
        imgAlt="New Invoice"
        btnText="Cek Tagihan Saya"
        onClick={navBilling}
      />
    );

  return (
    <AlertCard
      titleAlert={
        dueDate
          ? "Tagihan Anda akan jatuh tempo. Lakukan pembayaran sebelum dibatalkan"
          : "Tagihan Anda sudah lewat jatuh tempo. Lakukan pembayaran segera!"
      }
      contentBody={
        dueDate ? (
          <p className="mb-4 text-secondary">
            Tagihan layanan {process.env.REACT_APP_NAME} anda akan jatuh tempo
            {totalDays > 0 && " dalam"}
            <span className="font-medium">
              {totalDays > 0 ? ` ${totalDays} hari` : " hari ini"}
            </span>
            . Silakan lakukan pembayaran sebelum{" "}
            <span className="font-medium">{dayDueToLocale}</span>, jika sudah melewati tanggal
            tersebut maka tagihan akan dibatalkan dan layanan terkena suspend.
          </p>
        ) : (
          <p className="mb-4 text-secondary">
            Tagihan layanan {process.env.REACT_APP_NAME} anda sudah lewat jatuh tempo sejak
            <span className="font-medium"> {dayDueToLocale}</span>, Layanan anda memasuki masa
            tenggang yang berlaku 3 hari setelah jatuh tempo. Lakukan pembayaran segera sebelum
            tagihan dibatalkan dan layanan terkena suspend.
          </p>
        )
      }
      imgSrc={financeApp}
      imgAlt="Due Date"
      btnText="Lakukan Pembayaran"
      onClick={navBilling}
      className={dueDate ? "yellow-bg" : "red-bg"}
    />
  );
};

export default PaymentAlert;
