import { Button, Checkbox, Form, Modal, Select, Upload, Tag } from "antd";
import { MaskedInput } from "antd-mask-input";
import { addFakturPajak, editFakturPajak, getUnTaxedInvoice } from "api";
import DatePicker from "components/datepicker/DatePicker";
import { ICUploadOutlined } from "components/list/ListIcon";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { gatherFormData, requiredRules } from "utils/helpers";
import { ModalSuccessFaktur } from "./ModalSuccess";

const FormTaxInvoice = ({ handle }) => {
  return (
    <MaskedInput
      placeholder="Masukkan nomor seri faktur"
      maskOptions={{ mask: "000.000-00.00000000", lazy: false }}
      onChange={handle}
    />
  );
};

const ModalFakturPajak = ({ data = false, action, open = {}, setOpen, cancel, handleSuccess }) => {
  const listCompany = useSelector(({ listAllCompany }) => listAllCompany.value);
  const [list, setList] = useState([]);
  const [listInvoice, setListInvoice] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const onCompanyChange = (_, option) =>
    setList({ companyUuid: option.value, companyName: option.children });
  const onSelectCompany = async (value) => {
    const { data } = await getUnTaxedInvoice({ uuid: value });
    setListInvoice(data);
    const values = form.getFieldsValue();
    if (values.invUuid)
      form.setFields([
        { name: "invUuid", value: false, errors: ["Harap pilih invoice yang tersedia"] },
      ]);
  };
  const onInvoiceChange = (value) => setList({ ...list, invoice: value });
  const [form] = Form.useForm();

  const uploadImgProps = {
    customRequest: ({ file, onSuccess }) => {
      onSuccess("ok");
    },
    multiple: false,
    maxCount: 1,
  };

  useEffect(() => {
    form.resetFields();
    setDisable(false);
    setList([]);
    setListInvoice([]);
  }, [action, form]);

  const getUntaxedInv = useCallback(async () => {
    const { data: untaxed } = await getUnTaxedInvoice({ uuid: data.company_uuid });
    setListInvoice(untaxed);
  }, [data.company_uuid]);

  useEffect(() => {
    if (action === "uploadCompany") {
      getUntaxedInv();
    }
  }, [action, getUntaxedInv]);

  const title = action === "edit" ? "Edit Faktur Pajak" : "Upload Faktur Pajak";

  const addFakturHandle = () => {
    const callback = async () => {
      setIsLoading(true);
      const values = form.getFieldsValue();
      const { code, msg } = await addFakturPajak({
        ...values,
        companyUuid: list.companyUuid || data.company_uuid,
        invUuid: list.invoice,
        file: values.file[0].originFileObj,
      });
      if (code === 200 && msg.includes("success")) {
        setIsLoading(false);
        setOpen(false);
        ModalSuccessFaktur({
          action: "upload",
          companyName: data.company_name || list.companyName,
          onOk: handleSuccess,
        });
      }
      setIsLoading(false);
    };
    gatherFormData(form, callback);
  };

  const editFakturHandle = () => {
    const callback = async () => {
      setIsLoading(true);
      const values = form.getFieldValue();
      const { code, msg } = await editFakturPajak({
        ...values,
        invUuid: list.invoice,
        file: values.file[0].originFileObj,
        tax_uuid: data.tax_uuid,
      });
      setIsLoading(false);
      if (code === 200 && msg.toLowerCase().includes("success")) {
        setOpen(false);
        ModalSuccessFaktur({
          action: "edit",
          companyName: data.company_name,
          onOk: handleSuccess,
        });
      }
    };
    gatherFormData(form, callback);
  };

  const handleMask = ({ unmaskedValue }) => {
    const values = form.getFieldsValue();
    form.setFieldsValue({ ...values, taxNumber: unmaskedValue });
  };

  if (!open) return "";

  return (
    <Modal
      centered
      title={title}
      open={open}
      onOk={action === "edit" ? editFakturHandle : addFakturHandle}
      okButtonProps={{ htmlType: "submit", loading: isLoading, disabled: disable }}
      onCancel={cancel}
      okText="Simpan"
      cancelText="Batal">
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          companyName: data.company_name,
          invNumber: data.inv_number,
          taxNumber: data.tax_number,
          notification: true,
        }}>
        <Form.Item
          label="Nama Perusahaan"
          name="companyName"
          className="mb-4 right-required"
          rules={[{ required: true, message: "Nama Perusahaan harus diisi!" }]}>
          <Select
            showSearch
            placeholder="Masukkan Nama Perusahaan"
            optionFilterProp="children"
            onChange={onCompanyChange}
            onSelect={onSelectCompany}
            notFoundContent="Harap pilih nama perusahaan terlebih dahulu"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={action === "edit" || action === "uploadCompany"}>
            {listCompany.map((company) => (
              <Select.Option value={company.company_uuid} key={company.company_uuid}>
                {company.company_name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Nomor Invoice"
          name="invNumber"
          className="mb-4 right-required"
          rules={[{ required: true, message: "Nomer Invoice harus diisi!" }]}>
          <Select
            showSearch
            placeholder="Masukkan Nomor Invoice"
            optionFilterProp="children"
            onChange={onInvoiceChange}
            notFoundContent="Tidak ada invoice tersedia"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            disabled={action === "edit"}>
            {listInvoice.map((invoice) => (
              <Select.Option value={invoice.inv_uuid} key={invoice.inv_uuid}>
                <span style={{ marginRight: 8 }}>{invoice.inv_number}</span>
                <Tag className="font-medium">{invoice.inv_status}</Tag>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Tanggal Faktur Pajak"
          name="invDate"
          className="mb-4"
          rules={[requiredRules]}>
          <DatePicker className="w-full" format="yyyy-MM-dd" />
        </Form.Item>
        <Form.Item
          label="Nomor Seri Faktur Pajak"
          name="taxNumber"
          className="mb-4 right-required"
          rules={[
            { required: true, message: "Nomer Seri Faktur Pajak harus diisi!" },
            { min: 16, message: "Nomor Seri Faktur Pajak Belum Sesuai" },
          ]}>
          <FormTaxInvoice handle={handleMask} />
        </Form.Item>
        <Form.Item
          name="file"
          label="Faktur Pajak"
          className="mb-4 right-required"
          valuePropName="fileList"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) return e;
            return e?.fileList;
          }}
          rules={[
            {
              validator: (rule, value) => {
                if (!value) {
                  setDisable(true);
                  return Promise.reject(new Error("Harap Upload Faktur Pajak"));
                } else {
                  const fileSize = value[0].size / 1024 / 1024 < 2;
                  if (!fileSize) {
                    setDisable(true);
                    return Promise.reject(new Error("Ukuran file terlalu besar (maksimal: 2MB)"));
                  } else {
                    setDisable(false);
                    return Promise.resolve();
                  }
                }
              },
            },
          ]}>
          <Upload {...uploadImgProps} accept="application/pdf">
            <Button icon={<ICUploadOutlined />}>Upload Faktur Pajak</Button>
          </Upload>
        </Form.Item>
        <Form.Item className="mb-0" name="notification" valuePropName="checked">
          <Checkbox>Kirim email notifikasi ke client</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalFakturPajak;
