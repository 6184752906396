import { Card, Col, Divider, Row, Tag } from "antd";
import { getDetailUserNotification } from "api";
import DashboardPageHeader from "components/dashboard-header/DashboardPageHeader";
import MarkdownInput from "components/input/MarkdownInput";
import { ICNotificationOutlined, ICPercentageOutlined } from "components/list/ListIcon";
import React, { useCallback, useEffect, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import { capitalizeFirstLetter } from "utils/helpers";
import useGetCompanyPackage from "utils/hooks/useGetCompanyPackage";
import useDocumentTitle from "utils/useDocumentTitle";
import "./notification.less";
import NotificationInformationCard from "./NotificationInformationCard";

const CategoryNotification = ({ category }) => {
  const categoryList = [
    { name: "info", icon: <ICNotificationOutlined /> },
    { name: "promo", icon: <ICPercentageOutlined /> },
  ];
  const categoryRender = categoryList.find(({ name }) => name === category);
  if (categoryRender)
    return <Tag icon={categoryRender.icon}>{capitalizeFirstLetter(categoryRender.name)}</Tag>;
  else return <Tag>{capitalizeFirstLetter(category)}</Tag>;
};

const NotificationDetails = () => {
  useGetCompanyPackage();
  const [data, setData] = useState(false);
  const { state } = useLocation();
  const { name } = useParams();
  useDocumentTitle(`${name.split("-").join(" ") || "Notification"}`);

  const getNotificationDetail = useCallback(async () => {
    const { data } = await getDetailUserNotification({ uuid: state.uuid });
    setData(data);
  }, [state?.uuid]);

  useEffect(() => {
    if (state && name) getNotificationDetail();
  }, [getNotificationDetail, name, state]);

  if (!state) return <Navigate to="/notification" />;

  return (
    <div className="notification-detail">
      <DashboardPageHeader title={name.split("-").join(" ")} backPath="/notification" />
      <div className="py-4 px-6">
        <Row gutter={[24, 24]}>
          <Col xl={18} lg={24} md={24} sm={24} xs={24}>
            <Card bordered={false} loading={!data}>
              <MarkdownInput data={data.content} />
              <Divider className="mt-4 mb-6" />
              <div className="categoryNotification">
                <span>Kategori Notifikasi:</span>
                <CategoryNotification category={data.category} />
              </div>
            </Card>
          </Col>
          <Col xl={6} lg={24} md={24} sm={24} xs={24}>
            <NotificationInformationCard />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NotificationDetails;
