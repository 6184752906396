import { createSlice } from "@reduxjs/toolkit";

export const reportEmailChart = createSlice({
  name: "reportEmailChart",
  initialState: {
    value: false, // default state
  },
  reducers: {
    // reducers action
    setReportEmailChart: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setReportEmailChart } = reportEmailChart.actions;
export default reportEmailChart.reducer;
