import { Modal } from "antd";
import { FormRecordPayment } from "components/form/FormManualPayment";
import { useState } from "react";
import ModalSuccess from "./ModalSuccess";
import ModalError from "./ModalError";
import { RecordManualPayment } from "api";
import { format } from "date-fns";

export const ModalManualPayment = ({
  open,
  close,
  dataTable,
  handleBillPayment,
  billPayment,
  form,
  handlePphPayment,
  pphPayment,
  billCount,
  pphCount,
  validateNominalPajak,
  setBillCount,
  setPphCount,
  callbackInvoice,
  callbackSummary,
}) => {
  const [tanggalFieldValue, setTanggalFieldValue] = useState(null);
  const [rekeningFieldValue, setRekeningFieldValue] = useState(null);
  const [matchCount, setMatchCount] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const HandleManualPayment = async () => {
    setLoadingSubmit(true);
    const requestBody = {
      amount: billCount,
      taxAmount: pphCount ? pphCount : 0,
      withTax: pphPayment ? true : false,
      bankId: rekeningFieldValue,
      invoiceUuid: dataTable.inv_uuid,
      date: format(new Date(tanggalFieldValue), "yyyy-MM-dd"),
    };

    const response = await RecordManualPayment(requestBody);

    if (response.code === 404) {
      return ModalError({
        title: "Bank ID Not Found",
        customMessage: response.msg,
        width: 470,
        onOk: () => {
          close();
          setLoadingSubmit(false);
        },
      });
    } else if (response.code === 400) {
      return ModalError({
        title: "Input doesnt Match",
        customMessage: response.msg,
        width: 470,
        onOk: () => {
          close();
          setLoadingSubmit(false);
        },
      });
    } else if (response.code === 200) {
      return ModalSuccess({
        title: "Record payment berhasil dilakukan",
        customMessage: (
          <span>
            Pembayaran untuk nomor invoice{" "}
            <span className="font-medium">{dataTable.inv_number}</span> milik{" "}
            {dataTable.company_name} telah berhasil diperbaharui pada sistem.
          </span>
        ),
        width: 470,
        onOk: () => {
          close();
          setLoadingSubmit(false);
          callbackInvoice();
          callbackSummary();
        },
      });
    } else {
      return ModalError({
        title: "Terdapat kesalahan pada sistem",
        customMessage:
          "Terdapat kendala pada sistem saat memperbarui pembayaran customer. Silakan hubungi Tim Product Development",
        width: 470,
        onOk: () => {
          close();
          setLoadingSubmit(false);
        },
      });
    }
  };

  return (
    <Modal
      centered
      title={"Record Pembayaran"}
      open={open}
      onOk={HandleManualPayment}
      okButtonProps={{
        disabled: billPayment
          ? !(tanggalFieldValue && rekeningFieldValue)
          : pphPayment
          ? !(billCount && pphCount && tanggalFieldValue && rekeningFieldValue)
          : !(billPayment && pphPayment)
          ? !(billCount && matchCount && tanggalFieldValue && rekeningFieldValue)
          : true,
      }}
      onCancel={close}
      okText="Simpan"
      cancelText="Batal"
      confirmLoading={loadingSubmit}>
      <FormRecordPayment
        form={form}
        dataTable={dataTable}
        handleBillPayment={handleBillPayment}
        billPayment={billPayment}
        setTanggalFieldValue={setTanggalFieldValue}
        setRekeningFieldValue={setRekeningFieldValue}
        handlePphPayment={handlePphPayment}
        pphPayment={pphPayment}
        billCount={billCount}
        pphCount={pphCount}
        validateNominalPajak={validateNominalPajak}
        setBillCount={setBillCount}
        setPphCount={setPphCount}
        setMatchCount={setMatchCount}
      />
    </Modal>
  );
};
