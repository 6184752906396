import { createSlice } from "@reduxjs/toolkit";

export const packageUsed = createSlice({
  name: "packageUsed",
  initialState: {
    value: false,
  },
  reducers: {
    setPackageUsed: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setPackageUsed } = packageUsed.actions;
export default packageUsed.reducer;
