import { createSlice } from "@reduxjs/toolkit";

export const SuppressionListAdmin = createSlice({
  name: "SuppressionListAdmin",
  initialState: {
    value: false, // default state
    code: false,
  },
  reducers: {
    // reducers action
    setSuppressionListAdmin: (state, { payload, code }) => {
      state.value = payload;
      state.code = code;
    },
  },
});

export const { setSuppressionListAdmin } = SuppressionListAdmin.actions;
export default SuppressionListAdmin.reducer;
