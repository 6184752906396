import { Card, Checkbox, Form, Switch } from "antd";
import { changeConfigNotification } from "api";
import React, { useState } from "react";

const NotificationSettings = ({ data, role }) => {
  const [form] = Form.useForm();
  const [statusNotifikasi, setStatusNotifikasi] = useState(
    data[2]?.config_value.toString() === "true" ? true : false
  );
  const [loadingSection, setLoadingSection] = useState(false);

  const changeSwitchNotif = (values) => {
    form.validateFields().then(async () => {
      setLoadingSection(true);
      await changeConfigNotification({
        type: "email-notification",
        value: values.toString(),
      });
      setLoadingSection(false);
      setStatusNotifikasi(values);
    });
  };

  const handleDailyStat = (values) => {
    const checked = values.target.checked;
    form.validateFields().then(async () => {
      await changeConfigNotification({
        type: "daily-static",
        value: checked.toString(),
      });
    });
  };

  const handleEmailFailed = (values) => {
    const checked = values.target.checked;
    form.validateFields().then(async () => {
      await changeConfigNotification({
        type: "email-failed",
        value: checked.toString(),
      });
    });
  };
  return (
    <div className="notification-settings">
      <Card title="Pengaturan Notifikasi">
        <div className="flex flex-col">
          <div className="flex justify-between mb-4">
            <p className="mb-0 font-medium">Aktifkan notifikasi email</p>
            <Switch
              defaultChecked={data[2]?.config_value.toString() === "true" ? true : false}
              disabled={role === "billing"}
              onChange={changeSwitchNotif}
              loading={loadingSection}
            />
          </div>
          <div className="checkbox mb-2">
            <Checkbox
              defaultChecked={data[0]?.config_value.toString() === "true" ? true : false}
              disabled={role !== "billing" && statusNotifikasi ? false : true}
              onChange={handleDailyStat}>
              Daily Statistics Report
            </Checkbox>
          </div>
          <div className="checkbox">
            <Checkbox
              defaultChecked={data[1]?.config_value.toString() === "true" ? true : false}
              disabled={role !== "billing" && statusNotifikasi ? false : true}
              onChange={handleEmailFailed}>
              Email Failed Report
            </Checkbox>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default NotificationSettings;
