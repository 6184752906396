import { Col, Divider } from "antd";

const convertNumber = (number = 0) => {
  if (typeof number !== "number" || number === "-") return null;
  return number.toLocaleString("en-US");
};

const CardSummarySuppress = ({ number, title }) => {
  let titleText = (
    <>
      {title === "Global" && <p className="mb-0">{title} Suppression</p>}
      {title === "Whitelist" && <p className="mb-0 text-success">{title}</p>}
      {title === "Hard" && <p className="mb-0 text-danger">{title} Bounce</p>}
      {title === "Manual" && (
        <p className="mb-0" style={{ color: "#FA8C16" }}>
          {title} Added
        </p>
      )}
    </>
  );

  const isDisabled = number === "-";

  return (
    <Col lg={12} md={12} sm={24} xl={6}>
      <div className="py-4 px-6 bg-white">
        {isDisabled ? (
          <h5 className="mb-0 text-3xl text-disable">-</h5>
        ) : (
          <h5 className="mb-0 text-3xl">{convertNumber(number)}</h5>
        )}
        {titleText}
        <Divider className="my-2 mb-0" />
      </div>
    </Col>
  );
};

export default CardSummarySuppress;
