import { Button, Dropdown, Menu, Space, Table, Tag } from "antd";
import {
  changePackagePublication,
  changePackageStatus,
  deletePackage,
  getPackageDetail,
} from "api";
import {
  ICCheckCircleOutlined,
  ICCloseCircleOutlined,
  ICDeleteOutlined,
  ICDownOutlined,
  ICEditOutlined,
  ICEyeInvisibleOutlined,
  ICEyeOutlined,
  ICPoweroffOutlined,
} from "components/list/ListIcon";
import ModalConfirm from "components/popups/modal/ModalConfirm";
import ModalError from "components/popups/modal/ModalError";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import TableTitle from "components/tableSendiri/TableTitle";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CurrencyFormat } from "utils/ConvertData";

const RegisteredPackageTable = ({ getPackage, getPackageSummary, filter, setFilter }) => {
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1200;

  const packageList = useSelector(({ packageList }) => packageList.value);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const Action = (record) => {
    return (
      <Menu
        items={[
          {
            label: "Edit Paket",
            key: 1,
            icon: <ICEditOutlined />,
            onClick: async () => {
              const { data } = await getPackageDetail({ uuid: record.uuid });
              data &&
                navigate("edit", {
                  state: {
                    data,
                    action: "edit",
                    headerTitle: "Edit Paket",
                    cardTitle: `Edit Paket ${process.env.REACT_APP_NAME}`,
                    uuid: record.uuid,
                  },
                });
            },
          },
          {
            label: "Lihat Paket",
            key: 2,
            icon: <ICEyeOutlined />,
            onClick: async () => {
              const { data } = await getPackageDetail({ uuid: record.uuid });
              data &&
                navigate("view", {
                  state: {
                    data,
                    action: "view",
                    headerTitle: record.name,
                    cardTitle: "Detail Paket",
                    disable: true,
                    uuid: record.uuid,
                  },
                });
            },
          },
          {
            label: record.status === "active" ? "Nonaktifkan Paket" : "Aktifkan Paket",
            key: 3,
            icon: <ICPoweroffOutlined />,
            onClick: () =>
              record.status === "active"
                ? ModalConfirm({
                    title: `Nonaktifkan paket layanan ${record.name}`,
                    customMessage: (
                      <>
                        <p className="mb-4">
                          Apakah anda yakin ingin menonaktifkan paket layanan tersebut?
                        </p>
                        <p className="mb-0">
                          Setelah menonaktifkan paket, klien tidak dapat menggunakan paket tersebut
                          dan paket akan disembunyikan.
                        </p>
                      </>
                    ),
                    okButtonProps: { danger: true },
                    okText: "Ya nonaktifkan",
                    onOk: async () => {
                      await changePackageStatus({ uuid: record.uuid, status: "inactive" });
                      ModalSuccess({
                        title: `Paket layanan ${record.name} berhasil dinonaktifkan`,
                        width: 499,
                        message: `Paket layanan ${record.name} sudah berhasil dinonaktifkan dan sembunyikan pada sistem`,
                        onOk: () => {
                          getPackage();
                          getPackageSummary();
                        },
                      });
                    },
                  })
                : ModalConfirm({
                    title: `Aktifkan paket layanan ${record.name}`,
                    customMessage: (
                      <>
                        <p className="mb-4">
                          Apakah anda yakin ingin mengaktifkan paket layanan tersebut?
                        </p>
                        <p className="mb-0">
                          Setelah mengaktifkan paket, klien dapat melihat dan menggunakan paket
                          tersebut saat melakukan pembelian.
                        </p>
                      </>
                    ),
                    okText: "Ya aktifkan",
                    onOk: async () => {
                      await changePackageStatus({ uuid: record.uuid, status: "active" });
                      ModalSuccess({
                        title: `Paket layanan ${record.name} berhasil diaktifkan`,
                        message: `Paket layanan ${record.name} sudah berhasil diaktifkan kembali pada sistem`,
                        width: 471,
                        onOk: () => {
                          getPackage();
                          getPackageSummary();
                        },
                      });
                    },
                  }),
          },
          {
            label: record.statusPublication ? "Sembunyikan Paket" : "Tampilkan Paket",
            key: 4,
            icon: <ICEyeInvisibleOutlined />,
            disabled: record.status === "inactive",
            onClick: () =>
              record.statusPublication
                ? ModalConfirm({
                    title: `Sembunyikan paket layanan ${record.name}`,
                    customMessage: (
                      <>
                        <p className="mb-4">
                          Apakah anda yakin ingin menyembunyikan paket layanan tersebut?
                        </p>
                        <p className="mb-0">
                          Setelah menyembunyikan paket, klien tidak dapat melihat paket tersebut
                          pada halaman checkout dan tidak dapat menggunakannya.
                        </p>
                      </>
                    ),
                    okButtonProps: { danger: true },
                    okText: "Ya Sembunyikan",
                    onOk: async () => {
                      await changePackagePublication({
                        uuid: record.uuid,
                        status: !record.statusPublication,
                      });
                      ModalSuccess({
                        title: `Paket layanan ${record.name} berhasil disembunyikan`,
                        width: 471,
                        message: `Paket layanan ${record.name} sudah berhasil disembunyikan pada sistem`,
                        onOk: () => getPackage(),
                      });
                    },
                  })
                : ModalConfirm({
                    title: `Tampilkan paket layanan ${record.name}`,
                    customMessage: (
                      <>
                        <p className="mb-4">
                          Apakah anda yakin ingin menampilkan paket layanan tersebut?
                        </p>
                        <p className="mb-0">
                          Setelah menampilkan paket, klien dapat melihat paket dan berlangganan
                          melalui halaman checkout
                        </p>
                      </>
                    ),
                    okText: "Ya tampilkan",
                    onOk: async () => {
                      await changePackagePublication({
                        uuid: record.uuid,
                        status: !record.statusPublication,
                      });
                      ModalSuccess({
                        title: `Paket layanan ${record.name} berhasil ditampilkan`,
                        message: `Paket layanan ${record.name} sudah berhasil ditampilkan pada sistem`,
                        width: 471,
                        onOk: () => getPackage(),
                      });
                    },
                  }),
          },
          {
            label: "Hapus Paket",
            key: 5,
            icon: <ICDeleteOutlined />,
            danger: true,
            onClick: () => {
              ModalConfirm({
                title: `Hapus ${record.name}`,
                customMessage: (
                  <>
                    <p className="mb-4">
                      Apakah anda yakin ingin menghapus paket layanan tersebut?
                    </p>
                    <p className="mb-0">
                      Setelah menghapus paket, seluruh client yang menggunakan paket tersebut akan
                      tidak memiliki paket.
                    </p>
                  </>
                ),
                okButtonProps: { danger: true },
                onOk: async () => {
                  const { data } = await deletePackage({ uuid: record.uuid });
                  if (data === "active-sub") {
                    ModalError({
                      title: `Paket Layanan ${record.name} Gagal Dihapus`,
                      message: `Paket layanan ${record.name} gagal dihapus pada sistem karena terdapat customer yang menggunakan paket tersebut`,
                    });
                  } else {
                    ModalSuccess({
                      title: `Paket layanan ${record.name} berhasil dihapus`,
                      width: 452,
                      message: `Paket layanan ${record.name} sudah berhasil dihapus pada sistem`,
                      onOk: () => getPackage(),
                    });
                  }
                },
              });
            },
          },
        ]}
      />
    );
  };
  const column = [
    {
      title: "Nama Paket",
      dataIndex: "name",
      key: "name",
      ...(width < breakpoint ? { width: 240 } : {}),
    },
    {
      title: "Deskripsi",
      dataIndex: "description",
      key: "description",
      ...(width < breakpoint ? { width: 320 } : {}),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ...(width < breakpoint ? { width: 91 } : {}),
      render: (status) => (
        <Tag className="m-0" color={status === "active" ? "blue" : "red"}>
          {status === "active" ? "Active" : "Inactive"}
        </Tag>
      ),
    },
    {
      title: "Show",
      dataIndex: "statusPublication",
      key: "statusPublication",
      align: "center",
      ...(width < breakpoint ? { width: 73 } : {}),
      render: (show) =>
        show ? (
          <ICCheckCircleOutlined className="text-success checkIcon" />
        ) : (
          <ICCloseCircleOutlined className="text-secondary checkIcon" />
        ),
    },
    {
      title: "Biaya Langganan",
      dataIndex: "price",
      key: "price",
      ...(width < breakpoint ? { width: 180 } : {}),
      align: "right",
      render: (item) => CurrencyFormat(item),
    },
    {
      dataIndex: "button",
      key: "button",
      ...(width < breakpoint ? { width: 120 } : {}),
      render: (_, record) => (
        <Dropdown trigger={["click"]} overlay={() => Action(record)}>
          <Button>
            <Space>
              Action
              <ICDownOutlined />
            </Space>
          </Button>
        </Dropdown>
      ),
    },
  ];

  return (
    <Table
      columns={column}
      dataSource={packageList.rows}
      pagination={{
        pageSize: filter.limit,
        current: filter.page,
        hideOnSinglePage: true,
        onChange: (page) => {
          setFilter({ ...filter, page });
        },
        total: packageList.count,
        showSizeChanger: false,
        size: "small",
      }}
      title={() => (
        <TableTitle
          title="Daftar Paket"
          desc={`List paket ${process.env.REACT_APP_NAME} yang tersedia`}
          pageLimit={{
            currentLimit: filter.limit,
            onSelectLimit: (limit) => setFilter({ ...filter, limit }),
            disable: packageList.count <= 10,
          }}
          buttonExport={{
            dataCSV: packageList.rows,
            // headerCSV: headerCSV,
            fileName: "Daftar-Paket-All Client",
          }}
        />
      )}
      rowKey={(record) => record.uuid}
      {...(width < breakpoint ? { scroll: { x: 1176 } } : {})}
    />
  );
};

export default RegisteredPackageTable;
