import { getDomain } from "api";
import { useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { setListDomain } from "redux/slices/listDomain";

const useGetDomain = () => {
  const dispatch = useDispatch();

  const getData = useCallback(async () => {
    const { data } = await getDomain();
    dispatch(setListDomain(data));
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);
};

export default useGetDomain;
