import { Button, Card } from "antd";
import ContactList from "components/contact-list/ContactList";
import { ICPlusOutlined } from "components/list/ListIcon";
import ModalContactPelanggan from "components/popups/modal/ModalContactPelanggan";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const BtnAddContact = ({ setOpen, user }) => {
  const showModal = () => setOpen(true);
  const disabledBtn = !user.isAdmin && user.role !== "primary" && user.role !== "techbill";

  return (
    <Button type="primary" icon={<ICPlusOutlined />} disabled={disabledBtn} onClick={showModal}>
      Tambah Kontak
    </Button>
  );
};

const CardContactInformation = ({ contact, loading, sectionLoad, company_uuid }) => {
  const user = useSelector(({ userData }) => userData.value);
  const [visible, setVisible] = useState(false);
  const closeModal = () => {
    setVisible(false);
  };

  return (
    <>
      <Card
        title="Informasi Kontak"
        extra={<BtnAddContact setOpen={setVisible} user={user} />}
        loading={loading === "contactInformation"}>
        {contact &&
          contact.map((user) => (
            <ContactList user={user} key={user.contactUuid} sectionLoad={sectionLoad} />
          ))}
      </Card>
      <ModalContactPelanggan
        action="add"
        visible={visible}
        sectionLoad={sectionLoad}
        onCancel={closeModal}
        uuid={company_uuid}
      />
    </>
  );
};

export default CardContactInformation;
