import { Col, Row } from "antd";
import {
  getContactClients,
  getDetailCompany,
  getPackageClient,
  getProfileCompany,
  updateAdminCompanyProfilePicture,
} from "api";
import CardPacketInfo from "components/box-card/CardPacketInfo";
import CardProfileNew from "components/box-card/CardProfileNew";
import DashboardPageHeader from "components/dashboard-header/DashboardPageHeader";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setReportBillingCustomerDetails } from "redux/slices/admin/reportBillingCustomerDetails";
import useDocumentTitle from "utils/useDocumentTitle";
import OverviewTagihan from "./OverviewTagihan";
import "./reportBilling.less";

const DetailReportBilling = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const { company_name, company_uuid } = state;
  const { id = "" } = useParams();
  const customerDetail = useSelector(
    ({ reportBillingCustomerDetails }) => reportBillingCustomerDetails.value
  );
  useDocumentTitle(`Detail ${customerDetail.profile?.companyName || "Customer"}`);

  const getData = useCallback(async () => {
    setLoading("all");
    const { data: companyProfile } = await getProfileCompany({ companyUuid: company_uuid });
    const { data: companyInfo } = await getDetailCompany({ companyUuid: company_uuid });
    const { data: contactInfo } = await getContactClients({ companyUuid: company_uuid });
    const { data: packetInfo } = await getPackageClient({ companyUuid: company_uuid });
    dispatch(
      setReportBillingCustomerDetails({
        profile: companyProfile,
        company: companyInfo,
        contact: contactInfo,
        packet: packetInfo,
      })
    );
    setLoading(false);
  }, [dispatch, company_uuid]);

  useEffect(() => {
    if (id && state) {
      getData();
    } else navigate("admin/report-billing");
  }, [id, state, getData, navigate]);

  const changeImage = async (option) => {
    setLoading("cardProfile");
    const { file } = option;
    await updateAdminCompanyProfilePicture({ photo: file, uuid: company_uuid });
    const { data: companyProfile } = await getProfileCompany({ companyUuid: company_uuid });
    dispatch(setReportBillingCustomerDetails({ ...customerDetail, profile: companyProfile }));
    setLoading(false);
  };

  return (
    <div className="report-billing">
      <div className="detail-billing-report">
        <DashboardPageHeader title={company_name} />
        <div className="px-6 py-4">
          <Row gutter={[24, 24]}>
            <Col sm={24} xl={6} className="colDetail">
              <Row gutter={24}>
                <Col sm={24} md={12} xl={24}>
                  <div className="profile-picture mb-6">
                    <CardProfileNew
                      name={customerDetail && customerDetail.profile?.companyName}
                      photo={customerDetail && customerDetail.profile?.companyPhoto}
                      website={customerDetail && customerDetail.profile?.companyWebsite}
                      createdAt={customerDetail && customerDetail.profile?.companyCreatedAt}
                      loading={loading}
                      infoProfile={customerDetail && customerDetail?.profile}
                      changeImage={changeImage}
                    />
                  </div>
                </Col>
                <Col sm={24} md={12} xl={24}>
                  <CardPacketInfo data={customerDetail.packet} loading={loading} />
                </Col>
              </Row>
            </Col>
            <Col sm={24} md={24} xl={18} className="colDetail">
              <OverviewTagihan data={customerDetail} loading={loading} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default DetailReportBilling;
