import { Modal } from "antd";

const { warning } = Modal;

const ModalWarning = (props) => {
  const { title, width = 416, message, customMessage, okButtonProps } = props;
  const messageModal = customMessage || <p className="mb-0">{message}</p>;

  return warning({
    ...props,
    title: title,
    width: width,
    content: messageModal,
    centered: true,
    okText: "Oke",
    okButtonProps: { ...okButtonProps },
    autoFocusButton: null,
  });
};

export default ModalWarning;
