import { Checkbox, Form, Modal } from "antd";
import { addDomain } from "api";
import FormDomain from "components/form/FormDomain";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { gatherFormData, runFunction } from "utils/helpers";
import ModalConfirm from "./ModalConfirm";

const ModalAddDomain = ({ isOpen, setOpen, getData }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);

  const handleAddDomain = () => {
    const callback = async () => {
      setLoading(true);
      const values = form.getFieldsValue();
      const { data, code } = await addDomain(values);
      setOpen(false);
      setLoading(false);
      if (data && data.includes("limit")) {
        ModalConfirm({
          title: `Domain ${values.namaDomain} gagal ditambahkan`,
          width: 600,
          customMessage: (
            <div>
              <p className="mb-4">
                Domain {values.namaDomain} gagal ditambahkan karena anda sudah menggunakan seluruh
                kuota domain anda.
              </p>
              <p className="mb-0">
                Untuk melakukan penambahan domain, silakan lakukan upgrade paket anda ke paket yang
                lebih tinggi.
              </p>
            </div>
          ),
          cancelText: "Upgrade Paket",
          okText: "Oke",
          onCancel: () => navigate("/billing/confirmation"),
          onOK: () => form.resetFields(),
        });
      } else if (data === "used-trial") {
        ModalConfirm({
          title: `Domain ${values.namaDomain} gagal ditambahkan`,
          width: 600,
          customMessage: (
            <div>
              <p className="mb-4">
                Domain {values.namaDomain} gagal ditambahkan karena sudah pernah menggunakan trial
                sebelumnya.
              </p>
              <p className="mb-0">
                Untuk menggunakan domain tersebut lagi, silakan dapat berlangganan paket layanan
                yang kami sediakan.
              </p>
            </div>
          ),
          cancelText: "Berlangganan Sekarang",
          onCancel: () => navigate("/checkout"),
          okText: "Oke",
          onOk: () => form.resetFields(),
        });
      } else if (code === 400 && data === "package") {
        ModalConfirm({
          title: `Domain ${values.namaDomain} gagal ditambahkan`,
          width: 600,
          customMessage: (
            <div>
              <p className="mb-4">
                Domain {values.namaDomain} gagal ditambahkan karena saat ini anda tidak memiliki
                paket berlangganan apapun
              </p>
              <p className="mb-0">
                Untuk dapat menambahkan domain baru, silakan dapat berlangganan paket layanan yang
                kami sediakan.
              </p>
            </div>
          ),
          cancelText: "Berlangganan Sekarang",
          onCancel: () => navigate("/checkout"),
          okText: "Oke",
          onOk: () => form.resetFields(),
        });
      } else if (code === 201) {
        runFunction(() => getData(false, "domainSection"));
      }
    };
    gatherFormData(form, callback);
  };

  const handleCancelDomain = () => {
    form.resetFields();
    setOpen(false);
  };

  return (
    <Modal
      title="Tambah Domain Baru"
      open={isOpen}
      centered
      okButtonProps={{
        htmlType: "submit",
        disabled: registered.disabled,
        loading: loading,
      }}
      onOk={handleAddDomain}
      onCancel={handleCancelDomain}
      okText="Simpan"
      cancelText="Batal">
      <Form layout="vertical" form={form} name="tambahDomain" initialValues={{ notifikasi: true }}>
        <FormDomain
          className="mb-4"
          label="Nama Domain"
          setRegistered={setRegistered}
          registered={registered}
        />
        <Form.Item name="notifikasi" className="mb-0" valuePropName="checked">
          <Checkbox>Aktifkan notifikasi via email untuk domain ini</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalAddDomain;
