import { Checkbox, Form, Input, Modal } from "antd";
import { editContact } from "api";
import { useState } from "react";
import { gatherFormData, requiredRules, runFunction } from "utils/helpers";
import ModalSuccess from "./ModalSuccess";

const ModalEditProfile = ({ isOpen, setOpen, data, getOthers }) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const closeForm = () => setOpen(false);

  const roleContact =
    data.contactRole === "primary"
      ? "primary"
      : data.contactRole === "techbill"
      ? ["technical", "billing"]
      : [data.contactRole];

  const handleEditContact = () => {
    const callback = async () => {
      setLoading(true);
      const values = form.getFieldsValue();
      const contactRole =
        data.contactRole === "primary"
          ? "primary"
          : values.contactRole.length > 1
          ? "techbill"
          : values.contactRole[0];

      const { status, code } = await editContact({
        ...values,
        contactUuid: data.contactUuid,
        contactRole: contactRole,
      });
      if (code === 200 && status === "success") {
        setOpen(false);
        ModalSuccess({
          title: "Kontak berhasil diupdate",
          message: "Kontak berhasil diupdate ke sistem",
          onOk: () => {
            runFunction(() => getOthers());
          },
        });
      }
      setOpen(false);
      setLoading(false);
    };
    gatherFormData(form, callback);
  };

  const validateMessages = {
    required: "Harap diisi terlebih dahulu",
    types: {
      email: "Harap masukkan alamat email yang vaild",
    },
  };

  return (
    <Modal
      title="Edit Kontak"
      open={isOpen === "profile"}
      okButtonProps={{ htmlType: "submit", loading: loading }}
      onCancel={closeForm}
      okText="Simpan"
      cancelText="Batal"
      onOk={handleEditContact}
      centered>
      <Form
        layout="vertical"
        form={form}
        name="editContact"
        initialValues={{
          name: data?.contactName,
          email: data?.contactEmail,
          phone: data?.contactPhone,
          contactRole: roleContact,
        }}
        validateMessages={validateMessages}>
        <Form.Item label="Nama Lengkap" name="name" className="mb-4" rules={[requiredRules]}>
          <Input />
        </Form.Item>
        <Form.Item label="Alamat Email" name="email" className="mb-4" rules={[requiredRules]}>
          <Input type="email" />
        </Form.Item>
        <Form.Item
          label="Nomor Handphone"
          name="phone"
          className="mb-4"
          rules={[requiredRules, { min: 8, message: "Harap masukkan nomor yang valid" }]}>
          <Input className="rmArrow" type="number" onWheel={(e) => e.target.blur()} />
        </Form.Item>
        <Form.Item
          name="contactRole"
          className="mb-1"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error("Harap pilih role kontak tersebut")),
            },
            { required: true, message: "Harap pilih role kontak tersebut" },
          ]}>
          <Checkbox.Group disabled>
            <div className="flex flex-col gap-y-1">
              <Checkbox value="technical">Set sebagai kontak Technical</Checkbox>
              <Checkbox value="billing">Set sebagai kontak Billing</Checkbox>
            </div>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalEditProfile;
