import { Button, Card, Col, Descriptions, Form, Input, Modal, Row } from "antd";
import { changePasswordAdmin, changeProfileAdmin } from "api";
import { ICEditOutlined, ICUnlockOutlined } from "components/list/ListIcon";
import ModalError from "components/popups/modal/ModalError";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import ModalWarning from "components/popups/modal/ModalWarning";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserData } from "redux/slices/user";
import { gatherFormData } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";

const ModalEditProfileAdmin = ({ open, setOpen, data }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    const callback = async () => {
      const values = form.getFieldsValue();
      const { name, email } = values;
      setLoading(true);
      const { code, msg } = await changeProfileAdmin({ name, email });
      setTimeout(() => {
        setLoading(false);
        if (code === 400 && msg.includes("registered!")) {
          ModalError({
            title: "Profil gagal diperbarui",
            message: "Email sudah teregistrasi, silahkan gunakan email lainnya",
            onOk: () => {},
          });
        } else if (code === 400 && msg.includes("internal")) {
          ModalError({
            title: "Alamat Email Tidak Diizinkan",
            message:
              "Harap gunakan alamat email dengan domain internal untuk setiap akun admin (contoh: @aktiva.co.id atau @excellent.co.id)",
            onOk: () => {},
          });
        } else if (code === 200 && msg.includes("Success")) {
          setOpen(false);
          ModalSuccess({
            title: "Akun berhasil di update",
            message: `Akun admin ${data?.name} berhasil diupdate pada sistem`,
            onOk: () => {
              dispatch(setUserData(false));
            },
          });
        }
      });
    };
    gatherFormData(form, callback);
  };

  return (
    <Modal
      title="Edit Admin"
      open={open}
      okButtonProps={{ htmlType: "submit" }}
      footer={[
        <Button onClick={() => setOpen(false)}>Batal</Button>,
        <Button type="primary" loading={loading} onClick={handleOk}>
          Simpan
        </Button>,
      ]}
      onCancel={() => setOpen(false)}
      centered>
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          name: data?.name,
          email: data?.email,
          role: data?.role === "superadmin" ? "Super Admin" : data?.role,
        }}>
        <Form.Item label="Nama Admin" name="name" className="mb-4">
          <Input />
        </Form.Item>
        <Form.Item label="Alamat Email" name="email" className="mb-4">
          <Input />
        </Form.Item>
        <Form.Item label="Role" name="role" className="mb-4">
          <Input disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const ModalChangePasswordAdmin = ({ open, setOpen }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSimpan = () => {
    const callback = async () => {
      const values = form.getFieldsValue();

      setLoading(true);
      const { code, msg } = await changePasswordAdmin({
        confirmNewPassword: values.confirmNewPassword,
        newPassword: values.newPassword,
        password: values.password,
      });
      setLoading(false);
      if (code === 400 && msg.includes("Wrong email or password")) {
        ModalError({
          title: "Password gagal diperbarui",
          message: "Pastikan password lama yang dimasukan sudah benar",
          onOk: () => {},
        });
      } else if (
        code === 400 &&
        (msg.includes("fails to match") || msg.includes("characters long"))
      ) {
        ModalWarning({
          title: "Password gagal diperbarui",
          message:
            "Password harus mengandung minimal 8 Karakter, komninasi huruf, angka dan simbol",
          onOk: () => {},
        });
      } else if (code === 400 && msg.includes("must be")) {
        ModalError({
          title: "Password gagal diperbarui",
          message: "Konfirmasi password baru tidak sama, silahkan cek kembali",
          onOk: () => {},
        });
      } else if (code === 200 && msg.includes("successfully")) {
        form.resetFields();
        setOpen(false);
        ModalSuccess({
          title: "Password berhasil diperbarui",
          message: "Password anda berhasil diperbarui pada sistem",
          onOk: () => {},
        });
      }
    };

    gatherFormData(form, callback);
  };

  return (
    <Modal
      title="Ubah Password"
      open={open}
      okButtonProps={{ htmlType: "submit" }}
      footer={[
        <Button key="back" onClick={() => setOpen(false)}>
          Batal
        </Button>,
        <Button key="submit" type="primary" loading={loading} onClick={handleSimpan}>
          Simpan
        </Button>,
      ]}
      onCancel={() => setOpen(false)}
      centered>
      <Form layout="vertical" form={form}>
        <Form.Item
          label="Password Saat ini"
          name="password"
          className="mb-4"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error("Harap masukkan password anda")),
            },
          ]}>
          <Input.Password placeholder="Masukan Password Lama" className="input-form-login w-full" />
        </Form.Item>
        <Form.Item
          label="Password Baru"
          name="newPassword"
          className="mb-4"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error("Harap masukkan password anda")),
            },
          ]}>
          <Input.Password placeholder="Masukan Password Baru" className="input-form-login w-full" />
        </Form.Item>
        <Form.Item
          label="Ulangi Password Baru"
          name="confirmNewPassword"
          className="mb-4"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(new Error("Harap masukkan password anda")),
            },
          ]}>
          <Input.Password
            placeholder="Masukan Kembali Paswword Baru!"
            className="input-form-login w-full"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const InformasiAdmin = () => {
  useDocumentTitle("Profile Admin");
  const userData = useSelector(({ userData }) => userData.value);
  const [showModalProfile, setShowModalProfile] = useState(false);
  const [showModalPassword, setShowModalPassword] = useState(false);

  return (
    <>
      <Card loading={!userData} title="Informasi Admin" className="mb-6">
        <Descriptions layout="horizontal" column={1} bordered className="drawer-desc-wrapper">
          <Descriptions.Item label="Nama">{userData?.name}</Descriptions.Item>
          <Descriptions.Item label="Email">{userData?.email}</Descriptions.Item>
          <Descriptions.Item label="Role">
            {userData?.role === "superadmin" ? "Super Admin" : userData?.role}
          </Descriptions.Item>
        </Descriptions>

        <Row gutter={[10, 0]}>
          <Col sm={12}>
            <Button
              icon={<ICEditOutlined />}
              type="primary"
              className="w-full"
              onClick={() => setShowModalProfile(true)}>
              Edit Profil
            </Button>
          </Col>
          <Col sm={12}>
            <Button
              icon={<ICUnlockOutlined />}
              className="w-full"
              onClick={() => setShowModalPassword(true)}>
              Ubah Password
            </Button>
          </Col>
        </Row>
      </Card>
      {/* Modal Edit Profile */}
      <ModalEditProfileAdmin
        open={showModalProfile}
        setOpen={setShowModalProfile}
        data={userData}
      />
      {/* Modal Change Password */}
      <ModalChangePasswordAdmin open={showModalPassword} setOpen={setShowModalPassword} />
    </>
  );
};

export default InformasiAdmin;
