import { Form, Input, Radio } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { regexEmail, requiredEmail } from "utils/helpers";

const FormSuppress = ({
  className = "",
  wrapperCol,
  label = "",
  isSetup = false,
  onEmailChange,
  EmailValidation,
  setReason,
  RadioState,
  onChoose,
}) => {
  const handleReasonChange = (e) => {
    setReason(e.target.value);
  };

  const inputEmail = <Input placeholder="nama@perusahaan.tld" onChange={onEmailChange} />;
  const inputReason = (
    <TextArea
      placeholder="Opsional: alasan mengapa email tujuan di block"
      onChange={handleReasonChange}
    />
  );

  const RadioButton = (
    <Radio.Group onChange={onChoose} value={RadioState}>
      <Radio value={"blacklist"}>Blacklist Suppression</Radio>
      <Radio value={"whitelist"}>Whitelist Suppression</Radio>
    </Radio.Group>
  );

  return (
    <Form.Item
      label={label}
      className={className}
      wrapperCol={wrapperCol}
      hasFeedback
      emailFieldValue={inputEmail}
      reasonFieldValue={inputReason}>
      {!isSetup ? (
        <div>
          <p className="mb-4">
            Tambah manual alamat email tujuan kedalam suppression list email, maka anda tidak dapat
            mengirimkan email ke alamat email tersebut.
          </p>
          <Form.Item
            label="Alamat Email"
            name="email"
            className="mb-4"
            rules={[requiredEmail, { pattern: regexEmail, message: EmailValidation }]}>
            {inputEmail}
          </Form.Item>
          <Form.Item className="mb-4">{RadioButton}</Form.Item>
          <Form.Item label="Alasan" name="message" className="mb-0">
            {inputReason}
          </Form.Item>
        </div>
      ) : null}
    </Form.Item>
  );
};

export default FormSuppress;
