import { Button, Card, Col, Row, Divider, Form, Alert, Typography } from "antd";
import { changePaymentMethod, getCurrentPayment } from "api";
import paidBilling from "assets/images/paidBilling.svg";
import {
  ICArrowRightOutlined,
  ICCreditCardOutlined,
  ICEditOutlined,
  ICInfoCircleOutlined,
} from "components/list/ListIcon";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCurrentPayment } from "redux/slices/currentPayment";
import { ModalChangePayment } from "components/popups/modal/ModalChangePayment";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import { ModalInfoPayment } from "components/popups/modal/ModalInfoPayment";
import ModalError from "components/popups/modal/ModalError";
import { ModalConfirmManual } from "components/popups/modal/ModalConfirmManualPay";
import { getNumericValue, formatNumberWithDot } from "utils/helpers";
import { ClockCircleOutlined } from "@ant-design/icons";

const NextBill = ({ servicePackage }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [modalChangePayment, setModalChangePayment] = useState(false);
  const [modalConfirmation, setModalConfirmation] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [paymentType, setPaymentType] = useState(null);
  const [paymentState, setPaymentState] = useState(null);
  const [process, setProcess] = useState(false);
  const [billPayment, setBillPayment] = useState(false);
  const [billCount, setBillCount] = useState(null);
  const [tanggalFieldValue, setTanggalFieldValue] = useState(null);
  const [rekeningTargetFieldValue, setRekeningTargetFieldValue] = useState(null);
  const [sourceFieldValue, setSourceFieldValue] = useState(null);
  const [fileValue, setFileValue] = useState(null);
  const [form] = Form.useForm();

  const getCurrentPayments = useSelector(({ currentPayment }) => currentPayment.value);
  const { data: table } = getCurrentPayments;

  const getData = useCallback(async () => {
    setLoading(true);
    const { data } = await getCurrentPayment();
    setPaymentType(data.payment_method);
    setPaymentState(data.payment_method === "xendit" ? 1 : 2);
    dispatch(setCurrentPayment({ ...getCurrentPayment, data }));
    setTimeout(setLoading, 1000, false);
  }, [dispatch]);

  useEffect(() => {
    if (!table) getData();
  }, [getData, table]);

  const navigate = useNavigate();

  const handleCheckout = () => {
    navigate("/checkout");
  };

  const handleOpenModalChange = () => {
    setModalChangePayment(true);
  };

  const handleCloseModalChange = () => {
    setModalChangePayment(false);
  };

  const handleOpenModalInfo = () => {
    setModalInfo(true);
  };

  const handleCloseModalInfo = () => {
    setModalInfo(false);
  };

  const handleOpenConfirmation = () => {
    setModalConfirmation(true);
  };

  const handleCloseConfirmation = () => {
    setModalConfirmation(false);
    form.resetFields();
    setBillCount(null);
    setBillPayment(false);
    setTanggalFieldValue(null);
    setRekeningTargetFieldValue(null);
    setSourceFieldValue(null);
    setFileValue(null);
  };

  const handleBillPayment = (e) => {
    const isChecked = e.target.checked;
    setBillPayment(isChecked);
    if (isChecked && getCurrentPayments?.data?.price) {
      const billAmount = parseFloat(
        formatNumberWithDot(getNumericValue(getCurrentPayments?.data?.price))
          .replace(/\./g, "")
          .replace(",", ".")
      );
      setBillCount(billAmount);
    } else {
      setBillCount(null);
    }
  };

  const handleChangePayment = async () => {
    setProcess(true);
    const response = await changePaymentMethod({
      paymentMethod: paymentState === 1 ? "xendit" : "manual",
    });
    if (response.code === 201) {
      return ModalSuccess({
        title: "Metode pembayaran berhasil diperbarui",
        customMessage:
          "Metode pembayaran layanan telah berhasil diperbarui. Silakan dapat melakukan pembayaran dengan metode pembayaran tersebut",
        width: 471,
        onOk: () => {
          getData();
          handleCloseModalChange();
          setProcess(false);
        },
      });
    } else {
      return ModalError({
        title: "Terdapat kesalahan pada sistem",
        customMessage:
          "Perubahan metode pembayaran gagal dilakukan. Harap dapat mencoba kembali atau dapat menghubungi Tim Support kami",
        width: 471,
        onOk: () => {
          setProcess(false);
        },
      });
    }
  };

  const unpaid = (
    <>
      {(getCurrentPayments?.data?.status && getCurrentPayments?.data?.overQuotaInv) ||
      getCurrentPayments?.data?.status ||
      getCurrentPayments?.data?.overQuotaInv ? (
        <div className="flex flex-col next-bill">
          <p className="mb-3 text-secondary">Tagihan yang perlu dibayarkan</p>
          {getCurrentPayments?.data?.status && getCurrentPayments?.data?.status !== "paid" && (
            <>
              {getCurrentPayments?.data?.overQuotaInv ? (
                <>
                  <Row style={{ display: "flex", justifyContent: "space-between" }}>
                    <Col>
                      <h4 className="text-xl font-medium mb-1">
                        {getCurrentPayments?.data?.price}
                      </h4>
                      <p className="mb-0 text-secondary text-status">
                        {getCurrentPayments?.data?.status === "overdue"
                          ? "Overdue sejak "
                          : "Due date "}
                        <span className="text-danger text-status">
                          {getCurrentPayments?.data?.due_date}
                        </span>
                      </p>
                    </Col>
                    <Col>
                      <Button
                        href={getCurrentPayments?.data?.link_payment}
                        target="_blank"
                        icon={<ICCreditCardOutlined />}
                        type="primary">
                        {getCurrentPayments?.data && getCurrentPayments?.data?.overQuotaInv
                          ? "Bayar"
                          : "Bayar Sekarang"}
                      </Button>
                    </Col>
                  </Row>
                  <Divider style={{ marginTop: 8, marginBottom: 12 }} />
                </>
              ) : (
                <div className="flex flex-col items-start next-bill">
                  <h4 className="text-xl font-medium mb-1">{getCurrentPayments?.data?.price}</h4>
                  <p className="mb-3 text-secondary text-status">
                    {getCurrentPayments?.data?.status === "overdue"
                      ? "Overdue sejak "
                      : "Due date "}
                    <span className="text-danger text-status">
                      {getCurrentPayments?.data?.due_date}
                    </span>
                  </p>
                  {getCurrentPayments?.data?.confirmation_status === "pending-confirmation" ? (
                    <Alert
                      message={
                        <span className="text-xs font-medium font-alert-warning">
                          Menunggu verifikasi pembayaran
                        </span>
                      }
                      type="warning"
                      icon={<ClockCircleOutlined />}
                      showIcon
                      className="alert-waiting"
                    />
                  ) : (
                    <div className="flex flex-col gap-y-1 w-full">
                      {paymentType !== "xendit" && (
                        <Button
                          type="default"
                          className="mb-1"
                          onClick={() => handleOpenModalInfo()}>
                          <Typography.Text ellipsis className="ellipsis">
                            <ICInfoCircleOutlined />
                            <span className="ml-2">Informasi Rekening</span>
                          </Typography.Text>
                        </Button>
                      )}
                      <Button
                        {...(paymentType === "xendit"
                          ? { href: getCurrentPayments?.data?.link_payment }
                          : { onClick: () => handleOpenConfirmation() })}
                        target="_blank"
                        type="primary">
                        <Typography.Text ellipsis className="ellipsis text-white">
                          <ICCreditCardOutlined />
                          <span className="ml-2">
                            {paymentType === "xendit" ? "Bayar Sekarang" : "Konfirmasi Pembayaran"}
                          </span>
                        </Typography.Text>
                      </Button>
                      <Button
                        className="text-primary"
                        type="link"
                        onClick={() => handleOpenModalChange()}>
                        <Typography.Text ellipsis className="ellipsis">
                          <ICEditOutlined />
                          <span className="ml-2">Ubah Metode Pembayaran</span>
                        </Typography.Text>
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
          {getCurrentPayments.data?.overQuotaInv && (
            <>
              {getCurrentPayments?.data?.status && getCurrentPayments?.data?.status !== "paid" ? (
                <Row style={{ display: "flex", justifyContent: "space-between" }}>
                  <Col>
                    <h4 className="text-xl font-medium mb-1">
                      {getCurrentPayments?.data?.overQuotaInv?.price}
                    </h4>
                    <p className="mb-3 text-secondary text-status">
                      {getCurrentPayments?.data?.overQuotaInv?.status === "overdue"
                        ? "Overdue sejak "
                        : "Due date "}
                      <span className="text-danger text-status">
                        {getCurrentPayments?.data?.overQuotaInv?.due_date}
                      </span>
                    </p>
                  </Col>
                  <Col>
                    <Button
                      href={getCurrentPayments?.data?.overQuotaInv?.link_payment}
                      target="_blank"
                      icon={<ICCreditCardOutlined />}
                      type="primary">
                      {getCurrentPayments?.data && getCurrentPayments?.data?.overQuotaInv
                        ? "Bayar"
                        : "Bayar Sekarang"}
                    </Button>
                  </Col>
                  <Divider style={{ margin: 0 }} />
                </Row>
              ) : (
                <div className="flex flex-col items-start next-bill">
                  <h4 className="text-xl font-medium mb-1">
                    {getCurrentPayments?.data?.overQuotaInv?.price}
                  </h4>
                  <p className="mb-3 text-secondary text-status">
                    {getCurrentPayments?.data?.overQuotaInv?.status === "overdue"
                      ? "Overdue sejak "
                      : getCurrentPayments?.data?.overQuotaInv?.status !== "overdue"
                      ? "Due date "
                      : undefined}
                    <span className="text-danger text-status">
                      {getCurrentPayments?.data?.overQuotaInv?.due_date}
                    </span>
                  </p>
                  <Button
                    href={getCurrentPayments?.data?.overQuotaInv?.link_payment}
                    target="_blank"
                    icon={<ICCreditCardOutlined />}
                    type="primary">
                    Bayar Sekarang
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      ) : undefined}
      {servicePackage.startDateNextSubscriptionPeriod &&
      servicePackage.endDateNextSubscriptionPeriod ? (
        <p className="mt-2 mb-0 text-secondary" style={{ fontSize: "12px", fontWeight: 500 }}>
          Periode: {servicePackage.startDateNextSubscriptionPeriod} -{" "}
          {servicePackage.endDateNextSubscriptionPeriod}
        </p>
      ) : null}
    </>
  );

  const paid = (
    <div className="flex flex-col items-start next-bill">
      <p className="mb-3 text-secondary">Tagihan anda sudah dibayarkan</p>
      <img src={paidBilling} alt="" className="m-auto w-full h-auto" />
      {servicePackage.startDateNextSubscriptionPeriod &&
      servicePackage.endDateNextSubscriptionPeriod ? (
        <p className="mt-2 mb-0 text-secondary" style={{ fontSize: "12px", fontWeight: 500 }}>
          Periode: {servicePackage.startDateNextSubscriptionPeriod} -{" "}
          {servicePackage.endDateNextSubscriptionPeriod}
        </p>
      ) : null}
    </div>
  );

  const noSubscribe = (
    <div className="flex flex-col items-start next-bill">
      <p className="mb-3 text-secondary">Belum ada langganan aktif</p>
      <Button type="primary" icon={<ICArrowRightOutlined />} onClick={handleCheckout}>
        Langganan Sekarang
      </Button>
    </div>
  );

  const content =
    (getCurrentPayments?.data?.status === "paid" && getCurrentPayments?.data?.overQuotaInv) ||
    getCurrentPayments?.data?.status === "unpaid" ||
    getCurrentPayments?.data?.status === "overdue" ||
    getCurrentPayments?.data?.overQuotaInv
      ? unpaid
      : getCurrentPayments?.data?.status === "paid" ||
        (getCurrentPayments?.data?.status === "paid" &&
          getCurrentPayments?.data?.confirmation_status === "confirmed")
      ? paid
      : noSubscribe;
  return (
    <Card loading={loading} title="Tagihan Aktif" className="card-next-billing">
      {content}
      <ModalChangePayment
        open={modalChangePayment}
        close={handleCloseModalChange}
        paymentType={paymentType}
        handleChangePayment={handleChangePayment}
        process={process}
        setPaymentState={setPaymentState}
        paymentState={paymentState}
      />
      <ModalInfoPayment open={modalInfo} close={handleCloseModalInfo} />
      <ModalConfirmManual
        open={modalConfirmation}
        close={handleCloseConfirmation}
        getCurrentPayments={getCurrentPayments}
        form={form}
        setBillCount={setBillCount}
        billPayment={billPayment}
        billCount={billCount}
        handleBillPayment={handleBillPayment}
        callbackData={getData}
        tanggalFieldValue={tanggalFieldValue}
        setTanggalFieldValue={setTanggalFieldValue}
        rekeningTargetFieldValue={rekeningTargetFieldValue}
        setRekeningTargetFieldValue={setRekeningTargetFieldValue}
        sourceFieldValue={sourceFieldValue}
        setSourceFieldValue={setSourceFieldValue}
        fileValue={fileValue}
        setFileValue={setFileValue}
      />
    </Card>
  );
};

export default NextBill;
