import React from "react";
import { Button, Descriptions, Drawer, Badge } from "antd";
import { ICCloseOutlined } from "components/list/ListIcon";
import "./drawer.less";

const DetailDrawer = ({ visible, onClose, rowData }) => {
  const Alldetails = rowData.category === "hard" || rowData.category === "soft";
  return (
    <Drawer
      closable={false}
      className="drawer-log-detail title-report-email"
      title="Suppression Detail"
      width={736}
      open={visible}
      onClose={onClose}
      extra={
        <Button icon={<ICCloseOutlined />} type="text" onClick={onClose} className="text-success">
          Close
        </Button>
      }>
      <Descriptions
        layout="horizontal"
        column={1}
        bordered
        className="drawer-desc-wrapper"
        labelStyle={{ padding: "0 12px" }}>
        {Alldetails && (
          <>
            <Descriptions.Item label="Waktu Ditambahkan">{rowData.timestamp}</Descriptions.Item>
            <Descriptions.Item label="Host Server">{rowData.mx_host}</Descriptions.Item>
            <Descriptions.Item label="Message ID">{rowData.message_id}</Descriptions.Item>
            <Descriptions.Item label="Domain Pengirim">{rowData.sender_domain}</Descriptions.Item>
            <Descriptions.Item label="Penerima">{rowData.email}</Descriptions.Item>
            <Descriptions.Item label="Status">
              <Badge
                color={
                  rowData.category === "hard"
                    ? "red"
                    : rowData.category === "manual"
                    ? "#FADB14"
                    : "orange"
                }
                text={
                  rowData.category === "hard"
                    ? "Hard Bounce"
                    : rowData.category === "manual"
                    ? "Manual Added"
                    : "Soft bounce"
                }
                style={{ fontWeight: 500 }}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Pesan Error">{rowData.message}</Descriptions.Item>
          </>
        )}
        {!Alldetails && (
          <>
            <Descriptions.Item label="Waktu Ditambahkan">{rowData.timestamp}</Descriptions.Item>
            <Descriptions.Item label="Penerima">{rowData.email}</Descriptions.Item>
            <Descriptions.Item label="Status">
              <Badge
                color={
                  rowData.category === "manual"
                    ? "#FADB14"
                    : rowData.category === "whitelist"
                    ? "#0D9F3F"
                    : ""
                }
                text={
                  rowData.category === "manual"
                    ? "Manual Added"
                    : rowData.category === "whitelist"
                    ? "Whitelist"
                    : ""
                }
                style={{ fontWeight: 500 }}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Pesan Error">{rowData.message}</Descriptions.Item>
          </>
        )}
      </Descriptions>
    </Drawer>
  );
};

export default DetailDrawer;
