import { createSlice } from "@reduxjs/toolkit";

export const packageSummary = createSlice({
  name: "packageSummary",
  initialState: {
    value: false,
  },
  reducers: {
    setPackageSummary: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setPackageSummary } = packageSummary.actions;
export default packageSummary.reducer;
