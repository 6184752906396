import { InfoCircleOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Form, Input, Row } from "antd";
import { createPackage, editPackage } from "api";
import DashboardPageHeader from "components/dashboard-header/DashboardPageHeader";
import { ICCloseOutlined, ICExportOutlined, ICSaveOutlined } from "components/list/ListIcon";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { numberRules, requiredRules } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import "./kelolapaket.less";

const { TextArea } = Input;

const actionList = [
  { action: "create", actionTrans: "Tambah Paket" },
  { action: "edit", actionTrans: "Edit Paket" },
  { action: "view", actionTrans: "Paket" },
];

const PackageActions = () => {
  const { state } = useLocation();
  const actionTitle = actionList.find((act) => act.action === state?.action)?.actionTrans;
  useDocumentTitle(state?.action === "view" ? `${actionTitle} ${state?.headerTitle}` : actionTitle);
  const navigate = useNavigate();
  const back = () => navigate(-1);

  if (state) {
    const { data, action, cardTitle, headerTitle, disable = false, uuid } = state;
    const onFinish = async (values) => {
      const value = { ...values, uuid };
      if (action === "edit") {
        const { code } = await editPackage(value);
        if (code === 200) {
          navigate("/admin/package");
        }
      } else if (action === "create") {
        const { code } = await createPackage(values);
        if (code === 200) navigate("/admin/package");
      }
    };

    const extraBtn = action === "view" && (
      <Button
        type="primary"
        icon={<ICExportOutlined />}
        onClick={() =>
          navigate("/admin/package/edit", {
            state: {
              data,
              action: "edit",
              headerTitle: "Edit Paket",
              cardTitle: `Edit Paket ${process.env.REACT_APP_NAME}`,
              uuid,
            },
          })
        }>
        Edit Paket
      </Button>
    );

    const footerBtn = action !== "view" && (
      <div className="w-full flex justify-end gap-x-2">
        <Form.Item>
          <Button icon={<ICCloseOutlined />} danger onClick={back}>
            Batalkan
          </Button>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={<ICSaveOutlined />}>
            Simpan Paket
          </Button>
        </Form.Item>
      </div>
    );

    return (
      <div className="kelola-paket">
        <DashboardPageHeader title={headerTitle} />
        <div className="py-4 px-6">
          <Card title={cardTitle} extra={extraBtn}>
            <Form
              layout="vertical"
              onFinish={onFinish}
              className="form-package"
              {...(data && {
                initialValues: {
                  packageName: data.packagesName,
                  packageDescription: data.packagesDescription,
                  packagePrice: data.packagesPrice,
                  zohoItemId: data.zohoItemId,
                  maxEmailSent: data.limitEmail,
                  maxCountEmailPerHour: data.limitEmailHourly,
                  maxDomain: data.limitDomain,
                  maxAttachment: data.limitSizeAttachment,
                  supportService: data.supportService,
                  dedicatedIP: data.dedicatedIP,
                  packageShow: data.packageShow,
                },
              })}>
              <Row gutter={[60, 0]}>
                <Col xl={8}>
                  <Form.Item
                    name="packageName"
                    label="Nama Paket"
                    className="right-required"
                    rules={[requiredRules]}>
                    <Input placeholder="Nama paket layanan" disabled={disable} />
                  </Form.Item>
                  <Form.Item
                    name="packageDescription"
                    label="Deskripsi Paket"
                    className="right-required"
                    rules={[requiredRules]}>
                    <TextArea placeholder="Deskripsi singkat paket layanan" disabled={disable} />
                  </Form.Item>
                  <Form.Item
                    name="packagePrice"
                    label="Biaya Paket"
                    className="right-required"
                    rules={[requiredRules, numberRules]}>
                    <Input
                      addonBefore="Rp"
                      placeholder="Masukkan nominal biaya"
                      disabled={disable}
                    />
                  </Form.Item>
                  <Form.Item
                    name="zohoItemId"
                    tooltip={{
                      title: "Item ID bisa didapat di Zoho Books - Items",
                      icon: <InfoCircleOutlined />,
                      placement: "right",
                      trigger: ["click"],
                      overlayClassName: "zohoLabel__tooltip",
                    }}
                    label="Zoho Item ID"
                    className="right-required zoho-id"
                    rules={[requiredRules, numberRules]}>
                    <Input placeholder="Masukkan item id" disabled={disable} />
                  </Form.Item>
                </Col>
                <Col xl={8}>
                  <Form.Item
                    name="maxEmailSent"
                    label="Maksimal Kiriman Email"
                    className="right-required"
                    rules={[requiredRules, numberRules, { max: 7 }]}>
                    <Input placeholder="Max kiriman email" addonAfter="email" disabled={disable} />
                  </Form.Item>
                  <Form.Item
                    name="maxCountEmailPerHour"
                    label="Maksimal Kiriman Email per Jam"
                    className="right-required"
                    rules={[requiredRules, numberRules, { max: 6 }]}>
                    <Input
                      placeholder="Max kiriman email/jam"
                      addonAfter="email/jam"
                      disabled={disable}
                    />
                  </Form.Item>
                  <Form.Item
                    name="maxDomain"
                    label="Maksimal Domain"
                    className="right-required"
                    rules={[requiredRules, numberRules, { max: 2 }]}>
                    <Input placeholder="Max domain" addonAfter="domain" disabled={disable} />
                  </Form.Item>
                  <Form.Item
                    name="maxAttachment"
                    label="Maksimal Attachment"
                    className="right-required"
                    rules={[requiredRules, numberRules, { max: 2 }]}>
                    <Input placeholder="Max attachment" addonAfter="MB" disabled={disable} />
                  </Form.Item>
                  <Form.Item label="Additional Features" className="mb-9">
                    <Form.Item
                      name="packageShow"
                      initialValue={false}
                      valuePropName="checked"
                      className="mb-0"
                      noStyle>
                      <Checkbox className="mb-1" disabled={disable}>
                        Tampilkan Paket di Halaman Checkout
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="supportService"
                      initialValue={false}
                      valuePropName="checked"
                      className="mb-0"
                      noStyle>
                      <Checkbox className="mb-1" disabled={disable}>
                        Support via Phone/WhatsApp
                      </Checkbox>
                    </Form.Item>
                    <Form.Item
                      name="dedicatedIP"
                      initialValue={false}
                      valuePropName="checked"
                      className="mb-0"
                      noStyle>
                      <Checkbox className="mb-1" disabled={disable}>
                        Dedicated IP Address
                      </Checkbox>
                    </Form.Item>
                  </Form.Item>
                </Col>
              </Row>
              {footerBtn}
            </Form>
          </Card>
        </div>
      </div>
    );
  }
  return <Navigate to="/admin/package" />;
};

export default PackageActions;
