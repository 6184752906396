import { Button, Col, Divider } from "antd";
import billing from "assets/images/billing.svg";
import emptyInbox from "assets/images/empty_inbox.svg";
import notSubcribes from "assets/images/api_not_found.svg";
import notAvail from "assets/images/api_key_not_avail.svg";
import lineEmpty from "assets/images/line_empty.svg";
import magnifierEmpty from "assets/images/magnifier.svg";
import failed_load from "assets/images/failed_load.svg";
import { ICInfoCircleOutlined } from "components/list/ListIcon";
import { Link } from "react-router-dom";

const BtnSubscribe = () => {
  return (
    <Link to={"/checkout"}>
      {" "}
      <Button className="mt-3" type="primary">
        Langganan Sekarang
      </Button>
    </Link>
  );
};

export const EmptyWrapper = ({
  image,
  message,
  className = "",
  imgMargin = "mb-1",
  title,
  msgClassName,
  type = "magnifier",
}) => {
  const typeImg = type === "line" ? lineEmpty : magnifierEmpty;
  const imageSrc = image || typeImg;
  const titleEmpty = title || "Data belum tersedia";

  return (
    <div className={`flex ${className}`}>
      <div className="flex flex-col items-center text-center m-auto w-full empty-wrapper">
        <img src={imageSrc} alt="Empty Chart" className={imgMargin} />
        <p className="mb-1 text-base font-medium">{titleEmpty}</p>
        <p className={`mb-0 text-secondary font-normal ${msgClassName}`}>{message}</p>
      </div>
    </div>
  );
};

export const EmptyWrappers = ({
  image,
  message,
  className = "",
  imgMargin = "mb-1",
  title,
  msgClassName,
  type = "magnifier",
}) => {
  const typeImg = type === "line" ? lineEmpty : magnifierEmpty;
  const imageSrc = image || typeImg;
  const titleEmpty = title || "Data belum tersedia";

  return (
    <div className={`flex ${className}`}>
      <div className="flex flex-col items-center text-center m-auto w-full">
        <img src={imageSrc} alt="Empty Chart" className={imgMargin} />
        <p className="mb-1 text-base font-medium">{titleEmpty}</p>
        <p className={`mb-0 text-secondary font-normal ${msgClassName}`}>{message}</p>
      </div>
    </div>
  );
};

export const EmptyChartTopSender = () => {
  return (
    <div className="chart-text-wrapper flex-col">
      <ICInfoCircleOutlined className="text-secondary" />
      <p className="text-secondary font-normal mb-0">Data belum tersedia</p>
      {/* <p className="text-secondary text-base mb-0 py-7">Data belum tersedia</p> */}
    </div>
  );
};

export const EmptyStateBillingData = ({ title, message }) => {
  return (
    <div className="flex flex-col items-center mt-6 error-wrapper">
      <img src={billing} alt="Faktur not available" className="mb-4" />
      <h5 className="text-base font-medium mb-1">{title}</h5>
      <p className="text-secondary text-center mb-0">{message}</p>
    </div>
  );
};

export const EmptyStateCustomerReport = ({ title }) => {
  return (
    <Col lg={12} md={12} sm={24} xl={6}>
      <div className="flex flex-col px-6 pt-4 pb-1">
        <p className="mb-2 font-medium">{title}</p>
        <div className="chart-text-wrapper">
          <p className="text-secondary text-base font-normal py-7 text-center m-0">
            Data belum tersedia
          </p>
        </div>
        <Divider className="my-2" />
      </div>
    </Col>
  );
};

export const EmptyNotification = () => {
  return (
    <div className="flex flex-col items-center w-full mt-4 mb-5">
      <img src={emptyInbox} alt="There's no notification" className="mb-1" />
      <p className="mb-1 text-primary font-sm font-medium">Tidak ada pemberitahuan</p>
      <p className="mb-0 text-secondary">Belum ada pemberitahuan terbaru pada akun anda.</p>
    </div>
  );
};

export const AcoountApiInactive = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <img src={notSubcribes} alt="Account Inactive" className="mb-2" />
      <p className="mt-1 mb-0 text-primary font-sm font-medium">Anda Belum Berlangganan</p>
      <p className="mb-0 text-secondary text-center">
        Silakan dapat berlangganan terlebih dahulu agar dapat menggunakan API untuk pengiriman email
        anda.
      </p>
      <BtnSubscribe />
    </div>
  );
};

export const ApiKeyNotAvail = () => {
  return (
    <div className="flex flex-col items-center w-full">
      <img src={notAvail} alt="Account Inactive" className="mb-2" />
      <p className="mt-1 mb-0 text-primary font-sm font-medium">API Key Belum Tersedia</p>
      <p className="mb-0 text-secondary text-center">
        Silakan dapat melakukan generate API Key untuk dapat menggunakan API untuk pengiriman email
        anda.
      </p>
    </div>
  );
};

export const EmptySuppress = () => {
  return (
    <div className="flex flex-col items-center w-full" style={{ paddingTop: "7px" }}>
      <img
        src={magnifierEmpty}
        alt="Suppress Empty"
        className="mb-1"
        style={{ width: "310px", height: "240px" }}
      />
      <p className="mt-3 mb-1 text-primary font-sm font-medium" style={{ fontSize: "16px" }}>
        Data belum tersedia
      </p>
      <p className="mb-0 text-secondary text-center">
        Data suppression akan tersedia setelah terdapat kiriman email <br /> gagal atau anda
        menambahkan secara manual.
      </p>
    </div>
  );
};

export const SearchNotFound = () => {
  return (
    <div className="flex flex-col items-center w-full" style={{ paddingTop: "7px" }}>
      <img
        src={magnifierEmpty}
        alt="Suppress Empty"
        className="mb-1"
        style={{ width: "310px", height: "240px" }}
      />
      <p className="mt-3 mb-1 text-primary font-sm font-medium" style={{ fontSize: "16px" }}>
        Data tidak ditemukan
      </p>
      <p className="mb-0 text-secondary text-center">
        Email suppression yang anda cari tidak ditemukan, pastikan
        <br /> tidak ada salah ketik pada penulisan email.
      </p>
    </div>
  );
};

export const LoadFailed = () => {
  return (
    <div className="flex flex-col items-center w-full" style={{ paddingTop: "7px" }}>
      <img src={failed_load} alt="" className="mb-1" style={{ width: "393px", height: "289px" }} />
      <p className="mt-3 mb-1 text-primary font-sm font-medium" style={{ fontSize: "16px" }}>
        Gagal memuat data
      </p>
      <p className="mb-0 text-secondary text-center">
        Sistem gagal memuat data layanan. Harap dapat muat ulang <br />
        atau refresh browser yang anda gunakan.
      </p>
    </div>
  );
};
