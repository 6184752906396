import { Button } from "antd";
import resultImage from "assets/images/call_to_setup.svg";
import HeaderSection from "components/header/HeaderSection";
import React from "react";
import { useNavigate } from "react-router-dom";

const CallToSetupCompany = () => {
  const navigate = useNavigate();
  const navCompany = () => navigate("/register/company-data");

  return (
    <>
      <HeaderSection />
      <div className="center">
        <div className="flex flex-col justify-center items-center process-domain__wrapper gap-y-4">
          <img src={resultImage} alt="" />
          <div className="flex text-center flex-col gap-y-1">
            <h2 className="text-3xl mb-1">Aktivasi Trial Sukses</h2>
            <p className="text-secondary mb-0">
              Akun trial anda saat ini sudah berhasil diaktifkan.
            </p>
            <p className="text-secondary mb-0">
              Selanjutnya silakan lengkapi data perusahaan anda untuk dapat menggunakan trial
              layanan kami
            </p>
          </div>
          <Button type="primary" className="mt-4 form-login" onClick={navCompany}>
            Lengkapi Data Perusahaan
          </Button>
        </div>
      </div>
    </>
  );
};

export default CallToSetupCompany;
