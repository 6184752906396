import { Button, Card, Collapse, Form, Input, Select } from "antd";
import { getDomain, getTableLog } from "api";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import DatePicker from "components/datepicker/DatePicker";
import { EmptyWrapper, LoadFailed } from "components/empty/EmptyComponent";
import { ICCloseOutlined, ICSearchOutlined, ICUpOutlined } from "components/list/ListIcon";
import Spinner from "components/Spinner/Spinner";
import TableLog from "components/table/TableLog";
import TableTitle from "components/tableSendiri/TableTitle";
import { endOfDay, format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setListDomain } from "redux/slices/listDomain";
import { setLogEmail } from "redux/slices/logEmail";
import useDocumentTitle from "utils/useDocumentTitle";
import "./log.less";

const { Option } = Select;
const { Panel } = Collapse;

const ExtraHeader = ({ setFilter, filter }) => {
  const dispatch = useDispatch();
  const listDomain = useSelector(({ listDomain }) => listDomain.value);

  const getDataDomain = useCallback(async () => {
    const { data } = await getDomain();
    dispatch(setListDomain(data));
  }, [dispatch]);

  useEffect(() => {
    if (!listDomain) getDataDomain();
  }, [getDataDomain, listDomain]);

  const handleChangeDomain = async (domain) => {
    setFilter({ ...filter, domain, page: 1 });
  };

  const handleChangeStatus = async (status) => {
    setFilter({ ...filter, status, page: 1 });
  };

  return (
    <div className="flex justify-between items-center w-full">
      <span className="title-log-email">Filter</span>
      <div className="flex gap-x-6 items-center">
        <div className="flex gap-x-1 items-center">
          <span className="hidden-xs">Domain:</span>
          <Select
            name="domain"
            defaultValue="Semua Domain"
            onChange={handleChangeDomain}
            className="select-domain">
            <Option value="">Semua Domain</Option>
            {listDomain &&
              listDomain.map((domain) => (
                <Option value={domain.domainName} key={domain.domainUuid}>
                  {domain.domainName}
                </Option>
              ))}
          </Select>
        </div>
        <div className="flex gap-x-1 items-center">
          <span className="hidden-xs">Status:</span>
          <Select
            defaultValue="Semua Status"
            onChange={handleChangeStatus}
            className="select-status">
            <Option value="">Semua Status</Option>
            <Option value="sent">Delivered</Option>
            <Option value="bounced">Failed</Option>
          </Select>
        </div>
      </div>
    </div>
  );
};

const HeaderPanel = () => {
  const [click, setClick] = useState(false);

  return (
    <div className="flex items-center w-full">
      <span className="text-success" onClick={() => setClick((prev) => !prev)}>
        Advanced Filter
      </span>
      <ICUpOutlined className="ml-1 text-success" rotate={click ? 0 : 180} />
    </div>
  );
};

const LogEmail = () => {
  let dataCSV = [];
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState(false);
  const [filter, setFilter] = useState({
    domain: "",
    status: "",
    sender: "",
    recipient: "",
    page: 1,
    limit: "10",
  });

  useDocumentTitle("Log Email");
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const logEmail = useSelector(({ logEmail }) => logEmail.value);

  const resetfilter = () => {
    form.resetFields();
    let newFilter = { ...filter };
    delete newFilter.datetime;
    delete newFilter.to_datetime;
    let filtered = {
      ...newFilter,
      page: 1,
      sender: "",
      recipient: "",
    };
    setFilter(filtered);
  };

  const hitApi = useCallback(async () => {
    setLoading(true);
    const { data, msg, code } = await getTableLog(filter);
    dispatch(setLogEmail({ tableLogUser: data, msg, code }));
    setLoading(false);
  }, [filter, dispatch]);

  useEffect(() => {
    if (filter) hitApi();
  }, [hitApi, filter]);

  const onChangeDatetime = (values) => {
    if (values && values.length > 0) {
      const startDate = format(new Date(values[0]), "yyyy-MM-dd");
      const endDate = format(new Date(values[1]), "yyyy-MM-dd");
      setStartDate(`${startDate} 00:00:00`);
      setEndDate(`${endDate} 23:59:59`);
    } else {
      setStartDate(false);
      setEndDate(false);
    }
  };

  const handleAdvanceFilter = async (values) => {
    const { sender, recipient } = values;
    delete filter.datetime;
    delete filter.to_datetime;
    let filtered = { ...filter, page: 1, sender, recipient };
    if (startDate && endDate)
      filtered = {
        ...filter,
        page: 1,
        sender,
        recipient,
        datetime: startDate,
        to_datetime: endDate,
      };
    setFilter(filtered);
  };

  const titleMessage =
    logEmail.msg === "Get Log Email Success" ? "Data tidak ditemukan" : "Data belum tersedia";
  const message =
    logEmail.msg === "Get Log Email Success"
      ? "Email log yang anda cari tidak ditemukan, pastikan tidak ada salah ketik pada penulisan anda"
      : `Email log akan muncul setelah client melakukan pengiriman email menggunakan akun ${process.env.REACT_APP_NAME}.`;

  const disabledDate = (current) => {
    const lastMonth = new Date();
    lastMonth.setDate(lastMonth.getDate() - 31);
    return current && (current < lastMonth || current > endOfDay(new Date()));
  };

  return (
    <div className="log-email-user">
      <DashboardHeader title="Log Email Terkirim" />
      <div className="log-email-user px-6 py-4">
        <Collapse defaultActiveKey={["0"]} ghost className="mb-6 log-collapse">
          <Panel
            header={<HeaderPanel />}
            key="1"
            extra={<ExtraHeader filter={filter} setFilter={setFilter} rotate={false} />}
            showArrow={false}
            collapsible="header"
            className="log-email-header">
            <Form
              layout="inline"
              className="formFilter"
              form={form}
              onFinish={handleAdvanceFilter}
              initialValues={{
                sender: "",
                recipient: "",
                datetime: "",
              }}>
              <div>
                <Form.Item label="Sender" name="sender">
                  <Input type="text" placeholder="sender@domain.tld" />
                </Form.Item>
                <Form.Item label="Recipient" name="recipient">
                  <Input type="text" placeholder="recipient@domain.tld" />
                </Form.Item>
                <Form.Item name="datetime">
                  <DatePicker.RangePicker
                    className="w-full"
                    disabledDate={disabledDate}
                    onChange={onChangeDatetime}
                    format="yyyy-MM-dd"
                  />
                </Form.Item>
              </div>
              <div className="button-advance-filter">
                <Button onClick={resetfilter} icon={<ICCloseOutlined />}>
                  Reset
                </Button>
                <Button type="primary" icon={<ICSearchOutlined />} htmlType="submit">
                  Cari
                </Button>
              </div>
            </Form>
          </Panel>
        </Collapse>
        {logEmail.tableLogUser?.count > 0 ? (
          <TableLog
            filter={filter}
            setFilter={setFilter}
            loading={loading}
            hitApi={hitApi}
            role="user"
          />
        ) : logEmail ? (
          <div className="site-card-border-less-wrapper">
            <Card
              loading={loading}
              bordered={false}
              title={
                <TableTitle
                  title="Email Log"
                  desc={`Log email penggunaan ${process.env.REACT_APP_NAME}. Klik untuk melihat detail`}
                  buttonRefresh={{
                    onClick: () => {
                      hitApi();
                    },
                  }}
                  loading={loading}
                  buttonExport={{ disable: true, dataCSV: dataCSV, responsive: "lg" }}
                  pageLimit={{
                    currentLimit: filter.limit,
                    disable: true,
                    onSelectLimit: () => {},
                  }}
                />
              }>
              {logEmail.code === 500 ? (
                <LoadFailed />
              ) : (
                <EmptyWrapper imgMargin="mb-4" title={titleMessage} message={message} />
              )}
            </Card>
          </div>
        ) : (
          <Spinner loading={loading} full />
        )}
      </div>
    </div>
  );
};

export default LogEmail;
