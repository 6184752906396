import { ExportOutlined } from "@ant-design/icons";
import { Button, Input, Select } from "antd";
import DButton from "components/button/DButton";
import { ICReloadOutlined, ICUpOutlined } from "components/list/ListIcon";
import React, { useState } from "react";
import { CSVLink } from "react-csv";

const hasKeys = (params) => params && Object.keys(params).length > 0;

const ButtonExport = ({ disable, dataCSV, name = "", headerCSV, fileName = "", responsive }) => {
  if (!dataCSV) return "";
  return (
    <CSVLink
      data={dataCSV}
      headers={headerCSV}
      filename={fileName ? fileName : `log-email-${name?.replaceAll(" ", "-")}.csv`}>
      <DButton
        icon={<ExportOutlined />}
        className="primary"
        disabled={disable}
        responsive={responsive}>
        Export To CSV
      </DButton>
    </CSVLink>
  );
};

const PageLimit = ({ currentLimit, onSelectLimit, disable }) => {
  return (
    <Select
      className="pageLimit__selector"
      defaultValue={`${currentLimit || 10}`}
      onSelect={onSelectLimit}
      disabled={disable}>
      <Select.Option value="10">10 per page</Select.Option>
      <Select.Option value="25">25 per page</Select.Option>
      <Select.Option value="50">50 per page</Select.Option>
      <Select.Option value="100">100 per page</Select.Option>
    </Select>
  );
};

const Refresh = ({ disable, onClick, loading = false }) => {
  return (
    <Button icon={<ICReloadOutlined spin={loading} />} disabled={disable} onClick={onClick}>
      Refresh Log
    </Button>
  );
};

const SearchCompany = ({ disabled, isAdmin, onSearch }) => {
  if (!isAdmin) return "";
  return (
    <Input.Search
      placeholder="Cari nama perusahaan..."
      className="input-company"
      enterButton
      disabled={disabled}
      onSearch={onSearch}
    />
  );
};

const TableTitleWithCollapse = ({
  title = "",
  className,
  pageLimit = { currentLimit: 0, onSelectLimit: 0, disable: 0 },
  buttonExport = { disable: 0, onExport: 0 },
  buttonRefresh,
  desc,
  addsAfter,
  addsBefore,
  loading,
  searchCompany,
  addsAccordion,
  responsiveWidth,
  breakpoint_third,
}) => {
  // console.log(responsiveWidth, breakpoint_third);
  const [click, setClick] = useState(false);

  const hasButtonExport = hasKeys(buttonExport);
  const hasPagelimit = hasKeys(pageLimit);
  const hasRefresh = hasKeys(buttonRefresh);
  const hasSearchCompany = hasKeys(searchCompany);

  return (
    <div className={`tableTitleAccordion__wrapper ${click ? "tableTitleAccordion__open" : ""}`}>
      <div className={`flex items-center justify-between ${className}`}>
        <div className="title-text">
          <h5 className="text-base font-medium mb-0">{title}</h5>
          <p className="text-secondary mb-0">{desc}</p>
        </div>
        <div className="btn-group-log flex gap-x-2 gap-y-2" style={{ marginBottom: 8 }}>
          {responsiveWidth > breakpoint_third && (
            <div
              className="flex items-center text-success filter-text pointer"
              onClick={() => setClick((prev) => !prev)}>
              <span>Filter</span>
              <ICUpOutlined className="ml-1" rotate={click ? 0 : 180} />
            </div>
          )}
          {addsBefore}
          {hasSearchCompany && <SearchCompany {...searchCompany} />}
          <div className="flex gap-x-2">
            {hasPagelimit && <PageLimit {...pageLimit} />}
            <div className="flex gap-x-1">
              {hasRefresh && <Refresh {...buttonRefresh} loading={loading} />}
              {hasButtonExport && <ButtonExport {...buttonExport} />}
              {addsAfter}
            </div>
          </div>
        </div>
      </div>
      {responsiveWidth <= breakpoint_third && (
        <div
          className="flex items-center justify-end text-success filter-text pointer"
          onClick={() => setClick((prev) => !prev)}
          style={{ marginTop: 8 }}>
          <span>Filter</span>
          <ICUpOutlined className="ml-1" rotate={click ? 0 : 180} />
        </div>
      )}
      <div
        className="tableTitleAccordion__content"
        {...(responsiveWidth <= breakpoint_third && { style: { marginTop: 24 } })}>
        {addsAccordion}
      </div>
    </div>
  );
};

export default TableTitleWithCollapse;
