import { configureStore } from "@reduxjs/toolkit";
import accountSMTP from "./slices/accountSMTP";
import billingDataFakturTable from "./slices/admin/billingDataFakturTable";
import billingDataInvoiceSummary from "./slices/admin/billingDataInvoiceSummary";
import billingDataInvoiceTable from "./slices/admin/billingDataInvoiceTable";
import billingDataConfirmPayment from "./slices/admin/billingDataConfirmPayment";
import detailCompany from "./slices/admin/detailCompany";
import EmailAPI from "./slices/EmailAPI";
import fakturCustomer from "./slices/admin/fakturCustomer";
import invoiceCustomer from "./slices/admin/invoiceCustomer";
import listActivityAdmin from "./slices/admin/listActivityAdmin";
import listAdmin from "./slices/admin/listAdmin";
import listAdminShort from "./slices/admin/listAdminShort";
import listAllClients from "./slices/admin/listAllClients";
import listAllCompany from "./slices/admin/listAllCompany";
import listAllContact from "./slices/admin/listAllContact";
import listAllCustomer from "./slices/admin/listAllCustomer";
import listDomainAdmin from "./slices/admin/listDomainAdmin";
import listNotificationAdmin from "./slices/admin/listNotificationAdmin";
import logActivityAdmin from "./slices/admin/logActivityAdmin";
import logEmailAdmin from "./slices/admin/logEmailAdmin";
import packageList from "./slices/admin/packageList";
import packageListFilter from "./slices/admin/packageListFilter";
import packageSummary from "./slices/admin/packageSummary";
import packageUsed from "./slices/admin/packageUsed";
import reportBillingCustomerDetails from "./slices/admin/reportBillingCustomerDetails";
import reportUsage from "./slices/admin/reportUsage";
import reportUsageChart from "./slices/admin/reportUsageChart";
import summaryPelanggan from "./slices/admin/summaryPelanggan";
import checkoutChecker from "./slices/checkoutChecker";
import companyData from "./slices/companyData";
import companyPackage from "./slices/companyPackage";
import currentPayment from "./slices/currentPayment";
import detailsSubscription from "./slices/detailsSubscription";
import domainActive from "./slices/domainActive";
import emailSummary from "./slices/emailSummary";
import fakturPajak from "./slices/fakturPajak";
import invoice from "./slices/invoice";
import listDomain from "./slices/listDomain";
import logEmail from "./slices/logEmail";
import mailDeliveryChart from "./slices/mailDeliveryChart";
import notificationList from "./slices/notificationList";
import packetList from "./slices/packet";
import pageStatus from "./slices/pageStatus";
import userProfile from "./slices/profile";
import reportBilling from "./slices/reportBilling";
import reportEmail from "./slices/reportEmail";
import reportEmailChart from "./slices/reportEmailChart";
import servicePackage from "./slices/servicePackage";
import setupLayanan from "./slices/setupLayanan";
import userData from "./slices/user";
import userPackage from "./slices/userPackage";
import SuppressionList from "./slices/SuppressionList";
import HistoryList from "./slices/HistoryList";
import SuppressionListAdmin from "./slices/admin/SuppressionList";
import HistoryListAdmin from "./slices/admin/HistoryList";
import SummarySuppress from "./slices/admin/SummarySuppress";
import infoOverQuota from "./slices/infoOverQuota";

export default configureStore({
  reducer: {
    EmailAPI,
    listAllContact,
    detailCompany,
    summaryPelanggan,
    userData,
    pageStatus,
    notificationList,
    reportEmailChart,
    listAllCompany,
    reportEmail,
    companyData,
    setupLayanan,
    userProfile,
    logEmail,
    fakturPajak,
    emailSummary,
    reportBilling,
    accountSMTP,
    packetList,
    invoice,
    servicePackage,
    currentPayment,
    listDomain,
    logEmailAdmin,
    listDomainAdmin,
    domainActive,
    listAllClients,
    reportUsage,
    reportUsageChart,
    fakturCustomer,
    invoiceCustomer,
    billingDataInvoiceSummary,
    reportBillingCustomerDetails,
    billingDataInvoiceTable,
    billingDataFakturTable,
    billingDataConfirmPayment,
    packageList,
    packageSummary,
    packageUsed,
    listAllCustomer,
    checkoutChecker,
    listAdmin,
    logActivityAdmin,
    packageListFilter,
    detailsSubscription,
    userPackage,
    listAdminShort,
    listActivityAdmin,
    listNotificationAdmin,
    mailDeliveryChart,
    companyPackage,
    SuppressionList,
    HistoryList,
    SuppressionListAdmin,
    HistoryListAdmin,
    SummarySuppress,
    infoOverQuota,
  },
});
