import ErrorApiImage from "assets/images/api_error.svg";
import React from "react";

const ApiError = ({ title, body }) => {
  const titleRender = title || "Gagal memuat data";
  const bodyRender =
    body ||
    "Sistem gagal memuat data layanan. Harap dapat muat ulang atau refresh browser yang anda gunakan.";

  return (
    <div className="flex flex-col items-center error-wrapper text-center mt-6">
      <img src={ErrorApiImage} alt="There's no notification" className="mb-4" />
      <h5 className="text-base font-medium mb-1">{titleRender}</h5>
      <p className="mb-0 text-secondary">{bodyRender}</p>
    </div>
  );
};

export default ApiError;
