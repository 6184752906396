import { Button, Popover, Modal } from "antd";
import { useState } from "react";
import { ICQuestionCircleOutlined, ICPlayCircleTwoTone } from "components/list/ListIcon";
import Thumbnail from "assets/images/regist_guide_big.png";

const Support = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
    setIsPopoverVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setIsPopoverVisible(true);
  };

  const youtubeVideoLink = "https://www.youtube.com/embed/EZco0raL6GI";

  const videoModal = (
    <Modal
      title="Video Panduan Registrasi"
      open={isModalVisible}
      onCancel={handleCancel}
      footer={null}
      width={920}
      destroyOnClose>
      <div style={{ position: "relative", paddingBottom: "56.25%" }}>
        <iframe
          width="100%"
          src={youtubeVideoLink}
          title="YouTube Video"
          allowFullScreen
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}></iframe>
      </div>
    </Modal>
  );

  const ContentGuideSupport = (
    <div style={{ width: 400 }}>
      <h3 style={{ fontWeight: 700, fontSize: 14, marginBottom: 0 }}>Video: Panduan Registrasi</h3>
      <p className="content-support" style={{ marginBottom: 0 }}>
        Pelajari tahapan registrasi layanan Aktiva Transaksional Email, melalui video panduan kami
      </p>
      <div className="thumbnail-container">
        <img
          src={Thumbnail}
          alt="Video Thumbnail"
          onClick={showModal}
          style={{ cursor: "pointer" }}
        />
        <div className="red-icon-overlay" onClick={showModal}>
          <ICPlayCircleTwoTone twoToneColor="#F5222D" />
        </div>
      </div>
      <p className="content-support" style={{ fontSize: 12 }}>
        Memiliki kendala dan pertanyaan? Silakan hubungi kami melalui{" "}
        <a href="mailto:support@aktiva.co.id" style={{ color: "#0D9F3F" }}>
          Email
        </a>
        ,{" "}
        <a href="https://support.aktiva.co.id/" style={{ color: "#0D9F3F" }} target="blank">
          Support Ticket
        </a>{" "}
        atau{" "}
        <a
          href="https://api.whatsapp.com/send/?phone=6281318349824&text=Halo+Aktiva,%0D%0A%0D%0ASaya+sedang+melakukan+registrasi+layanan+Aktiva+Transaksional+Email,+dan+memiliki+pertanyaan+untuk+anda&type=phone_number&app_absent=0"
          style={{ color: "#0D9F3F" }}
          target="blank">
          WhatsApp
        </a>
      </p>
      {videoModal}
    </div>
  );
  return (
    <Popover
      placement="topRight"
      title={<div style={{ padding: "12px 0" }}>Panduan & Support</div>}
      content={ContentGuideSupport}
      trigger="click"
      open={isPopoverVisible}
      onOpenChange={(visible) => setIsPopoverVisible(visible)}>
      <Button
        className="btn-support"
        style={{ position: "fixed", bottom: 40, right: 40, height: 45 }}>
        <ICQuestionCircleOutlined /> Panduan & Support
      </Button>
    </Popover>
  );
};

export default Support;
