import { Menu, Tag } from "antd";
import { authLogout } from "api";
import {
  ICAppstoreOutlined,
  ICBellOutlined,
  ICBookOutlined,
  ICCreditCardOutlined,
  ICDollarCircleOutlined,
  ICLineChartOutlined,
  ICLogoutOutlined,
  ICRetweetOutlined,
  ICRobotOutlined,
  ICSettingOutlined,
  ICTagOutlined,
  ICTeamOutlined,
  ICUserOutlined,
  ICApiOutlined,
  ICInboxOutlined,
} from "components/list/ListIcon";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { setLogout } from "utils/helpers";
import "./sidebar.less";

const LabelSidebar = ({ path, label, tag }) => {
  return (
    <NavLink to={path}>
      <span className="nav-text">{label}</span>
      {tag === "baru" && (
        <Tag className="tagSoon" color="error">
          Baru!
        </Tag>
      )}
    </NavLink>
  );
};

const userRoles = {
  primary: "primary",
  techbill: "techbill",
  billing: "billing",
  technical: "technical",
};

const adminRoles = {
  superadmin: "superadmin",
  admin: "admin",
  billing: "accounting",
};

const Sidebar = () => {
  const { pathname: path } = useLocation();
  const user = useSelector(({ userData }) => userData.value);
  const isAdmin = user.isAdmin;
  const [selectedMenu, setSelectedMenu] = useState("");

  const selectMenu = ({ key }) => {
    setSelectedMenu(key);
  };

  useEffect(() => {
    if (isAdmin) {
      const adminPath = path.split("/");
      selectMenu({ key: adminPath[2] });
    } else {
      const userPath = path.replace("/", "");
      selectMenu({ key: userPath });
    }
  }, [path, isAdmin]);

  const handleLogout = async () => {
    await authLogout();
    setTimeout(() => {
      setLogout(isAdmin ? "admin" : "user");
    }, 500);
  };

  const itemsAdmin = [
    {
      key: "main",
      label: <LabelSidebar path="/admin/main" label="Dashboard" />,
      icon: <ICAppstoreOutlined />,
      role: [adminRoles.superadmin, adminRoles.admin, adminRoles.billing],
    },
    {
      key: "log",
      label: <LabelSidebar path="/admin/log" label="Log Email" />,
      icon: <ICRetweetOutlined />,
      role: [adminRoles.superadmin, adminRoles.admin],
    },
    {
      key: "suppression",
      label: <LabelSidebar path="/admin/suppression" label="Suppression" />,
      icon: <ICInboxOutlined />,
      role: [adminRoles.superadmin, adminRoles.admin],
    },
    {
      key: "report",
      label: <LabelSidebar path="/admin/report" label="Report Penggunaan" />,
      icon: <ICLineChartOutlined />,
      role: [adminRoles.superadmin, adminRoles.admin],
    },
    {
      key: "report-billing",
      label: <LabelSidebar path="/admin/report-billing" label="Report Tagihan" />,
      icon: <ICDollarCircleOutlined />,
      role: [adminRoles.superadmin, adminRoles.billing],
    },
    {
      key: "pelanggan",
      label: <LabelSidebar path="/admin/pelanggan" label="Daftar Pelanggan" />,
      icon: <ICTeamOutlined />,
      role: [adminRoles.superadmin, adminRoles.admin],
    },
    {
      key: "billing",
      label: <LabelSidebar path="/admin/billing" label="Data Tagihan" />,
      icon: <ICCreditCardOutlined />,
      role: [adminRoles.superadmin, adminRoles.billing],
    },
    {
      key: "package",
      label: <LabelSidebar path="/admin/package" label="Kelola Paket" />,
      icon: <ICTagOutlined />,
      role: [adminRoles.superadmin, adminRoles.admin, adminRoles.billing],
    },
    {
      key: "admin",
      label: <LabelSidebar path="/admin/admin" label="Kelola Admin" />,
      icon: <ICRobotOutlined />,
      role: [adminRoles.superadmin, adminRoles.admin, adminRoles.billing],
    },
    {
      key: "manage-notification",
      label: <LabelSidebar path="/admin/manage-notification" label="Kelola Notifikasi" />,
      icon: <ICBellOutlined />,
      role: [adminRoles.superadmin, adminRoles.admin],
    },
    {
      key: "dokumentasi",
      label: <LabelSidebar path="/admin/dokumentasi" label="Dokumentasi" tag="soon" />,
      icon: <ICBookOutlined />,
      role: [adminRoles.superadmin, adminRoles.admin],
    },
    {
      key: "profile",
      label: <LabelSidebar path="/admin/profile" label="Profile" />,
      icon: <ICUserOutlined />,
      role: [adminRoles.superadmin, adminRoles.admin, adminRoles.billing],
    },
  ];

  const itemsUser = [
    {
      key: "main",
      label: <LabelSidebar path="/main" label="Dashboard" />,
      icon: <ICAppstoreOutlined />,
      role: [userRoles.primary, userRoles.techbill, userRoles.technical, userRoles.billing],
    },
    {
      key: "setup",
      label: <LabelSidebar path="/setup" label="Setup Layanan" />,
      icon: <ICSettingOutlined />,
      role: [userRoles.primary, userRoles.techbill, userRoles.technical],
    },
    {
      key: "email-api",
      label: <LabelSidebar path="/email-api" label="Email API" tag="baru" />,
      icon: <ICApiOutlined />,
      role: [userRoles.primary, userRoles.techbill, userRoles.technical],
    },
    {
      key: "log",
      label: <LabelSidebar path="/log" label="Log Email" />,
      icon: <ICRetweetOutlined />,
      role: [userRoles.primary, userRoles.techbill, userRoles.technical],
    },
    {
      key: "report",
      label: <LabelSidebar path="/report" label="Report Email" />,
      icon: <ICLineChartOutlined />,
      role: [userRoles.primary, userRoles.techbill, userRoles.technical],
    },
    {
      key: "suppression",
      label: <LabelSidebar path="/suppression" label="Suppression" />,
      icon: <ICInboxOutlined />,
      role: [userRoles.primary, userRoles.techbill, userRoles.technical],
    },
    {
      key: "billing",
      label: <LabelSidebar path="/billing" label="Tagihan" />,
      icon: <ICCreditCardOutlined />,
      role: [userRoles.primary, userRoles.techbill, userRoles.billing],
    },
    {
      key: "documentation",
      label: <LabelSidebar path="/documentation" label="Dokumentasi" />,
      icon: <ICBookOutlined />,
      role: [userRoles.primary, userRoles.techbill, userRoles.technical],
    },
    {
      key: "profile",
      label: <LabelSidebar path="/profile" label="Profile" />,
      icon: <ICUserOutlined />,
      role: [userRoles.primary, userRoles.techbill, userRoles.technical, userRoles.billing],
    },
  ];

  const authorizedAdminSidebar = itemsAdmin.filter((nav) =>
    nav.role.find((role) => role === user.role)
  );

  const authorizedUserSidebar = itemsUser.filter((nav) =>
    nav.role.find((role) => role === user.role)
  );

  const items = isAdmin ? authorizedAdminSidebar : authorizedUserSidebar;

  return (
    <>
      <Menu
        mode="inline"
        defaultSelectedKeys={[selectedMenu]}
        className="menu-sidebar"
        selectedKeys={selectedMenu}
        onSelect={selectMenu}
        inlineIndent={16}
        items={items}
      />
      <Menu
        mode="inline"
        inlineIndent={16}
        className="btn-logout-wrapper"
        onClick={handleLogout}
        items={[
          {
            key: 0,
            label: "Keluar",
            icon: <ICLogoutOutlined />,
          },
        ]}
      />
    </>
  );
};

export default Sidebar;
