import { Button, Card, ConfigProvider, Table, Tag, Menu, Dropdown } from "antd";
import { getListNotificationAdmin, DeleteNotificationAdmin } from "api";
import DashboardHeader from "components/dashboard-header/DashboardHeader";
import { EmptyWrapper } from "components/empty/EmptyComponent";
import { splitDate } from "utils/helpers";
import {
  ICEyeOutlined,
  ICNotificationOutlined,
  ICPercentageOutlined,
  ICPlusOutlined,
  ICDeleteOutlined,
  ICEditOutlined,
  ICDownOutlined,
} from "components/list/ListIcon";
import ModalDeleteNotification from "components/popups/modal/ModalDeleteNotification";
import ModalDeleteNotificationSuccess from "components/popups/modal/ModalSuccessDeleteNotification";
import TableTitle from "components/tableSendiri/TableTitle";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setListNotificationAdmin } from "redux/slices/admin/listNotificationAdmin";
import { capitalizeFirstLetter } from "utils/helpers";
import useDocumentTitle from "utils/useDocumentTitle";
import "./manageNotification.less";

const TableNotification = ({ filter, setFilter, loading, setLoading, getListNotification }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 992;
  const navigate = useNavigate();
  const navCreate = () => navigate("add");
  const listNotification = useSelector(({ listNotificationAdmin }) => listNotificationAdmin.value);

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const column = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 105,
      render: (data) => {
        const { date, time } = splitDate(data);
        return (
          <div className="flex flex-col">
            <p className="mb-0">{date}</p>
            <p className="mb-0 text-secondary text-xs" type="secondary">
              {time}
            </p>
          </div>
        );
      },
    },
    {
      title: "Judul Notifikasi",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Kategori",
      dataIndex: "category",
      key: "category",
      width: 120,
      render: (values) => {
        return (
          <Tag icon={values === "info" ? <ICNotificationOutlined /> : <ICPercentageOutlined />}>
            {capitalizeFirstLetter(values)}
          </Tag>
        );
      },
    },
    {
      title: "Publisher",
      dataIndex: "publisher",
      key: "publisher",
      width: 180,
      render: (values) => {
        return values.name;
      },
    },
    {
      width: 120,
      fixed: "right",
      render: (_, record) => {
        return (
          <Dropdown trigger={["click"]} overlay={() => ActionTableNotification(record)}>
            <Button>
              Action
              <ICDownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  const ActionTableNotification = (record) => {
    const handleViewClick = () => {
      navigate({ pathname: "view", search: `uuid=${record.uuid}` });
    };

    const handleEditClick = () => {
      navigate({ pathname: "edit", search: `uuid=${record.uuid}` });
    };

    const handleDeleteNotification = () => {
      ModalDeleteNotification({
        title: "Yakin Hapus Notifikasi?",
        customMessage: (
          <div>
            <p className="mb-6">
              Apakah anda yakin ingin menghapus notifikasi berjudul{" "}
              <span style={{ fontWeight: 500 }}>{record.title}</span>?
              <br /> <br /> Setelah dihapus, user tidak dapat melihat notifikasi tersebut lagi.
            </p>
          </div>
        ),
        cancelText: "Batalkan",
        okText: "Ya, Hapus",
        onOk: () => {
          setTimeout(async () => {
            await DeleteNotificationAdmin({
              notifUuid: record.uuid,
            });
            ModalDeleteNotificationSuccess({
              title: "Notifikasi Berhasil Dihapus",
              customMessage: (
                <div>
                  <p className="mb-0">
                    Notifikasi berjudul <span style={{ fontWeight: 500 }}>{record.title}</span>{" "}
                    berhasil dihapus pada sistem.
                  </p>
                </div>
              ),
              okText: "Oke",
              onOk: () => {
                setTimeout(async () => {
                  try {
                    setLoading(true);
                    await getListNotification();
                  } catch (error) {
                    console.error("Error fetching API details:", error);
                  } finally {
                    setLoading(false);
                  }
                }, 500);
              },
            });
          }, 500);
        },
      });
    };

    return (
      <Menu
        items={[
          {
            label: "Lihat Notifikasi",
            key: 1,
            icon: <ICEyeOutlined />,
            onClick: () => {
              handleViewClick();
            },
          },
          {
            label: "Edit Notifikasi",
            key: 2,
            icon: <ICEditOutlined />,
            onClick: () => {
              handleEditClick();
            },
          },
          {
            label: "Delete Notifikasi",
            key: 3,
            danger: true,
            icon: <ICDeleteOutlined />,
            onClick: () => {
              handleDeleteNotification();
            },
          },
        ]}
      />
    );
  };

  const BtnCreateNew = () => {
    return (
      <Button type="primary" icon={<ICPlusOutlined />} onClick={navCreate}>
        Tambah Baru
      </Button>
    );
  };

  if (!listNotification) return <Card bordered={false} loading />;
  return (
    <ConfigProvider
      renderEmpty={() => (
        <EmptyWrapper
          title="Data belum tersedia"
          message="Belum ada notifikasi yang dikirimkan, silakan tambah notifikasi untuk mengirimkan notifikasi ke customer"
        />
      )}>
      <Table
        {...(width < breakpoint ? { scroll: { x: 992 } } : undefined)}
        columns={column}
        dataSource={listNotification.rows}
        pagination={{
          size: "small",
          current: filter.page,
          pageSize: filter.limit,
          total: listNotification.count,
          hideOnSinglePage: true,
          showSizeChanger: false,
          disable: listNotification.count < 10,
          onChange: (page) => setFilter({ ...filter, page }),
        }}
        loading={loading}
        title={() => (
          <TableTitle
            title="Daftar Notifikasi"
            desc="List notifikasi yang sudah dibroadcast ke klien"
            pageLimit={{
              onSelectLimit: (limit) => setFilter({ limit, page: 1 }),
              currentLimit: 10,
            }}
            addsAfter={<BtnCreateNew />}
          />
        )}
        rowKey={(record) => record.uuid}
      />
    </ConfigProvider>
  );
};

const ManageNotification = () => {
  useDocumentTitle("Kelola Notifikasi");
  const dispatch = useDispatch();

  const [filter, setFilter] = useState({ page: 1, limit: "10" });
  const [loading, setLoading] = useState(false);

  const getListNotification = useCallback(async () => {
    setLoading(true);
    const { data } = await getListNotificationAdmin(filter);
    dispatch(setListNotificationAdmin(data));
    setLoading(false);
  }, [dispatch, filter]);

  useEffect(() => {
    if (filter) getListNotification();
  }, [getListNotification, filter]);

  return (
    <div className="manageNotification">
      <DashboardHeader title="Kelola Notifikasi" />
      <div className="py-4 px-6">
        <div className="tableWrapper">
          <TableNotification
            filter={filter}
            setFilter={setFilter}
            loading={loading}
            setLoading={setLoading}
            getListNotification={getListNotification}
          />
        </div>
      </div>
    </div>
  );
};

export default ManageNotification;
