import { Button, Select, Form, Input } from "antd";
import { ICFilterOutlined, ICFlagOutlined } from "components/list/ListIcon";
import DatePicker from "components/datepicker/DatePicker";

import { useEffect, useState } from "react";

const { Option } = Select;

export const CollapseFilter = ({
  isDataAvailable,
  isDataLoadFailed,
  isDataNotFound,
  selectedCategory,
  handleAdvanceFilter,
  changeCategory,
  ListCompany,
  changeCompany,
  company,
  handleSearch,
  disabledDate,
  searchValue,
  onChangeDatetime,
  filteredCompanies,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 1200;
  const breakpoint_third = 768;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const [form] = Form.useForm();
  return {
    ...(width < breakpoint && width > breakpoint_third ? (
      <Form className="formFilter" form={form} onFinish={handleAdvanceFilter}>
        <div className="flex gap-x-4">
          <Form.Item label="Perusahaan" name="select_company" className="form-company">
            <Select
              name="domain"
              onClick={(e) => e.stopPropagation()}
              className="domain-list"
              onSelect={changeCompany}
              value={searchValue}
              defaultValue={"AllCompany"}
              {...(isDataLoadFailed
                ? { disabled: isDataLoadFailed }
                : isDataNotFound
                ? { disabled: false }
                : isDataAvailable
                ? { disabled: false }
                : { disabled: true })}
              dropdownRender={(menu) => (
                <>
                  <Input
                    placeholder="Ketik perusahaan..."
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchValue}
                  />
                  {menu}
                </>
              )}>
              <Select.Option value="AllCompany">
                <span>Semua Perusahaan</span>
              </Select.Option>
              <Select.Option value="global">
                <span>
                  <ICFlagOutlined style={{ marginRight: 4 }} />
                  Global Suppression
                </span>
              </Select.Option>
              {filteredCompanies.length > 0
                ? filteredCompanies.map(({ company_name, company_uuid }, index) => (
                    <Select.Option key={company_uuid + index} value={company_uuid}>
                      {company_name}
                    </Select.Option>
                  ))
                : ListCompany.length > 0
                ? ListCompany.map(({ company_name, company_uuid }, index) => (
                    <Select.Option key={company_uuid + index} value={company_uuid}>
                      {company_name}
                    </Select.Option>
                  ))
                : undefined}
            </Select>
          </Form.Item>
          <Form.Item label="Kategori" name="select_category" className="form-category">
            <Select
              value={selectedCategory}
              onSelect={changeCategory}
              defaultValue={"All"}
              {...(isDataLoadFailed
                ? { disabled: isDataLoadFailed }
                : isDataNotFound
                ? { disabled: false }
                : isDataAvailable
                ? { disabled: false }
                : { disabled: true })}>
              <Option value="All">Semua Kategori</Option>
              <Option value="hard">Hard Bounce</Option>
              <Option value="manual">Manual Added</Option>
              <Option value="soft">Soft Bounce</Option>
              <Option value="whitelist">Whitelist</Option>
            </Select>
          </Form.Item>
        </div>
        <div className="flex gap-x-6">
          <Form.Item name="datetime" className="form-daterange">
            <DatePicker.RangePicker
              className="w-full"
              disabledDate={disabledDate}
              format="yyyy-MM-dd"
              onChange={onChangeDatetime}
              {...(isDataLoadFailed
                ? { disabled: isDataLoadFailed }
                : isDataNotFound
                ? { disabled: false }
                : isDataAvailable
                ? { disabled: false }
                : { disabled: true })}
            />
          </Form.Item>
          <Button
            type="primary"
            icon={<ICFilterOutlined />}
            htmlType="submit"
            {...(isDataLoadFailed
              ? { disabled: isDataLoadFailed }
              : isDataNotFound
              ? { disabled: false }
              : isDataAvailable
              ? { disabled: false }
              : { disabled: true })}>
            Apply Filter
          </Button>
        </div>
      </Form>
    ) : width < breakpoint_third ? (
      <Form className="formFilter" form={form} onFinish={handleAdvanceFilter}>
        <div className="flex gap-x-4">
          <Form.Item label="Perusahaan" name="select_company" className="form-company">
            <Select
              name="domain"
              onClick={(e) => e.stopPropagation()}
              className="domain-list"
              onSelect={changeCompany}
              value={searchValue}
              defaultValue={"AllCompany"}
              {...(isDataLoadFailed
                ? { disabled: isDataLoadFailed }
                : isDataNotFound
                ? { disabled: false }
                : isDataAvailable
                ? { disabled: false }
                : { disabled: true })}
              dropdownRender={(menu) => (
                <>
                  <Input
                    placeholder="Ketik perusahaan..."
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchValue}
                  />
                  {menu}
                </>
              )}>
              <Select.Option value="AllCompany">
                <span>Semua Perusahaan</span>
              </Select.Option>
              <Select.Option value="global">
                <span>
                  <ICFlagOutlined style={{ marginRight: 4 }} />
                  Global Suppression
                </span>
              </Select.Option>
              {filteredCompanies.length > 0
                ? filteredCompanies.map(({ company_name, company_uuid }, index) => (
                    <Select.Option key={company_uuid + index} value={company_uuid}>
                      {company_name}
                    </Select.Option>
                  ))
                : ListCompany.length > 0
                ? ListCompany.map(({ company_name, company_uuid }, index) => (
                    <Select.Option key={company_uuid + index} value={company_uuid}>
                      {company_name}
                    </Select.Option>
                  ))
                : undefined}
            </Select>
          </Form.Item>
          <Form.Item label="Kategori" name="select_category" className="form-category">
            <Select
              value={selectedCategory}
              onSelect={changeCategory}
              defaultValue={"All"}
              {...(isDataLoadFailed
                ? { disabled: isDataLoadFailed }
                : isDataNotFound
                ? { disabled: false }
                : isDataAvailable
                ? { disabled: false }
                : { disabled: true })}>
              <Option value="All">Semua Kategori</Option>
              <Option value="hard">Hard Bounce</Option>
              <Option value="manual">Manual Added</Option>
              <Option value="soft">Soft Bounce</Option>
              <Option value="whitelist">Whitelist</Option>
            </Select>
          </Form.Item>
        </div>
        <div className="flex gap-x-6">
          <Form.Item name="datetime" className="form-daterange">
            <DatePicker.RangePicker
              className="w-full"
              disabledDate={disabledDate}
              format="yyyy-MM-dd"
              onChange={onChangeDatetime}
              {...(isDataLoadFailed
                ? { disabled: isDataLoadFailed }
                : isDataNotFound
                ? { disabled: false }
                : isDataAvailable
                ? { disabled: false }
                : { disabled: true })}
            />
          </Form.Item>
        </div>
        <Button
          type="primary"
          icon={<ICFilterOutlined />}
          htmlType="submit"
          {...(isDataLoadFailed
            ? { disabled: isDataLoadFailed }
            : isDataNotFound
            ? { disabled: false }
            : isDataAvailable
            ? { disabled: false }
            : { disabled: true })}>
          Apply Filter
        </Button>
      </Form>
    ) : (
      <div className="mb-3">
        <Form layout="inline" className="formFilter" onFinish={handleAdvanceFilter}>
          <Form.Item name="select_company" label="Perusahaan" className="form-company">
            <Select
              name="domain"
              onClick={(e) => e.stopPropagation()}
              className="domain-list"
              onSelect={changeCompany}
              value={searchValue}
              defaultValue={"AllCompany"}
              {...(isDataLoadFailed
                ? { disabled: isDataLoadFailed }
                : isDataNotFound
                ? { disabled: false }
                : isDataAvailable
                ? { disabled: false }
                : { disabled: true })}
              dropdownRender={(menu) => (
                <>
                  <Input
                    placeholder="Ketik perusahaan..."
                    onChange={(e) => handleSearch(e.target.value)}
                    value={searchValue}
                  />
                  {menu}
                </>
              )}>
              <Select.Option value="AllCompany">
                <span>Semua Perusahaan</span>
              </Select.Option>
              <Select.Option value="global">
                <span>
                  <ICFlagOutlined style={{ marginRight: 4 }} />
                  Global Suppression
                </span>
              </Select.Option>
              {filteredCompanies.length > 0
                ? filteredCompanies.map(({ company_name, company_uuid }, index) => (
                    <Select.Option key={company_uuid + index} value={company_uuid}>
                      {company_name}
                    </Select.Option>
                  ))
                : ListCompany.length > 0
                ? ListCompany.map(({ company_name, company_uuid }, index) => (
                    <Select.Option key={company_uuid + index} value={company_uuid}>
                      {company_name}
                    </Select.Option>
                  ))
                : undefined}
            </Select>
          </Form.Item>
          <Form.Item name="select_category" label="Kategori" className="form-category">
            <Select
              value={selectedCategory}
              onSelect={changeCategory}
              defaultValue={"All"}
              {...(isDataLoadFailed
                ? { disabled: isDataLoadFailed }
                : isDataNotFound
                ? { disabled: false }
                : isDataAvailable
                ? { disabled: false }
                : { disabled: true })}>
              <Option value="All">Semua Kategori</Option>
              <Option value="hard">Hard Bounce</Option>
              <Option value="manual">Manual Added</Option>
              <Option value="soft">Soft Bounce</Option>
              <Option value="whitelist">Whitelist</Option>
            </Select>
          </Form.Item>
          <Form.Item name="datetime" className="form-daterange">
            <DatePicker.RangePicker
              className="w-full"
              disabledDate={disabledDate}
              format="yyyy-MM-dd"
              onChange={onChangeDatetime}
              {...(isDataLoadFailed
                ? { disabled: isDataLoadFailed }
                : isDataNotFound
                ? { disabled: false }
                : isDataAvailable
                ? { disabled: false }
                : { disabled: true })}
            />
          </Form.Item>
          <div className="button-advance-filter">
            <Button
              type="primary"
              icon={<ICFilterOutlined />}
              htmlType="submit"
              {...(isDataLoadFailed
                ? { disabled: isDataLoadFailed }
                : isDataNotFound
                ? { disabled: false }
                : isDataAvailable
                ? { disabled: false }
                : { disabled: true })}>
              Apply Filter
            </Button>
          </div>
        </Form>
      </div>
    )),
  };
};
