import { ExportOutlined } from "@ant-design/icons";
import { Button, Collapse, Form, Input, Select, Space, Tooltip } from "antd";
import { ICFilterOutlined, ICReloadOutlined, ICUpOutlined } from "components/list/ListIcon";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";

const { Option } = Select;
const { Panel } = Collapse;

const hasKeys = (params) => params && Object.keys(params).length > 0;

const ButtonExport = ({ disable, dataCSV, name = "", headerCSV, fileName = "", responsive }) => {
  const ButtonComponent = () => {
    return (
      <Tooltip overlayClassName={responsive && `tooltipCSV-${responsive}`} title="Export To CSV">
        <Button icon={<ExportOutlined />} type="primary" disabled={disable}>
          <span className={responsive && `hidden-${responsive}`}>Export To CSV</span>
        </Button>
      </Tooltip>
    );
  };

  if (!dataCSV) return "";
  else if (disable) return <ButtonComponent />;
  return (
    <CSVLink
      data={dataCSV}
      headers={headerCSV}
      filename={fileName ? fileName : `log-email-${name?.replaceAll(" ", "-")}.csv`}>
      <ButtonComponent />
    </CSVLink>
  );
};

const PageLimit = ({ currentLimit, onSelectLimit, isVisible, disable }) => {
  if (!onSelectLimit) return "";
  return (
    <Select
      className="pageLimit__selector"
      defaultValue={`${currentLimit || 10}`}
      onSelect={onSelectLimit}
      disabled={disable}>
      <Option value="10">10 per page</Option>
      <Option value="25">25 per page</Option>
      <Option value="50">50 per page</Option>
      <Option value="100">100 per page</Option>
    </Select>
  );
};

const Refresh = ({ disable, onClick, loading = false, responsive = "md" }) => {
  return (
    <Button icon={<ICReloadOutlined spin={loading} />} disabled={disable} onClick={onClick}>
      <span className={responsive && `hidden-${responsive}`}>Refresh Log</span>
    </Button>
  );
};

const SearchCompany = ({ disabled, isAdmin, onSearch }) => {
  if (!isAdmin) return "";
  return (
    <Input.Search
      placeholder="Cari nama perusahaan..."
      className="input-company"
      enterButton
      disabled={disabled}
      onSearch={onSearch}
    />
  );
};

const AdvanceFilter = ({ rotate = false }) => {
  const HeaderAdvanceFilter = () => {
    const [click, setClick] = useState(rotate);
    return (
      <div className="flex items-center">
        <Space size={4}>
          <span className="text-success">
            Filter
            <ICUpOutlined className="ml-1 text-xs" rotate={click ? 0 : 180} />
          </span>
        </Space>
      </div>
    );
  };

  return (
    <>
      <Collapse defaultActiveKey={["0"]} ghost className="report-collapse">
        <Panel
          header={<HeaderAdvanceFilter rotate={false} />}
          key="1"
          showArrow={false}
          collapsible="header"
          className="title-tbl-pelanggan">
          <Form layout="inline" className="form-wrapper" id="f-advance-filter">
            <Form.Item label="Paket Layanan" name="paket-layanan">
              <Select
                mode="multiple"
                allowClear
                className="log-act-admin"
                placeholder="Semua Paket">
                <Option className="log-admin-selectall" value="selectall">
                  Select All
                </Option>
                <Option value="bronze">Bronze</Option>
                <Option value="silver">Silver</Option>
                <Option value="gold">Gold</Option>
                <Option value="premium">Premium</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Subscription" name="reccipient">
              <Select
                mode="multiple"
                allowClear
                className="log-act-admin"
                placeholder="Semua Status">
                <Option className="log-admin-selectall" value="selectall">
                  Select All
                </Option>
                <Option value="active">Active</Option>
                <Option value="inactive">Inactive</Option>
                <Option value="trial">Trial</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Akun Relay" name="paketLayanan">
              <Select
                mode="multiple"
                allowClear
                className="log-act-admin"
                placeholder="Semua Relay">
                <Option className="log-admin-selectall" value="selectall">
                  Select All
                </Option>
                <Option value="active">Active</Option>
                <Option value="locked">locked</Option>
                <Option value="suspend">Suspend</Option>
              </Select>
            </Form.Item>
            <Form.Item className="button-advance-filter">
              <Button type="primary" icon={<ICFilterOutlined />}>
                Apply Filter
              </Button>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
    </>
  );
};

const TableTitle = ({
  title = "",
  className = "",
  pageLimit = { currentLimit: 0, onSelectLimit: 0, disable: 0 },
  buttonExport = { disable: 0, onExport: 0 },
  buttonRefresh,
  desc,
  addsBeforeExport,
  addsAfter,
  addsBefore,
  loading,
  searchCompany,
  advanceFilter,
}) => {
  const hasButtonExport = hasKeys(buttonExport);
  const hasPagelimit = hasKeys(pageLimit);
  const hasRefresh = hasKeys(buttonRefresh);
  const hasSearchCompany = hasKeys(searchCompany);
  const hasAdvanceFilter = hasKeys(advanceFilter);
  const { companyName } = useSelector(({ userData }) => userData.value);

  return (
    <div className="tableTitle__wrapper">
      <div className={`flex justify-between items-center ${className}`}>
        <div className="title-text">
          <h5 className="text-base font-medium mb-0">{title}</h5>
          {desc && <p className="text-secondary text-sm font-normal mb-0">{desc}</p>}
        </div>
        <div className="btn-group-log">
          <div className="flex gap-x-2">
            {hasAdvanceFilter && <AdvanceFilter {...advanceFilter} />}
            {addsBefore}
            {hasSearchCompany && <SearchCompany {...searchCompany} />}
            <div className="flex gap-x-2">
              {hasPagelimit && <PageLimit {...pageLimit} />}
              <div className="flex gap-x-1">
                {hasRefresh && <Refresh {...buttonRefresh} loading={loading} />}
                {addsBeforeExport}
                {hasButtonExport && <ButtonExport {...buttonExport} name={companyName} />}
                {addsAfter}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableTitle;
