import { Button, Form, Upload, Dropdown, Menu, Input } from "antd";
import { MaskedInput } from "antd-mask-input";
import { ICUploadOutlined } from "components/list/ListIcon";
import React from "react";
import { ICDownOutlined } from "components/list/ListIcon";
import { BanCharacters } from "utils/helpers";

const FormNPWP = ({ handleMask, classNPWP = "", setDisable, setAction, action, form }) => {
  form.setFieldsValue({ npwpType: action === "NPWP 15" || !action ? "15" : "16" });

  if (!form.getFieldValue(["npwp"])) setDisable(true);

  const props = {
    customRequest: ({ file, onSuccess }) => {
      onSuccess("ok");
    },
    multiple: false,
    maxCount: 1,
    onRemove: () => setDisable(false),
  };

  const itemsUsage = [
    { label: "NPWP 15", key: "NPWP 15" },
    { label: "NPWP 16", key: "NPWP 16" },
  ];

  const onSelectDropdown = ({ key }) => {
    setAction(key);
    form.setFieldsValue({ npwpType: key === "NPWP 15" ? "15" : "16" });
    form.setFieldsValue({ npwp: "" });
    setDisable(true);
  };

  const menu = (
    <Menu onClick={onSelectDropdown} style={{ marginLeft: -12, marginTop: 4 }}>
      {itemsUsage.map((item) => (
        <Menu.Item key={item.key} style={{ padding: "5px 26px 5px 12px" }}>
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );
  const searchAction = itemsUsage.find((item) => item.label === action);

  const SearchDropdown = () => {
    return (
      <Dropdown overlay={menu} destroyPopupOnHide trigger={["click"]}>
        <span className="flex items-center gap-1">
          {searchAction.label}
          <ICDownOutlined />
        </span>
      </Dropdown>
    );
  };

  return (
    <>
      <Form.Item label="NPWP" name="npwp" className={classNPWP}>
        {action === "NPWP 15" || !action ? (
          <MaskedInput
            maskOptions={{ mask: "00.000.000.0-000.000", lazy: false }}
            onChange={(e) => {
              handleMask(e);
              if (
                form.getFieldValue("npwpType") === "15" &&
                form.getFieldValue("npwp").length === 15
              )
                setDisable(false);
            }}
            addonBefore={<SearchDropdown />}
          />
        ) : (
          <Input
            placeholder="No NPWP"
            addonBefore={<SearchDropdown />}
            onChange={(e) => {
              if (
                form.getFieldValue("npwpType") === "16" &&
                (form.getFieldValue("npwp").length === 16 || e.target.value.length === 16)
              ) {
                setDisable(false);
              } else {
                setDisable(true);
              }
            }}
            onKeyDown={(e) => {
              if (e.target.value.length >= 16 && e.keyCode !== 8 && e.keyCode !== 46) {
                e.preventDefault();
              }
              if (BanCharacters.test(e.key) && e.key !== "Backspace") {
                e.preventDefault();
              }
            }}
            onWheel={(e) => e.preventDefault()}
          />
        )}
      </Form.Item>
      <Form.Item name="npwpType" hidden />
      <Form.Item
        name="fileNpwp"
        valuePropName="fileList"
        getValueFromEvent={(e) => {
          if (Array.isArray(e)) return e;
          return e?.fileList;
        }}
        rules={[
          {
            validator: (rule, value) => {
              if (value && value.length) {
                const fileSize = value[0].size / 1024 / 1024 < 2;
                if (!fileSize) {
                  setDisable(true);
                  return Promise.reject(new Error("Ukuran file terlalu besar (maksimal: 2MB)"));
                } else {
                  setDisable(false);
                  return Promise.resolve();
                }
              }
            },
          },
        ]}>
        <Upload {...props} accept=".jpg, .png, .pdf">
          <Button icon={<ICUploadOutlined />}>Click to Upload</Button>
        </Upload>
      </Form.Item>
    </>
  );
};

export default FormNPWP;
