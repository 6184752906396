import { Form, Input, Modal, Select } from "antd";
import { createAdmin, editAdmin } from "api";
import { useEffect, useState } from "react";
import { gatherFormData, requiredRules } from "utils/helpers";
import ModalError from "./ModalError";
import ModalSuccess from "./ModalSuccess";

const { Option } = Select;

const ModalKelolaAdmin = ({ visible, onCancel, action, data = false, getUpdate }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (visible) form.resetFields();
  }, [form, visible]);

  const title = action === "add" ? "Tambah Admin Baru" : "Edit Admin";

  const handleAdd = () => {
    const callback = async () => {
      setLoading(true);
      const values = form.getFieldsValue();
      const { code, msg } = await createAdmin({ ...values });
      setTimeout(() => {
        if (code === 400 && msg.includes("Registered")) {
          ModalError({
            title: "Akun gagal dibuat",
            message: "Email sudah teregistrasi, silahkan gunakan email lainnya",
            onOk: () => {},
          });
        } else if (code === 400 && msg.includes("internal")) {
          ModalError({
            title: "Akun gagal dibuat",
            message:
              "Harap gunakan alamat email dengan domain internal untuk setiap akun admin (contoh: @aktiva.co.id atau @excellent.co.id)",
            onOk: () => {},
          });
        } else if (code === 200 && msg.includes("Success")) {
          onCancel();
          ModalSuccess({
            title: "Akun berhasil dibuat",
            message: `Akun admin ${values.name} berhasil dibuat pada sistem`,
            onOk: () => {
              form.resetFields();
              getUpdate();
            },
          });
        }
      }, 500);
      setLoading(false);
    };
    gatherFormData(form, callback);
  };

  const handleEdit = () => {
    const callback = async () => {
      const values = form.getFieldsValue();
      setLoading(true);
      const { code, msg } = await editAdmin({ uuid: data.uuid, ...values });
      if (code === 400 && msg.includes("registered!")) {
        ModalError({
          title: "Akun gagal diperbarui",
          message: "Email sudah teregistrasi, silahkan gunakan email lainnya",
          onOk: () => {},
        });
      } else if (code === 400 && msg.includes("internal")) {
        ModalError({
          title: "Alamat Email Tidak Diizinkan",
          message:
            "Harap gunakan alamat email dengan domain internal untuk setiap akun admin (contoh: @aktiva.co.id atau @excellent.co.id)",
          onOk: () => {},
        });
      } else if (code === 200 && msg.includes("Success")) {
        onCancel();
        ModalSuccess({
          title: "Akun berhasil di update",
          message: `Akun admin ${data?.name} berhasil diupdate pada sistem`,
          onOk: () => getUpdate(),
        });
      }
      setLoading(false);
    };
    gatherFormData(form, callback);
  };

  return (
    <Modal
      title={title}
      open={visible}
      onCancel={onCancel}
      centered
      okButtonProps={{ htmlType: "submit", loading: loading }}
      onOk={action === "add" ? handleAdd : handleEdit}>
      <Form
        form={form}
        layout="vertical"
        {...(action === "edit" && {
          initialValues: {
            name: data.name,
            email: data.email,
            role: data.roleName,
          },
        })}>
        <Form.Item label="Nama Admin" name="name" className="mb-4" rules={[requiredRules]}>
          <Input placeholder="Nama Lengkap" />
        </Form.Item>
        <Form.Item
          label="Alamat Email"
          name="email"
          className="mb-4"
          rules={[
            requiredRules,
            { type: "email", message: "Harap masukkan alamat email yang valid" },
          ]}>
          <Input placeholder="nama@perusahaan.tld" />
        </Form.Item>
        <Form.Item label="Role" name="role" className="mb-0" rules={[requiredRules]}>
          <Select placeholder="Pilih">
            <Option value="superadmin">Super Admin</Option>
            <Option value="admin">Admin</Option>
            <Option value="accounting">Accounting</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalKelolaAdmin;
