import { getEmailSummaryAdmin, getGraphAdmin, getTopSenderAdmin } from "api";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setEmailSummary } from "redux/slices/emailSummary";

export const useGetAdminDashboardData = () => {
  const dispatch = useDispatch();
  const sentSummary = useSelector(({ emailSummary }) => emailSummary.value);

  const getData = useCallback(async () => {
    const { data: emailSummary } = await getEmailSummaryAdmin();
    const { data: topSender } = await getTopSenderAdmin({ getBy: "today" });
    const { data: graph } = await getGraphAdmin();
    delete topSender.top_receiver_domain;
    dispatch(setEmailSummary({ emailSummary, topSender, graph }));
  }, [dispatch]);

  useEffect(() => {
    if (!sentSummary) getData();
  }, [getData, sentSummary]);
};
