import React from "react";
import ReactMarkdown from "react-markdown";

const MarkdownInput = ({ data }) => {
  return (
    <ReactMarkdown
      children={data}
      components={{
        code: ({ node, children, ...props }) => <code>{children}</code>,
        h1: ({ node, children, ...props }) => <h1 className="font-medium text-3xl">{children}</h1>,
        img: ({ src, alt, title }) => (
          <img src={src} alt={alt} title={title} className="imgRender" />
        ),
      }}
    />
  );
};

export default MarkdownInput;
