import { Typography } from "antd";
import logoDark from "assets/images/aktiva-dark.png";
import logo from "assets/images/aktiva.png";

const { Title } = Typography;

export const LogoAktiva = ({ className }) => {
  return (
    <div className={className}>
      <img src={logo} alt="logo" />
    </div>
  );
};

export const LogoAktivaDark = ({ className }) => {
  return (
    <div className={className}>
      <img src={logoDark} alt="logo" />
    </div>
  );
};

export const TitleAndParagraph = ({ level, classNameT, classNameP, childrenP, childrenT }) => {
  return (
    <>
      <Title level={level} className={`${classNameT} title-form`}>
        {childrenT}
      </Title>
      <p className={`text-secondary ${classNameP}`}>{childrenP}</p>
    </>
  );
};

export const TitleParagraph = ({ title = "", desc = "", descClass, className }) => {
  const titleClass = className || "mb-1";
  return (
    <>
      <h2 className={`text-3xl font-medium ${titleClass}`}>{title}</h2>
      <p className={`text-secondary ${descClass}`}>{desc}</p>
    </>
  );
};
