import { createSlice } from "@reduxjs/toolkit";

export const billingDataFakturTable = createSlice({
  name: "billingDataFakturTable",
  initialState: {
    value: false,
  },
  reducers: {
    setBillingDataFakturTable: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setBillingDataFakturTable } = billingDataFakturTable.actions;
export default billingDataFakturTable.reducer;
