import { createSlice } from "@reduxjs/toolkit";

export const billingDataConfirmPayment = createSlice({
  name: "billingDataConfirmPayment",
  initialState: {
    value: false,
  },
  reducers: {
    setBillingDataConfirmPayment: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setBillingDataConfirmPayment } = billingDataConfirmPayment.actions;
export default billingDataConfirmPayment.reducer;
