import { Button, Dropdown, Menu } from "antd";
import { authLogout } from "api";
import { LogoAktiva } from "components/Components";
import { ICPoweroffOutlined, ICUserOutlined } from "components/list/ListIcon";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setLogout } from "utils/helpers";
import "./headersection.less";

const HeaderSection = () => {
  const user = useSelector(({ userData }) => userData.value);
  const logout = async () => {
    const { code } = await authLogout();
    if (code === 200) setLogout("user");
  };

  const companyName = (
    <span className="text-base text-success font-normal">PT Aktiva Kreasi Investama</span>
  );
  let actionView = companyName;

  const userDropdown = (
    <Menu
      items={[
        {
          key: "1",
          icon: <ICPoweroffOutlined className="text-danger" rotate={90} />,
          label: (
            <Button type="link" className="p-0 text-danger" onClick={logout}>
              Keluar
            </Button>
          ),
          onClick: () => logout(),
        },
      ]}
    />
  );

  if (user)
    actionView = (
      <Dropdown
        overlay={userDropdown}
        trigger={["click"]}
        placement="bottomRight"
        overlayClassName="dropdown-header"
        overlayStyle={{ minWidth: "min-content" }}>
        <Button type="link" className="p-0" icon={<ICUserOutlined />}>
          {user.name}
        </Button>
      </Dropdown>
    );
  return (
    <header className="flex items-center justify-between header-setup">
      <Link to="/main">
        <LogoAktiva className="aktiva-logo-setup" />
      </Link>
      {actionView}
    </header>
  );
};

export default HeaderSection;
