import { createSlice } from "@reduxjs/toolkit";

export const emailSummary = createSlice({
  name: "emailSummary",
  initialState: {
    value: false, // default state
  },
  reducers: {
    // reducers action
    setEmailSummary: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setEmailSummary } = emailSummary.actions;
export default emailSummary.reducer;
