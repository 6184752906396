import { createSlice } from "@reduxjs/toolkit";

export const mailDeliveryChart = createSlice({
  name: "mailDeliveryChart",
  initialState: {
    value: false,
  },
  reducers: {
    setMailDeliveryChart: (state, { payload }) => {
      state.value = payload;
    },
  },
});

export const { setMailDeliveryChart } = mailDeliveryChart.actions;
export default mailDeliveryChart.reducer;
