import { Button, Card, Table, Tooltip } from "antd";
import { deleteAdmin } from "api";
import { ICDeleteOutlined, ICEditOutlined, ICExportOutlined } from "components/list/ListIcon";
import ModalConfirm from "components/popups/modal/ModalConfirm";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import TableTitle from "components/tableSendiri/TableTitle";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const roleName = [
  { name: "Super Admin", key: "superadmin" },
  { name: "Admin", key: "admin" },
  { name: "Accounting", key: "accounting" },
];

const TableListAdmin = ({ setOpen, user, filter, setFilter, getListAdmin }) => {
  const listAdmin = useSelector(({ listAdmin }) => listAdmin.value);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 900;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const column = [
    {
      title: "Nama Admin",
      dataIndex: "name",
      key: "name",
      width: width > breakpoint ? 456 : 300,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 420,
    },
    {
      title: "Role",
      dataIndex: "roleName",
      key: "roleName",
      width: 160,
      render: (values) => {
        const role = roleName.find((item) => item.key === values);
        return <span>{role.name}</span>;
      },
    },
    {
      dataIndex: "button",
      key: "button",
      render: (_, record) => (
        <div className="flex gap-x-1">
          <div className="edit-btn">
            <Tooltip placement="top" title="Edit Admin">
              <Button
                icon={<ICEditOutlined />}
                className="w-12"
                disabled={user.role !== "superadmin"}
                onClick={() => {
                  setOpen({ mode: "edit", record });
                }}
              />
            </Tooltip>
          </div>
          <div className="delete-btn">
            <Tooltip placement="top" title="Hapus Admin">
              <Button
                icon={<ICDeleteOutlined />}
                danger
                className="w-12"
                disabled={user.role !== "superadmin"}
                onClick={() => {
                  ModalConfirm({
                    title: `Hapus admin ${record.name}`,
                    message: "Apakah anda yakin ingin menghapus admin tersebut?",
                    okButtonProps: { danger: true },
                    onOk: async () => {
                      const { status } = await deleteAdmin({ uuid: record.uuid });
                      if (status === "success") {
                        ModalSuccess({
                          title: "Akun berhasil di hapus",
                          message: `Akun admin ${record.name} berhasil dihapus pada sistem`,
                          onOk: () => getListAdmin(),
                        });
                      }
                    },
                  });
                }}
              />
            </Tooltip>
          </div>
        </div>
      ),
    },
  ];

  if (!listAdmin) return <Card bordered={false} loading />;

  return (
    <Table
      dataSource={listAdmin.rows}
      title={() => (
        <TableTitle
          title="Daftar Admin"
          desc={`List administrator ${process.env.REACT_APP_NAME} Dashboard`}
          addsAfter={
            <Button
              type="primary"
              icon={<ICExportOutlined />}
              disabled={user.role !== "superadmin"}
              onClick={() => setOpen({ mode: "add" })}>
              <span className="hidden-xs">Tambah Admin</span>
            </Button>
          }
        />
      )}
      columns={column}
      {...(width <= breakpoint ? { scroll: { x: 1024 } } : {})}
      pagination={{
        current: filter.page,
        pageSize: filter.limit,
        onChange: (page) => {
          setFilter({ ...filter, page });
        },
        total: listAdmin.count,
        size: "small",
        showSizeChanger: false,
        hideOnSinglePage: true,
      }}
      showSorterTooltip={false}
      rowKey={(record) => record.uuid}
    />
  );
};

export default TableListAdmin;
