import { Card, Col, Row, Skeleton } from "antd";
import { useState, useEffect } from "react";
import { formatNumberWithDot } from "utils/helpers";

const formatNumberWithCommas = formatNumberWithDot;

export const DetailOverQuota = ({ showSkeleton, infoQuota }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const widthResponsive = 768;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  return (
    <div {...(width < 992 ? { className: "mb-2" } : { className: "mb-3" })}>
      {width < widthResponsive ? (
        <Card title={"Detail Over Quota Usage"} className="card-detail">
          {showSkeleton ? (
            <Skeleton active paragraph={{ rows: 0 }} title={{ width: "100%" }} />
          ) : (
            <Col>
              <Row justify="space-between">
                <Col>
                  <p className="mb-0 font-medium" style={{ fontSize: 12 }}>
                    Over Quota
                  </p>
                  <p className="mb-0 text-secondary">
                    {formatNumberWithCommas(infoQuota.overQuotaUsage)}
                  </p>
                </Col>
                <Col>
                  <p className="mb-0 font-medium" style={{ fontSize: 12 }}>
                    Estimasi Tagihan
                  </p>
                  <p className="mb-0 text-secondary">
                    Rp{formatNumberWithCommas(infoQuota.estimateBilling)}
                  </p>
                </Col>
                <Col>
                  <p className="mb-0 font-medium" style={{ fontSize: 12 }}>
                    Tanggal Tagihan
                  </p>
                  <p className="mb-0 text-secondary">{infoQuota.billingIssued}</p>
                </Col>
              </Row>
            </Col>
          )}
        </Card>
      ) : (
        <>
          {showSkeleton ? (
            <Card>
              <Skeleton active paragraph={{ rows: 0 }} title={{ width: "100%" }} />
            </Card>
          ) : (
            <Row>
              <Col
                md={6}
                className="card-detail font-medium"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}>
                Detail Over Quota Usage
              </Col>
              <Col md={6} className="detail-wrapper">
                <Col>
                  <p className="mb-0 font-medium" style={{ fontSize: 12 }}>
                    Over Quota
                  </p>
                  <p className="mb-0 text-secondary">
                    {formatNumberWithCommas(infoQuota.overQuotaUsage)}
                  </p>
                </Col>
              </Col>
              <Col md={6} className="detail-wrapper">
                <Col>
                  <p className="mb-0 font-medium" style={{ fontSize: 12 }}>
                    Estimasi Tagihan
                  </p>
                  <p className="mb-0 text-secondary ">
                    Rp{formatNumberWithCommas(infoQuota.estimateBilling)}
                  </p>
                </Col>
              </Col>
              <Col md={6} className="detail-wrapper">
                <Col>
                  <p className="mb-0 font-medium" style={{ fontSize: 12 }}>
                    Tanggal Tagihan
                  </p>
                  <p className="mb-0 text-secondary">{infoQuota.billingIssued}</p>
                </Col>
              </Col>
            </Row>
          )}
        </>
      )}
    </div>
  );
};
