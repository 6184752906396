import { Button } from "antd";
import { useSelector } from "react-redux";

const ContactButton = ({ role, handleEdit, handleDelete }) => {
  const user = useSelector(({ userData }) => userData.value);
  return (
    <div className="flex gap-x-1 items-center btn-profile">
      <Button
        type="link"
        size="small"
        disabled={!user.isAdmin && user.role !== "primary" && user.role !== "techbill"}
        className="text-xs p-0"
        onClick={handleEdit}>
        Edit
      </Button>
      <span className="text-xs text-secondary">•</span>
      <Button
        type="link"
        size="small"
        className="text-xs p-0 text-danger"
        disabled={
          role === "primary" ||
          (!user.isAdmin && user.role !== "primary" && user.role !== "techbill")
        }
        onClick={handleDelete}>
        Hapus
      </Button>
    </div>
  );
};

export default ContactButton;
