import { Card, Tabs } from "antd";
import { ICInboxOutlined, ICHistoryOutlined } from "components/list/ListIcon";
import { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import SummarySuppress from "./SuppressSummary";
import TableSupressAdmin from "./TableSuppress";
import TableHistoryAdmin from "./TableHistory";
import {
  GetAllSuppressList,
  GetHistorySuppressList,
  getListCompany,
  GetSummarySuppress,
} from "api";
import { setSuppressionListAdmin } from "redux/slices/admin/SuppressionList";
import { setHistoryListAdmin } from "redux/slices/admin/HistoryList";
import { setListAllCompany } from "redux/slices/admin/listAllCompany";
import { setSummarySuppress } from "redux/slices/admin/SummarySuppress";

const SuppressSection = () => {
  const dispatch = useDispatch();
  const [activeTabs, setActiveTabs] = useState("1");
  const handleChange = (key) => setActiveTabs(key);
  const [showSkeletonSummary, setShowSkeletonSummary] = useState(false);
  const [showSkeletonSuppress, setShowSkeletonSuppress] = useState(false);
  const [showSkeletonHistory, setShowSkeletonHistory] = useState(false);

  const [filterSuppress, setfilterSuppress] = useState({
    limit: 10,
    page: 1,
  });

  const [filterHistory, setfilterHistory] = useState({
    limit: 10,
    page: 1,
  });

  const isSuppressAdmin = useSelector(({ SuppressionListAdmin }) => SuppressionListAdmin.value);
  const isHistoryAdmin = useSelector(({ HistoryListAdmin }) => HistoryListAdmin.value);
  const ListAllCompany = useSelector(({ listAllCompany }) => listAllCompany.value);
  const SuppressSummary = useSelector(({ SummarySuppress }) => SummarySuppress.value);

  const getDataSuppress = useCallback(async () => {
    setShowSkeletonSuppress(true);
    const { data, code } = await GetAllSuppressList(filterSuppress);
    if (data && Object.keys(data).length) dispatch(setSuppressionListAdmin({ data, code }));
    setShowSkeletonSuppress(false);
  }, [dispatch, filterSuppress]);

  const getDataHistory = useCallback(async () => {
    setShowSkeletonHistory(true);
    const { data, code } = await GetHistorySuppressList(filterHistory);
    if (data && Object.keys(data).length) dispatch(setHistoryListAdmin({ data, code }));
    setShowSkeletonHistory(false);
  }, [dispatch, filterHistory]);

  const getAllCompany = useCallback(async () => {
    const { data } = await getListCompany();
    if (data && Object.keys(data).length) dispatch(setListAllCompany(data));
  }, [dispatch]);

  const getSummary = useCallback(async () => {
    setShowSkeletonSummary(true);
    const { data, code } = await GetSummarySuppress();
    if (data && Object.keys(data).length) dispatch(setSummarySuppress({ data, code }));
    setShowSkeletonSummary(false);
  }, [dispatch]);

  useEffect(() => {
    getDataSuppress();
    getDataHistory();
  }, [getDataSuppress, getDataHistory]);

  useEffect(() => {
    getAllCompany();
  }, [getAllCompany]);

  useEffect(() => {
    getSummary();
  }, [getSummary]);

  return (
    <div>
      <SummarySuppress dataSummary={SuppressSummary} showSkeletonSummary={showSkeletonSummary} />
      <Card>
        <Tabs
          defaultActiveKey="1"
          activeKey={activeTabs}
          className="billing-tab"
          onChange={handleChange}
          items={[
            {
              label: (
                <span>
                  <ICInboxOutlined />
                  Suppression List
                </span>
              ),
              key: "1",
              children: (
                <div>
                  <TableSupressAdmin
                    dataSuppressAdmin={isSuppressAdmin}
                    filterSuppressAdmin={filterSuppress}
                    setfilterSuppressAdmin={setfilterSuppress}
                    ListCompany={ListAllCompany}
                    setShowSkeletonSuppress={setShowSkeletonSuppress}
                    showSkeletonSuppressAdmin={showSkeletonSuppress}
                    getDataSuppress={getDataSuppress}
                    getDataHistory={getDataHistory}
                  />
                </div>
              ),
            },
            {
              label: (
                <span>
                  <ICHistoryOutlined />
                  History Aktivitas
                </span>
              ),
              key: "2",
              children: (
                <div>
                  <TableHistoryAdmin
                    dataSuppressAdmin={isSuppressAdmin}
                    filterSuppressAdmin={filterSuppress}
                    dataHistoryAdmin={isHistoryAdmin}
                    filterHistoryAdmin={filterHistory}
                    showSkeletonHistory={showSkeletonHistory}
                    setfilterHistoryAdmin={setfilterHistory}
                    ListCompany={ListAllCompany}
                  />
                </div>
              ),
            },
          ]}
        />
      </Card>
    </div>
  );
};

export default SuppressSection;
