import { Button, Card, Tag, Table, Typography, Skeleton } from "antd";
import {
  ICEyeInvisibleOutlined,
  ICEyeOutlined,
  ICSyncOutlined,
  ICCopyOutlined,
  ICCheckOutlined,
} from "components/list/ListIcon";
import { getEmailAPI, GenerateAPI, ReGenerateAPI, getDetailServicePackage } from "api";
import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModalActivate from "components/popups/modal/ModalGenerate";
import ModalActivateSuccess from "components/popups/modal/ModalGenerateSuccess";
import { AcoountApiInactive } from "components/empty/EmptyComponent";
import { ApiKeyNotAvail } from "components/empty/EmptyComponent";
import { btnCopyPassword, btnShowHidePassword } from "utils/copyHidePass";
import { setEmailAPI } from "redux/slices/EmailAPI";
import { setServicePackage } from "redux/slices/servicePackage";

const { Paragraph } = Typography;

const InformationAccountAPI = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const EmailAPI = useSelector(({ EmailAPI }) => EmailAPI.value);
  const servicePackage = useSelector(({ servicePackage }) => servicePackage.value);

  const getService = useCallback(async () => {
    setLoading(true);
    const { data } = await getDetailServicePackage();
    dispatch(setServicePackage(data));
    setTimeout(setLoading, 1000, false);
  }, [dispatch]);

  useEffect(() => {
    if (!servicePackage) getService();
  }, [getService, servicePackage]);

  const getData = useCallback(async () => {
    setLoading(true);
    const { data } = await getEmailAPI();
    dispatch(setEmailAPI(data));
    setTimeout(setLoading, 1000, false);
  }, [dispatch]);

  useEffect(() => {
    if (!EmailAPI);
  }, [getData, EmailAPI]);

  const [hideToken, setHideToken] = useState(true);
  const [copyToken, setCopyToken] = useState(true);
  const [copyEndpoint, setcopyEndpoint] = useState(true);
  const pwdHide = <span>************</span>;

  const columns = [
    {
      title: "params",
      dataIndex: "params",
      key: "params",
      width: 150,
      className: "params-value-col",
    },
    {
      title: "values",
      dataIndex: "values",
      key: "values",
      className: "params-value-col",
      render: (values, index) => (
        <>
          {index.params === "API Token" ? (
            <Paragraph>
              {hideToken ? pwdHide : values}
              <Button
                className="m-0 act-pass"
                type="link"
                icon={
                  hideToken ? (
                    <ICEyeOutlined className="text-sm" />
                  ) : (
                    <ICEyeInvisibleOutlined className="text-sm" />
                  )
                }
                onClick={() => btnShowHidePassword(setHideToken)}
                style={{ marginLeft: "8px", marginRight: "8px" }}
              />
              <Button
                className="m-0 act-pass"
                type="link"
                icon={
                  copyToken ? (
                    <ICCopyOutlined className="text-sm" />
                  ) : (
                    <ICCheckOutlined className="text-sm" />
                  )
                }
                onClick={() => btnCopyPassword(values, setCopyToken)}
              />
            </Paragraph>
          ) : index.params === "API Endpoint" ? (
            <Paragraph>
              {values}
              <Button
                className="m-0 act-pass"
                type="link"
                icon={
                  copyEndpoint ? (
                    <ICCopyOutlined className="text-sm" />
                  ) : (
                    <ICCheckOutlined className="text-sm" />
                  )
                }
                onClick={() => btnCopyPassword(values, setcopyEndpoint)}
                style={{ marginLeft: "8px" }}
              />
            </Paragraph>
          ) : index.params === "Created Date" || index.params === "Generated by" ? (
            <Paragraph>{values}</Paragraph>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  const dataSource = [
    {
      key: "1",
      params: "API Endpoint",
      values: EmailAPI.apiEndpoint,
    },
    {
      key: "2",
      params: "API Token",
      values: EmailAPI.token,
    },
    {
      key: "3",
      params: "Generated by",
      values: EmailAPI.generatedBy,
    },
    {
      key: "4",
      params: "Created Date",
      values: EmailAPI.generatedAt,
    },
  ];

  const BtnReGenerate = () => {
    const handleReGenerateAPI = async () => {
      ModalActivate({
        title: "Apakah anda yakin ingin Re-generate API Key?",
        customMessage: (
          <div>
            <p className="mb-6">
              Setelah melakukan generate ulang API Key, maka akses penggunaan API Key sebelumnya
              akan tidak valid.
              <br /> <br /> Anda harus melakukan konfigurasi ulang terhadap kode atau aplikasi anda
              menggunakan API Key terbaru.
            </p>
          </div>
        ),
        cancelText: "Batalkan",
        okText: "Re-Generate API Key",

        iconType: "regenerate",
        onOk: async () => {
          try {
            await ReGenerateAPI();
            ModalActivateSuccess({
              title: "API Key berhasil di-generate ulang",
              customMessage: (
                <p className="mb-0">
                  API Key milik anda berhasil diperbaharui menggunakan API Key terbaru. Silakan
                  sesuaikan konfigurasi pada kode atau aplikasi anda
                </p>
              ),
              okText: "Oke",
              onOk: () => {
                setTimeout(async () => {
                  try {
                    setLoading(true);
                    await getData();
                  } catch (error) {
                    console.error("Error fetching API details:", error);
                  } finally {
                    setLoading(false);
                  }
                }, 1000);
              },
            });
          } catch (error) {
            console.error("Error regenerating API key:", error);
          }
        },
      });
    };
    return (
      <Button type="primary" icon={<ICSyncOutlined />} onClick={handleReGenerateAPI}>
        Re-generate API Key
      </Button>
    );
  };

  const BtnGenerate = () => {
    const handleGenerateAPI = () => {
      ModalActivate({
        title: "Apakah anda yakin ingin Generate API Key?",
        customMessage: (
          <div>
            <p className="mb-6">
              API Key akan digenerate untuk kebutuhan pengiriman email via API. Anda tidak dapat
              melakukan revoke API Key, namun dapat men-generate ulang apabila dibutuhkan.
            </p>
          </div>
        ),
        cancelText: "Batalkan",
        okText: "Generate API Key",
        onOk: () => {
          setTimeout(async () => {
            await GenerateAPI();
            ModalActivateSuccess({
              title: "API Key berhasil di-generate",
              customMessage: (
                <p className="mb-0">
                  API Key milik anda berhasil dibuat. Silakan melakukan konfigurasi pada kode atau
                  aplikasi anda untuk dapat mengirimkan email via API
                </p>
              ),
              okText: "Oke",
              onOk: () => {
                window.location.reload();
              },
            });
          }, 500);
        },
      });
    };
    return (
      // Generate Button

      <Button type="primary" icon={<ICSyncOutlined />} onClick={handleGenerateAPI}>
        Generate API Key
      </Button>
    );
  };

  const BtnReGenerateDisable = () => {
    return (
      // Regenerate Button

      <Button type="primary" icon={<ICSyncOutlined />} disabled>
        Re-generate API Key
      </Button>
    );
  };

  const BtnGenerateDisable = () => {
    return (
      // Generate Button
      <Button type="primary" icon={<ICSyncOutlined />} disabled>
        Generate API Key
      </Button>
    );
  };

  const TitleActivation = ({ statusService, statusApi }) => {
    let tagColor = "";
    let status = "";
    if (statusService && statusApi) {
      if (statusApi === "suspended" || statusApi === "locked") {
        if (statusApi === "suspended") {
          tagColor = "orange";
          status = statusApi;
        } else {
          tagColor = "red";
          status = statusApi;
        }
      } else if (statusApi === "active") {
        tagColor = "blue";
        status = statusApi;
      } else if (statusApi === "inactive") {
        tagColor = "red";
        status = statusApi;
      }
    } else if (statusService && !statusApi) {
      tagColor = "red";
      status = "inactive";
    } else if (!statusService && statusApi) {
      tagColor = "blue";
      status = statusApi;
    } else {
      tagColor = "red";
      status = "inactive";
    }

    return (
      <div className="flex gap-x-2 items-center">
        <span>API Details</span>
        <div>
          <Tag color={tagColor}>{status} </Tag>
        </div>
      </div>
    );
  };

  const BtnActivation = ({ statusService, statusApi }) => {
    if (statusService && statusApi) {
      if (statusApi === "suspended" || statusApi === "locked") {
        return <BtnReGenerateDisable />;
      } else if (statusApi === "active") {
        return <BtnReGenerate />;
      }
    } else if (statusService && !statusApi) {
      return <BtnGenerate />;
    } else if (!statusService && statusApi) {
      return <BtnReGenerate />;
    } else {
      return <BtnGenerateDisable />;
    }
    return null;
  };
  return (
    <Card
      title={
        loading ? (
          <Skeleton.Input active={loading} />
        ) : (
          <TitleActivation statusService={servicePackage.status} statusApi={EmailAPI.status} />
        )
      }
      extra={
        loading ? (
          <Skeleton.Button active={loading} />
        ) : (
          <BtnActivation statusService={servicePackage.status} statusApi={EmailAPI.status} />
        )
      }>
      {loading ? (
        <Skeleton active={loading} /> // Show loading indicator
      ) : EmailAPI.status === "locked" || EmailAPI.status === "suspended" ? (
        <div>
          <p className="text-secondary mb-2">
            Untuk menggunakan API, pastikan akun dan status langganan anda aktif
          </p>
          <Table
            dataSource={dataSource}
            columns={columns}
            showHeader={false}
            pagination={false}
            rowKey={(record) => record.values}
            style={{ padding: "4px 0px" }}
            loading={loading}
          />
          <p className="text-secondary mt-2 mb-0" style={{ fontStyle: "italic" }}>
            Last update by {EmailAPI.updatedBy} at {EmailAPI.updatedAt}
          </p>
        </div>
      ) : servicePackage.status === "active" && !EmailAPI.status ? (
        <ApiKeyNotAvail />
      ) : (!servicePackage.status || servicePackage.status === "active") &&
        EmailAPI.status === "active" ? (
        <div>
          <p className="text-secondary mb-2">
            Untuk menggunakan API, pastikan akun dan status langganan anda aktif
          </p>
          <div style={{ overflowX: "auto" }}>
            <Table
              dataSource={dataSource}
              columns={columns}
              showHeader={false}
              pagination={false}
              rowKey={(record) => record.values}
              style={{ minWidth: "100%", padding: "4px 0px" }}
            />
          </div>
          <p className="text-secondary mt-2 mb-0" style={{ fontStyle: "italic" }}>
            Last update by {EmailAPI.updatedBy} at {EmailAPI.updatedAt}
          </p>
        </div>
      ) : (
        <AcoountApiInactive />
      )}
    </Card>
  );
};

export default InformationAccountAPI;
