import { Button } from "antd";
import { checkStatusVerification, resendConfirmEmail } from "api";
import ModalSuccess from "components/popups/modal/ModalSuccess";
import { useEffect, useState } from "react";
import {
  getCookie,
  getStorage,
  removeCookie,
  removeStorage,
  secondsToTime,
  setCookie,
  setStorage,
} from "utils/helpers";
import Support from "./Support";

const VerifikasiEmail = ({ next }) => {
  const statusVerif = getStorage("isValid");
  const counterCookies = getCookie("r_btn");
  const [counter, setCounter] = useState(counterCookies || 0);
  const [statusVerification, setStatusVerification] = useState(statusVerif || "");
  const [btnLoading, setBtnLoading] = useState("");

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter > 0) {
        setStatusVerification("resend");
        setCounter(counter - 1);
        setCookie("r_btn", counter - 1);
      } else if (counter === 0) {
        removeCookie(["r_btn"]);
        removeStorage("isValid");
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [counter]);

  const checkVerification = async () => {
    setStatusVerification("");
    setBtnLoading("check");
    const { msg, status } = await checkStatusVerification();
    setBtnLoading("");
    if (msg.includes("kembali") || status === "failed") {
      setStorage("isValid", "not valid");
      setStatusVerification("not valid");
    }
    if (msg.includes("aktif")) {
      removeCookie(["r_btn"]);
      removeStorage("isValid");
      next();
    }
  };

  const resendVerification = async () => {
    const { msg } = await resendConfirmEmail();
    if (msg.includes("diaktifkan")) {
      ModalSuccess({
        title: "Email Anda Sudah Terverifikasi",
        message:
          "Anda sudah berhasil melakukan verifikasi alamat email dan tidak perlu melakukan verifikasi kembali",
        width: 460,
        okText: "Selanjutnya",
        onOk: () => next(),
      });
    } else {
      setBtnLoading("resend");
      setBtnLoading("");
      setStorage("isValid", "resend");
      setStatusVerification("resend");
      setCookie("r_btn", 120);
      setCounter(120);
    }
  };

  const notValid = (
    <span className="text-danger m-0">
      Verifikasi email tidak valid. Silakan lakukan verifikasi email dengan benar.
    </span>
  );

  const resendRender = (
    <div>
      <p className="text-success m-0">
        Email verifikasi sudah berhasil kami kirimkan ulang, silakan cek email anda.
      </p>
      <p className="text-success m-0">
        Email dapat dikirim ulang kembali dalam waktu {secondsToTime(counter)}.
      </p>
    </div>
  );

  const helperRender =
    statusVerification === "resend"
      ? resendRender
      : statusVerification === "not valid"
      ? notValid
      : "";

  return (
    <div>
      <div className="flex flex-col justify-center setup-form mx-auto mt-10 mb-12">
        <h2 className="text-3xl mb-1">Verifikasi Email Anda</h2>
        <p className="text-secondary mb-0">
          Kami sudah berhasil membuat akun anda pada sistem. Selanjutnya lakukan verifikasi alamat
          email anda untuk dapat melanjutkan proses registrasi layanan kami. Periksa email dari kami
          pada inbox anda, jika mengalami kendala silakan dapat hubungi kami pada
          <a href="mailto:support@aktiva.co.id"> support@aktiva.co.id</a>
        </p>
      </div>
      <div className="flex flex-col mx-auto text-center items-center mb-2">{helperRender}</div>
      <div className="flex flex-col mx-auto text-center items-center gap-y-2">
        <Button
          className="btn-setup"
          type="primary"
          loading={btnLoading === "check"}
          onClick={checkVerification}>
          Saya sudah verifikasi
        </Button>
        <Button
          type="link"
          className="btn-setup text-success"
          disabled={counter !== 0}
          loading={btnLoading === "resend"}
          onClick={resendVerification}>
          Kirim ulang email verifikasi
        </Button>
      </div>
      <Support />
    </div>
  );
};

export default VerifikasiEmail;
